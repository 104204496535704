import styled from "styled-components";

export default styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 20px;

    border-right: 1px solid ${(props) => props.theme.homeListBodyBorderColor};
    border-left: 1px solid ${(props) => props.theme.homeListBodyBorderColor};
    border-bottom: 1px solid ${(props) => props.theme.homeListBodyBorderColor};

    .loader {
        height: 50vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .heading {
        display: flex;
        justify-content: center;
        align-items: center;

        min-height: 50px;
        max-height: 50px;

        border-radius: 5px 5px 0 0;

        background-color: ${(props) => props.theme.homeListTitleRowBackground};
        color: ${(props) => props.theme.homeListTitleRowText};

        font-size: 20px;

        margin-bottom: 10px;
    }

    .MuiTypography-root {
        font-family: Calibri;
    }

    .PrivateTimePickerToolbar-hourMinuteLabel {
        .MuiButton-root {
            .MuiTypography-root {
                color: ${(props) => props.theme.textPrimary};
            }
        }
        .PrivateTimePickerToolbar-separator {
            color: ${(props) => props.theme.textPrimary};
        }
    }
    .PrivateTimePickerToolbar-ampmSelection {
        .MuiButton-root {
            .MuiTypography-root {
                color: ${(props) => props.theme.textPrimary};
            }
        }
    }

    .rbc-off-range-bg {
        background-color: ${(props) => props.theme.dateOutOfRange};
    }

    .rbc-btn-group {
        button {
            color: ${(props) => props.theme.textPrimary};
        }
    }

    .rbc-event {
        background-color: ${(props) => props.theme.filterButtonBackgroundHover};
        color: ${(props) => props.theme.textPrimary};

        &.currentSession {
            background-color: ${(props) => props.theme.subMenuBadgeBackground};
            color: ${(props) => props.theme.homeListTitleRowText};
        }
    }

    .rbc-date-cell {
        &.rbc-now {
            color: ${(props) => props.theme.homeListTitleRowText};
        }
    }

    .rbc-today {
        background-color: ${(props) => props.theme.homeListTitleRowBackground};
        color: ${(props) => props.theme.homeListTitleRowText};
    }

    .rbc-event-content {
    }

    .MuiClockPicker-root {
        span {
            color: ${(props) => props.theme.textPrimary};
        }
    }

    .MuiGrid-root {
        padding-left: 10px;
    }

    .MuiTypography-overline {
        display: none;
    }

    .Mui-selected {
        font-weight: bolder;
    }

    .PrivateTimePickerToolbar-penIconLandscape {
        display: none;
    }

    .MuiPickerStaticWrapper-root {
        background-color: ${(props) => props.theme.modalInnerBackground};
    }

    .button {
        margin: 10px 10px 20px 20px;
    }

    .modalBox {
        max-width: 300px;

        .time-picker-wrapper {
            margin: 10px 20px 10px 20px;

            .MuiFormControl-root {
                background-color: ${(props) =>
                    props.theme.filterButtonBackground};
                border: none;
                outline: none;
                border-radius: 5px;

                min-height: 40px;
                max-height: 40px;

                input,
                fieldset {
                    min-height: 40px;
                    max-height: 40px;
                    border: none;
                    outline: none;
                    padding: 0 15px;
                }

                :hover {
                    background-color: ${(props) =>
                        props.theme.filterButtonBackgroundHover};
                }
            }
        }
        .button {
            width: calc(100% - 40px);
        }
    }

    .MuiTextField-root {
        width: 100%;
    }
`;
