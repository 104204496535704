import styled from "styled-components";
import colors from "assets/styles/colors";

export default styled.div`
    flex-direction: column;
    display: flex;
    max-width: 100%;
    min-width: 100%;

    .searchBar {
        max-width: 100%;
        min-width: 100%;
        display: flex;
        align-items: center;
        z-index: 10;

        .searchOptions {
            margin: 10px 0 10px -10px;
        }

        .button {
            margin-right: 0px;
        }
    }

    .editIcon {
        color: ${colors.textNavy};
        cursor: pointer;
    }

    .checkIcon {
        color: ${colors.textNavy};
    }
`;
