import styled from "styled-components";

export default styled.div`
    display: flex;
    flex-direction: row;
    min-width: 100%;

    .questionCell {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 300px;
        max-width: 300px;
        min-height: 60px;
        max-height: 60px;
        text-align: center;

        color: ${(props) => props.theme.textPrimary};
    }

    .desktop-only {
        display: none;
      }

    .votingCells {
        display: flex;
        flex-direction: row;
        flex-grow: 1;
    }

    @media only screen and (min-width: 768px) {
        .desktop-only {
          display: block;
        }
      }

    @media screen and (max-width: 900px) {
        flex-direction: column;

        margin-top: 20px;

        .questionCell {
            justify-content: flex-start;
            align-items: flex-end;
            margin-left: 10px;

            min-height: unset;
            max-height: unset;
        }
    }
`;
