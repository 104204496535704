import styled from 'styled-components';

export default styled.div`
    .modal-title {
        font-size: 20px;
        letter-spacing: 0.1em;
        color: ${(props) => props.theme.pageTitleText};
        text-align: center;
    }

    .form {
        padding: 20px;
        .inputContainer {
            margin-top: 20px;
        }
    }

    .btn-close-container {
        display:flex;
        justify-content: flex-end;
        padding-top: 8px;
    }
    .btn-add-container {
        display: flex;
        justify-content: flex-start;
        padding: 10px;
    }
`;
