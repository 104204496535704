import styled from 'styled-components';

export default styled.div`
    .vote {
        border: 1px solid ${(props) => props.theme.votingCellBorder};
        border-radius: 5px;
        padding: 10px;
        margin-bottom: 10px;
    }
    .check-icon {
        margin-left: 10px;
        margin-right: 10px;
    }
`;
