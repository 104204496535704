import React, { useState } from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// Material UI
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Popper from "@mui/material/Popper";

// FontAwesome
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Utils
import { toDate } from "utils/general.utils";

// Styles
import {
    DateSelectContainer,
    DateSelectPopupContainer,
} from "./DateSelect.styles";

type Props = {
    placeholder: string;
    value: Date | undefined;
    setValue: Function;
    error: boolean;
    label?: string;
    disabled?: boolean;
    errorText?: string;
};

export default function DateSelect({
    placeholder,
    value,
    setValue,
    error,
    label,
    disabled,
    errorText = "",
}: Props) {
    const [open, setOpen] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<any>(null);

    const handleSelectDate = (date: Date) => {
        setOpen(false);
        setValue(date);
    };

    const handleClick = (event: React.MouseEvent) => {
        if (!open) {
            setAnchorEl(event.currentTarget);
            setOpen(true);
        }
    };

    const handleClickAway = (event: any) => {
        if (anchorEl && !anchorEl.contains(event.target as Node)) {
            setOpen(false);
        }
    };

    return (
        <DateSelectContainer className="dateSelect">
            {label && <label>{label}</label>}
            <div
                style={label ? { marginTop: "10px" } : {}}
                className={error ? "dateBox error" : "dateBox"}
                onClick={handleClick}
            >
                <p className={value ? "active" : ""}>
                    {value ? toDate(value) : placeholder}
                </p>
                <FontAwesomeIcon icon={faCalendar} fixedWidth />
            </div>

            <Popper
                open={open}
                anchorEl={anchorEl}
                className="dropDownMenu"
                style={{ zIndex: 1000 }}
            >
                <ClickAwayListener onClickAway={handleClickAway}>
                    <DateSelectPopupContainer>
                        <DatePicker
                            readOnly={disabled ? true : false}
                            selected={value}
                            onChange={handleSelectDate}
                            inline
                        />
                    </DateSelectPopupContainer>
                </ClickAwayListener>
            </Popper>
            {errorText && error ? (
                <p className="errorText">{errorText}</p>
            ) : null}
        </DateSelectContainer>
    );
}
