import { useState, useEffect } from "react";

// Components
import Markdown from "components/Common/Markdown/Markdown";
import DocsSideBar from "components/Docs/DocsSideBar/DocsSideBar";
import LoadingSpinner from "components/Common/LoadingSpinner/LoadingSpinner";

// Context
import { useSettings } from "context/Settings.context";

import importList from "documentation/config/help/importList";

// Styles
import HelpContainer from "./Help.styles";

export default function Help() {
    const { route } = useSettings();

    const [text, setText] = useState<any>("");

    const getFile = async (path: string) => {
        if (path) {
            // @ts-ignore
            let res = await fetch(await importList[path]);
            let string = await res.text();

            let doc = string.split("///").pop();
            let meta: any = string.split("///")[0];

            meta = meta.split("export const meta = ").pop();
            meta = meta.replace("};", "}");

            setText(doc);
        }
    };

    useEffect(() => {
        getFile(route.replace("/help/", ""));
    }, [route]);

    return (
        <HelpContainer>
            {!text ? (
                <div className="docsMain" id="docs">
                    <LoadingSpinner noText />
                </div>
            ) : (
                <>
                    <div className="docsMain" id="docs">
                        <Markdown text={text} />
                    </div>

                    <DocsSideBar text={text} />
                </>
            )}
        </HelpContainer>
    );
}
