import styled from "styled-components";
import colors from "assets/styles/colors";

export const MainMenuContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    min-height: 100%;
    min-width: 100px;
    max-width: 100px;

    padding-bottom: 10px;

    overflow: auto;

    background-color: ${(props) => props.theme.mainMenuBackground};

    z-index: 25;

    user-select: none;

    .menuList {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;

export const MenuTrayDropDownContainer = styled.div`
    position: fixed;

    bottom: 10px;
    left: 80px;

    margin-top: 10px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    min-width: 350px;
    max-width: 350px;
    box-shadow: 0px 5px 15px 5px rgba(0, 0, 0, 0.28);
    background-color: ${(props) => props.theme.notificationsBackground};
    overflow: hidden;
    user-select: none;
    max-height: 80vh;

    .title {
        padding: 10px 10px 10px 15px;
        background-color: ${(props) =>
            props.theme.notificationsHeaderBackground};
        color: ${(props) => props.theme.notificationsHeaderText};
        letter-spacing: 0.1em;
        display: flex;
        justify-content: space-between;

        svg {
            font-size: 20px;
            cursor: pointer;
            :hover {
                color: #aaaaaa;
            }
        }
    }

    .body {
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        .menu-item {
            cursor: pointer;
        }
    }

    .footer {
        padding: 10px 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        letter-spacing: 0.1em;
        cursor: pointer;
        color: ${(props) => props.theme.notificationsRowText};

        :hover {
            background-color: ${(props) =>
                props.theme.notificationsRowBackgroundHover};
        }
    }

    .loading {
        padding: 30px 0;

        .no-results {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
`;
