import SearchOptions from "components/Common/SearchOptions/SearchOptions";
import React, {useEffect, useState} from "react";


import AlertsTabContainer from "./AlertsTab.styles";

// Utils
import * as Database from "../../../../../utils/database.utils";

// Styles
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes, faCheck} from "@fortawesome/free-solid-svg-icons";
import BaseTab from "../_BaseTab/_BaseTab";
import {Signoff} from "../models/signoff";
import Button from "../../../../Common/Button/Button";

interface AlertsTabProps {
    checkName: string;
}


const getAlertsUrl = (checkName: string) => `api/nav/alerts/${checkName}/`
const getSignOffUrl = (checkName: string, date: string|null = null) => {
    if (date) {
        return `api/nav/alerts/${checkName}/signoff/${date}/`
    }

    return `api/nav/alerts/${checkName}/signoff/`
}

const AlertsTab = ({checkName}: AlertsTabProps) => {
    const [alertUrl, setAlertUrl] = useState<string>('');
    const [signOff, setSignOff] = useState<Signoff|null>(null);
    const [selectedDate, setSelectedDate] = useState<Date|null>(null);
    const [filterQuery, setFilterQuery] = useState<string>("");
    const [savingSignOff, setSavingSignOff] = useState<boolean>(false);

    const getISOFromDate = (dt: Date) => dt.toISOString().slice(0, 10);

    const getFetchUrl = () => {
        let url = getSignOffUrl(checkName);

        if (filterQuery) {
            let date_string = filterQuery.substring(filterQuery.indexOf("=") + 1, filterQuery.lastIndexOf("00:") - 4);
            let dt = new Date(date_string)
            let date_formatted = getISOFromDate(dt);

            setSelectedDate(dt)

            url = getSignOffUrl(checkName, date_formatted);
        }

        return url;
    }

    const fetchSignOff = async () => {
        const url = getFetchUrl();

        let response = await Database.get(url);

         if (response.ok) {
             if (response.status === 204) {
                 setSignOff(null)
             } else {
                 let resp = await response.json();
                 let data = new Signoff(resp)
                 setSignOff(data)
             }
         }
    }

    const doSignOff = async () => {
        setSavingSignOff(true);

        let selectedDateString = null;
        if (selectedDate) {
            selectedDateString =  getISOFromDate(selectedDate);
        }

        let url = getSignOffUrl(checkName, selectedDateString);

        let response = await Database.post(url, {});

         if (response.ok) {
             let resp = await response.json();

             let data = new Signoff(resp);

             setSavingSignOff(false);

             setSignOff(data)
         }
    }

    useEffect(() => {
        fetchSignOff()
    }, [filterQuery]);

    useEffect(() => {
        setAlertUrl(getAlertsUrl(checkName))
    }, []);

    const filterOptions = [
        { id: 1, name: "Select Date", value: 'date', date: 'date', type: "SINGLEDATE" },
    ];

    return (
        <AlertsTabContainer>
            <div className={"header"}>
                <div className={"left"}>
                    <div className="actionsDiv">
                        <div>
                            <p className={"sectionTitle"}>ALERTS</p>
                            <p className="title mb20">Input reasons for discrepancy</p>
                        </div>
                    </div>
                    <SearchOptions
                        setFilterQuery={setFilterQuery}
                        options={filterOptions}
                        showAll={false}
                    />
                </div>
                <div className={"right "}>
                    {!signOff &&
                         <Button
                            text={(savingSignOff) ? "Saving..." : "Sign Off"}
                            disabled={savingSignOff}
                            color="primary"
                            click={() => doSignOff()}
                        />
                    }


                    {signOff &&<div className={"signed-off"}>
                         <div className={(signOff) ? "green" : "red"}>
                            <FontAwesomeIcon
                                className={"icon"}
                                icon={(signOff) ? faCheck : faTimes}
                                fixedWidth
                                size={"2x"}
                            />
                        </div>
                        <div className={"details"}>
                            <div className={"green status"}>Signed Off</div>
                            <div>{signOff.created_by}</div>
                            <div>{signOff.created_at && signOff.created_at.toLocaleDateString()}</div>
                        </div>
                    </div> }

                </div>
            </div>

            <BaseTab tableType={'alert'} checkName={checkName} baseUrl={alertUrl} filterQuery={filterQuery}/>
        </AlertsTabContainer>
    );
};

export default AlertsTab;
