import { useState } from "react";

// Material UI
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Popper from "@mui/material/Popper";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCaretDown,
    faCaretUp,
    faSearch,
} from "@fortawesome/free-solid-svg-icons";

// Styles
import {
    SearchableDropDownContainer,
    SearchableOptionsContainer,
} from "./SearchableDropDown.styles";

type Props = {
    options: Array<Option>;
    value: Option;
    setValue: Function;
    placeholder: string;
    error: boolean;
};

export default function SearchableDropDown({
    options,
    value,
    setValue,
    placeholder,
    error,
}: Props) {
    const [open, setOpen] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<any>(null);

    const [results, setResults] = useState<Array<Option>>(options || []);
    const [searchString, setSearchString] = useState<string>("");

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchString(event.target.value);
        setResults(
            options.filter((option) =>
                option.label
                    .toLowerCase()
                    .includes(event.target.value.toLowerCase())
            )
        );
    };

    const handleSelectOption = (option: Option) => {
        setOpen(false);
        setValue(option);
        setSearchString("");
    };

    const handleClick = (event: React.MouseEvent) => {
        if (!open) {
            setValue(false);
            setSearchString("");
            setResults(options || []);
            setAnchorEl(event.currentTarget);
            setOpen(true);
        }
    };

    const handleClickAway = (event: any) => {
        if (!anchorEl.contains(event.target)) {
            setOpen(false);
        }
    };

    return (
        <SearchableDropDownContainer>
            <div
                className={error ? "searchBox error" : "searchBox"}
                onClick={handleClick}
            >
                <input
                    placeholder={placeholder}
                    value={value ? value.label : searchString}
                    onChange={handleSearch}
                />
                <FontAwesomeIcon icon={faSearch} fixedWidth />
                {open ? (
                    <FontAwesomeIcon
                        icon={faCaretUp}
                        fixedWidth
                        onClick={() => setOpen(false)}
                    />
                ) : (
                    <FontAwesomeIcon icon={faCaretDown} fixedWidth />
                )}
            </div>

            <Popper
                open={open}
                anchorEl={anchorEl}
                className="dropDownMenu"
                disablePortal
            >
                <ClickAwayListener onClickAway={handleClickAway}>
                    <SearchableOptionsContainer>
                        {results.length > 0 ? (
                            results.map((option) => (
                                <div
                                    className="option"
                                    onClick={() => handleSelectOption(option)}
                                >
                                    <p>{option.label}</p>
                                </div>
                            ))
                        ) : (
                            <div className="noResults">
                                <p>No Results</p>
                            </div>
                        )}
                    </SearchableOptionsContainer>
                </ClickAwayListener>
            </Popper>
        </SearchableDropDownContainer>
    );
}
