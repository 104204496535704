import { useState, useContext, createContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

// MSAL
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

// Context
import { useSettings } from "./Settings.context";

// Utils
import { getPermissions, logout, saveUserTokens, setLastRoute, getScopes, setValidToken } from "utils/user.utils";
// import { getUsersDefaultHomePage } from "../utils/user.utils";

// Logger
import Logger from "utils/logger.utils";
const Log = new Logger();
Log.init("Auth.Context");

const AuthContext = createContext();

export function AuthProvider({ children }) {
    const history = useHistory();
    const { route, setShowLoadingScreen } = useSettings();
    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const [user, setUser] = useState(null);

    const loggedIn = async () => {
        Log.info("loggedIn()");

        let user = await refreshTokens();
        let permissions = await getPermissions();

        setUser({
            id: user.uniqueId,
            name: user.account.name,
            email: user.account.username,
            permissions: permissions,
        });

        if (route === "/login") {
            goToLastRoute(user.uniqueId, permissions);
        }

        localStorage.removeItem("logging-in");

        setShowLoadingScreen(false);
    };

    const refreshTokens = async () => {
        if (isAuthenticated) {
            Log.info("refreshTokens()");

            let res = setValidToken()

            return res;
        }
    };

    const checkAuth = () => {
        Log.info("checkAuth()");

        if (isAuthenticated) {
            loggedIn();
        } else {
            if (window.Cypress) { return }

            if (route !== "/login" && route !== "/logged-in") {
                let path = history.location.pathname + history.location.search;
                setLastRoute(path)
            }

            loggedOut();
        }
    };

    const loggedOut = () => {
        Log.info("loggedOut()");

        if (route !== "/login" && route !== "/logged-in") {
            history.push("/login");
            setShowLoadingScreen(false);
        } else if (
            route === "/login" &&
            !localStorage.getItem("logging-in")
        ) {
            setShowLoadingScreen(false);
        }
    };

    const goToLastRoute = (id, permissions) => {
        let lastRoute = JSON.parse(localStorage.getItem("lastRoute"));

        if (
            lastRoute &&
            lastRoute.userID === id &&
            lastRoute.route !== "/login"
        ) {
            history.push(lastRoute.route);
        } else {
            if (permissions.includes("p_list_extractions")) {
                history.push("/ada/snapshot");
                return;
            }
            if (permissions.includes("p_bqmq_can_view_results")) {
                history.push("/voting");
                return;
            }
            if (permissions.includes("p_can_view_fees")) {
                history.push("/fees/dublin?tab=global");
                return;
            }
        }
    };

    useEffect(() => {
        if (isAuthenticated) {
            checkAuth();
        }

        const timer = setTimeout(() => {
            checkAuth();
        }, 100);

        return () => clearTimeout(timer);
    }, [isAuthenticated]);

    useEffect(() => {
        refreshTokens();

        if (window.Cypress) {
            setShowLoadingScreen(false);
        }
    }, [route]);

    return (
        <AuthContext.Provider
            value={{
                user,
                setUser,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}

export const useAuth = () => {
    window.authContext = useContext(AuthContext);

    return useContext(AuthContext);
};
