import styled from 'styled-components';
import colors from "assets/styles/colors";

export default styled.div`
    .sectionTitle {
        font-size: 25px;
        letter-spacing: 0.1em;

        color: ${(props) => props.theme.pageTitleText};
        margin-bottom: 10px;
        margin-top: 20px;
    }

    .selectContainer {
        flex-direction: row;
        display: inline-block;
    }

    .select {
        width: 300px;
        display: inline;
        float: left;
    }

    .createBtn {
        margin-top: 20px;
        float: right;
    }

    .textFieldDiv {
        width: 300px;
        margin-top: 10px;
    }

    .inputContainerDiv {
        margin-top: 20px;
        max-width: 80vw;
    }

    .inputLabel {
        margin-bottom: 10px;
    }

    label {
        letter-spacing: 0.1em;
        color: ${colors.textNavy};
        margin-bottom: 20px;
    }

    .blankDiv {
        align-items: center;
        max-width: 250px;
        margin-top: 10px;
        min-width: 250px;
        height: 40px;
        display: flex;


        cursor: pointer;

        background-color: ${(props) => props.theme.filterButtonBackground};
        color: ${(props) => props.theme.filterButtonForeground};

        border-radius: 5px;
        overflow: hidden;
    }

    .blankSignOffDiv {
        align-items: center;
        max-width: 250px;
        min-width: 250px;
        height: 40px;
        display: flex;


        cursor: pointer;

        background-color: ${(props) => props.theme.filterButtonBackground};
        color: ${(props) => props.theme.filterButtonForeground};

        border-radius: 5px;
        overflow: hidden;
    }

    .loadingScreen {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
    }

    .type-label {
        padding: 4px 6px;
        border-radius: 5px;
        text-transform: uppercase;
    }

    .current {
        background-color: ${(props) => props.theme.labelSuccessBg};
        color: ${(props) => props.theme.labelSuccessColor};
    }

    .future {
        border: 1px solid  ${(props) => props.theme.labelSecondaryBg};
        color: ${(props) => props.theme.labelSecondaryBg};
    }

    .historic {
        background-color: ${(props) => props.theme.labelAlertBg};
        color: ${(props) => props.theme.labelAlertColor};
    }
`;
