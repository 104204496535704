import styled from "styled-components";

export default styled.div`
    .searchBox,
    .dateBox,
    .textField {
        max-width: unset;
    }
    .textArea {
        margin-bottom: -10px;
    }
`;
