import { useState } from "react";

// Styles
import DiscrepancyReportContainer from "./DiscrepancyReport.styles";

// Components
import TableCell from "components/Common/TableCell/TableCell";
import Table from "components/Common/Table/Table";
import SearchOptions from "components/Common/SearchOptions/SearchOptions";
import ButtonSmall from "components/Common/ButtonSmall/ButtonSmall";

// Font Awesomne
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCheckCircle,
    faDownload,
    faWarning,
} from "@fortawesome/free-solid-svg-icons";

// Context
import { useSettings } from "context/Settings.context";

// Utils
import { createLocalDownload } from "utils/export.utils";
import * as Database from "utils/database.utils";

interface DiscrepancyReportProps {
    url: string;
    reportId: string;
}

const DiscrepancyReport = ({ url, reportId }: DiscrepancyReportProps) => {
    const [filterQuery, setFilterQuery] = useState<string>("");
    const { setShowLoadingToast } = useSettings();
    const columns = [
        {
            id: 1,
            title: "PARTNER ID",
            sortable: true,
            value: "partner_id",
            cell: (data: any) => <TableCell data={data.partner_id} />,
            show: true,
            fullDataRow: true,
        },
        {
            id: 2,
            title: "QUARTER",
            sortable: true,
            value: "quarter",
            cell: (data: any) => (
                <TableCell
                    data={
                        data.quarter.slice(0, 4) +
                        " " +
                        data.quarter.slice(4, 6)
                    }
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 3,
            title: "DELOITTE CRYSTALLISATION AMOUNT",
            sortable: true,
            value: "deloitte_crystallisation_amount",
            cell: (data: any) => (
                <TableCell
                    data={parseInt(
                        data.deloitte_crystallisation_amount
                    ).toFixed(2)}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 4,
            title: "REPORT CRYSTALLISATION AMOUNT",
            sortable: true,
            value: "report_crystallisation_amount",
            cell: (data: any) => (
                <TableCell
                    data={parseInt(data.report_crystallisation_amount).toFixed(
                        2
                    )}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 5,
            title: "PF CRYSTALLISATION AMOUNT",
            sortable: true,
            value: "pf_crystallisation_delta",
            cell: (data: any) => (
                <TableCell
                    data={parseInt(data.pf_crystallisation_delta).toFixed(2)}
                />
            ),
            show: true,
            fullDataRow: true,
        },
    ];

    const filterOptions = [
        {
            id: 1,
            name: "Select Series",
            value: "series",
            type: "DROPDOWN",
            options: [
                { label: "Temp1", value: "TEST1" },
                { label: "Temp2", value: "TEST2" },
            ],
        },
        {
            id: 2,
            name: "Select Shareholder",
            value: "shareholder",
            type: "DROPDOWN",
            options: [
                { label: "Temp1", value: "TEST1" },
                { label: "Temp2", value: "TEST2" },
            ],
        },
    ];

    const handleDownload = async () => {
        setShowLoadingToast({
            show: true,
            message: "DOWNLOADING",
        });

        const res = await Database.get(url + "/download");
        const file = await res.blob();
        createLocalDownload(`${reportId} - Discrepancies.csv`, file);

        setShowLoadingToast({
            show: false,
            message: "",
        });
    };

    return (
        <DiscrepancyReportContainer>
            <div style={{ marginTop: 20 }}>
                <SearchOptions
                    setFilterQuery={setFilterQuery}
                    options={filterOptions}
                />
                <ButtonSmall
                    className="downloadBtn"
                    click={() => handleDownload()}
                    text={"DOWNLOAD"}
                    icon={faDownload}
                />
                <div className="tableDiv">
                    <Table
                        columns={columns}
                        url={url}
                        defaultLimit={25}
                        pagination
                        filterQuery={filterQuery}
                        defaultSortKey="partner_id"
                        defaultSortOrder="DESC"
                    />
                </div>
            </div>
        </DiscrepancyReportContainer>
    );
};
export default DiscrepancyReport;
