import React, { useEffect, useState } from "react";

// Context
import { useSettings } from "context/Settings.context";

// Styles
import EditDealVotesContainer from "./EditDealVotes.styles";

// Types
import {DealVote} from "assets/interfaces/growth_voting";

// Utils
import {getVoteName} from "utils/growth_voting.utils";


//Enums
import { EnumGrowthVotingVoteTypes } from "assets/enums/growth_voting";
import {Checkbox} from "@mui/material";


// Fontawsome
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
    votes: DealVote[]
    setVotes: React.Dispatch<React.SetStateAction<DealVote[]>>
}

const EditDealVotes  = ({votes, setVotes}: Props) => {
    const { setToast } = useSettings();
    // track the new votes
    const [newVotes, setNewVotes] = useState<string[]>([]);

    const isVoteExist = (voteType: string) => {
        let isIncluded = false;

        votes.forEach((vote: DealVote) => {
            if (vote.type === voteType) {
               isIncluded = true;
            }
        });

        return isIncluded;
    }

    const isNewVote = (voteType: string) => {
        let selected = false;

        newVotes.forEach((vote) => {
            if (vote === voteType) {
                selected = true;
            }
        });

        return selected;
    }

    const createVotes = (voteType: string) => {
        if (isVoteExist(voteType) && !isNewVote(voteType)) {
            setToast({message: "Existing vote cannot be removed.", type: "error", show: true});
            return;
        }

        // all the votes for the deals
        let createVotes: DealVote[] = votes.slice(0);

        if (isNewVote(voteType)) {
            // if it just selected, remove
            createVotes = createVotes.filter((vote: DealVote) => vote.type !== voteType);
            setNewVotes(newVotes.filter((vote) => vote !== voteType));
        } else {
            // create new vote
            createVotes.push({type: voteType, open: true});
            setNewVotes([...newVotes, voteType]);

        }

        setVotes(createVotes);
    }

    return (
        <EditDealVotesContainer>
            <div className={"create-votes"}>
                <div className={"vote bq-vote"}>
                    <Checkbox
                        readOnly={isVoteExist(EnumGrowthVotingVoteTypes.BQ)}
                        checked={isVoteExist(EnumGrowthVotingVoteTypes.BQ) || isNewVote(EnumGrowthVotingVoteTypes.BQ)}
                        disableRipple
                        onClick={() => createVotes(EnumGrowthVotingVoteTypes.BQ)}
                    />
                    <span>{getVoteName(EnumGrowthVotingVoteTypes.BQ)}</span>
                </div>
                <div className={"vote mq-vote"}>
                    <Checkbox
                        readOnly={isVoteExist(EnumGrowthVotingVoteTypes.MQ)}
                        checked={isVoteExist(EnumGrowthVotingVoteTypes.MQ) || isNewVote(EnumGrowthVotingVoteTypes.MQ)}
                        disableRipple
                        onClick={() => createVotes(EnumGrowthVotingVoteTypes.MQ)}
                    />
                    <span>{getVoteName(EnumGrowthVotingVoteTypes.MQ)}</span>
                </div>
                <div className={"vote sp-vote"}>
                    <Checkbox
                        readOnly={isVoteExist(EnumGrowthVotingVoteTypes.ST)}
                        checked={isVoteExist(EnumGrowthVotingVoteTypes.ST) || isNewVote(EnumGrowthVotingVoteTypes.ST)}
                        disableRipple
                        onClick={() => createVotes(EnumGrowthVotingVoteTypes.ST)}
                    />
                    <span>{getVoteName(EnumGrowthVotingVoteTypes.ST)}</span>
                </div>
            </div>
        </EditDealVotesContainer>
    );
};

export default EditDealVotes
