import styled from "styled-components";

export default styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    padding-left: 1rem;
    background: #eeeeee;
    border-radius: 5px;

    .radio-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;

        .radio-options {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }
    .text-field-wrapper {
        border: 2px solid #c4d6d1;
        border-radius: 4px;
        margin-bottom: 2rem;
    }
`;
