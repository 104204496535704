import colors from "assets/styles/colors";

// Component Themes
import menuTheme from "./menu.theme";
import buttonTheme from "./button.theme";
import tableTheme from "./table.theme";
import tabsTheme from "./tabs.theme";
import filterButtonTheme from "./filterButton.theme";
import profileTheme from "./profile.theme";
import modalTheme from "./modal.theme";
import homeListTheme from "./homeList.theme";
import bqmqTheme from "./bqmq.theme";
import adaHome from "./adaHome.theme";
import askAdaTheme from "./askAda.theme";
import labelTheme from "./label.theme";

export default {
    mainBackground: "black",

    textPrimary: "white",

    pageTitleText: "white",
    pageSubTitleText: "white",

    settingsMenuMainBackground: "black",
    settingsMenuHeaderBackground: "white",
    settingsMenuHeaderButtonBackground: "red",
    settingsMenuHeaderButtonBackgroundHover: "darkRed",
    settingsMenuHeaderText: "black",

    switchTrack: "white",
    switchChecked: "white",

    permissionsCheck: "white",
    permissionsAdd: "green",
    permissionsDelete: "red",

    ...menuTheme,
    ...buttonTheme,
    ...tableTheme,
    ...tabsTheme,
    ...filterButtonTheme,
    ...profileTheme,
    ...modalTheme,
    ...homeListTheme,
    ...bqmqTheme,
    ...adaHome,
    ...askAdaTheme,
    ...labelTheme,
};
