import styled from "styled-components";

export default styled.div`
    display: flex;
    flex-direction: column;

    .comment {
        display: flex;
        flex-direction: column;

        margin-bottom: 20px;

        .name {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            min-width: 400px;
            max-width: 400px;

            span {
                color: lightgray;
            }
        }

        .answer {
            display: flex;
            white-space: pre-line;
            padding: 10px;
            background-color: ${(props) => props.theme.filterButtonBackground};
            color: ${(props) => props.theme.filterButtonForeground};

            border-radius: 5px;

            width: fit-content;
        }
    }

    .noComments {
        display: flex;
        justify-content: center;

        width: 100%;

        margin: 30px 0 50px 0;
    }

    @media screen and (max-width: 900px) {
        .comment {
            flex-direction: column;

            .name {
                margin-bottom: 2px;
            }
        }
    }
`;
