import React, { Component } from "react";
import { Route } from "react-router-dom";

// Components
import ErrorBoundary from "components/Common/ErrorBoundary/ErrorBoundary";
import Can from "components/Common/Can/Can";
import NoAccess from "../NoAccess/NoAccess";

type Props = {
    exact: boolean;
    path: string;
    permissions: string[] | string;
    component: any;
};

export default function PrivateRoute({
    exact,
    path,
    permissions,
    component: Component,
}: Props): JSX.Element {
    return (
        <ErrorBoundary key={String(window.location)} goBack>
            <Route
                exact={exact}
                path={path}
                render={() => (
                    <Can
                        permissions={permissions}
                        yes={<Component />}
                        no={<NoAccess />}
                    />
                )}
            />
        </ErrorBoundary>
    );
}
