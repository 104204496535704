import { useHistory } from "react-router-dom";
import { applyTimeZoneOffset } from "utils/common.utils";

// Utils
import { toDate } from "utils/general.utils";

// MaterialUI
import { Tooltip } from "@mui/material";

// FontAwesome
import {
    faCalendar,
    faThumbsUp,
    faUser,
    faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Components
import ModesCell from "../../../Results/ModesCell/ModesCell";

// Data
import { teamLabelDict } from "assets/data/lists";

// Styles
import CompletedSessionRowContainer from "./CompletedSessionRow.styles";

// context
import { useBQMQ } from "context/BQMQ.context";

type Props = {
    data: BQMQSession;
};

export default function CompletedSessionRow({ data }: Props) {
    const history = useHistory();
    const { analysts } = useBQMQ();

    const presenter = analysts.find(
        (analyst: any) => analyst.value === data.presenter_id
    );
    const getTeam = () => {
        // @ts-ignore
        return teamLabelDict[data.company?.sector?.name.toUpperCase()];
    };
    const mode = { BQ: data.bq, MQ: data.mq };
    return (
        <CompletedSessionRowContainer
            onClick={() =>
                history.push(
                    `/voting/result/company?company_id=${data.company.id}&company_name=${data.company.name}&session_id=${data.id}`
                )
            }
        >
            <div className={"info"}>
                <div data-cy="recent-company" className={"name"}>
                    {data.company.name.toUpperCase()}
                </div>

                <div className={"details"}>
                    {presenter && (
                        <Tooltip title={<h2>ANALYST</h2>}>
                            <div className={"detail"}>
                                <FontAwesomeIcon icon={faUser} fixedWidth />
                                <p>{presenter.label}</p>
                            </div>
                        </Tooltip>
                    )}

                    {data.date && (
                        <Tooltip
                            title={
                                <h2>
                                    {"MEETING DATE: " +
                                        applyTimeZoneOffset(data.date)}
                                </h2>
                            }
                        >
                            <div className={"detail"}>
                                <FontAwesomeIcon icon={faCalendar} fixedWidth />
                                <p>{toDate(data.date)}</p>
                            </div>
                        </Tooltip>
                    )}

                    {data.company?.sector?.name && (
                        <Tooltip title={<h2>TEAM</h2>}>
                            <div className={"detail"}>
                                <FontAwesomeIcon icon={faUsers} fixedWidth />
                                <p className={"capitalize"}>{getTeam()}</p>
                            </div>
                        </Tooltip>
                    )}
                </div>
            </div>
            <div className="info end">
                <div className="details">
                    <Tooltip title={<h2>ATTENDEES VOTED</h2>}>
                        <div className={"detail"}>
                            <FontAwesomeIcon icon={faThumbsUp} fixedWidth />
                            <p className={"capitalize"}>{data.attended}</p>
                        </div>
                    </Tooltip>
                </div>
            </div>

            <ModesCell mode={mode} />
        </CompletedSessionRowContainer>
    );
}
