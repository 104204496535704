// Styles
import SummarySheetMoreInfoContainer from "./SummarySheetMoreInfo.styles";
import { Grid } from "@mui/material";

type Props = {
    summarySheetData: any;
};

export default function SummarySheetMoreInfo({ summarySheetData }: Props) {
    return (
        <SummarySheetMoreInfoContainer>
            <Grid container>
                <Grid item xs={3}>
                    <div className="info">
                        <label>FIXES ID</label>
                        <p>
                            {summarySheetData.fixes_id
                                ? summarySheetData.fixes_id
                                : "-"}
                        </p>
                    </div>
                </Grid>
                <Grid item xs={9}>
                    <div className="info">
                        <label>FOLDER PATH</label>
                        <p>{summarySheetData.folder_path}</p>
                    </div>
                </Grid>
            </Grid>
        </SummarySheetMoreInfoContainer>
    );
}
