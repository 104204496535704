import styled from "styled-components";

export default styled.div`
    @media screen and (max-width: 900px) {
        .columns {
            display: flex;
            flex-direction: column;

            .half {
                min-width: 100%;
                max-width: 100%;
            }
        }
    }
`;
