import styled from 'styled-components';

export default styled.div`
    .excelExportBtn {
        float: right;
        margin-top: -10px;
    }

    .adaTopContainer {
        display: flex;
    }
`;