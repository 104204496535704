import { useState } from "react";
import { useHistory } from "react-router-dom";

// Material UI
import Tooltip from "@mui/material/Tooltip";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faEye } from "@fortawesome/free-solid-svg-icons";

// Components
import PageTitle from "components/Common/PageTitle/PageTitle";
import SearchOptions from "components/Common/SearchOptions/SearchOptions";
import Table from "components/Common/Table/Table";
import PortfolioLiveCounts from "components/Screens/Data/PortfolioLive/PortfolioLiveCounts/PortfolioLiveCounts";
import TableCell from "components/Common/TableCell/TableCell";

// Utils
import { toDateAndTimeSeconds } from "utils/general.utils";

// Data
import { methodOptions } from "assets/data/lists";

// Styles
import PortfolioLiveListContainer from "./PortfolioLiveList.styles";

type Props = {
    apiUrl: string;
    region: string;
};

const PortfolioLive: React.FC<Props> = ({ region, apiUrl }) => {
    const history = useHistory();

    const [filterQuery, setFilterQuery] = useState<string>("");

    const columns = [
        {
            id: 1,
            title: "EXTRACTION DATE",
            sortable: true,
            value: "extraction_datetime",
            cell: (data: Date) => (
                <p className="capitalize">{toDateAndTimeSeconds(data)}</p>
            ),
            show: true,
        },
        {
            id: 2,
            title: "LAST MODIFIED",
            sortable: true,
            value: "last_modified_datetime",
            cell: (data: Date) => (
                <p className="capitalize">{toDateAndTimeSeconds(data)}</p>
            ),
            show: true,
        },
        {
            id: 3,
            title: "MODIFIED BY",
            sortable: true,
            value: "last_modified_by",
            cell: (data: string) => (
                <TableCell styleTypes="capitalize" data={data} />
            ),
            show: true,
        },

        {
            id: 4,
            title: "",
            sortable: false,
            value: "model_status",
            cell: (data: any) => (
                <PortfolioLiveCounts
                    portfolio={data.num_portfolio_companies}
                    plErrors={data.num_pl_sheet_errors}
                    plSortNewErrors={data.num_pl_sort_new_errors}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 5,
            title: "",
            sortable: false,
            value: "id",
            cell: (id: string) => (
                <Tooltip title={"VIEW"}>
                    <div className="viewIcon">
                        <FontAwesomeIcon
                            icon={faArrowRight}
                            fixedWidth
                            onClick={() =>
                                history.push(
                                    `/data/portfolio-live?id=${id}&region=${region}`
                                )
                            }
                        />
                    </div>
                </Tooltip>
            ),
            show: true,
        },
    ];

    const filterOptions = [
        { id: 1, name: "Extracted On", value: "date", type: "DATE" },
    ];

    return (
        <PortfolioLiveListContainer>
            <SearchOptions
                setFilterQuery={setFilterQuery}
                options={filterOptions}
            />

            <Table
                url={apiUrl}
                columns={columns}
                filterQuery={filterQuery}
                defaultSortKey="extraction_datetime"
                defaultSortOrder="DESC"
                pagination
                large
                emptyText="NO RESULTS"
            />
        </PortfolioLiveListContainer>
    );
};

export default PortfolioLive;
