import { useHistory } from "react-router-dom";

// FontAwesome
import {
    faHome,
    faPlus,
    faCog,
    faUsers,
    faList,
} from "@fortawesome/free-solid-svg-icons";

// Components
import SubMenuItem from "../../SubMenuItem/SubMenuItem";
import SubMenuTitle from "../../SubMenuTitle/SubMenuTitle";

// Styles
import ExtractionsContainer from "./BQMQ.styles";

export default function BQMQ() {
    const history = useHistory();

    return (
        <ExtractionsContainer className={"submenu"}>
            <div className={"top"}>
                <h1>BQ/MQ</h1>
                <hr />
                <SubMenuTitle text={"VOTING APP"} />
                <SubMenuItem
                    text={"Home"}
                    active={history.location.pathname === "/voting"}
                    icon={faHome}
                    click={() => history.push("/voting")}
                />
                <SubMenuItem
                    text={"Sessions"}
                    active={history.location.pathname === "/voting/sessions"}
                    icon={faUsers}
                    click={() => history.push("/voting/sessions")}
                    permissions={["p_bqmq_can_create_session"]}
                />
                <SubMenuItem
                    text={"Create New Session"}
                    active={
                        history.location.pathname === "/voting/create-session"
                    }
                    icon={faPlus}
                    click={() => history.push("/voting/create-session")}
                    permissions={["p_bqmq_can_create_session"]}
                />

                {/* PRIORITISATION */}
                <SubMenuTitle
                    text={"PRIORITISED VOTES"}
                    permissions={["p_can_action_fees", "p_can_view_fees"]}
                />
                <SubMenuItem
                    text={"Prioritised Votes"}
                    active={
                        history.location.pathname ===
                        "/voting/prioritised-votes"
                    }
                    icon={faList}
                    click={() => history.push("/voting/prioritised-votes")}
                    permissions={["p_bqmq_can_create_session"]}
                />

                {/*<SubMenuItem*/}
                {/*    text={"All Ratings"}*/}
                {/*    active={history.location.pathname.includes(*/}
                {/*        "/voting/company-results"*/}
                {/*    )}*/}
                {/*    icon={faChartColumn}*/}
                {/*    click={() => history.push("/voting/company-results")}*/}
                {/*    permissions={["p_bqmq_can_view_results"]}*/}
                {/*/>*/}

                {/*<SubMenuItem*/}
                {/*    text={"Export Data"}*/}
                {/*    active={history.location.pathname === "/voting/export"}*/}
                {/*    icon={faDownload}*/}
                {/*    click={() => history.push("/voting/export")}*/}
                {/*    permissions={["p_bqmq_can_export"]}*/}
                {/*/>*/}
            </div>

            <div className={"bottom"}>
                <SubMenuItem
                    text={"Settings"}
                    active={history.location.pathname === "/voting/settings"}
                    icon={faCog}
                    click={() => history.push("/voting/settings")}
                    permissions={[
                        "p_bqmq_can_create_session",
                        "p_bqmq_can_edit_session",
                    ]}
                />
            </div>
        </ExtractionsContainer>
    );
}
