// FontAwesome
import { faBan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Styles
import NoAccessContainer from "./NoAccess.styles";

export default function NoAccess() {
    return (
        <NoAccessContainer>
            <FontAwesomeIcon icon={faBan} fixedWidth />
            <p data-cy="no-access">NO ACCESS</p>
        </NoAccessContainer>
    );
}
