import { gbl_config_table } from "./gbl_config";
import { gbl_process_table } from "./gbl_process";
import { gbl_report_table } from "./gbl_report";
import { gbl_stage_table } from "./gbl_stage";

export const databaseSchema = [
    {
        text: "gbl_config",
        type: "database",
        value: "gbl_config",
        tables: gbl_config_table,
    },
    {
        text: "gbl_process",
        type: "database",
        value: "gbl_process",
        tables: gbl_process_table,
    },
    {
        text: "gbl_report",
        type: "database",
        value: "gbl_report",
        tables: gbl_report_table,
    },
    {
        text: "gbl_stage",
        type: "database",
        value: "gbl_stage",
        tables: gbl_stage_table,
    },
];
