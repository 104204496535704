import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// Context
import { useAuth } from "context/Auth.context";

// Styles
import TabsContainer from "./Tabs.styles";

type Props = {
    defaultTab: string;
    setTab?: Function;
    tabs: Array<Tab>;
    dontChangeUrl?: boolean;
};

type Tab = {
    title: string;
    value: string;
    content: React.ReactNode;
    permissions?: Array<string>;
    icon?: React.ReactNode;
    hide?: boolean;
};

export default function Tabs({
    defaultTab,
    setTab = () => {},
    tabs,
    dontChangeUrl = false,
}: Props) {
    const history = useHistory();
    const { user } = useAuth();
    const perms = user.permissions;

    const [filteredTabs, setFilteredTabs] = useState<Tab[]>([]);

    const [selectedTab, setSelectedTab] = useState<string>("");

    const filterTabs = () => {
        let filteredTabs: Tab[] = [];

        tabs.forEach((tab) => {
            if (!tab.hide) {
                if (!tab.permissions) {
                    filteredTabs.push(tab);
                } else if (
                    perms.some((perm: string) =>
                        tab.permissions!.includes(perm)
                    )
                ) {
                    filteredTabs.push(tab);
                }
            }
        });
        setFilteredTabs(filteredTabs);
    };

    useEffect(() => {
        filterTabs();
    }, [tabs]);

    useEffect(() => {
        setSelectedTab(defaultTab);
    }, [defaultTab]);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);

        let tab = urlParams.get("tab");

        setSelectedTab(tab ? tab : defaultTab);
    }, [history]);

    const setNewRoute = (value: string) => {
        if (!dontChangeUrl) {
            const urlParams = new URLSearchParams(window.location.search);

            urlParams.set("tab", value);
            urlParams.delete("subTab");
            let url = history.location.pathname + "?" + urlParams.toString();

            history.push(url);
        }

        setSelectedTab(value);
        setTab(value);
    };

    return (
        <TabsContainer className="tabs">
            <div className={"tabsBar"}>
                {filteredTabs.map((item, index) => (
                    <div
                        data-cy="tab-item"
                        className={
                            selectedTab === item.value
                                ? "active tabItem"
                                : "tabItem"
                        }
                        key={index}
                        onClick={() => setNewRoute(item.value)}
                    >
                        <p>{item.title}</p>
                        {item.icon && item.icon}
                    </div>
                ))}

                <div className={"spacer"}></div>
            </div>
            <div className="tabsContent">
                {selectedTab &&
                    tabs.find((tab: Tab) => tab.value === selectedTab)!.content}
            </div>
        </TabsContainer>
    );
}
