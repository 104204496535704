// Context
import { useAuth } from "../../../../context/Auth.context";
import { useSettings } from "context/Settings.context";

// Components
import MenuItem from "../MenuItem/MenuItem";

// Styles
import { ProfileButtonContainer } from "./ProfileButton.styles";

export default function ProfileButton() {
    const { route, setShowSettingsMenu } = useSettings();
    const { user } = useAuth();

    return (
        <ProfileButtonContainer>
            <MenuItem
                id={"profileMenuBtn"}
                isActive={route.includes("/user/profile3242")}
                click={() => setShowSettingsMenu(true)}
                text={""}
                icon={
                    <div className={"avatar"} id={"avatar"}>
                        <p>{user?.name[0]}</p>
                    </div>
                }
                permissions={[]}
            />
        </ProfileButtonContainer>
    );
}
