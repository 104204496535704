export default {
    adaHomeBackground: "#003331",
    adaHomeDividerShadow:
        "inset 2px 2px 3px #002321,inset -2px -2px 3px #004341;",
    adaHomeDividerBackground: "#003331",
    cardBorder: "1px solid #003331",
    cardBackground: "linear-gradient(145deg, #002e2c, #003734);",
    cardBoxShadow: "5px 5px 10px #002321, -5px -5px 10px #004341;",
    cardBoxShadowHover: "9px 9px 18px #002321,-9px -9px 18px #004341;",
};
