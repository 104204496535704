import styled from "styled-components";

export default styled.div`
    .sectionTitle {
        font-size: 25px;
        letter-spacing: 0.1em;

        color: ${(props) => props.theme.pageTitleText};
        margin-bottom: 40px;
        margin-top: 20px;
    }

    .green {
        color: ${(props) => props.theme.permissionsAdd};
    }

    .red {
        color: ${(props) => props.theme.permissionsDelete};
    }

    .viewIcon {
        cursor: pointer;

        svg {
            color: ${(props) => props.theme.tableIconHighlight};
            font-size: 20px;
        }
    }

    .tableDiv {
        clear: right;
    }



    .options {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        & > * {
            margin-bottom: 15px;
        }
    }
`;
