import styled from "styled-components";

export default styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    position: fixed;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;

    background-color: ${(props) => props.theme.modalBackground};
    backdrop-filter: ${(props) => props.theme.modalBackgroundFilter};

    z-index: 150;

    .menuModal {
        display: flex;
        flex-direction: column;

        min-width: 300px;
        max-width: 300px;
        min-height: 415px;
        max-height: 415px;

        background-color: ${(props) => props.theme.settingsMenuMainBackground};

        border-radius: 5px;

        box-shadow: 0px 0px 20px 9px rgba(0, 0, 0, 0.1);

        .headerBar {
            display: flex;

            min-width: 100%;
            max-width: 100%;
            min-height: 60px;
            max-height: 60px;

            background-color: ${(props) =>
                props.theme.settingsMenuHeaderBackground};

            border-radius: 5px 5px 0 0;
            overflow: hidden;

            color: ${(props) => props.theme.settingsMenuHeaderText};

            .toggleBtn {
                display: flex;
                justify-content: center;
                align-items: center;

                min-height: 60px;
                max-height: 60px;

                font-size: 30px;

                background-color: ${(props) =>
                    props.theme.settingsMenuHeaderButtonBackground};

                overflow: hidden;

                cursor: pointer;

                :hover {
                    background-color: ${(props) =>
                        props.theme.settingsMenuHeaderButtonBackgroundHover};
                }

                &.left {
                    min-width: 0;
                    max-width: 0;
                }

                &.right {
                    min-width: 60px;
                    max-width: 60px;
                }

                &.active {
                    animation: hideButton 100ms ease-in-out both;
                }

                &.inactive {
                    animation: showButton 100ms ease-in-out both;
                }
            }

            .pageTitle {
                display: flex;
                flex-grow: 1;
                justify-content: center;
                align-items: center;

                min-height: 60px;
                max-height: 60px;

                font-size: 35px;
                letter-spacing: 2px;

                user-select: none;
            }
        }
    }

    .clickToClose {
        position: absolute;
        bottom: 10px;

        color: ${(props) => props.theme.pageSubTitleText};

        letter-spacing: 0.3em;
        font-size: 10px;
    }

    @keyframes hideButton {
        0% {
            min-width: 60px;
            max-width: 60px;
        }
        100% {
            min-width: 0px;
            max-width: 0px;
        }
    }

    @keyframes showButton {
        0% {
            min-width: 0px;
            max-width: 0px;
        }
        100% {
            min-width: 60px;
            max-width: 60px;
        }
    }
`;
