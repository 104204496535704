import colors from "assets/styles/colors";

export default {
    avatarBackground: colors.textNavy,
    avatarText: "white",
    nameText: colors.textNavy,

    logoutButtonBackground: colors.textNavy,
    logoutButtonText: "white",
    logoutButtonBackgroundHover: colors.textNavyDark,
};
