import React, { useEffect, useState } from "react";

// Styles
import ScenarioDetailContainer from "./ScenarioDetail.styles";

// Context
import { useSettings } from "context/Settings.context";

// Router
import { useHistory } from "react-router-dom";

// Types
import { scenarioInputs } from "../../ScenarioSelectionTab/types";

// Utils
import * as Database from "utils/database.utils";
import { toDateAndTime } from "utils/general.utils";

// Components
import PageTitle from "components/Common/PageTitle/PageTitle";
import Button from "components/Common/Button/Button";
import ScenarioInputFields from "../../ScenarioInputFields/ScenarioInputFields";
import Can from "components/Common/Can/Can";

// Font Awesome
import {
    faArrowAltCircleLeft,
    faEye,
    faRefresh,
} from "@fortawesome/free-solid-svg-icons";

// Material UI
import { Grid } from "@mui/material";

interface ScenarioDetailProps {}

const ScenarioDetail = ({}: ScenarioDetailProps) => {
    const history = useHistory();
    const { setShowLoadingToast, setToast } = useSettings();

    const [scenarioData, setScenarioData] = useState<any>({});
    const [blockRun, setBlockRun] = useState(false);

    const getScenario = async (scenarioId: string) => {
        setShowLoadingToast({ show: true, message: "LOADING" });
        let res = await Database.get(
            `api/fees/scenarios/${encodeURIComponent(scenarioId)}`
        );
        if (res.ok) {
            let data = await res.json();
            setScenarioData(data);
        }
        setShowLoadingToast({ show: false, message: "" });
    };

    const getID = (): string => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        return urlParams.get("id") || "";
    };

    useEffect(() => {
        getScenario(getID());
    }, []);

    const handleChange = (value: any, name: string) => {
        setScenarioData({
            ...scenarioData,
            [name]: value,
        });
    };

    const updateScenario = async (scenarioId: string) => {
        setShowLoadingToast({ show: true, message: "UPDATING" });

        // parse all values to floats,
        // just to be sure no strings are passed
        scenarioInputs.map((scenario) => {
            for (let i = 1; i <= 12; i++) {
                scenarioData[scenario.name + "_q" + i] = parseFloat(
                    scenarioData[scenario.name + "_q" + i]
                );
            }
        });

        // remove following properties from obj
        // as they do not need to be passed to update api
        const {
            scenario_id,
            owner,
            owner_id,
            scenario_created_date,
            ...parsedValuesObj
        } = scenarioData;

        let res = await Database.patch(
            `api/fees/scenarios/${scenarioId}`,
            parsedValuesObj
        );
        if (res.ok) {
            setToast({
                message: "Scenario updated",
                type: "success",
                show: true,
            });

            getScenario(getID());
        }

        setShowLoadingToast({ show: false, message: "" });
    };

    const runScenario = async (scenarioId: string) => {
        setShowLoadingToast({
            show: true,
            message: "RUNNING",
        });
        setBlockRun(true);
        let res = await Database.post(
            `api/fees/generation/forecasts/${scenarioId}`
        );
        if (res.status === 200 || 201) {
            setToast({
                show: true,
                message: "Scenario run has been successful",
                type: "success",
            });
            setShowLoadingToast({
                show: false,
                message: "",
            });
            setBlockRun(false);
        }
    };

    return (
        <ScenarioDetailContainer>
            <PageTitle
                title={
                    scenarioData.scenario_id
                        ? `FEES SCENARIO - ${scenarioData.scenario_id}`
                        : "LOADING"
                }
                actions={
                    <Button
                        icon={faArrowAltCircleLeft}
                        text="Back to all scenario overview"
                        click={() =>
                            history.push(`/fees/forecasts?tab=all_scenarios`)
                        }
                    />
                }
            />

            <Grid container className="metadata">
                <Grid item xs={6} md={3} className="topGrid">
                    <div className="info">
                        <label>CREATED DATE</label>
                        <p>
                            {scenarioData.scenario_created_date
                                ? toDateAndTime(
                                      scenarioData.scenario_created_date
                                  )
                                : "LOADING"}
                        </p>
                    </div>

                    <div className="info">
                        <label>CREATED BY</label>
                        <p>
                            {scenarioData.owner
                                ? scenarioData.owner
                                : "LOADING"}
                        </p>
                    </div>
                </Grid>
            </Grid>

            <p className="title">UPDATE SCENARIO VALUES:</p>

            <Can
                yes={
                    <div className="textFieldDiv">
                        {scenarioData && (
                            <ScenarioInputFields
                                readOnly={false}
                                handleChange={handleChange}
                                newScenarioValues={scenarioData}
                            />
                        )}
                    </div>
                }
                no={
                    <div className="textFieldDiv">
                        {scenarioData && (
                            <ScenarioInputFields
                                readOnly={true}
                                handleChange={handleChange}
                                newScenarioValues={scenarioData}
                            />
                        )}
                    </div>
                }
                permissions={["p_can_action_fees"]}
            />

            <div className="btnDiv">
                <Can
                    yes={
                        <Button
                            className="updateBtn"
                            text={"UPDATE"}
                            icon={faRefresh}
                            click={() => updateScenario(getID())}
                        />
                    }
                    permissions={["p_can_action_fees"]}
                />

                <Button
                    className="updateBtn"
                    text={"RUN AND VIEW"}
                    icon={faEye}
                    disabled={blockRun}
                    click={() => runScenario(getID())}
                />
            </div>
        </ScenarioDetailContainer>
    );
};
export default ScenarioDetail;
