import React from "react";

// Styles
import GEFSectorsViewContainer from "./GEFSectorsView.styles";

interface GEFSectorsViewProps {}

const GEFSectorsView = ({}: GEFSectorsViewProps) => {
    return (
        <GEFSectorsViewContainer>
            <div className="iframeContainer">
                <iframe
                    title="Sector Scorecards vLive (QS)"
                    src="https://app.powerbi.com/reportEmbed?reportId=2eec0436-c4cd-4fce-9a2b-3f7faa493025&autoAuth=true&ctid=bf4450a2-bfcb-472d-8c40-1425eafb1a3b&filterPaneEnabled=false"
                    width="100%"
                    height="100%"
                    allowFullScreen
                    frameBorder={0}
                />
            </div>
        </GEFSectorsViewContainer>
    );
};
export default GEFSectorsView;
