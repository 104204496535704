import colors from "assets/styles/colors";

export default {
    bqmqVoteScore1: "#A4BD9B",
    bqmqVoteScore2: "#E1EEDB",
    bqmqVoteScore3: "#FFF2CC",
    bqmqVoteScore4: "#FCE4D7",
    bqmqVoteScore5: "#F9CCAD",

    chartAxesColor: "white",
    chartGridColor: "#666666",
    chartLabelColor: "white",

    dateOutOfRange: "#777",

    votingCellBorder: colors.darkThemeLight,
    votingCellText: colors.darkThemeLight,
    votingCellCommentText: "white",
    votingCellBackground: colors.darkThemeMain,
};
