import styled from 'styled-components';

export default styled.div`
    .search {
        padding: 0px 30px;
        padding-bottom: 10px;
    }
    .users {
        border-top: 1px solid ${(props) => props.theme.homeListBodyBorderColor};
        height:300px;
        overflow:auto;
        padding-top: 10px;
        padding-left: 20px;
        .user {
            display: flex;
            align-items: center;
            justify-content: left;
        }
    }

`;
