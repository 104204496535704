import colors from "assets/styles/colors";

export default {
    primaryButtonBackground: colors.darkThemeLight,
    primaryButtonForeground: "white",
    primaryButtonBackgroundHover: colors.darkThemeLighter,

    secondaryButtonBackground: colors.teal80,
    secondaryButtonForeground: "white",
    secondaryButtonBackgroundHover: colors.teal100,

    thirdButtonBackground: colors.grey90,
    thirdButtonForeground: colors.grey40,
    thirdButtonBackgroundHover: colors.grey80,

    confirmButtonBackground: colors.fillGreen,
    confirmButtonBackgroundHover: colors.accentGreen,
    confirmButtonBackgroundForeground: colors.grey40,

    accentButtonBackground: colors.accentRed,
    accentButtonForeground: "white",
    accentButtonBackgroundHover: colors.accentRedDark,

    flatButtonBackground: "unset",
    flatButtonForeground: "white",
    flatButtonBackgroundHover: colors.grey30,

    squareButtonBackground: colors.darkThemeMain,
    squareButtonText: "white",
    squareButtonIcon: "white",
    squareButtonBackgroundHover: colors.darkThemeLight,
    squareButtonCount: colors.accentRed,
    squareButtonBorder: "3px solid transparent",
    squareButtonBorderActive: colors.darkThemeLighter,
    squaredButtonFailed: colors.accentRed,
    squaredButtonHolding: colors.accentOrange,
    squaredButtonSuccess: "white",
};
