export const gbl_report_table = [
    {
        text: "model_metrics",
        type: "table",
        value: "model_metrics",
        fields: [
            {
                field: "extraction_id",
                type: "String(36)",
                primaryKey: true,
                nullable: false,
            },
            {
                field: "company",
                type: "String(50)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "company_extract_group",
                type: "String(60)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "extraction_datetime",
                type: "DateTime",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "include_model_flag",
                type: "SmallInteger",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "current_model_flag",
                type: "SmallInteger",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "include_metric_flag",
                type: "SmallInteger",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "model_h1_start_date",
                type: "Date",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "model_h1_end_date",
                type: "Date",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "model_h1_year",
                type: "Integer",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "model_tab",
                type: "String(10)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "metric",
                type: "String(40)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "metric_id",
                type: "String(51)",
                primaryKey: true,
                nullable: false,
            },
            {
                field: "metric_name_db",
                type: "String(50)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "metric_root",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "metric_root_name_db",
                type: "String(50)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "metric_year_classification",
                type: "String(1))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "metric_year_notation",
                type: "String(6)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "metric_year_diff",
                type: "Integer",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "metric_start_date",
                type: "Date",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "metric_end_date",
                type: "Date",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "metric_year",
                type: "Integer",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "reporting_currency",
                type: "String(10)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "currency_differs_flag",
                type: "SmallInteger",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "value",
                type: "String(30000))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "value_numeric",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "value_text",
                type: "String(30000))",
                primaryKey: false,
                nullable: true,
            },
        ],
    },
    {
        text: "model_metrics_archive",
        type: "table",
        value: "model_metrics_archive",
        fields: [
            {
                field: "extraction_id",
                type: "String(36)",
                primaryKey: true,
                nullable: false,
            },
            {
                field: "company",
                type: "String(50)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "company_extract_group",
                type: "String(60)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "extraction_datetime",
                type: "DateTime",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "include_model_flag",
                type: "SmallInteger",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "current_model_flag",
                type: "SmallInteger",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "include_metric_flag",
                type: "SmallInteger",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "model_h1_start_date",
                type: "Date",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "model_h1_end_date",
                type: "Date",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "model_h1_year",
                type: "Integer",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "model_tab",
                type: "String(10)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "metric",
                type: "String(40)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "metric_id",
                type: "String(51)",
                primaryKey: true,
                nullable: false,
            },
            {
                field: "metric_name_db",
                type: "String(50)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "metric_root",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "metric_root_name_db",
                type: "String(50)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "metric_year_classification",
                type: "String(1))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "metric_year_notation",
                type: "String(6)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "metric_year_diff",
                type: "Integer",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "metric_start_date",
                type: "Date",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "metric_end_date",
                type: "Date",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "metric_year",
                type: "Integer",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "reporting_currency",
                type: "String(10)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "currency_differs_flag",
                type: "SmallInteger",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "value",
                type: "String(30000))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "value_numeric",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "value_text",
                type: "String(30000))",
                primaryKey: false,
                nullable: true,
            },
        ],
    },
    {
        text: "model_metrics_time_evol",
        type: "table",
        value: "model_metrics_time_evol",
        fields: [
            {
                field: "extraction_id",
                type: "String(36)",
                primaryKey: true,
                nullable: false,
            },
            {
                field: "include_model_flag",
                type: "SmallInteger",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "current_model_flag",
                type: "SmallInteger",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "company",
                type: "String(50)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "extraction_datetime",
                type: "DateTime",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "extraction_date",
                type: "Date",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "model_h1_start_date",
                type: "Date",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "model_h1_end_date",
                type: "Date",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "model_h1_year",
                type: "Integer",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "metric_year_classification",
                type: "String(1))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "metric_year_notation",
                type: "String(6)",
                primaryKey: true,
                nullable: false,
            },
            {
                field: "metric_year_diff",
                type: "Integer",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "metric_start_date",
                type: "Date",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "metric_end_date",
                type: "Date",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "metric_year",
                type: "Integer",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "_ac_cashdisc",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_adjusted_peers_fade",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peers_cfroi_hist_median_high",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peers_cfroi_hist_median_low",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peers_cfroi_hist_median_med",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peers_cfroi_lfy_high",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peers_cfroi_lfy_low",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peers_cfroi_lfy_med",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "asset_turnover",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "associates_per_share",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "basic_shares_year_av",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "basic_shares_year_end",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "business_quality_score",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "cap_period_years",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capex_depreciation",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capex_incl_intangibles",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capitalised_rd",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "cash_perc_market_cap",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ce_discounted",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ce_growth_terminal_t15",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "change_in_working_capital",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "company_adj_cfroi_annualised_t5_t15",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "company_adjusted_cfroi",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "company_fade",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "company_fade_annualised",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "country_rev_holding_perc_aud",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "country_rev_holding_perc_brl",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "country_rev_holding_perc_cad",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "country_rev_holding_perc_ejpy",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "country_rev_holding_perc_eur",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "country_rev_holding_perc_gbp",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "country_rev_holding_perc_usd",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "country_rev_holding_perc_xtra1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "country_rev_holding_perc_xtra2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "currency_multiplier_reporting",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "debt_to_op_capital_t15",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "depreciation_incl_amortize_intangibles",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "discount_rate",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "dividend_yield",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "dps_paid",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "dps_paid_growth",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_add_back_capex_less_depr",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_margin",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_margin_t5_bear",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_margin_t5_bull",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_value",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebita_margin",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebitda_margin",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "enterprise_cash",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "eps_street",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "eps_street_h1_high",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "eps_street_h1_low",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "eps_street_scen",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "eps_street_t1_high",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "eps_street_t2_high",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "eps_street_t2_low",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ev",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ev_change_net_debt_t1_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ev_div_capital_employed",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ev_div_ebitda",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ev_div_nopat",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ev_div_sales",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "eva_fade",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "eva_margin",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "exit_multiple",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "exit_multiple_bear",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "exit_multiple_bull",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "exit_t1_evnopat",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "exit_t2_evnopat",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "exit_trail_evnopat",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fade",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fair_value",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fair_value_bear",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fair_value_bull",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fair_value_range_of_outcomes",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fair_value_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fair_value_upside",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_cash_conv_ratio",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_equity_per_share",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_equity_per_share_cagr",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_per_share",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "financial_year_end_date",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "free_cash_flow",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fv_attribution_change_in_margin",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fv_attribution_change_in_net_debt",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fv_attribution_dividends_t1_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fv_attribution_other_pandl",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fv_attribution_re_rating",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fv_attribution_residual",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fv_attribution_sales_growth",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fy_end_year",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_ce",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_ce_per_share",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_ce_per_share_t1_t5_cagr",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_cfroi",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_eps",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_eps_cagr",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_eps_t5_bear",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_eps_t5_bull",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_fade_model",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_fade_of_spread",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "generation_eps",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "generation_eps_growth",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "goodwill_and_intangibles",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gross_debt_ex_npo",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gross_margin",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "implied_ev_future_proj",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "implied_nopat_growth",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "implied_p_e",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "industry_fade",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "industry_type_switch",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "inflation",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ke",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ke_wacc_ce_growth_t15",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ke_wacc_financials",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ke_wacc_perc_market_cap",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ke_wacc_range_of_outcomes",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ke_wacc_strd_debt_rate",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "less_equity_minorities_per_share",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "less_gross_debt_per_share",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "less_plus_other_correction",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "less_value_options_rsus_per_share",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "management_quality_score",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "market_cap",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "market_ev_per_share",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "model_using_eva_valudation_flag",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "model_v_street",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_cashflow_yield_equity",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_debt_cash",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_debt_div_ebitda",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_debt_perc_ev",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_ebitda",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_p_pe",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "netpenob",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "netpenob_per_share",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "nonop_share",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "nopat",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "nopat_st15_bull",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "nopat_t15_bear",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "norm_baseyr",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "normalised_organic_constant_currency_growth",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "normalised_sales_organic_cc_growth_to_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_asset_turnover",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_growth_cap",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_growth_cap_period",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_growth_cap_period_bear",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_growth_cap_period_bull",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_per_share",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_per_share_cagr_t1_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_per_share_growth",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_fade_of_spread",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_fade_of_spread_bear",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_fade_of_spread_bull",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_fcf_equity",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_fcf_per_share",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_free_cash_flow",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_roce",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_roce_bear",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_roce_t15_bull",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_roce_t5_bull",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "operation_real_sales",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "oproce_growth_h1_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "oproce_growth_t5_t15",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "other_costs",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "payout_ratio",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "perc_company_adjusted_cfroi_t15",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "perc_discounted_divs_t1_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "perc_future_ce_t40",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "pv_dividends_per_share",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "pv_per_share",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "pv_terminal_value_per_share_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "region_rev_mix_asia",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "region_rev_mix_asia_ex_japan",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "region_rev_mix_europe",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "region_rev_mix_japan",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "region_rev_mix_north_america",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "region_rev_mix_other",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "reported_eps_net_income",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "revenue_growth_cap",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "revenue_growth_h5_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "roce",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "roce_ke",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_growth",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_growth_cagr",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_growth_cagr_bear",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_growth_cagr_bull",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_growth_cap",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_growth_h1_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_growth_h5_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_org_cc_growth",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "section_median_cfroi_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sector_cfroi_standard_deviation_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "share_price",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sum_percent_cashflow_times_dcf_t1_t80",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "tax_adjusted_net_interest",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "tax_rate",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "time_adjusted_fair_value",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "time_adjusted_fair_value_bear",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "time_adjusted_fair_value_bull",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "time_adjusted_today_date",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "time_adjusted_upside",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "time_adjusted_upside_bear",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "time_adjusted_upside_bull",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "working_capital_asset_less_liab",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
        ],
    },
    {
        text: "model_metrics_time_evol_archive",
        type: "table",
        value: "model_metrics_time_evol_archive",
        fields: [
            {
                field: "extraction_id",
                type: "String(36)",
                primaryKey: true,
                nullable: false,
            },
            {
                field: "include_model_flag",
                type: "SmallInteger",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "current_model_flag",
                type: "SmallInteger",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "company",
                type: "String(50)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "extraction_datetime",
                type: "DateTime",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "extraction_date",
                type: "Date",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "model_h1_start_date",
                type: "Date",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "model_h1_end_date",
                type: "Date",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "model_h1_year",
                type: "Integer",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "metric_year_classification",
                type: "String(1))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "metric_year_notation",
                type: "String(6)",
                primaryKey: true,
                nullable: false,
            },
            {
                field: "metric_year_diff",
                type: "Integer",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "metric_start_date",
                type: "Date",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "metric_end_date",
                type: "Date",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "metric_year",
                type: "Integer",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "_ac_cashdisc",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_adjusted_peers_fade",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peers_cfroi_hist_median_high",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peers_cfroi_hist_median_low",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peers_cfroi_hist_median_med",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peers_cfroi_lfy_high",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peers_cfroi_lfy_low",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peers_cfroi_lfy_med",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "asset_turnover",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "associates_per_share",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "basic_shares_year_av",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "basic_shares_year_end",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "business_quality_score",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "cap_period_years",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capex_depreciation",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capex_incl_intangibles",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capitalised_rd",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "cash_perc_market_cap",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ce_discounted",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ce_growth_terminal_t15",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "change_in_working_capital",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "company_adj_cfroi_annualised_t5_t15",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "company_adjusted_cfroi",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "company_fade",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "company_fade_annualised",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "country_rev_holding_perc_aud",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "country_rev_holding_perc_brl",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "country_rev_holding_perc_cad",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "country_rev_holding_perc_ejpy",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "country_rev_holding_perc_eur",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "country_rev_holding_perc_gbp",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "country_rev_holding_perc_usd",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "country_rev_holding_perc_xtra1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "country_rev_holding_perc_xtra2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "currency_multiplier_reporting",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "debt_to_op_capital_t15",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "depreciation_incl_amortize_intangibles",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "discount_rate",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "dividend_yield",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "dps_paid",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "dps_paid_growth",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_add_back_capex_less_depr",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_margin",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_margin_t5_bear",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_margin_t5_bull",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_value",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebita_margin",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebitda_margin",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "enterprise_cash",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "eps_street",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "eps_street_h1_high",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "eps_street_h1_low",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "eps_street_scen",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "eps_street_t1_high",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "eps_street_t2_high",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "eps_street_t2_low",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ev",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ev_change_net_debt_t1_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ev_div_capital_employed",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ev_div_ebitda",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ev_div_nopat",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ev_div_sales",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "eva_fade",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "eva_margin",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "exit_multiple",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "exit_multiple_bear",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "exit_multiple_bull",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "exit_t1_evnopat",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "exit_t2_evnopat",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "exit_trail_evnopat",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fade",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fair_value",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fair_value_bear",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fair_value_bull",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fair_value_range_of_outcomes",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fair_value_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fair_value_upside",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_cash_conv_ratio",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_equity_per_share",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_equity_per_share_cagr",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_per_share",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "financial_year_end_date",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "free_cash_flow",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fv_attribution_change_in_margin",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fv_attribution_change_in_net_debt",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fv_attribution_dividends_t1_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fv_attribution_other_pandl",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fv_attribution_re_rating",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fv_attribution_residual",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fv_attribution_sales_growth",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fy_end_year",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_ce",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_ce_per_share",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_ce_per_share_t1_t5_cagr",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_cfroi",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_eps",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_eps_cagr",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_eps_t5_bear",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_eps_t5_bull",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_fade_model",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_fade_of_spread",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "generation_eps",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "generation_eps_growth",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "goodwill_and_intangibles",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gross_debt_ex_npo",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gross_margin",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "implied_ev_future_proj",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "implied_nopat_growth",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "implied_p_e",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "industry_fade",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "industry_type_switch",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "inflation",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ke",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ke_wacc_ce_growth_t15",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ke_wacc_financials",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ke_wacc_perc_market_cap",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ke_wacc_range_of_outcomes",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ke_wacc_strd_debt_rate",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "less_equity_minorities_per_share",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "less_gross_debt_per_share",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "less_plus_other_correction",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "less_value_options_rsus_per_share",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "management_quality_score",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "market_cap",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "market_ev_per_share",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "model_using_eva_valudation_flag",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "model_v_street",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_cashflow_yield_equity",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_debt_cash",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_debt_div_ebitda",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_debt_perc_ev",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_ebitda",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_p_pe",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "netpenob",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "netpenob_per_share",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "nonop_share",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "nopat",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "nopat_st15_bull",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "nopat_t15_bear",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "norm_baseyr",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "normalised_organic_constant_currency_growth",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "normalised_sales_organic_cc_growth_to_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_asset_turnover",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_growth_cap",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_growth_cap_period",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_growth_cap_period_bear",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_growth_cap_period_bull",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_per_share",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_per_share_cagr_t1_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_per_share_growth",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_fade_of_spread",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_fade_of_spread_bear",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_fade_of_spread_bull",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_fcf_equity",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_fcf_per_share",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_free_cash_flow",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_roce",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_roce_bear",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_roce_t15_bull",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_roce_t5_bull",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "operation_real_sales",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "oproce_growth_h1_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "oproce_growth_t5_t15",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "other_costs",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "payout_ratio",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "perc_company_adjusted_cfroi_t15",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "perc_discounted_divs_t1_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "perc_future_ce_t40",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "pv_dividends_per_share",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "pv_per_share",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "pv_terminal_value_per_share_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "region_rev_mix_asia",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "region_rev_mix_asia_ex_japan",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "region_rev_mix_europe",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "region_rev_mix_japan",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "region_rev_mix_north_america",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "region_rev_mix_other",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "reported_eps_net_income",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "revenue_growth_cap",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "revenue_growth_h5_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "roce",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "roce_ke",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_growth",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_growth_cagr",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_growth_cagr_bear",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_growth_cagr_bull",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_growth_cap",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_growth_h1_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_growth_h5_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_org_cc_growth",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "section_median_cfroi_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sector_cfroi_standard_deviation_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "share_price",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sum_percent_cashflow_times_dcf_t1_t80",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "tax_adjusted_net_interest",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "tax_rate",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "time_adjusted_fair_value",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "time_adjusted_fair_value_bear",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "time_adjusted_fair_value_bull",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "time_adjusted_today_date",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "time_adjusted_upside",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "time_adjusted_upside_bear",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "time_adjusted_upside_bull",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "working_capital_asset_less_liab",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
        ],
    },
    {
        text: "model_metrics_wide",
        type: "table",
        value: "model_metrics_wide",
        fields: [
            {
                field: "extraction_id",
                type: "String(36)",
                primaryKey: true,
                nullable: true,
            },
            {
                field: "include_model_flag",
                type: "SmallInteger",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "current_model_flag",
                type: "SmallInteger",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "company",
                type: "String(50)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "company_extract_group",
                type: "String(60)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "extraction_datetime",
                type: "DateTime",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "extraction_date",
                type: "Date",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "model_h1_start_date",
                type: "Date",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "model_h1_end_date",
                type: "Date",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "model_h1_year",
                type: "Integer",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "additional_1",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "additional_2",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "additional_3",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "additional_4",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_adjusted_peers_fade",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_1",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_10",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_11",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_12",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_13",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_14",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_15",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_16",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_17",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_18",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_19",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_2",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_20",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_21",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_22",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_23",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_24",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_25",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_26",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_27",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_28",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_29",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_3",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_30",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_31",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_32",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_33",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_34",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_35",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_36",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_37",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_38",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_39",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_4",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_40",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_41",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_42",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_43",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_44",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_45",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_46",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_47",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_48",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_49",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_5",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_50",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_51",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_52",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_53",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_54",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_55",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_56",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_57",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_58",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_59",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_6",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_60",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_7",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_8",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_9",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peers_cfroi_hist_median_high",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peers_cfroi_hist_median_low",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peers_cfroi_hist_median_med",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peers_cfroi_lfy_high",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peers_cfroi_lfy_low",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peers_cfroi_lfy_med",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peers_cfroi_number",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "asset_turnover_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "asset_turnover_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "asset_turnover_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "asset_turnover_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "asset_turnover_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "asset_turnover_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "asset_turnover_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "asset_turnover_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "asset_turnover_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "asset_turnover_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "asset_turnover_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "asset_turnover_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "asset_turnover_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "associates_per_share",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "basic_shares_year_av_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "basic_shares_year_av_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "basic_shares_year_av_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "basic_shares_year_av_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "basic_shares_year_av_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "basic_shares_year_av_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "basic_shares_year_av_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "basic_shares_year_av_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "basic_shares_year_av_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "basic_shares_year_av_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "basic_shares_year_av_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "basic_shares_year_av_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "basic_shares_year_av_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "basic_shares_year_end_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "basic_shares_year_end_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "basic_shares_year_end_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "basic_shares_year_end_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "basic_shares_year_end_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "basic_shares_year_end_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "basic_shares_year_end_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "basic_shares_year_end_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "basic_shares_year_end_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "basic_shares_year_end_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "basic_shares_year_end_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "basic_shares_year_end_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "basic_shares_year_end_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "bloomberg_ticker",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "business_quality_score",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "cap_period_years",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capex_depreciation_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capex_depreciation_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capex_depreciation_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capex_depreciation_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capex_depreciation_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capex_depreciation_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capex_depreciation_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capex_depreciation_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capex_depreciation_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capex_depreciation_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capex_depreciation_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capex_depreciation_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capex_depreciation_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capex_incl_intangibles_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capex_incl_intangibles_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capex_incl_intangibles_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capex_incl_intangibles_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capex_incl_intangibles_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capex_incl_intangibles_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capex_incl_intangibles_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capex_incl_intangibles_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capex_incl_intangibles_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capex_incl_intangibles_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capex_incl_intangibles_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capex_incl_intangibles_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capex_incl_intangibles_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capitalised_rd_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capitalised_rd_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capitalised_rd_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capitalised_rd_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capitalised_rd_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capitalised_rd_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capitalised_rd_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capitalised_rd_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capitalised_rd_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capitalised_rd_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capitalised_rd_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capitalised_rd_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capitalised_rd_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "cash_and_chng_net_debt_per_share",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "cash_perc_market_cap_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "cash_perc_market_cap_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ce_discounted_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ce_growth_terminal_t15",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "change_in_working_capital_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "change_in_working_capital_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "change_in_working_capital_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "change_in_working_capital_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "change_in_working_capital_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "change_in_working_capital_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "change_in_working_capital_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "change_in_working_capital_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "change_in_working_capital_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "change_in_working_capital_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "change_in_working_capital_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "change_in_working_capital_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "change_in_working_capital_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "company_adjusted_cfroi_annualised_t5_t15",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "company_adjusted_cfroi_t15",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "company_fade_annualised_t1_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "company_fade_annualised_t5_t15",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "company_fade_t15",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "company_fade_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "company_name",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "company_name_in_model",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "comparator_1",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "comparator_2",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "comparator_3",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "comparator_4",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "comparator_5",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "comparator_6",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "comparator_7",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "comparator_8",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "country_rev_holding_perc_aud",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "country_rev_holding_perc_brl",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "country_rev_holding_perc_cad",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "country_rev_holding_perc_ejpy",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "country_rev_holding_perc_eur",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "country_rev_holding_perc_gbp",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "country_rev_holding_perc_usd",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "country_rev_holding_perc_xtra1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "country_rev_holding_perc_xtra2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "currency_listing",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "currency_multiplier_reporting",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "currency_reporting",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "debt_to_op_capital_t15",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "depreciation_incl_amortize_intangibles_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "depreciation_incl_amortize_intangibles_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "depreciation_incl_amortize_intangibles_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "depreciation_incl_amortize_intangibles_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "depreciation_incl_amortize_intangibles_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "depreciation_incl_amortize_intangibles_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "depreciation_incl_amortize_intangibles_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "depreciation_incl_amortize_intangibles_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "depreciation_incl_amortize_intangibles_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "depreciation_incl_amortize_intangibles_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "depreciation_incl_amortize_intangibles_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "depreciation_incl_amortize_intangibles_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "depreciation_incl_amortize_intangibles_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "discount_basis",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "discount_rate",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "dividend_yield_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "dividend_yield_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "dps_paid_growth_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "dps_paid_growth_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "dps_paid_growth_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "dps_paid_growth_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "dps_paid_growth_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "dps_paid_growth_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "dps_paid_growth_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "dps_paid_growth_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "dps_paid_growth_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "dps_paid_growth_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "dps_paid_growth_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "dps_paid_growth_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "dps_paid_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "dps_paid_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "dps_paid_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "dps_paid_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "dps_paid_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "dps_paid_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "dps_paid_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "dps_paid_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "dps_paid_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "dps_paid_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "dps_paid_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "dps_paid_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "dps_paid_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_add_back_capex_less_depr_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_add_back_capex_less_depr_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_add_back_capex_less_depr_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_add_back_capex_less_depr_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_add_back_capex_less_depr_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_add_back_capex_less_depr_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_add_back_capex_less_depr_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_add_back_capex_less_depr_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_add_back_capex_less_depr_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_add_back_capex_less_depr_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_add_back_capex_less_depr_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_add_back_capex_less_depr_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_add_back_capex_less_depr_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_margin_cagr_t1_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_margin_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_margin_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_margin_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_margin_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_margin_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_margin_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_margin_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_margin_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_margin_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_margin_t15",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_margin_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_margin_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_margin_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_margin_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_margin_t5_bear",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_margin_t5_bull",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_value_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_value_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_value_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_value_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_value_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_value_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_value_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_value_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_value_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_value_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_value_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_value_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_value_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebita_margin_cagr_t1_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebita_margin_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebita_margin_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebita_margin_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebita_margin_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebita_margin_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebita_margin_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebita_margin_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebita_margin_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebita_margin_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebita_margin_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebita_margin_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebita_margin_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebita_margin_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebitda_margin_cagr_t1_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebitda_margin_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebitda_margin_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebitda_margin_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebitda_margin_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebitda_margin_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebitda_margin_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebitda_margin_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebitda_margin_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebitda_margin_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebitda_margin_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebitda_margin_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebitda_margin_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebitda_margin_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "enterprise_cash_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "enterprise_cash_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "eps_street_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "eps_street_h1_high",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "eps_street_h1_low",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "eps_street_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "eps_street_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "eps_street_t1_high",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "eps_street_t1_low",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "eps_street_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "eps_street_t2_high",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "eps_street_t2_low",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "eps_street_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ev_change_net_debt_t1_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ev_div_capital_employed_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ev_div_ebitda_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ev_div_ebitda_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ev_div_nopat_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ev_div_nopat_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ev_div_sales_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ev_div_sales_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ev_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ev_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "eva_fade",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "eva_margin_t15",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "eva_margin_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "eva_or_oproce_indicator",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "exit_multiple",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "exit_multiple_bear",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "exit_multiple_bull",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "exit_t1_nopat_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "exit_t1_nopat_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "exit_t2_nopat_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "exit_trail_nopat_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "exit_trail_nopat_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fade",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fair_value",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fair_value_bear",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fair_value_bull",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fair_value_range_of_outcomes",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fair_value_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fair_value_upside_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_cash_conv_ratio_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_cash_conv_ratio_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_cash_conv_ratio_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_cash_conv_ratio_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_cash_conv_ratio_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_cash_conv_ratio_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_cash_conv_ratio_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_cash_conv_ratio_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_cash_conv_ratio_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_cash_conv_ratio_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_cash_conv_ratio_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_cash_conv_ratio_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_cash_conv_ratio_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_equity_per_share_cagr_t1_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_equity_per_share_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_equity_per_share_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_equity_per_share_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_equity_per_share_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_equity_per_share_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_equity_per_share_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_equity_per_share_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_equity_per_share_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_equity_per_share_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_equity_per_share_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_equity_per_share_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_equity_per_share_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_equity_per_share_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_per_share_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_per_share_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_per_share_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_per_share_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_per_share_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_per_share_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_per_share_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_per_share_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_per_share_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_per_share_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_per_share_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_per_share_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_per_share_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "financial_year_end_date_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "financial_year_end_date_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "financial_year_end_date_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "financial_year_end_date_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "financial_year_end_date_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "financial_year_end_date_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "financial_year_end_date_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "financial_year_end_date_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "free_cash_flow_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "free_cash_flow_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "free_cash_flow_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "free_cash_flow_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "free_cash_flow_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "free_cash_flow_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "free_cash_flow_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "free_cash_flow_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "free_cash_flow_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "free_cash_flow_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "free_cash_flow_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "free_cash_flow_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "free_cash_flow_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fv_attribution_change_in_margin",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fv_attribution_change_in_net_debt",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fv_attribution_dividends_t1_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fv_attribution_other_pandl",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fv_attribution_re_rating",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fv_attribution_residual",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fv_attribution_sales_growth",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fy_end_year_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fy_end_year_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fy_end_year_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fy_end_year_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fy_end_year_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fy_end_year_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fy_end_year_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fy_end_year_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fy_end_year_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fy_end_year_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fy_end_year_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fy_end_year_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fy_end_year_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ge_eps_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_ce_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_ce_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_ce_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_ce_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_ce_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_ce_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_ce_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_ce_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_ce_per_share_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_ce_per_share_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_ce_per_share_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_ce_per_share_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_ce_per_share_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_ce_per_share_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_ce_per_share_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_ce_per_share_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_ce_per_share_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_ce_per_share_t1_t5_cagr",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_ce_per_share_t15",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_ce_per_share_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_ce_per_share_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_ce_per_share_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_ce_per_share_t40",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_ce_per_share_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_ce_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_ce_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_ce_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_ce_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_ce_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_cfroi_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_cfroi_t15",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_cfroi_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_eps_cagr_t1_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_eps_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_eps_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_eps_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_eps_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_eps_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_eps_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_eps_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_eps_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_eps_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_eps_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_eps_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_eps_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_eps_t5_bear",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_eps_t5_bull",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_fade_of_spread",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_model_fade",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "generation_eps_growth_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "generation_eps_growth_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "generation_eps_growth_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "generation_eps_growth_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "generation_eps_growth_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "generation_eps_growth_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "generation_eps_growth_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "generation_eps_growth_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "generation_eps_growth_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "generation_eps_growth_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "generation_eps_growth_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "generation_eps_growth_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "generation_eps_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "generation_eps_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "generation_eps_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "generation_eps_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "generation_eps_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "goodwill_and_intangibles_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "goodwill_and_intangibles_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "goodwill_and_intangibles_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "goodwill_and_intangibles_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "goodwill_and_intangibles_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "goodwill_and_intangibles_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "goodwill_and_intangibles_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "goodwill_and_intangibles_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "goodwill_and_intangibles_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "goodwill_and_intangibles_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "goodwill_and_intangibles_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "goodwill_and_intangibles_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "goodwill_and_intangibles_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gross_debt_ex_npo_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gross_debt_ex_npo_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gross_margin_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gross_margin_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gross_margin_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gross_margin_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gross_margin_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gross_margin_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gross_margin_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gross_margin_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gross_margin_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gross_margin_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gross_margin_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gross_margin_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gross_margin_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "implied_ev_future_proj",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "implied_nopat_growth_t15",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "implied_nopat_growth_t15_t40",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "implied_p_e_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "implied_p_e_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "industry_fade",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "industry_type_switch",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "inflation",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "investment_thesis",
                type: "String(30000))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ke",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ke_wacc_ce_growth_rate_t15",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ke_wacc_financials",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ke_wacc_perc_market_cap",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ke_wacc_range_of_outcomes",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ke_wacc_strd_debt_rate",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "keypoints_base",
                type: "String(30000))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "keypoints_bear",
                type: "String(30000))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "keypoints_bull",
                type: "String(30000))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "less_equity_minorities_per_share",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "less_gross_debt_per_share",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "less_plus_other_correction_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "less_value_options_rsus_per_share_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "management_quality_score",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "market_cap_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "market_cap_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "market_ev_per_share_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "model_using_eva_valudation_flag",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "model_v_street_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "model_v_street_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "model_version",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_cashflow_yield_equity_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_cashflow_yield_equity_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_debt_cash_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_debt_cash_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_debt_cash_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_debt_cash_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_debt_cash_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_debt_cash_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_debt_cash_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_debt_cash_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_debt_cash_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_debt_cash_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_debt_cash_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_debt_cash_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_debt_cash_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_debt_div_ebitda_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_debt_perc_ev_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_debt_perc_ev_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_ebitda_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_ebitda_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_p_pe_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_p_pe_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_p_pe_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_p_pe_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_p_pe_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_p_pe_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_p_pe_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_p_pe_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_p_pe_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_p_pe_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_p_pe_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_p_pe_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_p_pe_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "netpenob",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "netpenob_per_share",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "nonop_share",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "nopat_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "nopat_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "nopat_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "nopat_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "nopat_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "nopat_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "nopat_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "nopat_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "nopat_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "nopat_t15",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "nopat_t15_bear",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "nopat_t15_bull",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "nopat_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "nopat_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "nopat_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "nopat_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "norm_baseyr",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "normalised_organic_constant_currency_growth",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "normalised_sales_organic_cc_growth_to_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_asset_turnover_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_asset_turnover_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_asset_turnover_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_asset_turnover_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_asset_turnover_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_asset_turnover_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_asset_turnover_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_asset_turnover_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_asset_turnover_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_asset_turnover_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_asset_turnover_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_asset_turnover_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_asset_turnover_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_growth_cap_period",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_growth_cap_period_bear",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_growth_cap_period_bull",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_per_share_cagr_t1_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_per_share_growth_cagr_t1_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_per_share_growth_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_per_share_growth_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_per_share_growth_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_per_share_growth_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_per_share_growth_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_per_share_growth_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_per_share_growth_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_per_share_growth_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_per_share_growth_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_per_share_growth_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_per_share_growth_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_per_share_growth_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_per_share_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_per_share_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_per_share_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_per_share_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_per_share_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_per_share_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_per_share_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_per_share_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_per_share_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_per_share_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_per_share_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_per_share_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_per_share_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_t15",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_fade_of_spread",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_fade_of_spread_bear",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_fade_of_spread_bull",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_fcf_equity_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_fcf_equity_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_fcf_per_share_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_fcf_per_share_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_fcf_per_share_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_fcf_per_share_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_fcf_per_share_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_fcf_per_share_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_fcf_per_share_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_fcf_per_share_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_fcf_per_share_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_fcf_per_share_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_fcf_per_share_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_fcf_per_share_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_fcf_per_share_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_free_cash_flow_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_free_cash_flow_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_free_cash_flow_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_free_cash_flow_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_free_cash_flow_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_free_cash_flow_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_free_cash_flow_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_free_cash_flow_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_free_cash_flow_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_free_cash_flow_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_free_cash_flow_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_free_cash_flow_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_free_cash_flow_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_roce_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_roce_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_roce_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_roce_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_roce_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_roce_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_roce_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_roce_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_roce_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_roce_t15",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_roce_t15_bear",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_roce_t15_bull",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_roce_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_roce_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_roce_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_roce_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_roce_t5_bear",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_roce_t5_bull",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "operation_real_sales_t15",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "oproce_growth_h1_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "oproce_growth_t5_t15",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "original_peer_industry",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "other_costs_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "other_costs_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "other_costs_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "other_costs_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "other_costs_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "other_costs_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "other_costs_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "other_costs_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "other_costs_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "other_costs_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "other_costs_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "other_costs_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "other_costs_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "payout_ratio_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "payout_ratio_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "payout_ratio_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "payout_ratio_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "payout_ratio_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "payout_ratio_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "payout_ratio_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "payout_ratio_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "payout_ratio_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "payout_ratio_t15",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "payout_ratio_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "payout_ratio_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "payout_ratio_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "payout_ratio_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "peer_industry",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "perc_company_adjusted_cfroi_t15",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "perc_future_ce_t40",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "profitability_percentile_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "profitability_percentile_t15",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "profitability_percentile_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "pv_dividends_per_share",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "pv_per_share",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "pv_terminal_value_per_share_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "region_rev_mix_asia",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "region_rev_mix_asia_ex_japan",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "region_rev_mix_europe",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "region_rev_mix_japan",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "region_rev_mix_north_america",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "region_rev_mix_other",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "reported_eps_net_income_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "reported_eps_net_income_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "reported_eps_net_income_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "reported_eps_net_income_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "reported_eps_net_income_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "reported_eps_net_income_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "reported_eps_net_income_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "reported_eps_net_income_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "reported_eps_net_income_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "reported_eps_net_income_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "reported_eps_net_income_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "reported_eps_net_income_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "reported_eps_net_income_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "revenue_growth_cap",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "revenue_growth_h5_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "roce_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "roce_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "roce_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "roce_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "roce_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "roce_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "roce_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "roce_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "roce_ke_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "roce_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "roce_t15",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "roce_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "roce_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "roce_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "roce_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_cagr_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_cagr_t5_bear",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_cagr_t5_bull",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_growth_cap",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_growth_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_growth_h1_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_growth_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_growth_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_growth_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_growth_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_growth_h5_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_growth_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_growth_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_growth_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_growth_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_growth_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_growth_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_growth_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_org_cc_growth_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_org_cc_growth_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_org_cc_growth_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_org_cc_growth_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_org_cc_growth_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_org_cc_growth_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_org_cc_growth_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_org_cc_growth_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_org_cc_growth_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_org_cc_growth_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_org_cc_growth_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_org_cc_growth_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_org_cc_growth_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_t15",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sector_cfroi_median_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sector_cfroi_standard_deviation_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "share_price",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sum_percent_cashflow_times_dcf_t1_t80",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "tax_adjusted_net_interest_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "tax_adjusted_net_interest_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "tax_adjusted_net_interest_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "tax_adjusted_net_interest_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "tax_adjusted_net_interest_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "tax_adjusted_net_interest_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "tax_adjusted_net_interest_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "tax_adjusted_net_interest_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "tax_adjusted_net_interest_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "tax_adjusted_net_interest_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "tax_adjusted_net_interest_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "tax_adjusted_net_interest_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "tax_adjusted_net_interest_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "tax_rate_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "tax_rate_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "tax_rate_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "tax_rate_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "tax_rate_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "tax_rate_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "tax_rate_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "tax_rate_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "tax_rate_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "tax_rate_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "tax_rate_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "tax_rate_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "tax_rate_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "time_adjusted_fair_value",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "time_adjusted_fair_value_bear",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "time_adjusted_fair_value_bull",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "time_adjusted_today_date",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "time_adjusted_upside",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "time_adjusted_upside_bear",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "time_adjusted_upside_bull",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "working_capital_asset_less_liab_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "working_capital_asset_less_liab_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "working_capital_asset_less_liab_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "working_capital_asset_less_liab_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "working_capital_asset_less_liab_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "working_capital_asset_less_liab_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "working_capital_asset_less_liab_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "working_capital_asset_less_liab_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "working_capital_asset_less_liab_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "working_capital_asset_less_liab_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "working_capital_asset_less_liab_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "working_capital_asset_less_liab_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "working_capital_asset_less_liab_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
        ],
    },
    {
        text: "model_metrics_wide_archive",
        type: "table",
        value: "model_metrics_wide_archive",
        fields: [
            {
                field: "extraction_id",
                type: "String(36)",
                primaryKey: true,
                nullable: true,
            },
            {
                field: "include_model_flag",
                type: "SmallInteger",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "current_model_flag",
                type: "SmallInteger",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "company",
                type: "String(50)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "company_extract_group",
                type: "String(60)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "extraction_datetime",
                type: "DateTime",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "extraction_date",
                type: "Date",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "model_h1_start_date",
                type: "Date",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "model_h1_end_date",
                type: "Date",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "model_h1_year",
                type: "Integer",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "additional_1",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "additional_2",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "additional_3",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "additional_4",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_adjusted_peers_fade",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_1",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_10",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_11",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_12",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_13",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_14",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_15",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_16",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_17",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_18",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_19",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_2",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_20",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_21",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_22",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_23",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_24",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_25",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_26",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_27",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_28",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_29",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_3",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_30",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_31",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_32",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_33",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_34",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_35",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_36",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_37",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_38",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_39",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_4",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_40",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_41",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_42",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_43",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_44",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_45",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_46",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_47",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_48",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_49",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_5",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_50",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_51",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_52",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_53",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_54",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_55",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_56",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_57",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_58",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_59",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_6",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_60",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_7",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_8",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peer_company_9",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peers_cfroi_hist_median_high",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peers_cfroi_hist_median_low",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peers_cfroi_hist_median_med",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peers_cfroi_lfy_high",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peers_cfroi_lfy_low",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peers_cfroi_lfy_med",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "analyst_peers_cfroi_number",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "asset_turnover_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "asset_turnover_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "asset_turnover_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "asset_turnover_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "asset_turnover_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "asset_turnover_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "asset_turnover_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "asset_turnover_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "asset_turnover_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "asset_turnover_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "asset_turnover_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "asset_turnover_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "asset_turnover_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "associates_per_share",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "basic_shares_year_av_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "basic_shares_year_av_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "basic_shares_year_av_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "basic_shares_year_av_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "basic_shares_year_av_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "basic_shares_year_av_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "basic_shares_year_av_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "basic_shares_year_av_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "basic_shares_year_av_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "basic_shares_year_av_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "basic_shares_year_av_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "basic_shares_year_av_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "basic_shares_year_av_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "basic_shares_year_end_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "basic_shares_year_end_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "basic_shares_year_end_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "basic_shares_year_end_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "basic_shares_year_end_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "basic_shares_year_end_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "basic_shares_year_end_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "basic_shares_year_end_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "basic_shares_year_end_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "basic_shares_year_end_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "basic_shares_year_end_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "basic_shares_year_end_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "basic_shares_year_end_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "bloomberg_ticker",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "business_quality_score",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "cap_period_years",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capex_depreciation_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capex_depreciation_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capex_depreciation_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capex_depreciation_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capex_depreciation_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capex_depreciation_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capex_depreciation_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capex_depreciation_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capex_depreciation_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capex_depreciation_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capex_depreciation_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capex_depreciation_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capex_depreciation_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capex_incl_intangibles_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capex_incl_intangibles_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capex_incl_intangibles_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capex_incl_intangibles_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capex_incl_intangibles_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capex_incl_intangibles_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capex_incl_intangibles_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capex_incl_intangibles_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capex_incl_intangibles_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capex_incl_intangibles_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capex_incl_intangibles_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capex_incl_intangibles_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capex_incl_intangibles_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capitalised_rd_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capitalised_rd_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capitalised_rd_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capitalised_rd_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capitalised_rd_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capitalised_rd_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capitalised_rd_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capitalised_rd_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capitalised_rd_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capitalised_rd_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capitalised_rd_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capitalised_rd_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "capitalised_rd_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "cash_and_chng_net_debt_per_share",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "cash_perc_market_cap_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "cash_perc_market_cap_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ce_discounted_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ce_growth_terminal_t15",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "change_in_working_capital_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "change_in_working_capital_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "change_in_working_capital_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "change_in_working_capital_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "change_in_working_capital_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "change_in_working_capital_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "change_in_working_capital_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "change_in_working_capital_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "change_in_working_capital_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "change_in_working_capital_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "change_in_working_capital_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "change_in_working_capital_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "change_in_working_capital_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "company_adjusted_cfroi_annualised_t5_t15",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "company_adjusted_cfroi_t15",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "company_fade_annualised_t1_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "company_fade_annualised_t5_t15",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "company_fade_t15",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "company_fade_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "company_name",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "company_name_in_model",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "comparator_1",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "comparator_2",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "comparator_3",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "comparator_4",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "comparator_5",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "comparator_6",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "comparator_7",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "comparator_8",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "country_rev_holding_perc_aud",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "country_rev_holding_perc_brl",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "country_rev_holding_perc_cad",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "country_rev_holding_perc_ejpy",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "country_rev_holding_perc_eur",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "country_rev_holding_perc_gbp",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "country_rev_holding_perc_usd",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "country_rev_holding_perc_xtra1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "country_rev_holding_perc_xtra2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "currency_listing",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "currency_multiplier_reporting",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "currency_reporting",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "debt_to_op_capital_t15",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "depreciation_incl_amortize_intangibles_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "depreciation_incl_amortize_intangibles_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "depreciation_incl_amortize_intangibles_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "depreciation_incl_amortize_intangibles_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "depreciation_incl_amortize_intangibles_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "depreciation_incl_amortize_intangibles_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "depreciation_incl_amortize_intangibles_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "depreciation_incl_amortize_intangibles_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "depreciation_incl_amortize_intangibles_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "depreciation_incl_amortize_intangibles_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "depreciation_incl_amortize_intangibles_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "depreciation_incl_amortize_intangibles_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "depreciation_incl_amortize_intangibles_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "discount_basis",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "discount_rate",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "dividend_yield_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "dividend_yield_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "dps_paid_growth_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "dps_paid_growth_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "dps_paid_growth_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "dps_paid_growth_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "dps_paid_growth_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "dps_paid_growth_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "dps_paid_growth_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "dps_paid_growth_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "dps_paid_growth_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "dps_paid_growth_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "dps_paid_growth_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "dps_paid_growth_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "dps_paid_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "dps_paid_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "dps_paid_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "dps_paid_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "dps_paid_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "dps_paid_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "dps_paid_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "dps_paid_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "dps_paid_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "dps_paid_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "dps_paid_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "dps_paid_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "dps_paid_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_add_back_capex_less_depr_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_add_back_capex_less_depr_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_add_back_capex_less_depr_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_add_back_capex_less_depr_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_add_back_capex_less_depr_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_add_back_capex_less_depr_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_add_back_capex_less_depr_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_add_back_capex_less_depr_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_add_back_capex_less_depr_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_add_back_capex_less_depr_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_add_back_capex_less_depr_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_add_back_capex_less_depr_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_add_back_capex_less_depr_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_margin_cagr_t1_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_margin_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_margin_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_margin_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_margin_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_margin_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_margin_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_margin_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_margin_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_margin_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_margin_t15",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_margin_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_margin_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_margin_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_margin_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_margin_t5_bear",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_margin_t5_bull",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_value_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_value_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_value_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_value_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_value_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_value_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_value_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_value_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_value_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_value_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_value_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_value_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebit_value_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebita_margin_cagr_t1_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebita_margin_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebita_margin_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebita_margin_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebita_margin_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebita_margin_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebita_margin_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebita_margin_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebita_margin_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebita_margin_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebita_margin_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebita_margin_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebita_margin_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebita_margin_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebitda_margin_cagr_t1_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebitda_margin_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebitda_margin_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebitda_margin_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebitda_margin_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebitda_margin_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebitda_margin_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebitda_margin_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebitda_margin_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebitda_margin_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebitda_margin_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebitda_margin_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebitda_margin_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ebitda_margin_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "enterprise_cash_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "enterprise_cash_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "eps_street_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "eps_street_h1_high",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "eps_street_h1_low",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "eps_street_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "eps_street_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "eps_street_t1_high",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "eps_street_t1_low",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "eps_street_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "eps_street_t2_high",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "eps_street_t2_low",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "eps_street_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ev_change_net_debt_t1_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ev_div_capital_employed_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ev_div_ebitda_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ev_div_ebitda_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ev_div_nopat_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ev_div_nopat_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ev_div_sales_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ev_div_sales_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ev_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ev_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "eva_fade",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "eva_margin_t15",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "eva_margin_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "eva_or_oproce_indicator",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "exit_multiple",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "exit_multiple_bear",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "exit_multiple_bull",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "exit_t1_nopat_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "exit_t1_nopat_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "exit_t2_nopat_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "exit_trail_nopat_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "exit_trail_nopat_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fade",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fair_value",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fair_value_bear",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fair_value_bull",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fair_value_range_of_outcomes",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fair_value_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fair_value_upside_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_cash_conv_ratio_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_cash_conv_ratio_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_cash_conv_ratio_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_cash_conv_ratio_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_cash_conv_ratio_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_cash_conv_ratio_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_cash_conv_ratio_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_cash_conv_ratio_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_cash_conv_ratio_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_cash_conv_ratio_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_cash_conv_ratio_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_cash_conv_ratio_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_cash_conv_ratio_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_equity_per_share_cagr_t1_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_equity_per_share_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_equity_per_share_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_equity_per_share_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_equity_per_share_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_equity_per_share_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_equity_per_share_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_equity_per_share_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_equity_per_share_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_equity_per_share_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_equity_per_share_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_equity_per_share_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_equity_per_share_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_equity_per_share_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_per_share_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_per_share_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_per_share_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_per_share_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_per_share_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_per_share_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_per_share_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_per_share_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_per_share_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_per_share_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_per_share_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_per_share_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fcf_per_share_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "financial_year_end_date_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "financial_year_end_date_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "financial_year_end_date_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "financial_year_end_date_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "financial_year_end_date_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "financial_year_end_date_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "financial_year_end_date_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "financial_year_end_date_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "free_cash_flow_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "free_cash_flow_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "free_cash_flow_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "free_cash_flow_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "free_cash_flow_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "free_cash_flow_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "free_cash_flow_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "free_cash_flow_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "free_cash_flow_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "free_cash_flow_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "free_cash_flow_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "free_cash_flow_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "free_cash_flow_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fv_attribution_change_in_margin",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fv_attribution_change_in_net_debt",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fv_attribution_dividends_t1_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fv_attribution_other_pandl",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fv_attribution_re_rating",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fv_attribution_residual",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fv_attribution_sales_growth",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fy_end_year_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fy_end_year_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fy_end_year_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fy_end_year_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fy_end_year_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fy_end_year_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fy_end_year_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fy_end_year_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fy_end_year_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fy_end_year_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fy_end_year_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fy_end_year_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fy_end_year_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ge_eps_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_ce_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_ce_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_ce_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_ce_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_ce_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_ce_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_ce_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_ce_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_ce_per_share_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_ce_per_share_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_ce_per_share_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_ce_per_share_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_ce_per_share_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_ce_per_share_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_ce_per_share_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_ce_per_share_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_ce_per_share_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_ce_per_share_t1_t5_cagr",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_ce_per_share_t15",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_ce_per_share_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_ce_per_share_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_ce_per_share_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_ce_per_share_t40",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_ce_per_share_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_ce_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_ce_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_ce_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_ce_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_ce_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_cfroi_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_cfroi_t15",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_cfroi_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_eps_cagr_t1_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_eps_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_eps_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_eps_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_eps_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_eps_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_eps_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_eps_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_eps_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_eps_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_eps_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_eps_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_eps_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_eps_t5_bear",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_eps_t5_bull",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_fade_of_spread",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gen_model_fade",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "generation_eps_growth_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "generation_eps_growth_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "generation_eps_growth_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "generation_eps_growth_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "generation_eps_growth_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "generation_eps_growth_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "generation_eps_growth_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "generation_eps_growth_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "generation_eps_growth_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "generation_eps_growth_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "generation_eps_growth_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "generation_eps_growth_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "generation_eps_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "generation_eps_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "generation_eps_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "generation_eps_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "generation_eps_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "goodwill_and_intangibles_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "goodwill_and_intangibles_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "goodwill_and_intangibles_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "goodwill_and_intangibles_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "goodwill_and_intangibles_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "goodwill_and_intangibles_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "goodwill_and_intangibles_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "goodwill_and_intangibles_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "goodwill_and_intangibles_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "goodwill_and_intangibles_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "goodwill_and_intangibles_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "goodwill_and_intangibles_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "goodwill_and_intangibles_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gross_debt_ex_npo_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gross_debt_ex_npo_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gross_margin_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gross_margin_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gross_margin_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gross_margin_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gross_margin_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gross_margin_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gross_margin_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gross_margin_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gross_margin_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gross_margin_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gross_margin_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gross_margin_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "gross_margin_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "implied_ev_future_proj",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "implied_nopat_growth_t15",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "implied_nopat_growth_t15_t40",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "implied_p_e_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "implied_p_e_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "industry_fade",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "industry_type_switch",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "inflation",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "investment_thesis",
                type: "String(30000))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ke",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ke_wacc_ce_growth_rate_t15",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ke_wacc_financials",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ke_wacc_perc_market_cap",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ke_wacc_range_of_outcomes",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ke_wacc_strd_debt_rate",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "keypoints_base",
                type: "String(30000))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "keypoints_bear",
                type: "String(30000))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "keypoints_bull",
                type: "String(30000))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "less_equity_minorities_per_share",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "less_gross_debt_per_share",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "less_plus_other_correction_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "less_value_options_rsus_per_share_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "management_quality_score",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "market_cap_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "market_cap_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "market_ev_per_share_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "model_using_eva_valudation_flag",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "model_v_street_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "model_v_street_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "model_version",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_cashflow_yield_equity_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_cashflow_yield_equity_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_debt_cash_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_debt_cash_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_debt_cash_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_debt_cash_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_debt_cash_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_debt_cash_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_debt_cash_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_debt_cash_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_debt_cash_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_debt_cash_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_debt_cash_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_debt_cash_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_debt_cash_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_debt_div_ebitda_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_debt_perc_ev_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_debt_perc_ev_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_ebitda_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_ebitda_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_p_pe_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_p_pe_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_p_pe_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_p_pe_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_p_pe_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_p_pe_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_p_pe_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_p_pe_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_p_pe_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_p_pe_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_p_pe_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_p_pe_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "net_p_pe_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "netpenob",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "netpenob_per_share",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "nonop_share",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "nopat_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "nopat_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "nopat_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "nopat_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "nopat_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "nopat_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "nopat_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "nopat_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "nopat_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "nopat_t15",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "nopat_t15_bear",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "nopat_t15_bull",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "nopat_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "nopat_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "nopat_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "nopat_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "norm_baseyr",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "normalised_organic_constant_currency_growth",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "normalised_sales_organic_cc_growth_to_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_asset_turnover_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_asset_turnover_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_asset_turnover_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_asset_turnover_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_asset_turnover_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_asset_turnover_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_asset_turnover_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_asset_turnover_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_asset_turnover_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_asset_turnover_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_asset_turnover_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_asset_turnover_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_asset_turnover_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_growth_cap_period",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_growth_cap_period_bear",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_growth_cap_period_bull",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_per_share_cagr_t1_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_per_share_growth_cagr_t1_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_per_share_growth_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_per_share_growth_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_per_share_growth_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_per_share_growth_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_per_share_growth_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_per_share_growth_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_per_share_growth_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_per_share_growth_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_per_share_growth_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_per_share_growth_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_per_share_growth_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_per_share_growth_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_per_share_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_per_share_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_per_share_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_per_share_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_per_share_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_per_share_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_per_share_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_per_share_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_per_share_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_per_share_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_per_share_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_per_share_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_per_share_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_t15",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_ce_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_fade_of_spread",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_fade_of_spread_bear",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_fade_of_spread_bull",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_fcf_equity_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_fcf_equity_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_fcf_per_share_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_fcf_per_share_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_fcf_per_share_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_fcf_per_share_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_fcf_per_share_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_fcf_per_share_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_fcf_per_share_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_fcf_per_share_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_fcf_per_share_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_fcf_per_share_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_fcf_per_share_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_fcf_per_share_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_fcf_per_share_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_free_cash_flow_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_free_cash_flow_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_free_cash_flow_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_free_cash_flow_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_free_cash_flow_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_free_cash_flow_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_free_cash_flow_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_free_cash_flow_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_free_cash_flow_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_free_cash_flow_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_free_cash_flow_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_free_cash_flow_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_free_cash_flow_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_roce_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_roce_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_roce_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_roce_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_roce_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_roce_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_roce_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_roce_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_roce_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_roce_t15",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_roce_t15_bear",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_roce_t15_bull",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_roce_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_roce_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_roce_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_roce_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_roce_t5_bear",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "op_roce_t5_bull",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "operation_real_sales_t15",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "oproce_growth_h1_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "oproce_growth_t5_t15",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "original_peer_industry",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "other_costs_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "other_costs_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "other_costs_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "other_costs_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "other_costs_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "other_costs_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "other_costs_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "other_costs_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "other_costs_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "other_costs_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "other_costs_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "other_costs_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "other_costs_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "payout_ratio_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "payout_ratio_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "payout_ratio_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "payout_ratio_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "payout_ratio_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "payout_ratio_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "payout_ratio_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "payout_ratio_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "payout_ratio_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "payout_ratio_t15",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "payout_ratio_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "payout_ratio_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "payout_ratio_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "payout_ratio_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "peer_industry",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "perc_company_adjusted_cfroi_t15",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "perc_future_ce_t40",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "profitability_percentile_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "profitability_percentile_t15",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "profitability_percentile_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "pv_dividends_per_share",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "pv_per_share",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "pv_terminal_value_per_share_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "region_rev_mix_asia",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "region_rev_mix_asia_ex_japan",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "region_rev_mix_europe",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "region_rev_mix_japan",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "region_rev_mix_north_america",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "region_rev_mix_other",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "reported_eps_net_income_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "reported_eps_net_income_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "reported_eps_net_income_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "reported_eps_net_income_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "reported_eps_net_income_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "reported_eps_net_income_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "reported_eps_net_income_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "reported_eps_net_income_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "reported_eps_net_income_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "reported_eps_net_income_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "reported_eps_net_income_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "reported_eps_net_income_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "reported_eps_net_income_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "revenue_growth_cap",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "revenue_growth_h5_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "roce_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "roce_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "roce_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "roce_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "roce_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "roce_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "roce_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "roce_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "roce_ke_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "roce_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "roce_t15",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "roce_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "roce_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "roce_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "roce_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_cagr_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_cagr_t5_bear",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_cagr_t5_bull",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_growth_cap",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_growth_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_growth_h1_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_growth_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_growth_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_growth_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_growth_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_growth_h5_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_growth_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_growth_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_growth_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_growth_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_growth_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_growth_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_growth_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_org_cc_growth_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_org_cc_growth_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_org_cc_growth_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_org_cc_growth_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_org_cc_growth_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_org_cc_growth_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_org_cc_growth_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_org_cc_growth_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_org_cc_growth_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_org_cc_growth_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_org_cc_growth_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_org_cc_growth_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_org_cc_growth_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_t15",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sales_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sector_cfroi_median_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sector_cfroi_standard_deviation_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "share_price",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "sum_percent_cashflow_times_dcf_t1_t80",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "tax_adjusted_net_interest_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "tax_adjusted_net_interest_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "tax_adjusted_net_interest_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "tax_adjusted_net_interest_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "tax_adjusted_net_interest_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "tax_adjusted_net_interest_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "tax_adjusted_net_interest_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "tax_adjusted_net_interest_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "tax_adjusted_net_interest_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "tax_adjusted_net_interest_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "tax_adjusted_net_interest_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "tax_adjusted_net_interest_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "tax_adjusted_net_interest_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "tax_rate_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "tax_rate_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "tax_rate_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "tax_rate_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "tax_rate_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "tax_rate_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "tax_rate_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "tax_rate_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "tax_rate_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "tax_rate_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "tax_rate_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "tax_rate_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "tax_rate_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "time_adjusted_fair_value",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "time_adjusted_fair_value_bear",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "time_adjusted_fair_value_bull",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "time_adjusted_today_date",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "time_adjusted_upside",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "time_adjusted_upside_bear",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "time_adjusted_upside_bull",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "working_capital_asset_less_liab_h1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "working_capital_asset_less_liab_h2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "working_capital_asset_less_liab_h3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "working_capital_asset_less_liab_h4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "working_capital_asset_less_liab_h5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "working_capital_asset_less_liab_h6",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "working_capital_asset_less_liab_h7",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "working_capital_asset_less_liab_h8",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "working_capital_asset_less_liab_t1",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "working_capital_asset_less_liab_t2",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "working_capital_asset_less_liab_t3",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "working_capital_asset_less_liab_t4",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "working_capital_asset_less_liab_t5",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
        ],
    },
    {
        text: "model_process_meta",
        type: "table",
        value: "model_process_meta",
        fields: [
            {
                field: "extraction_id",
                type: "String(36)",
                primaryKey: true,
                nullable: true,
            },
            {
                field: "company",
                type: "String(50)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "company_extract_group",
                type: "String(60)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "extraction_datetime",
                type: "DateTime",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "extraction_date",
                type: "Date",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "model_status",
                type: "String(3)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "include_model_flag",
                type: "SmallInteger",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "current_model_flag",
                type: "SmallInteger",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "fixes_id",
                type: "String(36))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "last_modified_datetime",
                type: "DateTime)",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "last_modified_by",
                type: "String(30))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "model_h1_start_date",
                type: "Date",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "model_h1_end_date",
                type: "Date",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "model_h1_year",
                type: "Integer",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "reporting_currency",
                type: "String(10)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "listing_currency",
                type: "String(10)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "currency_differs_flag",
                type: "SmallInteger",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "processing_datetime",
                type: "DateTime)",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "processing_date",
                type: "Date)",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "processing_method",
                type: "String(10))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "model_initial_success_flag",
                type: "SmallInteger",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "model_in_holding_flag",
                type: "SmallInteger",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "num_errors_critical",
                type: "Integer)",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "num_errors_warning",
                type: "Integer)",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "num_errors_info",
                type: "Integer)",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "model_errors_ignored_flag",
                type: "SmallInteger",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "model_deleted_flag",
                type: "SmallInteger",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "model_replaced_flag",
                type: "SmallInteger",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "user_id",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "user_notes",
                type: "String(2000))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "company_id",
                type: "Integer",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "company_ref_id",
                type: "Integer",
                primaryKey: false,
                nullable: false,
            },
        ],
    },
];
