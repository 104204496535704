import styled from "styled-components";
import colors from "assets/styles/colors";

export default styled.div`
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 10px;
    width: 300px;
    margin-top: 10px;

    background-color: white;
    box-shadow: 5px 5px 10px 5px rgba(0, 0, 0, 0.28);

    h3 {
        margin: 0 0 10px 0;
        font-size: 18px;
        color: ${colors.textTeal};
        letter-spacing: 0.1em;
        text-align: center;
    }

    .errorList {
        display: flex;
        flex-direction: column;
        padding: 20px 0 0 0;

        .errorRow {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 5px 0;

            svg {
                margin-top: 3px;
            }
        }
    }

    .seeAllErrors {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 0 5px 0;
        border-radius: 5px;
        cursor: pointer;
        letter-spacing: 0.1em;
        color: ${colors.textNavy};

        svg {
            margin-left: 10px;
            margin-bottom: 4px;
            color: ${colors.textNavy};
            font-size: 18px;
        }

        :hover {
            background-color: ${colors.teal30};
        }
    }

    .noErrorInfo {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 0 5px 0;
        letter-spacing: 0.1em;
        color: ${colors.textNavy};
    }
`;
