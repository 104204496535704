import { useState } from "react";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

// Components
import Table from "components/Common/Table/Table";
import SearchOptions from "components/Common/SearchOptions/SearchOptions";
import TableCell from "components/Common/TableCell/TableCell";

// Utils
import { to4DecimalPlaces } from "utils/general.utils";

// Styles
import SummarySheetDataContainer from "./SummarySheetData.styles";

type Props = {
    id: string;
    apiUrl: string;
};

export default function SummarySheetData({ id, apiUrl }: Props) {
    const [filterQuery, setFilterQuery] = useState("");

    const columns = [
        {
            id: 1,
            title: "CELL REF",
            sortable: true,
            value: "cell_ref",
            cell: (data: string) => <TableCell data={data} />,
            show: true,
        },

        {
            id: 2,
            title: "TYPE",
            sortable: true,
            value: "cell_type",
            cell: (data: string) => <TableCell data={data} />,
            show: true,
        },
        {
            id: 3,
            title: "VALUE",
            sortable: true,
            value: "cell_value",
            cell: (data: string) => <p>{to4DecimalPlaces(data)}</p>,
            show: true,
        },
        {
            id: 4,
            title: "FORMULA",
            sortable: false,
            value: "cell_formula",
            cell: (data: string) => <p className={"formula"}>{data}</p>,
            show: true,
        },
        {
            id: 5,
            title: "HIDDEN FLAG",
            sortable: true,
            value: "hidden_flag",
            cell: (data: number) =>
                data === 1 && (
                    <FontAwesomeIcon
                        className="checkIcon"
                        icon={faCheckCircle}
                        fixedWidth
                    />
                ),
            show: true,
        },
    ];

    const filterOptions = [
        {
            id: 1,
            name: "CELL",
            value: "search",
            type: "SEARCH",
        },
        {
            id: 2,
            name: "Hidden",
            value: "hidden_flag",
            type: "DROPDOWN",
            options: [
                {
                    label: "Only Hidden",
                    value: "1",
                },
                {
                    label: "Only Visable",
                    value: "0",
                },
            ],
        },
    ];

    return (
        <SummarySheetDataContainer>
            <SearchOptions
                setFilterQuery={setFilterQuery}
                options={filterOptions}
            />

            <Table
                url={`${apiUrl}values/${id}`}
                columns={columns}
                filterQuery={filterQuery}
                defaultSortKey="cell_ref"
                defaultSortOrder="ASC"
                pagination
            />
        </SummarySheetDataContainer>
    );
}
