export const accrualsFields = {
    dublin_fees: [
        {
            name: "directors_fee_eur",
            label: "Directors Fee (EUR)",
            type: "input"
        },
        {
            name: "directors_meeting_expenses",
            label: "Directors Meeting Expenses",
            type: "input"
        },
        {
            name: "auditors_fee_eur",
            label: "Auditors Fee (EUR)",
            type: "input"
        },
        {
            name: "other_professional_fees_pwc_uk_rfs_gbp",
            label: "Other Professional Fees (PWC UK RFS) (GBP)",
            type: "input"
        },
        {
            name: "legal_fees_annual",
            label: "Legal Fees Annual",
            type: "input"
        },
        {
            name: "custody_fee_safekeeping",
            label: "Custody Fee Safekeeping",
            type: "input"
        },
        {
            name: "misc_vat_tt_charges",
            label: "Misc VAT (TT Charges)",
            type: "input"
        },
        {
            name: "ifrsa_levy_eur",
            label: "IFRSA Levy (EUR)",
            type: "input"
        },
        {
            name: "offshore_registration",
            label: "Offshore Registration",
            type: "input"
        },
        {
            name: "liability_insurance_gbp_dublin",
            label: "Liability Insurance (GBP)",
            type: "input"
        },
    ],
    delaware_fees: [
        {
            name: "ta_fee_nt",
            label: "TA Fee - NT",
            type: "input"
        },
        {
            name: "tax_reporting_services_deloitte",
            label: "Tax Reporting Services - Deloitte",
            type: "input"
        },
        {
            name: "incentive_fee_calculation_deloitte",
            label: "Incentive Fee Calculation - Deloitte",
            type: "input"
        },
        {
            name: "custody_fee_nt",
            label: "Custody Fee - NT",
            type: "input"
        },
        {
            name: "audit_fee_kpmg",
            label: "Audit Fee - KPMG",
            type: "input"
        },
        {
            name: "fund_admin_nt",
            label: "Fund Admin - NT",
            type: "input"
        },
        {
            name: "legal_fee",
            label: "Legal Fee",
            type: "input"
        },
        {
            name: "prior_month_nav",
            label: "Prior Month NAV",
            type: "input"
        },
        {
            name: "liability_insurance_gbp_delaware",
            label: "Liability Insurance (GBP)",
            type: "input"
        },
    ],
    delaware_deductions: [
        {
            name: "outstanding_accrual_carried_forward_delaware",
            label: "Outstanding Accrual Carried Forward",
            type: "input"
        },
        {
            name: "deductions_to_accrued_expenses_delaware",
            label: "Deductions To Accrued Expenses",
            type: "input"
        },
        {
            name: "management_fee_paid_delaware",
            label: "Management Fee Paid",
            type: "input"
        },
    ],
    dublin_deductions: [
        {
            name: "outstanding_accrual_carried_forward_dublin",
            label: "Outstanding Accrual Carried Forward",
            type: "input"
        },
        {
            name: "deductions_to_accrued_expenses_dublin",
            label: "Deductions To Accrued Expenses",
            type: "input"
        },
        {
            name: "management_fees_dublin",
            label: "Management Fees",
            type: "input"
        },
    ]
}

export type AccrualsValues = {
    // Fees
    fund_admin: number;
    directors_fee_eur: number;
    directors_meeting_expenses: number;
    auditors_fee_eur: number;
    other_professional_fees_pwc_uk_rfs_gbp: number;
    legal_fees_annual: number;
    sub_custody_fee_trustee: number;
    custody_fee_safekeeping: number;
    misc_vat_tt_charges: number;
    ifrsa_levy_eur: number;
    offshore_registration: number;
    liability_insurance_gbp_dublin: number;
    prior_month_nav: number;

    // Deductions
    ta_fee_nt: number;
    tax_reporting_services_deloitte: number;
    incentive_fee_calculation_deloitte: number;
    custody_fee_nt: number;
    audit_fee_kpmg: number;
    fund_admin_nt: number;
    legal_fee: number;
    liability_insurance_gbp_delaware: number;

    // Delaware Deductions
    outstanding_accrual_carried_forward_delaware: number;
    deductions_to_accrued_expenses_delaware: number;
    management_fee_paid_delaware: number;

    // Dublin Deductions
    outstanding_accrual_carried_forward_dublin: number;
    deductions_to_accrued_expenses_dublin: number;
    management_fees_dublin: number;

    fund_region: string;
}
