import { setValidToken } from "utils/user.utils";

// Logger
import Logger from "utils/logger.utils";
const Log = new Logger();
Log.init("database.utils");

const getHeaders = () => {
    let newHeaders;

    if (process.env.REACT_APP_SIGNIN_METHOD === "EMAIL") {
        newHeaders = {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        };
    } else if (process.env.REACT_APP_SIGNIN_METHOD === "SSO") {
        newHeaders = {
            "gim-token": localStorage.getItem("gim-token"),
            "gim-user-id": localStorage.getItem("gim-user-id"),
            "gim-expiry": localStorage.getItem("gim-expiry"),
            "gim-audience": localStorage.getItem("gim-audience"),
        };
    }

    return newHeaders;
};

export const get = async (path) => {
    Log.info("get(): ", path);

    await setValidToken();
    return await fetch(process.env.REACT_APP_BACKEND_URL + path, {
        headers: {
            "Content-Type": "application/json",
            ...getHeaders(),
        },
        method: "GET",
    });
};

export const remove = async (path) => {
    Log.info("remove(): ", path);

    await setValidToken();
    return await fetch(process.env.REACT_APP_BACKEND_URL + path, {
        headers: {
            "Content-Type": "application/json",
            ...getHeaders(),
        },
        method: "DELETE",
    });
};

export const put = async (path, data) => {
    Log.info("put(): ", path, data);

    await setValidToken();
    return await fetch(process.env.REACT_APP_BACKEND_URL + path, {
        headers: {
            "Content-Type": "application/json",
            ...getHeaders(),
        },
        method: "PUT",
        body: JSON.stringify(data),
    });
};

export const post = async (path, data) => {
    Log.info("post(): ", path, data);

    await setValidToken();
    return await fetch(process.env.REACT_APP_BACKEND_URL + path, {
        headers: {
            "Content-Type": "application/json",
            ...getHeaders(),
        },
        method: "POST",
        body: JSON.stringify(data),
    });
};

export const patch = async (path, data) => {
    Log.info("patch(): ", path, data);

    await setValidToken();
    return await fetch(process.env.REACT_APP_BACKEND_URL + path, {
        headers: {
            "Content-Type": "application/json",
            ...getHeaders(),
        },
        method: "PATCH",
        body: JSON.stringify(data),
    });
};

export const login = async (path, data) => {
    return await fetch(process.env.REACT_APP_BACKEND_URL + path, {
        headers: {
            "Content-Type": "application/json",
            ...getHeaders(),
        },
        method: "POST",
        body: JSON.stringify(data),
    });
};

export const postFile = async (path, formData) => {
    Log.info("postFile(): ", path, formData);

    await setValidToken();
    return await fetch(process.env.REACT_APP_BACKEND_URL + path, {
        headers: {
            // "Content-Type": "multipart/form-data",
            ...getHeaders(),
        },
        method: "POST",
        body: formData,
    });
};
