import { useEffect, useState } from "react";
import {useLocation} from "react-router";
import { useHistory } from "react-router-dom";

// Styles
import SeparateAccountsContainer from "./SeparateAccounts.styles";

// Components
import Select from "components/Common/Select/Select";
import Tabs from "components/Common/Tabs/Tabs";
import SeparateAccountsGlobal from "./Global/SeparateAccountsGlobal";

// Utils
import {getSACPeriodQuarterOptions} from "utils/fees.utils";

interface SeparateAccountsProps {}

const SeparateAccounts = ({}: SeparateAccountsProps) => {
    const history = useHistory();
    const location = useLocation();
    const [showPeriods, setShowPeriods] = useState<boolean>(true);

    const urlParams = new URLSearchParams(window.location.search);
    let current_period = urlParams.get("period") || "2022M1"; // getCurrentSACPeriod();
    if (current_period === undefined || current_period === "latest")
        current_period = "2022M1";
    let subTab = urlParams.get("subTab") || "latest";
    const [selectedPeriod, setSelectedPeriod] =
        useState<string>(current_period);
    let sacPeriodOptions = getSACPeriodQuarterOptions();

    const toggleQuarterSelector = () => {
       const subTab = urlParams.get("subTab");

        if (subTab == "historic") {
            setShowPeriods(false);
        } else {
            setShowPeriods(true);
        }
    };

    useEffect(() => {
        history.push(
            `/fees/separate-accounts?tab=global&subTab=${subTab}&period=${selectedPeriod}`
        );
    }, [selectedPeriod]);

    useEffect(() => {
        toggleQuarterSelector();
    }, []);

    return (
        <SeparateAccountsContainer>
            <p className={"sectionTitle"}>SEPARATE ACCOUNTS</p>
            <div className={"periodSelect"}>
                {showPeriods && <Select
                    filterName="period"
                    value={selectedPeriod}
                    setValue={setSelectedPeriod}
                    options={sacPeriodOptions}
                />}
            </div>
            <Tabs
                defaultTab="global"
                tabs={[
                    {
                        value: "global",
                        title: "GLOBAL",
                        content: (
                            <SeparateAccountsGlobal
                                region="global"
                                period={selectedPeriod}
                            />
                        ),
                        permissions: ["p_can_view_fees", "p_can_action_fees"],
                    },
                ]}
            />
        </SeparateAccountsContainer>
    );
};
export default SeparateAccounts;
