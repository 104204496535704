import styled from 'styled-components';

export default styled.div`
    .sectionTitle {
        font-size: 25px;
        letter-spacing: 0.1em;

        color: ${(props) => props.theme.pageTitleText};
        margin-bottom: 10px;
        margin-top: 20px;
    }

    .title {
        letter-spacing: 0.1em;
        color: ${(props) => props.theme.pageTitleText};
    }

    .downloadBtn {
        margin-top: -60px;
        margin-bottom: 15px;
        float: right;
    }
`;
