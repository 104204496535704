import styled from 'styled-components';

export default styled.div`
    .fundTitle { 
        font-size: 26px;
        margin-top: 10px;
        margin-bottom: 20px;
    }

    .goBackDiv {
        display: inline;
        flex-direction: row;
        cursor: pointer;
        
        .goBackElements {
            display: inline;
        }

        .goBackText {
            font-size: 25px;
            letter-spacing: 0.05em;

            color: ${(props) => props.theme.pageTitleText};
            margin-bottom: 10px;
            margin-top: 20px;
        }

        svg {
            color: ${(props) => props.theme.pageTitleText};
            height: 20px;
            width: 20px;
            margin-right: 10px;
        }
    }

    .downloadBtn {
        float: right;
    }
`;