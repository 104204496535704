import { useHistory } from "react-router-dom";

// FontAwesome
import {
    faAlignJustify,
    faBook,
    faBriefcase,
    faChartLine,
    faChartPie,
    faCheck,
    faClock,
    faCog,
    faCut,
    faDatabase,
    faHandScissors,
    faHome,
    faInfoCircle,
    faPlus,
    faSpider,
    faUser,
} from "@fortawesome/free-solid-svg-icons";

// Components
import SubMenuItem from "../../SubMenuItem/SubMenuItem";
import SubMenuTitle from "../../SubMenuTitle/SubMenuTitle";

// Styles
import DashboardsContainer from "./Dashboards.styles";

export default function Dashboards() {
    const history = useHistory();

    return (
        <DashboardsContainer className={"submenu"}>
            <div className={"top"}>
                <h1>DASHBOARDS</h1>
                <hr />
                <SubMenuItem
                    text={"Home"}
                    active={history.location.pathname === "/dashboards"}
                    icon={faHome}
                    click={() => history.push("/dashboards")}
                />
            </div>

            <div className={"bottom"}></div>
        </DashboardsContainer>
    );
}
