import colors from "assets/styles/colors";

export default {
    mainMenuBackground: colors.textNavy,
    mainMenuForeground: "white",
    mainMenuActiveForeground: colors.textNavy,

    subMenuBackground: colors.grey100,
    subMenuTitleText: colors.textNavy,
    subMenuText: colors.grey30,
    subMenuHighlight: colors.grey80,
    subMenuActive: colors.grey60,
    subMenuSeparator: colors.grey80,
    subMenuHandleBorder: colors.textNavy,

    subMenuButtonBackground: "white",
    subMenuButtonForeground: colors.textNavy,
    subMenuButtonBackgroundHover: colors.accentBlue,
    subMenuButtonForegroundHover: "white",

    subMenuBadgeBackground: colors.accentRed,
    subMenuBadgeText: "white",
    subMenuBadgeBackgroundSecondary: colors.accentOrange,
    subMenuBadgeTextSecondary: "white",

    mobileMenuButtonBackground: colors.textNavy,
    mobileMenuButtonForeground: "white",

    notificationsBackground: "white",
    notificationsHeaderBackground: colors.textNavy,
    notificationsHeaderText: "white",
    notificationsRowText: colors.grey20,
    notificationsRowBackgroundHover: colors.grey90,
};
