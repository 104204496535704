import styled from 'styled-components';

export default styled.div`
.filters {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    & > * {
        margin: 0px;
        margin-right: 10px;
    }
}
`;
