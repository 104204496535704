import styled from "styled-components";

export default styled.div`
    .lockedIcon {
        display: flex;
        color: ${(props) => props.theme.tableIconHighlight};

        font-size: 12px;
        margin-bottom: 4px;
        margin-left: 5px;
    }

    .companyCell {
        display: flex;
        flex-direction: row;
        align-items: center;

        flex-grow: 1;

        color: ${(props) => props.theme.tableIconHighlight};
    }
`;
