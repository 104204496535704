import styled from 'styled-components';
import colors from "assets/styles/colors";

export default styled.div`
    .textFieldDiv {
        margin-top: 20px;
    }

    .textField {
        margin-right: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
    }   

    .buttonDiv {
        display: flex;
    }

    .inputField {
        min-width: 250px;
        max-width: 250px;
        display: inline;
        float: left;
    }

    .metadata {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .topGrid {
        margin-bottom: 10px;
    }

    .info {
        flex-direction: column;
        margin: 0 0 20px 0;
        display: flex;

        label {
            font-size: 14px;
            color: #777777;
        }

        p {
            font-size: 18px;
            letter-spacing: 1px;
        }

        svg {
            margin-right: 8px;
            margin-bottom: -2px;

            &.blue {
                color: ${colors.accentBlue};
            }
            &.orange {
                color: ${colors.accentOrange};
            }
            &.red {
                color: ${colors.accentRed};
            }
        }
    }

    .title {
        font-size: 25px;
        letter-spacing: 0.1em;

        color: ${(props) => props.theme.pageTitleText};
    }

    .updateBtn {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .btnDiv {
        display: flex;
    }
`;