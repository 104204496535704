import styled from "styled-components";
import colors from "assets/styles/colors";

export default styled.div`
    display: flex;
    flex-direction: column;

    .selectIcon {
        color: ${colors.textNavy};
        font-size: 20px;
        cursor: pointer;
    }

    .searchBar {
        display: flex;
        flex-direction: row;
        align-items: center;

        .button {
            margin: 20px 0;
        }

        .selectedDate {
            display: flex;
            flex-grow: 1;
            text-align: center;
            justify-content: center;
            letter-spacing: 0.1em;

            span {
                color: ${colors.textNavy};
                margin-right: 10px;
                font-weight: bold;
            }
        }
    }

    .title {
        color: ${colors.textTeal};
        letter-spacing: 0.1em;
        margin: 20px 0;
        text-align: center;
    }

    .green {
        color: ${colors.accentGreen};
    }

    .red {
        color: ${colors.accentRed};
    }
`;
