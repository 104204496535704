import React from "react";

// Styles
import ResearchContainer from "./Research.styles";

// Components
import SubMenuTitle from "../../SubMenuTitle/SubMenuTitle";
import SubMenuItem from "../../SubMenuItem/SubMenuItem";

// React Router
import { useHistory } from "react-router-dom";
import {
    faClipboard,
    faPencilAlt,
    faPencilSquare,
    faClipboardList
} from "@fortawesome/free-solid-svg-icons";

interface ResearchProps {}

const Research = ({}: ResearchProps) => {
    const history = useHistory();
    return (
        <ResearchContainer className="submenu">
            <div className="top">
                <h1>RESEARCH</h1>
                <hr />
                <SubMenuItem
                    text={"Morning Meeting"}
                    active={history.location.pathname.includes(
                        "/morning-meeting"
                    )}
                    icon={faClipboard}
                    click={() => history.push("/research/morning-meeting")}
                    permissions={[
                        "P_bqmq_can_host",
                        "p_bqmq_can_view_results",
                        "p_bqmq_can_vote",
                    ]}
                />
                <SubMenuItem
                    text={"New Notes"}
                    active={history.location.pathname.includes("/new-notes")}
                    icon={faPencilAlt}
                    click={() => history.push("/research/new-notes")}
                    permissions={[
                        "P_bqmq_can_host",
                        "p_bqmq_can_view_results",
                        "p_bqmq_can_vote",
                    ]}
                />
                <SubMenuTitle
                    text="INVESTMENT CASES"
                    permissions={[
                        "P_bqmq_can_host",
                        "p_bqmq_can_view_results",
                        "p_bqmq_can_vote",
                    ]}
                />
                <SubMenuItem
                    text={"View Cases"}
                    active={history.location.pathname.includes("/investment-cases")}
                    icon={faClipboardList}
                    click={() => history.push("/research/investment-cases")}
                    permissions={[
                        "P_bqmq_can_host",
                        "p_bqmq_can_view_results",
                        "p_bqmq_can_vote",
                    ]}
                />
            </div>
        </ResearchContainer>
    );
};
export default Research;
