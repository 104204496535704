import { useState } from "react";

// MaterialUI
import { Checkbox, FormControlLabel } from "@mui/material";

// Components
import PageTitle from "components/Common/PageTitle/PageTitle";
import Button from "components/Common/Button/Button";
import DateSelect from "components/Common/DateSelect/DateSelect";

// Utils
import * as Database from "utils/database.utils";
import { createLocalDownload } from "utils/export.utils";
import { resultIsJson } from "utils/common.utils";

// Context
import { useSettings } from "context/Settings.context";

// Styles
import ExportBQMQContainer from "./ExportBQMQ.styles";

export default function ExportBQMQ() {
    const { setShowLoadingToast, setToast } = useSettings();

    const [startDate, setStartDate] = useState<Date | undefined>(undefined);
    const [endDate, setEndDate] = useState<Date | undefined>(undefined);

    const [onlyCurrentEmployees, setOnlyCurrentEmployees] = useState(false);
    const [onlyLastVote, setOnlyLastVote] = useState(false);

    const exportData = async () => {
        // setShowLoadingToast({
        //     show: true,
        //     message: "EXPORTING",
        // });
        // const data = {
        //     start_date:
        //         startDate === undefined
        //             ? "All"
        //             : new Date(startDate).toISOString().slice(0, 10),
        //     end_date:
        //         endDate === undefined
        //             ? "All"
        //             : new Date(endDate).toISOString().slice(0, 10),
        //     only_current_employees: onlyCurrentEmployees,
        //     only_latest_vote: onlyLastVote,
        // };
        // const res = await Database.post(
        //     "api/data_extraction/bq_mq_voting",
        //     data
        // );
        // const isJson = await resultIsJson(res);
        // if (isJson) {
        //     const data = await res.json();
        //     setToast({
        //         show: true,
        //         type: "error",
        //         message: data.message,
        //     });
        // } else {
        //     const file = await res.blob();
        //     createLocalDownload("BQ MQ EditVotes Data Export.csv", file);
        // }
        // setShowLoadingToast({
        //     show: false,
        //     message: "EXPORTING",
        // });
    };

    return (
        <ExportBQMQContainer>
            <PageTitle title="EXPORT BQMQ" />

            <div className={"form-row"}>
                <DateSelect
                    placeholder="Start Date"
                    setValue={setStartDate}
                    value={startDate}
                    error={false}
                />
                <DateSelect
                    placeholder="End Date"
                    setValue={setEndDate}
                    value={endDate}
                    error={false}
                />
            </div>

            <FormControlLabel
                label="Only current employees"
                labelPlacement="end"
                control={
                    <Checkbox
                        disableRipple
                        disableFocusRipple
                        checked={onlyCurrentEmployees}
                        onClick={() =>
                            setOnlyCurrentEmployees(!onlyCurrentEmployees)
                        }
                    />
                }
            />
            <FormControlLabel
                label="Only last vote"
                labelPlacement="end"
                control={
                    <Checkbox
                        disableRipple
                        disableFocusRipple
                        checked={onlyLastVote}
                        onClick={() => setOnlyLastVote(!onlyLastVote)}
                    />
                }
            />

            <Button text="DOWNLOAD" color="primary" click={exportData} />
        </ExportBQMQContainer>
    );
}
