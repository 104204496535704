import React, { useEffect, useState } from "react";
import { Tooltip } from "@mui/material";

// Styles
import DealIconContainer from "./DealIcon.styles";

// Types
import {FullDealDetails} from "assets/interfaces/growth_voting";

// FontAwesome
import {
    faCheckCircle,
    faXmarkCircle
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


interface Porps {
    deal: FullDealDetails|null;
}


const DealIcon  = ({deal}: Porps) => {
    const [open, setOpen] = useState<boolean>(false);

    const getTooltip = () => {
        let name = deal?.company?.name + " - " + deal?.funding_round;


        if (open) {
            return "Open " + name;
        } else {
            return "Closed " + name;
        }
    }

    useEffect(() => {
        if (deal) {
            setOpen(deal.open ? deal.open : false);
        }
    }, [deal]);

    useEffect(() => {
        if (deal) {
            setOpen(deal.open ? deal.open : false);
        }
    }, []);


    return (
        <DealIconContainer>
            <Tooltip title={getTooltip()} placement="top">
                <div className={"deal-icon " + (deal?.open ? 'open' : 'closed')}>
                    {!open && <FontAwesomeIcon className="icon" icon={faXmarkCircle} fixedWidth />}
                    {open && <FontAwesomeIcon className="icon" icon={faCheckCircle} fixedWidth />}
                </div>
            </Tooltip>
        </DealIconContainer>
    );
};

export default DealIcon
