// Material UI
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/lab/Alert";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

// Utils
import { useSettings } from "context/Settings.context";

// Styles
import ToastContainer from "./Toast.styles";

export default function Toast() {
    const { toast, setToast } = useSettings();
    const { show, type, message } = toast;

    const handleClose = () => {
        setToast({
            show: false,
            type: toast.type,
            message: toast.message,
        });
    };

    return (
        <ToastContainer>
            <Snackbar
                data-cy="toast"
                open={show}
                autoHideDuration={6000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
                <Alert
                    severity={type}
                    className="toast"
                    onClick={handleClose}
                    action={<FontAwesomeIcon icon={faTimes} fixedWidth />}
                >
                    {message}
                </Alert>
            </Snackbar>
        </ToastContainer>
    );
}
