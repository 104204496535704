export const gbl_process_table = [
    {
        text: "model_metrics",
        type: "table",
        value: "model_metrics",
        fields: [
            {
                field: "extraction_id",
                type: "String(36)",
                primaryKey: true,
                nullable: false,
            },
            {
                field: "company",
                type: "String(50)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "company_extract_group",
                type: "String(60)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "extraction_datetime",
                type: "DateTime",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "processing_datetime",
                type: "DateTime",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "ws",
                type: "String(10)",
                primaryKey: true,
                nullable: false,
            },
            {
                field: "metric",
                type: "String(40)",
                primaryKey: true,
                nullable: false,
            },
            {
                field: "metric_id",
                type: "String(51)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "metric_name_db",
                type: "String(50)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "metric_root",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "metric_root_name_db",
                type: "String(50)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "metric_year_notation",
                type: "String(6)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "cell_ref",
                type: "String(10))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ws_metric_only",
                type: "SmallInteger)",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "hidden_flag",
                type: "SmallInteger)",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "wb_ref_type",
                type: "String(10))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "cell_type",
                type: "String(10))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "cell_type_complete",
                type: "String(10)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "cell_formula",
                type: "String(30000))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "cell_data_exists",
                type: "SmallInteger)",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "cell_value",
                type: "String(30000))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "cell_value_numeric",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "cell_value_str",
                type: "String(30000))",
                primaryKey: false,
                nullable: true,
            },
        ],
    },
    {
        text: "model_metrics_completed",
        type: "table",
        value: "model_metrics_completed",
        fields: [
            {
                field: "extraction_id",
                type: "String(36)",
                primaryKey: true,
                nullable: false,
            },
            {
                field: "company",
                type: "String(50)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "company_extract_group",
                type: "String(60)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "extraction_datetime",
                type: "DateTime",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "processing_datetime",
                type: "DateTime",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "ws",
                type: "String(10)",
                primaryKey: true,
                nullable: false,
            },
            {
                field: "metric",
                type: "String(40)",
                primaryKey: true,
                nullable: false,
            },
            {
                field: "metric_id",
                type: "String(51)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "metric_name_db",
                type: "String(50)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "metric_root",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "metric_root_name_db",
                type: "String(50)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "metric_year_notation",
                type: "String(6)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "cell_ref",
                type: "String(10))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ws_metric_only",
                type: "SmallInteger)",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "hidden_flag",
                type: "SmallInteger)",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "wb_ref_type",
                type: "String(10))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "cell_type",
                type: "String(10))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "cell_type_complete",
                type: "String(10)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "cell_formula",
                type: "String(30000))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "cell_data_exists",
                type: "SmallInteger)",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "cell_value",
                type: "String(30000))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "cell_value_numeric",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "cell_value_str",
                type: "String(30000))",
                primaryKey: false,
                nullable: true,
            },
        ],
    },
    {
        text: "model_metrics_holding",
        type: "table",
        value: "model_metrics_holding",
        fields: [
            {
                field: "extraction_id",
                type: "String(36)",
                primaryKey: true,
                nullable: false,
            },
            {
                field: "company",
                type: "String(50)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "company_extract_group",
                type: "String(60)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "extraction_datetime",
                type: "DateTime",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "processing_datetime",
                type: "DateTime",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "ws",
                type: "String(10)",
                primaryKey: true,
                nullable: false,
            },
            {
                field: "metric",
                type: "String(40)",
                primaryKey: true,
                nullable: false,
            },
            {
                field: "metric_id",
                type: "String(51)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "metric_name_db",
                type: "String(50)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "metric_root",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "metric_root_name_db",
                type: "String(50)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "metric_year_notation",
                type: "String(6)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "cell_ref",
                type: "String(10))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ws_metric_only",
                type: "SmallInteger)",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "hidden_flag",
                type: "SmallInteger)",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "wb_ref_type",
                type: "String(10))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "cell_type",
                type: "String(10))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "cell_type_complete",
                type: "String(10)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "cell_formula",
                type: "String(30000))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "cell_data_exists",
                type: "SmallInteger)",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "cell_value",
                type: "String(30000))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "cell_value_numeric",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "cell_value_str",
                type: "String(30000))",
                primaryKey: false,
                nullable: true,
            },
        ],
    },
    {
        text: "model_metrics_raw",
        type: "table",
        value: "model_metrics_raw",
        fields: [
            {
                field: "extraction_id",
                type: "String(36)",
                primaryKey: true,
                nullable: false,
            },
            {
                field: "ws",
                type: "String(10)",
                primaryKey: true,
                nullable: false,
            },
            {
                field: "metric",
                type: "String(40)",
                primaryKey: true,
                nullable: false,
            },
            {
                field: "metric_id",
                type: "String(51)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "cell_ref",
                type: "String(10))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ws_metric_only",
                type: "SmallInteger)",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "hidden_flag",
                type: "SmallInteger)",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "wb_ref_type",
                type: "String(10))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "cell_type",
                type: "String(10))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "cell_type_complete",
                type: "String(10))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "cell_formula",
                type: "String(30000))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "cell_data_exists",
                type: "SmallInteger)",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "cell_value",
                type: "String(30000))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "cell_value_numeric",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "cell_value_str",
                type: "String(30000))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "id",
                type: "String(10))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "target",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
        ],
    },
    {
        text: "model_process_meta",
        type: "table",
        value: "model_process_meta",
        fields: [
            {
                field: "extraction_id",
                type: "String(36)",
                primaryKey: true,
                nullable: true,
            },
            {
                field: "company",
                type: "String(50)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "company_extract_group",
                type: "String(60)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "extraction_datetime",
                type: "DateTime",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "processing_datetime",
                type: "DateTime",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "processing_method",
                type: "String(10)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "model_status",
                type: "String(3)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "model_initial_success_flag",
                type: "SmallInteger",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "model_in_holding_flag",
                type: "SmallInteger",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "num_errors_critical",
                type: "Integer)",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "num_errors_warning",
                type: "Integer)",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "num_errors_info",
                type: "Integer)",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "model_errors_ignored_flag",
                type: "SmallInteger",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "model_deleted_flag",
                type: "SmallInteger",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "model_replaced_flag",
                type: "SmallInteger",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "user_id",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "user_notes",
                type: "String(2000))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fixes_id",
                type: "String(36))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "current_model_flag",
                type: "SmallInteger",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "include_model_flag",
                type: "SmallInteger",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "last_modified_datetime",
                type: "DateTime)",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "last_modified_by",
                type: "String(30))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "folder_path",
                type: "String(100))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "model_h1_start_date",
                type: "Date",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "model_h1_end_date",
                type: "Date",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "model_h1_year",
                type: "Integer",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "reporting_currency",
                type: "String(10)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "listing_currency",
                type: "String(10)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "currency_differs_flag",
                type: "SmallInteger",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "company_id",
                type: "Integer",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "company_ref_id",
                type: "Integer",
                primaryKey: false,
                nullable: false,
            },
        ],
    },
    {
        text: "model_process_meta_raw",
        type: "table",
        value: "model_process_meta_raw",
        fields: [
            {
                field: "extraction_id",
                type: "String(36)",
                primaryKey: true,
                nullable: true,
            },
            {
                field: "company",
                type: "String(50)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "company_extract_group",
                type: "String(60)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "extraction_datetime",
                type: "DateTime",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "processing_datetime",
                type: "DateTime",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "processing_method",
                type: "String(10)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "last_modified_datetime",
                type: "DateTime)",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "last_modified_by",
                type: "String(30))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "created_datetime",
                type: "DateTime)",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "created_by",
                type: "String(30))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "folder_path",
                type: "String(100))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fixes_id",
                type: "String(36))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "company_id",
                type: "Integer",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "company_ref_id",
                type: "Integer",
                primaryKey: false,
                nullable: false,
            },
        ],
    },
    {
        text: "validation_results",
        type: "table",
        value: "validation_results",
        fields: [
            {
                field: "extraction_id",
                type: "String(36)",
                primaryKey: true,
                nullable: false,
            },
            {
                field: "company",
                type: "String(50)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "company_extract_group",
                type: "String(60)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "extraction_datetime",
                type: "DateTime",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "processing_datetime",
                type: "DateTime",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "v_model_tab",
                type: "String(10)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "v_metric",
                type: "String(40)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "v_metric_id",
                type: "String(51)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "metric_name_db",
                type: "String(50)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "error_id",
                type: "Integer",
                primaryKey: true,
                nullable: false,
            },
            {
                field: "error_id_desc",
                type: "String(100))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "error_flag",
                type: "SmallInteger",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "error_severity",
                type: "String(8))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "error_desc",
                type: "String(100))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "metric_exists",
                type: "SmallInteger",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "v_data_type_assumed",
                type: "String(10)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "v_data_type_enforced",
                type: "String(10))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "v_data_type_enforced_errort",
                type: "String(8))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "v_can_be_blank_or_missing",
                type: "String(1))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "v_can_be_blank_or_missing_errort",
                type: "String(8))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "v_can_be_error",
                type: "String(1))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "v_can_be_error_errort",
                type: "String(8))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "v_min_value",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "v_min_value_errort",
                type: "String(8))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "v_max_value",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "v_max_value_errort",
                type: "String(8))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "v_model_delta_min_value",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "v_model_delta_min_value_errort",
                type: "String(8))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "v_model_delta_max_value",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "v_model_delta_max_value_errort",
                type: "String(8))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "wb_ref_type",
                type: "String(10))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "cell_type_complete",
                type: "String(10))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "cell_formula",
                type: "String(30000))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "cell_data_exists",
                type: "SmallInteger)",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "cell_value",
                type: "String(30000))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "cell_value_numeric",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "cell_value_str",
                type: "String(30000))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "cell_formula_p",
                type: "String(30000))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "cell_data_exists_p",
                type: "SmallInteger)",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "cell_value_p",
                type: "String(30000))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "cell_value_numeric_p",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "cell_value_str_p",
                type: "String(30000))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "version_metric_in_both_flag",
                type: "SmallInteger)",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "version_metric_in_new_only_flag",
                type: "SmallInteger)",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "version_metric_in_prev_only_flag",
                type: "SmallInteger)",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "version_change_flag",
                type: "SmallInteger)",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "version_delta_diff_numeric",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "version_delta_numeric",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
        ],
    },
];
