import styled from 'styled-components';

export default styled.div`
    .page-title {
        font-size: 25px;
        color: ${(props) => props.theme.pageTitleText};
        letter-spacing: 0.1em;
        margin-bottom: 20px;
        margin-top: 20px;
    }
`
