import styled from "styled-components";
import colors from "assets/styles/colors";

export default styled.div`
    p {
        line-height: 150%;
        letter-spacing: 0.05em;

        span {
            color: ${colors.accentRed};
            font-weight: bolder;
            cursor: pointer;
            margin-left: 5px;
            :hover {
                color: ${colors.accentRedDark};
            }
        }
    }

    .title {
        letter-spacing: 0.1em;
        color: ${colors.textNavy};
    }

    .selectIcon {
        color: ${colors.textNavy};
        font-size: 20px;
        cursor: pointer;
    }

    .searchBar {
        max-width: 100%;
        min-width: 100%;
        display: flex;
        align-items: center;
        z-index: 10;

        .addNewButton {
            border-radius: 5px;
            background-color: ${colors.accentRed};
            height: 40px;
            margin: 10px 0 10px 20px;
            padding: 0 15px;
            justify-content: center;
            align-items: center;
            display: flex;
            cursor: pointer;

            p {
                margin: 0;
                font-size: 18px;
                color: white;
            }

            :hover {
                background-color: ${colors.accentRedDark};
            }
        }
    }

    .backBtn {
        border-radius: 5px;
        background-color: ${colors.textNavy};
        height: 40px;
        margin: 20px 0 10px 0;
        padding: 0 15px;
        justify-content: center;
        align-items: center;
        display: flex;
        cursor: pointer;
        max-width: fit-content;

        p {
            margin: 0;
            font-size: 18px;
            color: white;
        }

        :hover {
            background-color: ${colors.textNavyDark};
        }
    }

    .buttonsRow {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 0 -10px;

        .buttonsRowNewUpdate{
            display: flex;
            flex-direction: row;
            margin: 0px;
        }
    }
    .noData {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 250px;

        svg {
            font-size: 50px;
            color: ${colors.teal60};
            margin-bottom: 20px;
        }
        .spinner {
            animation: spin 2s linear infinite;
        }

        p {
            color: ${colors.textTeal};
            letter-spacing: 0.1em;
        }
    }
`;
