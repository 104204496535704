import React, { useEffect, useState } from "react";

// Types and Data
import { scenarioInputs } from "../types";

// Styles
import NewScenarioContainer from "./NewScenario.styles";

// Components
import TextField from "components/Common/TextField/TextField";
import Button from "components/Common/Button/Button";

// Font Awesome
import { faEye, faSave } from "@fortawesome/free-solid-svg-icons";
import Select from "components/Common/Select/Select";
import NewScenarioModal from "./NewScenarioModal/NewScenarioModal";
import { Tooltip } from "@mui/material";
import ScenarioInputFields from "../../ScenarioInputFields/ScenarioInputFields";

interface NewScenarioProps {}

const NewScenario = ({}: NewScenarioProps) => {
    const [newScenarioValues, setNewScenarioValues] = useState<any>({});
    const [showNewScenarioModal, setShowNewScenarioModal] =
        useState<boolean>(false);

    const handleChange = (value: any, name: string) => {
        if (value >= -1 && value <= 1) {
            setNewScenarioValues({
                ...newScenarioValues,
                [name]: value,
            });
        }
    };

    const createScenarioObject = () => {
        scenarioInputs.map((scenario) => {
            for (let i = 1; i <= 12; i++) {
                newScenarioValues[scenario.name + "_q" + i] = 0.0;
            }
        });
    };

    useEffect(() => {
        createScenarioObject();
    }, []);

    return (
        <NewScenarioContainer>
            <p className="fundTitle">New scenario</p>
            <div className="">
                <ScenarioInputFields
                    handleChange={handleChange}
                    newScenarioValues={newScenarioValues}
                />
            </div>
            <br />
            <div className="buttonDiv">
                {/* <Button
                    text="Run and view"
                    icon={faEye}
                    // disabled={blockButton}
                    click={() => {}}
                /> */}
                <Button
                    text="Save scenario"
                    icon={faSave}
                    click={() => setShowNewScenarioModal(true)}
                />
            </div>
            <br />

            {Boolean(showNewScenarioModal) && (
                <NewScenarioModal
                    newScenarioValues={newScenarioValues}
                    setShowModal={setShowNewScenarioModal}
                />
            )}
        </NewScenarioContainer>
    );
};
export default NewScenario;
