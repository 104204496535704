import styled from 'styled-components';

export default styled.div`
    .team {
        margin-bottom: 20px;

    }
    .remove {
         cursor: pointer;
         text-align: right;
         padding-right: 10px;
    }
    .loading {
        margin: 30px 0px;
    }
`;
