import styled from "styled-components";

export default styled.div`
    min-height: 100%;
    padding: 3rem;
    background-color: #c4d6d1;
    margin: 0px -20px 0px -20px;

    h1 {
        font-size: 4rem;
    }

    .form {
        border-radius: 4px;
        padding: 5rem;
        background: #c4d6d1;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        box-shadow: 1px 1px 4px #a1afab, -1px -1px 4px #e7fdf7;

        .form-title {
            padding-left: 1rem;
        }

        .hr {
            width: 100%;
            height: 5px;
            border-radius: 50%;
            background: #c4d6d1;
            box-shadow: inset 1px 1px 2px #a1afab, inset -1px -1px 2px #e7fdf7;
        }

        .form-options {
            display: flex;
            flex-direction: row;
            gap: 2rem;
            padding-left: 1rem;

            .submission-details {
                font-size: 2.5rem;
                color: ${(props) => props.theme.pageTitleText};
                letter-spacing: 1.7px;
                font-weight: 400;
                display: flex;
                flex-direction: row;
            }
        }

        .neumorphic {
            padding: 0.5rem;
            border-radius: 4px;
            background: #c4d6d1;
            box-shadow: 1px 1px 4px #a1afab, -1px -1px 4px #e7fdf7;
            div {
                margin: 0;
            }
        }

        .panel {
            padding: 0.5rem;
            border-radius: 4px;
            background: #c4d6d1;
            /* box-shadow: 1px 1px 4px #a1afab, -1px -1px 4px #e7fdf7; */
            div {
                margin: 0;
                width: 100%;
            }

            &.skeleton {
                width: 100%;
            }

            &.question {
                width: 100%;
                padding: 1rem;
            }

            .question-wrapper {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 1rem;
                &.child {
                    margin: 1rem 1rem 0rem 1rem;
                    padding: 1rem;
                }
                .question-text {
                    width: 100%;
                    font-size: 2em;
                    color: ${(props) => props.theme.pageTitleText};

                    .required-indicator {
                        color: ${(props) => props.theme.accentButtonBackground};
                    }
                }

                .question-field {
                    width: fit-content;
                    box-shadow: 1px 1px 4px #a1afab, -1px -1px 4px #e7fdf7;
                    &.text-area {
                        width: 100%;
                        textarea {
                            margin: 0;
                        }
                    }
                    &.number {
                        width: fit-content;
                    }
                }
            }
        }
    }
    .modal-wrapper {
        padding: 4rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .modal-title {
            color: ${(props) => props.theme.pageTitleText};
            font-size: 1.9rem;
            text-align: center;
        }
        .modal-subtitle {
            color: ${(props) => props.theme.pageTitleText};
            font-size: 1.5rem;
            text-align: center;
            margin: 1rem;
        }
        .modal-buttons {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin: auto;
        }
    }
    .submit {
        background: rgba(196, 214, 209, 0.41);
        border-radius: 4px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border: 1px solid rgba(196, 214, 209, 0.3);
        padding: 1rem;
        margin: 1rem;
        position: fixed;
        bottom: 0px;
        right: 0px;
        display: flex;
        justify-content: flex-end;
        gap: 1rem;
    }
`;
