export const configurationsInputFields = {
    "configurations": [
    {
        name: "blended_management_fee_dbn_asia",
        label: "Blended MF Dublin-Asia",
        type: "input"
    },
    {
        name: "blended_management_fee_dbn_gbl_equity",
        label: "Blended MF Dublin-Global Equity",
        type: "input"
    },
    {
        name: "blended_management_fee_dlw_asia",
        label: "Blended MF Delaware-Asia",
        type: "input"
    },
    {
        name: "blended_management_fee_dlw_gbl_equity",
        label: "Blended MF Delaware-Global Equity",
        type: "input"
    },
    {
        name: "dbn_asia_admin_accrual_allocation_percent",
        label: "Dublin Asia Admin Accrual Allocation %",
        type: "input"
    },
    {
        name: "dbn_asia_custody_accrual_allocation_percent",
        label: "Dublin Asia Custody Accrual Allocation %",
        type: "input"
    },
    {
        name: "dbn_gbl_equity_admin_accrual_allocation_percent",
        label: "Dublin Global Equity Admin Accrual Allocation %",
        type: "input"
    },
    {
        name: "dbn_gbl_equity_custody_accrual_allocation_percent",
        label: "Dublin Global Equity Custody Accrual Allocation %",
        type: "input",
    },
    {
        name: "duration_forwards_broker_exposure_calculation_exclusion",
        label: "Duration Forwards Broker Exposure Calculation Excl",
        type: "input"
    },
    {
        name: "esh2_index_factor",
        label: "ESH2 Index Factor",
        type: "input"
    },
    {
        name: "euro_forwards_tolerance",
        label: "Euro Forwards Tolerance",
        type: "input"
    },
    {
        name: "grand_total",
        label: "Grand Total",
        type: "input"
    },
    {
        name: "min_annual_fee",
        label: "Min Annual Fee",
        type: "input"
    },
    {
        name: "ndf_tolerance",
        label: "NDF Tolerance",
        type: "input"
    },
    {
        name: "vgh2_index_factor",
        label: "VGH2 Index Factor",
        type: "input"
    }
    ],
    "dublin_fee_sheet": [
    {
        name: "gav_global_dublin_equity_fund",
        label: "Global Equity",
        type: "input"
    },
    {
        name: "gav_asia_dublin_fund",
        label: "Asia",
        type: "input"
    },
    {
        name: "percent_band_0_200mm",
        label: "0 - 200,000,000 Fee %",
        type: "input"
    },
    {
        name: "percent_band_200mm_400mm",
        label: "200,000,000 - 400,000,000 Fee %",
        type: "input"
    },
    {
        name: "percent_band_400mm_1bn",
        label: "400,000,000 - 1,000,000,000 Fee %",
        type: "input"
    },
    {
        name: "percent_band_1bn_2bn",
        label: "1,000,000,000 - 2,000,000,000 Fee %",
        type: "input"
    },
    {
        name: "percent_band_2bn_7bn",
        label: "2,000,000,000 - 7,000,000,000 Fee %",
        type: "input"
    },
    {
        name: "percent_band_7bn_infinity",
        label: "7,000,000,000 - Infinity Fee %",
        type: "input"
    },
]
}

export type ConfigurationsInputValues = {
    //configuration
    blended_management_fee_dbn_asia: number;
    blended_management_fee_dbn_gbl_equity: number;
    blended_management_fee_dlw_asia: number;
    blended_management_fee_dlw_gbl_equity: number;
    dbn_asia_admin_accrual_allocation_percent: number,
    dbn_asia_custody_accrual_allocation_percent: number,
    dbn_gbl_equity_admin_accrual_allocation_percent: number,
    dbn_gbl_equity_custody_accrual_allocation_percent: number,
    duration_forwards_broker_exposure_calculation_exclusion: number,
    esh2_index_factor: number,
    euro_forwards_tolerance: number,
    grand_total: number,
    min_annual_fee: number,
    ndf_tolerance: number,
    vgh2_index_factor: number,
    //dublin fee sheet
    gav_global_dublin_equity_fund: number,
    gav_asia_dublin_fund: number,
    percent_band_0_200mm: number,
    percent_band_200mm_400mm: number,
    percent_band_400mm_1bn: number,
    percent_band_1bn_2bn: number,
    percent_band_2bn_7bn: number,
    percent_band_7bn_infinity: number

    signed_off_by_1: string | null,
    signed_off_dt_1: string | null,
    signed_off_by_2: string | null,
    signed_off_dt_2: string | null,
    version: number;
    id: string | null;
}