import { useEffect, useState } from "react";

// Styles
import IncomingFileHistoryContainer from "./IncomingFileHistory.styles";
import IncomingFileList from "screens/DailyRecs/Inputs/IncomingFileList/IncomingFileList";


const IncomingFileHistory = () => {
    return (
        <IncomingFileHistoryContainer>
            <div className={"page-title"}>INCOMING FILE HISTORY</div>
            <IncomingFileList />
        </IncomingFileHistoryContainer>
    );
};
export default IncomingFileHistory
