// Styles
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import TextField from "components/Common/TextField/TextField";
import { useEffect, useState } from "react";
import RadioContainer from "./Radio.styles";

interface Props {
    options: any[];
    textOption: boolean;
    handleChange: (value: string) => void;
    value: string;
}

export default function RadioField({
    options,
    textOption,
    handleChange,
    value,
}: Props) {
    const [textFieldValue, setTextFieldValue] = useState<string | null>(null);

    const handleTextFieldChange = (value: string) => {
        handleChange(`*Other - ${value}`);
        setTextFieldValue(value);
    };

    const handleChecked = (option: string) => {
        if (
            option == value ||
            (value?.includes("*Other - ") && option?.includes("Other"))
        )
            return true;
    };

    useEffect(() => {
        if (!value) return;
        if (value.includes("*Other -")) {
            setTextFieldValue(value.split("*Other - ")[1]);
        }
    }, []);

    return (
        <RadioContainer>
            <RadioGroup sx={{ width: "95%" }} defaultValue={null}>
                <div className="radio-wrapper">
                    <div className="radio-options">
                        {options.map((option: any) => (
                            <FormControlLabel
                                key={option.question_option}
                                value={option.question_option}
                                label={option.question_option}
                                control={
                                    <Radio
                                        disableRipple
                                        checked={handleChecked(
                                            option.question_option
                                        )}
                                        onChange={(event: any) => {
                                            handleChange(event.target.value);
                                            setTextFieldValue("");
                                        }}
                                        sx={{
                                            color: "#004453",
                                            "&.Mui-checked": {
                                                color: "#004453",
                                            },
                                        }}
                                    />
                                }
                            />
                        ))}
                    </div>
                    {textOption && value?.includes("Other") && (
                        <div className="text-field-wrapper">
                            <TextField
                                placeholder="Please specify.."
                                value={textFieldValue}
                                change={handleTextFieldChange}
                            />
                        </div>
                    )}
                </div>
            </RadioGroup>
        </RadioContainer>
    );
}
