import React, {useEffect, useState} from "react";


// Styles
import GrowthHomeContainer from "./GrowthHome.styles";

// Utils
import { ApiHandler } from "services/api_handler";


// Interfaces
import { FullDealDetails} from "assets/interfaces/growth_voting";

//Components
import DealList from "components/Screens/GrowthVoting/DealList/DealList";

// contenxt
import {useSettings} from "context/Settings.context";
import { useAuth } from "context/Auth.context";

const URL_DEAL_LIST = "api/growth_voting/deals?page_size=5&page=1";
const URL_PENDING_MY_VOTE = "api/growth_voting/pending-votes";


const GrowthHome = () => {
    const { setShowLoadingToast, setToast } = useSettings();
    const [pendingDeals, setPendingDeals] = useState<FullDealDetails[]>([]);
    const [recentActiveDeals, setRecentActiveDeals] = useState<FullDealDetails[]>([]);

    const { user } = useAuth();

    const fetchRecentOpenDeals = async () => {
        let response = await new  ApiHandler(URL_DEAL_LIST, setShowLoadingToast, setToast).list();
        setRecentActiveDeals(response.data.data.slice(0, 5));
    };

    const fetchPendingMyVote = async () => {
        let url = URL_PENDING_MY_VOTE + "?user_id=" + user.id;
        let response = await new  ApiHandler(url, setShowLoadingToast, setToast).list();
        // if no votes setup for the deal user cannot vote on it.
        const dealsWithVotes = response.data.filter((deal: FullDealDetails) => {return deal.votes.length > 0});

        setPendingDeals(dealsWithVotes);
    };

    useEffect(() => {
        fetchPendingMyVote();
        fetchRecentOpenDeals();
    }, []);


    return (
        <GrowthHomeContainer>
            <div className={"page-header"}>
                 <p data-cy="deals-main-title" className={"title"}>
                    HOME
                </p>
            </div>

            <div className={"section"}>DEALS PENDING MY VOTE</div>
            {pendingDeals.length > 0 && <DealList deals={pendingDeals} />}
            {pendingDeals.length === 0 && <div className={"no-result"}>No pending votes</div>}

            <div className={"section margin-top-50"}>5 MOST RECENT  DEALS</div>
            <DealList deals={recentActiveDeals} />
        </GrowthHomeContainer>
    );
};
export default GrowthHome;
