import { useState, useEffect } from "react";

// Components
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

// Styles
import ModalContainer from "./Modal.styles";

type Props = {
    loading?: boolean;
    children: React.ReactNode;
    width?: string | number;
};

export default function Modal({ loading, children, width }: Props) {
    const [loadingHeight, setLoadingHeight] = useState<number | null>(null);
    const [showLoading, setShowLoading] = useState<boolean>(false);

    useEffect(() => {
        if (loading) {
            setLoadingHeight(document.getElementById("modal")!.clientHeight);
            setShowLoading(true);
        } else {
            setShowLoading(false);
        }
    }, [loading]);

    return (
        <ModalContainer className={"modalBackdrop"}>
            <div
                className="modalBox"
                id="modal"
                style={{
                    minHeight: loadingHeight + "px",
                    width: width,
                    overflowY: "auto",
                    maxHeight: "90vh",
                }}
            >
                {showLoading ? <LoadingSpinner /> : children}
            </div>
        </ModalContainer>
    );
}
