import React, { useState } from "react";

//Components
import Button from "components/Common/Button/Button";
import DateSelect from "components/Common/DateSelect/DateSelect";
import Modal from "components/Common/Modal/Modal";
import Select from "components/Common/Select/Select";

//MUI
import { Checkbox, FormControlLabel } from "@mui/material";

//Context
import { useSettings } from "context/Settings.context";

//Utils
import { exportAllBQMQData, exportCompanyBQMQData } from "utils/export.utils";

//Styles
import ExportModalContainer from "./ExportModal.styles";

type Props = {
    open: React.Dispatch<React.SetStateAction<boolean>>;
    selectedCompany: string | null;
    selectedAnalyst?: string | null;
    companyOptions: Option[];
    analystOptions: Option[];
};

export default function ExportModal({
    open,
    selectedCompany,
    selectedAnalyst = "",
    companyOptions,
    analystOptions,
}: Props) {
    const { setShowLoadingToast, setToast } = useSettings();

    const [startDate, setStartDate] = useState<Date | undefined>(undefined);
    const [endDate, setEndDate] = useState<Date | undefined>(undefined);
    const [onlyCurrentEmployees, setOnlyCurrentEmployees] = useState(true);
    const [onlyLastVote, setOnlyLastVote] = useState(true);
    const [company, setCompany] = useState<string | null>(selectedCompany);

    const handleClose = () => {
        open(false);
    };

    const createFilename = (
        companyID: string | null,
        analystID: string | null
    ) => {
        const prefix = "BQMQ ";

        if (startDate || endDate) return `${prefix}: ${startDate} - ${endDate}`;

        if (companyID)
            return `${prefix}: ${
                companyOptions.find(
                    (company: Option) => company.value === companyID
                )?.label
            }`;

        if (analystID)
            return `${prefix}: ${
                analystOptions.find(
                    (analyst: Option) => analyst.value === analystID
                )?.label
            }`;

        return `${prefix}: All time`;
    };

    const exportData = async () => {
        const companyID = company === "" ? null : company;
        const analystID = selectedAnalyst === "" ? null : selectedAnalyst;

        const filename = createFilename(companyID, analystID);

        const exportBody = {
            start_date: startDate ? startDate : null,
            end_date: endDate ? endDate : null,
            only_current_employees: onlyCurrentEmployees,
            only_latest_vote: onlyLastVote,
            analyst_id: analystID,
            company_id: companyID,
            session_id: null,
        };
        if (!companyID) {
            await exportAllBQMQData(
                filename,
                exportBody,
                setShowLoadingToast,
                setToast
            );
        } else {
            await exportCompanyBQMQData(
                filename,
                exportBody,
                setShowLoadingToast,
                setToast
            );
        }

        handleClose();
    };

    return (
        <ExportModalContainer>
            <Modal>
                <div className="main">
                    <div className="text">
                        <h3>EXPORT BQMQ DATA</h3>
                    </div>

                    <div className="col">
                        <Select
                            options={companyOptions}
                            value={company}
                            setValue={setCompany}
                            filterName="Companies"
                            searchable
                        />
                        <div className="row">
                            <div className="half">
                                <DateSelect
                                    placeholder="Start Date (Any)"
                                    setValue={setStartDate}
                                    value={startDate}
                                    error={false}
                                />
                                <FormControlLabel
                                    label="Only current employees"
                                    labelPlacement="end"
                                    control={
                                        <Checkbox
                                            disableRipple
                                            disableFocusRipple
                                            checked={onlyCurrentEmployees}
                                            onClick={() =>
                                                setOnlyCurrentEmployees(
                                                    !onlyCurrentEmployees
                                                )
                                            }
                                        />
                                    }
                                />
                            </div>
                            <div className="half">
                                <DateSelect
                                    placeholder="End Date (Any)"
                                    setValue={setEndDate}
                                    value={endDate}
                                    error={false}
                                />
                                <FormControlLabel
                                    label="Only last vote"
                                    labelPlacement="end"
                                    control={
                                        <Checkbox
                                            disableRipple
                                            disableFocusRipple
                                            checked={onlyLastVote}
                                            onClick={() =>
                                                setOnlyLastVote(!onlyLastVote)
                                            }
                                        />
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="actions">
                    <Button text="CANCEL" color="flat" click={handleClose} />
                    <Button
                        text="EXPORT"
                        color="primary"
                        click={exportData}
                        disabled={false}
                    />
                </div>
            </Modal>
        </ExportModalContainer>
    );
}
