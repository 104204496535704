import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// Utils
import * as Database from "utils/database.utils";

// Components
import HomeList from "../../../Home/HomeList/HomeList";
import NewCompanyRow from "./NewCompanyRow/NewCompanyRow";

// Styles
import NewCompanyListContainer from "./NewCompanyList.styles";

export default function NewCompanyList() {
    const history = useHistory();

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);

    const getFailedExtractions = async () => {
        let res = await Database.get(
            "api/companies/new-companies?page=1&pageSize=1000&sortKey=first_detected_date&sortOrder=ASC"
        );
        let data = await res.json();

        setData(data.data);
        setTotal(data.total);
        setLoading(false);
    };

    useEffect(() => {
        setLoading(true);
        getFailedExtractions();
    }, []);

    return (
        <NewCompanyListContainer>
            <HomeList
                data={data}
                row={(row: any, index: number) => (
                    <NewCompanyRow data={row} index={index} />
                )}
                title={"NEW COMPANIES"}
                count={total}
                countColor={""}
                loading={loading}
                seeAll={() => history.push("/data/new-companies")}
                maxRows={5}
            />
        </NewCompanyListContainer>
    );
}
