import { useState, useEffect } from 'react';
import queryString from "query-string";

// Utils
import * as Database from "utils/database.utils";
import { formatUserFriendlyDate} from "utils/date.utils";

// Styles
import VotingSessionFilterContainer from "./VotingSessionFilter.styles";
import Select from "components/Common/Select/Select";

type Props = {
  setSelectedDateSession: React.Dispatch<React.SetStateAction<string>>;
  allCompanySessions: any;
  gotCompanySessions: boolean;
};

const VotingSessionFilter = ({setSelectedDateSession, allCompanySessions, gotCompanySessions}: Props) => {
  const companyID = queryString.parse(window.location.search).company_id;
  const [companySessions, setCompanySessions] = useState<any>(null);
  const [dateFilter, setDateFilter] = useState<string>("");
  const [sessionDates, setSessionDates] = useState<Record<string, string>>({});
  const [loadingSessionDates, setLoadingSessionDates] = useState<boolean>(false);

  useEffect(() => {
    setCompanySessions(allCompanySessions);
  }, [gotCompanySessions]);

  useEffect(() => {
    if (companySessions) {
      getSessionDates(companySessions);
    }
  }, [companySessions]);

  const handleChangeFilter = (id: any) => {
    setDateFilter(id);
    setSelectedDateSession(id);
  }


 const getSessionDates = (companySessions: any) => {
    setLoadingSessionDates(true);

    const dates: Record<string, string> = {"": "Latest Session"};

    if (companySessions) {
      companySessions.data.forEach((session: any) => {
        const formattedDate =  "Session on " + formatUserFriendlyDate(session.date);
        dates[session.id] = formattedDate;
      });
    }

    setSessionDates(dates);

    setLoadingSessionDates(true);
  };

  return (
    <VotingSessionFilterContainer className="votingSessionDate">
      {loadingSessionDates && <div>

          <div className={"session-selector"}>
          <Select
              filterName="Latest Session"
              showAll={false}
              value={dateFilter}
              setValue={handleChangeFilter}
              options={Object.entries(sessionDates).map(([sessionId, date]) => (
                  { value: sessionId, label: date }
              ))}
          />
          </div>
      </div>}
    </VotingSessionFilterContainer>
  );
};

export default VotingSessionFilter;
