import { useState, useEffect } from "react";

// Material UI
import { Grid } from "@mui/material";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookOpen } from "@fortawesome/free-solid-svg-icons";

// Components
import PageTitle from "components/Common/PageTitle/PageTitle";
import Tabs from "components/Common/Tabs/Tabs";
import PortfolioLiveData from "components/Screens/Data/PortfolioLive/PortfolioLiveData/PortfolioLiveData";
import PortfolioLiveErrors from "components/Screens/Data/PortfolioLive/PortfolioLiveErrors/PortfolioLiveErrors";
import PortfolioLiveCounts from "components/Screens/Data/PortfolioLive/PortfolioLiveCounts/PortfolioLiveCounts";
import SummarySheetMoreInfo from "components/Screens/Data/SummarySheets/SummarySheetMoreInfo/SummarySheetMoreInfo";

// Utils
import queryString from "query-string";
import { sessionCacheData, getSessionCachedData, toDateAndTime } from "utils/general.utils";
import * as Database from "utils/database.utils";

// Context
import { useSettings } from "context/Settings.context";

// Styles
import PortfolioLiveDetailContainer from "./PortfolioLiveDetail.styles";

const PortfolioLiveDetail: React.FC = () => {
    const { setShowLoadingToast, setDefaultFilters } = useSettings();

    const parsedUrl = queryString.parse(window.location.search);

    const apiUrl =
        parsedUrl.region === "asia"
            ? "api/asia_portfolio_live/"
            : "api/portfolio_live/";

    const cacheKey =
        parsedUrl.region === "asia"
            ? "asiaPortfolioLiveDetail"
            : "portfolioLiveDetail";

    const initialPorfolioLiveData = {
        id: "",
        extraction_datetime: "",
        processing_datetime: "",
        processing_method: "AUTO",
        last_modified_datetime: "",
        last_modified_by: "",
        folder_path: "",
        fixes_id: "",
        num_portfolio_companies: 0,
        num_pl_sheet_errors: 0,
        num_pl_sort_new_errors: 0,
        num_pl_sheet_vals_extracted: 0,
        num_pl_sort_new_vals_extracted: 0,
    };

    const [porfolioLiveData, setPorfolioLiveData] = useState<any>(
        initialPorfolioLiveData
    );

    const getPortfolioLive = (index: string) => {
        setShowLoadingToast({ show: true, message: "LOADING" });

        Database.get(apiUrl + index).then((res) => {
            if (res.ok) {
                res.json().then((data) => {
                    setPorfolioLiveData(data.data[0]);
                    sessionCacheData(cacheKey, index, data.data[0]);
                });
            }
        });
    };

    const getID = (): string => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        return urlParams.get("id") || "";
    };

    useEffect(() => {
        if (parsedUrl.region === "global")
            setDefaultFilters({ ws: "PANDL SHEET" });

        let oldData = getSessionCachedData(cacheKey, getID());

        if (oldData && oldData.id === getID()) {
            setPorfolioLiveData(oldData);
        } else {
            getPortfolioLive(getID());
        }
    }, []);

    return (
        <PortfolioLiveDetailContainer>
            <PageTitle
                title={
                    porfolioLiveData.extraction_datetime
                        ? "PORTFOLIO LIVE: " +
                          toDateAndTime(porfolioLiveData.extraction_datetime)
                        : "LOADING"
                }
            ></PageTitle>

            <Grid container className="metadata">
                <Grid item xs={6} md={3} className="topGrid">
                    <div className="info">
                        <label>EXTRACTION DATE</label>
                        <p>
                            {porfolioLiveData.extraction_datetime
                                ? toDateAndTime(
                                      porfolioLiveData.extraction_datetime
                                  )
                                : "LOADING"}
                        </p>
                    </div>

                    <div className="info">
                        <label>PROCESSED ON</label>
                        <p>
                            {toDateAndTime(
                                porfolioLiveData.processing_datetime
                            )}
                        </p>
                    </div>
                </Grid>
                <Grid item xs={6} md={3} className="topGrid">
                    <div className="info">
                        <label>LAST MODIFIED ON</label>
                        <p>
                            {porfolioLiveData.last_modified_datetime
                                ? toDateAndTime(
                                      porfolioLiveData.last_modified_datetime
                                  )
                                : "LOADING"}
                        </p>
                    </div>

                    <div className="info">
                        <label>LAST MODIFIED BY</label>
                        <p>{porfolioLiveData.last_modified_by}</p>
                    </div>
                </Grid>

                <Grid item xs={6} md={3} className="topGrid">
                    <div className="info">
                        <label># VALUES P&amp;L SHEET</label>
                        <p>{porfolioLiveData.num_pl_sheet_vals_extracted}</p>
                    </div>
                    <div className="info">
                        <label># VALUES P&amp;L SORT NEW</label>
                        <p>{porfolioLiveData.num_pl_sort_new_vals_extracted}</p>
                    </div>
                </Grid>

                <Grid item xs={6} md={3} className="topGrid">
                    <div className="info">
                        <label>PORTFOLIO LIVE COMPANIES</label>
                        <p>
                            <FontAwesomeIcon
                                icon={faBookOpen}
                                fixedWidth
                                className="orange"
                            />
                            {porfolioLiveData.num_portfolio_companies}
                        </p>
                    </div>
                </Grid>
            </Grid>

            <Tabs
                defaultTab="data"
                tabs={[
                    {
                        value: "data",
                        title: "DATA",
                        content: (
                            <PortfolioLiveData apiUrl={apiUrl} id={getID()} />
                        ),
                    },
                    {
                        value: "more-info",
                        title: "MORE INFO",
                        content: (
                            <SummarySheetMoreInfo
                                summarySheetData={porfolioLiveData}
                            />
                        ),
                    },
                    {
                        value: "errors",
                        title: "ERRORS",
                        icon: (
                            <PortfolioLiveCounts
                                plErrors={porfolioLiveData.num_pl_sheet_errors}
                                plSortNewErrors={
                                    porfolioLiveData.num_pl_sort_new_errors
                                }
                            />
                        ),
                        content: (
                            <PortfolioLiveErrors apiUrl={apiUrl} id={getID()} />
                        ),
                    },
                ]}
            />
        </PortfolioLiveDetailContainer>
    );
};

export default PortfolioLiveDetail;
