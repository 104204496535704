import styled from "styled-components";

export default styled.div`
    margin-top: 20px;

    .companyModeBtns {
        display: flex;
        flex-direction: row;
        margin: 0 -10px 20px -10px;

        .companyModeBtn {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            flex-grow: 1;
            margin: 0 10px;

            min-height: 150px;

            background-color: ${(props) => props.theme.filterButtonBackground};
            color: ${(props) => props.theme.filterButtonForeground};

            border-radius: 5px;

            svg {
                font-size: 50px;
                margin-bottom: 20px;
                color: ${(props) => props.theme.filterButtonBackgroundActive};
            }

            :hover {
                background-color: ${(props) =>
                    props.theme.filterButtonBackgroundHover};
                cursor: pointer;
            }
        }
    }

    .company {
        display: flex;
        flex-direction: row;

        min-height: 50px;
        max-height: 50px;

        min-width: 100%;

        margin-bottom: 20px;

        font-size: 18px;

        .label {
            display: flex;
            justify-content: center;
            align-items: center;

            border-radius: 5px 0 0 5px;

            background-color: #004453;
            color: white;

            padding: 0 15px;

            min-width: 150px;
            max-width: 150px;
        }

        .textBoxContainer {
            display: flex;
            justify-content: center;
            align-items: center;

            border-radius: 0 5px 5px 0;

            border: 1px solid #dddddd;
            outline: none;
            padding: 10px;

            font-size: 18px;

            flex-grow: 1;

            .textBox {
                display: flex;
                justify-content: center;
                align-items: center;

                border-radius: 0 5px 5px 0;

                border: none;
                outline: none;
                padding: 10px;

                font-size: 18px;

                background-color: white;

                flex-grow: 1;
            }

            .clearCompanyBtn {
                display: flex;
                justify-content: center;
                align-items: center;

                min-height: 50px;
                max-height: 50px;

                cursor: pointer;

                color: #004453;
            }
        }
    }
`;
