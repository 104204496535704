// Components
import PageTitle from "components/Common/PageTitle/PageTitle";

// Styles
import PortfolioLiveListContainer from "./PortfolioLiveList.styles";
import Tabs from "components/Common/Tabs/Tabs";
import PortfolioLive from "./PortfolioLive";

const PortfolioLiveList: React.FC = () => {
    return (
        <PortfolioLiveListContainer>
            <PageTitle title="PORTFOLIO LIVE" />
            <Tabs
                defaultTab="global"
                tabs={[
                    {
                        value: "global",
                        title: "GLOBAL",
                        content: (
                            <PortfolioLive
                                region="global"
                                apiUrl="api/portfolio_live"
                            />
                        ),
                    },
                    {
                        value: "asia",
                        title: "ASIA",
                        content: (
                            <PortfolioLive
                                region="asia"
                                apiUrl="api/asia_portfolio_live"
                            />
                        ),
                    },
                ]}
            />
        </PortfolioLiveListContainer>
    );
};

export default PortfolioLiveList;
