import { useEffect, useState } from "react";

// Utils
import queryString from "query-string";

// Components
import LoadingSpinner from "components/Common/LoadingSpinner/LoadingSpinner";
import Tabs from "components/Common/Tabs/Tabs";
import ResultsHeader from "components/Screens/BQMQ/Results/ResultsHeader/ResultsHeader";
import SummaryCard from "components/Screens/BQMQ/Results/SummaryCard/SummaryCard";
import VotingSessionFilter from "components/Screens/BQMQ/Results/VotingSessionFilter/VotingSessionFilter";

// Utils
import { factors, getMode, getResults, isCoreResults, getAllCompanySessions} from "utils/bqmq.utils";
import * as Database from "utils/database.utils";

// Context
import { useBQMQ } from "context/BQMQ.context";
import { useSettings } from "context/Settings.context";

// Styles
import InvitedUserModal from "components/Screens/BQMQ/Results/InvitedUserModal/InvitedUserModal";
import CompanyResultDetailContainer from "./CompanyResultDetail.styles";

const getSummaryCards = (
    results: any,
    voteCountMode: string,
    setVoteCountMode: any
) => {
    return (
        <div className={"summaryRow"}>
            {factors.map(
                (factor: { name: string }) =>
                    factors &&
                    results && (
                        <SummaryCard
                            factor={factor.name}
                            results={results[factor.name]}
                            mode={getMode(factor.name, results)}
                            usersVotes={results.votes}
                            comments={results.comments[factor.name]}
                            key={factor.name}
                            voteCountMode={voteCountMode}
                            setVoteCountMode={setVoteCountMode}
                            editVote
                        />
                    )
            )}
        </div>
    );
};

export default function SessionResultDetail() {
    const { setShowLoadingToast } = useSettings();
    const { showFullUserList, setDefaultChoices } = useBQMQ();
    const [loading, setLoading] = useState(true);
    const [sessionCoreResults, setSessionCoreResults] = useState<any>(null);
    const [previousVotes, setPreviousVotes] = useState<any>(null);
    const [sessionAllResults, setSessionAllResults] = useState<any>(null);
    const [voteCountMode, setVoteCountMode] = useState<"QUESTION" | "USER">(
        "QUESTION"
    );
    const companyID = queryString.parse(window.location.search).company_id;
    const sessionID = queryString.parse(window.location.search).session_id || 0;

    const [selectedDateSession, setSelectedDateSession] = useState<string>("");
    const [companySessions, setCompanySessions] = useState<any>(null);
    const [gotCompanySessions, setGotCompanySessions] = useState<boolean>(false);

    useEffect(() => {
        getAllCompanySessions(setCompanySessions, companyID, sessionID);
    }, [companyID, sessionID]);

    useEffect(() => {
        if (companySessions) {
            setGotCompanySessions(true);
    }}, [companySessions]);

    useEffect(() => {
        if (selectedDateSession !== "") {
            getResults(
                companyID,
                selectedDateSession,
                setDefaultChoices,
                setSessionAllResults,
                setSessionCoreResults,
                setPreviousVotes
            );
        }
        else {
            setShowLoadingToast({
                show: true,
                message: "LOADING COMPANY RESULTS",
            });
            getResults(
                companyID,
                sessionID,
                setDefaultChoices,
                setSessionAllResults,
                setSessionCoreResults,
                setPreviousVotes
            );
            setLoading(false);
            setShowLoadingToast({
                show: false,
                message: "LOADING COMPANY RESULTS",
            });
        }
    }, [selectedDateSession, companySessions]);

    useEffect(() => {
    }, [sessionAllResults]);

    return (
        <CompanyResultDetailContainer className={"pageContainer"}>
            <ResultsHeader
                reload={() => console.log("reload")}
                sessionData={sessionAllResults}
            />
            <VotingSessionFilter 
            setSelectedDateSession={setSelectedDateSession} 
            allCompanySessions={companySessions} 
            gotCompanySessions={gotCompanySessions} 
            />
            <div className="res-container">
                {!loading ? (
                    <Tabs
                        defaultTab={
                            isCoreResults(sessionCoreResults) ? "CORE" : "ALL"
                        }
                        dontChangeUrl
                        tabs={[
                            {
                                title: "CORE",
                                value: "CORE",
                                content: getSummaryCards(
                                    sessionCoreResults,
                                    voteCountMode,
                                    setVoteCountMode
                                ),
                                hide: !isCoreResults(sessionCoreResults),
                            },
                            {
                                title: "ALL",
                                value: "ALL",
                                content: getSummaryCards(
                                    sessionAllResults,
                                    voteCountMode,
                                    setVoteCountMode
                                ),
                            },
                        ]}
                    />
                ) : (
                    <div className={"loadingScreen"}>
                        <LoadingSpinner />
                    </div>
                )}

                {showFullUserList && (
                    <InvitedUserModal
                        companyID={sessionAllResults.session.id}
                        session={sessionAllResults}
                    />
                )}
            </div>
        </CompanyResultDetailContainer>
    );
}
