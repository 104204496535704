import { useEffect, useState } from "react";

// FontAwesome
import { faCog, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// MaterialUI
import { ClickAwayListener, Tooltip } from "@mui/material";

// Context
import { useSettings } from "context/Settings.context";

// Components
import Profile from "./Profile/Profile";
import Settings from "./Settings/Settings";

// Styles
import SettingsMenuContainer from "./SettingsMenu.styles";

export default function SettingsMenu() {
    const { showSettingsMenu, setShowSettingsMenu } = useSettings();

    const [page, setPage] = useState<"SETTINGS" | "PROFILE">("PROFILE");
    const [hasChangedPage, setHasChangedPage] = useState(false);

    const changePage = (page: "SETTINGS" | "PROFILE") => {
        setPage(page);
        setHasChangedPage(true);
    };

    useEffect(() => {
        setPage("PROFILE");
        setHasChangedPage(false);
    }, [showSettingsMenu]);

    return (
        showSettingsMenu && (
            <SettingsMenuContainer id={"settingsMenu"}>
                <ClickAwayListener
                    onClickAway={() => setShowSettingsMenu(false)}
                >
                    <div className={"menuModal"}>
                        <div className={"headerBar"}>
                            <Tooltip title={"PROFILE"}>
                                <div
                                    className={
                                        "toggleBtn " +
                                        (hasChangedPage
                                            ? page === "PROFILE"
                                                ? "active"
                                                : "inactive"
                                            : "left")
                                    }
                                    onClick={() => changePage("PROFILE")}
                                >
                                    <FontAwesomeIcon icon={faUser} fixedWidth />
                                </div>
                            </Tooltip>

                            <div className={"pageTitle"}>{page}</div>

                            <Tooltip title={"SETTINGS"}>
                                <div
                                    className={
                                        "toggleBtn " +
                                        (hasChangedPage
                                            ? page === "SETTINGS"
                                                ? "active"
                                                : "inactive"
                                            : "right")
                                    }
                                    onClick={() => changePage("SETTINGS")}
                                >
                                    <FontAwesomeIcon icon={faCog} fixedWidth />
                                </div>
                            </Tooltip>
                        </div>
                        {
                            {
                                PROFILE: <Profile />,
                                SETTINGS: <Settings />,
                            }[page]
                        }
                    </div>
                </ClickAwayListener>

                <p className={"clickToClose"}>CLICK ANYWHERE TO CLOSE</p>
            </SettingsMenuContainer>
        )
    );
}
