// Styles
import DelawareFundContainer from "./DelawareFund.styles";

// Components
import Tabs from "components/Common/Tabs/Tabs";
import DelawareGlobal from "./Global/DelawareGlobal";
import DelawareAsia from "./Asia/DelawareAsia";

interface DelawareFundProps {}

const DelawareFund = ({}: DelawareFundProps) => {
    return (
        <DelawareFundContainer>
            <p className={"sectionTitle"}>DELAWARE FUND</p>
            <Tabs
                defaultTab="global"
                tabs={[
                    {
                        value: "global",
                        title: "GLOBAL",
                        content: <DelawareGlobal region="global" />,
                        permissions: ["p_can_view_fees", "p_can_action_fees"],
                    },
                    {
                        value: "asia",
                        title: "ASIA",
                        content: <DelawareAsia region="asia" />,
                        permissions: ["p_can_view_fees", "p_can_action_fees"],
                    },
                ]}
            />
        </DelawareFundContainer>
    );
};
export default DelawareFund;
