// Styles
import HedgingContainer from "./Hedging.styles";
import Tabs from "../../../../components/Common/Tabs/Tabs";
import ReportsTab from "../../../../components/Screens/DailyRecs/DailyFlowsCheck/ReportsTab/ReportsTab";
import AlertsTab from "../../../../components/Screens/DailyRecs/DailyFlowsCheck/AlertsTab/AlertsTab";
import ChangeLogTab from "../../../../components/Screens/DailyRecs/DailyFlowsCheck/ChangeLogTab/ChangeLogTab";

interface HedgingProps {}

const powerBI =
    "https://app.powerbi.com/reportEmbed?reportId=57c40f69-67b4-4f5b-bae4-d7ca75a857d4&autoAuth=true&ctid=bf4450a2-bfcb-472d-8c40-1425eafb1a3b&filterPaneEnabled=false";
const checkName = "hedging_restrictions";

const Hedging = ({}: HedgingProps) => {
    return (
        <HedgingContainer>
            <p className={"sectionTitle"}>HEDGING</p>
            <Tabs
                defaultTab="reports"
                tabs={[
                    {
                        value: "reports",
                        title: "REPORTS",
                        permissions: ["p_can_view_navs", "p_can_action_navs"],
                        content: <ReportsTab powerBI={powerBI} />,
                    },
                    {
                        value: "alerts",
                        title: "ALERTS",
                        permissions: ["p_can_view_navs", "p_can_action_navs"],
                        content: <AlertsTab checkName={checkName} />,
                    },
                    {
                        value: "change_log",
                        title: "CHANGE LOG",
                        permissions: ["p_can_view_navs", "p_can_action_navs"],
                        content: <ChangeLogTab checkName={checkName} />,
                    },
                ]}
            />
        </HedgingContainer>
    );
};

export default Hedging;
