import styled from "styled-components";

export default styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .body {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding: 20px;
        text-align: center;

        .avatar {
            display: flex;
            justify-content: center;
            align-items: center;

            min-height: 80px;
            max-height: 80px;
            min-width: 80px;
            max-width: 80px;

            background-color: ${(props) => props.theme.avatarBackground};

            border-radius: 10px;

            margin: 20px auto;

            p {
                font-size: 60px;
                line-height: 60px;
                margin-top: 5px;
                color: ${(props) => props.theme.avatarText};
            }
        }

        .name {
            color: ${(props) => props.theme.nameText};

            font-weight: bold;

            letter-spacing: 2px;

            font-size: 25px;

            max-width: 100%;

            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .email {
            font-size: 14px;

            margin-top: 10px;

            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .logoutBtn {
        display: flex;
        justify-content: center;
        align-items: center;

        color: ${(props) => props.theme.logoutButtonText};
        background-color: ${(props) => props.theme.logoutButtonBackground};

        margin: 20px;
        padding: 10px 20px;

        border-radius: 5px;

        cursor: pointer;

        p {
            margin: 3px 0 0 0;
            font-size: 18px;
            letter-spacing: 2px;
        }

        :hover {
            background-color: ${(props) =>
                props.theme.logoutButtonBackgroundHover};
        }
    }
`;
