import styled from "styled-components";

export default styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    border-bottom: 1px solid #efefef;

    .column {
        display: flex;
        justify-content: flex-start;
        flex: 1 1;
    }

    .completedRow {
        display: flex;
        align-items: center;
    }

    .info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        padding: 15px 15px;
        flex: 3 3;

        .name {
            display: flex;
            flex-direction: row;
            align-items: center;

            white-space: nowrap;
            font-weight: bolder;
            letter-spacing: 0.1em;
            font-size: 16px;
            color: ${(props) => props.theme.homeListRowHeader};

            .completedIcon {
                color: ${(props) => props.theme.tableIconHighlight};
                margin: 0 0 3px 5px;
            }

            .userCompleted {
                display: flex;
                flex-direction: row;
                align-items: center;
            }

            .coreTag {
                display: flex;

                padding: 3px 10px;
                border-radius: 5px;

                margin-left: 10px;

                color: white;
                background-color: ${(props) =>
                    props.theme.subMenuBadgeBackgroundSecondary};
            }

            &.notCompleted {
                color: ${(props) => props.theme.accentButtonBackground};
            }
        }

        .details {
            display: flex;
            flex-direction: row;
            margin: 0 -5px;

            .detail {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin: 0 5px;

                p {
                    color: ${(props) => props.theme.homeListRowText};
                    font-size: 14px;
                }

                svg {
                    color: ${(props) => props.theme.homeListRowText};
                    font-size: 10px;
                    margin-right: 4px;
                    margin-bottom: 2px;
                }
            }
        }
    }

    .date {
        // width: 80px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex: 2 2;

        color: ${(props) => props.theme.homeListRowText};
        font-size: 14px;
        margin: 0 20px;
        white-space: nowrap;

        svg {
            font-size: 12px;
            margin-right: 5px;
            margin-top: 2px;
        }
    }

    .modesCell {
        flex: 1 1;
    }

    .completedIcon {
        color: ${(props) => props.theme.tableIconHighlight};
    }
`;
