import React, {useEffect, useState} from "react";

// Styles
import GrowthDealDetailContainer from "./GrowthDealDetail.styles";


// Components
import GrowthVotingDeal from "components/Screens/GrowthVoting/GrowthVotingDeal/GrowthVotingDeal";

// Context
import {GrowthDealProvider} from "context/growth/GrowthDeal.context";
import {GrowthUsersProvider} from "context/growth/GrowthUser.context";
import {GrowthCompaniesProvider} from "context/growth/GrowthCompany.context";



const GrowthDealDetail = () => {
    /*
    Wrapper for the Deal page to wrap with the GrowthDealProvider
    */
    return (
        <GrowthCompaniesProvider>
            <GrowthUsersProvider>
                <GrowthDealProvider>
                    <GrowthDealDetailContainer>
                        <GrowthVotingDeal />
                    </GrowthDealDetailContainer>
                </GrowthDealProvider>
            </GrowthUsersProvider>
        </GrowthCompaniesProvider>
    );
};
export default GrowthDealDetail;
