import React from "react";

// Styles
import TrendsViewContainer from "./TrendsView.styles";

interface TrendsViewProps {}

const TrendsView = ({}: TrendsViewProps) => {
    return (
        <TrendsViewContainer>
            <div className="iframeContainer">
                <iframe
                    title="Trends Dashboard vLive (QS)"
                    src="https://app.powerbi.com/reportEmbed?reportId=80909569-63f0-496a-bee5-1af9c12e1859&autoAuth=true&ctid=bf4450a2-bfcb-472d-8c40-1425eafb1a3b&filterPaneEnabled=false"
                    width="100%"
                    height="100%"
                    allowFullScreen
                    frameBorder={0}
                />
            </div>
        </TrendsViewContainer>
    );
};
export default TrendsView;
