import { useEffect, useState } from "react";

// Material UI
import { Grid } from "@mui/material";

// Components
import Button from "components/Common/Button/Button";
import TextField from "components/Common/TextField/TextField";
import SearchableDropDown from "components/Common/SearchableDropDown/SearchableDropDown";
import DateSelect from "components/Common/DateSelect/DateSelect";
import Modal from "components/Common/Modal/Modal";
import TextArea from "components/Common/TextArea/TextArea";

// Utils
import * as Database from "utils/database.utils";
import { toAnalyticsDate } from "utils/general.utils";

// Context
import { useSettings } from "context/Settings.context";

// Styles
import StockSplitModalContainer from "./StockSplitModal.styles";
import { getAllCompanies } from "utils/companies.utils";

type Props = {
    mode: "NEW" | "CONFIRM" | "EDIT";
    selectedStockSplit: any;
    setShowModal: Function;
    reload: Function;
};

const StockSplitModal: React.FC<Props> = ({
    mode,
    selectedStockSplit,
    setShowModal,
    reload,
}) => {
    const { setToast } = useSettings();

    const [loading, setLoading] = useState<boolean>(false);

    const getDate = () => {
        if (selectedStockSplit.detected_datetime) {
            return new Date(selectedStockSplit.detected_datetime);
        } else if (selectedStockSplit.split_date) {
            return new Date(selectedStockSplit.split_date);
        } else {
            return undefined;
        }
    };

    const [companiesList, setCompaniesList] = useState<any[]>([]);

    const [company, setCompany] = useState<Option>({
        label: selectedStockSplit.company,
        value: selectedStockSplit.company_id,
    });
    const [date, setDate] = useState<Date | undefined>(getDate());
    const [splitFactor, setSplitFactor] = useState<number | undefined>(
        selectedStockSplit.split_factor_est
            ? selectedStockSplit.split_factor_est
            : selectedStockSplit.split_factor
    );
    const [splitRatio, setSplitRatio] = useState<number | undefined>(
        selectedStockSplit.split_ratio_est
            ? selectedStockSplit.split_ratio_est
            : selectedStockSplit.split_ratio
    );
    const [notes, setNotes] = useState<string>("");

    const [errors, setErrors] = useState<any>({});

    const setFactor = (factor: number) => {
        if (factor > 0) {
            setSplitRatio(1 / factor);
        }
        setSplitFactor(factor);
    };

    const setRatio = (ratio: number) => {
        if (ratio > 0) {
            setSplitFactor(1 / ratio);
        }
        setSplitRatio(ratio);
    };

    const save = async () => {
        setLoading(true);

        let endpoints = {
            NEW: "api/stock_splits/confirmed/manually_add",
            CONFIRM: "api/stock_splits/auto_detected/confirm",
            EDIT: "api/stock_splits/confirmed/edit",
        };

        let data = {
            ...selectedStockSplit,
            company: company.label,
            company_id: company.value,
            split_factor: splitFactor,
            split_ratio: splitRatio,
            split_date: date,
            user_notes: notes,
            update_datetime: toAnalyticsDate(new Date()),
        };

        let res = await Database.post(endpoints[mode], data);

        if (res.ok) {
            setToast({
                show: true,
                type: "success",
                message: "Stock split has been saved",
            });

            setShowModal(false);
            reload();
        }
    };

    const checkFields = () => {
        let error: any = {};

        if (
            (splitRatio && (splitRatio < 0 || isNaN(splitRatio))) ||
            !splitRatio
        )
            error.splitRatio = true;
        if (
            (splitFactor && (splitFactor < 0 || isNaN(splitFactor))) ||
            !splitFactor
        )
            error.splitFactor = true;
        if (!company.value) error.company = true;
        if (!date) error.date = true;

        if (Object.keys(error).length === 0) {
            setErrors({});
            save();
        } else {
            setErrors(error);
        }
    };

    const getCompanies = async () => {
        let res = await getAllCompanies();
        let companies = await res.json();

        setCompaniesList(
            companies.data.map((comp: any) => ({
                label: comp.company,
                value: comp.company_id,
            }))
        );
    };

    useEffect(() => {
        getCompanies();
    }, []);

    return (
        <StockSplitModalContainer>
            <Modal loading={loading}>
                <div className="main">
                    <div className="text">
                        <h3>{mode + " STOCK SPLIT"} </h3>
                    </div>

                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6}>
                            <label>Company</label>
                            {mode === "NEW" ? (
                                <SearchableDropDown
                                    placeholder="Company"
                                    options={companiesList}
                                    setValue={setCompany}
                                    value={company}
                                    error={errors.company}
                                />
                            ) : (
                                <TextField
                                    placeholder="Company"
                                    change={() => {}}
                                    value={company.label}
                                    disabled
                                />
                            )}
                        </Grid>

                        <Grid item xs={12} sm={12} md={6}>
                            <label>Date</label>
                            <DateSelect
                                placeholder="Date"
                                setValue={setDate}
                                value={date}
                                error={errors.date}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6}>
                            <label>Split Factor</label>
                            <TextField
                                placeholder="Split Factor"
                                change={setFactor}
                                value={splitFactor ? String(splitFactor) : ""}
                                error={errors.splitFactor}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6}>
                            <label>Split Ratio</label>
                            <TextField
                                placeholder="Split Ratio"
                                change={setRatio}
                                value={splitRatio ? String(splitRatio) : ""}
                                error={errors.splitRatio}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextArea
                                label="Notes"
                                rows={5}
                                placeholder="Notes"
                                change={setNotes}
                                value={notes}
                            />
                        </Grid>
                    </Grid>
                </div>

                <div className="actions">
                    <Button
                        text="CANCEL"
                        color="flat"
                        click={() => setShowModal(false)}
                    />
                    <Button text="SAVE" click={checkFields} />
                </div>
            </Modal>
        </StockSplitModalContainer>
    );
};

export default StockSplitModal;
