import styled from "styled-components";

export default styled.div`
    display: flex;
    flex-direction: column;

    .formula {
        word-break: break-all;
        white-space: break-spaces;
    }
`;
