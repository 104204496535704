import styled from "styled-components";
import colors from "assets/styles/colors";

export const SelectContainer = styled.div`
    display: flex;
    align-items: center;

    padding: 8px 15px;

    min-width: fit-content;

    background-color: ${(props) => props.theme.filterButtonBackground};
    color: ${(props) => props.theme.filterButtonForeground};

    border-radius: 5px;

    &.enabled {
        :hover {
            background-color: ${(props) =>
                props.theme.filterButtonBackgroundHover};
        }
        cursor: pointer;
    }

    margin-bottom: 10px;
    margin-right: 10px;
    user-select: none;

    max-height: 40px;
    min-height: 40px;

    p {
        letter-spacing: 1px;
    }

    .filterLabel {
        &.showAll {
            flex-grow: 1;
        }

        &.showSelect {
            flex-grow: none;
            color: lightgray;
        }
    }
    .value {
        font-weight: bolder;
        margin-left: 15px;
        display: flex;
    }

    .selected {
        display: flex;
        flex-grow: 1;
        margin-right: 10px;
    }

    .clearFilter {
        margin-left: 10px;
        display: flex;
    }

    .error {
        border: 2px solid lightcoral;
    }
`;

export const PopUpContainer = styled.div`
    display: flex;
    flex-direction: column;

    background-color: ${(props) => props.theme.dropDownMenuBackground};
    color: ${(props) => props.theme.dropDownMenuText};

    box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.28);

    margin-top: 5px;
    /* max-width: 250px; */

    user-select: none;

    .searchBox {
        border-bottom: 1px solid
            ${(props) => props.theme.dropDownSearchBoxBorder};
        background-color: ${(props) => props.theme.dropDownMenuBackground};
        color: ${(props) => props.theme.dropDownMenuText};

        align-items: center;
        display: flex;
        max-width: 250px;
        min-width: 250px;
        margin: 0 !important;

        input {
            border: none;
            height: 40px;
            font-size: 18px;
            background-color: ${(props) => props.theme.dropDownMenuBackground};
            color: ${(props) => props.theme.dropDownMenuText};
            padding-left: 15px;
            outline: none;
            display: flex;
            flex-grow: 1;
        }

        svg {
            margin: 0 10px;
            color: ${(props) => props.theme.dropDownSearchBoxIcon};
        }

        :hover {
            background-color: ${(props) => props.theme.dropDownBackgroundHover};

            input {
                background-color: ${(props) =>
                    props.theme.dropDownBackgroundHover};
            }
        }
    }

    .loading {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
        min-height: 100px;
        min-width: 250px;
    }

    .options {
        flex-direction: column;
        display: flex;
        max-height: 400px;
        overflow-y: scroll;

        .option {
            min-width: 150px;
            cursor: pointer;

            :hover {
                background-color: ${(props) =>
                    props.theme.dropDownBackgroundHover};
            }
            p {
                margin: 10px 15px;
            }
            .info {
                color: ${colors.accentBlue};
            }
            .warning {
                color: ${colors.accentOrange};
            }
            .critical {
                color: ${colors.accentRed};
            }
        }

        .noResults {
            min-width: 150px;
            width: 100%;
            p {
                margin: 10px 15px;
                width: 100%;
                text-align: center;
                color: ${(props) => props.theme.dropDownMenuText};
            }
        }
    }

    .date {
        div {
            display: block;
        }
        .react-datepicker__day-name,
        .react-datepicker__day {
            display: inline-block;
        }
    }
`;
