import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// Components
import HomeList from "../../../Home/HomeList/HomeList";
import CompanyResultsRow from "./CompanyResultsRow/CompanyResultsRow";

// Utils
import { filterCompaniesWithNoVotes } from "utils/bqmq.utils";

// Context
import { useBQMQ } from "context/BQMQ.context";

// Styles
import CompanyResultsListContainer from "./CompanyResultsList.styles";

type Props = {
    filterQuery: string;
    filterResults: any;
};

export default function CompanyResultsList({
    filterQuery,
    filterResults,
}: Props) {
    const history = useHistory();
    const { companies } = useBQMQ();
    const [filteredData, setFilteredData] = useState<any[]>([]);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        if (companies) {
            const filteredResults = filterResults(
                filterCompaniesWithNoVotes(companies)
            );
            setFilteredData(filteredResults);
            setTotal(filteredResults.length);
        }
    }, [companies, filterQuery]);

    return (
        <CompanyResultsListContainer>
            <HomeList
                data={filteredData}
                row={(row: any) => <CompanyResultsRow data={row} />}
                title={"LATEST COMPANY RESULTS"}
                count={total}
                countColor={""}
                seeAll={() => history.push("/voting/focus-list")}
                maxRows={1000}
            />
        </CompanyResultsListContainer>
    );
}
