import styled from 'styled-components';

export default styled.div`
    .inputDiv {
        display: inline-block;
        margin-right: 40px;
        min-width: 200px;
        max-width: 200px;
    }

    .labelDiv {
        padding-top: 10px;
        border: none;
        align: center;
        align-items: center;
        border-radius: 5px;
        text-align: center;
        justify-content: center;
        display: inline;
        height: 40px;
        width: 70px;
        font-size: 18px;
        outline: none;
        background-color: #dedede;
        float: left;
        margin-right: 10px;
    }

    .input {
        display: inline ;
    }

    .infoIcon {
        display: inline;
        width: 12px;
        height: 12px;
        margin-bottom: 2px;
        margin-left: 2px;
    }

    .infoText {
        display: inline;
    }
`;