import styled from "styled-components";

export default styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .tabsBar {
        margin-top: 10px;
        max-width: 100%;
        // border-bottom: 2px solid ${(props) => props.theme.tabsBottomBorder};
        display: flex;

        overflow-x: scroll;
        overflow-y: visible;

        ::-webkit-scrollbar {
            display: none;
        }

        .tabItem {
            height: 40px;
            padding: 0 20px;
            align-items: center;
            cursor: pointer;
            border-radius: 5px 5px 0 0;
            user-select: none;
            display: flex;
            min-width: fit-content;

            border-bottom: 2px solid ${(props) => props.theme.tabsBottomBorder};

            p {
                margin: 0;
                font-size: 18px;
                color: ${(props) => props.theme.tabItemText};
                letter-spacing: 2px;
                font-weight: lighter;
                display: flex;
            }

            :hover {
                background-color: ${(props) =>
                    props.theme.tabItemBackgroundHover};
                p {
                    color: ${(props) => props.theme.tabItemTextHover};
                }

                svg {
                    margin-top: 0;
                }
                border-bottom: 2px solid
                    ${(props) => props.theme.tabItemBorderHover};

                .newCompanyCount {
                    margin-top: 2px;
                }
            }

            &.active {
                p {
                    font-weight: bolder;
                    color: ${(props) => props.theme.tabItemTextActive};
                }

                svg {
                    margin-top: 0;
                }
                border-bottom: 2px solid
                    ${(props) => props.theme.tabItemBorderActive};

                .newCompanyCount {
                    margin-top: 2px;
                }

                :hover {
                    background-color: unset;
                }
            }
        }
    }

    .subTabsBar {
        margin-top: 30px;
        max-width: 100%;
        // border-bottom: 2px solid ${(props) => props.theme.tabsBottomBorder};
        display: flex;

        overflow-x: scroll;
        overflow-y: visible;

        ::-webkit-scrollbar {
            display: none;
        }

        .tabItem {
            height: 30px;
            padding: 0 15px;
            align-items: center;
            cursor: pointer;
            border-radius: 5px 5px 0 0;
            user-select: none;
            display: flex;
            min-width: fit-content;

            border-bottom: 2px solid ${(props) => props.theme.tabsBottomBorder};

            p {
                margin: 0;
                font-size: 16px;
                color: ${(props) => props.theme.tabItemText};
                letter-spacing: 2px;
                font-weight: lighter;
                display: flex;
            }

            :hover {
                background-color: ${(props) =>
                    props.theme.tabItemBackgroundHover};
                p {
                    color: ${(props) => props.theme.tabItemTextHover};
                }

                svg {
                    margin-top: 0;
                }
                border-bottom: 2px solid
                    ${(props) => props.theme.tabItemBorderHover};

                .newCompanyCount {
                    margin-top: 2px;
                }
            }

            &.active {
                p {
                    font-weight: bolder;
                    color: ${(props) => props.theme.tabItemTextActive};
                }

                svg {
                    margin-top: 0;
                }
                border-bottom: 2px solid
                    ${(props) => props.theme.tabItemBorderActive};

                .newCompanyCount {
                    margin-top: 2px;
                }

                :hover {
                    background-color: unset;
                }
            }
        }
    }

    .spacer {
        display: flex;
        flex-grow: 1;

        border-bottom: 2px solid ${(props) => props.theme.tabsBottomBorder};
    }
`;
