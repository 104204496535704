import styled from "styled-components";

export default styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 100%;
    border-radius: 5px;

    margin-top: 20px;
    margin-bottom: 3px;

    color: ${(props) => props.theme.subMenuText};

    .text {
        display: flex;

        font-size: 14px;
        margin: 0;
        white-space: nowrap;
    }

    .separator {
        display: flex;
        flex-grow: 1;

        min-height: 1px;
        max-height: 1px;

        border-bottom: 1px solid ${(props) => props.theme.subMenuSeparator};
        margin: 0 0 3px 10px;
    }
`;
