// Styles
import { isObject } from "lodash";
import ResultCellContainer from "./ResultCell.styles";

type Props = { value: { value: any } | string | number | null };

export default function ResultCell({ value }: Props) {
    let val;
    if (isObject(value)) {
        val = value?.value;
    } else {
        val = value;
    }
    return (
        <ResultCellContainer
            data-cy="mode-result"
            className={"resultsCell score-" + val}
        >
            {val}
        </ResultCellContainer>
    );
}
