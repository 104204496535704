// Styles
import EditAnalystModalContainer from "./EditAnalystModal.styles";
import Modal from "components/Common/Modal/Modal";
import Button from "../../../../../../Common/Button/Button";
import React, { useEffect, useState } from "react";
import { ClickAwayListener, FormControlLabel, Switch } from "@mui/material";
import UserRow from "../../../../../BQMQ/Results/InvitedUserModal/UserRow/UserRow";
import * as Database from "../../../../../../../utils/database.utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import LoadingSpinner from "../../../../../../Common/LoadingSpinner/LoadingSpinner";
import { useSettings } from "../../../../../../../context/Settings.context";

type Props = {
    setShowEditAnalystModal: Function;
    company: any;
    reloadCompanies: number;
    setReloadCompanies: Function;
};

export default function EditAnalystModal({
    company,
    setShowEditAnalystModal,
    reloadCompanies,
    setReloadCompanies,
}: Props) {
    const { setShowLoadingToast, setToast } = useSettings();

    const [users, setUsers] = useState<any[]>([]);

    const getUserList = async () => {
        let res = await Database.get("api/bqmq_voting/azure_users");
        let data = await res.json();

        setUsers(
            data.data
                .sort((a: any, b: any) => b.name - a.name)
                .map((user: any) => ({
                    name: user.name,
                    id: user.id,
                }))
        );
    };

    useEffect(() => {
        getUserList();
    }, []);

    const updateAnalyst = async (id: string, name: string) => {
        setShowEditAnalystModal(false);

        setShowLoadingToast({
            show: true,
            message: "UPDATING ANALYST",
        });

        try {
            let res = await Database.put("api/companies/edit-analyst", {
                company_id: company.company_id,
                analyst_id: id,
                analyst_name: name,
            });
            let data = await res.json();

            setToast({
                show: true,
                type: "success",
                message: "Analyst Updated",
            });

            setReloadCompanies(reloadCompanies + 1);
        } catch (err) {
            setToast({
                show: true,
                type: "error",
                message: "Error Updating Analyst",
            });
        }

        setShowLoadingToast({
            show: false,
            message: "UPDATING ANALYST",
        });
    };

    return (
        <Modal>
            <ClickAwayListener
                onClickAway={() => setShowEditAnalystModal(false)}
            >
                <EditAnalystModalContainer>
                    <div className="main">
                        <div className="text">
                            <h3>SELECT ANALYST</h3>
                            <h4>{company.company}</h4>
                        </div>

                        {users.length === 0 ? (
                            <div className={"loadingScreen"}>
                                <LoadingSpinner />
                            </div>
                        ) : (
                            <div className="userList">
                                {users.map((user: any) => (
                                    <div
                                        className={"userRow"}
                                        onClick={() =>
                                            updateAnalyst(user.id, user.name)
                                        }
                                    >
                                        <p>{user.name}</p>
                                        <FontAwesomeIcon
                                            icon={faArrowRight}
                                            fixedWidth
                                        />
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>

                    <div className="actions">
                        <Button
                            text="CANCEL"
                            color="flat"
                            click={() => setShowEditAnalystModal(false)}
                        />
                    </div>
                </EditAnalystModalContainer>
            </ClickAwayListener>
        </Modal>
    );
}
