// Components
import PageTitle from "components/Common/PageTitle/PageTitle";
import NewCompanies from "components/Screens/Data/Companies/NewCompanies/NewCompanies";

// Styles
import NewCompaniesContainer from "./NewCompanies.styles";

export default function NewCompaniesPage() {
    return (
        <NewCompaniesContainer>
            <PageTitle title="NEW COMPANIES" />
            <NewCompanies />
        </NewCompaniesContainer>
    );
}
