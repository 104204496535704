import styled from "styled-components";
import colors from "assets/styles/colors";

export default styled.div`
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;

    position: fixed;

    top: 0;
    left: 0;

    background-color: ${(props) => props.theme.modalBackground};
    backdrop-filter: ${(props) => props.theme.modalBackgroundFilter};

    z-index: 250;

    display: flex;
    justify-content: center;
    align-items: center;

    animation: growModal 0.2s both linear;

    .modalBox {
        width: min(600px, 90%);
        height: fit-content;
        border-radius: 5px;

        background-color: ${(props) => props.theme.modalInnerBackground};

        flex-direction: column;
        justify-content: center;
        display: flex;

        box-shadow: ${(props) => props.theme.modalBoxShadow};

        .main {
            flex-grow: 1;
            display: flex;
            padding: 0 20px;

            label {
                letter-spacing: 0.1em;
                color: ${colors.textNavy};
                margin-bottom: 5px;
            }

            .text {
                width: 100%;
                justify-content: center;
                display: flex;
                flex-direction: column;
                align-items: center;

                h3 {
                    color: ${(props) => props.theme.modalTitle};
                    letter-spacing: 2px;
                    font-size: 25px;
                    font-weight: lighter;
                    margin-top: 15px;
                    margin-bottom: 15px;
                }

                p {
                    margin-bottom: 20px;
                    color: ${(props) => props.theme.modalTitle};
                }
            }
        }

        .actions {
            height: 40px;
            max-width: 100%;
            margin: 20px 10px;
            display: flex;
            flex-direction: row;

            .button {
                flex-grow: 1;
                margin: 0 10px;
            }
        }
    }

    @keyframes growModal {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
`;
