import styled from "styled-components";
import colors from "assets/styles/colors";

export default styled.div`
    .searchBox {
        max-width: 100%;
        width: 100%;
    }

    .info {
        span {
            font-size: 14px;
            font-weight: bolder;
            letter-spacing: 0.1em;
        }
    }

    .table {
        margin-bottom: 0;
    }

    .changed {
        color: ${colors.accentRed};
        font-weight: bold;
    }
`;
