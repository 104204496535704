import styled from "styled-components";

export default styled.div`
    display: flex;
    flex-direction: column;
    max-width: 100%;
    .no-border-head {
        border: none;
    }
    .stickyHeader,
    .stickyCell {
        position: sticky !important;
        left: 0;
    }
    .stickyHeader {
        z-index: 11 !important;
    }
    .stickyCell {
        z-index: 10 !important;
        background-color: white;
    }
    .tableHeadCell {
        background-color: ${(props) => props.theme.tableHeadCellBackground};
        white-space: nowrap;
        z-index: 5;
        color: ${(props) => props.theme.tableHeadCellText};

        svg {
            color: ${(props) => props.theme.tableHeadCellSortIcon};

            :hover {
                color: ${(props) => props.theme.tableHeadCellSortIconHover};
            }
        }

        &.sortable {
            cursor: pointer;
        }

        &.sortActive {
            background-color: ${(props) =>
                props.theme.tableHeadCellSortActiveBackground};
            color: ${(props) => props.theme.tableHeadCellSortActiveText};

            svg {
                color: ${(props) => props.theme.tableHeadCellSortActiveIcon};
            }
        }
    }

    .table {
        &.large {
            .MuiTableCell-body {
                height: 60px;
            }
        }
        margin-bottom: 20px;

        .MuiTableRow-root {
            p {
                color: ${(props) => props.theme.tableBodyText};
            }

            .MuiCheckbox-root {
                svg {
                    color: ${(props) => props.theme.tableRowCheckbox};
                }
            }

            &.MuiTableRow-head {
                .MuiCheckbox-root {
                    svg {
                        color: ${(props) => props.theme.tableHeadCellSortIcon};
                    }
                }
            }

            &.disabled {
                background-color: ${(props) =>
                    props.theme.tableDisabledRowBackground};
                pointer-events: none;

                p,
                svg {
                    color: ${(props) => props.theme.tableDisabledRowText};
                }

                .icon {
                    background-color: ${(props) =>
                        props.theme.tableDisabledRowText};
                    svg {
                        color: ${(props) => props.theme.tableDisabledRowText};
                    }
                }
            }

            &.clickable {
                cursor: pointer;
            }

            .disabledIcon {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                pointer-events: auto;

                svg {
                    font-size: 20px;
                    color: ${(props) => props.theme.tableDisabledRowText};
                }
            }
        }

        .Mui-selected {
            background-color: ${(props) =>
                props.theme.tableSelectedRowBackground};
            color: ${(props) => props.theme.tableSelectedRowText};

            .MuiCheckbox-root {
                svg {
                    color: ${(props) => props.theme.tableRowCheckboxSelected};
                }
            }

            :hover {
                background-color: ${(props) =>
                    props.theme.tableSelectedRowBackground};
                color: ${(props) => props.theme.tableSelectedRowText};
            }
        }

        .MuiTablePagination-root {
            .MuiTablePagination-input {
                color: ${(props) => props.theme.tableBodyText};
                svg {
                    color: ${(props) => props.theme.tableBodyText};
                }
            }
            .MuiTablePagination-actions {
                button {
                    color: ${(props) => props.theme.tableBodyText};

                    &.Mui-disabled {
                        color: ${(props) => props.theme.mainBackground};
                    }
                }
            }
        }
    }

    .noData {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 250px;

        svg {
            font-size: 50px;
            color: ${(props) => props.theme.tableHeadCellBackground};
            margin-bottom: 20px;
        }

        .spinner {
            animation: spin 2s linear infinite;
        }

        p {
            color: ${(props) => props.theme.tableHeadCellBackground};
            letter-spacing: 0.1em;
        }
    }
`;
