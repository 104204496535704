import styled from 'styled-components';

export default styled.div`

    .reasonPill {
        padding: 6px;
        border-radius: 5px;
        font-size: 12px;

        &.noVotingData {
            color: #FFFFFF;
            background-color: black;
        }

        &.oldVotingData {
            color: #FFFFFF;
            background-color: #808080;
        }

        &.highControversy {
            color: black;
            background-color: #ff8282;
        }

        &.medControversy {
            color: black;
            background-color: #ffd782;
        }

        &.lowControversy {
            color: black;
            background-color: #89c781;
        }
    }

    .viewIcon {
        cursor: pointer;

        svg {
            color: ${(props) => props.theme.tableIconHighlight};
            font-size: 20px;
        }
    }
    
`;