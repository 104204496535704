import React, { isValidElement, useEffect, useState } from "react";

// Styles
import EditCompanyModalContainer from "./EditCompanyModal.styles";

// Types
import {GrowthCompany} from "assets/interfaces/growth_voting";

// Utils
import { getDomain } from "utils/growth_voting.utils";

// Services
import { ApiHandler } from "services/api_handler";

// Context
import { useSettings } from "context/Settings.context";

// Material UI
import { Tooltip } from "@mui/material";

// Components
import Modal from "components/Common/Modal/Modal";
import ButtonSmall from "../../../Common/ButtonSmall/ButtonSmall";
import {faClose} from "@fortawesome/free-solid-svg-icons";
import Button from "../../../Common/Button/Button";
import TextField from "../../../Common/TextField/TextField";

const URL_SAVE_COMPANY = "api/growth_voting/companies";

interface Porps {
    company: GrowthCompany;
    setCompany: React.Dispatch<React.SetStateAction<GrowthCompany>>;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    setSaved: React.Dispatch<React.SetStateAction<boolean>>; // saving the changes
}

const EditCompanyModal  = ({company, setCompany, setShowModal, setSaved}: Porps) => {
    const [loading, setLoading] = useState<boolean>(false);
    const { setShowLoadingToast, setToast } = useSettings();

    const updateName = (name: string) => {
        company = {...company, name: name};
        setCompany(company);
    };

    const updateUrl = (urlStr: string) => {
        const domain = getDomain(urlStr);
        company = {...company, url: domain};
        setCompany(company);
    };

    const isValid = () => {
        if (!company.name) {
            setToast({
                show: true,
                message: "Please set a company name.",
                type: "error",
            });
            return false;
        }

        if (!company.url) {
            setToast({
                show: true,
                message: "Please set a URL for the company.",
                type: "error",
            });
            return false;
        }

        const domain = getDomain(company.url);

        if (company.url && !domain) {
            setToast({
                show: true,
                message: "Domain is invalid. Please set a valid domain.",
                type: "error",
            });
            return false;
        }

        return true;
    }

    const saveCompany = async () => {
        if (!isValid()) {
            return;
        }
        let url = `${URL_SAVE_COMPANY}`;

        // remove deals from the payload
        // the main reason is the historical data, there is no milestone for the deals as default
        // which could cause validation error
        const companyToSave = {...company};
        delete companyToSave.deals;

        let response = await new ApiHandler(url, setShowLoadingToast, setToast).post({company: companyToSave});

        setSaved(true);
        setCompany({name: "", url: ""})
        setShowModal(false);
    }

    return (
        <EditCompanyModalContainer>
            <Modal loading={loading} width={"500px"}>
                <div className={"btn-close-container"}>
                    <ButtonSmall
                        color={"flat"}
                        text={"Close"}
                        icon={faClose}
                        click={() => {setShowModal(false)}}
                    />
                </div>

                <div className="modal-title">
                    Company
                </div>

                <div className={"form"}>
                <div className="inputContainer">
                    <label>Name</label>
                    <TextField
                        value={company?.name ? company.name : ""}
                        change={updateName}
                        placeholder={""}
                    />
                </div>

                <div className="inputContainer">
                        <label>
                            <Tooltip title="Examples of a domain: example.com" placement="top">
                                <span>Domain</span>
                            </Tooltip>
                        </label>
                    <TextField
                        value={company?.url ? company.url : ""}
                        change={updateUrl}
                        placeholder={""}
                    />
                </div>
            </div>

                <div className={"btn-add-container"}>
                    <Button text="OK" color="primary"   click={() => saveCompany()} />
                </div>
            </Modal>
        </EditCompanyModalContainer>
    );
};

export default EditCompanyModal
