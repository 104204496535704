import queryString from "query-string";
import { Fragment, useState } from "react";

// Components
import ResultCell from "../ResultCell/ResultCell";

// Utils
import { sortAnswersForResults } from "utils/bqmq.utils";

// Styles
import VoteCountsTableContainer from "./VoteCountsTable.styles";

// Logger
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@mui/material";
import Can from "components/Common/Can/Can";
import { toDate } from "utils/general.utils";
import Logger from "utils/logger.utils";
import EmailReminder from "../InvitedUserModal/EmailReminder/EmailReminder";
const Log = new Logger();
Log.init("VoteCountsTable");
type Props = {
    results: any;
    usersVotes: any;
    factor: string;
    setHighlightedFactor: Function;
};

export default function VoteCountsTable({
    results,
    usersVotes,
    factor,
    setHighlightedFactor,
}: Props) {
    const [selectedQuestion, setSelectedQuestion] = useState("");

    const getUsersThatVotedX = (questionID: string, score: string) => {
        let selectedUsers = usersVotes.filter((vote: any) => {
            const choice = vote.choice?.text;
            if (choice === score && questionID === vote.question.text) {
                return true;
            }
            return false;
        });

        if (selectedUsers.length > 0) {
            return (
                <div>
                    {selectedUsers.map((vote: any) => (
                        <p key={vote.analyst.name}>{vote.analyst.name}</p>
                    ))}
                </div>
            );
        } else {
            return "";
        }
    };

    const getCompanyName = () => {
        const company_name: string = queryString.parse(
            window.location.search
        ).company_name;
        return company_name;
    };

    const analystDropDown = (question: any) => {
        const voteList = usersVotes.filter(
            (vote: any) => vote.question.text === question
        );
        const commentList = usersVotes.filter(
            (vote: any) =>
                vote.question.text === factor?.toLowerCase() + "_comments"
        );

        const commentsByAnalyst: Record<string, string> = {};

        commentList.forEach((vote: any) => {
            commentsByAnalyst[vote.analyst.name] = vote.comment;
        });
        return (
            <div className={"usersVotes"}>
                {voteList
                    .sort((a: any, b: any) =>
                        a.analyst.name.localeCompare(b.analyst.name)
                    )
                    ?.map((vote: any) => (
                        <div
                            className={"userRow"}
                            key={question + vote.analyst.name}
                        >
                            <div className="user-details-wrapper">
                                <Tooltip
                                    title={
                                        <h2>
                                            {`${vote.analyst.name}: ${
                                                commentsByAnalyst[
                                                    vote.analyst.name
                                                ]
                                                    ? commentsByAnalyst[
                                                          vote.analyst.name
                                                      ]
                                                    : " No Comment"
                                            }`}
                                        </h2>
                                    }
                                >
                                    <div className="user-details">
                                        <p
                                            data-cy="userrow-username"
                                            className={"username"}
                                        >
                                            <div>
                                                {`${vote.analyst.name} `}
                                                <span className="date">{`${
                                                    vote?.historic_date
                                                        ? toDate(
                                                              vote.historic_date
                                                          )
                                                        : vote?.created
                                                        ? toDate(vote?.created)
                                                        : ""
                                                }`}</span>
                                            </div>
                                        </p>
                                    </div>
                                </Tooltip>
                                <div
                                    data-cy="users-vote"
                                    className={"users-vote"}
                                >
                                    {question && (
                                        <ResultCell
                                            value={
                                                vote.choice.text !== "Comment"
                                                    ? vote.choice.text
                                                    : ""
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                            <Can
                                yes={
                                    <EmailReminder
                                        userName={vote.analyst.name}
                                        companyID={
                                            usersVotes.length > 0
                                                ? usersVotes[0].session_id
                                                : ""
                                        }
                                        companyName={getCompanyName()}
                                        userID={vote.analyst.id}
                                    />
                                }
                                no={null}
                                permissions={["p_bqmq_can_create_session"]}
                            />
                        </div>
                    ))}
            </div>
        );
    };

    const formatQuestion = (question: string): string => {
        const QUESTIONMAP: Record<string, string> = {
            bq_overall: "BQ Overall",
            moat: "Moat",
            critical_sustainability_factors: "Sustainability",
            ecosystem: "Ecosystem",
            business_stability: "Business Stability",
            pricing_power: "Pricing Power",
            degree_of_difficulty: "Degree of Difficulty",
            attractiveness_of_castle: "Castle",
            mq_overall: "MQ Overall",
            operations: "Operations",
            capital_allocation: "Capital Allocation",
            governance: "Governance",
            strategy: "Strategy",
            culture: "Culture",
            alignment: "Alignment",
            people: "People",
            fade: "Fade",
            mqstar: "MQ*",
        };
        return QUESTIONMAP[question];
    };

    return (
        <VoteCountsTableContainer>
            {["BQ", "MQ"].includes(factor) ? (
                <div className={"titleRow"}>
                    <div data-cy="question" className={"question"}></div>
                    <div className={"answerColumn"}>
                        <ResultCell value={{ value: "1" }} />
                    </div>
                    <div className={"answerColumn"}>
                        <ResultCell value={{ value: "2" }} />
                    </div>
                    <div className={"answerColumn"}>
                        <ResultCell value={{ value: "3" }} />
                    </div>
                    <div className={"answerColumn"}>
                        <ResultCell value={{ value: "4" }} />
                    </div>
                    <div className={"answerColumn"}>
                        <ResultCell value={{ value: "5" }} />
                    </div>
                    <div className={"answerColumn mode"}>MODE</div>
                    <div className={"answerColumn mode"}>MEAN</div>
                    
                </div>
            ) : (
                <div className={"titleRow"}>
                    <div className={"question"}></div>
                    <div className={"answerColumn"}>
                        <ResultCell value={{ value: "AGREE" }} />
                    </div>
                    <div className={"answerColumn"}>
                        <ResultCell value={{ value: "NEUTRAL" }} />
                    </div>
                    <div className={"answerColumn"}>
                        <ResultCell value={{ value: "DISAGREE" }} />
                    </div>

                    <div className={"answerColumn mode"}>RATING</div>
                </div>
            )}

            {sortAnswersForResults(results).map(
                ([key, question]: any, index: number) => (
                    <Fragment key={key}>
                        {key !== "mode" && (
                            <div
                                className={"questionRow index-" + index}
                                onMouseEnter={() => setHighlightedFactor(key)}
                                onMouseLeave={() => setHighlightedFactor("")}
                                onClick={() =>
                                    key === selectedQuestion
                                        ? setSelectedQuestion("")
                                        : setSelectedQuestion(key)
                                }
                            >
                                {["BQ", "MQ"].includes(factor) ? (
                                    <>
                                        <div
                                            data-cy="question"
                                            className={"question"}
                                        >
                                            {formatQuestion(key)}
                                            <FontAwesomeIcon
                                                icon={
                                                    selectedQuestion === key
                                                        ? faCaretUp
                                                        : faCaretDown
                                                }
                                                fixedWidth
                                            />
                                               
                                        </div>

                                        <Tooltip
                                            title={
                                                <h2>
                                                    {" "}
                                                    {getUsersThatVotedX(
                                                        key,
                                                        "1"
                                                    )}
                                                </h2>
                                            }
                                        >
                                            <div className={"answerColumn"}>
                                                {question["1"]
                                                    ? question["1"]
                                                    : "-"}
                                            </div>
                                        </Tooltip>
                                        <Tooltip
                                            title={
                                                <h2>
                                                    {getUsersThatVotedX(
                                                        key,
                                                        "2"
                                                    )}
                                                </h2>
                                            }
                                        >
                                            <div className={"answerColumn"}>
                                                {question["2"]
                                                    ? question["2"]
                                                    : "-"}
                                            </div>
                                        </Tooltip>
                                        <Tooltip
                                            title={
                                                <h2>
                                                    {getUsersThatVotedX(
                                                        key,
                                                        "3"
                                                    )}
                                                </h2>
                                            }
                                        >
                                            <div className={"answerColumn"}>
                                                {question["3"]
                                                    ? question["3"]
                                                    : "-"}
                                            </div>
                                        </Tooltip>
                                        <Tooltip
                                            title={
                                                <h2>
                                                    {getUsersThatVotedX(
                                                        key,
                                                        "4"
                                                    )}
                                                </h2>
                                            }
                                        >
                                            <div className={"answerColumn"}>
                                                {question["4"]
                                                    ? question["4"]
                                                    : "-"}
                                            </div>
                                        </Tooltip>
                                        <Tooltip
                                            title={
                                                <h2>
                                                    {getUsersThatVotedX(
                                                        key,
                                                        "5"
                                                    )}
                                                </h2>
                                            }
                                        >
                                            <div className={"answerColumn"}>
                                                {question["5"]
                                                    ? question["5"]
                                                    : "-"}
                                            </div>
                                        </Tooltip>
                                        <div className={"answerColumn mode"}>
                                            <ResultCell
                                                value={question?.mode}
                                            />
                                        </div>
                                        <div className={"answerColumn mode"}>
                                            <ResultCell
                                                value={question?.mean}
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div
                                            data-cy="question"
                                            className={"question"}
                                        >
                                            {formatQuestion(key)}
                                        </div>
                                        <Tooltip
                                            title={
                                                <h2>
                                                    {getUsersThatVotedX(
                                                        key,
                                                        "Agree"
                                                    )}
                                                </h2>
                                            }
                                        >
                                            <div className={"answerColumn"}>
                                                {question && question["Agree"]
                                                    ? question["Agree"]
                                                    : "-"}
                                            </div>
                                        </Tooltip>
                                        <Tooltip
                                            title={
                                                <h2>
                                                    {getUsersThatVotedX(
                                                        key,
                                                        "Neutral"
                                                    )}
                                                </h2>
                                            }
                                        >
                                            <div className={"answerColumn"}>
                                                {question && question["Neutral"]
                                                    ? question["Neutral"]
                                                    : "-"}
                                            </div>
                                        </Tooltip>
                                        <Tooltip
                                            title={
                                                <h2>
                                                    {getUsersThatVotedX(
                                                        key,
                                                        "Disagree"
                                                    )}
                                                </h2>
                                            }
                                        >
                                            <div className={"answerColumn"}>
                                                {question &&
                                                question["Disagree"]
                                                    ? question["Disagree"]
                                                    : "-"}
                                            </div>
                                        </Tooltip>
                                        <div className={"answerColumn mode"}>
                                            <ResultCell
                                                value={question?.mode.toUpperCase()}
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                        )}

                        {selectedQuestion &&
                            selectedQuestion === key &&
                            analystDropDown(key)}
                    </Fragment>
                )
            )}
        </VoteCountsTableContainer>
    );
}