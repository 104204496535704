import { useState } from "react";

// Components
import Table from "components/Common/Table/Table";
import TableCell from "components/Common/TableCell/TableCell";
import EditableCell from "components/Screens/Admin/ValidationRules/EditableCell/EditableCell";

// Font Awesome
import { useFees } from "context/Fees.context";

// Utils
import FloatToCurrencyParser from "../../utils/FloatToCurrencyParser";
import FloatToPercParser from "../../utils/FloatToPercParser";
import formatDate from "../../utils/FormatDate";
import StringToFloatParser from "../../utils/StringToFloatParser";
import { SeparateAccountsRow } from "../../utils/types";
import SubscriptionsContainer from "./Subscriptions.styles";
interface RedemptionsProps {
    fund?: string;
    region?: string;
    period?: string;
}

const Subscriptions = ({ fund, region, period }: RedemptionsProps) => {
    const { subscriptions, updateFeesData, period: selectedPeriod } = useFees();
    const [filterQuery, setFilterQuery] = useState<string>("");
    const [tableIsLoading, setTableIsLoading] = useState<boolean>(false);
    const [reload, setReload] = useState(0);

    const columns = [
        {
            id: 0,
            title: "ACCOUNT ID",
            sortable: false,
            value: "account_id",
            cell: (data: SeparateAccountsRow) => (
                <TableCell data={data.account_id} />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 1,
            title: "ACCOUNT NAME",
            sortable: false,
            value: "account_id",
            cell: (data: SeparateAccountsRow) => (
                <TableCell data={data.account_name} />
            ),
            show: true,
            fullDataRow: true,
        },

        {
            id: 2,
            title: "SUBSCRIPTION DATE",
            sortable: false,
            value: "subscription_date",
            cell: (data: SeparateAccountsRow) => (
                <EditableCell
                    rowId={data.id}
                    cellValueType="string"
                    cellValue="comments"
                    rowIdKey={"id"}
                    initialValue={
                        data.subscription_date
                            ? formatDate(data.subscription_date)
                            : "-"
                    }
                    type="dateField"
                    setTableIsLoading={setTableIsLoading}
                    updateTable={() => setReload(reload + 1)}
                    onUpdate={(idx: number, value: string) => {
                        return updateFeesData(
                            "subscription_date",
                            data.account_id,
                            selectedPeriod,
                            data.tranche,
                            value,
                            {
                                id: data.uuid,
                                version: data.version,
                            }
                        );
                    }}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 4,
            title: "TRANCHE",
            sortable: false,
            value: "tranche",
            cell: (data: SeparateAccountsRow) => (
                <TableCell data={data.tranche} />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 5,
            title: "SUBSCRIPTION AMOUNT",
            sortable: false,
            value: "subscription_amount",
            cell: (data: SeparateAccountsRow) => (
                <EditableCell
                    rowId={data.id}
                    cellValueType="string"
                    cellValue="comments"
                    rowIdKey={"id"}
                    initialValue={FloatToCurrencyParser(
                        data.subscription_amount,
                        data.currency
                    )}
                    type="numericalField"
                    setTableIsLoading={setTableIsLoading}
                    updateTable={() => setReload(reload + 1)}
                    inputParser={StringToFloatParser}
                    onUpdate={(idx: number, value: string) => {
                        return updateFeesData(
                            "subscription_amount",
                            data.account_id,
                            selectedPeriod,
                            data.tranche,
                            value,
                            {
                                id: data.uuid,
                                version: data.version,
                            }
                        );
                    }}
                />
            ),

            show: true,
            fullDataRow: true,
        },
        {
            id: 3,
            title: "OWNERSHIP AT SUBSCRIPTION DATE",
            sortable: false,
            value: "ownership_at_subscription_date",
            cell: (data: SeparateAccountsRow) => (
                <TableCell
                    data={FloatToPercParser(
                        data.ownership_at_subscription_date
                    )}
                />
            ),
            show: true,
            fullDataRow: true,
        },

        {
            id: 6,
            title: "GROSS MARKET VALUE ACCOUNT",
            sortable: false,
            value: "gross_market_value_account",
            cell: (data: SeparateAccountsRow) => (
                <TableCell
                    data={FloatToCurrencyParser(
                        data.gross_market_value_account,
                        data.currency
                    )}
                />
            ),
            show: true,
            fullDataRow: true,
        },

        {
            id: 13,
            title: "NEW OWNERSHIP SPLIT",
            sortable: false,
            value: "new_ownship_split",
            cell: (data: SeparateAccountsRow) => (
                <TableCell data={FloatToPercParser(data.new_ownship_split)} />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 14,
            title: "CLIENT CONFIG SETUP",
            sortable: false,
            value: "client_config_setup",
            cell: (data: SeparateAccountsRow) => (
                <TableCell data={data.client_config_setup} />
            ),
            show: true,
            fullDataRow: true,
        },
    ];
    return (
        <SubscriptionsContainer>
            <div className="tableDiv">
                <Table
                    emptyText={"No Data"}
                    showEmptyIcon={false}
                    data={subscriptions}
                    columns={columns}
                    filterQuery={filterQuery + "&period=" + period}
                    reload={reload}
                    pagination
                    defaultSortKey="event_date"
                    defaultSortOrder="DESC"
                    stickyColumns={[0, 1]}
                />
            </div>
        </SubscriptionsContainer>
    );
};
export default Subscriptions;
