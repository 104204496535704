import styled from "styled-components";

export default styled.div`
    .cell {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        font-size: 16px;

        height: 100%;

        margin: 0;
    }
`;
