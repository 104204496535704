import styled from 'styled-components';

export default styled.div`

    .notSigned { 
        cursor: pointer   
    }

    .icon {
        margin-left: 5px;
        height: 20px;
        width: 20px;
        color: ${(props) => props.theme.tableIconHighlight};
    }

    .confirmText {
        display: flex;
        font-weight: bolder;
        letter-spacing: 0.1em;
        font-size: 16px;
        color: ${(props) => props.theme.homeListRowHeader};
    }

`;