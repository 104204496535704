import styled from "styled-components";

export default styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;

    background-color: ${(props) => props.theme.modalBackground};
    backdrop-filter: ${(props) => props.theme.modalBackgroundFilter};

    z-index: 50;

    padding: 50px 50px 0 50px;

    overflow-y: scroll;

    .modalInner {
        display: flex;
        flex-direction: column;

        flex-grow: 1;

        min-width: 100%;
        max-width: 100%;
        min-height: fit-content;

        background-color: ${(props) => props.theme.modalInnerBackground};
        box-shadow: ${(props) => props.theme.modalBoxShadow};

        border-radius: 5px;

        margin-bottom: 50px;

        .tabsContent {
            background-color: ${(props) => props.theme.modalInnerBackground};
            flex-grow: 1;
        }

        .tabItem {
            .modesCell {
                margin-left: 10px;
            }
        }

        .header {
            display: flex;
            flex-direction: column;

            padding: 10px 20px;
            color: ${(props) => props.theme.pageTitleText};

            .filterBtn {
                margin: 0 10px;
            }

            .title {
                display: flex;
                flex-direction: row;
                align-items: center;

                .focusList {
                    margin-bottom: 2px;
                    margin-left: 10px;
                    font-size: 20px;
                }
            }

            .topRow {
                display: flex;
                justify-content: space-between;

                .headerActions {
                    display: flex;
                    flex-direction: row;

                    .button {
                        margin-top: 5px;
                        margin-right: 0;
                    }
                }
            }

            .details {
                display: flex;
                flex-direction: row;
                margin: 0 -5px 20px -5px;

                .detail {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin: 0 5px;

                    p {
                        color: ${(props) => props.theme.textPrimary};
                        font-size: 14px;
                    }

                    svg {
                        color: #999999;
                        font-size: 10px;
                        margin-right: 4px;
                        margin-bottom: 2px;
                    }
                }
            }
        }

        .badge {
            display: flex;
            justify-content: center;
            align-items: center;

            border-radius: 5px;

            margin-left: 6px;
            margin-bottom: 2px;

            padding: 1px 5px 0 5px;

            min-height: 20px;
            max-height: 20px;

            font-size: 14px;
            line-height: 14px;
            font-weight: bolder;

            background-color: ${(props) => props.theme.subMenuBadgeBackground};
            color: ${(props) => props.theme.subMenuBadgeText};

            &.primary {
                background-color: ${(props) =>
                    props.theme.primaryButtonBackground};
                color: ${(props) => props.theme.primaryButtonForeground};
            }

            &.secondary {
                background-color: ${(props) =>
                    props.theme.subMenuBadgeBackgroundSecondary};
                color: ${(props) => props.theme.subMenuBadgeTextSecondary};
            }
        }
    }

    .loading {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
        min-height: 100%;

        .noData {
            color: ${(props) => props.theme.pageSubTitleText};
        }
    }

    .editOptions {
        display: flex;
        flex-direction: row;
        margin: 10px -10px;
    }
`;
