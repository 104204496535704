import { useEffect, useState} from "react";
import { useHistory } from 'react-router-dom';
import { cloneDeep } from "lodash";

// Styles
import EditGrowthVotingModalContainer from "./EditGrwothVotingModal.styles";

// Services
import { ApiHandler } from "services/api_handler";

// Context
import {useSettings} from "context/Settings.context";
import { useAuth } from "context/Auth.context";

// Types
import {DealDetails, DealTeam, DealVote, FullDealDetails} from "assets/interfaces/growth_voting";

// Components
import Modal from "components/Common/Modal/Modal";
import ButtonSmall from "../../../Common/ButtonSmall/ButtonSmall";
import HorizontalStepper from "components/Common/Stepper/Stepper";
import Button from "components/Common/Button/Button";
import EditDealDetails from "../EditDealDetails/EditDealDetails";
import EditDealTeams from "../EditDealTeams/EditDealTeams";
import EditDealVotes from "../EditDealVotes/EditDealVotes";
import DealSummary from "../DealSummary/DealSummary";

// icon
import {faClose} from "@fortawesome/free-solid-svg-icons";
import { debug } from "console";


interface CreateGrowthVotingModalProps {
    showModal: boolean;
    setShowModal: Function;
    deal?: FullDealDetails|null; //if passed we edit an existing one
    dealSaved?: () => void; // listener if the deal updated
}


const STEP_DETAILS = "Deal Details";
const STEP_TEAMS = "Deal Teams";
const STEP_VOTES = "Vote Types";
const STEP_REVIEW = "Review";
const STEPS = [STEP_DETAILS, STEP_TEAMS, STEP_VOTES, STEP_REVIEW];

const CREATE_DEAL = "api/growth_voting/create-deal"
const UPDATE_DEAL = "api/growth_voting/deals"

const CreateGrowthVotingModal  = ({showModal, setShowModal, deal, dealSaved}: CreateGrowthVotingModalProps) => {
    const getEmptyDealDetail = (): DealDetails => {
        return {
            funding_round: "",
            description: "",
            company: {
                name: "",
                url: "",
            },
            created: user.id
        }
    }

    const { user } = useAuth();
    const history = useHistory();
    const [loading, setLoading] = useState<boolean>(false);

    const [currentStep, setCurrentStep] = useState(STEP_DETAILS);
    const [details, setDetails] = useState<DealDetails>(getEmptyDealDetail());
    const [teams, setTeams] = useState<DealTeam[]>([]);
    const [votes, setVotes] = useState<DealVote[]>([]);
    const { setShowLoadingToast, setToast } = useSettings();

    const setNextStep = () => {
        let currentIndex = STEPS.indexOf(currentStep);
        if (currentIndex < STEPS.length - 1) {
            setCurrentStep(STEPS[currentIndex + 1]);
        } else {
            saveDeal();
        }
    };

    const validate = () => {
        if (!details.funding_round) {
            setToast({show: true, message: "Please add the Milestone", type: "error"});
            return false;
        }

        if (!details.company.name) {
            setToast({show: true, message: "Please add a Company", type: "error"});
            return false;
        }

        return true;
    }

    const redirectToDeals = () => {
        history.push("/growth-voting/deals/");
    }

    const saveDeal = async () => {
        if (!validate()) {
            return;
        }

        let dealToSave ={"deal": {
            ...details,
            teams: teams,
            votes: votes
        }};

        if (deal) {
            update(dealToSave);
        } else {
            create(dealToSave)
        }

        // close
        setShowModal(false);
    }

    const create = async (deal: any) => {
        await new ApiHandler(CREATE_DEAL, setShowLoadingToast, setToast).post(deal);
        // redirect
        redirectToDeals();
    }

    const update = async (deal: any) => {
        const url = `${UPDATE_DEAL}/${deal.deal.id}`;
        await new ApiHandler(url, setShowLoadingToast, setToast).put(deal);
        dealSaved && dealSaved();
    }

    const setPreviousStep = () => {
        let currentIndex = STEPS.indexOf(currentStep);
        if (currentIndex > 0) {
            setCurrentStep(STEPS[currentIndex - 1]);
        }
    }

    useEffect(() => {
        if (!deal) return;

        const localDeal = cloneDeep(deal)


        setDetails(localDeal);
        setTeams(localDeal.teams)
        setVotes(localDeal.votes)
    },[deal]);

    return (
        <EditGrowthVotingModalContainer>
            <Modal loading={loading} width={"58vw"}>
                <div className={"btn-close-container"}>
                    <ButtonSmall
                        color={"flat"}
                        text={"Close"}
                        icon={faClose}
                        click={() => {setShowModal(!showModal)}}
                    />
                </div>
                <HorizontalStepper steps={STEPS} selectedStep={currentStep} />

                <div className={"step-content"}>
                    {currentStep === STEP_DETAILS && <EditDealDetails details={details} setDetails={setDetails} />}
                    {currentStep === STEP_TEAMS && <EditDealTeams teams={teams} setTeams={setTeams} />}
                    {currentStep === STEP_VOTES && <EditDealVotes votes={votes} setVotes={setVotes} />}
                    {currentStep === STEP_REVIEW && <DealSummary votes={votes} details={details} teams={teams} />}
                </div>

                <div className={"step-actions"}>
                    <Button
                        click={setPreviousStep}
                        text={"Back"}
                        disabled={STEPS.indexOf(currentStep) == 0}
                    />

                    <Button
                        click={setNextStep}
                        text={(STEPS.indexOf(currentStep) == STEPS.length - 1) ? "Save" : "Next"}
                    />
                </div>
            </Modal>
        </EditGrowthVotingModalContainer>
    );
};

export default CreateGrowthVotingModal
