// Components
import PageTitle from "components/Common/PageTitle/PageTitle";
import ValidationRules from "components/Screens/Admin/ValidationRules/ValidationRules";

// Styles
import ValidationRulesPageContainer from "./ValidationRules.styles";

export default function ValidationRulesPage() {
    return (
        <ValidationRulesPageContainer className={"pageContainer"}>
            <PageTitle title="VALIDATION RULES" />
            <ValidationRules />
        </ValidationRulesPageContainer>
    );
}
