import styled from "styled-components";

export default styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;

    user-select: none;

    background-color: #eeeeee;

    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;

    .loginModal {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        background-color: white;

        border-radius: 5px;
        overflow: hidden;

        min-height: 120px;
        max-height: 120px;

        box-shadow: 0px 0px 20px 9px rgba(0, 0, 0, 0.1);

        .header {
            display: flex;
            justify-content: center;
            align-items: center;

            min-height: 120px;
            max-height: 120px;
            min-width: 120px;
            max-width: 120px;

            background-color: #004453;

            border-radius: 5px 0 0 5px;

            img {
                display: flex;
                width: 110px;
            }
        }

        .body {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;

            padding: 20px;
        }
    }

    button {
        min-width: 400px;
        max-width: 400px;
        min-height: 40px;
        max-height: 40px;
        margin-bottom: 5px;
        border-radius: 5px;
        color: white;
        background-color: #004453;
        border: none;
        font-size: 18px;
        letter-spacing: 3px;
        font-weight: bolder;
        cursor: pointer;
        user-select: none;

        :hover {
            background-color: #002e38;
            color: white;
        }
    }

    p {
        max-width: 600px;
        word-break: break-all;
        color: #777777;
        letter-spacing: 3px;
        cursor: pointer;
        margin-top: 10px;

        :hover {
            color: #222222;
        }
    }

    .quantSparkLogo {
        display: flex;
        position: absolute;

        bottom: 10px;

        width: 100px;
    }

    @media screen and (max-width: 600px) {
        .loginModal {
            flex-direction: column;

            min-height: 420px;
            max-height: 420px;
            min-width: 300px;
            max-width: 300px;

            .header {
                min-height: 300px;
                max-height: 300px;
                min-width: 300px;
                max-width: 300px;

                border-radius: 5px 0 0 5px;

                img {
                    width: 200px;
                }
            }

            .body {
                min-width: 300px;
                max-width: 300px;

                padding: 20px;

                button {
                    min-width: 100%;
                    max-width: 100%;
                }
            }
        }
    }
`;
