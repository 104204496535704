import styled from 'styled-components';

export default styled.div`
    .downloadIcon {
        cursor: pointer;

        svg {
            color: ${(props) => props.theme.tableIconHighlight};
            font-size: 20px;
        }
    }

    .generateBtn {
        margin-top: 15px;
        margin-bottom: 15px;
        float: right;
    }

    .tableDiv {
        clear: right;
        margin-top: 15px;
    }
`;