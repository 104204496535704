import React, { useEffect, useState } from "react";
import {Link} from "react-router-dom";

// Styles
import DealListContainer from "./DealList.styles";

// Interface
import { FullDealDetails } from "assets/interfaces/growth_voting";

// Utils
import { getDealName, getVoteName } from "utils/growth_voting.utils";
import { formatDate } from "utils/date.utils";

// enums
import {EnumGrowthAnswerTypes, EnumGrowthVotingVoteTypes} from "assets/enums/growth_voting";

// Icons
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";

// Components
import ButtonSmall from "../../../Common/ButtonSmall/ButtonSmall";
import VoteIcon from "../VoteIcon/VoteIcon";
import DealIcon from "../DealIcon/DealIcon";
import AnswerResult from "components/Screens/GrowthVoting/VoteDetail/AnswerResult/AnswerResult";

interface Props {
    deals: FullDealDetails[]
}

const DealList = ({deals}: Props) => {
    const openDeal = (id: string|undefined) => {
        alert("Open Deal");
    }

    return (
        <DealListContainer>
            <div className={"deals"}>
              <div key={"header"} className={"deal header"}>
                        <div>Deal </div>
                        <div className={"bq"}> BQ </div>
                        <div className={"mq"}> MQ </div>
                        <div className={"sp"}> SP </div>
                        <div className={"actions"}> </div>
                </div>
                {deals.map((deal: FullDealDetails, index) => (
                    <div key={index} className={"deal"}>
                        <div className={"name"}>
                            <DealIcon deal={deal} /> {getDealName(deal)}
                            <div className={"created"}>{deal.created && formatDate(new Date(deal.created.substr(0,10)))}</div>
                        </div>
                        <div className={"vote bq"}>
                            {deal.votes.length > 0 &&
                                deal.votes.filter(vote => vote.type === EnumGrowthVotingVoteTypes.BQ).map((vote, index) => (
                                    <div className={"vote-score"} key={index}>
                                            <div className={"vote-type"}>
                                                {deal.votes.length > 0 && deal.votes.filter(vote => vote.type === EnumGrowthVotingVoteTypes.BQ).map((vote, index) => (
                                                    <div className={"vote-type"} key={index}>
                                                        <VoteIcon vote={vote} />
                                                    </div>
                                                ))}
                                            </div>
                                            {vote?.score && <AnswerResult result={vote.score}
                                                answerType={EnumGrowthAnswerTypes.Rating_1_5}
                                                numberOfAnswers={1}
                                            />}
                                    </div>
                                ))}
                        </div>
                        <div className={"vote mq"}>
                        {deal.votes.length > 0 &&
                                deal.votes.filter(vote => vote.type === EnumGrowthVotingVoteTypes.MQ).map((vote, index) => (
                                    <div className={"vote-score"} key={index}>
                                        {vote?.score && <>
                                            <div className={"vote-type"}>
                                                {deal.votes.length > 0 && deal.votes.filter(vote => vote.type === EnumGrowthVotingVoteTypes.MQ).map((vote, index) => (
                                                    <div className={"vote-type"} key={index}>
                                                        <VoteIcon vote={vote} />
                                                    </div>
                                                ))}
                                            </div>
                                            <AnswerResult result={vote.score}
                                                answerType={EnumGrowthAnswerTypes.Rating_1_5}
                                                numberOfAnswers={1}
                                            />
                                        </>}
                                    </div>
                                ))}
                        </div>
                        <div className={"vote sp"}>
                            {deal.votes.length > 0 &&
                                deal.votes.filter(vote => vote.type === "st").map((vote, index) => (
                                    <div className={"vote-score"} key={index}>
                                        <div className={"vote-type"}>
                                            {deal.votes.length > 0 && deal.votes.filter(vote => vote.type === EnumGrowthVotingVoteTypes.ST).map((vote, index) => (
                                                <div className={"vote-type"} key={index}>
                                                    <VoteIcon vote={vote} />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                        </div>
                        <div className={"actions"}>
                            <Link to={{
                                pathname: `/growth-voting/deals/${deal.id}`,
                                state: { deal: deal }
                            }}>
                                <ButtonSmall
                                    text={"Open Deal"}
                                    icon={faArrowRight}
                                    click={() => {}}
                                />
                            </Link>
                        </div>
                    </div>
                ))}
            </div>
        </DealListContainer>
    );
};

export default DealList
