import React from "react";

// Styles
import ValidationRulesContainer from "./FeesValidationRules.styles";

// Components
import FeesValidationRules from "components/Screens/Fees/FeesValidationRules/FeesValidationRules";

interface ValidationRulesProps {}

const FeesValidationRulesPage = ({}: ValidationRulesProps) => {
    return (
        <ValidationRulesContainer>
            <FeesValidationRules />
        </ValidationRulesContainer>
    );
};
export default FeesValidationRulesPage;
