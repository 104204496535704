// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBell,
    faCheck,
    faClock,
    faExclamation,
    faSync,
} from "@fortawesome/free-solid-svg-icons";

// Utils
import { toDateAndTimeSeconds, truncateString } from "utils/general.utils";

// Styles
import NotificationsDropDownItemContainer from "./NotificationsDropDownItem.styles";

type Props = {
    item: NotificationItem;
};

export default function NotificationsDropDownItem({ item }: Props) {
    return (
        <NotificationsDropDownItemContainer>
            <div className="type">
                <div className={`circle ${item.severity.toLocaleLowerCase()}`}>
                    <FontAwesomeIcon icon={faBell} fixedWidth />
                </div>
            </div>
            <div className="info">
                <p
                    className={`description  ${item.severity.toLocaleLowerCase()}`}
                >
                    {truncateString(item.message, 43)}
                </p>
                <p className="date">{toDateAndTimeSeconds(item.created_on)}</p>
            </div>
        </NotificationsDropDownItemContainer>
    );
}
