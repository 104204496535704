// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBookOpen,
    faSearch,
    faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

// Material UI
import Tooltip from "@mui/material/Tooltip";

// Styles
import SummarySheetCountsContainer from "./SummarySheetCounts.styles";

type Props = {
    focus?: number;
    portfolio?: number;
    error: number;
};

export default function SummarySheetCounts({ focus, portfolio, error }: Props) {
    return (
        <SummarySheetCountsContainer className="warningCounts">
            {focus && (
                <Tooltip title={"FOCUS LIST COMPANIES"}>
                    <div className="count blue">
                        <FontAwesomeIcon icon={faSearch} fixedWidth />
                        <p>{focus}</p>
                    </div>
                </Tooltip>
            )}
            {portfolio && (
                <Tooltip title={"PORTFOLIO COMPANIES"}>
                    <div className="count orange">
                        <FontAwesomeIcon icon={faBookOpen} fixedWidth />
                        <p>{portfolio}</p>
                    </div>
                </Tooltip>
            )}

            {error > 0 && (
                <Tooltip title={"ERRORS"}>
                    <div className="count red">
                        <FontAwesomeIcon icon={faTimesCircle} fixedWidth />
                        <p>{error}</p>
                    </div>
                </Tooltip>
            )}
        </SummarySheetCountsContainer>
    );
}
