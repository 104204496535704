import colors from "assets/styles/colors";

export default {
    askAdaBackground: colors.grey10,
    askAdaSeparators: colors.grey80,
    askAdaText: "white",
    askAdaBubbleBackground: "white",
    askAdaBubbleColor: colors.textNavy,
    askAdaAvatarColor: colors.grey10
,
};
