import { useState } from "react";

// Material UI
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Popper from "@mui/material/Popper";

// DatePicker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// FontAwesome
import {
    faCaretDown,
    faSearch,
    faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Utils
import { toDate } from "utils/general.utils";

// Styles
import { OrderByButtonContainer, PopUpContainer } from "./OrderByButton.styles";

type OrderBy = {
    id?: number;
    options?: Array<{
        label: string;
        value: any;
    }>;
    value?: any;
    setValue?: Function;
    disabled?: string;
    name: string;
};

export default function OrderByButton({
    options,
    value,
    setValue,
    disabled,
    name,
}: OrderBy) {
    const [open, setOpen] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<any>(null);

    const [results, setResults] = useState<Option[]>(options || []);

    const handleClick = (event: React.MouseEvent) => {
        setResults(options || []);
        setAnchorEl(event.currentTarget);
        setOpen(!open);
    };

    const handleSelectFilter = (option: Option) => {
        setOpen(false);
        setValue!(option.value);
    };

    const getValue = () => {
        return options!.find((option: any) => option.value[0] === value[0])!
            .label;
    };

    return (
        <>
            {!disabled && (
                <OrderByButtonContainer
                    onClick={handleClick}
                    className={"filterBtn"}
                >
                    <p className={"label"}>{name}</p>
                    <p className="selected">{getValue()}</p>
                    <FontAwesomeIcon icon={faCaretDown} fixedWidth />
                </OrderByButtonContainer>
            )}

            <Popper
                open={open}
                anchorEl={anchorEl}
                disablePortal
                style={{ zIndex: 50 }}
            >
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                    <PopUpContainer>
                        <div className="options">
                            {results.length > 0 ? (
                                results.map((option, index) => (
                                    <div
                                        className="option"
                                        onClick={() =>
                                            handleSelectFilter(option)
                                        }
                                        key={index}
                                    >
                                        <p>{option.label}</p>
                                    </div>
                                ))
                            ) : (
                                <div className="noResults">
                                    <p>No Results</p>
                                </div>
                            )}
                        </div>
                    </PopUpContainer>
                </ClickAwayListener>
            </Popper>
        </>
    );
}
