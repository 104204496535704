import React, {useEffect, useState} from "react";

// Styles
import OpenCloseContainer from "./OpenCloseButton.styles";
import Button from "components/Common/Button/Button";
import ButtonSmall from "components/Common/ButtonSmall/ButtonSmall";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faLockOpen } from "@fortawesome/free-solid-svg-icons";

// Components

interface Props {
    open: boolean;
    changeHanlder: (open: boolean) => void;
    hoverText: string;
}

const OpenCloseButton = ({open, changeHanlder, hoverText}: Props) => {

    return (
        <OpenCloseContainer>
            <>
                {!open && (
                    <ButtonSmall
                        text="CLOSED"
                        click={() => changeHanlder(true)}
                        color={"accent"}
                        icon={faLock}
                    />
                )}
                {open && (
                    <ButtonSmall
                        text="OPEN"
                        color={"third"}
                        click={() => changeHanlder(false)}
                        icon={faLockOpen}
                    />
                )}
            </>
        </OpenCloseContainer>
    );
};
export default OpenCloseButton;
