import React from "react";

// Styles
import GHGViewContainer from "./GHGView.styles";

interface GHGViewProps {}

const GHGView = ({}: GHGViewProps) => {
    return (
        <GHGViewContainer>
            <div className="iframeContainer">
                <iframe
                    title="ESG - CDP vLive (QS)"
                    src="https://app.powerbi.com/reportEmbed?reportId=5031aa0f-9f78-4578-b8de-6f05c689fc80&autoAuth=true&ctid=bf4450a2-bfcb-472d-8c40-1425eafb1a3b&filterPaneEnabled=false"
                    width="100%"
                    height="100%"
                    allowFullScreen
                    frameBorder={0}
                />
            </div>
        </GHGViewContainer>
    );
};
export default GHGView;
