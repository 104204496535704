import styled from "styled-components";

export default styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 5px;

    max-width: 25px;
    min-width: 25px;
    max-height: 25px;
    min-height: 25px;

    color: black;
    font-weight: bolder;


    &.score-2 {
        background-color: ${(props) => props.theme.bqmqVoteScore2};
    }


    
`;
