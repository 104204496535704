// Styles
import FileTreeContainer from "./FileTree.styles";
import React, { useEffect, useState } from "react";
import Category from "../../Docs/DocsMenu/Category/Category";
import Item from "../../Docs/DocsMenu/Item/Item";

type Props = { tree: any };

export default function FileTree({ tree }: Props) {
    const [fileTree, setFileTree] = useState<any[]>([]);

    useEffect(() => {
        if (Array.isArray(tree)) {
            setFileTree(tree);
        } else {
            setFileTree(JSON.parse(tree));
        }
    }, []);

    return (
        <FileTreeContainer>
            {fileTree &&
                fileTree
                    .sort((a: any, b: any) => a.order - b.order)
                    .map((item: any) => (
                        <React.Fragment key={item.name}>
                            {item.type === "FOLDER" ? (
                                <Category
                                    item={item}
                                    doc={"doc"}
                                    type={"docs"}
                                />
                            ) : (
                                <Item item={item} doc={"doc"} type={"docs"} />
                            )}
                        </React.Fragment>
                    ))}
        </FileTreeContainer>
    );
}
