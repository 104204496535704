import * as Database from "utils/database.utils";

// Logger
import Logger from "utils/logger.utils";
const Log = new Logger();
Log.init("bqmq.utils");

export const getUserAnswerHistory = async (
    userID: string,
    sessionID: string,
    setDates: Function,
    setMyVotes: Function
) => {
    const result = await Database.get(
        `api/bqmq_voting/company/latest-user-votes/${sessionID}/${userID}`
    );
    const data = await result.json();
    const existingVotes: Record<string, string> = {};
    let latestDate: any = null;
    data.data.forEach((vote: Vote) => {
        const date = vote.historic_date ? vote.historic_date : vote.created;
        if (latestDate === null) latestDate = new Date(vote.created);
        const dateObj = new Date(date);
        latestDate = new Date(
            Math.max(dateObj.getTime(), latestDate.getTime())
        );
        if (vote.choice.text === "Comment") {
            existingVotes[vote.question_id] = vote.comment;
        } else {
            existingVotes[vote.question_id] = vote.choice.text;
        }
    });
    setDates(latestDate);
    setMyVotes(existingVotes);
};

const getLatestCompanyDetails = async (
    companyID: string,
    sessionID: string
) => {
    const latestCompanyDetails = await Database.get(
        `api/bqmq_voting/company/latest-mode/${companyID}/${sessionID}`
    );
    let latestCompanyData = await latestCompanyDetails.json();
    latestCompanyData = latestCompanyData.data;
    return latestCompanyData;
};

export const getAllFactors = async () => {
    Log.info("getAllFactors()");

    let res = await Database.get(
        "api/bqmq_voting/factors?page=1&pageSize=1000&sortKey=factor_id&sortOrder=ASC"
    );
    let data = await res.json();
    let factorsList: FactorListType = {};
    const factorMap = {
        BQ: { name: "Business Quality", key: "BQ" },
        MQ: { name: "Management Quality", key: "MQ" },
        FADE: { name: "Extended Fade", key: "FADE" },
        MQSTAR: { name: "Management Quality*", key: "MQSTAR" },
    };
    data.data.forEach((factor: { text: string }) => {
        const mappedFactor = factorMap[factor.text as keyof typeof factorMap];
        if (!mappedFactor) return;
        factorsList[mappedFactor.key as keyof typeof factorsList] = {
            name: mappedFactor.name,
            short_name: mappedFactor.name,
            order: null,
        };
    });

    Log.info("FACTORS: ", factorsList);

    return factorsList;
};

export const getAnalysts = async (setAnalysts: Function) => {
    const response = await Database.get(`api/bqmq_voting/dropdowns/analysts`);
    const analysts = await response.json();
    try {
        setAnalysts(analysts.data);
    } catch (e) {
        console.error(e);
    }
};

export const getTeams = async (setTeams: Function) => {
    const response = await Database.get(`api/bqmq_voting/dropdowns/sectors`);
    const teams = await response.json();
    try {
        setTeams(teams.data);
    } catch (e) {
        console.error(e);
    }
};

export const getCompanies = async (setCompanies: Function) => {
    const response = await Database.get(`api/bqmq_voting/dropdowns/companies`);
    const companies = await response.json();
    try {
        setCompanies(companies.data);
    } catch (e) {
        console.error(e);
    }
};

export const getAllQuestions = async () => {
    Log.info("getAllQuestions()");

    let res = await Database.get(
        "api/bqmq_voting/questions?page=1&pageSize=1000&sortKey=order_number&sortOrder=ASC"
    );
    let data = await res.json();

    return data.data;
};

export const getSessions = async (
    setSessions: Function,
    setPendingSessions: Function,
    setUpcomingSessions: Function,
    user_id: string
) => {
    const response = await Database.get(
        `api/bqmq_voting/dropdowns/sessions/${user_id}`
    );
    const sessions = await response.json();
    try {
        setSessions(sessions.data.sessions);
        setUpcomingSessions(sessions.data.upcoming_sessions);
        setPendingSessions(sessions.data.pending_sessions);
    } catch (e) {
        console.error(e);
    }
};

export const sortVotes = (votes: Vote[], sortedResults: any): Vote[] => {
    let names = sortedResults.map((result: any) => result[0]);
    votes.sort((voteA: Vote, voteB: Vote) => {
        const indexA = names.indexOf(voteA.question.text);
        const indexB = names.indexOf(voteB.question.text);

        return indexA - indexB;
    });
    return votes;
};

export const sortAnswersForResults = (
    results: Results
): [string, NumericalResults | NonNumericalResults][] => {
    let names = ["bq_overall", "mq_overall", "fade", "mqstar"];
    let list = Object.entries(results);
    const index = list.findIndex((obj) => names.includes(obj[0]));
    if (index === -1) {
        // name not found, return original list
        return list;
    }
    const obj = list[index];
    return [obj, ...list.slice(0, index), ...list.slice(index + 1)];
};

export const commentSorter = (
    detailedCommentA: BQMQCommentDetail,
    detailedCommentB: BQMQCommentDetail
) => {
    if (
        detailedCommentA.text.endsWith("_comments") &&
        !detailedCommentB.text.endsWith("_comments")
    ) {
        return 1;
    } else if (
        !detailedCommentA.text.endsWith("_comments") &&
        detailedCommentB.text.endsWith("_comments")
    ) {
        return -1;
    } else {
        return 0;
    }
};

export const createPartial = (
    func: (...args: any[]) => any,
    ...args: any[]
) => {
    return function (this: any, ...innerArgs: any[]) {
        var allArguments = args.concat(innerArgs);
        return func.apply(this, allArguments);
    };
};

export const filterResults = (
    query: QueryString,
    teams: Sector[],
    data: any
) => {
    if (data.length === 0) return data;
    let newFilteredData = [...data];
    const getCompany = (session: BQMQSession) => session.company;
    const getSession = (session: BQMQSession) => session;

    let getFunc: Function;
    if (data[0].company) {
        getFunc = getCompany;
    } else {
        getFunc = getSession;
    }

    if (query.filter_search) {
        newFilteredData = newFilteredData.filter((session: any) => {
            const name = getFunc(session).name || getFunc(session).label;
            return name
                .toUpperCase()
                .includes(query.filter_search?.toUpperCase());
        });
    }

    if (query.filter_company_id) {
        newFilteredData = newFilteredData.filter((session: any) => {
            const id = getFunc(session).id || getFunc(session).value;
            return id === query.filter_company_id;
        });
    }

    if (query.filter_gim_subteam) {
        let sector = teams.filter(
            (team: Sector) =>
                team.value === query.filter_gim_subteam?.replace("_AND_", " & ")
        );
        if (sector.length === 0) return newFilteredData;
        const sectorText = sector[0].label;
        newFilteredData = newFilteredData.filter((session: any) => {
            return getFunc(session).sector?.name === sectorText;
        });
    }

    return newFilteredData;
};

export const getSelectedID = (
    filterQuery: string,
    setSelected: Function,
    includeText: string
) => {
    let companyID: string;
    if (filterQuery === "") setSelected("");
    if (filterQuery.includes(includeText)) {
        companyID = filterQuery.split(includeText)[1];
        setSelected(companyID);
    }
};

export const filterCompaniesWithNoVotes = (companies: BQMQCompany[]) => {
    return companies.filter(
        (company: BQMQCompany) =>
            company.session_count && company.session_count > 0
    );
};

export const addTableId = (sessions: BQMQSession[]): BQMQSession[] => {
    return sessions.map((row: BQMQSession, index: number) => ({
        ...row,
        tableID: row.id + row.date + index,
    }));
};

const setResult = (countedVotes: CountedVotes) => {
    const result: any = {
        BQ: {},
        MQ: {},
        FADE: {},
        MQSTAR: {},
    };

    for (const [question, new_votes] of Object.entries(countedVotes)) {
        const typedNewVotes = new_votes as { factor: string; mode: string };
        const factorText = typedNewVotes.factor as keyof typeof result;
        result[factorText] = result[factorText] || {};
        result[factorText][question] = new_votes;
        if (
            question === "bq_overall" ||
            question === "mq_overall" ||
            question === "fade" ||
            question === "mqstar"
        ) {
            result[factorText]["mode"] = typedNewVotes["mode"];
        }
    }
    return result;
};


export const countVotes = (
    votes: Vote[],
    defaultChoices: Results
): CountedVotes => {
    const countedVotes: CountedVotes = {};
    for (const vote of votes) {
        
        if (vote.choice.text === "Comment") {
            continue;
        }

        if (!countedVotes[vote.question.text]){
            countedVotes[vote.question.text] = {
                ...defaultChoices[vote.question.text as keyof Results],
                sum: 0,
                count:0,
                mode: "",
                mean: ""
            };
        }
        countedVotes[vote.question.text][vote.choice.text] =
            (countedVotes[vote.question.text][vote.choice.text] || 0) + 1; 

        countedVotes[vote.question.text]["factor"] = vote.question.factor.text; 
        const numericChoice = +vote.choice.text;

        //compute modes (in a rolling fashion)
        let mode = "";
        let maxCount = -1;
        for (const [choice, count] of Object.entries(
            countedVotes[vote.question.text]
        )){
            if (choice !== "sum" && choice != "count" && choice !== "mode" && choice !== "factor" && count > maxCount){
                mode = choice;
                maxCount = count;
            }
        }
        countedVotes[vote.question.text]["mode"] = mode;

        if(!isNaN(numericChoice)){
            countedVotes[vote.question.text]["sum"] += numericChoice;
            countedVotes[vote.question.text]["count"] += 1;
        }
    }

    //compute mean
    for (const questionText in countedVotes){
        const questionData = countedVotes[questionText];
        let mean = "";
        if (questionData["count"] > 0){
            mean = (questionData["sum"]/questionData["count"]).toFixed(1); //one decimal place
        }
        countedVotes[questionText]["mean"] = mean;
    }
    return countedVotes;
};

const sortComments = (
    questionID: string,
    resultsData: BQMQSessionExtended
): CommentDetail[] => {
    let commentsList: CommentDetail[] = [];
    resultsData.votes.forEach((vote: Vote) => {
        if (vote.question.text === questionID && vote.comment !== "") {
            commentsList.push({
                user: vote.analyst.name,
                comment: {
                    value: vote.comment,
                    date: vote?.historic_date
                        ? vote?.historic_date
                        : vote.created,
                },
            });
        }
    });
    return commentsList;
};

const getComments = (latestCompanyData: BQMQSessionExtended) => {
    let comments = {
        BQ: sortComments("bq_comments", latestCompanyData),
        MQ: sortComments("mq_comments", latestCompanyData),
        FADE: sortComments("fade_comments", latestCompanyData),
        MQSTAR: sortComments("mqstar_comments", latestCompanyData),
    };
    return comments;
};

const organizeCompanyData = (
    latestCompanyData: BQMQSessionExtended,
    votes: Vote[]
) => {
    let countedVotes = countVotes(votes, latestCompanyData.default_choices);
    let result = setResult(countedVotes);
    const latestCompanyDataWithResult = {
        ...latestCompanyData,
        ...result,
    };
    let comments = getComments(latestCompanyDataWithResult);
    const latestCompanyDataWithResultAndComments = {
        ...latestCompanyDataWithResult,
        comments: comments,
    };
    return latestCompanyDataWithResultAndComments;
};

const getCoreUserVotes = (results: BQMQSessionExtended): Vote[] => {
    return results.votes.filter((vote: Vote) =>
        results.core
            .map((analyst: Analyst) => analyst.id)
            .includes(vote.analyst_id)
    );
};

const getCoreComments = (factor: string, results: any) => {
    return results.comments[factor].filter((comment: UserComment) =>
        results.core.map((obj: any) => obj.name).includes(comment.user)
    );
};

const filterCommentsByFactor = (coreCompanyData: any) => {
    for (const factor of Object.keys(coreCompanyData.comments)) {
        coreCompanyData.comments[factor] = getCoreComments(
            factor,
            coreCompanyData
        );
    }
};

export const getResults = async (
    companyID: string,
    sessionID: string,
    setDefaultChoices: Function,
    setsessionAllResults: Function,
    setSessionCoreResults: Function,
    setPreviousVotes: Function
) => {
    let allCompanyData = await getLatestCompanyDetails(companyID, sessionID);
    setDefaultChoices(allCompanyData.default_choices);
    setPreviousVotes(allCompanyData.previous_votes);
    const sessionResults = organizeCompanyData(
        allCompanyData,
        allCompanyData?.votes
    );
    setsessionAllResults(sessionResults);
    let coreCompanyData = { ...allCompanyData };
    coreCompanyData.votes = getCoreUserVotes(coreCompanyData);
    const coreCompanyVotesWithComments = organizeCompanyData(
        coreCompanyData,
        coreCompanyData.votes
    );
    filterCommentsByFactor(coreCompanyVotesWithComments);
    setSessionCoreResults(coreCompanyVotesWithComments);
};

export const getMode = (factor: string, results: any) => {
    if (factor === "BQ") {
        return results?.session?.bq;
    } else if (factor === "MQ") {
        return results?.session?.mq;
    } else {
        return results[factor].mode;
    }
};

export const isCoreResults = (coreResults: BQMQSessionExtended) => {
    return coreResults?.votes.length > 0;
};

export const hasSetupChanged = (
    originalState: BQMQSession,
    currentState: BQMQSession
) => {
    return !!(
        currentState.company &&
        JSON.stringify(originalState) !== JSON.stringify(currentState)
    );
};

export const factors = [
    {
        name: "BQ",
    },
    {
        name: "MQ",
    },
    {
        name: "FADE",
    },
    {
        name: "MQSTAR",
    },
];

export const QUESTIONMAP: any = {
    BQ: {
        moat: { label: "Moat", order: 0 },
        attractiveness_of_castle: { label: "Castle", order: 1 },
        critical_sustainability_factors: { label: "Sustainability", order: 2 },
        business_stability: { label: "Business Stability", order: 3 },
        pricing_power: { label: "Pricing Power", order: 4 },
        ecosystem: { label: "Ecosystem", order: 5 },
        degree_of_difficulty: { label: "Degree of Difficulty", order: 6 },
        bq_overall: { label: "BQ Overall", order: 7 },
        bq_comments: { label: "BQ Comments", order: 8 }, // Optional but makes prior votes mandatory if filled
    },
    MQ: {
        strategy: { label: "Strategy", order: 0 },
        operations: { label: "Operations", order: 1 },
        culture: { label: "Culture", order: 2 },
        capital_allocation: { label: "Capital Allocation", order: 3 },
        alignment: { label: "Alignment", order: 4 },
        governance: { label: "Governance", order: 5 },
        people: { label: "People", order: 6 },
        mq_overall: { label: "MQ Overall", order: 7 },
        mq_comments: { label: "MQ Comments", order: 8 }, // Optional but makes prior votes mandatory if filled
    },
    Fade: {
        fade: { label: "Fade", order: 0, mandatoryIfComments: true }, // Optional but make mandatory if comments are inputted
        fade_comments: { label: "Fade Comments", order: 1 }, // Optional
    },
    MQStar: {
        mqstar: { label: "MQ*", order: 0, mandatoryIfComments: true }, // Optional but make mandatory if comments are inputted
        mqstar_comments: { label: "MQ* Comments", order: 1 }, // Optional
    },
};
export const allFactorsList = [
    {
        name: "Business Quality",
        order: 1,
        short_name: "BQ",
    },
    {
        name: "Management Quality",
        order: 2,
        short_name: "MQ",
    },
    {
        name: "Extended Fade",
        order: 3,
        short_name: "FADE",
    },
    {
        name: "Management Quality*",
        order: 4,
        short_name: "MQSTAR",
    },
];

export const getUserPreviousVotes = (previousVotes: any, setUserPreviousVotes: Function) => {
    const userID = localStorage.getItem("gim-user-id") || 0;
    let previousUserVotes = previousVotes?.filter(
        (vote: any) =>
        vote.analyst.id === userID &&
        !vote.question.factor.text.toLowerCase().includes("comment")
    );
    const previousVoteData: Record<string, string> = {};
    if (previousUserVotes?.length) {
        previousUserVotes.forEach((vote: any) => {
            previousVoteData[vote.question.id] = vote.choice.text;
        });
    }
    setUserPreviousVotes(previousVoteData);
};

export const getQuestionPreviousUserVote = (previousUserVotes: any, questionID: string) => {
    let score = null;
    let choice = previousUserVotes?.[questionID];
    if (choice) {
        score = choice;
    }
    return score;
};


export const getAllCompanySessions = async (setCompanySessions: Function, companyID: string, sessionID: string) => {
    try {
      let res = await Database.get(`api/bqmq_voting/sessions/company-active/${companyID}`);
      if (res.ok) {
        let response = await res.json();
        response.data = response.data.filter((session: any) => session.id !== sessionID);
        setCompanySessions(response);
      }
    } catch (error) {
      console.error("Error fetching company sessions:", error);
    }
};

export const percentageFormatter = (value: number, price: number): string => {
    const percentageChange = ((value / price) - 1) * 100;
    const roundedPercentage = Math.round(percentageChange);
    const percentageToString = roundedPercentage.toString();
    if (isNaN(Number(percentageToString)) || percentageToString.toLowerCase() === 'infinity') {
        return "N/A";
    }
    return percentageToString + "%";
};
