// Core
import { useEffect, useState } from "react";

// Styles
import UserSettingsContainer from "./UserSettings.styles";

// Database
import * as Database from "utils/database.utils";

//Components
import { Switch } from "@mui/material";
import SkeletonLoader from "components/Common/SkeletonLoader/SkeletonLoader";

// Icons
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useBQMQ } from "context/BQMQ.context";
import { useSettings } from "context/Settings.context";

type Props = {};

type BQMQUser = {
    value: string;
    label: string;
    active: boolean;
};

export default function UserSettings({}: Props) {
    const [users, setUsers] = useState<BQMQUser[] | null>(null);
    const { setToast } = useSettings();
    const { analysts, getDropDowns } = useBQMQ();
    const changeValue = async (index: number) => {
        if (users) {
            let newUsers = [...users];
            newUsers[index].active = !users[index].active;
            setUsers(newUsers);
            const result = await Database.put(
                `api/bqmq_voting/settings/employee-status/${users[index].value}`,
                {
                    is_current_employee: users[index].active,
                }
            );
            if (result.ok) {
                setToast({
                    show: true,
                    type: "success",
                    message: "User employment status updated",
                });
                getUsers();
                getDropDowns();
            } else {
                setToast({
                    show: true,
                    type: "error",
                    message: "Error updating user employment status",
                });
            }
        }
    };

    const getUsers = async () => {
        const data = [...analysts];
        const sortedUsers = data
            .sort((a: BQMQUser, b: BQMQUser) =>
                a.label.toLowerCase().localeCompare(b.label.toLowerCase())
            )
            .sort(
                (a: BQMQUser, b: BQMQUser) =>
                    Number(b.active) - Number(a.active)
            );

        setUsers(sortedUsers);
    };

    useEffect(() => {
        getUsers();
    }, []);

    return (
        <UserSettingsContainer>
            {users ? (
                <>
                    <div className={"heading"}>
                        <div className={"end"} />
                        <p data-cy={"list-header"}>USER EMPLOYMENT STATUS</p>
                        <div className={"end right"}>
                            <p>IS A CURRENT EMPLOYEE</p>
                        </div>
                    </div>

                    {users?.map((user: BQMQUser, index: number) => (
                        <div
                            className={"userRow"}
                            key={user.label}
                            onClick={() => changeValue(index)}
                        >
                            <div className={"icon " + user.active}>
                                <FontAwesomeIcon icon={faUser} fixedWidth />
                            </div>

                            <p className={"name " + user.active}>
                                {user.label}
                            </p>

                            <Switch checked={user.active} />
                        </div>
                    ))}
                </>
            ) : (
                <SkeletonLoader type="table" rows={20} />
            )}
        </UserSettingsContainer>
    );
}
