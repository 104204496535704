import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faCog } from "@fortawesome/free-solid-svg-icons";

// Components
import MenuItem from "../MenuItem/MenuItem";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import LoadingSpinner from "../../../Common/LoadingSpinner/LoadingSpinner";
import AnalyticsQueueDropDownItem from "./AnalyticsQueueDropDownItem/AnalyticsQueueDropDownItem";

// Utils
import * as Database from "utils/database.utils";

// Styles
import { MenuTrayDropDownContainer } from "../MainMenu.styles";
import AnalyticsQueueContainer from "./AnalyticsQueue.styles";

export default function AnalyticsQueue() {
    const history = useHistory();

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<any[]>([]);

    const getData = async () => {
        setLoading(true);
        let res = await Database.get(
            "api/analytics-requests?pageSize=10&page=1&sortKey=start_time&sortOrder=DESC"
        );

        if (res.ok) {
            let data = await res.json();
            setData(data.data);
            setLoading(false);
        }
    };

    const handleItemClick = (item: any) => {
        history.push(`/data/analytics-queue`);
        setOpen(false);
    };

    useEffect(() => {
        if (open) {
            getData();
        }
    }, [open]);

    return (
        <AnalyticsQueueContainer id={"analyticsQueueBtn"}>
            <MenuItem
                text={"ANALYTICS QUEUE"}
                isActive={false}
                icon={<FontAwesomeIcon icon={faClock} fixedWidth />}
                click={() => setOpen(true)}
                permissions={["p_fix_extraction", "p_upload_model"]}
                hideTooltip={open}
            />

            {open && (
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                    <MenuTrayDropDownContainer id={"analyticsQueueTray"}>
                        <div className="title">
                            <p>ANALYTICS QUEUE</p>
                            {false && (
                                <FontAwesomeIcon
                                    icon={faCog}
                                    fixedWidth
                                    onClick={() => {
                                        history.push("/");
                                        setOpen(false);
                                    }}
                                />
                            )}
                        </div>

                        <div className="body">
                            {data.length > 0 ? (
                                data.map((item) => (
                                    <div
                                        key={item.id}
                                        className="menu-item"
                                        onClick={() => handleItemClick(item)}
                                    >
                                        <AnalyticsQueueDropDownItem
                                            item={item}
                                        />
                                    </div>
                                ))
                            ) : (
                                <div className="loading">
                                    {loading ? (
                                        <LoadingSpinner noText />
                                    ) : (
                                        <div className="no-results">
                                            <p>NO RESULTS</p>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                        <div
                            className="footer"
                            onClick={() => {
                                history.push("/data/analytics-queue");
                                setOpen(false);
                            }}
                        >
                            <p>SEE ALL</p>
                        </div>
                    </MenuTrayDropDownContainer>
                </ClickAwayListener>
            )}
        </AnalyticsQueueContainer>
    );
}
