import styled from "styled-components";

export default styled.div`
    .generateBtn {
        margin-top: 15px;
        margin-bottom: 15px;
        float: right;
    }

    .tableDiv {
        margin-top: 20px;
        clear: right;
    }

    .narrow {
        width: 75%;
    }

    .underline {
        border-bottom: 3px solid #0f4453;
        width: fit-content;
    }
`;
