import { getEnvironment } from 'utils/env.utils';

const FIRST_FEES_SAC_PERIOD = "2022M1";

export async function feesDashboardUrl(dashboard: string, setState: React.Dispatch<React.SetStateAction<string>>) {
    // import and return the fees related PowerBI dashboard urls based on the environment
    // when the promise resolved the "setState" function is called with the url as parameter
    // TODO: this should move to a global state manager once it is introduced
    const environment: string = getEnvironment();

    const feesDashBoardUrlsModule = await import(`assets/data/fees/dashboards/${environment}`);

    let url: string = feesDashBoardUrlsModule[dashboard];

    setState(url);
}

export const getNextSACPeriodMonth = (period: string): string => {
    let parts = period.split("M").map(Number);

    if (parts[1] == 12) {
        parts[1] = 1;
        parts[0]++;
    } else {
        parts[1] = parts[1] + 1;
    }

    return parts.join("M");
};

export const getLastSACPeriod = (): string => {
    const today = new Date();
    const monthNum = today.getMonth() + 1;
    return today.getFullYear() + "M" + monthNum;
};

export const getNextSACPeriodMonths = (): string[] => {
    let currentPeriod = getLastSACPeriod();

    let periods = [FIRST_FEES_SAC_PERIOD]

    let period = FIRST_FEES_SAC_PERIOD;

    while (true) {
        if (period === currentPeriod) break;

        let nextPeriod = getNextSACPeriodMonth(period);

        periods.push(nextPeriod);

        period = nextPeriod;
    }

    return periods;
};

export const getCurrentQuarterFromStartMonth = (month: string): string => {
    let parts = month.split("M").map(Number);

    let quarter: string = "";

    if (parts[1] == 4) {
        quarter = parts[0] + "Q2";
    } else if (parts[1] == 7) {
        quarter = parts[0] + "Q3";
    } else if (parts[1] == 10) {
        quarter = parts[0] + "Q4";
    } else if (parts[1] == 1) {
        quarter = parts[0] + "Q1";
    }

    return quarter;
}

export const getNextSACQuarterDetails = (endQuarterMonth: string) => {
    let parts = endQuarterMonth.split("M").map(Number);

    let quarter: string = "";
    let startQuarterMonth: string = "";

    if (parts[1] == 4) {
        quarter = parts[0] + "Q1";
        startQuarterMonth = parts[0] + "M1";
    } else if (parts[1] == 7) {
        quarter = parts[0] + "Q2";
        startQuarterMonth = parts[0] + "M4";
    } else if (parts[1] == 10) {
        quarter = parts[0] + "Q3";
        startQuarterMonth = parts[0] + "M7";
    } else if (parts[1] == 1) {
        parts[0] = parts[0] - 1; // previous year
        quarter = parts[0] + "Q4";
        startQuarterMonth = parts[0] + "M10";
    }

    return {quarter: quarter, startQuarterMonth: startQuarterMonth};
}

export const getSACPeriodQuarterOptions = (): Option[] => {
    const months = getNextSACPeriodMonths();

    let quarterOptions: Option[] = [];

    months.forEach((endQuarterMonth, index) => {
        if (index < 3) return; // skip the first 3 months (not enough months for a quarter)

        let {quarter, startQuarterMonth} = getNextSACQuarterDetails(endQuarterMonth);

        if (quarter) {
            let options: Option = {value: startQuarterMonth, label: quarter}
            quarterOptions.push(options);
        }
    });

    return quarterOptions;
};
