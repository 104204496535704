import styled from 'styled-components';

export default styled.div`

    .boxContainer {
        margin-top: 10px;
        min-width: 72vw;
    }

    .investmentThesis {
        width: 74vw;
        border-radius: 5px;
        padding: 5px;
        margin: 10px;
        border: 1px solid ${(props) => props.theme.homeListBodyBorderColor};

        .text {
            padding: 10px;
        }
    }

    .title {
        font-size: 14px;
        font-weight: bold;
        border-radius: 5px;
        padding: 5px;
        background-color: ${(props) => props.theme.homeListBodyBorderColor};
    }
`;