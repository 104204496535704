// Components
import Can from "components/Common/Can/Can";

// Styles
import DropDownMenuItemContainer from "./DropDownMenuItem.styles";

type Props = {
    text: string;
    icon: any;
    click: Function;
    permissions?: string[];
    className?: string;
};

export default function DropDownMenuItem({
    text,
    icon,
    click,
    permissions = [],
    className,
}: Props) {
    return (
        <Can
            yes={
                <DropDownMenuItemContainer
                    className={className}
                    onClick={() => click()}
                >
                    <div className="icon">{icon}</div>
                    <p data-cy="actions-label" className="label">
                        {text}
                    </p>
                </DropDownMenuItemContainer>
            }
            permissions={permissions}
        />
    );
}
