import styled from 'styled-components';

export default styled.div`
.comments-header {
    display: flex;
    align-items: center;
    padding: 6px;
    dispaly: inline;
    border-bottom: 1px solid ${(props) =>  props.theme.subMenuHighlight};
}

.comment {
    padding: 10px 0px;
    .comment-header {
        display: flex;
        align-items: center;
        color: ${(props) =>  props.theme.subMenuActive};
        & > div {
            padding-right: 10px;
        }
    }
    .comment-body {
        max-width: 700px;
        padding-top: 5px;
        margin-left: 20px
    }
}
`;
