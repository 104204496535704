import styled from 'styled-components';
import colors from "assets/styles/colors";

export default styled.div`
    .downloadIcon {
        cursor: pointer;

        svg {
            color: ${(props) => props.theme.tableIconHighlight};
            font-size: 20px;
        }
    }

    .generateBtn {
        margin-top: 15px;
        margin-bottom: 15px;
        float: right;
    }

    .tableDiv {
        clear: right;
    }

    .statusText {
        padding: 6px;
        text-transform: uppercase;
        font-weight: bold;
        border-radius: 7px;
        color: ${(props) => props.theme.subMenuBadgeTextSecondary};

        &.received {
            background-color: ${colors.accentBlue};
        }

        &.pending {
            background-color: ${colors.accentOrange};
        }
    }
`;