import styled from "styled-components";

export default styled.div`
    display: flex;
    flex-direction: row;

    user-select: none;

    &.mobile {
        position: fixed;
        z-index: 50;

        width: 100%;

        left: -310px;
        top: 0;
        bottom: 0;

        &.open {
            animation: openMenu 100ms 150ms ease-in-out both;
            pointer-events: auto;

            .menuBackground {
                position: fixed;

                top: 0;
                left: 0;
                right: 0;
                bottom: 0;

                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.5);

                animation: fadeBackgroundIn 150ms ease-in-out both;
            }
        }
        &.closed {
            animation: closeMenu 200ms ease-in-out both;
            pointer-events: none;
        }

        &.init {
            pointer-events: none;
        }
    }

    .mobileMenuBtn {
        display: none;
        justify-content: center;
        align-items: center;

        background-color: ${(props) => props.theme.mobileMenuButtonBackground};
        color: ${(props) => props.theme.mobileMenuButtonForeground};

        border-radius: 50%;

        position: fixed;
        bottom: 20px;
        right: 20px;

        height: 50px;
        width: 50px;

        font-size: 22px;
        pointer-events: auto;

        box-shadow: 0px 0px 20px 9px rgba(0, 0, 0, 0.1);
    }

    @media screen and (max-width: 600px) {
        .mobileMenuBtn {
            display: flex;
        }
    }

    @keyframes closeMenu {
        0% {
            left: 0;
        }
        100% {
            left: -310px;
        }
    }

    @keyframes openMenu {
        0% {
            left: -310px;
        }
        100% {
            left: 0;
        }
    }

    @keyframes fadeBackgroundIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
`;
