import React from "react";
import { useEffect, useReducer } from "react";
import queryString from "query-string";

//Context
import { useSFDRDataCapture } from "context/SFDRDataCapture.context";
import { useSettings } from "context/Settings.context";

//Components
import PageTitle from "components/Common/PageTitle/PageTitle";
import Select from "components/Common/Select/Select";
import Modal from "components/Common/Modal/Modal";
import TextArea from "components/Common/TextArea/TextArea";
import TextField from "components/Common/TextField/TextField";
import Button from "components/Common/Button/Button";
import RadioField from "components/Common/Radio/Radio";
import Can from "components/Common/Can/Can";

// MUI
import { Skeleton } from "@mui/material";

//utils
import { isObjectEmpty, toDateAndTimeSeconds } from "utils/general.utils";

//Types
import { IQuestion } from "components/Screens/SFDRDataCapture/SFDRDataCapture.types";

// Styles
import SFDRDataCaptureContainer from "./SFDRDataCapture.styles";

interface Props {}

export const SFDRDataCapture = ({}: Props) => {
    const queryStr = queryString.parse(window.location.search, {
        parseBooleans: true,
    });

    const [formState, setFormState] = useReducer(
        (prev: any, next: any) => {
            return { ...prev, ...next };
        },
        {
            selectedOption: "",
            childQuestion: [],
            showModal: false,
            isSustainableInvestment: false,
            canContinue: false,
            rerender: false,
        }
    );

    const {
        dataCaptureContext,
        setDataCaptureContext,
        createQuestionnaire,
        clearQuestionnaire,
        getCompaniesAndAnalystOptions,
        submitAnswers,
        updateAnswersObject,
        handleSignOff,
        deleteEntryFromAnswers,
        clearLatestFlag,
        fetchLatestAnswers
    } = useSFDRDataCapture();

    const {
        isLoading,
        analystList,
        selectedAnalyst,
        companyList,
        selectedCompany,
        answers,
        questionsWithOptions,
        allOptions,
        childQuestions,
        keyQuestionsWithOptions,
        urlQuery,
    } = dataCaptureContext;

    const { setSubMenuWidth } = useSettings();

    const handleModal = () => {
        setFormState({ showModal: true });
    };

    const handleClearQuestions = async () => {
        setFormState({
            selectedOption: "",
            childQuestion: [],
        });
        setFormState({ showModal: false, rerender: true });
        clearQuestionnaire();
        await clearLatestFlag();
    };

    const handleFormState = (value: string) => {
        setFormState({
            selectedOption: value,
        });
    };

    const handleSubmit =  async () => {
        await submitAnswers();
        const latestAnswers = await fetchLatestAnswers();

        setDataCaptureContext({
            answers: latestAnswers,
        });
    };

    const getChildQuestion = (option: any) => {
        if (!option) return;
        let selectedOption: any = null;
        if (["social", "environmental"].includes(option)) {
            selectedOption = allOptions.find(
                (option: any) =>
                    option.question_option.toLowerCase() ==
                    formState.selectedOption
            );

            const childQuestion = childQuestions.find(
                (question: any) =>
                    question.parent_option_id == selectedOption.id
            );

            const childQuestionToRemove = childQuestions.find(
                (question: any) =>
                    question.parent_option_id != selectedOption.id
            )?.options;

            childQuestionToRemove?.forEach((childQuestion: any) => {
                if (answers[childQuestion.question_id])
                    deleteEntryFromAnswers(childQuestion.question_id);
            });

            setFormState({ childQuestion: [childQuestion] });
        }
    };

    useEffect(() => {
        getChildQuestion(formState.selectedOption);
    }, [formState.selectedOption]);

    useEffect(() => {
        if (allOptions) {
            getChildQuestion(formState.selectedOption);
        }
        if (!isObjectEmpty(answers)) {
            const activeChildQuestion = Object.entries(answers)
                .map(([key, value]) => ({
                    answer: value,
                }))
                .find(
                    (answer: any) =>
                        answer.answer === "social" ||
                        answer.answer === "environmental"
                );
            if (activeChildQuestion) {
                setFormState({ selectedOption: activeChildQuestion.answer });
            }
        }
    }, [dataCaptureContext]);

    useEffect(() => {
        if (selectedCompany) createQuestionnaire();

        setDataCaptureContext({
            questionsWithOptions: [],
            answers: [],
            keyQuestionsWithOptions: [],
            answersToUpdate: {},
        });
    }, [selectedCompany, selectedAnalyst]);

    useEffect(() => {
        setFormState({
            canContinue:
                answers["d8f858c9-6ce3-4d58-bc04-9182e2a5c5c5"] == "Yes",
        });
    }, [answers]);

    useEffect(() => {
        setFormState({ rerender: false });
    }, [formState.rerender]);

    useEffect(() => {
        setSubMenuWidth(15);
        getCompaniesAndAnalystOptions();

        if (!isObjectEmpty(queryStr)) {
            setDataCaptureContext({
                selectedAnalyst: queryStr.user_id,
                selectedCompany: parseInt(queryStr.company_id),
                submissionId: queryStr.submission_id,
                urlQuery: queryStr,
            });
        }

        return () => {
            clearQuestionnaire();
            setFormState({ canContinue: false });
        };
    }, []);

    return (
        <SFDRDataCaptureContainer>
            {!formState.rerender ? (
                <div className="form">
                    <div className="form-title">
                        <PageTitle title={`SFDR DATA CAPTURE`} />
                    </div>
                    <div className="form-options">
                        <Can
                            yes={
                                <React.Fragment>
                                    {!isObjectEmpty(urlQuery) ? (
                                        <h1 className="submission-details">
                                            {`${
                                                analystList
                                                    ? analystList?.find(
                                                          (analyst: any) =>
                                                              analyst.id ==
                                                              selectedAnalyst
                                                      )?.displayName
                                                    : ""
                                            }`}
                                        </h1>
                                    ) : (
                                        <div className="neumorphic">
                                            {isLoading ? (
                                                <Skeleton
                                                    sx={{ bgcolor: "#C4D6D1" }}
                                                    width={200}
                                                    height={40}
                                                    animation="wave"
                                                    variant="rectangular"
                                                />
                                            ) : (
                                                <Select
                                                    showAll={false}
                                                    searchable
                                                    options={analystList.map(
                                                        (
                                                            analyst: AzureUser
                                                        ) => ({
                                                            label: analyst.displayName,
                                                            value: analyst.id,
                                                        })
                                                    )}
                                                    setValue={(value: string) =>
                                                        setDataCaptureContext({
                                                            selectedAnalyst:
                                                                value,
                                                        })
                                                    }
                                                    value={selectedAnalyst}
                                                    disabled={
                                                        isLoading ||
                                                        !isObjectEmpty(urlQuery)
                                                    }
                                                    filterName="Select Analyst"
                                                />
                                            )}
                                        </div>
                                    )}
                                </React.Fragment>
                            }
                            permissions={[
                                "p_can_submit_sfdr_esg_questionnaire_on_behalf",
                            ]}
                        />
                        {!isObjectEmpty(urlQuery) ? (
                            <h1 className="submission-details">
                                {`${
                                    companyList
                                        ? companyList?.find(
                                              (company: any) =>
                                                  company.company_id ==
                                                  selectedCompany
                                          )?.company
                                        : ""
                                }`}
                            </h1>
                        ) : (
                            <div className="neumorphic">
                                {isLoading ? (
                                    <Skeleton
                                        sx={{ bgcolor: "#C4D6D1" }}
                                        width={200}
                                        height={40}
                                        animation="wave"
                                        variant="rectangular"
                                    />
                                ) : (
                                    <Select
                                        showAll={false}
                                        options={companyList?.map(
                                            (company: Company) => ({
                                                label: company.company,
                                                value: company.company_id,
                                            })
                                        )}
                                        searchable
                                        setValue={(value: string) =>
                                            setDataCaptureContext({
                                                selectedCompany: value,
                                            })
                                        }
                                        value={selectedCompany}
                                        disabled={
                                            isLoading ||
                                            !isObjectEmpty(urlQuery)
                                        }
                                        filterName="Select Company"
                                    />
                                )}
                            </div>
                        )}
                        {!isObjectEmpty(urlQuery) && !isLoading && (
                            <h1 className="submission-details">
                                {`Submitted on: ${toDateAndTimeSeconds(
                                    urlQuery?.submitted_on
                                )}`}
                            </h1>
                        )}
                    </div>
                    {selectedCompany
                        ? keyQuestionsWithOptions.length > 0
                            ? keyQuestionsWithOptions.map(
                                  (question: IQuestion) => (
                                      <div
                                          key={question.order_number}
                                          className="panel question"
                                      >
                                          <div className="question-wrapper">
                                              <p className="question-text">
                                                  {question.display_order && `${question.display_order}) `}
                                                  {`${question.question}`}
                                                  <span className="required-indicator">
                                                      {question.is_required
                                                          ? "*"
                                                          : null}
                                                  </span>
                                              </p>
                                              {question.element_type ==
                                                  "radio" && (
                                                  <div
                                                      className={`panel question-field ${question.element_type}`}
                                                  >
                                                      <RadioField
                                                          textOption={false}
                                                          options={
                                                              question.options
                                                          }
                                                          handleChange={(
                                                              value: string
                                                          ) => {
                                                              updateAnswersObject(
                                                                  question.id,
                                                                  value
                                                              );
                                                          }}
                                                          value={
                                                              dataCaptureContext
                                                                  .answers[
                                                                  question.id
                                                              ]
                                                          }
                                                      />
                                                  </div>
                                              )}
                                              <div
                                                  className={`panel question-field text-area`}
                                              >
                                                  <TextArea
                                                      rows={4}
                                                      placeholder="Notes (Optional)"
                                                      label=""
                                                      value={
                                                          dataCaptureContext
                                                              .answers[
                                                              `notes-${question.id}`
                                                          ]
                                                      }
                                                      change={(
                                                          value: string
                                                      ) => {
                                                          updateAnswersObject(
                                                              `notes-${question.id}`,
                                                              value
                                                          );
                                                      }}
                                                  />
                                              </div>
                                          </div>
                                      </div>
                                  )
                              )
                            : [
                                  "10.5em",
                                  "4em",
                                  "10.5em",
                                  "4em",
                                  "10.5em",
                                  "4em",
                                  "10.5em",
                                  "4em",
                              ].map((num: string, index: number) => (
                                  <div
                                      key={`${num + index}`}
                                      className="panel skeleton"
                                  >
                                      <Skeleton
                                          height={num}
                                          sx={{ bgcolor: "#C4D6D1" }}
                                          animation="wave"
                                          variant="rectangular"
                                      />
                                  </div>
                              ))
                        : null}
                    {formState.canContinue &&
                    selectedCompany &&
                    keyQuestionsWithOptions
                        ? questionsWithOptions.length > 0
                            ? questionsWithOptions.map(
                                  (question: IQuestion) => (
                                      <div
                                          key={question.order_number}
                                          className="panel question"
                                      >
                                          <div className="question-wrapper">
                                              <p className="question-text">
                                                  {question.display_order && `${question.display_order}) `}
                                                  {`${question.question}`}
                                                  <span className="required-indicator">
                                                      {question.is_required
                                                          ? "*"
                                                          : null}
                                                  </span>
                                              </p>
                                              {question.element_type ==
                                                  "text-area" && (
                                                  <div
                                                      className={`panel question-field ${question.element_type}`}
                                                  >
                                                      <TextArea
                                                          rows={4}
                                                          placeholder="Your answer here"
                                                          label=""
                                                          value={
                                                              dataCaptureContext
                                                                  .answers[
                                                                  question.id
                                                              ]
                                                          }
                                                          change={(
                                                              value: string
                                                          ) => {
                                                              updateAnswersObject(
                                                                  question.id,
                                                                  value
                                                              );
                                                          }}
                                                      />
                                                  </div>
                                              )}
                                              {question.element_type ==
                                                  "text-field" && (
                                                  <div
                                                      className={`panel question-field ${question.element_type}`}
                                                  >
                                                      <TextField
                                                          placeholder="Your answer here"
                                                          label=""
                                                          value={
                                                              dataCaptureContext
                                                                  .answers[
                                                                  question.id
                                                              ]
                                                          }
                                                          change={(
                                                              value: string
                                                          ) => {
                                                              updateAnswersObject(
                                                                  question.id,
                                                                  value
                                                              );
                                                          }}
                                                      />
                                                  </div>
                                              )}
                                              {question.element_type ==
                                                  "select" && (
                                                  <div
                                                      className={`panel question-field ${question.element_type}`}
                                                  >
                                                      <Select
                                                          showAll={false}
                                                          options={question.options.map(
                                                              (
                                                                  option: any
                                                              ) => ({
                                                                  label: option.question_option,
                                                                  value: option.question_option?.toLowerCase(),
                                                              })
                                                          )}
                                                          setValue={(
                                                              value: string
                                                          ) => {
                                                              handleFormState(
                                                                  value
                                                              );
                                                              updateAnswersObject(
                                                                  question.id,
                                                                  value
                                                              );
                                                          }}
                                                          value={
                                                              dataCaptureContext
                                                                  .answers[
                                                                  question.id
                                                              ]
                                                          }
                                                          disabled={isLoading}
                                                          filterName="Select"
                                                      />
                                                  </div>
                                              )}

                                              {question.element_type ==
                                                  "number" && (
                                                  <>
                                                      <div
                                                          className={`panel question-field ${question.element_type}`}
                                                      >
                                                          <TextField
                                                              type="number"
                                                              placeholder="# Between 0 ~ 100"
                                                              label=""
                                                              value={
                                                                  dataCaptureContext
                                                                      .answers[
                                                                      question
                                                                          .id
                                                                  ]
                                                              }
                                                              change={(
                                                                  value: string
                                                              ) => {
                                                                  updateAnswersObject(
                                                                      question.id,
                                                                      value
                                                                  );
                                                              }}
                                                          />
                                                      </div>
                                                      <div
                                                          className={`panel question-field text-area`}
                                                      >
                                                          <TextArea
                                                              rows={4}
                                                              placeholder="Notes (Optional)"
                                                              label=""
                                                              value={
                                                                  dataCaptureContext
                                                                      .answers[
                                                                      `notes-${question.id}`
                                                                  ]
                                                              }
                                                              change={(
                                                                  value: string
                                                              ) => {
                                                                  updateAnswersObject(
                                                                      `notes-${question.id}`,
                                                                      value
                                                                  );
                                                              }}
                                                          />
                                                      </div>
                                                  </>
                                              )}
                                              {question.element_type ==
                                                  "radio" && (
                                                  <>
                                                      <div
                                                          className={`panel question-field ${question.element_type}`}
                                                      >
                                                          <RadioField
                                                              textOption={false}
                                                              options={
                                                                  question.options
                                                              }
                                                              handleChange={(
                                                                  value: string
                                                              ) => {
                                                                  updateAnswersObject(
                                                                      question.id,
                                                                      value
                                                                  );
                                                              }}
                                                              value={
                                                                  dataCaptureContext
                                                                      .answers[
                                                                      question
                                                                          .id
                                                                  ]
                                                              }
                                                          />
                                                      </div>
                                                      <div
                                                          className={`panel question-field text-area`}
                                                      >
                                                          <TextArea
                                                              rows={4}
                                                              placeholder="Notes (Optional)"
                                                              label=""
                                                              value={
                                                                  dataCaptureContext
                                                                      .answers[
                                                                      `notes-${question.id}`
                                                                  ]
                                                              }
                                                              change={(
                                                                  value: string
                                                              ) => {
                                                                  updateAnswersObject(
                                                                      `notes-${question.id}`,
                                                                      value
                                                                  );
                                                              }}
                                                          />
                                                      </div>
                                                  </>
                                              )}
                                              {question.element_type ==
                                                  "radio-with-text-field" && (
                                                  <div
                                                      className={`panel question-field ${question.element_type}`}
                                                  >
                                                      <RadioField
                                                          textOption
                                                          options={
                                                              question.options
                                                          }
                                                          handleChange={(
                                                              value: string
                                                          ) => {
                                                              updateAnswersObject(
                                                                  question.id,
                                                                  value
                                                              );
                                                          }}
                                                          value={
                                                              answers[
                                                                  question.id
                                                              ]
                                                          }
                                                      />
                                                  </div>
                                              )}
                                          </div>
                                          {["social", "environmental"].includes(
                                              answers[question.id]
                                          ) &&
                                              formState.childQuestion.map(
                                                  (question: any) => (
                                                      <div
                                                          key={question}
                                                          className="question-wrapper child"
                                                      >
                                                          <p className="question-text">
                                                              {question.display_order && `${question.display_order}) `}
                                                              {`${question.question}`}
                                                              <span className="required-indicator">
                                                                  {question.is_required
                                                                      ? "*"
                                                                      : null}
                                                              </span>
                                                          </p>
                                                          <div
                                                              className={`panel question-field ${question.element_type}`}
                                                          >
                                                              <Select
                                                                  showAll={
                                                                      false
                                                                  }
                                                                  options={question.options.map(
                                                                      (
                                                                          option: any
                                                                      ) => ({
                                                                          label: option.question_option,
                                                                          value: option.question_option?.toLowerCase(),
                                                                      })
                                                                  )}
                                                                  setValue={(
                                                                      value: string
                                                                  ) => {
                                                                      updateAnswersObject(
                                                                          question.id,
                                                                          value
                                                                      );
                                                                  }}
                                                                  value={
                                                                      answers[
                                                                          question
                                                                              .id
                                                                      ]
                                                                  }
                                                                  disabled={
                                                                      isLoading
                                                                  }
                                                                  filterName="Select"
                                                              />
                                                          </div>
                                                          <div
                                                              className={`panel question-field text-area`}
                                                          >
                                                              <TextArea
                                                                  rows={4}
                                                                  placeholder="Notes (Optional)"
                                                                  label=""
                                                                  value={
                                                                      dataCaptureContext
                                                                          .answers[
                                                                          `notes-${question.id}`
                                                                      ]
                                                                  }
                                                                  change={(
                                                                      value: string
                                                                  ) => {
                                                                      updateAnswersObject(
                                                                          `notes-${question.id}`,
                                                                          value
                                                                      );
                                                                  }}
                                                              />
                                                          </div>
                                                      </div>
                                                  )
                                              )}
                                      </div>
                                  )
                              )
                            : [
                                  "10.5em",
                                  "4em",
                                  "10.5em",
                                  "4em",
                                  "10.5em",
                                  "4em",
                                  "10.5em",
                                  "4em",
                              ].map((num: string) => (
                                  <div className="panel skeleton">
                                      <Skeleton
                                          height={num}
                                          sx={{ bgcolor: "#C4D6D1" }}
                                          animation="wave"
                                          variant="rectangular"
                                      />
                                  </div>
                              ))
                        : null}
                    {selectedCompany && (
                        <div className="submit">
                            <div className="">
                                <Button
                                    color="accent"
                                    text="Clear"
                                    click={handleModal}
                                />
                            </div>
                            <div className="">
                                <Button text="Save" click={handleSubmit} />
                            </div>
                            <Can
                                yes={
                                    <Button
                                        color="confirm"
                                        text="Sign off"
                                        click={handleSignOff}
                                    />
                                }
                                permissions={[
                                    "p_can_submit_sfdr_esg_questionnaire_on_behalf",
                                ]}
                            />
                        </div>
                    )}
                </div>
            ) : null}
            {formState.showModal && (
                <Modal>
                    <div className="modal-wrapper">
                        <p className="modal-title">Are you sure ?</p>
                        <p className="modal-subtitle">
                            Your answers to all the questions will be removed.
                        </p>
                        <div className="modal-buttons">
                            <Button
                                text="Remove Answers"
                                click={handleClearQuestions}
                            />
                            <Button
                                color="flat"
                                text="Cancel"
                                click={() => setFormState({ showModal: false })}
                            />
                        </div>
                    </div>
                </Modal>
            )}
        </SFDRDataCaptureContainer>
    );
};

export default SFDRDataCapture;
