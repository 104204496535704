import styled from "styled-components";

export default styled.div`
    flex-direction: column;
    display: flex;
    flex-grow: 1;

    padding-top: 20px;

    .output {
        white-space: break-spaces;
        word-break: break-all;
    }

    .notificationRead {
        opacity: 0.3
    }
`;
