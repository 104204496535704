export const gbl_config_table = [
    {
        text: "cfg_company_ref",
        type: "table",
        value: "cfg_company_ref",
        fields: [
            {
                field: "company_ref_id",
                type: "Integer",
                primaryKey: true,
                nullable: true,
            },
            {
                field: "company_id",
                type: "Integer",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "current_company_ref_flag",
                type: "SmallInteger",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "bbg_ticker",
                type: "String(50)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "company",
                type: "String(50)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "company_model_excel_name",
                type: "String(50)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "ref_start_date",
                type: "Date",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "ref_end_date",
                type: "Date",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "gim_team",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "live_company_flag",
                type: "SmallInteger",
                primaryKey: false,
                nullable: false,
            },
        ],
    },
    {
        text: "cfg_error_lookup",
        type: "table",
        value: "cfg_error_lookup",
        fields: [
            {
                field: "error_lookup_id",
                type: "Integer",
                primaryKey: true,
                nullable: false,
            },
            {
                field: "error_id",
                type: "Integer",
                primaryKey: true,
                nullable: false,
            },
            {
                field: "error_id_desc",
                type: "String(100)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "error_lookup_start_date",
                type: "Date",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "error_lookup_end_date",
                type: "Date",
                primaryKey: false,
                nullable: false,
            },
        ],
    },
    {
        text: "cfg_metric_dictionary",
        type: "table",
        value: "cfg_metric_dictionary",
        fields: [
            {
                field: "metric_dictionary_entry_id",
                type: "Integer",
                primaryKey: true,
                nullable: false,
            },
            {
                field: "current_metric_dict_flag",
                type: "SmallInteger",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "source",
                type: "String(30)",
                primaryKey: true,
                nullable: false,
            },
            {
                field: "metric_id",
                type: "String(51)",
                primaryKey: true,
                nullable: false,
            },
            {
                field: "ws",
                type: "String(10)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "metric",
                type: "String(40)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "include_metric",
                type: "SmallInteger",
                primaryKey: true,
                nullable: false,
            },
            {
                field: "metric_year_notation",
                type: "String(6))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "metric_name_raw",
                type: "String(200))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "metric_name_db",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "metric_root",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "metric_root_name_db",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "metric_data_type",
                type: "String(8))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "metric_unit",
                type: "String(20))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "example",
                type: "String(20))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "description",
                type: "String(2000))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "metric_calc_or_base",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "metric_formula",
                type: "String(30000))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "notes",
                type: "String(2000))",
                primaryKey: false,
                nullable: true,
            },
        ],
    },
    {
        text: "cfg_metric_dictionary_archive",
        type: "table",
        value: "cfg_metric_dictionary_archive",
        fields: [
            {
                field: "metric_dict_entry_id",
                type: "Integer",
                primaryKey: true,
                nullable: false,
            },
            {
                field: "current_metric_dict_flag",
                type: "SmallInteger",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "source",
                type: "String(30)",
                primaryKey: true,
                nullable: false,
            },
            {
                field: "metric_id",
                type: "String(51)",
                primaryKey: true,
                nullable: false,
            },
            {
                field: "ws",
                type: "String(10)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "metric",
                type: "String(40)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "include_metric",
                type: "SmallInteger",
                primaryKey: true,
                nullable: false,
            },
            {
                field: "metric_year_notation",
                type: "String(6))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "metric_name_raw",
                type: "String(200))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "metric_name_db",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "metric_root",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "metric_root_name_db",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "metric_data_type",
                type: "String(8))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "metric_unit",
                type: "String(20))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "example",
                type: "String(20))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "description",
                type: "String(2000))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "metric_calc_or_base",
                type: "String(50))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "metric_formula",
                type: "String(30000))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "notes",
                type: "String(2000))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "metric_dict_entry_start_date",
                type: "Date",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "metric_dict_entry_end_date",
                type: "Date",
                primaryKey: false,
                nullable: false,
            },
        ],
    },
    {
        text: "cfg_metric_year_diff_map",
        type: "table",
        value: "cfg_metric_year_diff_map",
        fields: [
            {
                field: "metric_year_notation",
                type: "String(6)",
                primaryKey: true,
                nullable: true,
            },
            {
                field: "metric_year_classification",
                type: "String(1)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "metric_year_diff",
                type: "Integer",
                primaryKey: false,
                nullable: false,
            },
        ],
    },
    {
        text: "cfg_validation_rules",
        type: "table",
        value: "cfg_validation_rules",
        fields: [
            {
                field: "validation_rule_id",
                type: "Integer",
                primaryKey: true,
                nullable: false,
            },
            {
                field: "current_validation_rule_flag",
                type: "SmallInteger",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "v_source",
                type: "String(30)",
                primaryKey: true,
                nullable: false,
            },
            {
                field: "v_metric_id",
                type: "String(51)",
                primaryKey: true,
                nullable: false,
            },
            {
                field: "v_model_tab",
                type: "String(10)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "v_metric",
                type: "String(40)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "v_data_type_assumed_raw",
                type: "String(8))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "v_data_type_assumed",
                type: "String(10)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "v_data_type_enforced",
                type: "String(10))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "v_data_type_enforced_errort",
                type: "String(8)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "v_can_be_blank_or_missing",
                type: "String(1))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "v_can_be_blank_or_missing_errort",
                type: "String(8))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "v_can_be_error",
                type: "String(1))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "v_can_be_error_errort",
                type: "String(8))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "v_min_value",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "v_min_value_errort",
                type: "String(8))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "v_max_value",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "v_max_value_errort",
                type: "String(8))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "v_model_delta_min_value",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "v_model_delta_min_value_errort",
                type: "String(8))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "v_model_delta_max_value",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "v_model_delta_max_value_errort",
                type: "String(8))",
                primaryKey: false,
                nullable: true,
            },
        ],
    },
    {
        text: "cfg_validation_rules_archive",
        type: "table",
        value: "cfg_validation_rules_archive",
        fields: [
            {
                field: "validation_rule_id",
                type: "Integer",
                primaryKey: true,
                nullable: false,
            },
            {
                field: "current_validation_rule_flag",
                type: "SmallInteger",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "v_source",
                type: "String(30)",
                primaryKey: true,
                nullable: false,
            },
            {
                field: "v_metric_id",
                type: "String(51)",
                primaryKey: true,
                nullable: false,
            },
            {
                field: "v_model_tab",
                type: "String(10)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "v_metric",
                type: "String(40)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "v_data_type_assumed_raw",
                type: "String(8))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "v_data_type_assumed",
                type: "String(10)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "v_data_type_enforced",
                type: "String(10)) ",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "v_data_type_enforced_errort",
                type: "String(8)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "v_can_be_blank_or_missing",
                type: "String(1))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "v_can_be_blank_or_missing_errort",
                type: "String(8))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "v_can_be_error",
                type: "String(1))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "v_can_be_error_errort",
                type: "String(8))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "v_min_value",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "v_min_value_errort",
                type: "String(8))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "v_max_value",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "v_max_value_errort",
                type: "String(8))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "v_model_delta_min_value",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "v_model_delta_min_value_errort",
                type: "String(8))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "v_model_delta_max_value",
                type: "Numeric(18",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "v_model_delta_max_value_errort",
                type: "String(8))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "rule_start_date",
                type: "Date",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "rule_end_date",
                type: "Date",
                primaryKey: false,
                nullable: false,
            },
        ],
    },
];
