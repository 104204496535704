import {
    faLineChart,
} from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import SubMenuItem from "../../SubMenuItem/SubMenuItem";
import SubMenuTitle from "../../SubMenuTitle/SubMenuTitle";
// Styles
import JustClimateContainer from "./JustClimate.styles";
interface JustClimateProps {}
const JustClimate = ({}: JustClimateProps) => {
    const history = useHistory();
    return (
        <JustClimateContainer className={"submenu"}>
            <div className={"top"}>
                <div>
                    <h1 style={{ width: "90%" }}>JUST CLIMATE</h1>
                </div>
                <hr />
                <SubMenuTitle
                    text={"Company Level"}
                    permissions={["p_can_view_just_climate"]}
                />
                <SubMenuItem
                    text={"Chronograph"}
                    active={
                        history.location.pathname ===
                        "/just-climate/company-chronograph" 
                    }
                    icon={faLineChart}
                    click={() =>
                        history.push(
                            "/just-climate/company-chronograph" 
                        )
                    }
                    permissions={["p_can_view_just_climate"]}
                />
            </div>
        </JustClimateContainer>
    );
};
export default JustClimate;