import * as Database from "utils/database.utils";

export const getIdentifiers = async () => {
    return await Database.get("api/notifications/identifiers");
};

export const setUserDefaultConfig = async (defaultConfig) => {
    return await Database.post("api/notifications/user_config", defaultConfig);
};

export const setUserDefaultCompanies = async (companies) => {
    return await Database.post("api/notifications/user_company", companies);
};

export const getUserConfig = async () => {
    const result = await Database.get("api/notifications/user_config");
    const data = await result.json();
    const sortedData = data.data.sort((a, b) => {
        const textA = a.identifier.toUpperCase();
        const textB = b.identifier.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    return sortedData;
};

export const getUserCompanies = async () => {
    const result = await Database.get(
        "api/notifications/user_company?page=1&pageSize=1000"
    );
    const data = await result.json();
    return data.data;
};

export const setDefaultCompanies = async (companies) => {
    const defaultCompanies = companies.map((company) => {
        return {
            company_ref_id: company.company_ref_id,
        };
    });
    const result = await setUserDefaultCompanies(defaultCompanies);
    if (result.ok) return result;
};

export const resetConfigAsDefault = async (configId) => {
    const result = await Database.remove(
        `api/notifications/user_config/${configId}`
    )
    return result
}

export const disableCompany = async (companyMapId) => {
    const result = await Database.remove(
        `api/notifications/user_company/${companyMapId}`
    );
    return result;
};

export const enableCompany = async (companyRefId) => {
    
    const result = await Database.post("api/notifications/user_company", [
        {
            company_ref_id: companyRefId,
        },
    ]);
    return result;
};

export const enableAllCompanies = async (companyRefIds) => {
    const result = await Database.post("api/notifications/user_company", companyRefIds);
    return result;
}

export const enableConfig = async (
    config,
    source,
    description,
    method,
    identifier
) => {
    const result =
        source === "user"
            ? await Database.put(`api/notifications/user_config/${config}`, {
                  enabled: true,
                  opt_out: false,
              })
            : await Database.post(`api/notifications/user_config`, [
                  {
                      description: description,
                      enabled: true,
                      identifier: identifier,
                      method: method,
                      opt_out: false,
                  },
              ]);
    return result;
};

export const disableConfig = async (
    config,
    source,
    description,
    method,
    identifier
) => {
    const result =
        source === "user"
            ? await Database.put(`api/notifications/user_config/${config}`, {
                  enabled: false,
                  opt_out: true,
              })
            : await Database.post(`api/notifications/user_config`, [
                {
                  description: description,
                  enabled: false,
                  identifier: identifier,
                  method: method,
                  opt_out: true,
                }
            ]);
    return result;
};

export const resetCompaniesToDefault = async () => {
    const result = await Database.post('api/notifications/user_config/reset_companies')
    if (result.ok) return result.ok
}

export const resetConfigToDefault = async () => {
    const result = await Database.post('api/notifications/user_config/reset_config')
    if (result.ok) return result.ok
}


export const createUniqueConfigs = (configSettings) => {
    const ids = configSettings.map((config) => config.data.identifier);
    const filteredConfigs = configSettings.filter(
        (config, index) => !ids.includes(config.data.identifier, index + 1)
    );
    return filteredConfigs;
};

export const parseBool = (string) => {
    return string === "true" ? true : false;
};
