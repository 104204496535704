import styled from "styled-components";

export default styled.div`
    align-items: center;
    cursor: pointer;

    background-color: ${(props) => props.theme.filterButtonBackground};
    color: ${(props) => props.theme.filterButtonForeground};
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    min-height: 40px;
    max-height: 40px;
    padding: 0 5px 0 15px;
    user-select: none;

    margin: 0 10px;

    p {
        letter-spacing: 1px;
    }

    .value {
        font-weight: bolder;
        margin-left: 15px;
        display: flex;
    }

    svg {
        margin: 0 5px 0 15px;
        font-size: 20px;
        color: #999999;
    }

    :hover {
        background-color: ${(props) => props.theme.filterButtonBackgroundHover};

        input {
            background-color: ${(props) =>
                props.theme.filterButtonBackgroundHover};
        }
    }

    &.active {
        background-color: ${(props) =>
            props.theme.filterButtonBackgroundActive};
        color: ${(props) => props.theme.filterButtonForegroundActive};

        svg {
            color: white;
        }
    }
`;
