// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBookOpen,
    faExclamationCircle,
    faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

// Material UI
import Tooltip from "@mui/material/Tooltip";

// Styles
import PortfolioLiveCountsContainer from "./PortfolioLiveCounts.styles";

type Props = {
    portfolio?: number;
    plErrors: number;
    plSortNewErrors: number;
};

export default function PortfolioLiveCounts({
    portfolio,
    plErrors,
    plSortNewErrors,
}: Props) {
    return (
        <PortfolioLiveCountsContainer className="warningCounts">
            {portfolio && (
                <Tooltip title={"PORTFOLIO COMPANIES"}>
                    <div className="count orange">
                        <FontAwesomeIcon icon={faBookOpen} fixedWidth />
                        <p>{portfolio}</p>
                    </div>
                </Tooltip>
            )}

            {plErrors > 0 && (
                <Tooltip title={"PL ERRORS"}>
                    <div className="count red">
                        <FontAwesomeIcon icon={faTimesCircle} fixedWidth />
                        <p>{plErrors}</p>
                    </div>
                </Tooltip>
            )}

            {plSortNewErrors > 0 && (
                <Tooltip title={"PL SORT NEW ERRORS"}>
                    <div className="count red">
                        <FontAwesomeIcon
                            icon={faExclamationCircle}
                            fixedWidth
                        />
                        <p>{plSortNewErrors}</p>
                    </div>
                </Tooltip>
            )}
        </PortfolioLiveCountsContainer>
    );
}
