// Styles
import GlobalSearchBoxContainer from "./GlobalSearchBox.styles";
import { useSettings } from "../../../context/Settings.context";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";

export default function GlobalSearchBox() {
    const { showGlobalSearchBox, setShowGlobalSearchBox } = useSettings();

    const [searchString, setSearchString] = useState("");

    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setSearchString("");

        if (inputRef.current !== null) {
            inputRef.current.focus();
        }
    }, [showGlobalSearchBox]);

    return (
        showGlobalSearchBox && (
            <GlobalSearchBoxContainer>
                <div className={"searchModal"}>
                    <div className={"searchBox"}>
                        <input
                            ref={inputRef}
                            placeholder={"SEARCH..."}
                            value={searchString}
                            onChange={(e) => setSearchString(e.target.value)}
                        />
                        <FontAwesomeIcon icon={faSearch} fixedWidth />
                    </div>
                    <div className={"results"}></div>

                    <div
                        className={"closeBtn"}
                        onClick={() => setShowGlobalSearchBox(false)}
                    >
                        <FontAwesomeIcon icon={faTimes} fixedWidth />
                    </div>
                </div>
            </GlobalSearchBoxContainer>
        )
    );
}
