import styled from "styled-components";
import colors from "assets/styles/colors";

export const OrderByButtonContainer = styled.div`
    padding: 8px 15px;

    min-width: fit-content;

    background-color: ${(props) => props.theme.filterButtonBackground};
    color: ${(props) => props.theme.filterButtonForeground};

    border-radius: 5px;
    align-items: center;
    display: flex;
    margin: 0 10px;

    :hover {
        background-color: ${(props) => props.theme.filterButtonBackgroundHover};
    }

    user-select: none;

    max-height: 40px;
    min-height: 40px;

    cursor: pointer;

    .label {
        margin-right: 10px;
    }

    p {
        letter-spacing: 1px;
    }

    .value {
        font-weight: bolder;
        margin-left: 15px;
        display: flex;
    }

    .selected {
        font-weight: bolder;
        display: flex;
        margin-right: 5px;
    }

    .clearFilter {
        margin-left: 10px;
        display: flex;
    }
`;

export const PopUpContainer = styled.div`
    display: flex;
    flex-direction: column;

    margin-top: 5px;
    max-width: 250px;

    box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.28);
    background-color: ${(props) => props.theme.dropDownMenuBackground};
    color: ${(props) => props.theme.dropDownMenuText};

    .searchBox {
        display: flex;
        align-items: center;

        border-bottom: 1px solid
            ${(props) => props.theme.dropDownSearchBoxBorder};
        background-color: ${(props) => props.theme.dropDownMenuBackground};
        color: ${(props) => props.theme.dropDownMenuText};

        max-width: 250px;
        min-width: 250px;
        margin: 0 !important;

        input {
            border: none;
            height: 40px;
            font-size: 18px;
            background-color: ${(props) => props.theme.dropDownMenuBackground};
            color: ${(props) => props.theme.dropDownMenuText};

            padding-left: 15px;
            outline: none;
            display: flex;
            flex-grow: 1;
        }

        svg {
            margin: 0 10px;
            color: ${(props) => props.theme.dropDownSearchBoxIcon};
        }

        :hover {
            background-color: ${(props) => props.theme.dropDownBackgroundHover};

            input {
                background-color: ${(props) =>
                    props.theme.dropDownBackgroundHover};
            }
        }
    }

    .options {
        flex-direction: column;
        display: flex;
        max-height: 400px;
        overflow-y: scroll;

        .option {
            min-width: 150px;
            cursor: pointer;

            :hover {
                background-color: ${(props) =>
                    props.theme.dropDownBackgroundHover};
            }
            p {
                margin: 10px 15px;
            }
        }

        .noResults {
            min-width: 150px;
            width: 100%;

            p {
                margin: 10px 15px;
                width: 100%;
                text-align: center;
                color: ${(props) => props.theme.dropDownMenuText};
            }
        }
    }

    .date {
        div {
            display: block;
        }
        .react-datepicker__day-name,
        .react-datepicker__day {
            display: inline-block;
        }
    }
`;
