// Styles
import PortfolioLiveContainer from "./PortfolioLive.styles";

type Props = {};

export default function PortfolioLive({}: Props) {
    return (
        <PortfolioLiveContainer>
            <div className="iframeContainer">
                <iframe
                    src="https://app.powerbi.com/reportEmbed?reportId=e802e0b1-0278-466f-8d50-21987b53dc5f&autoAuth=true&ctid=bf4450a2-bfcb-472d-8c40-1425eafb1a3b&filterPaneEnabled=false"
                    title="Portfolio Snapshot vLive (QS)"
                    width="100%"
                    height="100%"
                    allowFullScreen
                    frameBorder={0}
                />
            </div>
        </PortfolioLiveContainer>
    );
}
