import styled from "styled-components";
import colors from "assets/styles/colors";

export default styled.div`
    margin: 10px -10px;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    z-index: 10;

    width: calc(100% + 20px);

    .searchFilter {
        max-height: 40px;
        min-height: 40px;
        display: flex;
        margin: 10px 0;

        /* NEUMORPHISM STYLE*/
        /* .switch {
            background: linear-gradient(145deg, #e6e6e6, #ffffff);
            box-shadow: 4px 4px 12px #c5c5c5, -4px -4px 12px #ffffff;
        } */
    }

    .button {
        max-height: 40px;
        min-height: 40px;
        display: flex;
        margin: 10px;
    }

    .showAll {
        padding: 5px 15px;

        max-height: 40px;
        min-height: 40px;

        margin: 10px;

        background-color: ${(props) =>
            props.theme.searchOptionsShowAllBackground};
        color: ${(props) => props.theme.searchOptionsShowAllText};

        border-radius: 5px;
        align-items: center;
        display: flex;
        user-select: none;

        margin-right: 10px;

        cursor: pointer;

        p {
            margin: 0;
            letter-spacing: 1px;
        }

        &.true {
            background-color: ${(props) => props.theme.filterButtonBackground};
            color: ${(props) => props.theme.filterButtonForeground};

            :hover {
                background-color: ${(props) =>
                    props.theme.filterButtonBackgroundHover};
            }
        }
    }

    .searchDiv {
        flex-grow: 1;
    }

    .searchBox {
        align-items: center;
        cursor: pointer;

        background-color: ${(props) => props.theme.filterButtonBackground};
        color: ${(props) => props.theme.filterButtonForeground};

        border-radius: 5px;
        overflow: hidden;
        display: flex;
        flex-grow: 1;
        min-height: 40px;
        max-height: 40px;
        margin: 0 10px;

        input {
            border: none;
            min-height: 40px;
            max-height: 40px;
            font-size: 18px;
            padding-left: 15px;
            outline: none;
            display: flex;
            flex-grow: 1;
            background-color: ${(props) => props.theme.filterButtonBackground};
            color: ${(props) => props.theme.filterButtonForeground};

            ::placeholder {
                color: ${(props) => props.theme.filterButtonForeground};
            }
        }

        svg {
            margin: 0 10px;
            color: ${(props) => props.theme.filterButtonForeground};
        }

        :hover {
            background-color: ${(props) =>
                props.theme.filterButtonBackgroundHover};

            input {
                background-color: ${(props) =>
                    props.theme.filterButtonBackgroundHover};
            }
        }

        &.error {
            border: 2px solid lightcoral;
        }
    }
`;
