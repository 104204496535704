// Styles
import IframeContainer from "../IframeContainer.styles";

type Props = {};

export default function PublicCompanies({}: Props) {
    return (
        <IframeContainer>
            <div className="iframeContainer">
                <iframe
                    src="https://app.powerbi.com/reportEmbed?reportId=aae307bb-036b-4da8-854e-e2b0877d4315&autoAuth=true&ctid=bf4450a2-bfcb-472d-8c40-1425eafb1a3b&filterPaneEnabled=false"
                    title="Fund Public Companies vLive (QS)"
                    width="100%"
                    height="100%"
                    allowFullScreen
                    frameBorder={0}
                />
            </div>
        </IframeContainer>
    );
}
