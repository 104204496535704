export default {
    adaHomeBackground: "#e8e8e8",
    adaHomeDividerShadow:
        "inset 1px 1px 3px #a7a7a7, inset -1px -1px 3px #ffffff;",
    adaHomeDividerBackground: "#f5f5f5",
    cardBorder: "1px solid #e8e8e8",
    cardBackground: "linear-gradient(145deg, #e6e6e6, #ffffff)",
    cardBoxShadow: "2px 2px 6px #c5c5c5, -2px -2px 6px #ffffff",
    cardBoxShadowHover: "2px 2px 8px #c5c5c5, -2px -2px 8px #ffffff",
};
