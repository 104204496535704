// React
import { useEffect, useState } from "react";

// Libraries
import queryString from "query-string";

// Components
import Table from "components/Common/Table/Table";
import SkeletonLoader from "components/Common/SkeletonLoader/SkeletonLoader";

// Utils
import * as Database from "utils/database.utils";
import { toDateAndTimeSeconds } from "utils/general.utils";

// Styles
import NotificationsListContainer from "./NotificationsList.styles";

export interface Notification {
    app_notification_id: string;
    id: string;
    task_completed: true;
    task_completed_by: string;
    task_completed_on: string;
    read: boolean;
    message: string;
    detail_message: string;
    status: string;
    severity: string;
    created_on: string;
}

export default function NotificationsList() {
    const [loading, setLoading] = useState<boolean>(true);
    const [notifications, setNotifications] = useState<Notification[] | null>(
        null
    );
    const [notificationDataCell, setNotificationDataCell] = useState<
        any | null
    >(null);
    const [filterQuery, setFilterQuery] = useState("");
    const notificationID = queryString.parse(
        window.location.search
    ).notificationId;

    const getNotications = async () => {
        setLoading(true);
        const notificationsRes = await Database.get(
            "api/notifications/web?page=1&pageSize=1000"
        );
        const notificationsData = await notificationsRes.json();
        const sortedNotifications = notificationsData.data.sort(
            (a: any, b: any) =>
                Date.parse(b.created_on) - Date.parse(a.created_on)
        );
        setNotifications(sortedNotifications);
        setLoading(false);
    };

    const createNotificationDataCell = () => {
        const selectedNotifications = notifications?.filter(
            (notification: any) =>
                notificationID
                    ? notification.app_notification_id === notificationID
                    : notification
        );
        setNotificationDataCell(selectedNotifications);
    };

    // Mark notification as unread
    // const markNotificationAsUnread = async (appNotificationId: string) => {
    //     await Database.post(
    //         `api/notifications/mark_unread/${appNotificationId}`
    //     );
    //    getNotications()
    // };

    const markSelectedNotificationRead = async (notificationID: string) => {
        await Database.post(`api/notifications/mark_read/${notificationID}`);
    };

    const markAllNotificationsRead = async () => {
        if (notifications) {
            for (let index in notifications) {
                if (notifications[index].read === false) {
                    await Database.post(
                        `api/notifications/mark_read/${notifications[index].app_notification_id}`
                    );
                }
            }
        }
    };

    useEffect(() => {
        createNotificationDataCell();
        notificationID
            ? markSelectedNotificationRead(notificationID)
            : markAllNotificationsRead();
    }, [notifications]);

    useEffect(() => {
        getNotications();
    }, []);

    const columns = [
        {
            id: 1,
            title: "MESSAGE",
            sortable: false,
            value: "data",
            cell: (data: Notification) => (
                <p className={data.read ? "notificationRead" : ""}>
                    {data.message}
                </p>
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 2,
            title: "DETAILED",
            sortable: true,
            value: "data",
            cell: (data: Notification) => (
                <p
                    className={data.read ? "notificationRead" : ""}
                    dangerouslySetInnerHTML={{ __html: data.detail_message }}
                ></p>
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 3,
            title: "STATUS",
            sortable: true,
            value: "data",
            cell: (data: Notification) => (
                <p className={data.read ? "notificationRead" : ""}>
                    {data.status}
                </p>
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 4,
            title: "SEVERITY",
            sortable: true,
            value: "data",
            cell: (data: Notification) => (
                <p className={data.read ? "notificationRead" : ""}>
                    {data.severity}
                </p>
            ),
            show: true,
            fullDataRow: true,
        },
        // {
        //     id: 5,
        //     title: "TASK COMPLETED",
        //     sortable: true,
        //     value: "task_completed",
        //     cell:(data: boolean) => <CheckCell selected={data} />,
        //     show:true
        // },
        {
            id: 6,
            title: "DATE",
            sortable: true,
            value: "data",
            cell: (data: any) => (
                <p className={data.read ? "notificationRead" : ""}>
                    {toDateAndTimeSeconds(data.created_on)}
                </p>
            ),
            show: true,
            fullDataRow: true,
        },
        // {
        //     id: 7,
        //     title: "",
        //     sortable: false,
        //     value: "data",
        //     cell: (data: any) => (
        //         <div
        //             style={{
        //                 display: "flex",
        //                 alignItems: "center",
        //                 justifyContent: "flex-start",
        //             }}
        //         >
        //             {data.read && (
        //                 <Actions
        //                     markAsUnread={() =>
        //                         markNotificationAsUnread(
        //                             data.app_notification_id
        //                         )
        //                     }
        //                 />
        //             )}
        //         </div>
        //     ),
        //     show: true,
        // },
    ];

    return (
        <NotificationsListContainer>
            {notificationDataCell ? (
                <Table
                    data={notificationDataCell}
                    columns={columns}
                    filterQuery={filterQuery}
                    defaultSortKey="created_on"
                    defaultSortOrder="ASC"
                    pagination
                    emptyText="NO NOTIFICATIONS"
                    loading={loading}
                    large
                />
            ) : (
                <SkeletonLoader type="table" rows={18} />
            )}
        </NotificationsListContainer>
    );
}
