import { useHistory } from "react-router-dom";

// Utils
import { applyTimeZoneOffset } from "utils/common.utils";

// MaterialUI
import { Tooltip } from "@mui/material";

// FontAwesome
import { faChair, faUser, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Styles
import { teamLabelDict } from "../../../../../../assets/data/lists";
import PendingMyVoteRowContainer from "./PendingMyVoteRow.styles";

type Props = {
    pendingSessionData: BQMQSession;
};

export default function PendingMyVoteRow({ pendingSessionData }: Props) {
    const history = useHistory();

    const team = //@ts-ignore
        teamLabelDict[pendingSessionData?.company?.sector?.name.toUpperCase()];

    const sessionDate = applyTimeZoneOffset(pendingSessionData.date);
    return (
        <PendingMyVoteRowContainer>
            <div
                onClick={() =>
                    history.push(
                        `/voting/vote?company_name=${pendingSessionData.company.name}&session_id=${pendingSessionData.id}&editing=false&date=${pendingSessionData.date}`
                    )
                }
                className={"info"}
            >
                <div data-cy="pending-company" className={"name"}>
                    {pendingSessionData.company.name.toUpperCase()}
                </div>

                <div className={"details"}>
                    {pendingSessionData.presenter.name && (
                        <Tooltip title={<h2>ANALYST</h2>}>
                            <div className={"detail"}>
                                <FontAwesomeIcon icon={faUser} fixedWidth />
                                <p>{pendingSessionData.presenter.name}</p>
                            </div>
                        </Tooltip>
                    )}

                    {pendingSessionData.host.name && (
                        <Tooltip title={<h2>CHAIR</h2>}>
                            <div className={"detail"}>
                                <FontAwesomeIcon icon={faChair} fixedWidth />
                                <p>{pendingSessionData.host.name}</p>
                            </div>
                        </Tooltip>
                    )}

                    {pendingSessionData?.company?.sector?.name && (
                        <Tooltip title={<h2>TEAM</h2>}>
                            <div className={"detail"}>
                                <FontAwesomeIcon icon={faUsers} fixedWidth />
                                <p className={"capitalize"}>{team}</p>
                            </div>
                        </Tooltip>
                    )}
                </div>
            </div>
            <Tooltip title={<h2>MEETING DATE: {sessionDate}</h2>}>
                <div className={"date"}>{sessionDate}</div>
            </Tooltip>
        </PendingMyVoteRowContainer>
    );
}
