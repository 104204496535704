import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// Material UI
import Tooltip from "@mui/material/Tooltip";

// Components
import Can from "components/Common/Can/Can";

// Styles
import MenuItemContainer from "./MenuItem.styles";

//Utils
import { formatstringforTests } from "utils/common.utils";

type Props = {
    id?: string;
    click: Function;
    text: string;
    icon: React.ReactNode;
    permissions: string[];
    isActive?: Boolean;
    hideTooltip?: boolean;
    development?: boolean;
};

export default function MenuItem({
    id,
    click,
    text,
    icon,
    permissions,
    isActive,
    hideTooltip,
    development,
}: Props) {
    const [showToolTip, setShowToolTip] = useState<boolean>(false);

    const isDevelopment = development
        ? process.env.REACT_APP_GIM_ENVIRONMENT === "development"
        : false;

    return (
        <>
            {isDevelopment || !development ? (
                <Can
                    yes={
                        <MenuItemContainer
                            id={id}
                            onClick={(e) => click(e)}
                            className={
                                isActive ? "menuItem active" : "menuItem "
                            }
                        >
                            {/* <Tooltip
                                open={showToolTip && !hideTooltip}
                                title={text}
                                placement="right"
                            > */}
                            <div
                                data-cy={`menu-nav-icon-${formatstringforTests(
                                    text
                                )}`}
                                className="iconContainer"
                                onMouseEnter={() => setShowToolTip(true)}
                                onMouseLeave={() => setShowToolTip(false)}
                            >
                                {icon}
                                <p className="menuItemText">{text}</p>
                            </div>
                            {/* </Tooltip> */}
                        </MenuItemContainer>
                    }
                    permissions={permissions}
                />
            ) : null}
        </>
    );
}
