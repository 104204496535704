import styled from "styled-components";

export default styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 8px 0 8px 0;
    cursor: pointer;

    img {
        width: 65px;
        height: 65px;
    }
`;
