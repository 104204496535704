// Components
import ExtractionOptions from "components/Common/SelectedOptions/ExtractionOptions/ExtractionOptions";

// Styles
import SelectedOptionsContainer from "./SelectedOptions.styles";

type Props = {
    selectedCount: number;
    type?: string;
    data: any[];
    setData: Function;
    reload?: Function;
};

export default function SelectedOptions({
    selectedCount,
    type,
    data,
    setData,
    reload,
}: Props): JSX.Element {
    return (
        <SelectedOptionsContainer>
            <p>{selectedCount} rows selected</p>

            {type === "EXTRACTION" && (
                <ExtractionOptions
                    data={data}
                    setData={setData}
                    reload={reload!}
                />
            )}
        </SelectedOptionsContainer>
    );
}
