import React, { useEffect, useState } from "react";

// Styles
import EditBooleanAnswerContainer from "./EditBooleanAnswer.styles";

// Types
import {GrowthAnswer} from "assets/interfaces/growth_voting";

// Components


interface Porps {
    answer: GrowthAnswer;
    addToUpdatedAnsweres: (updated: GrowthAnswer) => void;
    open: boolean|undefined;
}


const EditBooleanAnswer  = ({answer, addToUpdatedAnsweres, open}: Porps) => {
    const [value, setValue] = useState<boolean| undefined>();

    const handleChange = (value: boolean|undefined) => {
        let updated: GrowthAnswer = {
            ...answer,
            answer_bool: (value) ? 1 : 0
        }

        addToUpdatedAnsweres(updated)
    }

    useEffect(() => {
        if (!open) return;

        if (answer.answer_bool === (value ? 1 : 0)) return;

        if (value !== undefined) {
            handleChange(value)
        }
    }, [value])

    useEffect(() => {
        if (answer.answer_bool === undefined) return;

        setValue((answer.answer_bool === 1) ? true : false)
    }, [])

    return (
        <EditBooleanAnswerContainer>
            <div className={"choices " + (!open ? 'closed' : '')}>
                <div onClick={() => setValue(true)} className={"choice " + (value === true ? 'selected' : '')}>
                    <span>Yes</span>
                </div>
                <div onClick={() => setValue(false)} className={"choice " + (value === false ? 'selected' : '')}>
                    <span>No</span>
                </div>
            </div>
        </EditBooleanAnswerContainer>
    );
};

export default EditBooleanAnswer
