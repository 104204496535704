import styled from "styled-components";
import colors from "assets/styles/colors";

export default styled.div`
    display: flex;
    flex-direction: row;
    padding: 5px 0;

    .type {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 50px;
        max-width: 50px;
        height: 50px;

        .circle {
            border-radius: 50%;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
                color: white;
            }

            &.high {
                background-color: ${colors.accentRed};
            }
            &.medium {
                background-color: ${colors.accentOrange};
            }
            &.low {
                background-color: ${colors.accentBlue};
                /* svg {
                    animation: spin 2s linear infinite;
                } */
            }
            &.green {
                background-color: ${colors.accentGreen};
            }
            &.grey {
                background-color: #777777;
            }
        }
    }

    .info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        white-space: nowrap;
        overflow-x: hidden;

        color: ${(props) => props.theme.notificationsRowText};

        .description {
            letter-spacing: 0.05em;
            font-weight: bold;

            &.high {
                color: ${colors.accentRed};
            }
            &.medium {
                color: ${colors.accentOrange};
            }
            &.low {
                color: ${colors.accentBlue};
                /* svg {
                    animation: spin 2s linear infinite;
                } */
            }
        }

        .date {
            font-size: 14px;
        }
    }

    :hover {
        background-color: ${(props) =>
            props.theme.notificationsRowBackgroundHover};
    }
`;
