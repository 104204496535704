import styled from "styled-components";

export default styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    user-select: none;

    .modeCell {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 8px;

        .label {
            display: flex;
            font-size: 14px;
        }
    }
`;
