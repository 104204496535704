import styled from "styled-components";
import colors from "assets/styles/colors";

export default styled.div`
    width: 100%;

    img {
        max-width: 100%;
    }
`;
