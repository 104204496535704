// Styles
import AlertContainer from "./Alert.styles";

type Props = {
    children: React.ReactNode;
};

export default function Alert({ children }: Props) {
    return (
        <AlertContainer>
            <div className="modalBox">{children}</div>
        </AlertContainer>
    );
}
