// Calendar
import moment from "moment";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";

// Styles
import { useEffect, useState } from "react";
import Modal from "../../../../Common/Modal/Modal";
import SelectDateContainer from "./SelectDate.styles";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { TextField } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Button from "../../../../Common/Button/Button";

// Database
import LoadingSpinner from "components/Common/LoadingSpinner/LoadingSpinner";

type Props = {
    date: Date;
    setDate: (date: Date) => void;
    company: any | null;
    scheduledEvents: ScheduledEvent[];
    eventArray: any[];
    setEventArray: Function;
};

export default function SelectDate({
    date,
    setDate,
    company,
    scheduledEvents,
    eventArray,
    setEventArray,
}: Props) {
    moment.locale("ko", {
        week: {
            dow: 1,
            doy: 1,
        },
    });
    const localizer = momentLocalizer(moment);
    const [loading, setLoading] = useState<boolean>(false);
    const [time, setTime] = useState(new Date());
    const [showTimeSelect, setShowTimeSelect] = useState(false);
    const handleChooseDate = (newDate: any) => {
        setEventArray([
            {
                title: `-> ${moment(newDate).format("h:mm")}: ${
                    company ? company.label : "NEW SESSION"
                }`,
                start: newDate.start,
                end: newDate.end,
                allDay: true,
            },
        ]);
        setShowTimeSelect(true);
    };

    const saveTime = () => {
        let finishedTime = new Date(eventArray[0].start);
        finishedTime.setHours(time?.getHours());
        finishedTime.setMinutes(time?.getMinutes());

        setDate(finishedTime);
        let newEvent = eventArray[0];

        newEvent.title = `> ${moment(finishedTime).format("HH:mm")}: ${
            company ? company.label || company.name : "NEW SESSION"
        }`;

        setEventArray([...scheduledEvents, newEvent]);
        setShowTimeSelect(false);
    };

    const changeTime = (date: any) => {
        let updatedDate = new Date(time);

        updatedDate.setHours(date?.getHours());
        updatedDate.setMinutes(date?.getMinutes());

        setTime(date);
    };

    useEffect(() => {
        setTime(new Date(time.setHours(10, 0, 0, 0)));
    }, []);

    const highlightCurrentDate = () => {
        let datesElements: HTMLCollectionOf<Element> =
            document.getElementsByClassName("rbc-event");

        // @ts-ignore
        for (const element of datesElements) {
            if (element.innerText.includes(">")) {
                element.classList.add("currentSession");
            } else {
                element.classList.remove("currentSession");
            }
        }
    };

    useEffect(() => {
        highlightCurrentDate();

        setTimeout(() => {
            highlightCurrentDate();
        }, 100);
    }, [eventArray]);

    return (
        <SelectDateContainer>
            <div className={"heading"}>
                <p>SELECT MEETING DATE</p>
            </div>
            {loading ? (
                <div className="loader">
                    <LoadingSpinner />
                </div>
            ) : (
                <div className={"calendar"}>
                    <Calendar
                        localizer={localizer}
                        events={eventArray}
                        views={["month"]}
                        style={{ height: 600 }}
                        selectable
                        onSelectSlot={(e) => handleChooseDate(e)}
                    />
                </div>
            )}

            {showTimeSelect && (
                <Modal>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <div className={"heading"}>
                            <p>SELECT TIME</p>
                        </div>

                        <div className="time-picker-wrapper">
                            <TimePicker
                                disableOpenPicker={true}
                                ampm={false}
                                value={time}
                                minutesStep={5}
                                onChange={(newValue: any) => {
                                    changeTime(newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} />
                                )}
                            />
                        </div>
                        <Button text={"SAVE"} click={saveTime} />
                    </LocalizationProvider>
                </Modal>
            )}
        </SelectDateContainer>
    );
}
