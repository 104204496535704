import React, { useRef } from 'react';
import DOMPurify from 'dompurify';

// Components
import RichTextEditor from "components/Common/RichTextEditor/RichTextEditor";

// Styles
import RichTextContainer from "./RichText.styles";  

type Props = {
    value?: string // original value of the field
    setText?: React.Dispatch<React.SetStateAction<string>> | null
    edit?: boolean // if true edit the text
};

export default function RichText({ setText = null, value = "", edit = false }: Props) {
    const sanitizedValue = DOMPurify.sanitize(value);

    return (
        <RichTextContainer>
            <div className={"rich-text"}>
                {!edit && <div dangerouslySetInnerHTML={{ __html: sanitizedValue }} />}
                {edit && setText && <RichTextEditor setText={setText} value={value} />}
            </div>
        </RichTextContainer>
    );
}
