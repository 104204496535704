import styled from "styled-components";

export default styled.div`
.company {
    border: 1px solid ${(props) => props.theme.votingCellBorder};
    border-radius: 5px;
    margin-bottom: 25px;
    padding: 10px;

    .company-details {
        display: flex;
        align-items: center;
        padding-bottom: 5px;
        .name {
            background-color: ${(props) => props.theme.secondaryButtonBackground};
            color: ${(props) => props.theme.secondaryButtonColor};
            font-weight: bold;
            font-size: 1.05rem;
            padding: 4px 8px;
            border-radius: 5px;
            margin-right: 5px;
        }
        .url {
            flex: 1;
            padding: 3px 5px;
            margin-right: 5px;
            border-radius: 5px;
            color: ${(props) => props.theme.labelSecondaryBg};
        }

        .edit {
            margin-left: 5px;
        }
    }
    .deal {
        border-top: 1px solid ${(props) => props.theme.votingCellBorder};
        padding: 10px 0px;
        margin-left: 30px;
        display: flex;
        align-items: center;
        .vote-types {
            margin-left: 15px;
            font-weight: normal;
            display: flex;
            padding-top: 2px;

        }
        .link {
            flex: 1;
            display: flex;
            justify-content: flex-end;
        }
    }
    .created {
        color: ${(props) => props.theme.subMenuHighlight};
    }
}

.link {
    text-decoration: none;
}
`;
