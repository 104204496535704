import styled from "styled-components";

export default styled.div`
    .output {
        white-space: break-spaces;
        word-break: break-all;
    }

    .capitalize {
        text-transform: capitalize;
    }
`;
