import styled from 'styled-components';
import colors from "assets/styles/colors";

export default styled.div`
    label {
        letter-spacing: 0.1em;
        color: ${colors.textNavy};
    }

    .containerDiv {
        display: inline
        margin-bottom: 20px;
    }

    .blankDiv {
        float: left;
        display: flex
        align-items: center;
        max-width: 250px;
        min-width: 250px;
        height: 40px;

        background-color: ${(props) => props.theme.filterButtonBackground};
        color: ${(props) => props.theme.filterButtonForeground};

        border-radius: 5px;
        overflow: hidden;
    }

    .notSigned { 
        cursor: pointer   
    }

    .icon {
        cursor: pointer;
        height: 40px;
        width: 40px;
        color: ${(props) => props.theme.tableIconHighlight};
    }

    .labelText {
        letter-spacing: 0.1em;
        color: ${colors.textNavy};
        margin-bottom: 10px;
        margin-top: 10px;
    }

    .iconDiv {
        display: inline;
        position: relative
        right: 0
    }

    .confirmText {
        display: flex;
        font-weight: bolder;
        letter-spacing: 0.1em;
        font-size: 16px;
        color: ${(props) => props.theme.homeListRowHeader};
    }
`;