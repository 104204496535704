export const hasPermissions = (
    requiredPermissions: string[],
    usersPermissions: string[]
) => {
    const hasPerms = usersPermissions?.some((perm: string) =>
        requiredPermissions.includes(perm)
    );

    return hasPerms || requiredPermissions.length === 0;
};
