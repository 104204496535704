// @ts-nocheck

//React
import { useState, useEffect } from "react";

// Material UI
import { Tooltip } from "@mui/material";

// Components
import Table from "components/Common/Table/Table";
import CheckCell from "components/Screens/Notifications/Settings/CheckCell/CheckCell";
import Button from "components/Common/Button/Button";
import SearchOptions from "components/Common/SearchOptions/SearchOptions";

// Utils
import {
    getUserConfig,
    disableConfig,
    enableConfig,
    createUniqueConfigs,
    resetConfigToDefault,
} from "utils/notifications.utlis";

//Types
import {
    ReducedConfigs,
    UserConfiguration,
} from "./ConfigurationSettings.types";

// Context
import { useSettings } from "context/Settings.context";

// Styles
import ConfigurationSettingsContainer from "./ConfigurationSettings.styles";
import colors from "assets/styles/colors";

const ConfigurationSettings: React.FC = () => {
    const { setShowLoadingToast } = useSettings();

    const [loading, setLoading] = useState<boolean>(true);
    const [userConfig, setUserConfig] = useState<UserConfiguration[]>([]);
    const [reducedConfigs, setReducedConfigs] = useState<ReducedConfigs[]>([]);
    const [allEnabledText, setAllEnabledText] = useState<string>("");
    const [identifierFilterQuery, setIdentifierFilterQuery] = useState<string>(
        ""
    );

    const identifiersColumns = [
        {
            id: 1,
            title: "NOTIFICATION TYPE",
            sortable: false,
            value: "data",
            cell: (data: { identifier: string; description: string }) => (
                <Tooltip title={data.description.toUpperCase()}>
                    <p>{data.identifier}</p>
                </Tooltip>
            ),
            show: true,
        },
        {
            id: 2,
            title: "WEB",
            sortable: false,
            value: "data",
            cell: (data: {
                method: string;
                webSource: string;
                webId: any;
                webEnabled: boolean;
                description: string;
                identifier: string;
            }) => (
                <CheckCell
                    method="WEB"
                    source={data.webSource}
                    configId={data.webId}
                    enabled={data.webEnabled}
                    updateConfig={updateConfig}
                    description={data.description}
                    identifier={data.identifier}
                />
            ),
            show: true,
        },
        {
            id: 3,
            title: "EMAIL",
            sortable: false,
            value: "data",
            cell: (data: {
                method: string;
                emailSource: string;
                emailId: any;
                emailEnabled: boolean;
                description: string;
                identifier: string;
            }) => (
                <CheckCell
                    method="EMAIL"
                    source={data.emailSource}
                    configId={data.emailId}
                    enabled={data.emailEnabled}
                    description={data.description}
                    updateConfig={updateConfig}
                    identifier={data.identifier}
                />
            ),
            show: true,
        },
    ];

    const filterIdentifierOptions = [
        {
            id: 1,
            name: "NOTIFICATION TYPE",
            value: "identifier",
            type: "SEARCH",
        },
    ];

    const filteredReducedConfigs = reducedConfigs?.filter((config: any) =>
        identifierFilterQuery.length
            ? config.data.identifier
                  .toLowerCase()
                  .includes(
                      identifierFilterQuery
                          .toLowerCase()
                          .split("identifier=")[1]
                  )
            : config
    );

    const getConfigs = async (loader: boolean) => {
        setLoading(loader);
        const userConfigData = await getUserConfig();
        const filteredUserConfigData = userConfigData.filter(
            (config: any) => config.identifier !== "SEC_ACCESS_ALERT"
        );
        setUserConfig(filteredUserConfigData);
        setUserConfig(userConfigData);
        if (loader) setLoading(false);
    };

    const updateConfig = async (
        enable: boolean,
        configId: any,
        source: string,
        description?: string,
        method?: string,
        identifier?: string
    ) => {
        setShowLoadingToast({
            show: true,
            message: "UPDATING CONFIGURATION",
        });

        enable
            ? await enableConfig(
                  configId,
                  source,
                  description,
                  method,
                  identifier
              )
            : await disableConfig(
                  configId,
                  source,
                  description,
                  method,
                  identifier
              );
        await getConfigs(false);
        setShowLoadingToast({
            show: false,
            message: "",
        });
    };

    const resetDefaultConfig = async () => {
        setLoading(true);
        setShowLoadingToast({
            show: true,
            message: "UPDATING CONFIGURATION",
        });

        await resetConfigToDefault();

        setShowLoadingToast({
            show: false,
            message: "",
        });

        setLoading(false);
        getConfigs(true);
    };

    const userHasEnabled = () => {
        const hasEnabled = userConfig.some((config) => config.enabled === true);
        return hasEnabled;
    };

    const disableAllConfigs = async () => {
        setLoading(true);
        for (let config in userConfig) {
            await disableConfig(
                userConfig[config].id,
                userConfig[config].source,
                userConfig[config].description,
                userConfig[config].method,
                userConfig[config].identifier
            );
        }
        setLoading(false);
        getConfigs(true);
    };

    const enableAllConfigs = async () => {
        setLoading(true);
        for (let config in userConfig) {
            await enableConfig(
                userConfig[config].id,
                userConfig[config].source,
                userConfig[config].description,
                userConfig[config].method,
                userConfig[config].identifier
            );
        }
        setLoading(false);
        getConfigs(true);
    };

    const enableOrDisableConfigurations = () => {
        if (userHasEnabled()) disableAllConfigs();
        if (!userHasEnabled()) enableAllConfigs();
    };

    const filterUserSelectedConfiguration = () => {
        const configSettings = userConfig
            .map((config: any) => {
                return {
                    data: {
                        identifier: config.identifier,
                        webId: getWebEmailId("WEB", config.identifier),
                        emailId: getWebEmailId("EMAIL", config.identifier),
                        webEnabled: getWebEmailEnabled(
                            "WEB",
                            config.identifier
                        ),
                        emailEnabled: getWebEmailEnabled(
                            "EMAIL",
                            config.identifier
                        ),
                        webSource: getWebEmailSource("WEB", config.identifier),
                        emailSource: getWebEmailSource(
                            "EMAIL",
                            config.identifier
                        ),
                        description: config.description,
                        method: config.method,
                    },
                };
            })
            .filter((config) => !config.data.identifier.includes("TEST"));
        const filteredConfigs = createUniqueConfigs(configSettings);
        setReducedConfigs(filteredConfigs);
    };

    const getWebEmailId = (method: string, identifier: string) => {
        const config = userConfig.find(
            (config: UserConfiguration) =>
                config.method === method && config.identifier === identifier
        );
        return config?.id;
    };

    const getWebEmailEnabled = (method: string, identifier: string) => {
        const config = userConfig.find(
            (config: UserConfiguration) =>
                config.method === method && config.identifier === identifier
        );
        return config?.enabled;
    };

    const getWebEmailSource = (method: string, identifier: string) => {
        const config = userConfig.find(
            (config: UserConfiguration) =>
                config.method === method && config.identifier === identifier
        );
        return config?.source;
    };

    useEffect(() => {
        filterUserSelectedConfiguration();
        if (userHasEnabled()) setAllEnabledText("DISABLE ALL");
        if (!userHasEnabled()) setAllEnabledText("ENABLE ALL");
    }, [userConfig]);

    useEffect(() => {
        getConfigs(true);
    }, []);

    return (
        <ConfigurationSettingsContainer>
            <p className="title">NOTIFICATION TYPE</p>
            <div className="button-container">
                <Button
                    text={allEnabledText}
                    click={enableOrDisableConfigurations}
                />
                <Button text="RESET TO DEFAULT" click={resetDefaultConfig} />
            </div>
            <div className="searchBarIdentifiers">
                <SearchOptions
                    setFilterQuery={setIdentifierFilterQuery}
                    options={filterIdentifierOptions}
                />
            </div>
            <Table
                data={filteredReducedConfigs}
                columns={identifiersColumns}
                filterQuery={identifierFilterQuery}
                loading={loading}
                pagination={false}
            />
        </ConfigurationSettingsContainer>
    );
};

export default ConfigurationSettings;
