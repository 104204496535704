import colors from "assets/styles/colors";

export default {
    bqmqVoteScore1: "#017E52",
    bqmqVoteScore2: "#A8C449",
    bqmqVoteScore3: "#F7B901",
    bqmqVoteScore4: "#e07300",
    bqmqVoteScore5: "#aa0008",

    chartAxesColor: "white",
    chartGridColor: "white",
    chartLabelColor: "white",

    dateOutOfRange: "#777",

    votingCellBorder: "#dddddd",
    votingCellText: "#999",
    votingCellCommentText: "#333333",
    votingCellBackground: "rgba(250, 250, 250, 1)",
};
