import styled from 'styled-components';

export default styled.div`

.btn-add-container {
    display: flex;
    justify-content: space-between;
    padding: 10px;
}
.btn-close-container {
    display:flex;
    justify-content: flex-end;
    padding-top: 8px;
}

.section-title {
    font-size: 20px;
    letter-spacing: 0.1em;

    color: ${(props) => props.theme.pageTitleText};
    margin-bottom: 15px;
    text-align: center;
}
.form {
    padding: 10px 20px;
}
`;
