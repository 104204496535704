// Styles
import ExampleContainer from "./Example.styles";

type Props = {
    styles: any;
    children: any;
};

export default function Example({ styles, children }: Props) {
    return <ExampleContainer styles={styles}>{children}</ExampleContainer>;
}
