// Styles
import HomeContainer from "./Home.styles";

// Components
import DataLinks from "components/Screens/Data/Home/DataLinks/DataLinks";
import FailedExtractions from "components/Screens/Data/Home/FailedExtractions/FailedExtractions";
import HoldingExtractions from "components/Screens/Data/Home/HoldingExtractions/HoldingExtractions";
import NewCompanyList from "components/Screens/Data/Home/NewCompanyList/NewCompanyList";
import StockSplitList from "components/Screens/Data/Home/StockSplitList/StockSplitList";

// Context
import { useAuth } from "context/Auth.context";

// Utils
import { hasPermissions } from "utils/permissions.utils";

export default function Home() {
    const { user } = useAuth();

    return (
        <HomeContainer className={"pageContainer"}>
            <p className={"sectionTitle"}>DATA QUALITY</p>
            <DataLinks />

            <div className={"columns"}>
                {hasPermissions(["p_update_companies"], user.permissions) ? (
                    <>
                        <div className={"half"}>
                            <StockSplitList />

                            <NewCompanyList />
                        </div>
                        <div className={"half"}>
                            <FailedExtractions />

                            <HoldingExtractions />
                        </div>
                    </>
                ) : (
                    <>
                        <div className={"half"}>
                            <FailedExtractions />
                        </div>
                        <div className={"half"}>
                            <HoldingExtractions />
                        </div>
                    </>
                )}
            </div>
        </HomeContainer>
    );
}
