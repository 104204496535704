import styled from "styled-components";

export default styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 5px;

    max-width: 25px;
    min-width: 25px;
    max-height: 25px;
    min-height: 25px;

    color: black;
    font-weight: bolder;

    &.score-1 {
        background-color: ${(props) => props.theme.bqmqVoteScore1};
    }
    &.score-2 {
        background-color: ${(props) => props.theme.bqmqVoteScore2};
    }
    &.score-3 {
        background-color: ${(props) => props.theme.bqmqVoteScore3};
    }
    &.score-4 {
        background-color: ${(props) => props.theme.bqmqVoteScore4};
    }
    &.score-5 {
        background-color: ${(props) => props.theme.bqmqVoteScore5};
    }

    &.score-AGREE {
        max-width: fit-content;
        min-width: fit-content;
        padding: 0 10px;

        background-color: ${(props) => props.theme.bqmqVoteScore1};
    }
    &.score-NEUTRAL {
        max-width: fit-content;
        min-width: fit-content;
        padding: 0 10px;

        background-color: ${(props) => props.theme.bqmqVoteScore3};
    }
    &.score-DISAGREE {
        max-width: fit-content;
        min-width: fit-content;
        padding: 0 10px;

        background-color: ${(props) => props.theme.bqmqVoteScore5};
    }
`;
