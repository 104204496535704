import styled from "styled-components";

export default styled.div`
    .toast {
        &.MuiAlert-standardInfo {
            background-color: ${(props) => props.theme.primaryButtonBackground};
            color: ${(props) => props.theme.primaryButtonForeground};
            svg {
                color: ${(props) => props.theme.primaryButtonForeground};
            }
        }
        &.MuiAlert-standardWarning {
            background-color: ${(props) => props.theme.accentButtonBackground};
            color: ${(props) => props.theme.accentButtonForeground};
            svg {
                color: ${(props) => props.theme.accentButtonForeground};
            }
        }
        &.MuiAlert-standardError {
            background-color: ${(props) => props.theme.accentButtonBackground};
            color: ${(props) => props.theme.accentButtonForeground};
            svg {
                color: ${(props) => props.theme.accentButtonForeground};
            }
        }
        &.MuiAlert-standardSuccess {
            background-color: ${(props) => props.theme.primaryButtonBackground};
            color: ${(props) => props.theme.primaryButtonForeground};
            svg {
                color: ${(props) => props.theme.primaryButtonForeground};
            }
        }

        .MuiAlert-action {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 5px;
        }
    }
`;
