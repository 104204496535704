// Styles
import InfoPillContainer from "./InfoPill.styles";

type Props = { label: string; value: any; error?: boolean };

export default function InfoPill({ label, value, error }: Props) {
    return (
        <InfoPillContainer className={error ? "error" : ""}>
            <div className={"label"}>{label}</div>
            <div data-cy="info-pill" className={"value"}>
                {value}
            </div>
        </InfoPillContainer>
    );
}
