import styled from 'styled-components';

export default styled.div`
.table-details, th, td {
    border-collapse: collapse;
    border: 1px solid ${(props) => props.theme.votingCellBorder};

}

.table-details {
    min-width: 300px;
    width: 100%;
    table-layout: auto;
    thead {
        tr {
            background-color: ${(props) => props.theme.tableHeadCellBackground};
            color: ${(props) => props.theme.tableHeadCellText};
            td {
                padding: 10px;
                transform: rotate(-180deg);
                writing-mode: tb-rl;
            }
        }
    }
    tbody {
        tr {
            &:hover > td {
                background-color: ${(props) => props.theme.subMenuBackground} !important;
            }
            td {
                white-space: nowrap;
                padding: 8px 3px;
                text-align: center;
            }
            td.name {
                white-space: wrap;
                max-width:fit-content;
                text-align: left;
            }
            td.overall {
                background-color: ${(props) => props.theme.votingCellBackground};
            }
            td.result {
                background-color: ${(props) => props.theme.subMenuHighlight};
            }
            td.result-name {
                text-align: right;
                color: ${(props) => props.theme.subMenuForeground};
            }
        }
    }
}
`;
