// Styles
import VotingCellContainer from "./VotingCell.styles";

type Props = {
    number: string;
    selected: string;
    setSelected: Function;
    disabled?: boolean;
};

export default function VotingCell({
    number,
    selected,
    setSelected,
    disabled = false,
}: Props) {
    return (
        <VotingCellContainer
            data-cy="voting-cell"
            className={
                (selected?.toUpperCase() === number?.toString()
                    ? "selected score-" + number
                    : "") + (disabled ? " disabled" : " active")
            }
            onClick={() => {
                if (!disabled) {
                    setSelected(selected?.toString() === number ? undefined : number);
                }
            }}
        >
            {number}
        </VotingCellContainer>
    );
}
