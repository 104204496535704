import * as Database from "../utils/database.utils";

export const queryDatabase = async (url: string) => {
    const result = await Database.get(url);
    let data = await result.json();
    return data.data;
};

export const submitToDatabase = async (url: string, answers: any) => {
    const result = await Database.post(url, answers);
    return result;
};
