import styled from 'styled-components';

export default styled.div`

    .fundTitle {
        font-size: 25px;
        letter-spacing: 0.1em;

        color: ${(props) => props.theme.pageTitleText};
        margin-bottom: 10px;
        margin-top: 20px;
    }
    .textFieldDiv {
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
    }

    .textField {
        margin-right: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
    }   

    .buttonDiv {
        display: flex;
    }

    .inputField {
        min-width: 250px;
        max-width: 250px;
        display: inline;
        float: left;
    }
`;