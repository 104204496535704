import colors from "assets/styles/colors";

export default {
    avatarBackground: "white",
    avatarText: "black",
    nameText: "white",

    logoutButtonBackground: "white",
    logoutButtonText: "black",
    logoutButtonBackgroundHover: colors.grey80,
};
