//Components
import CompanySettings from "./CompanySettings/CompanySettings"
import ConfigurationSettings from "./ConfigurationSettings/ConfigurationSettings"

// Styles
import SettingsContainer from "./Settings.styles";


export default function Settings() {
    return (
        <SettingsContainer>
            <div className="root">
                <div className="table-container">
                    <ConfigurationSettings/>
                </div>
                <div className="table-container">
                    <CompanySettings/>
                </div>
            </div>
        </SettingsContainer>
    );
}
