import { useHistory } from "react-router-dom";

// FontAwesome
import {
    faRotate
} from "@fortawesome/free-solid-svg-icons";

// Components
import SubMenuItem from "../../SubMenuItem/SubMenuItem";
//import SubMenuTitle from "../../SubMenuTitle/SubMenuTitle";

// Styles
import ChronographContainer from "./Chronograph.styles";

export default function Chronograph() {
    const history = useHistory();

    return (
        <ChronographContainer className={"submenu"}>
            <div className={"top"}>
                <h1>CHRONOGRAPH</h1>
                <hr />
                <SubMenuItem
                    text={"Data Refresh"}
                    active={
                        history.location.pathname === "/chronograph/refresh"
                    }
                    icon={faRotate}
                    click={() => history.push("/chronograph/refresh")}
                    permissions={["p_chron_can_refresh"]}
                />
            </div>

        </ChronographContainer>
    );
}
