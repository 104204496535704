import styled from "styled-components";
import colors from "assets/styles/colors";

export default styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    textarea {
        border-radius: 5px;
        padding: 10px;
        font-size: 18px;
        border: none;
        color: ${colors.textGrey};
        outline: none;
        background-color: #eeeeee;
        margin-bottom: 10px;
        resize: vertical;
    }
`;
