import styled from 'styled-components';

export default styled.div`
    .slider-inputs {
        display: flex;
        .field {
            min-width: 80px;
            max-width: 80px;
            margin-right: 15px;
        }
        .slider {
            width: 100%;
            margin: 0 10px;
            flex: 1;
        }
    }
`;
