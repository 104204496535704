import { useState } from "react";

// FontAwesome
import {
    faCheck,
    faTimes,
    faHammer,
    faPause,
    faTrash,
    faRetweet,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Material UI
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Popper from "@mui/material/Popper";

// Components
import ErrorDetail from "./ErrorDetail/ErrorDetail";

// Styles
import StatusContainer from "./Status.styles";

type Props = {
    status: Status;
    extractionId: string;
    info: number;
    warning: number;
    critical: number;
    noText?: boolean;
};

export default function Status({
    status,
    extractionId,
    info,
    warning,
    critical,
    noText,
}: Props) {
    const [open, setOpen] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<any>(null);

    let statusDict = {
        SCS: {
            icon: faCheck,
            text: "Successful",
            color: "blue",
        },
        IGN: { icon: faCheck, text: "Successful", color: "blue" },
        FIX: { icon: faHammer, text: "Fixed", color: "blue" },
        HLD: {
            icon: faPause,
            text: "Holding",
            color: "orange",
        },
        FAI: {
            icon: faTimes,
            text: "Failed",
            color: "red",
        },
        DLT: {
            icon: faTrash,
            text: "Deleted",
            color: "red",
        },
        RPL: {
            icon: faRetweet,
            text: "Replaced",
            color: "green",
        },
    };

    const handleClick = (event: React.MouseEvent) => {
        setAnchorEl(event.currentTarget);
        setOpen(!open);
    };

    const handleClickAway = (event: any) => {
        if (!anchorEl.contains(event.target)) {
            setOpen(false);
        }
    };
    return (
        <>
            <StatusContainer
                className={statusDict[status].color}
                onClick={handleClick}
            >
                <div className="icon">
                    <FontAwesomeIcon
                        icon={statusDict[status].icon}
                        fixedWidth
                    />
                </div>
                {!noText && (
                    <div className="text">
                        <p>{statusDict[status].text}</p>
                        {status === "IGN" && (
                            <p className="ignoredInfo">Warnings Ignored</p>
                        )}
                    </div>
                )}
            </StatusContainer>

            <Popper open={open} anchorEl={anchorEl} style={{ zIndex: 10 }}>
                <ClickAwayListener onClickAway={handleClickAway}>
                    <div>
                        <ErrorDetail
                            extractionId={extractionId}
                            info={info}
                            warning={warning}
                            critical={critical}
                        />
                    </div>
                </ClickAwayListener>
            </Popper>
        </>
    );
}
