// Components
import Modal from "components/Common/Modal/Modal";
import Button from "components/Common/Button/Button";
import Table from "components/Common/Table/Table";
import TableCell from "components/Common/TableCell/TableCell";

// Utils
import * as Database from "utils/database.utils";
import { createLocalDownload } from "utils/export.utils";

// Context
import { useSettings } from "context/Settings.context";

// Styles
import ExportCompanyListModalContainer from "./ExportCompanyListModal.styles";

type Props = { setShowModal: Function };

export default function ExportCompanyListModal({ setShowModal }: Props) {
    const { setShowLoadingToast } = useSettings();

    const columns = [
        {
            id: 1,
            title: "FIELD",
            sortable: false,
            value: "field",
            cell: (data: string) => <TableCell data={data} />,
            show: true,
        },
        {
            id: 2,
            title: "DESCRIPTION",
            sortable: false,
            value: "description",
            cell: (data: string) => <TableCell data={data} />,
            show: true,
        },
    ];

    const tableData = [
        {
            field: "company_id",
            description: "A unique identifier for a company",
        },
        {
            field: "company",
            description: "Company name as defined by bloomberg",
        },
        {
            field: "isin",
            description: "International Securities Identification Number",
        },
        {
            field: "ticker",
            description: "Company ticker as defined by Bloomberg",
        },
        {
            field: "company_model_excel_name",
            description: "Name of Company Model Excel file",
        },
        {
            field: "gim_subteam",
            description:
                "Team to which company is assigned e.g. Financials, Industrials, Tech & Telco, etc.",
        },
        {
            field: "analyst",
            description: "Analyst who is tracking company",
        },
        {
            field: "analyst_initials",
            description: "Analysts initials who is tracking company",
        },
        {
            field: "focus_list_gbl",
            description:
                "Flag to indicate company is currently in Global focus List",
        },
        {
            field: "focus_list_asia",
            description:
                "Flag to indicate company is currently in Asia focus list",
        },
        {
            field: "weight_global",
            description: "Current weight of company in Global portfolio",
        },
        {
            field: "weight_asia",
            description: "Current weight of company in Asia portfolio",
        },
    ];

    const exportData = async () => {
        setShowLoadingToast({
            show: true,
            message: "EXPORTING",
        });
        setShowModal(false);

        const res = await Database.post("api/data_extraction/company_details");
        const file = await res.blob();
        createLocalDownload("Company Data Export.csv", file);

        setShowLoadingToast({
            show: false,
            message: "EXPORTING",
        });
    };

    return (
        <Modal>
            <ExportCompanyListModalContainer>
                <p className={"title"}>EXPORT COMPANY LIST</p>

                <div className={"body"}>
                    <Table
                        data={tableData}
                        columns={columns}
                        filterQuery={""}
                        emptyText="NO COMPANIES"
                    />
                </div>

                <div className={"actions"}>
                    <Button
                        text={"CANCEL"}
                        click={() => setShowModal(false)}
                        color={"flat"}
                    />
                    <Button text={"EXPORT"} click={exportData} />
                </div>
            </ExportCompanyListModalContainer>
        </Modal>
    );
}
