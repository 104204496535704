import { useEffect, useState } from "react";

// Context
import { useBQMQ } from "context/BQMQ.context";

// Styles
import VotingCommentContainer from "./VotingComment.styles";

type Props = { questionID: string; label: string; disabled?: boolean };

export default function VotingComment({
    questionID,
    label,
    disabled = false,
}: Props) {
    const { myVotes, setMyVotes } = useBQMQ();

    const charLimit = 2000;

    const [value, setValue] = useState<any>("");

    const setDefaultValue = () => {
        setValue("");
    };

    useEffect(() => {
        setMyVotes({
            ...myVotes,
            [questionID]: value,
        });
    }, [value]);

    useEffect(() => {
        if (myVotes[questionID] && myVotes[questionID] !== value) {
            setValue(myVotes[questionID]);
        }
    }, [myVotes[questionID]]);

    useEffect(() => {
        if (value === 0) setDefaultValue();
        if (myVotes[questionID]) {
            setValue(myVotes[questionID]);
        }
    }, []);

    const handleChangeValue = (value: string) => {
        if (value.length <= charLimit && !disabled) {
            setValue(value);
        }
    };

    return (
        <VotingCommentContainer>
            <div className={"questionCell"}>
                {label}
                <div
                    className={
                        "charCount " +
                        (value.length >= charLimit ? "limit" : "")
                    }
                >
                    {value.length} / {charLimit}
                </div>
            </div>

            <textarea
                data-cy="question-comment-box"
                placeholder={"Comment"}
                disabled={disabled}
                rows={8}
                value={value === 0 ? "" : value}
                onChange={(e) => handleChangeValue(e.target.value)}
            />
        </VotingCommentContainer>
    );
}
