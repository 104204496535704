import { useHistory } from "react-router-dom";

// FontAwesome
import { faSave } from "@fortawesome/free-solid-svg-icons";

// Components
import InfoPill from "components/Common/InfoPill/InfoPill";
import LargeButton from "components/Common/LargeButton/LargeButton";
import ReviewUserList from "./ReviewUserList/ReviewUserList";

// Context
import { useBQMQ } from "context/BQMQ.context";
import { useSettings } from "context/Settings.context";

// Utils
import * as Database from "utils/database.utils";
import { toDateAndTime } from "utils/general.utils";

// Styles
import ReviewContainer from "./Review.styles";

// Logger
import Logger from "utils/logger.utils";
const Log = new Logger();
Log.init("BQMQ: Focus List Setup: Review");

type Props = {
    company: any;
    analyst: any;
    chair: any;
    users: any;
    date: any;
    updateSessionID?: string;
};

export default function Review({
    company,
    analyst,
    chair,
    users,
    date,
    updateSessionID,
}: Props) {
    const history = useHistory();
    const { setToast, setShowLoadingToast, showLoadingToast } = useSettings();
    const { getDropDowns } = useBQMQ();
    type user = {
        core: boolean;
        id: string;
        name: string;
    };
    const createFocusListSession = async () => {
        setShowLoadingToast({
            show: true,
            message: "SAVING",
        });
        let newFocusListSession: any = {
            questions: [],
            analysts: users
                .filter((obj: user) => obj.core === false)
                .map((obj: user) => ({
                    id: obj.id,
                    name: obj.name,
                })),
            core: users
                .filter((obj: user) => obj.core === true)
                .map((obj: user) => ({
                    id: obj.id,
                    name: obj.name,
                })),
            host: {
                id: chair.id,
                name: chair.name,
            },
            presenter: {
                id: analyst.id,
                name: analyst.name,
            },
            company: {
                name: company.label || company.name,
            },

            votes: [],
            date: date,
            revote: false,
        };
        if (company.value || company.id) {
            newFocusListSession.company_id = company.value || company.id;
            newFocusListSession.company.id = company.value || company.id;
        }

        if (updateSessionID) newFocusListSession.id = updateSessionID;

        Log.info("createFocusListSession ", newFocusListSession);

        let res: any;

        try {
            res = await Database.post(
                "api/bqmq_voting/session/create",
                newFocusListSession
            );
            const data = await res.json();
            if (data.data.message === "SESSION_CREATED") {
                setToast({
                    show: true,
                    type: "success",
                    message: "Focus List Session Saved",
                });

                history.push("/voting/");
                getDropDowns();
            }
        } catch (err) {
            setToast({
                show: true,
                type: "error",
                message: "Error Saving Focus List Session ",
            });
        }

        setShowLoadingToast({
            show: false,
            message: "SAVING",
        });
    };

    const checkFields = () => {
        if (!chair) {
            setToast({
                show: true,
                type: "error",
                message: "Please select the chair",
            });
        } else if (!company) {
            setToast({
                show: true,
                type: "error",
                message: "Please select the company",
            });
        } else if (!analyst) {
            setToast({
                show: true,
                type: "error",
                message: "Please select the analyst",
            });
        } else if (!date) {
            setToast({
                show: true,
                type: "error",
                message: "Please select the date",
            });
        } else if (
            !users.find((user: any) => user.id === chair.id).invited &&
            !users.find((user: any) => user.id === chair.id).core
        ) {
            setToast({
                show: true,
                type: "error",
                message: "The chair needs to be invited to the session",
            });
        } else {
            createFocusListSession();
        }
    };

    return (
        <ReviewContainer>
            <div className={"columns"}>
                <div className={"half left"}>
                    <InfoPill
                        label={"DATE"}
                        value={date && toDateAndTime(date)}
                        error={!date}
                    />
                    <InfoPill
                        label={"COMPANY"}
                        value={company?.label || company?.name}
                        error={!company?.label && !company?.name}
                    />
                    <InfoPill
                        label={"CHAIR"}
                        value={chair?.name}
                        error={!chair?.name}
                    />
                    <InfoPill
                        label={"PRESENTER"}
                        value={analyst?.name}
                        error={!analyst?.name}
                    />

                    <LargeButton
                        className={"hideMD"}
                        text={"SAVE SESSION"}
                        icon={faSave}
                        click={checkFields}
                        disabled={showLoadingToast.show}
                    />
                </div>

                <div className={"half right"}>
                    <ReviewUserList users={users} />

                    <LargeButton
                        className={"showMD"}
                        text={"SAVE SESSION"}
                        icon={faSave}
                        click={checkFields}
                        disabled={showLoadingToast.show}
                    />
                </div>
            </div>
        </ReviewContainer>
    );
}
