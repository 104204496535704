// Context
import { useAuth } from "context/Auth.context";

type Props = {
    yes: JSX.Element | null;
    no?: JSX.Element | null;
    permissions: Array<string> | string;
};

export default function Can({
    yes = null,
    no = null,
    permissions = [],
}: Props): JSX.Element | null {
    const { user } = useAuth();
    const usersPermissions = user?.permissions;

    const hasPerms = usersPermissions?.some((perm: string) =>
        permissions.includes(perm)
    );

    return hasPerms || permissions.length === 0 ? yes : no;
}
