import { useHistory } from "react-router-dom";

// FontAwesome
import { faComments } from "@fortawesome/free-solid-svg-icons";

// Components
import SubMenuItem from "../../SubMenuItem/SubMenuItem";

// Styles
import AskAdaContainer from "./AskAda.styles";

export default function AskAda() {
    const history = useHistory();

    return (
        <AskAdaContainer className={"submenu"}>
            <div className={"top"}>
                <h1>ASK ADA</h1>
                <hr />
                <SubMenuItem
                    development
                    text={"Q&A"}
                    active={history.location.pathname === "/ask-ada/chat"}
                    icon={faComments}
                    click={() => history.push("/ask-ada/chat")}
                    permissions={["p_can_view_ask"]}
                />
            </div>
        </AskAdaContainer>
    );
}
