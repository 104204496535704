// Utils
import { toDate } from "utils/general.utils";

// Styles
import MoreInfoContainer from "./MoreInfo.styles";
import { Grid } from "@mui/material";

type Props = {
    extractionMetaData: {
        id: string;
        model_h1_year: string;
        model_h1_start_date: string;
        model_h1_end_date: string;
        reporting_currency: string;
        listing_currency: string;
        folder_path: string;
        user_notes: string;
    };
};

export default function MoreInfo({ extractionMetaData }: Props) {
    return (
        <MoreInfoContainer>
            <Grid container>
                <Grid item xs={3}>
                    <div className="info">
                        <label>MODEL H1 YEAR</label>
                        <p>{extractionMetaData.model_h1_year}</p>
                    </div>
                    <div className="info">
                        <label>MODEL H1 START DATE</label>
                        <p>{toDate(extractionMetaData.model_h1_start_date)}</p>
                    </div>
                    <div className="info">
                        <label>MODEL H1 END DATE</label>
                        <p>{toDate(extractionMetaData.model_h1_end_date)}</p>
                    </div>
                </Grid>
                <Grid item xs={3}>
                    <div className="info">
                        <label>REPORTING CURRENCY</label>
                        <p>{extractionMetaData.reporting_currency}</p>
                    </div>
                    <div className="info">
                        <label>LISTING CURRENCY</label>
                        <p>{extractionMetaData.listing_currency}</p>
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div className="info">
                        <label>FOLDER PATH</label>
                        <p>{extractionMetaData.folder_path}</p>
                    </div>
                    <div className="info">
                        <label>ID</label>
                        <p>{extractionMetaData.id}</p>
                    </div>
                    <div className="info">
                        <label>USER NOTES</label>
                        <p>
                            {extractionMetaData.user_notes
                                ? extractionMetaData.user_notes
                                : "-"}
                        </p>
                    </div>
                </Grid>
            </Grid>
        </MoreInfoContainer>
    );
}
