import React, { useEffect, useState } from "react";

// Styles
import VoteDetailListContainer from "./VoteDetailList.styles";
// Interface
import {DealVote, GrowthQuestion, GrowthAnswer, DealTeam} from "assets/interfaces/growth_voting";

// enums
import {EnumGrowthVotingTeams, EnumGrowthAnswerTypes} from "assets/enums/growth_voting";

// Components
import Button from "components/Common/Button/Button";
import VoteIcon from "../VoteIcon/VoteIcon";
import {useSettings} from "context/Settings.context";
import { useGrowthDeal } from "context/growth/GrowthDeal.context";
import { Tooltip } from "@mui/material";
import AnswerResult from "components/Screens/GrowthVoting/VoteDetail/AnswerResult/AnswerResult";

// Utils
import {formatDate} from "utils/date.utils";
import { ApiHandler } from "services/api_handler";
import { getTeamNameByType, getVoteName, getVoteParticipation } from "utils/growth_voting.utils";

// Material UI
import Accordion from '@mui/material/Accordion';
import { styled } from '@mui/material/styles';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faUserAltSlash } from "@fortawesome/free-solid-svg-icons";
import VoteDetail from "../VoteDetail/VoteDetail";
import MyVotingModal from "../EditVoteModal/EditVoteModal";



interface Props {
    dealId: string|null,
    votes: DealVote[],
    teams: DealTeam[],
    open: boolean,
}

const URL_QUESTIONS = "api/growth_voting/questions";
const URL_ANSWERS = "api/growth_voting/answers";

const CustomAccordion = styled(Accordion)(({ theme }) => {
    return {
      boxShadow: 'none', // this styles directly apply to accordion
      border: `1px solid ${theme.palette.grey[200]}`,
      borderTop: `2px solid ${theme.palette.grey[300]}`,
      '.MuiAccordionDetails-root': {},
      '.MuiAccordionSummary-root': {
        padding: '0 20px',
        minHeight: '65px',
        backgroundColor: theme.palette.grey[200],
      }, // this apply to Summary
    };
  });

const VoteDetailList = ({dealId, votes, teams, open=true}: Props) => {
    const { setShowLoadingToast, setToast } = useSettings();
    const [ questions, setQuestions ]= useState<GrowthQuestion[]>([]);
    const [ answeres, setAnsweres ]= useState<GrowthAnswer[]>([]);
    const {answersDirty, setAnswersDirty} = useGrowthDeal();

    const fetchQuestions = async () => {
        let response = await new  ApiHandler(URL_QUESTIONS, setShowLoadingToast).get();
        let q = response.data.sort((a:GrowthQuestion, b:GrowthQuestion) => a.order - b.order);
        setQuestions([...q]);
    }

    const fetchAnsweres = async () => {
        if (!dealId) {
            return;
        }

        let url = URL_ANSWERS + "?deal=" + dealId;
        let response = await new  ApiHandler(url, setShowLoadingToast).get();
        setAnsweres([...response.data]);
        setAnswersDirty(false);
    }

    const getVoteQuestions = (vote: DealVote): GrowthQuestion[] => {
        let voteQuestions = questions.filter(question => question.type === vote.type);
        return voteQuestions
    }

    const getVoteAnswers = (vote: DealVote): GrowthAnswer[] => {
        return answeres.filter(answer => answer.vote_id === vote.id);
    }

    const getVoteTeam = (): DealTeam => {
        let team = teams.find(team => team.type === EnumGrowthVotingTeams.Vote);

        if (!team) {
            throw new Error("No Vote team added to the deal.");
        }

        return team;
    }

    useEffect(() => {
        fetchQuestions();
    }, []);

    useEffect(() => {
        fetchAnsweres();
    }, [dealId]);

    useEffect(() => {
        if (answersDirty) {
            // answers change, re-fetch
            fetchAnsweres();
        }
    }, [answersDirty]);

    return (
        <VoteDetailListContainer>
            <>
            <div className={"sectionTitle"}>Votes</div>

            {votes.length === 0 &&
                <div className={"no-vote"}>
                    No votes were added to the deal.
                </div>
            }

            <div className={"votes"}>
                {votes.length > 0 &&
                    votes.map((vote, index) => {

                        let voteQuestions = getVoteQuestions(vote);
                        let voteAnswers = getVoteAnswers(vote);
                        let voteTeam = getVoteTeam();
                        let partipation = getVoteParticipation(voteAnswers, voteTeam);

                        return (
                                <div className={"vote"}>
                                    <div className={"header"}>
                                        <span className={"type"}>
                                            <VoteIcon vote={vote}  key={"vote-"+ vote?.id} />
                                            <span>{getVoteName(vote.type)}</span>
                                        </span>
                                        <Tooltip title={"Member included if voted on any question."} placement="top">
                                            <span className={"participation"}>{partipation.numVotedMembers}</span>
                                        </Tooltip>
                                        {partipation.membersNotVoted.length > 0 &&
                                            <Tooltip title={"Missing Voters: " + partipation.membersNotVoted.map(m => m.name).join(", ")} placement="top">
                                                <span className={"missing"}>
                                                    <FontAwesomeIcon className="icon" icon={faUserAltSlash} fixedWidth />
                                                </span>
                                            </Tooltip>
                                        }
                                        {vote?.score && <Tooltip title={"Vote Score"} placement="top">
                                            <AnswerResult result={vote.score} key={"score-"+vote.id}
                                                answerType={EnumGrowthAnswerTypes.Rating_1_5}
                                                numberOfAnswers={1}
                                            />
                                        </Tooltip>}

                                    <span className={"date"}>{vote?.created ? formatDate(new Date(vote.created)) : ""}</span>
                                    </div>
                                    <VoteDetail key={"details-"+vote?.id} vote={vote} questions={voteQuestions} answeres={voteAnswers} team={voteTeam} dealOpen={open} />
                                </div>
                        )
            })}
            </div>
            </>
        </VoteDetailListContainer>
    );
};

export default VoteDetailList
