import styled from 'styled-components';
import colors from "assets/styles/colors";

export default styled.div`
    .sectionTitle {
        font-size: 25px;
        letter-spacing: 0.1em;

        color: ${(props) => props.theme.pageTitleText};
        margin-bottom: 10px;
        margin-top: 20px;
    }

    .title {
        letter-spacing: 0.1em;
        color: ${(props) => props.theme.pageTitleText};
    }

    .inputContainer {
        width: 20vw;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    label {
        letter-spacing: 0.1em;
        color: ${colors.textNavy};
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .mainContainer {
        display: block;
    }
`;