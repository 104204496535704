// Components
import PageTitle from "components/Common/PageTitle/PageTitle";
import DatabaseSchema from "components/Screens/Data/DataDictionary/DatabaseSchema/DatabaseSchema";

// Stlyes
import DatabaseSchemaContainer from "./DatabaseSchema.styles";

const DatabaseSchemaPage: React.FC = () => {
    return (
        <DatabaseSchemaContainer>
            <PageTitle title="DATABASE SCHEMA" />
            <DatabaseSchema />
        </DatabaseSchemaContainer>
    );
};

export default DatabaseSchemaPage;
