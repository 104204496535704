import React from "react";

// Components
import Category from "components/Docs/DocsMenu/Category/Category";
import Item from "components/Docs/DocsMenu/Item/Item";

// Data
import fileTree from "documentation/config/dev/fileTree";

// Styles
import DocsContainer from "./Docs.styles";
import FileTree from "components/Common/FileTree/FileTree";

export default function Docs() {
    return (
        <DocsContainer className={"submenu"}>
            <div className={"top"}>
                <h1>DOCS</h1>
                <hr />

                <div className="menu">
                    <FileTree tree={fileTree} />
                </div>
            </div>

            <div className={"bottom"}></div>
        </DocsContainer>
    );
}
