export default function Logger() {
    this.module = module;

    this.init = function (module) {
        if (
            process.env.NODE_ENV === "development" || localStorage.getItem("logsOn")
        ) {
            this.logsOn = true;
        }

        this.module = module;
    };

    this.info = function (...message) {
        if (this.logsOn) {
            let newArray = [];

            message.map((ele) => {
                if (typeof ele === "object" && ele !== null) {
                    ele = JSON.stringify(ele);
                }
                newArray.push(ele);
            });

            let time = new Date().toLocaleString();

            console.log(time + ": " + this.module + ": ", ...message);
        }
    };
}
