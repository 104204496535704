import colors from "assets/styles/colors";

export default {
    filterButtonBackground: colors.grey90,
    filterButtonBackgroundHover: colors.grey80,
    filterButtonForeground: colors.grey10,
    filterButtonBackgroundActive: colors.textNavy,
    filterButtonForegroundActive: "white",

    dropDownMenuBackground: "white",
    dropDownMenuText: colors.textGrey,
    dropDownSearchBoxIcon: colors.accentBlue,
    dropDownSearchBoxBorder: colors.grey60,
    dropDownBackgroundHover: colors.grey80,

    searchOptionsShowAllBackground: colors.textNavy,
    searchOptionsShowAllText: "white",
};
