import styled from "styled-components";

export default styled.div`
.sectionTitle {
    font-size: 25px;
    letter-spacing: 0.1em;

    color: ${(props) => props.theme.pageTitleText};
    margin-bottom: 10px;
    margin-top: 20px;
}

    .loader {
        margin-top: 100px;
    }

    .title {
        letter-spacing: 0.1em;
        color: ${(props) => props.theme.pageTitleText};
        margin-bottom: 50px;
    }

    .started {
        max-width: 800px;
        border: 1px solid ${(props) => props.theme.permissionsAdd};
        border-radius: 10px;
        .header {
            padding: 30px;
            .status {
                color: ${(props) => props.theme.permissionsAdd};
            }
        }
        .body {
            padding: 20px;
        }
    }

    .succeeded {
        max-width: 800px;
        border: 1px solid ${(props) => props.theme.permissionsCheck};
        border-radius: 10px;
        .header {
            padding: 30px;
            .status {
                color: ${(props) => props.theme.permissionsCheck};
            }
        }
        .body {
            padding: 20px;
        }
    }

    .failed {
        max-width: 800px;
        border: 1px solid ${(props) => props.theme.permissionsDelete};
        border-radius: 10px;
        .header {
            padding: 30px;
            .status {
                color: ${(props) => props.theme.permissionsDelete};
            }
        }
        .body {
            padding: 20px;
        }
    }

    .last-successful-refresh {
        padding: 15px;
        color: ${(props) => props.theme.subMenuActive};
    }

    .trigger-at {
        margin-top: 10px;
        color: ${(props) => props.theme.subMenuActive};
    }

    .important-note {
       padding: 10px;
       color: ${(props) => props.theme.subMenuBadgeBackgroundSecondary};
    }

`;
