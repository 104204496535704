import React, { useEffect } from "react";

// Styles
import DublinFundContainer from "./DublinFund.styles";
import * as Database from "utils/database.utils";

// Components
import Tabs from "components/Common/Tabs/Tabs";
import Historic from "components/Screens/Fees/Common/FundTabs/HistoricTab/Historic";
import Latest from "components/Screens/Fees/Common/FundTabs/LatestTab/Latest";
import Reports from "components/Screens/Fees/Common/FundTabs/ReportsTab/Reports";
import DublinGlobal from "./Global/DublinGlobal";
import DublinAsia from "./Asia/DublinAsia";

interface DublinFundProps {}

const DublinFund = ({}: DublinFundProps) => {
    return (
        <DublinFundContainer>
            <p className={"sectionTitle"}>DUBLIN FUND</p>
            <Tabs
                defaultTab="global"
                tabs={[
                    {
                        value: "global",
                        title: "GLOBAL",
                        content: <DublinGlobal region="global" />,
                        permissions: ["p_can_view_fees", "p_can_actions_fees"],
                    },
                    {
                        value: "asia",
                        title: "ASIA",
                        content: <DublinAsia region="asia" />,
                        permissions: ["p_can_view_fees", "p_can_action_fees"],
                    },
                ]}
            />
        </DublinFundContainer>
    );
};
export default DublinFund;
