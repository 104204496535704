export enum EnumGrowthVotingTeams {
    Deal = "deal",
    Quic = "quic",
    Vote = "vote",
    Lead = "lead",
}

export enum EnumGrowthVotingVoteTypes {
    BQ = "bq",
    MQ = "mq",
    ST = "st",
}

export enum EnumGrowthAnswerTypes {
    Boolean = "Boolean",
    Rating_1_5 = "Rating_1_5",
    Percentage = "Percentage",
}
