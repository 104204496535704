import React, { useEffect, useState } from "react";

// Styles
import EditVoteContainer from "./EditVote.styles";

// Types
import {GrowthQuestion, GrowthAnswer, DealVote, DealTeamMember} from "assets/interfaces/growth_voting";


// Components
import Button from "../../../Common/Button/Button";
import { getVoteName } from "utils/growth_voting.utils";
import EditAnswer from "../EditAnswer/EditAnswer";

// Context
import {useSettings} from "context/Settings.context";
import { useGrowthDeal } from "context/growth/GrowthDeal.context";

//Utils
import {ApiHandler} from "services/api_handler";
import VoteCountsTable from "components/Screens/BQMQ/Results/VoteCountsTable/VoteCountsTable";


const URL_ANSWERS= "api/growth_voting/answers";


interface Porps {
    vote: DealVote;
    questions: GrowthQuestion[];
    answers: GrowthAnswer[];
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    member : DealTeamMember | undefined; // the user whows vote we are looking at
}


const EditVote  = ({vote, questions, answers, setShowModal, member}: Porps) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [dispalyedAnsweres, setDispalyedAnsweres] = useState<GrowthAnswer[]>([]);
    const [updatedAnsweres, setUpdatedAnsweres] = useState<GrowthAnswer[]>([]);
    const [voteDisplayName, setVoteDisplayName] = useState<string>(vote.type ? vote.type : "");
    const { setToast, setShowLoadingToast } = useSettings();
    const {setAnswersDirty, setDealDirty} = useGrowthDeal();

    const saveVote = async () => {
        await new ApiHandler(URL_ANSWERS, setShowLoadingToast, setToast).post({"answers": updatedAnsweres});
        setUpdatedAnsweres([]);
        setAnswersDirty(true);
        setDealDirty(true);
        setShowModal(false);
    }

    useEffect(() => {
        setVoteDisplayName(getVoteName(vote.type))
    }, [])

    const buildDisplayedAnsweres = () => {
        let toDisplay: GrowthAnswer[] = [];

        questions.forEach((q) => {
            // find the answer for the question and for the given member (current user)
            let answer: GrowthAnswer|undefined = answers.find((a) => a.question_id === q.id && a?.team_member_id === member?.id)

            if (!answer) {
                answer = {
                    team_member_id: (member?.id) ? member.id : '',
                    question_id: q.id,
                    vote_id: vote.id ? vote.id : '',
                    answer_bool: undefined,
                    answer_rating: undefined,
                    answer_percent: undefined,
                    question: {
                        id: q.id,
                        question: q.question,
                        type: q.type,
                        answer_type: q.answer_type,
                        name: q.name,
                        active: q.active,
                        order: q.order,
                    }
                }
            }

            toDisplay.push(answer)
        })

        setDispalyedAnsweres(toDisplay)
    }

    useEffect(() => {
        buildDisplayedAnsweres()
    }, [])

    return (
        <EditVoteContainer>
            <div className={"content"}>
                <div className={"vote-header"}>
                    {voteDisplayName}
                </div>
                <div>
                    {!vote.open && <span className={"label label-closed"}>Closed</span>}
                    {vote.open && <span className={"label label-open"}>Open</span>}
                </div>
                <div className={"questions"}>
                    {dispalyedAnsweres.length > 0 && dispalyedAnsweres.map((dAnswer, index) => (
                        <div className={"question " + (dAnswer?.question?.name.toLowerCase().includes("overall") ? "overall" : "")} key={"question-" + index}>
                            <span className={"order-number"}>{index + 1}.</span>
                            <div className={"name"}>
                                {dAnswer.question?.question}
                            </div>
                            <div>
                                <EditAnswer answer={dAnswer} updatedAnswers={updatedAnsweres} setUpdatedAnswers={setUpdatedAnsweres} open={vote.open} />
                            </div>
                        </div>
                    ))}
                </div>

                {vote.open && <div className={"btn-add-container"}>
                    <Button text="Save" color="primary"  click={saveVote} />
                </div> }
            </div>
        </EditVoteContainer>
    );
};

export default EditVote
