import styled from "styled-components";
interface StyledDivProps {
    width?: number;
    value?: string | undefined;
}

export default styled.div<StyledDivProps>`
    .tabGroup {
        pointer-events: none;
        width: ${(props) => props.width}px;
        justify-content: center;
        margin-right: 10px;
        border-bottom: ${(props) => props.value} !important;
    }
    p {
        color: "#0F4453";
    }
`;
