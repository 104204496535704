import React, { useEffect, useState } from "react";

// Styles
import AnswerResultContainer from "./AnswerResult.styles";

// Enums
import { EnumGrowthAnswerTypes } from "assets/enums/growth_voting";

interface Props {
    result: number|null,
    answerType: string,
    numberOfAnswers: number,
    summary?: boolean
}

const AnswerResult = ({result, answerType, numberOfAnswers, summary = true}: Props) => {


    useEffect(() => {
    }, []);


    return (
        <AnswerResultContainer>
            {(() => {
                if (result != null && numberOfAnswers > 0) {
                    return (
                        <div>
                            {answerType === EnumGrowthAnswerTypes.Boolean &&
                                <div className={"bool"}>
                                    {summary && <span>Yes: {new Intl.NumberFormat('en-GB', {maximumFractionDigits: 0}).format(result*100)}% | </span>}
                                    {summary && <span>No: {new Intl.NumberFormat('en-GB', {maximumFractionDigits: 0}).format((1 -result)*100)}%</span>}
                                    {!summary && (result === 1 ? "Yes" : result === 0 ? "No" : "No answer")}
                                </div>
                            }
                            {answerType === EnumGrowthAnswerTypes.Percentage &&
                                <div className={"percent"}>
                                    <span>{new Intl.NumberFormat('en-GB', {maximumFractionDigits: 0}).format(result)}%</span>
                                </div>
                            }
                            {answerType === EnumGrowthAnswerTypes.Rating_1_5 &&
                                <div className={"rating"}>
                                    <span className={"rating-" + Math.floor(result)}>
                                        {new Intl.NumberFormat('en-GB', {minimumFractionDigits: 1, maximumFractionDigits: 1}).format(result)}
                                    </span>
                                </div>
                            }
                        </div>
                    )
                } else {
                    return (
                        <div className="no-answer">
                            {"No answer"}
                        </div>
                    )
                }
            })()}
        </AnswerResultContainer>
    );
};

export default AnswerResult
