import {
    faLineChart,
    faUsers,
    faTableColumns,
    faComputer
} from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import SubMenuItem from "../../SubMenuItem/SubMenuItem";
import SubMenuTitle from "../../SubMenuTitle/SubMenuTitle";
// Styles
import ReportingContainer from "./Reporting.styles";
import { NINETY_DAY_CONSUMPTION_TITLE, NINETY_DAY_DASHBOARD_TITLE, NINETY_DAY_USER_TITLE, THIRTY_DAY_TITLE } from "screens/ScreenConstants";

interface ReportingProps {}
const Reporting = ({}: ReportingProps) => {
    const history = useHistory();
    return (
        <ReportingContainer className={"submenu"}>
            <div className={"top"}>
                <div>
                    <h1 style={{ width: "90%" }}>REPORTING</h1>
                </div>
                <hr />
                <SubMenuTitle
                    text={"Reporting"}
                    permissions={["p_can_view_reporting"]}
                />
                <SubMenuItem
                    text={THIRTY_DAY_TITLE}
                    active={
                        history.location.pathname ===
                        "/reporting/thirty-day-summary" 
                    }
                    icon={faLineChart}
                    click={() =>
                        history.push(
                           "/reporting/thirty-day-summary" 
                        )
                    }
                    permissions={["p_can_view_reporting"]} 
                />
                <SubMenuItem
                    text={NINETY_DAY_DASHBOARD_TITLE}
                    active={
                        history.location.pathname ===
                        "/reporting/ninety-day-dashboard-summary"
                    }
                    icon={faTableColumns}
                    click={() =>
                        history.push(
                            "/reporting/ninety-day-dashboard-summary"
                        )
                    }
                    permissions={["p_can_view_reporting"]}
                /> 
                <SubMenuItem
                    text={NINETY_DAY_USER_TITLE}
                    active={
                        history.location.pathname ===
                        "/reporting/ninety-day-user-summary"
                    }
                    icon={faUsers}
                    click={() =>
                        history.push(
                            "/reporting/ninety-day-user-summary"
                        )
                    }
                    permissions={["p_can_view_reporting"]} 
                />
                <SubMenuItem
                    text={NINETY_DAY_CONSUMPTION_TITLE}
                    active={
                        history.location.pathname ===
                        "/reporting/ninety-day-consumption-summary"
                    }
                    icon={faComputer}
                    click={() =>
                        history.push(
                            "/reporting/ninety-day-consumption-summary"
                        )
                    }
                    permissions={["p_can_view_reporting"]}
                />
            </div>
        </ReportingContainer>
    );
};
export default Reporting;