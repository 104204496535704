import styled from "styled-components";

export default styled.div`
    .filter-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        .left {
            display: flex;
            align-items: center;
            flex: 1;
            .search-options {
                display: inline;
            }
        }
    }
`;
