export default [
    {
        name: "Home",
        path: "documentation/dev/Home.doc.mdx",
        order: 1,
        border: true,
        type: "FILE",
    },
    {
        name: "General",
        type: "FOLDER",
        order: 2,
        children: [
            {
                name: "Components",
                path: "documentation/dev/General/Components.doc.mdx",
                order: 2,
                type: "FILE",
            },
            {
                name: "Permissions",
                path: "documentation/dev/General/Permissions.doc.mdx",
                order: 8,
                type: "FILE",
            },
            {
                name: "Project Structure",
                path: "documentation/dev/General/ProjectStructure.doc.mdx",
                order: 1,
                type: "FILE",
            },
            {
                name: "Styling",
                path: "documentation/dev/General/Styling.doc.mdx",
                order: 4,
                type: "FILE",
            },
            {
                name: "Testing",
                path: "documentation/dev/General/Testing.doc.mdx",
                order: 6,
                type: "FILE",
            },
            {
                name: "Typescript",
                path: "documentation/dev/General/Typescript.doc.mdx",
                order: 5,
                type: "FILE",
            },
            {
                name: "Routing",
                path: "screens/Routing.doc.mdx",
                order: 7,
                type: "FILE",
            },
        ],
    },
    {
        name: "Components",
        type: "FOLDER",
        order: 3,
        children: [
            {
                name: "Common",
                type: "FOLDER",
                children: [
                    {
                        name: "Button",
                        path: "components/Common/Button/Button.doc.mdx",
                        order: 1,

                        permissions: [],
                        type: "FILE",
                    },
                ],
            },
            {
                name: "Menu",
                path: "components/Menu/Menu.doc.mdx",
                order: 2,

                permissions: [],
                type: "FILE",
            },
        ],
    },
    {
        name: "Documentation",
        type: "FOLDER",
        order: 4,
        border: true,
        children: [
            {
                name: "Creating New Docs",
                path: "documentation/dev/Documentation/CreatingDocs.doc.mdx",
                order: 2,

                permissions: [],
                type: "FILE",
            },
            {
                name: "Introduction",
                path: "documentation/dev/Documentation/DocumentationIntro.doc.mdx",
                order: 1,

                permissions: [],
                type: "FILE",
            },
        ],
    },
    {
        name: "BQMQ",
        type: "FOLDER",
        order: 5,
        children: [
            {
                name: "Home",
                path: "documentation/dev/BQMQ/BQMQIntro.doc.mdx",
                order: 1,
                type: "FILE",
            },
            {
                name: "Sessions",
                path: "documentation/dev/BQMQ/Sessions.doc.mdx",
                order: 2,
                type: "FILE",
            },
            {
                name: "Voting",
                path: "documentation/dev/BQMQ/Voting.doc.mdx",
                order: 3,
                type: "FILE",
            },
        ],
    },
];
