import React from "react";

// Styles
import MorningNotesRowContainer from "./MorningNotesRow.styles";

// React Router
import { useHistory } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faUser, faUsers, faClock, } from "@fortawesome/free-solid-svg-icons";
import { teamLabelDict } from "assets/data/lists";
import CommentCountCell from "../CommentCountCell/CommentCountCell";

interface MorningNotesRowProps {
    data: any;
}

const MorningNotesRow = ({ data }: MorningNotesRowProps) => {
    const history = useHistory();

    const redirectToNotes = () => {
        history.push(
            `/research/morning-meeting-notes?company_id=${data.id}&company_name=${data.company_name}`
        );
    };

    return (
        <MorningNotesRowContainer onClick={redirectToNotes}>
            <div className="info">
                <div className="name">{data.company_name.toUpperCase()}</div>
                <div className={"details"}>
                    {data.sector && (
                        <>
                            <Tooltip title={<h2>TEAM</h2>}>
                                <div className={"detail"}>
                                    <FontAwesomeIcon
                                        icon={faUsers}
                                        fixedWidth
                                    />
                                    <p className={"capitalize"}>
                                        {/* @ts-ignore */}
                                        {teamLabelDict[data.sector as any]}
                                    </p>
                                </div>
                            </Tooltip>
                            <Tooltip title={<h2>ANALYST</h2>}>
                                <div className={"detail"}>
                                    <FontAwesomeIcon icon={faUser} fixedWidth />
                                    <p className={"capitalize"}>
                                        {data.analyst}
                                    </p>
                                </div>
                            </Tooltip>
                            <Tooltip title={<h2>LAST VOTE DATE</h2>}>
                                <div className={"detail"}>
                                    <FontAwesomeIcon
                                        icon={faCalendar}
                                        fixedWidth
                                    />
                                    <p className={"capitalize"}>
                                        {data.last_vote_date}
                                    </p>
                                </div>
                            </Tooltip>
                            <Tooltip title={<h2>DAYS SINCE LAST COMMENT</h2>}>
                                <div className={"detail"}>
                                    <FontAwesomeIcon
                                        icon={faClock}
                                        fixedWidth
                                    />
                                    <p className={"capitalize"}>
                                        {data.days_since_last_comment} Days
                                    </p>
                                </div>
                            </Tooltip>
                        </>
                    )}
                </div>
            </div>
            <CommentCountCell value={{ value: data.comment_count }} />
        </MorningNotesRowContainer>
    );
};
export default MorningNotesRow;
