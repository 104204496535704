import styled from "styled-components";

export default styled.div`
    .votingSessionDate {
        font-size: 12px;
    }

    .session-selector {
        max-width: 500px;
        min-width: 400px;
    }
`;
