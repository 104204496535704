import { useEffect, useState } from "react";

//Components
import EditableCell from "./EditableCell/EditableCell";
import SearchOptions from "components/Common/SearchOptions/SearchOptions";
import Table from "components/Common/Table/Table";
import TableCell from "components/Common/TableCell/TableCell";

// Context
import { useSettings } from "context/Settings.context";

//Styles
import ValidationRulesContainer from "./ValidationRules.styles";

export default function ValidationRules() {
    const { setShowLoadingToast } = useSettings();

    const [filterQuery, setFilterQuery] = useState<string>("");
    const [reload, setReload] = useState(0);
    const [tableIsLoading, setTableIsLoading] = useState<boolean>(false);

    const selectOptions = [
        {
            label: "Info",
            value: "Info",
            color: "info",
        },
        {
            label: "Warning",
            value: "Warning",
            color: "warning",
        },
        {
            label: "Critical",
            value: "Critical",
            color: "critical",
        },
        {
            label: "NULL",
            value: "",
        },
    ];

    const columns = [
        {
            id: 1,
            title: "METRIC ID",
            sortable: true,
            value: "v_metric_id",
            cell: (data: string) => <TableCell data={data} />,
            show: true,
        },
        {
            id: 14,
            title: "METRIC TYPE",
            sortable: true,
            value: "v_data_type_assumed_raw",
            cell: (data: string) => <TableCell data={data} />,
            show: true,
        },
        {
            id: 2,
            title: "SOURCE",
            sortable: true,
            value: "v_source",
            cell: (data: string) => <TableCell data={data} />,
            show: true,
        },
        // {
        //     id: 3,
        //     title: "DATA TYPE ENFORCED ERROR TYPE",
        //     sortable: true,
        //     value: "v_data_type_enforced_errort",
        //     cell: (data: string) => (
        //         <EditableCell
        //             initialValue={data}
        //             selectOptions={selectOptions}
        //             type="selectOptions"
        //         />
        //     ),
        //     show: true,
        // },
        {
            id: 4,
            title: "CAN BE BLANK OR MISSING ERROR SEVERITY",
            sortable: true,
            value: "v_can_be_blank_or_missing_errort",
            cell: (data: any) => (
                <EditableCell
                    rowId={data.validation_rule_id}
                    cellValueType={data.v_data_type_assumed_raw}
                    validateCellValueTypeBy="text"
                    cellValue="v_can_be_blank_or_missing_errort"
                    rowIdKey="validation_rule_id"
                    startDateKey="rule_start_date"
                    initialValue={data.v_can_be_blank_or_missing_errort}
                    selectOptions={selectOptions}
                    type="selectOptions"
                    apiUrl="api/validation/update"
                    updateTable={() => setReload(reload + 1)}
                    setTableIsLoading={setTableIsLoading}
                    tableIsLoading={tableIsLoading}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 5,
            title: "CAN BE ERROR ERROR SEVERITY",
            sortable: true,
            value: "v_can_be_error_errort",
            cell: (data: any) => (
                <EditableCell
                    cellValueType={data.v_data_type_assumed_raw}
                    validateCellValueTypeBy="text"
                    cellValue="v_can_be_error_errort"
                    rowId={data.validation_rule_id}
                    rowIdKey="validation_rule_id"
                    startDateKey="rule_start_date"
                    initialValue={data.v_can_be_error_errort}
                    selectOptions={selectOptions}
                    type="selectOptions"
                    apiUrl="api/validation/update"
                    updateTable={() => setReload(reload + 1)}
                    setTableIsLoading={setTableIsLoading}
                    tableIsLoading={tableIsLoading}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 6,
            title: "MIN VALUE",
            sortable: true,
            value: "v_min_value",
            cell: (data: any) => (
                <EditableCell
                    cellValueType={data.v_data_type_assumed_raw}
                    validateCellValueTypeBy="text"
                    cellValue="v_min_value"
                    rowId={data.validation_rule_id}
                    rowIdKey="validation_rule_id"
                    startDateKey="rule_start_date"
                    initialValue={data.v_min_value}
                    selectOptions={selectOptions}
                    type="numericalField"
                    apiUrl="api/validation/update"
                    updateTable={() => setReload(reload + 1)}
                    setTableIsLoading={setTableIsLoading}
                    tableIsLoading={tableIsLoading}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 7,
            title: "MIN VALUE ERROR SEVERITY",
            sortable: true,
            value: "v_min_value_errort",
            cell: (data: any) => (
                <EditableCell
                    cellValueType={data.v_data_type_assumed_raw}
                    validateCellValueTypeBy="text"
                    cellValue="v_min_value_errort"
                    rowId={data.validation_rule_id}
                    rowIdKey="validation_rule_id"
                    startDateKey="rule_start_date"
                    initialValue={data.v_min_value_errort}
                    selectOptions={selectOptions}
                    type="selectOptions"
                    apiUrl="api/validation/update"
                    updateTable={() => setReload(reload + 1)}
                    setTableIsLoading={setTableIsLoading}
                    tableIsLoading={tableIsLoading}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 8,
            title: "MAX VALUE",
            sortable: true,
            value: "v_max_value",
            cell: (data: any) => (
                <EditableCell
                    cellValueType={data.v_data_type_assumed_raw}
                    validateCellValueTypeBy="text"
                    cellValue="v_max_value"
                    rowId={data.validation_rule_id}
                    rowIdKey="validation_rule_id"
                    startDateKey="rule_start_date"
                    initialValue={data.v_max_value}
                    selectOptions={selectOptions}
                    type="numericalField"
                    apiUrl="api/validation/update"
                    updateTable={() => setReload(reload + 1)}
                    setTableIsLoading={setTableIsLoading}
                    tableIsLoading={tableIsLoading}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 9,
            title: "MAX VALUE ERROR SEVERITY",
            sortable: true,
            value: "v_max_value_errort",
            cell: (data: any) => (
                <EditableCell
                    cellValueType={data.v_data_type_assumed_raw}
                    validateCellValueTypeBy="text"
                    cellValue="v_max_value_errort"
                    rowId={data.validation_rule_id}
                    rowIdKey="validation_rule_id"
                    startDateKey="rule_start_date"
                    initialValue={data.v_max_value_errort}
                    selectOptions={selectOptions}
                    type="selectOptions"
                    apiUrl="api/validation/update"
                    updateTable={() => setReload(reload + 1)}
                    setTableIsLoading={setTableIsLoading}
                    tableIsLoading={tableIsLoading}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 10,
            title: "MODEL DELTA MIN VALUE",
            sortable: true,
            value: "v_model_delta_min_value",
            cell: (data: any) => (
                <EditableCell
                    cellValueType={data.v_data_type_assumed_raw}
                    validateCellValueTypeBy="text"
                    cellValue="v_model_delta_min_value"
                    rowId={data.validation_rule_id}
                    rowIdKey="validation_rule_id"
                    startDateKey="rule_start_date"
                    initialValue={data.v_model_delta_min_value}
                    selectOptions={selectOptions}
                    type="numericalField"
                    apiUrl="api/validation/update"
                    updateTable={() => setReload(reload + 1)}
                    setTableIsLoading={setTableIsLoading}
                    tableIsLoading={tableIsLoading}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 11,
            title: "MODEL DELTA MIN VALUE ERROR SEVERITY",
            sortable: true,
            value: "v_model_delta_min_value_errort",
            cell: (data: any) => (
                <EditableCell
                    cellValueType={data.v_data_type_assumed_raw}
                    validateCellValueTypeBy="text"
                    cellValue="v_model_delta_min_value_errort"
                    rowId={data.validation_rule_id}
                    rowIdKey="validation_rule_id"
                    startDateKey="rule_start_date"
                    initialValue={data.v_model_delta_min_value_errort}
                    selectOptions={selectOptions}
                    type="selectOptions"
                    apiUrl="api/validation/update"
                    updateTable={() => setReload(reload + 1)}
                    setTableIsLoading={setTableIsLoading}
                    tableIsLoading={tableIsLoading}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 12,
            title: "MODEL DELTA MAX VALUE",
            sortable: true,
            value: "v_model_delta_max_value",
            cell: (data: any) => (
                <EditableCell
                    cellValueType={data.v_data_type_assumed_raw}
                    validateCellValueTypeBy="text"
                    cellValue="v_model_delta_max_value"
                    rowId={data.validation_rule_id}
                    rowIdKey="validation_rule_id"
                    startDateKey="rule_start_date"
                    initialValue={data.v_model_delta_max_value}
                    selectOptions={selectOptions}
                    type="numericalField"
                    apiUrl="api/validation/update"
                    updateTable={() => setReload(reload + 1)}
                    setTableIsLoading={setTableIsLoading}
                    tableIsLoading={tableIsLoading}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 13,
            title: "MODEL DELTA MAX VALUE ERROR SEVERITY",
            sortable: true,
            value: "v_model_delta_max_value_errort",
            cell: (data: any) => (
                <EditableCell
                    cellValueType={data.v_data_type_assumed_raw}
                    validateCellValueTypeBy="text"
                    cellValue="v_model_delta_max_value_errort"
                    rowId={data.validation_rule_id}
                    rowIdKey="validation_rule_id"
                    startDateKey="rule_start_date"
                    initialValue={data.v_model_delta_max_value_errort}
                    selectOptions={selectOptions}
                    type="selectOptions"
                    apiUrl="api/validation/update"
                    updateTable={() => setReload(reload + 1)}
                    setTableIsLoading={setTableIsLoading}
                    tableIsLoading={tableIsLoading}
                />
            ),
            fullDataRow: true,
            show: true,
        },
    ];

    useEffect(() => {
        if (tableIsLoading) {
            setShowLoadingToast({
                show: true,
                message: "UPDATING",
            });
        } else {
            setShowLoadingToast({
                show: false,
                message: "",
            });
        }
    }, [tableIsLoading]);

    const filterOption = [
        {
            id: 1,
            name: "METRIC ID",
            value: "v_metric_id",
            type: "SEARCH",
        },
    ];

    return (
        <ValidationRulesContainer>
            <SearchOptions
                setFilterQuery={setFilterQuery}
                options={filterOption}
                forceUpperCase
            />
            <Table
                url={"api/validation"}
                columns={columns}
                filterQuery={filterQuery}
                defaultSortKey="v_metric_id"
                defaultSortOrder="DESC"
                emptyText="NO VALIDATION RULES MATCH YOUR SEARCH"
                reload={reload}
                pagination
                large
            />
        </ValidationRulesContainer>
    );
}
