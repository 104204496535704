import {faArrowRightFromFile, faCheckCircle, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import Button from "components/Common/Button/Button";
import Table from "components/Common/Table/Table";
import TableCell from "components/Common/TableCell/TableCell";
import React, {useEffect, useState} from "react";

// Styles
import ChangeLogTabContainer from "./ChangeLogTab.styles";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import AlertsTab from "../AlertsTab/AlertsTab";
import BaseTab from "../_BaseTab/_BaseTab";

interface ChangeLogTabProps {
    checkName: string;
}

const getChangeLogUrl = (checkName: string) => `api/nav/alerts/${checkName}/history/`

const ChangeLogTab = ({checkName}: ChangeLogTabProps) => {
    const [url, setUrl] = useState<string>("");

     useEffect(() => {
       setUrl(getChangeLogUrl(checkName))
    }, []);

    return (
        <ChangeLogTabContainer>
            <div className="actionsDiv">
                <div>
                    <p className={"sectionTitle"}>CHANGE LOG</p>
                    <p className="title mb20">
                        Identify analyst and timestamp for any user input
                        changes
                    </p>
                </div>
            </div>
            <div className="tableDiv">
                <BaseTab tableType={'log'} checkName={checkName} baseUrl={url}/>
            </div>
        </ChangeLogTabContainer>
    );
};
export default ChangeLogTab;
