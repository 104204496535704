import {hardLogout} from "utils/user.utils";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faSignOut} from "@fortawesome/free-solid-svg-icons";

// Material UI
import Tooltip from "@mui/material/Tooltip";

// Styles
import LogoutContainer from "./Logout.styles";

export default function Logout() {

    return (
        <LogoutContainer onClick={hardLogout} id={"menuLogout"}>
            <Tooltip title={"Log out"} placement="right">
                <div className={"logout-icon"}>
                    <FontAwesomeIcon icon={faSignOut}></FontAwesomeIcon>
                </div>
            </Tooltip>
        </LogoutContainer>
    );
}
