// Components
import PageTitle from "components/Common/PageTitle/PageTitle";
import DataDictionary from "components/Screens/Data/DataDictionary/DataDictionary/DataDictionary";

// Stlyes
import DataDictionaryContainer from "./DataDictionary.styles";

const DataDictionaryPage: React.FC = () => {
    return (
        <DataDictionaryContainer>
            <PageTitle title="DATA DICTIONARY" />
            <DataDictionary />
        </DataDictionaryContainer>
    );
};

export default DataDictionaryPage;
