import styled from "styled-components";
import colors from "assets/styles/colors";

export default styled.div`
    .root {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-grow: 1;
    }

    .table-container {
        width: 49%;
    }

    .title {
        letter-spacing: 0.1em;
        color: ${colors.textNavy};
        margin: 20px 0 10px 0;
    }
`;
