import styled from "styled-components";
import colors from "assets/styles/colors";

export default styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-grow: 1;

    h3 {
        margin: 50px 0;
        color: ${colors.textNavy};
        letter-spacing: 0.1em;
    }
`;
