import styled from "styled-components";

export default styled.div`
    display: flex;
    flex-direction: column;

    margin-top: 20px;
    margin-bottom: 20px;

    .heading {
        display: flex;
        justify-content: space-between;

        padding: 0px 15px 0px 15px;

        align-items: center;

        min-height: 50px;
        max-height: 50px;

        border-radius: 5px 5px 0 0;

        background-color: #004453;
        color: white;

        font-size: 20px;

        .end {
            display: flex;

            justify-content: space-between;
            align-items: center;

            min-width: 50px;

            .MuiSwitch-thumb {
                color: white;
            }
            .MuiSwitch-track {
                background-color: white !important;
            }
        }
    }

    .userRow {
        display: flex;
        flex-direction: row;

        min-height: 50px;
        max-height: 50px;

        min-width: 100%;

        border: 1px solid #eeeeee;

        cursor: pointer;

        .icon {
            display: flex;
            justify-content: center;
            align-items: center;

            min-height: 50px;
            max-height: 50px;
            min-width: 50px;
            max-width: 50px;

            color: #dddddd;

            &.true {
                color: #004453;
            }
        }

        .name {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            flex-grow: 1;
            min-height: 50px;
            max-height: 50px;

            color: #999999;

            &.true {
                color: black;
            }
        }

        .MuiSwitch-thumb {
            color: #004453;
        }
        .MuiSwitch-track {
            background-color: #004453 !important;
        }
    }
`;
