import { useAuth } from "context/Auth.context";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { getGroupTabs, reduceConsecutiveStrings } from "./SubTabHelpers";
// Styles
import SubTabsContainer from "./SubTabs.styles";
interface SubTabsProps {
    defaultTab: string;
    setTab?: Function;
    subTabs: SubTab[];
    dontChangeUrl?: boolean;
}

type SubTab = {
    title: string;
    value: string;
    content: React.ReactNode;
    permissions?: string[];
    icon?: React.ReactNode;
    hide?: boolean;
    groupKey?: string;
};

const SubTabs = ({
    defaultTab,
    setTab = () => {},
    subTabs,
    dontChangeUrl,
}: SubTabsProps) => {
    const history = useHistory();
    const { user } = useAuth();
    const perms = user.permissions;
    const divRefs = useRef<(HTMLDivElement | null)[]>([]);
    const [widths, setWidths] = useState<(number | undefined)[]>([]);
    const [filteredSubTabs, setFilteredSubTabs] = useState<SubTab[]>([]);
    const [selectedSubTab, setSelectedSubTab] = useState<string>("");

    const setNewRoute = (value: string) => {
        if (!dontChangeUrl) {
            const urlParams = new URLSearchParams(window.location.search);
            urlParams.set("subTab", value);
            let url = history.location.pathname + "?" + urlParams.toString();
            history.push(url);
        }
        setSelectedSubTab(value);
        setTab(value);
    };

    const filterSubTabs = () => {
        let filteredSubTabs: SubTab[] = [];
        subTabs.forEach((tab) => {
            if (!tab.hide) {
                if (!tab.permissions) {
                    filteredSubTabs.push(tab);
                } else if (
                    perms.some((perm: string) =>
                        tab.permissions!.includes(perm)
                    )
                ) {
                    filteredSubTabs.push(tab);
                }
            }
        });
        setFilteredSubTabs(filteredSubTabs);
    };

    useEffect(() => {
        filterSubTabs();
        setWidths(divRefs.current.map((div) => div?.offsetWidth));
    }, [subTabs]);

    useEffect(() => {
        setSelectedSubTab(defaultTab);
    }, [defaultTab]);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        let subTab = urlParams.get("subTab");
        setSelectedSubTab(subTab ? subTab : defaultTab);
    }, [history]);

    return (
        <SubTabsContainer className="tabs">
            <div className="tabsBar groupBar">
                {reduceConsecutiveStrings(
                    filteredSubTabs.map((tab: SubTab) => tab.groupKey || "")
                ).map((tab, index) => {
                    return <span key={index}>{getGroupTabs(tab, widths)}</span>;
                })}
            </div>
            <div className="tabsBar">
                {filteredSubTabs.map((item, index) => (
                    <div
                        data-cy="tab-item"
                        className={
                            selectedSubTab === item.value
                                ? "active tabItem"
                                : "tabItem"
                        }
                        key={index}
                        onClick={() => setNewRoute(item.value)}
                        ref={(element) => (divRefs.current[index] = element)}
                    >
                        <p>{item.title}</p>
                        {item.icon && item.icon}
                    </div>
                ))}
                <div className="spacer"></div>
            </div>
            <div className="tabsContent">
                {selectedSubTab &&
                    subTabs.find((tab: SubTab) => tab.value === selectedSubTab)!
                        .content}
            </div>
        </SubTabsContainer>
    );
};
export default SubTabs;
