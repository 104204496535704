export const scenarioInputs = [
    {
        id: 1,
        name: "bm",
        label: "Benchmark Growth",
        tooltip: "Input the quarter % in decimals: i.e if you want a 3% Benchmark increase in a quarter then input 0.03 in the quarter . To note due to compounding if you input for example 3% in Q1 and 3% in Q3 the movement for the year would be 1.03^2 = 6.09%"
    },
    {
        id: 2,
        name: "alpha",
        label: "Alpha",
        tooltip: "Alpha is the outperformance of a fund/separate account (\"account\") versus the Benchmark. Alpha + Benchmark Growth = account return for the period. e.g. if you want outpeformance of 3% in a quarter and want total return of an account to be 6% in absolute terms for that quarter: Input 0.03 in the Benchmark Growth quarter field and 0.03 in Alpha quarter field."
    },
    {
        id: 3,
        name: "redemptions",
        label: "Redemptions",
        tooltip: "Also displayed in decimals. Assumes each client redeems the % amount indicated. Example: if you would like to indicate a redemption of 10% in a quarter enter 0.1. This will reduce each clients holding by 10% in that quarter."
    },
    // {
    //     id: 4,
    //     name: "fx",
    //     label: "FX: USD-GBP",
    //     tooltip: "FX: USD-GBP Q1-Q12 (number)"
    // },
];
