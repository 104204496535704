// Styles
import TextAreaContainer from "./TextArea.styles";

type Props = {
    label: string;
    value: string;
    change: Function;
    rows: number;
    placeholder: string;
    disabled?: boolean;
};

export default function TextArea({
    label,
    value,
    change,
    rows,
    placeholder,
    disabled,
}: Props) {
    return (
        <TextAreaContainer className="textArea">
            {label && <label>{label}</label>}
            <textarea
                value={value}
                onChange={(e) => change(e.target.value)}
                rows={rows}
                placeholder={placeholder}
                disabled={disabled}
            />
        </TextAreaContainer>
    );
}
