import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';


interface Props {
    steps: string[];
    selectedStep: string;
}


export default function HorizontalStepper({steps, selectedStep}: Props) {
    const [currentStepIndex, setCurrentStepIndex] = useState(0) ;

    const setStepIndex = () => {
        let index = steps.indexOf(selectedStep);

        if (index < 0) {
            setCurrentStepIndex(0);
        } else {
            setCurrentStepIndex(index);
        }
    }

    useEffect(() => {
        setStepIndex();
    }, [selectedStep]);

    return (
        <Box sx={{ width: '100%' }}>
            <Stepper activeStep={currentStepIndex} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
    )
}
