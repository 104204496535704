import styled from 'styled-components';

export default styled.div`

    display: inline-block;
    width: 18%;
    height: 100px;
    border-radius: 5px;
    padding: 5px;
    margin: 10px;

    border: 1px solid ${(props) => props.theme.homeListBodyBorderColor};

    .title {
        font-size: 14px;
        font-weight: bold;
        border-radius: 5px;
        padding: 5px;
        background-color: ${(props) => props.theme.homeListBodyBorderColor};
    }

    .dataContainer {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .data {
        color: ${(props) => props.theme.pageTitleText};
        font-weight: bold;
        font-size: 20px;
        margin-top: 20px;
        align: middle;
        justify-content: center;
    }

`;