import styled from "styled-components";

export default styled.div`
    .intro {
        color: #666666;
        line-height: 1.8em;
        margin-bottom: 20px;
    }

    .columns {
        display: flex;
        flex-direction: row;

        min-width: 100%;
        max-width: 100%;

        margin: 20px -10px;

        .half {
            display: flex;
            flex-direction: column;

            min-width: 50%;
            max-width: 50%;

            padding: 0 10px;
        }
    }

    .tabItem {
        :hover {
            svg {
                margin-top: 2px;
            }
        }
    }

    .tabIcon {
        display: flex;

        margin-left: 5px;
        margin-bottom: 3px;

        color: ${(props) => props.theme.accentButtonBackground};

        &.complete {
            color: ${(props) => props.theme.primaryButtonBackground};
        }
    }
`;
