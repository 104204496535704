import styled from "styled-components";
import colors from "assets/styles/colors";

export default styled.div`
    .button-container{
        display: flex;
        justify-content: flex-start;
        margin-left: -10px;
    }
`;
