import * as Database from "../utils/database.utils";
import {useSettings} from "../context/Settings.context";

export class ApiHandler {
    endpoint: string;
    setShowLoadingToast: any;
    setToast: any;

    constructor(url: string, setShowLoadingToast: any = null, setToast: any = null) {
        this.endpoint = url;
        this.setShowLoadingToast = setShowLoadingToast;
        this.setToast = setToast;
    }

    list = async () => {
        return await this.dispatcher("list");
    };

    get = async () => {
        return await this.dispatcher("get");
    };

    put = async (data: any) => {
        return await this.dispatcher("put", data);
    };

    post = async (data: any) => {
        return await this.dispatcher("post", data);
    };

    delete = async () => {
        return await this.dispatcher("delete");
    }

    private dispatcher = async (method: string, data?: any) => {
        let response: any;

        if (method == "list") {
           response = await this._list();
        } else if (method == "get") {
           response = await this._get();
        } else if (method == "put") {
           response =  await this._put(data);
        } else if (method == "post") {
           response = await this._post(data);
        } else if (method == "delete") {
            response = await this._delete();
        }

        return response;
    };

    private _list = async () => {
        this.setShowLoadingToast && this.setShowLoadingToast({ show: true, message: "LOADING" });
        const result = await Database.get(this.endpoint);
        this.setShowLoadingToast && this.setShowLoadingToast({ show: false});

        let response = await result.json();

        if (result.status === 200) {
            // Disabled because it can hide an issue with a previous api call
            // await this.showSuccessMessage("Loaded successfully", result);
        } else {
            await this.showErrorMessage(response);
        }

        return response;
    };

    private _get = async () => {
        this.setShowLoadingToast && this.setShowLoadingToast({ show: true, message: "LOADING" });
        const result = await Database.get(this.endpoint);
        this.setShowLoadingToast && this.setShowLoadingToast({ show: false});

        let response = await result.json();

        if (result.status === 200) {
            // Disabled because it can hide an issue with a previous api call
            // await this.showSuccessMessage("Loaded successfully", result);
        } else {
            await this.showErrorMessage(response);
        }

        return response;
    };

    private _post = async (data: any) => {
        const result = await Database.post(this.endpoint, data);

        let response = await result.json();

        if (result.status === 200) {
            await this.showSuccessMessage("Saved successfully", response);
        } else {
             await this.showErrorMessage(response);
        }

        return response;
    };

    private _put = async (data: any) => {
        this.setShowLoadingToast && this.setShowLoadingToast({ show: true, message: "UPDATING" });
        const result = await Database.put(this.endpoint, data);
        this.setShowLoadingToast && this.setShowLoadingToast({ show: false});

        let response = await result.json();

        if (result.status === 200) {
            await this.showSuccessMessage("Updated successfully", response);
        } else {
            await this.showErrorMessage(response);
        }

        return response;
    };

    private _delete = async () => {
        this.setShowLoadingToast && this.setShowLoadingToast({ show: true, message: "DELETING" });
        const result = await Database.remove(this.endpoint);
        this.setShowLoadingToast && this.setShowLoadingToast({ show: false});

        let response = await result.json();

        if (result.status === 200) {
            await this.showSuccessMessage("Deleted successfully", response);
        } else {
            await this.showErrorMessage(response);
        }

        return response;
    };

    private showSuccessMessage = async (message: string, response: any) => {
        let config = {
            show: true,
            type: "success",
            message: message,
        };

        // use the message from the response if it exists
        if ("message" in response) {
            config.message = response.message;
        }

        this.setToast && this.setToast(config);
    }

    private showErrorMessage = async (response: any) => {
        let config = {
            show: true,
            type: "error",
            message: "Something went wrong",
        };

        // use the message from the response if it exists
        if ("message" in response) {
            config.message = response.message;
        }

        this.setToast && this.setToast(config);
    }
}
