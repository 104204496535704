import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// MaterialUI
import { Tooltip } from "@mui/material";

// FontAwesome
import {
    faCalendar,
    faChair,
    faDownload,
    faPlus,
    faSquarePollVertical,
    faThumbsUp,
    faTrash,
    faUser,
    faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Components
import Alert from "components/Common/Alert/Alert";
import Button from "components/Common/Button/Button";
import ButtonSmall from "components/Common/ButtonSmall/ButtonSmall";
import Can from "components/Common/Can/Can";
import PageTitle from "components/Common/PageTitle/PageTitle";

// Context
import { useAuth } from "context/Auth.context";
import { useBQMQ } from "context/BQMQ.context";
import { useSettings } from "context/Settings.context";

// Utils
import { applyTimeZoneOffset } from "utils/common.utils";
import * as Database from "utils/database.utils";
import { exportCompanyBQMQData } from "utils/export.utils";
import { toDate, toDateAndTime } from "utils/general.utils";

// Data
import { teamLabelDict } from "assets/data/lists";

// Styles
import InviteAnalystList from "../InvitedUserModal/InviteAnalystList/InviteAnalystList";
import ResultsHeaderContainer from "./ResultsHeader.styles";

type Props = {
    sessionData: BQMQSessionExtended;
    reload: () => void;
    companyResults?: boolean;
};
const shouldVote = (sessionData: BQMQSessionExtended, user: Analyst) => {
    const participants = [...sessionData?.core, ...sessionData?.analysts];
    return participants.some(
        (participant: Analyst) => participant.id === user.id
    );
};

const hasVoted = (sessionData: BQMQSessionExtended, user: Analyst) => {
    const voted = sessionData?.session?.voters.some(
        (voter: any) => voter === user.id
    );
    return voted;
};

export default function ResultsHeader({ sessionData, reload }: Props) {
    const [load, setLoad] = useState<boolean>(false);
    const history = useHistory();
    const { user } = useAuth();
    const { sessionID, setShowFullUserList, getDropDowns } = useBQMQ();
    const { setShowLoadingToast, setToast } = useSettings();
    const [showDeleteSessionModal, setShowDeleteSessionModal] =
        useState<boolean>(false);
    const [showInviteAnalystScreen, setShowInviteAnalystScreen] =
        useState<boolean>(false);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);

    const exportToCSV = async () => {
        const exportName = `${
            sessionData?.session.company.name
        } - ${toDateAndTime(
            sessionData?.session.date ? sessionData.session.date : Date.now()
        )}`;
        const exportBody = {
            start_date: null,
            end_date: null,
            only_current_employees: null,
            only_latest_vote: null,
            analyst_id: null,
            company_id: sessionData?.session.company.id
                ? sessionData.session.company.id
                : null,
            session_id: sessionData?.session.id ? sessionData.session.id : null,
        };
        await exportCompanyBQMQData(
            exportName,
            exportBody,
            setShowLoadingToast,
            setToast
        );
    };

    const deleteSession = async () => {
        setIsDeleting(true);

        const res = await Database.remove(
            "api/bqmq_voting/session/delete/" + sessionData?.session.id
        );

        if (res.ok) {
            setShowDeleteSessionModal(false);

            if (window.location.pathname === "/voting/result") {
                history.push("/voting");
            }

            setToast({
                show: true,
                type: "success",
                message: "Session Deleted",
            });
            getDropDowns();
        } else {
            setIsDeleting(false);

            setToast({
                show: true,
                type: "error",
                message: "Error Deleting Session",
            });
        }
    };

    const emailSessionResults = async () => {
        setShowLoadingToast({
            show: true,
            message: "SENDING RESULTS",
        });
        const result = await Database.post("api/bqmq_voting/results/release", {
            session_id: sessionData?.session.id ? sessionData.session.id : null,
            company_id: sessionData?.session.company.id
                ? sessionData.session.company.id
                : null,
            company_name: sessionData?.session.company.name
                ? sessionData.session.company.name
                : null,
        });
        if (result.ok) {
            setToast({
                show: true,
                type: "success",
                message: "Results Sent",
            });
        } else {
            setToast({
                show: true,
                type: "error",
                message: "Error Sending Results",
            });
        }
        setShowLoadingToast({
            show: false,
            message: "",
        });
    };

    useEffect(() => {
        if (sessionData) {
            setLoad(Object.keys(sessionData.session).length > 0);
        }
    }, [sessionData]);

    const getTeam = () => {
        // @ts-ignore
        return teamLabelDict[
            sessionData?.session?.company?.sector?.name.toUpperCase()
        ];
    };

    return (
        <>
            {load && (
                <ResultsHeaderContainer>
                    {sessionData && (
                        <PageTitle
                            title={
                                <div className={"resultsTitle"}>
                                    <h1>
                                        {sessionData?.session?.company.name
                                            ? sessionData.session.company.name.toUpperCase()
                                            : "LOADING"}
                                    </h1>
                                </div>
                            }
                            actions={
                                <>
                                    {sessionData &&
                                        shouldVote(sessionData, user) &&
                                        hasVoted(sessionData, user) && (
                                            <Can
                                                yes={
                                                    <Button
                                                        color="accent"
                                                        className={"hideSM"}
                                                        text={"EDIT VOTES"}
                                                        click={() =>
                                                            history.replace(
                                                                `/voting/vote?company_name=${sessionData.session.company.name}&company_id=${sessionData.session.company.id}&editing=true&session_id=${sessionData.session.id}`
                                                            )
                                                        }
                                                    />
                                                }
                                                permissions={[
                                                    "p_bqmq_can_vote",
                                                ]}
                                            />
                                        )}

                                    {!sessionID &&
                                        shouldVote(sessionData, user) &&
                                        !hasVoted(sessionData, user) && (
                                            <Can
                                                yes={
                                                    <Button
                                                        color="accent"
                                                        className={"hideSM"}
                                                        text={"VOTE"}
                                                        click={() =>
                                                            history.push(
                                                                `/voting/vote?company_name=${sessionData.session.company.name}&session_id=${sessionData.session.id}&editing=false&date=${sessionData.session.date}`
                                                            )
                                                        }
                                                    />
                                                }
                                                permissions={[
                                                    "p_bqmq_can_vote",
                                                ]}
                                            />
                                        )}

                                    <Can
                                        yes={
                                            <ButtonSmall
                                                text={"ADD USER"}
                                                icon={faPlus}
                                                color={"third"}
                                                click={() =>
                                                    setShowInviteAnalystScreen(
                                                        true
                                                    )
                                                }
                                            />
                                        }
                                        permissions={["p_bqmq_can_vote"]}
                                    />
                                    <Can
                                        yes={
                                            <ButtonSmall
                                                data-cy="invited-attendees-button"
                                                className={"hideSM"}
                                                color={"third"}
                                                icon={faUsers}
                                                text="INVITED ATTENDEES"
                                                click={() =>
                                                    setShowFullUserList(true)
                                                }
                                            />
                                        }
                                        permissions={["p_bqmq_can_vote"]}
                                    />

                                    <Can
                                        yes={
                                            <ButtonSmall
                                                className={
                                                    "hideSM voting-history-button"
                                                }
                                                icon={faCalendar}
                                                text={"VOTING HISTORY"}
                                                color={"third"}
                                                click={() =>
                                                    history.push(
                                                        `/voting/company?id=${sessionData.session.company?.id}&name=${sessionData.session.company.name}`
                                                    )
                                                }
                                            />
                                        }
                                        permissions={["p_bqmq_can_vote"]}
                                    />
                                    <Can
                                        yes={
                                            <ButtonSmall
                                                className={"hideSM"}
                                                icon={faSquarePollVertical}
                                                text={"SEND RESULTS EMAIL"}
                                                click={() =>
                                                    emailSessionResults()
                                                }
                                            />
                                        }
                                        permissions={[
                                            "p_bqmq_can_create_session",
                                        ]}
                                    />
                                    <ButtonSmall
                                        className={"hideSM"}
                                        text={"EXPORT DATA"}
                                        icon={faDownload}
                                        click={() => exportToCSV()}
                                    />
                                    <Can
                                        yes={
                                            <ButtonSmall
                                                className={"hideSM"}
                                                text={"DELETE SESSION"}
                                                icon={faTrash}
                                                color={"accent"}
                                                click={() =>
                                                    setShowDeleteSessionModal(
                                                        true
                                                    )
                                                }
                                            />
                                        }
                                        permissions={[
                                            "p_bqmq_can_delete_session",
                                        ]}
                                    />
                                </>
                            }
                        />
                    )}
                    {sessionData && (
                        <div className={"details"}>
                            {sessionData?.session?.company?.sector?.text && (
                                <Tooltip title={<h2>TEAM</h2>}>
                                    <div className={"detail"}>
                                        <FontAwesomeIcon
                                            icon={faUsers}
                                            fixedWidth
                                        />
                                        <p className={"capitalize"}>
                                            {getTeam()}
                                        </p>
                                    </div>
                                </Tooltip>
                            )}

                            {sessionData?.session?.presenter.name && (
                                <Tooltip title={<h2>ANALYST</h2>}>
                                    <div className={"detail"}>
                                        <FontAwesomeIcon
                                            icon={faUser}
                                            fixedWidth
                                        />
                                        <p>
                                            {sessionData.session.presenter.name}
                                        </p>
                                    </div>
                                </Tooltip>
                            )}

                            {sessionData?.session?.host.name && (
                                <Tooltip title={<h2>CHAIR</h2>}>
                                    <div className={"detail"}>
                                        <FontAwesomeIcon
                                            icon={faChair}
                                            fixedWidth
                                        />
                                        <p>{sessionData.session.host.name}</p>
                                    </div>
                                </Tooltip>
                            )}

                            {sessionData?.session?.date && (
                                <Tooltip
                                    title={
                                        <h2>
                                            {"MEETING DATE: " +
                                                applyTimeZoneOffset(
                                                    sessionData.session.date
                                                )}
                                        </h2>
                                    }
                                >
                                    <div className={"detail"}>
                                        <FontAwesomeIcon
                                            icon={faCalendar}
                                            fixedWidth
                                        />
                                        <p>
                                            {sessionData.session.date
                                                ? toDate(
                                                      sessionData.session.date
                                                  )
                                                : "-"}
                                        </p>
                                    </div>
                                </Tooltip>
                            )}

                            {Object.keys(sessionData.session).length > 0 && (
                                <Tooltip title={<h2>ATTENDEES VOTED</h2>}>
                                    <div className={"detail"}>
                                        <FontAwesomeIcon
                                            icon={faThumbsUp}
                                            fixedWidth
                                        />
                                        <p>
                                            {sessionData.session.voters.length +
                                                " / " +
                                                [
                                                    ...sessionData.core,
                                                    ...sessionData.analysts,
                                                ].length}
                                        </p>
                                    </div>
                                </Tooltip>
                            )}
                        </div>
                    )}

                    {showInviteAnalystScreen && (
                        <InviteAnalystList
                            setShowInviteAnalystScreen={
                                setShowInviteAnalystScreen
                            }
                            setShowFullUserList={setShowFullUserList}
                            currentUsers={[
                                ...sessionData.core,
                                ...sessionData.analysts,
                            ]}
                            session={sessionData}
                            reload={reload}
                        />
                    )}

                    {showDeleteSessionModal && (
                        <Alert>
                            <h2>{"DELETE SESSION"}</h2>

                            <p>
                                Are you sure you would like to delete this
                                session?
                            </p>

                            <Button
                                text={isDeleting ? "DELETING..." : "DELETE"}
                                color="accent"
                                click={deleteSession}
                                disabled={isDeleting}
                            />
                            <Button
                                text="CANCEL"
                                color="flat"
                                click={() => setShowDeleteSessionModal(false)}
                            />
                        </Alert>
                    )}
                </ResultsHeaderContainer>
            )}
        </>
    );
}
