import styled from "styled-components";

export default styled.div`
    background-color: ${(props) => props.theme.mainBackground};
    color: ${(props) => props.theme.textPrimary};

    &.bqmqBanner {
        padding-top: 70px;
    }
`;
