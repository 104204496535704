import { useHistory } from "react-router-dom";

// Components
import Can from "components/Common/Can/Can";

// Styles
import FastLinkContainer from "./FastLink.styles";

type Props = {
    children: any;
    link: string;
    disabled?: boolean;
    permissions?: string[];
};

export default function FastLink({
    children,
    link,
    disabled = false,
    permissions = [],
}: Props) {
    const history = useHistory();

    const handleClick = () => {
        if (!disabled) history.push(link);
    };

    return (
        <Can
            yes={
                <FastLinkContainer
                    onClick={handleClick}
                    className={disabled ? "disabled" : ""}
                >
                    <div className={"fastLinkMain"}>{children}</div>
                </FastLinkContainer>
            }
            permissions={permissions}
        />
    );
}
