import styled from 'styled-components';

export default styled.div`
    .btn-close-container {
        display:flex;
        justify-content: flex-end;
        padding-top: 8px;
    }
    .btn-add-container {
        display: flex;
        justify-content: flex-start;
        padding: 10px;
    }
`;
