import styled from "styled-components";

export default styled.div`
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;

    position: fixed;

    top: 0;
    left: 0;

    background-color: rgba(0, 0, 0, 0.5);
    z-index: 50;

    display: flex;
    justify-content: center;
    align-items: center;

    .table {
        margin-bottom: 0;
    }
`;
