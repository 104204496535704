import React from "react";

// Components
import Button from "components/Common/Button/Button";

// Styles
import ErrorBoundaryContainer from "./ErrorBoundary.styles";

// Logger
import Logger from "utils/logger.utils";
const Log = new Logger();
Log.init("ErrorBoundary");

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }



    render() {
        if (this.state.hasError) {
            return (
                <ErrorBoundaryContainer>
                    <h3>SOMETHING WENT WRONG</h3>
                    {this.props.goBack && (
                        <Button
                            text="GO BACK"
                            click={() => window.history.back()}
                        />
                    )}
                </ErrorBoundaryContainer>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
