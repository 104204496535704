import styled from "styled-components";
import colors from "assets/styles/colors";

export default styled.div`
    width: 100%;
    height: 100%;

    .table {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .table-heading {
            height: 37px;
            width: 100%;
            margin: 3px 3px;

            .skeleton {
                background-color: ${(props) =>
                    props.theme.tableHeadCellBackground};
            }
        }

        .row {
            margin: 3px 3px;
            height: 60px;
            width: 100%;

            .skeleton {
                background-color: ${(props) =>
                    props.theme.tableSkeletonBackground};
            }
        }
    }

    .stat {
        height: 230px;
        width: 100%;

        .skeleton {
            background-color: ${(props) => props.theme.squareButtonBackground};
        }
    }

    .auto {
        width: 100%;
        height: 100%;

        .skeleton {
            opacity: 0.5;
            background-color: ${(props) => props.theme.squareButtonBackground};
        }
    }
`;
