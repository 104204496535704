import {useEffect, useState} from "react";

// Styles
import ForecastsContainer from "./Forecasts.styles";

// Components
import Tabs from "components/Common/Tabs/Tabs";
import ScenarioSelectionTab from "components/Screens/Fees/Forecasts/ScenarioSelectionTab/ScenarioSelectionTab";
import ReportsTab from "components/Screens/Fees/Forecasts/ReportsTab/ReportsTab";
import EditScenariosTab from "components/Screens/Fees/Forecasts/EditScenariosTab/EditScenariosTab";

// Context
import { useSettings } from "context/Settings.context";
import {feesDashboardUrl} from "../../../utils/fees.utils";

export default function FeesForecasts() {
    const [feesForecastUrl, setFeesForecastUrl] = useState("");

    useEffect(() => {
        feesDashboardUrl("FORECAST_REPORT", setFeesForecastUrl);
    }, []);

    return (
        <ForecastsContainer className={"pageContainer"}>
            <p className={"sectionTitle"}>FORECASTS</p>

            <Tabs
                defaultTab="all_scenarios"
                tabs={[
                    {
                        value: "all_scenarios",
                        title: "ALL SCENARIOS",
                        permissions: ["p_can_view_fees", "p_can_action_fees"],
                        content: (
                            <div className="iframeContainer">
                                <iframe
                                    src={feesForecastUrl}
                                    width="100%"
                                    height="100%"
                                    allowFullScreen
                                    frameBorder={0}
                                />
                            </div>
                        ),
                    },
                    {
                        value: "scenario_selection",
                        title: "NEW SCENARIO",
                        permissions: ["p_can_view_fees", "p_can_action_fees"],
                        content: <ScenarioSelectionTab />,
                    },
                    {
                        value: "edit_scenarios",
                        title: "EDIT SCENARIOS",
                        permissions: ["p_can_view_fees", "p_can_action_fees"],
                        content: <EditScenariosTab />,
                    },
                    // {
                    //     value: "reports",
                    //     title: "REPORTS",
                    //     permissions: ["p_can_view_fees", "p_can_action_fees"],
                    //     content: <ReportsTab />,
                    // },
                ]}
            />
        </ForecastsContainer>
    );
}
