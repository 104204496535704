import React, { useEffect, useState } from "react";

// Styles
import EditAnswerContainer from "./EditAnswer.styles";

// Types
import {GrowthAnswer} from "assets/interfaces/growth_voting";
import { EnumGrowthAnswerTypes } from "assets/enums/growth_voting";
import EditBooleanAnswer from "./EditBooleanAnswer/EditBooleanAnswer";
import EditRatingAnswer from "./EditRatingAnswer/EditRatingAnswer";
import EditPercAnswer from "./EditPercAnswer/EditPercAnswer";


// Components


interface Porps {
    answer: GrowthAnswer;
    updatedAnswers: GrowthAnswer[];
    setUpdatedAnswers: React.Dispatch<React.SetStateAction<GrowthAnswer[]>>;
    open: boolean|undefined;
}


const EditAnswer  = ({answer, updatedAnswers, setUpdatedAnswers, open}: Porps) => {

    const addToUpdatedAnsweres = (updated: GrowthAnswer) => {
        // add the updated answer to the updatedAnswers array
        let index = updatedAnswers.findIndex((a) => a.question_id === updated.question_id)

        if (index > -1) {
            updatedAnswers[index] = updated
        } else {
            updatedAnswers.push(updated)
        }

        setUpdatedAnswers(updatedAnswers)
    }

    return (
        <EditAnswerContainer>
            {EnumGrowthAnswerTypes.Boolean == answer.question?.answer_type && (
                <EditBooleanAnswer answer={answer} addToUpdatedAnsweres={addToUpdatedAnsweres} open={open}/>
            )}
            {EnumGrowthAnswerTypes.Rating_1_5 == answer.question?.answer_type && (
                <EditRatingAnswer answer={answer} addToUpdatedAnsweres={addToUpdatedAnsweres} open={open}/>

            )}
            {EnumGrowthAnswerTypes.Percentage == answer.question?.answer_type && (
                <EditPercAnswer answer={answer} addToUpdatedAnsweres={addToUpdatedAnsweres} open={open}/>
            )}
        </EditAnswerContainer>
    );
};

export default EditAnswer
