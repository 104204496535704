import colors from "assets/styles/colors";

export default {
    filterButtonBackground: colors.darkThemeMain,
    filterButtonBackgroundHover: colors.darkThemeLight,
    filterButtonForeground: "white",
    filterButtonBackgroundActive: colors.darkThemeLighter,
    filterButtonForegroundActive: "white",

    dropDownMenuBackground: colors.darkThemeMain,
    dropDownMenuText: "white",
    dropDownSearchBoxIcon: "white",
    dropDownSearchBoxBorder: colors.grey60,
    dropDownBackgroundHover: colors.darkThemeLight,

    searchOptionsShowAllBackground: colors.darkThemeLight,
    searchOptionsShowAllText: "white",
};
