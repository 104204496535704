// Microsoft Auth Library
import { useMsal } from "@azure/msal-react";

// Images
import GimLogo from "assets/images/generationGwhite.png";
import QSLogo from "assets/images/QuantSparkLogoDark.png";

// Styles
import LoginContainer from "./Login.styles";

export default function Login() {
    const { instance } = useMsal();

    const handleLoginRedirect = () => {
        localStorage.setItem("logging-in", "true");
        localStorage.setItem("gim-last-login", new Date().getTime().toString());

        instance.loginRedirect({
            scopes: ["user.read"],
        });
    };

    return (
        <LoginContainer>
            <div className={"loginModal"}>
                <div className={"header"}>
                    <img src={GimLogo} alt="GIM Logo" draggable={false} />
                </div>

                <div className={"body"}>
                    <button
                        id="loginButton"
                        className="mt10"
                        onClick={handleLoginRedirect}
                    >
                        LOGIN WITH OFFICE365
                    </button>
                    <p
                        onClick={() =>
                            window.open(
                                "https://forms.gle/BTCH2EmRfUUzucFq8",
                                "_blank"
                            )
                        }
                    >
                        <strong>TROUBLE LOGGING IN?</strong>
                    </p>
                </div>
            </div>

            <img
                className={"quantSparkLogo"}
                src={QSLogo}
                alt={"QuantSpark Logo"}
            />
        </LoginContainer>
    );
}
