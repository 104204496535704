import styled from "styled-components";

export default styled.div`
    position: absolute;

    .logout-icon {
        padding: 2px 5px 2px 2px;
        background-color: ${(props) => props.theme.mainMenuForeground};
        color: ${(props) => props.theme.mainMenuBackground};

        -webkit-border-bottom-right-radius: 50px;
        -moz-border-radius-bottomright: 50px;
        border-bottom-right-radius: 50px;
        cursor: pointer;

    }
`;
