import styled from 'styled-components';

export default styled.div`
    padding: 10px;

    .btn-close-container {
        display:flex;
        justify-content: flex-end;
        padding-top: 8px;
    }

    .section-title {
        font-size: 20px;
        letter-spacing: 0.1em;

        color: ${(props) => props.theme.pageTitleText};
        margin-bottom: 15px;
        text-align: center;
    }
   .step-content {
        padding: 30px;
   }
   .step-actions {
        padding: 20px;
        display: flex;
        justify-content: flex-end;
   }
`;
