import styled from 'styled-components';

export default styled.div`
    .btn-add-container {
        display: flex;
        justify-content: flex-start;
        padding: 10px;
    }

    .content {
        padding: 1rem;
        .vote-header {
            font-size: 1.5rem;
            letter-spacing: 0.1em;
            color: ${(props) => props.theme.pageTitleText};
        }

        .questions {
            .question {
                border-bottom: 1px solid ${(props) => props.theme.subMenuBackground};
                padding: 5px 10px;
                margin-bottom: 0px;
                display: grid;
                grid-template-columns: 30px 1fr 1fr;
                &.overall {
                    background-color: ${(props) => props.theme.subMenuBackground} !important;
                    border-radius: 5px;
                    .name {
                        font-weight: bold;

                    }
                }
            }

            /* columns */
            .question > * {
                padding: 8px 0px;
            }
        }
    }

    .label {
        padding: 3px 5px;
        margin-right: 5px;
        border-radius: 5px;
        background-color: ${(props) => props.theme.subMenuHighlight};
    }

    .label-closed {
        background-color: ${(props) => props.theme.labelAlertBg};
        color: ${(props) => props.theme.labelAlertColor};
    }

    .label-open {
        background-color: ${(props) => props.theme.labelSecondaryBg};
        color: ${(props) => props.theme.labelSecondaryColor};
    }

`;
