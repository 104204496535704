import React, { useEffect, useState } from "react";

// Components
import SelectUsersContainer from "./SelectUsers.styles";
import {DealTeam, DealTeamMember} from "assets/interfaces/growth_voting";
import {ApiHandler} from "../../../../services/api_handler";
import LoadingSpinner from "components/Common/LoadingSpinner/LoadingSpinner";
import Checkbox from "@mui/material/Checkbox";
import TextField from "components/Common/TextField/TextField";

// Context
import {useGrowthUsers} from "context/growth/GrowthUser.context";

interface Props {
    selectedUsers: DealTeamMember[]
    setSelectedUsers: React.Dispatch<React.SetStateAction<DealTeamMember[]>>
}

const URL_USERS_LIST = "api/growth_voting/users";


const UserSearch  = ({selectedUsers, setSelectedUsers}: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [search, setSearch] = useState<string>("");
    const [displayedUsers, setDisplayedUsers] = useState([]);
    const {users} = useGrowthUsers()


    const isUserSelected = (obj: DealTeamMember, list: DealTeamMember[]) => {
        for (let i = 0; i < list.length; i++) {
            if (list[i].user_id === obj.user_id) {
                return true;
            }
        }

        return false;
    }

    const handleSelection = (user: DealTeamMember) => {
        let newSelectedUsers;

        if (isUserSelected(user, selectedUsers)) {
            newSelectedUsers = selectedUsers.filter((selectedUser: any) => selectedUser.user_id !== user.user_id);
        } else {
            newSelectedUsers = [...selectedUsers, user];
        }

        setSelectedUsers(newSelectedUsers);
    }

    const filterUsers = () => {
        let filteredUsers = users.filter((user: any) => {
            if (!search) {
                return true;
            }


            return user.name.toLowerCase().includes(search.toLowerCase());
        });

        setDisplayedUsers(filteredUsers);
    }

    useEffect(() => {
        filterUsers();
    }, [search]);

    useEffect(() => {
        setDisplayedUsers(users);
    }, [users]);

    return (
        <SelectUsersContainer >
            {loading && (
                <div className="loading">
                    <LoadingSpinner />
                </div>
            )}

            {!loading && <div className={"content"}>

                <div className="search">
                    <TextField
                        placeholder="Select Users"
                        value={search}
                        change={setSearch}
                    />
                </div>

                <div className={"users"}>
                {displayedUsers.length > 0 &&
                    displayedUsers.map((user: any, index: any) => (
                        <div className={"user"}>
                            <Checkbox
                                checked={isUserSelected(user, selectedUsers)}
                                onClick={() => handleSelection(user) }
                            />
                            <div key={index} id={"user-" + index}>{user.name}</div>
                        </div>
                    ))}

                {displayedUsers.length === 0 && !loading && (
                    <div className="noResults">
                        <p>No Results</p>
                    </div>
                )}
                </div>
            </div>}
        </SelectUsersContainer>
    );
};

export default UserSearch
