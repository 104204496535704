import React, {useEffect, useState} from "react";

// Styles
import EmailDealingDeskModalContainer from "./EmailDealingDeskModal.styles";

// Components
import Modal from "components/Common/Modal/Modal";
import Button from "components/Common/Button/Button";
import TextArea from "components/Common/TextArea/TextArea";

// Context
import { useSettings } from "context/Settings.context";

// Utils
import * as Database from "utils/database.utils";
import Table from "../../../../Common/Table/Table";
import TableCell from "../../../../Common/TableCell/TableCell";
import EditableCell from "components/Screens/Admin/ValidationRules/EditableCell/EditableCell";
import {formatNumberFixDecimal} from "../../../../../utils/common.utils";

interface EmailDealingDeskModalProps {
    setShowModal: Function;
}

type EmailNoteValuesType = {
    comments: string;
};

interface Flow {
    account_name: string,
    account_number: string,
    flow_date_dt: string,
    id: string,
    management_fees: number,
    other_expenses: number,
    performance_fees: number,
    redemptions_subs: number,
    comments?: string,
}

const EmailDealingDeskModal = ({
    setShowModal,
}: EmailDealingDeskModalProps) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [currentFlows, setCurrentFlows] = useState<Flow[]>([]);
    const [futureFlows, setFutureFlows] = useState<Flow[]>([]);
    const { setToast } = useSettings();
    const [tableIsLoading, setTableIsLoading] = useState<boolean>(false);
    const [accountNoteValues, setAccountNoteValues] = useState<{}>({})

    const [emailNoteValues, setEmailNoteValues] = useState<EmailNoteValuesType>(
        {
            comments: "",
        }
    );

    const fetchNonHistoricFlows = async () => {
        // today, let backend figure out the previous day
        const flowDt = new Date().toISOString().substring(0, 10);

        let res = await Database.get(`api/nav/user_inputs/daily_flows/${flowDt}/non_historic/`);

        if (res.ok) {
            let response = await res.json();

            setCurrentFlows(response['current']);
            setFutureFlows(response['futures']);
        }
    };

    const handleChange = (value: string, name: string) => {
        setEmailNoteValues({
            ...emailNoteValues,
            [name]: value,
        });
    };

    const sendToDealingDesk = async () => {
        let payload = {
            ...emailNoteValues,
            account_comments: accountNoteValues
        }

        let res = await Database.post("api/nav/alerts/overview/create_checks_notification/", payload);

        setToast({
            show: true,
            type: "info",
            message: "Sending email to the Dealing desk ...",
        });
        if (res.ok) {
            setShowModal(false);
            setToast({
                show: true,
                type: "success",
                message: "Email has been sent.",
            });
        } else if (res.status == 400) {
            setToast({
                show: true,
                type: "error",
                message: "Please make sure the flows are entered correctly",
            });
        } else {
            setShowModal(false);
            setToast({
                show: true,
                type: "error",
                message: "The email cannot be sent. Contact support.",
            });
        }
    };

    const calculateFlowTotal = (flow: Flow) => {
       let managementFees =  flow.management_fees;
       if (!managementFees) {
           managementFees = 0;
       }

       let performanceFees =  flow.performance_fees;
       if (!performanceFees) {
           performanceFees = 0;
       }

       let otherExpenses =  flow.other_expenses;
       if (!otherExpenses) {
           otherExpenses = 0;
       }

       let redemptionsSubs =  flow.redemptions_subs;
       if (!redemptionsSubs) {
           redemptionsSubs = 0;
       }

       return managementFees + performanceFees + otherExpenses + redemptionsSubs;
    }

    const updateAccountComments = (id: string, comments: string) => {
        // find future flow based on the id (only they have comments)
        let futureFlow = futureFlows.find((flow) => flow.id === id);

        if (!futureFlow) {
            throw "Future Flow not found";
        }

        futureFlow.comments = comments;

        let compoundId = futureFlow.flow_date_dt + "_" + futureFlow.account_number

        setAccountNoteValues({
            ...accountNoteValues,
            [compoundId]: {
                // helps to find the merged LODH account
                compoundId: compoundId,
                id: futureFlow.id,
                account_number: futureFlow.account_number,
                comments: comments,
            }
        })
    }

    useEffect(() => {
        fetchNonHistoricFlows();
    }, []);

     const baseColumns = [
        {
            id: 6493549733,
            title: "DATE",
            sortable: false,
            value: "flow_date_dt",
            cell: (data: any) =>
                new Date(data.flow_date_dt).toLocaleDateString(),
            show: true,
            fullDataRow: true,
        },
         {
            id: 8658658765,
            title: "ACCOUNT",
            sortable: false,
            value: "account_name",
            cell: (data: any) => <TableCell data={data.account_name} />,
            show: true,
            fullDataRow: true,
        },
          {
            id: 498765867,
            title: "REDEMPTION/SUBSCRIPTIONS",
            sortable: false,
            value: "redemptions_subs",
            cell: (data: any) => <TableCell data={formatNumberFixDecimal(data.redemptions_subs)} />,
            show: true,
            fullDataRow: true,
        },
         {
            id: 98578657,
            title: "MGMT. FEES",
            sortable: false,
            value: "management_fees",
            cell: (data: any) => <TableCell data={formatNumberFixDecimal(data.management_fees)} />,
            show: true,
            fullDataRow: true,
        },
         {
            id: 8659856,
            title: "PERFORM. FEES",
            sortable: false,
            value: "performance_fees",
            cell: (data: any) => <TableCell data={formatNumberFixDecimal(data.performance_fees)} />,
            show: true,
            fullDataRow: true,
        },
        {
            id: 98786586,
            title: "OTHER FEES",
            sortable: false,
            value: "other_expenses",
            cell: (data: any) => <TableCell data={formatNumberFixDecimal(data.other_expenses)} />,
            show: true,
            fullDataRow: true,
        },
    ]

    const extraCurrentColumns = [
        {
            id: 76585785765,
            title: "Total Flow",
            sortable: false,
            value: "total",
            cell: (data: any) => <TableCell data={formatNumberFixDecimal(calculateFlowTotal(data))
            } />,
            show: true,
            fullDataRow: true,
        },
    ]

    const extraFutureColumns = [
        {
            id: 43765475,
            title: "Comments",
            sortable: false,
            value: "comments",
            cell: (data: any) => (
                <EditableCell
                    rowId={data.id}
                    cellValue="comments"
                    cellValueType="text"
                    rowIdKey={"id"}
                    initialValue={(data?.comments) ? data.comments : ""}
                    type="textField"
                    setTableIsLoading={setTableIsLoading}
                    updateTable={() => {}}
                    tableIsLoading={tableIsLoading}
                    onUpdate={updateAccountComments}
                />
            ),
            show: true,
            fullDataRow: true,
        },
    ]

    const currentColumns = baseColumns.concat(extraCurrentColumns);

    const futureColumns = baseColumns.concat(extraFutureColumns);

    return (
        <EmailDealingDeskModalContainer>
            <Modal loading={loading} width={"80vw"}>
                <div className={"main"}>
                    <div className={"title"}>Email Dealing Desk</div>

                    <div className={"flow-title"}>
                        <span className={"future label"}>Future Flows</span>
                    </div>

                    <Table
                        columns={futureColumns}
                        filterQuery={""}
                        defaultSortKey="flow_date_dt"
                        defaultLimit={200}
                        data={futureFlows}
                        loading={loading}
                    />

                    <div className={"flow-title"}>
                        <span className={"current label"}>Current Flows</span>
                    </div>

                    <Table
                        columns={currentColumns}
                        filterQuery={""}
                        defaultSortKey="flow_date_dt"
                        defaultLimit={200}
                        data={currentFlows}
                        loading={loading}
                    />

                    <label>Additional Notes</label>
                    <TextArea
                        label=""
                        value={emailNoteValues["comments"]}
                        change={(e: any) => handleChange(e, "comments")}
                        rows={5}
                        placeholder={""}
                    />

                </div>
                <div className="actions">
                    <Button
                        text="CANCEL"
                        color="flat"
                        click={() => setShowModal(false)}
                    />
                    <Button text="SEND" click={() => sendToDealingDesk()} />
                </div>
            </Modal>
        </EmailDealingDeskModalContainer>
    );
};
export default EmailDealingDeskModal;
