import { Route, Switch, useHistory } from "react-router-dom";

// Screens
import AnalyticsQueue from "screens/Data/AnalyticsQueue/AnalyticsQueue";
import Login from "screens/Login/Login";
import Notifications from "screens/Notifications/Notifications";

// ADA
import CompanyModelView from "screens/AdaViews/CompanyViews/CompanyModelView/CompanyModelView";
import FairValueChartsView from "screens/AdaViews/CompanyViews/FairValueChartsView/FairValueChartsView";
import PortfolioLive from "screens/PortfolioLive/PortfolioLive";
import DenominatorView from "./AdaViews/CompanyViews/DenominatorView/DenominatorView";
import GEFSectorsView from "./AdaViews/CompanyViews/GEFSectorsView/GEFSectorsView";
import AsiaPlView from "./AdaViews/CompanyViews/AsaiPlView/AsiaPlView";
import GHGView from "./AdaViews/CompanyViews/GHGView/GHGView";
import NOPATAssumptionsView from "./AdaViews/CompanyViews/NOPATAssumptionsView/NOPATAssumptionsView";
import QualityMatrixView from "./AdaViews/CompanyViews/QualityMatrixView/QualityMatrixView";
import SBTIView from "./AdaViews/CompanyViews/SBTIView/SBTIView";
import SFDRView from "./AdaViews/CompanyViews/SFDRView/SFDRView";
import SupplementaryInformationView from "./AdaViews/CompanyViews/SupplementaryInformationView/SupplementaryInformationView";
import TrendsView from "./AdaViews/CompanyViews/TrendsView/TrendsView";
import InvestmentCaseView from "./AdaViews/CompanyViews/InvestmentCasesView/InvestmentCaseView"

// Admin
import Permissions from "screens/Admin/Permissions/Permissions";

// Data
import CompanyList from "screens/Data/Companies/CompanyList/CompanyList";
import NewCompaniesPage from "screens/Data/Companies/NewCompanies/NewCompanies";
import StockSplitsPage from "screens/Data/Companies/StockSplits/StockSplits";
import CompanyModelDetail from "screens/Data/CompanyModels/CompanyModelDetail/CompanyModelDetail";
import CompanyModelList from "screens/Data/CompanyModels/CompanyModelList/CompanyModelList";
import DataDictionary from "screens/Data/DataDictionary/DataDictionary";
import DatabaseSchemaPage from "screens/Data/DatabaseSchema/DatabaseSchema";
import ExportBQMQ from "screens/Data/ExportBQMQ/ExportBQMQ";
import DataHome from "screens/Data/Home/Home";
import PortfolioLiveDetail from "screens/Data/PortfolioLive/PortfolioLiveDetail/PortfolioLiveDetail";
import PortfolioLiveList from "screens/Data/PortfolioLive/PortfolioLiveList/PortfolioLiveList";
import SummarySheetDetail from "screens/Data/SummarySheets/SummarySheetDetail/SummarySheetDetail";
import SummarySheetList from "screens/Data/SummarySheets/SummarySheetList/SummarySheetList";
import UploadData from "screens/Data/UploadData/UploadData";
import ValidationRulesPage from "screens/Data/ValidationRules/ValidationRules";

// BQMQ
import CreateSession from "screens/BQMQ/CreateSession/CreateSession";
import EditSession from "screens/BQMQ/EditSession/EditSession";
import BQMQHome from "screens/BQMQ/Home/Home";
import CompanyResultDetail from "screens/BQMQ/Results/CompanyResultDetail/CompanyResultDetail";
import Sessions from "screens/BQMQ/Sessions/Sessions";
import BlankVotingPage from "./BQMQ/BlankVotingPage/BlankVotingPage";
import PrioritisedVotes from "./BQMQ/PrioritisedVotes/PrioritisedVotes";
import CompanyDetail from "./BQMQ/Results/CompanyDetail/CompanyDetail";
import Settings from "./BQMQ/Settings/Settings";

// Fees
import ReportsDetail from "components/Screens/Fees/Common/FundTabs/ReportsTab/ReportsDetail/ReportsDetail";
import ScenarioDetail from "components/Screens/Fees/Forecasts/EditScenariosTab/ScenarioDetail/ScenarioDetail";
import ChronDataRefresh from "./Chronograph/DataRefresh/ChronDataRefresh";
import DelawareFund from "./Fees/DelawareFund/DelawareFund";
import DublinFund from "./Fees/DublinFund/DublinFund";
import FeesValidationRulesPage from "./Fees/FeesValidationRules/FeesValidationRules";
import FeesForecasts from "./Fees/Forecasts/Forecasts";
import SeparateAccounts from "./Fees/SeparateAccounts/SeparateAccounts";

//NAV
import IncomingFileHistory from "components/Screens/DailyRecs/IncomingFiles/IncomingFileHistory/IncomingFileHistory";

// Reconciliation
import Benchmarks from "./DailyRecs/Checks/Benchmarks/Benchmarks";
import BrokerExposure from "./DailyRecs/Checks/BrokerExposure/BrokerExposure";
import CRNTCashStock from "./DailyRecs/Checks/CRNTCashStock/CRNTCashStock";
import CRNTNav from "./DailyRecs/Checks/CRNTNav/CRNTNav";
import CashflowForecast from "./DailyRecs/Checks/CashflowForecast/CashflowForecast";
import CurrencyExposure from "./DailyRecs/Checks/CurrencyExposure/CurrencyExposure";
import DailyFlowsCheck from "./DailyRecs/Checks/DailyFlowsCheck/DailyFlowsCheck";
import DailyMovements from "./DailyRecs/Checks/DailyMovements/DailyMovements";
import FXRates from "./DailyRecs/Checks/FXRates/FXRates";
import ForwardsNDFExposureEMIR from "./DailyRecs/Checks/ForwardsNDFExposureEMIR/ForwardsNDFExposureEMIR";
import Hedging from "./DailyRecs/Checks/Hedging/Hedging";
import LeverageCollateralFuture from "./DailyRecs/Checks/LeverageCollateralFuture/LeverageCollateralFuture";
import Accruals from "./DailyRecs/Inputs/Accruals/Accruals";
import Configurations from "./DailyRecs/Inputs/Configurations/Configurations";
import DailyFlowsInput from "./DailyRecs/Inputs/DailyFlows/DailyFlowsInput";
import Futures from "./DailyRecs/Inputs/Futures/Futures";
import IncomingFiles from "./DailyRecs/Inputs/IncomingFiles/IncomingFiles";
import OverView from "./DailyRecs/Overviews/Overview/Overview";
import FormPF from "./DailyRecs/Checks/FormPF/FormPF";

//SFDR
import SFDRDataCapture from "screens/SFDRDataCapture/SFDRDataCapture";
import SFDRDataCaptureRedirect from "screens/SFDRDataCapture/SFDRDataCaptureRedirect";
import SFDRDataCaptureSubmissions from "screens/SFDRDataCapture/SFDRDataCaptureSubmissions/SFDRDataCaptureSubmissions";

//Research
import MorningMeeting from "./Research/MorningMeeting/MorningMeeting";
import MorningMeetingDetail from "./Research/MorningMeetingDetail/MorningMeetingDetail";

//Ask ADA Chat

// GROWTH VOTING
import GrowthDeals from "./GrowthVoting/GrowthDeals/GrowthDeals";
import GrowthHome from "./GrowthVoting/GrowthHome/GrowthHome";
import GrowthCompanies from "./GrowthVoting/GrowthCompanies/GrowthCompanies";

// Components
import HomeRedirect from "components/Common/HomeRedirect/HomeRedirect";
import PrivateRoute from "components/Common/PrivateRoute/PrivateRoute";
import PrivateRenderRoute from "components/Common/PrivateRoute/PrivateRenderRoute";

// Context
import { useSettings } from "context/Settings.context";

// Logger
import Logger from "utils/logger.utils";
import { useAuth } from "../context/Auth.context";
import Docs from "./Docs/Docs";
import Help from "./Help/Help";

// Utils
import { setLastRoute } from "utils/user.utils";
import NewNotes from "./Research/NewNotes/NewNotes";
import GrowthDealDetail from "./GrowthVoting/GrowthDealDetail/GrowthDealDetail";
import FundMetrics from "./MissionControl/FundMetrics/FundMetrics";
import Tables from "./MissionControl/Tables/Tables";
import ChartsAndAnalysis from "./MissionControl/ChartsAndAnalysis/ChartsAndAnalysis";
import PublicCompanies from "./MissionControl/PublicCompanies/PublicCompanies";
import PrivateCompanyDashboard from "./MissionControl/PrivateCompanyDashboard/PrivateCompanyDashboard";
import PublicCompanyDashboard from "./MissionControl/PublicCompanyDashboard/PublicCompanyDashboard";
import SustainabilityDashboard from "./MissionControl/SustainabilityDashboard/SustainabilityDashboard";
import ClimateLevelsView from "./AdaViews/CompanyViews/ClimateLevelsView/ClimateLevelsView";
import SFDRWebFormView from "./AdaViews/CompanyViews/SFDRWebFormView/SFDRWebFormView";
import ClimateChronograph from "./JustClimate/ClimateChronograph";
import UsageStatSummary from "./Reporting/UsageStatSummary";

// Constants
import { NINETY_DAY_CONSUMPTION_IFRAME_SRC, NINETY_DAY_CONSUMPTION_TITLE, NINETY_DAY_DASHBOARD_IFRAME_SRC, NINETY_DAY_DASHBOARD_TITLE, NINETY_DAY_USER_IFRAME_SRC, NINETY_DAY_USER_TITLE, THIRTY_DAY_IFRAME_SRC, THIRTY_DAY_TITLE } from "./ScreenConstants";

const Log = new Logger();
Log.init("MainRoutes");

export default function MainRoutes() {
    const { setRoute } = useSettings();
    const history = useHistory();
    const { user } = useAuth();

    history.listen((location: any) => {
        setRoute(location.pathname);
        if (location.pathname !== "/login" && user) {
            let route = location.pathname + location.search;
            setLastRoute(route, user.id);
        }

        document.getElementById("mainContainer")?.scrollTo(0, 0);
    });

    return (
        <Switch>
            <PrivateRoute
                exact
                path="/"
                permissions={""}
                component={HomeRedirect}
            />

            {/* Homepage routes */}
            {/* <PrivateRoute
                exact
                path="/home"
                permissions={""}
                component={AdaHome}
            /> */}

            {/* ADA */}
            <PrivateRoute
                exact
                path="/ada/snapshot"
                permissions={[
                    "P_bqmq_can_host",
                    "p_bqmq_can_view_results",
                    "p_bqmq_can_vote",
                ]}
                component={PortfolioLive}
            />
            <PrivateRoute
                exact
                path="/ada/financials"
                permissions={[
                    "P_bqmq_can_host",
                    "p_bqmq_can_view_results",
                    "p_bqmq_can_vote",
                ]}
                component={CompanyModelView}
            />
            <PrivateRoute
                exact
                path="/ada/fair-value-charts"
                permissions={[
                    "P_bqmq_can_host",
                    "p_bqmq_can_view_results",
                    "p_bqmq_can_vote",
                ]}
                component={FairValueChartsView}
            />
            <PrivateRoute
                exact
                path="/ada/nopat_assumptions"
                permissions={[
                    "P_bqmq_can_host",
                    "p_bqmq_can_view_results",
                    "p_bqmq_can_vote",
                ]}
                component={NOPATAssumptionsView}
            />
            <PrivateRoute
                exact
                path="/ada/trends"
                permissions={[
                    "P_bqmq_can_host",
                    "p_bqmq_can_view_results",
                    "p_bqmq_can_vote",
                ]}
                component={TrendsView}
            />
            <PrivateRoute
                exact
                path="/ada/supplementary-information"
                permissions={[
                    "P_bqmq_can_host",
                    "p_bqmq_can_view_results",
                    "p_bqmq_can_vote",
                ]}
                component={SupplementaryInformationView}
            />
            <PrivateRoute
                exact
                path="/ada/quality-matrix"
                permissions={[
                    "P_bqmq_can_host",
                    "p_bqmq_can_view_results",
                    "p_bqmq_can_vote",
                ]}
                component={QualityMatrixView}
            />
            <PrivateRoute
                exact
                path="/ada/gef_sectors"
                permissions={[
                    "P_bqmq_can_host",
                    "p_bqmq_can_view_results",
                    "p_bqmq_can_vote",
                ]}
                component={GEFSectorsView}
            />
            <PrivateRoute
                exact
                path="/ada/asia_pl"
                permissions={[
                    "P_bqmq_can_host",
                    "p_bqmq_can_view_results",
                    "p_bqmq_can_vote",
                ]}
                component={AsiaPlView}
            />

            {/* SUSTAINABILITY */}
            <PrivateRoute
                exact
                path="/sustainability/ghg"
                permissions={[
                    "P_bqmq_can_host",
                    "p_bqmq_can_view_results",
                    "p_bqmq_can_vote",
                ]}
                component={GHGView}
            />
            <PrivateRoute
                exact
                path="/sustainability/sbti"
                permissions={[
                    "P_bqmq_can_host",
                    "p_bqmq_can_view_results",
                    "p_bqmq_can_vote",
                ]}
                component={SBTIView}
            />
            <PrivateRoute
                exact
                path="/sustainability/climate-levels"
                permissions={[
                    "P_bqmq_can_host",
                    "p_bqmq_can_view_results",
                    "p_bqmq_can_vote",
                ]}
                component={ClimateLevelsView}
            />
            <PrivateRoute
                exact
                path="/sustainability/sfdr"
                permissions={[
                    "P_bqmq_can_host",
                    "p_bqmq_can_view_results",
                    "p_bqmq_can_vote",
                ]}
                component={SFDRView}
            />
            <PrivateRoute
                exact
                path="/sustainability/sfdr-assessments"
                permissions={[
                    "P_bqmq_can_host",
                    "p_bqmq_can_view_results",
                    "p_bqmq_can_vote",
                ]}
                component={SFDRWebFormView}
            />
            <PrivateRoute
                exact
                path="/sustainability/denominator"
                permissions={[
                    "P_bqmq_can_host",
                    "p_bqmq_can_view_results",
                    "p_bqmq_can_vote",
                ]}
                component={DenominatorView}
            />

            {/* SFDR DATA CAPTURE */}
            <PrivateRoute
                exact
                path="/sustainability/sfdr-data-capture/form"
                permissions={["p_can_submit_sfdr_esg_questionnaire"]}
                component={SFDRDataCapture}
            />

            <PrivateRoute
                exact
                path="/sustainability/sfdr-data-capture"
                permissions={["p_can_submit_sfdr_esg_questionnaire"]}
                component={SFDRDataCaptureRedirect}
            />

            <PrivateRoute
                exact
                path="/sustainability/sfdr-data-capture/submissions"
                permissions={["p_can_submit_sfdr_esg_questionnaire_on_behalf"]}
                component={SFDRDataCaptureSubmissions}
            />

            {/*  DATA  */}
            <PrivateRoute
                exact
                path="/data"
                permissions={["p_list_extractions"]}
                component={DataHome}
            />
            <PrivateRoute
                exact
                path="/data/upload-data"
                permissions={["p_fix_extraction", "p_upload_model"]}
                component={UploadData}
            />
            <PrivateRoute
                exact
                path="/data/company-models"
                permissions={["p_list_extractions"]}
                component={CompanyModelList}
            />
            <PrivateRoute
                exact
                path="/data/company-model"
                permissions={["p_list_extractions"]}
                component={CompanyModelDetail}
            />
            <PrivateRoute
                exact
                path="/data/company-list"
                permissions={["p_update_companies"]}
                component={CompanyList}
            />
            <PrivateRoute
                exact
                path="/data/new-companies"
                permissions={["p_update_companies"]}
                component={NewCompaniesPage}
            />
            <PrivateRoute
                exact
                path="/data/stock-splits"
                permissions={["p_update_companies"]}
                component={StockSplitsPage}
            />
            <PrivateRoute
                exact
                path="/data/summary-sheets"
                permissions={["p_gbl_dashboard"]}
                component={SummarySheetList}
            />
            <PrivateRoute
                exact
                path="/data/summary-sheet"
                permissions={["p_gbl_dashboard"]}
                component={SummarySheetDetail}
            />
            <PrivateRoute
                exact
                path="/data/portfolio-live-list"
                permissions={["p_gbl_dashboard"]}
                component={PortfolioLiveList}
            />
            <PrivateRoute
                exact
                path="/data/portfolio-live"
                permissions={["p_gbl_dashboard"]}
                component={PortfolioLiveDetail}
            />
            <PrivateRoute
                exact
                path="/data/data-dictionary"
                permissions={["p_view_dictionary"]}
                component={DataDictionary}
            />
            <PrivateRoute
                exact
                path="/data/database-schema"
                permissions={["p_view_dictionary"]}
                component={DatabaseSchemaPage}
            />
            <PrivateRoute
                exact
                path="/data/validation-rules"
                permissions={["p_update_validation"]}
                component={ValidationRulesPage}
            />
            <PrivateRoute
                exact
                path="/data/analytics-queue"
                permissions={["p_fix_extraction", "p_upload_model"]}
                component={AnalyticsQueue}
            />
            <PrivateRoute
                exact
                path="/data/export-bqmq"
                permissions={["p_bqmq_can_export"]}
                component={ExportBQMQ}
            />
            {/* //Enable when releasing the BS alerts feature */}
            {/* <PrivateRoute
                exact
                path="/data/buy-sell-alerts"
                permissions={["p_bqmq_can_export"]}
                component={BuySellAlerts}
            /> */}

            {/*  BQMQ VOTING   */}
            <PrivateRoute
                exact
                path="/voting"
                permissions={["p_bqmq_can_vote", "p_bqmq_can_view_results"]}
                component={BQMQHome}
            />

            <PrivateRoute
                exact
                path="/voting/sessions"
                permissions={["p_bqmq_can_vote", "p_bqmq_can_view_results"]}
                component={Sessions}
            />

            <PrivateRoute
                exact
                path="/voting/vote"
                permissions={["p_bqmq_can_vote"]}
                component={BlankVotingPage}
            />
            <PrivateRoute
                exact
                path="/voting/company"
                permissions={["p_bqmq_can_view_results"]}
                component={CompanyDetail}
            />
            <PrivateRoute
                exact
                path="/voting/result"
                permissions={["p_bqmq_can_view_results"]}
                component={CompanyResultDetail}
            />
            <PrivateRoute
                exact
                path="/voting/result/company"
                permissions={["p_bqmq_can_view_results"]}
                component={CompanyResultDetail}
            />
            <PrivateRoute
                exact
                path="/voting/export"
                permissions={["p_bqmq_can_export"]}
                component={ExportBQMQ}
            />
            <PrivateRoute
                exact
                path="/voting/create-session"
                permissions={["p_bqmq_can_create_session", "p_bqmq_can_host"]}
                component={CreateSession}
            />
            <PrivateRoute
                exact
                path="/voting/edit-session"
                permissions={[
                    "p_bqmq_can_edit_session",
                    "p_bqmq_can_delete_session",
                ]}
                component={EditSession}
            />
            <PrivateRoute
                exact
                path="/voting/settings"
                permissions={[
                    "p_bqmq_can_edit_session",
                    "p_bqmq_can_delete_session",
                    "p_bqmq_can_host",
                ]}
                component={Settings}
            />

            <PrivateRoute
                exact
                path="/voting/prioritised-votes"
                permissions={["p_bqmq_can_vote", "p_bqmq_can_view_results"]}
                component={PrioritisedVotes}
            />

            {/* FEES */}
            <PrivateRoute
                exact
                path="/fees/forecasts"
                permissions={["p_can_action_fees", "p_can_view_fees"]}
                component={FeesForecasts}
            />

            <PrivateRoute
                exact
                path="/fees/forecasts/scenario"
                permissions={["p_can_view_fees", "p_can_action_fees"]}
                component={ScenarioDetail}
            />

            <PrivateRoute
                exact
                path="/fees/dublin/report"
                permissions={["p_can_action_fees", "p_can_view_fees"]}
                component={ReportsDetail}
            />

            <PrivateRoute
                exact
                path="/fees/delaware/report"
                permissions={["p_can_action_fees", "p_can_view_fees"]}
                component={ReportsDetail}
            />

            <PrivateRoute
                exact
                path="/fees/dublin"
                permissions={["p_can_action_fees", "p_can_view_fees"]}
                component={DublinFund}
            />

            <PrivateRoute
                exact
                path="/fees/delaware"
                permissions={["p_can_action_fees", "p_can_view_fees"]}
                component={DelawareFund}
            />

            <PrivateRoute
                exact
                path="/fees/separate-accounts"
                permissions={["p_can_action_fees", "p_can_view_fees"]}
                component={SeparateAccounts}
            />

            <PrivateRoute
                exact
                path="/fees/validation-rules"
                permissions={["p_can_action_fees", "p_can_view_fees"]}
                component={FeesValidationRulesPage}
            />

            {/* DAILY NAVS */}
            {/* INPUTS */}
            <PrivateRoute
                exact
                path="/reconciliation/inputs/incoming_files"
                permissions={["p_can_view_navs"]}
                component={IncomingFiles}
            />

            <PrivateRoute
                exact
                path="/reconciliation/inputs/incoming_file_history"
                permissions={["p_can_view_navs"]}
                component={IncomingFileHistory}
            />

            <PrivateRoute
                exact
                path="/reconciliation/inputs/daily_flows"
                permissions={["p_can_view_navs"]}
                component={DailyFlowsInput}
            />

            <PrivateRoute
                exact
                path="/reconciliation/inputs/configurations"
                permissions={["p_can_view_navs"]}
                component={Configurations}
            />

            <PrivateRoute
                exact
                path="/reconciliation/inputs/accruals"
                permissions={["p_can_view_navs"]}
                component={Accruals}
            />

            <PrivateRoute
                exact
                path="/reconciliation/inputs/futures_credit_rating"
                permissions={["p_can_view_navs"]}
                component={Futures}
            />

            {/* DAILY NAVS */}
            {/* OVERVIEW */}
            <PrivateRoute
                exact
                path="/reconciliation/overviews/checks"
                permissions={["p_can_view_navs"]}
                component={OverView}
            />

            {/* DAILY NAVS */}
            {/* CHECKS */}
            <PrivateRoute
                exact
                path="/reconciliation/checks/daily_flows"
                permissions={["p_can_view_navs"]}
                component={DailyFlowsCheck}
            />

            <PrivateRoute
                exact
                path="/reconciliation/checks/cashflow_forecast"
                permissions={["p_can_view_navs"]}
                component={CashflowForecast}
            />

            <PrivateRoute
                exact
                path="/reconciliation/checks/daily_movements"
                permissions={["p_can_view_navs"]}
                component={DailyMovements}
            />

            <PrivateRoute
                exact
                path="/reconciliation/checks/form_pf"
                permissions={["p_can_view_navs"]}
                component={FormPF}
            />

            <PrivateRoute
                exact
                path="/reconciliation/checks/fx_rates"
                permissions={["p_can_view_navs"]}
                component={FXRates}
            />

            <PrivateRoute
                exact
                path="/reconciliation/checks/benchmarks"
                permissions={["p_can_view_navs"]}
                component={Benchmarks}
            />

            <PrivateRoute
                exact
                path="/reconciliation/checks/cr_nt_nav"
                permissions={["p_can_view_navs"]}
                component={CRNTNav}
            />

            <PrivateRoute
                exact
                path="/reconciliation/checks/cr_ntcash_stock"
                permissions={["p_can_view_navs"]}
                component={CRNTCashStock}
            />

            <PrivateRoute
                exact
                path="/reconciliation/checks/hedging"
                permissions={["p_can_view_navs"]}
                component={Hedging}
            />

            <PrivateRoute
                exact
                path="/reconciliation/checks/currency_exposure_checks"
                permissions={["p_can_view_navs"]}
                component={CurrencyExposure}
            />

            <PrivateRoute
                exact
                path="/reconciliation/checks/forwards_ndfexposure_emir"
                permissions={["p_can_view_navs"]}
                component={ForwardsNDFExposureEMIR}
            />

            <PrivateRoute
                exact
                path="/reconciliation/checks/broker_exposure"
                permissions={["p_can_view_navs"]}
                component={BrokerExposure}
            />

            <PrivateRoute
                exact
                path="/reconciliation/checks/leverage_collateral_future"
                permissions={["p_can_view_navs"]}
                component={LeverageCollateralFuture}
            />

            {/* GROWTH VOTING */}
            <PrivateRoute
                exact
                path="/growth-voting"
                permissions={["p_can_view_growth_voting"]}
                component={GrowthHome}
            />
            <PrivateRoute
                exact
                path="/growth-voting/deals"
                permissions={["p_can_view_growth_voting"]}
                component={GrowthDeals}
            />
            <PrivateRoute
                exact
                path="/growth-voting/deals/:id"
                permissions={["p_can_view_growth_voting"]}
                component={GrowthDealDetail}
            />
            <PrivateRoute
                exact
                path="/growth-voting/companies"
                permissions={["p_can_view_growth_voting"]}
                component={GrowthCompanies}
            />

            {/* RESEARCH */}
            <PrivateRoute
                exact
                path="/research/morning-meeting"
                permissions={[]}
                component={MorningMeeting}
            />
            <PrivateRoute
                exact
                path="/research/morning-meeting-notes"
                permissions={[]}
                component={MorningMeetingDetail}
            />
            <PrivateRoute
                exact
                path="/research/new-notes"
                permissions={[]}
                component={NewNotes}
            />
            <PrivateRoute
                exact
                path="/research/investment-cases"
                permissions={[
                    "P_bqmq_can_host",
                    "p_bqmq_can_view_results",
                    "p_bqmq_can_vote",
                ]}
                component={InvestmentCaseView}
            />

            {/*   CHRONOGRPAH   */}
            <PrivateRoute
                exact
                path="/chronograph/refresh"
                permissions={["p_chron_can_refresh"]}
                component={ChronDataRefresh}
            />

            {/*   MISSION CONTROL   */}
            <PrivateRoute
                exact
                path="/mission-control/fund-metrics"
                permissions={[
                    "p_can_view_mission_control",
                ]}
                component={FundMetrics}
            />

            <PrivateRoute
                exact
                path="/mission-control/tables"
                permissions={[
                    "p_can_view_mission_control",
                ]}
                component={Tables}
            />

            <PrivateRoute
                exact
                path="/mission-control/charts-and-analysis"
                permissions={[
                    "p_can_view_mission_control",
                ]}
                component={ChartsAndAnalysis}
            />

            <PrivateRoute
                exact
                path="/mission-control/public-companies"
                permissions={[
                    "p_can_view_mission_control_public",
                ]}
                component={PublicCompanies}
            />

            <PrivateRoute
                exact
                path="/mission-control/private-company-dashboard"
                permissions={[
                    "p_can_view_mission_control",
                ]}
                component={PrivateCompanyDashboard}
            />

            <PrivateRoute
                exact
                path="/mission-control/public-company-dashboard"
                permissions={[
                    "p_can_view_mission_control_public",
                ]}
                component={PublicCompanyDashboard}
            />

            <PrivateRoute
                exact
                path="/mission-control/sustainabilities-dashboard"
                permissions={[
                    "p_can_view_mission_control",
                ]}
                component={SustainabilityDashboard}
            />

            <PrivateRoute
                exact
                path="/just-climate/company-chronograph"
                permissions={[
                    "p_can_view_just_climate",
                ]}
                component={ClimateChronograph}
            />

            <PrivateRenderRoute
                exact
                path="/reporting/thirty-day-summary"
                permissions={[
                    "p_can_view_reporting",
                ]}
                render={(props) => (
                    <UsageStatSummary
                        {...props}
                        title={THIRTY_DAY_TITLE}
                        src={THIRTY_DAY_IFRAME_SRC}
                    />
                )}
            />

            <PrivateRenderRoute
                exact
                path="/reporting/ninety-day-dashboard-summary"
                permissions={[
                    "p_can_view_reporting",
                ]}
                render={(props) => (
                    <UsageStatSummary
                        {...props}
                        title={NINETY_DAY_DASHBOARD_TITLE}
                        src={NINETY_DAY_DASHBOARD_IFRAME_SRC}
                    />
                )}
            />

            <PrivateRenderRoute
                exact
                path="/reporting/ninety-day-user-summary"
                permissions={[
                    "p_can_view_reporting",
                ]}
                render={(props) => (
                    <UsageStatSummary
                        {...props}
                        title={NINETY_DAY_USER_TITLE}
                        src={NINETY_DAY_USER_IFRAME_SRC}
                    />
                )}
            />

            <PrivateRenderRoute
                exact
                path="/reporting/ninety-day-consumption-summary"
                permissions={[
                    "p_can_view_reporting",
                ]}
                render={(props) => (
                    <UsageStatSummary
                        {...props}
                        title={NINETY_DAY_CONSUMPTION_TITLE}
                        src={NINETY_DAY_CONSUMPTION_IFRAME_SRC}
                    />
                )}
            />

            {/*   ADMIN   */}
            <PrivateRoute
                exact
                path="/admin/permissions"
                permissions={["s_update_permissions"]}
                component={Permissions}
            />

            {/*   NOTIFICATIONS   */}
            <PrivateRoute
                exact
                path="/notifications"
                permissions={["p_read_own_notification_web"]}
                component={Notifications}
            />

            {/*  DOCS   */}
            <PrivateRoute
                exact={false}
                path="/docs"
                permissions="p_can_view_docs"
                component={Docs}
            />

            {/*  HELP   */}
            <PrivateRoute
                exact={false}
                path="/help"
                permissions="p_can_view_help"
                component={Help}
            />

            <Route path="/login" component={Login} />
        </Switch>
    );
}
