// FontAwesome
import {
    faCheck,
    faClock,
    faExclamation,
    faSync,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Styles
import StatusContainer from "./Status.styles";

type Props = {
    status: AnalyticsQueueStatus;
};

export default function Status({ status }: Props) {
    let statusDict = {
        FAILED: {
            color: "red",
            icon: faExclamation,
        },
        QUEUED: {
            color: "grey",
            icon: faClock,
        },
        PROCESSING: {
            color: "blue",
            icon: faSync,
        },
        SUCCEEDED: {
            color: "green",
            icon: faCheck,
        },
    };

    return (
        <StatusContainer className={statusDict[status].color}>
            <div className="icon">
                <FontAwesomeIcon icon={statusDict[status].icon} fixedWidth />
            </div>
            <div className="text">
                <p>{status}</p>
            </div>
        </StatusContainer>
    );
}
