import React from "react";

// Types
import { scenarioInputs } from "../ScenarioSelectionTab/types";

// Styles
import ScenarioInputFieldsContainer from "./ScenarioInputFields.styles";

// Material UI
import { Tooltip } from "@mui/material";

// Components
import TextField from "components/Common/TextField/TextField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons";

interface ScenarioInputFieldsProps {
    handleChange: (value: any, name: string) => void;
    newScenarioValues: any;
    readOnly?: boolean;
}

const ScenarioInputFields = ({
    handleChange,
    newScenarioValues,
    readOnly = false,
}: ScenarioInputFieldsProps) => {
    return (
        <ScenarioInputFieldsContainer>
            {scenarioInputs.map((scenario) => {
                let name = scenario.name;
                return (
                    <div key={scenario.id} className="inputDiv">
                        {scenario.tooltip ? (
                            <Tooltip placement="top" title={scenario.tooltip}>
                                <div>
                                    <p className="infoText">{scenario.label}</p>
                                    <FontAwesomeIcon
                                        icon={faInfo}
                                        fixedWidth
                                        className="infoIcon"
                                    />
                                </div>
                            </Tooltip>
                        ) : (
                            <p>{scenario.label}</p>
                        )}
                        <div>
                            {[...Array(12)].map((_, i) => {
                                let index = i + 1;
                                return (
                                    <div key={i}>
                                        <div className="labelDiv">
                                            {"Q" + index}
                                        </div>
                                        <div className="input">
                                            <TextField
                                                disabled={readOnly}
                                                key={name + "_q" + index}
                                                placeholder={0}
                                                type={"number"}
                                                change={(e: any) =>
                                                    handleChange(
                                                        e,
                                                        name + "_q" + index
                                                    )
                                                }
                                                value={
                                                    newScenarioValues[
                                                        name + "_q" + index
                                                    ] || ""
                                                }
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            })}
        </ScenarioInputFieldsContainer>
    );
};
export default ScenarioInputFields;
