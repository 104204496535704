// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Styles
import LargeButtonContainer from "./LargeButton.styles";

type Props = {
    text: string;
    icon: any;
    click: Function;
    disabled?: boolean;
    className?: string;
};

export default function LargeButton({
    text,
    icon,
    click,
    disabled,
    className,
}: Props) {
    return (
        <LargeButtonContainer
            data-cy="save-session-button"
            className={className + " largeBtn " + (disabled ? "disabled" : "")}
            onClick={disabled ? () => {} : () => click()}
        >
            <p>{text}</p>
            <div className={"icon"}>
                <FontAwesomeIcon icon={icon} fixedWidth />
            </div>
        </LargeButtonContainer>
    );
}
