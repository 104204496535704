export const toDate = (date) => {
    return new Date(date)
        .toLocaleString(navigator.language, { dateStyle: "medium" })
        .replace(",", "");
};

export const toDateAndTime = (date) => {
    return new Date(date)
        .toLocaleString(navigator.language, {
            dateStyle: "medium",
        })
        .replace(",", "");
};

export const toDateAndTimeSeconds = (date) => {
    return new Date(date)
        .toLocaleString(navigator.language, {
            dateStyle: "medium",
            timeStyle: "medium",
        })
        .replace(",", "");
};

export const to4DecimalPlaces = (value) => {
    if (Number(value)) {
        return Number(value).toFixed(4);
    } else {
        return value;
    }
};

export const diffColor = (value) => {
    if (Number(value)) {
        if (Number(value) > 0) return "green";
        if (Number(value) < 0) return "red";
    }
    return "";
};

export const toAnalyticsDate = (date) => {
    let rawDate = new Date(date);

    let year = rawDate.getFullYear();
    let month = rawDate.getMonth() + 1;
    month = month < 10 ? "0" + month : month;

    let day = rawDate.getDate();
    day = day < 10 ? "0" + day : day;

    let hour = rawDate.getHours();
    hour = hour < 10 ? "0" + hour : hour;

    let mins = rawDate.getMinutes();
    mins = mins < 10 ? "0" + mins : mins;

    let secs = rawDate.getSeconds();
    secs = secs < 10 ? "0" + secs : secs;

    return (
        year + "-" + month + "-" + day + " " + hour + ":" + mins + ":" + secs
    );
};

export const getTodaysDate = () => {
    let today = new Date();
    let date = today.getDate();
    var displayDate = date < 10 ? "0" + date : date;
    let month = today.getMonth() + 1;
    var displayMonth = month < 10 ? "0" + month : month;
    let year = today.getFullYear();
    return `${year}-${displayMonth}-${displayDate}`;
};

export const getTomorrowsDate = () => {
    let tomorrow = new Date();
    tomorrow.setDate(new Date().getDate() + 1);
    let date = tomorrow.getDate();
    var displayDate = date < 10 ? "0" + date : date;
    let month = tomorrow.getMonth() + 1;
    var displayMonth = month < 10 ? "0" + month : month;
    let year = tomorrow.getFullYear();
    return `${year}-${displayMonth}-${displayDate}`;
};

export const getTime = () => {
    let today = new Date();
    let hour = today.getHours();
    let mins = today.getMinutes();
    return `${hour}${mins}`;
};

export const isObjectEmpty = (obj) => {
    return Object.keys(obj).length === 0;
};

export const sessionCacheData = (name, id, data) => {
    sessionStorage.setItem(name, JSON.stringify({ id: id, data: data }));
};

export const getSessionCachedData = (name, id) => {
    let oldData = JSON.parse(sessionStorage.getItem(name));

    if (oldData && oldData.id === id) {
        return oldData.data;
    } else {
        return false;
    }
};

export const truncateString = (string, length) => {
    return `${string.substring(0, length)}...`;
};

export const isElementInViewport = (el) => {
    let rect = el.getBoundingClientRect();

    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
            (window.innerHeight ||
                document.documentElement
                    .clientHeight) /* or $(window).height() */ &&
        rect.right <=
            (window.innerWidth ||
                document.documentElement.clientWidth) /* or $(window).width() */
    );
};

export const scrollToTopOfPage = () => {
    document.getElementById("mainContainer").scrollTo(0, 0);
};

export const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const parsePoundValue = (value, decimal) => {
    if (value === 0) return "£" + numberWithCommas(value.toFixed());
    if (value < 0) return "-£" + numberWithCommas(Math.abs(value).toFixed());
    else return !value ? "-" : "£" + numberWithCommas(Number(value).toFixed(2));
};
