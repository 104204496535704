import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

// FontAwesome
import { faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Components
import LoadingSpinner from "components/Common/LoadingSpinner/LoadingSpinner";
import Status from "components/Screens/Data/CompanyModel/Status/Status";

// Utils
import * as Database from "utils/database.utils";
import { toDateAndTime } from "utils/general.utils";

// Styles
import BatchPopUpContainer from "./BatchPopUp.styles";

type Props = {
    companyExtractGroup: string;
};

export default function BatchPopUp({ companyExtractGroup }: Props) {
    const history = useHistory();

    const [loading, setLoading] = useState<boolean>(true);
    const [extractionList, setExtractionList] = useState<any[]>([]);

    const getExtractionBatch = () => {
        Database.get(
            "api/extractions?page=1&pageSize=1000&sortKey=processing_datetime&sortOrder=DESC&filter_company_extract_group=" +
                companyExtractGroup
        ).then((res) => {
            if (res.ok) {
                res.json().then((data) => {
                    setExtractionList(data.data);
                    setLoading(false);
                });
            }
        });
    };

    useEffect(() => {
        getExtractionBatch();
    }, []);

    return (
        <BatchPopUpContainer>
            <h3>EXTRACTION BATCH</h3>

            {loading ? (
                <LoadingSpinner />
            ) : (
                <div className="list">
                    {extractionList &&
                        extractionList.map((ext) => (
                            <div
                                className="extItem"
                                onClick={() =>
                                    history.push("extraction?id=" + ext.id)
                                }
                            >
                                <p className="date">
                                    {toDateAndTime(ext.processing_datetime)}
                                </p>
                                <div className="statusCell">
                                    <Status
                                        status={ext.model_status}
                                        extractionId={ext.extraction_id}
                                        info={ext.num_errors_info}
                                        warning={ext.num_errors_warning}
                                        critical={ext.num_errors_critical}
                                    />
                                </div>
                                <FontAwesomeIcon
                                    className="goToBtn"
                                    icon={faArrowAltCircleRight}
                                    fixedWidth
                                />
                            </div>
                        ))}
                </div>
            )}
        </BatchPopUpContainer>
    );
}
