import { useEffect, useState } from "react";

// Styles
import AllScenariosTabContainer from "./EditScenariosTab.styles";

// Components
import TableCell from "components/Common/TableCell/TableCell";
import Table from "components/Common/Table/Table";
import DropDownMenuItem from "components/Common/DropDownMenu/DropDownMenuItem/DropDownMenuItem";
import Actions from "../../Common/Actions/Actions";
import Can from "components/Common/Can/Can";
import SearchOptions from "components/Common/SearchOptions/SearchOptions";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faRefresh, faTrash } from "@fortawesome/free-solid-svg-icons";

// Router
import { useHistory } from "react-router-dom";

// Utils
import * as Database from "utils/database.utils";

// Context
import { useSettings } from "context/Settings.context";
import { uppercaseFirstLetter } from "utils/common.utils";

interface EditScenariosTabProps {}

const EditScenariosTab = ({}: EditScenariosTabProps) => {
    const history = useHistory();
    const { setToast, setShowLoadingToast } = useSettings();
    const [filterQuery, setFilterQuery] = useState<string>("");
    const [reload, setReload] = useState<number>(0);
    const [blockRun, setBlockRun] = useState(false);

    const deleteScenario = async (scenario_id: string) => {
        let res = await Database.remove(`api/fees/scenarios/${scenario_id}`);
        if (res.ok) {
            setToast({
                show: true,
                type: "success",
                message: "Session Deleted",
            });
            setReload(reload + 1);
        }
    };

    const runScenario = async (scenarioId: string) => {
        setShowLoadingToast({
            show: true,
            message: "RUNNING",
        });
        setBlockRun(true);
        let res = await Database.post(
            `api/fees/generation/forecasts/${encodeURI(scenarioId)}`
        );
        if (res.status === 200 || res.status === 201) {
            setToast({
                show: true,
                message: "Scenario run has been successful",
                type: "success",
            });
            setShowLoadingToast({
                show: false,
                message: "",
            });
            setBlockRun(false);
        }
        if (res.status === 500) {
            setToast({
                show: true,
                message: "An error has occured during scenario run",
                type: "error",
            });
        }
        if (res.status === 400) {
            setToast({
                show: true,
                message:
                    "Please wait until current scenario run is finished before running another.",
                type: "warning",
            });
        }
    };

    const columns = [
        {
            id: 1,
            title: "NAME",
            sortable: true,
            value: "scenario_id",
            cell: (data: any) => <TableCell data={data.scenario_id} />,
            show: true,
            fullDataRow: true,
        },
        {
            id: 5,
            title: "STATUS",
            sortable: false,
            value: "status",
            cell: (data: any) => (
                <TableCell data={uppercaseFirstLetter(data.status)} />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 2,
            title: "CREATED BY",
            sortable: true,
            value: "owner",
            cell: (data: any) => <TableCell data={data.owner} />,
            show: true,
            fullDataRow: true,
        },
        {
            id: 3,
            title: "CREATED DATE",
            sortable: true,
            value: "scenario_created_date",
            cell: (data: any) => (
                <TableCell
                    data={new Date(
                        data.scenario_created_date
                    ).toLocaleDateString()}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 4,
            title: "",
            sortable: false,
            value: "",
            cell: (data: any) => (
                <Actions
                    data={data}
                    actions={[
                        <DropDownMenuItem
                            icon={<FontAwesomeIcon icon={faEye} fixedWidth />}
                            text={"VIEW/EDIT"}
                            click={() => {
                                history.push(
                                    `/fees/forecasts/scenario?id=${encodeURIComponent(
                                        data.scenario_id
                                    )}`
                                );
                            }}
                        />,
                        <DropDownMenuItem
                            icon={
                                <FontAwesomeIcon icon={faRefresh} fixedWidth />
                            }
                            text={"RUN"}
                            click={() => {
                                runScenario(data.scenario_id);
                            }}
                        />,
                        <Can
                            yes={
                                <DropDownMenuItem
                                    icon={
                                        <FontAwesomeIcon
                                            icon={faTrash}
                                            fixedWidth
                                        />
                                    }
                                    text={"DELETE"}
                                    click={() => {
                                        deleteScenario(data.scenario_id);
                                    }}
                                />
                            }
                            permissions={["p_can_action_fees"]}
                        />,
                    ]}
                />
            ),
            show: true,
            fullDataRow: true,
        },
    ];

    const filterOptions = [
        {
            id: 1,
            name: "Search SCENARIO",
            value: "scenario_id",
            type: "SEARCH",
        },
    ];
    return (
        <AllScenariosTabContainer>
            <p className="fundTitle">All scenarios</p>
            <SearchOptions
                setFilterQuery={setFilterQuery}
                options={filterOptions}
            />
            <Table
                columns={columns}
                large
                pagination
                defaultSortKey="scenario_created_date"
                defaultSortOrder="DESC"
                filterQuery={filterQuery}
                emptyText="NO SCENARIOS"
                url="api/fees/scenarios/"
                reload={reload}
            />
        </AllScenariosTabContainer>
    );
};
export default EditScenariosTab;
