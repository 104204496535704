import React from "react";

// Styles
import DenominatorViewContainer from "./DenominatorView.styles";

interface DenominatorViewProps {}

const DenominatorView = ({}: DenominatorViewProps) => {
    return (
        <DenominatorViewContainer>
            <div className="iframeContainer">
                <iframe
                    title="ESG - Denominator vLive (QS)"
                    src="https://app.powerbi.com/reportEmbed?reportId=efef084b-5b84-4d71-a11c-437342c35267&autoAuth=true&ctid=bf4450a2-bfcb-472d-8c40-1425eafb1a3b&filterPaneEnabled=false"
                    width="100%"
                    height="100%"
                    allowFullScreen
                    frameBorder={0}
                />
            </div>
        </DenominatorViewContainer>
    );
};
export default DenominatorView;
