import { useState, useContext, createContext, useEffect } from "react";

// Context
import { useAuth } from "./Auth.context";

// Utils
import * as Database from "../utils/database.utils";

// Logger
import Logger from "utils/logger.utils";
import { hasPermissions } from "../utils/permissions.utils";
const Log = new Logger();
Log.init("Notifications.Context");

const NotificationsContext = createContext();

export function NotificationsProvider({ children }) {
    const { user } = useAuth();

    const [unknownCompanies, setUnknownCompanies] = useState(0);
    const [newStockSplits, setNewStockSplits] = useState(0);
    const [userNotifications, setUserNotifications] = useState(0);
    const [failedCompanyExtractions, setFailedCompanyExtractions] = useState(0);
    const [holdingCompanyExtractions, setHoldingCompanyExtractions] =
        useState(0);

    const getNotifications = async () => {
        Log.info("getNotifications()");

        if (hasPermissions("p_update_companies", user.permissions)) {
            let newCompRes = await Database.get(
                "api/companies/new-companies?page=1&pageSize=1000&sortKey=first_detected_date&sortOrder=DESC"
            );
            if (newCompRes.ok) {
                let data = await newCompRes.json();
                setUnknownCompanies(data.total);
            }
        }

        if (hasPermissions("p_update_companies", user.permissions)) {
            let stockSplitRes = await Database.get(
                "api/stock_splits/auto_detected?page=1&pageSize=1000&sortKey=detected_datetime&sortOrder=DESC"
            );
            if (stockSplitRes.ok) {
                let data = await stockSplitRes.json();
                setNewStockSplits(data.total);
            }
        }

        if (hasPermissions("p_read_own_notification_web", user.permissions)) {
            let notificationsRes = await Database.get(
                "api/notifications/web?filter_read=false&pageSize=10&page=1&sortKey=created_on&sortOrder=DESC"
            );
            if (notificationsRes.ok) {
                let data = await notificationsRes.json();
                setUserNotifications(data);
            }
        }
    };

    const pollForNotifications = (callback) => {
        setInterval(callback, 10000);
    };

    const requestNotifications = () => {
        getNotifications();
    };

    useEffect(() => {
        if (user) pollForNotifications(requestNotifications);
    }, [user]);

    return (
        <NotificationsContext.Provider
            value={{
                unknownCompanies,
                setUnknownCompanies,
                newStockSplits,
                setNewStockSplits,
                userNotifications,
                setUserNotifications,
                failedCompanyExtractions,
                setFailedCompanyExtractions,
                holdingCompanyExtractions,
                setHoldingCompanyExtractions,
            }}
        >
            {children}
        </NotificationsContext.Provider>
    );
}

export const useNotifications = () => {
    return useContext(NotificationsContext);
};
