import { useState, useEffect, useMemo } from "react";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimesCircle } from "@fortawesome/free-solid-svg-icons";

// Components
import FilterButton from "components/Common/SearchOptions/FilterButton/FilterButton";
import Switch from "components/Common/Switch/Switch";

// Utils
import { toDateAndTimeSeconds } from "utils/general.utils";
import * as _ from "lodash";

// Styles
import SearchOptionsContainer from "./SearchOptions.styles";
import { useSettings } from "../../../context/Settings.context";

type Props = {
    setFilterQuery: Function;
    options: Array<FilterOption>;
    localFilter?: boolean;
    forceUpperCase?: boolean;
    actions?: any;
    noDebounce?: boolean;
    showAll?: boolean
};

const SearchOptions: React.FC<Props> = ({
    setFilterQuery,
    options,
    localFilter,
    forceUpperCase,
    actions,
    noDebounce = false,
    showAll = true,
}) => {
    const [filters, setFilters] = useState<any>([]);
    const [startDate, setStartDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>();
    const [date, setDate] = useState<Date | null>(null);

    const { defaultFilters, setDefaultFilters } = useSettings();

    const initFilters = () => {
        let newFilters = {};

        options.forEach((item) => {
            if (["DROPDOWN", "SWITCH"].includes(item.type)) {
                newFilters = {
                    ...newFilters,
                    [item.value!]: defaultFilters[item.value!]
                        ? defaultFilters[item.value!]
                        : false,
                };
            } else if (["DATE"].includes(item.type)) {
                newFilters = {
                    ...newFilters,
                    [item.value + "_start"]: false,
                    [item.value + "_end"]: false,
                };
            } else if (["SINGLEDATE"].includes(item.type)) {
                newFilters = {
                    ...newFilters,
                    [item.value + "_date"]: false
                };
            } else if (["SEARCH"].includes(item.type)) {
                newFilters = {
                    ...newFilters,
                    [item.value!]: defaultFilters[item.value!]
                        ? defaultFilters[item.value!]
                        : "",
                };
            }
        });
        setDefaultFilters({});
        setFilters(newFilters);
    };

    const isFilterActive = () => {
        for (const [key, value] of Object.entries(filters)) {
            if (value || value === 0) return true;
        }
        return false;
    };

    const debouncedResults = useMemo(() => {
        if (noDebounce) {
            return (filterString: any) => setFilterQuery(filterString);
        } else {
            return _.debounce(
                (filterString) => setFilterQuery(filterString),
                400
            );
        }
    }, []);

    const buildFilterString = () => {
        if (localFilter) {
            setFilterQuery(filters);
        } else {
            let filterArray = [];

            for (const [key, value] of Object.entries(filters)) {
                if (value || value === 0) {
                    filterArray.push(`filter_${key}=${value}`);
                }
            }

            if (filterArray.length > 0) {
                let filterString = "&";

                filterArray.forEach((item, index) => {
                    if (index === filterArray.length - 1)
                        filterString = filterString + item;
                    else filterString = filterString + item + "&";
                });

                debouncedResults(filterString);
            } else {
                debouncedResults("");
            }
        }
    };

    const handleChangeDropDown = (value: string, filter: string) => {
        let newFilters = {
            ...filters,
            [filter]: value,
        };

        setFilters(newFilters);
    };

    useEffect(() => {
        if (Object.entries(filters).length > 0) {
            buildFilterString();
        }
    }, [filters]);

    useEffect(() => {
        initFilters();
    }, []);

    useEffect(() => {
        if (Object.entries(filters).length > 0) {
            if (startDate && endDate) {
                let endDateMidnight = new Date(endDate);
                endDateMidnight.setHours(23);
                endDateMidnight.setMinutes(59);
                endDateMidnight.setSeconds(59);

                let newFilters = {
                    ...filters,
                    date_start: toDateAndTimeSeconds(startDate),
                    date_end: toDateAndTimeSeconds(endDateMidnight),
                };
                setFilters(newFilters);
            } else {
                let newFilters = {
                    ...filters,
                    date_start: false,
                    date_end: false,
                };
                setFilters(newFilters);
            }
        }
    }, [startDate, endDate]);

    useEffect(() => {
        if (Object.entries(filters).length > 0) {
            if (date) {
                let newFilters = {
                    ...filters,
                    date_date: toDateAndTimeSeconds(date),
                };
                setFilters(newFilters);
            } else {
                let newFilters = {
                    ...filters,
                    date_date: false,
                };
                setFilters(newFilters);
            }
        }
    }, [date]);

    return (
        <SearchOptionsContainer className={"searchOptions"}>
            {showAll && <div
                className={"showAll " + isFilterActive()}
                onClick={initFilters}
            >
                ALL
            </div>}

            {Object.entries(filters).length > 0 &&
                options.map((filter) => (
                    <div
                        key={filter.id + "-1"}
                        className={
                            filter.type === "SEARCH"
                                ? "searchDiv searchFilter"
                                : "searchFilter"
                        }
                    >
                        {filter.type === "DROPDOWN" && (
                            <FilterButton
                                name={filter.name}
                                options={filter.options}
                                type="list"
                                searchable={filter.searchable}
                                value={filters[filter.value!]}
                                setValue={(value: string) =>
                                    handleChangeDropDown(value, filter.value!)
                                }
                                disabled={filters[filter.disabled!]}
                                key={filter.id + "-1"}
                            />
                        )}

                        {filter.type === "DATE" && (
                            <FilterButton
                                name={filter.name}
                                type="date"
                                startDate={filters[filter.value + "_start"]}
                                setStartDate={setStartDate}
                                endDate={filters[filter.value + "_end"]}
                                setEndDate={setEndDate}
                            />
                        )}

                        {filter.type === "DATE_RANGE" && (
                            <FilterButton
                                name={filter.name}
                                type="date"
                                startDate={startDate}
                                setStartDate={setStartDate}
                                endDate={endDate}
                                setEndDate={setEndDate}
                            />
                        )}

                        {filter.type === "SINGLEDATE" && (
                            <FilterButton
                                name={filter.name}
                                type="singledate"
                                day={filters[filter.value + "_date"]}
                                setDay={setDate}
                            />
                        )}

                        {filter.type === "SWITCH" && (
                            <Switch
                                value={filters[filter.value!]}
                                setValue={(value: string) =>
                                    handleChangeDropDown(value, filter.value!)
                                }
                                label={filter.name}
                            />
                        )}

                        {filter.type === "SEARCH" && (
                            <div className="searchBox">
                                <input
                                    placeholder={filter.name}
                                    value={filters[filter.value!]}
                                    onChange={(e) =>
                                        handleChangeDropDown(
                                            e.target.value,
                                            filter.value!
                                        )
                                    }
                                />
                                {filters[filter.value!] && (
                                    <FontAwesomeIcon
                                        icon={faTimesCircle}
                                        fixedWidth
                                        onClick={() =>
                                            handleChangeDropDown(
                                                "",
                                                filter.value!
                                            )
                                        }
                                    />
                                )}
                                <FontAwesomeIcon icon={faSearch} fixedWidth />
                            </div>
                        )}
                    </div>
                ))}

            {actions}
        </SearchOptionsContainer>
    );
};

export default SearchOptions;
