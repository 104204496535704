// Components
import Can from "components/Common/Can/Can";
import NewScenario from "./NewScenario/NewScenario";
import SavedScenario from "./SavedScenario/SavedScenario";

// Styles
import ScenarioSelectionTabContainer from "./ScenarioSelectionTab.styles";

interface ScenarioSelectionTabProps {}

const ScenarioSelectionTab = ({}: ScenarioSelectionTabProps) => {
    return (
        <ScenarioSelectionTabContainer>
            {/* <SavedScenario /> */}
            <Can permissions={["p_can_action_fees"]} yes={<NewScenario />} />
        </ScenarioSelectionTabContainer>
    );
};
export default ScenarioSelectionTab;
