import { useState } from "react";
//Context
import { useFees } from "context/Fees.context";
// Styles
import RedemptionsContainer from "./Redemptions.styles";

// Components
import Table from "components/Common/Table/Table";
import TableCell from "components/Common/TableCell/TableCell";
import EditableCell from "components/Screens/Admin/ValidationRules/EditableCell/EditableCell";

// Types
import { SeparateAccountsRow } from "../../utils/types";
// Utils
import FloatToCurrencyParser from "../../utils/FloatToCurrencyParser";
import FloatToPercParser from "../../utils/FloatToPercParser";
import formatDate from "../../utils/FormatDate";
import StringToFloatParser from "../../utils/StringToFloatParser";
interface RedemptionsProps {
    fund?: string;
    region?: string;
    period?: string;
}

const Redemptions = ({ fund, region, period }: RedemptionsProps) => {
    const [filterQuery, setFilterQuery] = useState<string>("");
    const [tableIsLoading, setTableIsLoading] = useState<boolean>(false);
    const [reload, setReload] = useState(0);
    const { redemptions, updateFeesData, period: selectedPeriod } = useFees();

    const columns = [
        {
            id: 0,
            title: "ACCOUNT ID",
            sortable: false,
            value: "account_id",
            cell: (data: SeparateAccountsRow) => (
                <TableCell data={data.account_id} />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 1,
            title: "ACCOUNT NAME",
            sortable: false,
            value: "account_id",
            cell: (data: SeparateAccountsRow) => (
                <TableCell data={data.account_name} />
            ),
            show: true,
            fullDataRow: true,
        },

        {
            id: 2,
            title: "REDEMPTION DATE",
            sortable: false,
            value: "redemption_date",
            cell: (data: SeparateAccountsRow) => (
                <EditableCell
                    rowId={data.id}
                    cellValueType="string"
                    cellValue="comments"
                    rowIdKey={"id"}
                    initialValue={
                        data.redemption_date
                            ? formatDate(data.redemption_date)
                            : "-"
                    }
                    type="dateField"
                    setTableIsLoading={setTableIsLoading}
                    updateTable={() => setReload(reload + 1)}
                    onUpdate={(idx: number, value: string) => {
                        return updateFeesData(
                            "redemption_date",
                            data.account_id,
                            selectedPeriod,
                            data.tranche,
                            value,
                            {
                                id: data.uuid,
                                version: data.version,
                            }
                        );
                    }}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 4,
            title: "TRANCHE",
            sortable: false,
            value: "tranche",
            cell: (data: SeparateAccountsRow) => (
                <TableCell data={data.tranche} />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 13,
            title: "TOTAL REDEMPTION AMOUNT",
            sortable: false,
            value: "total_redemptions",
            cell: (data: SeparateAccountsRow) => (
                <EditableCell
                    rowId={data.id}
                    cellValueType="string"
                    cellValue="comments"
                    rowIdKey={"id"}
                    initialValue={FloatToCurrencyParser(
                        data.total_redemptions,
                        data.currency
                    )}
                    type="numericalField"
                    setTableIsLoading={setTableIsLoading}
                    updateTable={() => setReload(reload + 1)}
                    inputParser={StringToFloatParser}
                    onUpdate={(idx: number, value: number) => {
                        return updateFeesData(
                            "total_redemptions",
                            data.account_id,
                            selectedPeriod,
                            data.tranche,
                            value,
                            {
                                id: data.uuid,
                                version: data.version,
                                total_account: data.max_nav - data.total_events,
                                gross_amount: data.gross_market_value_account,
                            }
                        );
                    }}
                />
            ),

            show: true,
            fullDataRow: true,
        },
        {
            id: 5,
            title: "REDEMPTION AMOUNT",
            sortable: false,
            value: "redemption_amount",
            cell: (data: SeparateAccountsRow) => (
                <EditableCell
                    rowId={data.id}
                    cellValueType="string"
                    cellValue="comments"
                    rowIdKey={"id"}
                    initialValue={FloatToCurrencyParser(
                        data.redemption_amount,
                        data.currency
                    )}
                    type="numericalField"
                    setTableIsLoading={setTableIsLoading}
                    updateTable={() => setReload(reload + 1)}
                    inputParser={StringToFloatParser}
                    onUpdate={(idx: number, value: string) => {
                        return updateFeesData(
                            "redemption_amount",
                            data.account_id,
                            selectedPeriod,
                            data.tranche,
                            idx,
                            value
                        );
                    }}
                />
            ),

            show: true,
            fullDataRow: true,
        },
        {
            id: 3,
            title: "OWNERSHIP AT REDEMPTION DATE",
            sortable: false,
            value: "ownership_at_redemption_date",
            cell: (data: SeparateAccountsRow) => (
                <TableCell
                    data={FloatToPercParser(data.ownership_at_redemption_date)}
                />
            ),
            show: true,
            fullDataRow: true,
        },

        {
            id: 6,
            title: "GROSS MARKET VALUE ACCOUNT",
            sortable: false,
            value: "gross_market_value_account",
            cell: (data: SeparateAccountsRow) => (
                <TableCell
                    data={FloatToCurrencyParser(
                        data.gross_market_value_account,
                        data.currency
                    )}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 7,
            title: "MANAGEMENT FEE",
            sortable: false,
            value: "management_fee",
            cell: (data: SeparateAccountsRow) => (
                <TableCell
                    data={FloatToCurrencyParser(
                        data.management_fee,
                        data.currency
                    )}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 8,
            title: "MARKET VALUE OF TRANCHE",
            sortable: false,
            value: "market_value_of_tranche",
            cell: (data: SeparateAccountsRow) => (
                <TableCell
                    data={FloatToCurrencyParser(
                        data.market_value_of_tranche,
                        data.currency
                    )}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 9,
            title: "MARKET VALUE OF BENCHMARK",
            sortable: false,
            value: "market_value_of_benchmark",
            cell: (data: SeparateAccountsRow) => (
                <TableCell
                    data={FloatToCurrencyParser(
                        data.market_value_of_benchmark,
                        data.currency
                    )}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 10,
            title: "PERFORMANCE FEE PAYABLE AT DATE",
            sortable: false,
            value: "performance_fee_payable_at_date",
            cell: (data: SeparateAccountsRow) => (
                <TableCell
                    data={FloatToCurrencyParser(
                        data.performance_fee_payable_at_date,
                        data.currency
                    )}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 11,
            title: "OWNERSHIP REDEEMED OF TRANCHE",
            sortable: false,
            value: "ownership_redeemed_of_tranche",
            cell: (data: SeparateAccountsRow) => (
                <TableCell
                    data={FloatToPercParser(data.ownership_redeemed_of_tranche)}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 12,
            title: "PERFORMANCE FEE PAYMENT ON REDEMPTION",
            sortable: false,
            value: "performance_fee_payment_on_redemption",
            cell: (data: SeparateAccountsRow) => (
                <TableCell
                    data={FloatToCurrencyParser(
                        data.performance_fee_payment_on_redemption,
                        data.currency
                    )}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 15,
            title: "NEW OWNERSHIP SPLIT",
            sortable: false,
            value: "new_ownship_split",
            cell: (data: SeparateAccountsRow) => (
                <TableCell data={FloatToPercParser(data.new_ownship_split)} />
            ),
            show: true,
            fullDataRow: true,
        },
    ];
    return (
        <RedemptionsContainer>
            <div className="tableDiv">
                <Table
                    emptyText={"No Data"}
                    showEmptyIcon={false}
                    data={redemptions}
                    columns={columns}
                    filterQuery={filterQuery + "&period=" + period}
                    reload={reload}
                    pagination
                    defaultSortKey="event_date"
                    defaultSortOrder="DESC"
                    stickyColumns={[0, 1]}
                />
            </div>
        </RedemptionsContainer>
    );
};
export default Redemptions;
