import React, { useEffect, useState } from "react";
import { Tooltip } from "@mui/material";

// Styles
import VoteIconContainer from "./VoteIcon.styles";

// Types
import {DealVote} from "assets/interfaces/growth_voting";

// Utils
import {getVoteName} from "utils/growth_voting.utils";


interface Porps {
    vote: DealVote;
}


const VoteIcon  = ({vote}: Porps) => {
    const getTooltip = () => {
        let name = getVoteName(vote.type);
        if (vote.open) {
            return "Open " + name + " vote"
        } else {
            return "Closed " + name + " vote"
        }
    }

    return (
        <VoteIconContainer>
            <Tooltip title={getTooltip()} placement="top">
                <div className={"vote " + (vote?.open ? 'open' : 'closed')}>
                    {vote.type.toUpperCase()}
                </div>
            </Tooltip>
        </VoteIconContainer>
    );
};

export default VoteIcon
