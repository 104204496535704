import FloatToCurrencyParser from "./FloatToCurrencyParser";
import FloatToPercParser from "./FloatToPercParser";
// Components
import TableCell from "components/Common/TableCell/TableCell";
import { Dispatch, SetStateAction } from "react";
import { SeparateAccountsRow } from "./types";

const useCellMap = (
    setReload: Dispatch<SetStateAction<number>>,
    reload: number,
    setTableIsLoading: Dispatch<SetStateAction<boolean>>
) => {
    return (qtr: number) => {
        const getTableCell = (
            value: string,
            valueParser: (value: number, currency: string) => string
        ) => {
            return (data: SeparateAccountsRow) => (
                <TableCell
                    data={valueParser(
                        data[value as keyof SeparateAccountsRow] as number,
                        data.currency
                    )}
                />
            );
        };

        return {
            IOO: getTableCell(`ioo_${qtr}`, FloatToCurrencyParser),
            "CUSTODY NAV": getTableCell(
                `custody_nav_${qtr}`,
                FloatToCurrencyParser
            ),
            "CUSTODY ACCRUED EXPENSES": getTableCell(
                `custody_accrued_expenses_${qtr}`,
                FloatToCurrencyParser
            ),
            VARIANCE: getTableCell(`variance_${qtr}`, FloatToCurrencyParser),
            "VARIANCE %": getTableCell(`variance_%_${qtr}`, FloatToPercParser),
        };
    };
};
export default useCellMap;
