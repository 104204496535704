import styled from 'styled-components';

export default styled.div`
.upload-container, .file-type-container, .upload-success { padding: 10px 20px; }


.success-response, .success-message {
    text-align: center;
}

.success-message {
    margin-bottom: 15px;
    margin-top: 30px;
    color:  ${(props) => props.theme.permissionsAdd};
}

.success-response {
    padding: 10px 0px;
    .name {
    color:  ${(props) => props.theme.pageSubTitleText};
    }
}

.upload-new-file {
    display: flex;
    justify-content: center;
    padding: 10px 0px;
}
`;
