import styled from 'styled-components';
import breakpoints from "assets/styles/breakpoints";

export default styled.div`
    .sectionTitle {
        font-size: 25px;
        letter-spacing: 0.1em;

        color: ${(props) => props.theme.pageTitleText};
        margin-bottom: 10px;
        margin-top: 20px;
    }

    .title {
        letter-spacing: 0.1em;
        color: ${(props) => props.theme.pageTitleText};
    }

    .header {
        display: flex;
        justify-content: space-between;

        .right {
            padding: 20px;
        }

        .signed-off {
            display: flex;
            align-items: center;
            border: 1px solid ${(props) => props.theme.subMenuBackground};
            border-radius: 10px;
            padding: 12px;

            .details {
                font-size: 0.9rem;
                color: ${(props) => props.theme.subMenuText};
                margin-left: 10px;
            }
        }
    }

     .green {
        color: ${(props) => props.theme.permissionsAdd};
     }

    .red {
       color: ${(props) => props.theme.permissionsDelete};
    }

     @media only screen and (${breakpoints.device.sm}) {
        .header {
            display: block;
        }
    }
`;
