// @ts-nocheck

import { useEffect, useState } from "react";

// Libraries
import queryString from "query-string";

// FontAwesome
import {
    faArrowRight,
    faCheckCircle,
    faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Components
import LoadingSpinner from "components/Common/LoadingSpinner/LoadingSpinner";
import PageTitle from "components/Common/PageTitle/PageTitle";
import Tabs from "components/Common/Tabs/Tabs";
import Review from "components/Screens/BQMQ/Setup/Review/Review";
import SelectAnalyst from "components/Screens/BQMQ/Setup/SelectAnalyst/SelectAnalyst";
import SelectCompany from "components/Screens/BQMQ/Setup/SelectCompany/SelectCompany";
import SelectDate from "components/Screens/BQMQ/Setup/SelectDate/SelectDate";
import SelectHost from "components/Screens/BQMQ/Setup/SelectHost/SelectHost";
import SelectUsers from "components/Screens/BQMQ/Setup/SelectUsers/SelectUsers";

// Utils
import { hasSetupChanged } from "utils/bqmq.utils";
import { applyTimeZoneOffset } from "utils/common.utils";
import * as Database from "utils/database.utils";

// Styles
import { useBQMQ } from "context/BQMQ.context";
import EditSessionContainer from "./EditSession.styles";

export default function EditSession() {
    const { setMyVotes, analysts, sessions } = useBQMQ();

    const [tab, setTab] = useState<
        "company" | "chair" | "analyst" | "users" | "review" | ""
    >("company");

    const [originalState, setOriginalState] = useState<any>({});

    const [loading, setLoading] = useState(true);
    const [hasEdited, setHasEdited] = useState(false);

    const [sessionID, setSessionID] = useState("");

    const [company, setCompany] = useState(null);
    const [analyst, setAnalyst] = useState(null);
    const [chair, setChair] = useState("");
    const [users, setUsers] = useState<any[]>([]);
    const [date, setDate] = useState<any>(null);

    const [scheduledEvents, setScheduledEvents] = useState<
        ScheduledEvent[] | []
    >([]);
    const [eventArray, setEventArray] = useState<any[]>([]);

    const getUserList = (invitedUsers: any, coreUsers: any) => {
        return analysts
            .sort((a: any, b: any) => a.label.localeCompare(b.label))
            .map((user: any) => ({
                name: user.label,
                id: user.value,
                active: false,
                completed: false,
                core: coreUsers.map((obj: any) => obj.id).includes(user.value),
                invited: invitedUsers
                    .map((obj: any) => obj.id)
                    .includes(user.value),
            }));
    };

    const getScheduledEvents = async (id, date) => {
        const scheduledEvents = sessions
            .filter((session) => session.id !== id)
            .map((session: any) => ({
                title: `${applyTimeZoneOffset(session.date, "HH:mm")}: ${
                    session.company.name
                }`,
                start: session.date,
                end: session.date,
                allDay: true,
            }));

        date
            ? setEventArray([
                  ...scheduledEvents,
                  {
                      title: `> ${applyTimeZoneOffset(date, "HH:mm")}: ${
                          company ? company.name : "NEW SESSION"
                      }`,
                      start: date,
                      end: date,
                      allDay: true,
                  },
              ])
            : setEventArray(scheduledEvents);

        setScheduledEvents(scheduledEvents);

        setLoading(false);
    };

    const getOldSession = async () => {
        const id = queryString.parse(window.location.search).id;
        setSessionID(id);

        const res = await Database.get("api/bqmq_voting/session/" + id);
        let data = await res.json();
        data = data.data;
        setCompany(data.company);
        setAnalyst(data.presenter);
        setChair(data.host);
        setDate(new Date(data.date));

        let invitedUsers = data.analysts;

        let coreUsers = data.core;

        let usersList = getUserList(invitedUsers, coreUsers);
        setUsers(usersList);

        let originalStateObj: any = {
            company: String(data.company.id),
            chair: data.host.id,
            analyst: data.presenter.id,
            users: JSON.stringify([...usersList]),
            date: new Date(data.date),
        };

        setOriginalState({ ...originalStateObj });

        getScheduledEvents(id, data.date);
    };

    useEffect(() => {
        const existingVotes = localStorage.getItem("bqmq");
        if (existingVotes) {
            localStorage.removeItem("bqmq");
            setMyVotes({});
        }
        getOldSession();
    }, []);

    const currentState = {
        company: String(company?.id),
        chair: chair?.id,
        analyst: analyst?.id,
        users: JSON.stringify([...users]),
        date,
    };

    useEffect(() => {
        if (!loading && originalState) {
            setHasEdited(hasSetupChanged(originalState, currentState));
        }
    }, [loading, originalState, currentState, tab]);

    return (
        <EditSessionContainer className={"pageContainer"}>
            <PageTitle title="EDIT SESSION" />

            {loading ? (
                <div className={"loadingData"}>
                    <LoadingSpinner />
                </div>
            ) : (
                <Tabs
                    defaultTab={tab}
                    setTab={setTab}
                    dontChangeUrl
                    tabs={[
                        {
                            title: "COMPANY",
                            value: "company",
                            content: (
                                <SelectCompany
                                    company={company}
                                    setCompany={setCompany}
                                />
                            ),
                            icon: (
                                <FontAwesomeIcon
                                    className={
                                        "tabIcon " + (company ? "complete" : "")
                                    }
                                    icon={
                                        company ? faCheckCircle : faTimesCircle
                                    }
                                    fixedWidth
                                />
                            ),
                        },
                        {
                            title: "CHAIR",
                            value: "chair",
                            content: (
                                <SelectHost
                                    chair={chair}
                                    setChair={setChair}
                                    users={users}
                                    setUsers={setUsers}
                                />
                            ),
                            icon: (
                                <FontAwesomeIcon
                                    className={
                                        "tabIcon " + (chair ? "complete" : "")
                                    }
                                    icon={chair ? faCheckCircle : faTimesCircle}
                                    fixedWidth
                                />
                            ),
                        },
                        {
                            title: "PRESENTER",
                            value: "analyst",
                            content: (
                                <SelectAnalyst
                                    analyst={analyst}
                                    setAnalyst={setAnalyst}
                                    users={users}
                                    setUsers={setUsers}
                                />
                            ),
                            icon: (
                                <FontAwesomeIcon
                                    className={
                                        "tabIcon " + (analyst ? "complete" : "")
                                    }
                                    icon={
                                        analyst ? faCheckCircle : faTimesCircle
                                    }
                                    fixedWidth
                                />
                            ),
                        },
                        {
                            title: "ATTENDEES",
                            value: "users",
                            content: (
                                <SelectUsers
                                    users={users}
                                    setUsers={setUsers}
                                    chair={chair}
                                    analyst={analyst}
                                />
                            ),
                            icon: (
                                <FontAwesomeIcon
                                    className={
                                        "tabIcon " +
                                        (users.some(
                                            (user: any) => user.core == true
                                        ) &&
                                        users.some(
                                            (user: any) => user.core == true
                                        )
                                            ? "complete"
                                            : "")
                                    }
                                    icon={
                                        users.some(
                                            (user: any) => user.core == true
                                        )
                                            ? faCheckCircle
                                            : faTimesCircle
                                    }
                                    fixedWidth
                                />
                            ),
                        },
                        {
                            title: "DATE",
                            value: "date",
                            content: (
                                <SelectDate
                                    date={date}
                                    setDate={setDate}
                                    company={company}
                                    eventArray={eventArray}
                                    setEventArray={setEventArray}
                                    scheduledEvents={scheduledEvents}
                                />
                            ),
                            icon: (
                                <FontAwesomeIcon
                                    className={
                                        "tabIcon " + (date ? "complete" : "")
                                    }
                                    icon={date ? faCheckCircle : faTimesCircle}
                                    fixedWidth
                                />
                            ),
                        },
                        {
                            title: "REVIEW & SAVE",
                            value: "review",
                            content: (
                                <Review
                                    company={company}
                                    chair={chair}
                                    analyst={analyst}
                                    users={users}
                                    date={date}
                                    updateSessionID={sessionID}
                                />
                            ),
                        },
                    ]}
                />
            )}

            {hasEdited && tab !== "review" && (
                <div
                    className={"saveChangesButton"}
                    onClick={() => setTab("review")}
                >
                    REVIEW CHANGES
                    <FontAwesomeIcon icon={faArrowRight} fixedWidth />
                </div>
            )}
        </EditSessionContainer>
    );
}
