import { useHistory } from "react-router-dom";

// Material UI
import Tooltip from "@mui/material/Tooltip";

// Images
import GIMLogo from "assets/images/generationGwhite.png";

// Context
import { useAuth } from "context/Auth.context";

// Styles
import MenuLogoContainer from "./MenuLogo.styles";

export default function MenuLogo() {
    const history = useHistory();

    const click = () => {
        history.push("/");
    };

    return (
        <MenuLogoContainer onClick={click} id={"menuLogo"}>
            <Tooltip title={"HOME"} placement="right">
                <div className={"logo"}>
                    <img src={GIMLogo} alt={"GIM LOGO"} />
                </div>
            </Tooltip>
        </MenuLogoContainer>
    );
}
