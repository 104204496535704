// FontAwesome
import { faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Utils
import { toDate } from "utils/general.utils";

// Components
import Table from "components/Common/Table/Table";
import TableCell from "components/Common/TableCell/TableCell";
import ModesCell from "components/Screens/BQMQ/Results/ModesCell/ModesCell";

// Styles
import BQMQListContainer from "./BQMQList.styles";

type Props = { bqmqLoading: boolean; bqmqData: any };

export default function BQMQList({ bqmqLoading, bqmqData }: Props) {
    const usersCompletedCount = (data: any) => {
        return `${data.voters.length} / ${
            data.analysts.length + data.core.length
        }`;
    };

    const columns = [
        {
            id: 6,
            title: "START DATE",
            sortable: false,
            value: "date",
            cell: (data: string) => <TableCell data={toDate(data)} />,
            show: true,
        },
        {
            id: 7,
            title: "USERS",
            sortable: false,
            value: "users",
            cell: (data: string) => (
                <>
                    <FontAwesomeIcon
                        className={"votesIcons"}
                        icon={faThumbsUp}
                        fixedWidth
                    />
                    {usersCompletedCount(data)}
                </>
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 8,
            title: "MODE",
            sortable: false,
            value: "mode",
            cell: (data: any) => {
                const mode = { BQ: data.bq, MQ: data.mq };
                return <ModesCell mode={mode} />;
            },

            show: true,
            fullDataRow: true,
        },
    ];

    return (
        <BQMQListContainer>
            <Table
                loading={bqmqLoading}
                data={bqmqData}
                columns={columns}
                filterQuery={""}
                defaultSortKey="start_date"
                defaultSortOrder={"DESC"}
                emptyText="NO BQMQ DATA"
                skeletonLength={3}
            />
        </BQMQListContainer>
    );
}
