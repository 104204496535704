import {
    faGlobe,
    faLineChart,
    faCoins,
    faTable,
    faChartColumn,
    faLock,
} from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import SubMenuItem from "../../SubMenuItem/SubMenuItem";
import SubMenuTitle from "../../SubMenuTitle/SubMenuTitle";

// Styles
import MissionControlContainer from "./MissionControl.styles";

interface MissionControlProps {}

const MissionControl = ({}: MissionControlProps) => {
    const history = useHistory();

    return (
        <MissionControlContainer className={"submenu"}>
            <div className={"top"}>
                <div>
                    <h1 style={{ width: "90%" }}>MISSION CONTROL</h1>
                </div>
                <hr />
                <SubMenuTitle
                    text={"FUND LEVEL"}
                    permissions={[
                        "p_can_view_mission_control",
                        "p_can_view_mission_control_public",
                    ]}
                />

                <SubMenuItem
                    text={"Fund Metrics"}
                    active={
                        history.location.pathname ===
                        "/mission-control/fund-metrics"
                    }
                    icon={faCoins}
                    click={() => history.push("/mission-control/fund-metrics")}
                    permissions={["p_can_view_mission_control"]}
                />
                <SubMenuItem
                    text={"Tables"}
                    active={
                        history.location.pathname === "/mission-control/tables"
                    }
                    icon={faTable}
                    click={() => history.push("/mission-control/tables")}
                    permissions={["p_can_view_mission_control"]}
                />

                <SubMenuItem
                    text={"Charts And Analysis"}
                    active={
                        history.location.pathname ===
                        "/mission-control/charts-and-analysis"
                    }
                    icon={faChartColumn}
                    click={() =>
                        history.push("/mission-control/charts-and-analysis")
                    }
                    permissions={["p_can_view_mission_control"]}
                />
                <SubMenuItem
                    text="Public Companies"
                    active={
                        history.location.pathname ===
                        "/mission-control/public-companies"
                    }
                    icon={faGlobe}
                    click={() =>
                        history.push("/mission-control/public-companies")
                    }
                    permissions={["p_can_view_mission_control_public"]}
                />

                <SubMenuTitle
                    text={"Company Level"}
                    permissions={[
                        "p_can_view_mission_control",
                        "p_can_view_mission_control_public",
                    ]}
                />
                <SubMenuItem
                    text={"Private Company Dashboard"}
                    active={
                        history.location.pathname ===
                        "/mission-control/private-company-dashboard"
                    }
                    icon={faLock}
                    click={() =>
                        history.push(
                            "/mission-control/private-company-dashboard"
                        )
                    }
                    permissions={["p_can_view_mission_control"]}
                />
                <SubMenuItem
                    text={"Public Company Dashboard"}
                    active={
                        history.location.pathname ===
                        "/mission-control/public-company-dashboard"
                    }
                    icon={faGlobe}
                    click={() =>
                        history.push(
                            "/mission-control/public-company-dashboard"
                        )
                    }
                    permissions={["p_can_view_mission_control_public"]}
                />

                <SubMenuTitle
                    text={"Sustainability"}
                    permissions={["p_can_view_mission_control"]}
                />
                <SubMenuItem
                    text={"Sustainability Dashboard"}
                    active={
                        history.location.pathname ===
                        "/mission-control/sustainabilities-dashboard"
                    }
                    icon={faLineChart}
                    click={() =>
                        history.push(
                            "/mission-control/sustainabilities-dashboard"
                        )
                    }
                    permissions={["p_can_view_mission_control"]}
                />
            </div>
        </MissionControlContainer>
    );
};
export default MissionControl;
