import React, { useEffect, useState } from "react";

// Styles
import EditCompanyModalContainer from "./EditDealCompanyModal.styles";

// Types
import {GrowthCompany} from "assets/interfaces/growth_voting";

// Context
import { useSettings } from "context/Settings.context";

// Utils
import { getDomainFromUrl} from "utils/common.utils"

// Components
import Modal from "components/Common/Modal/Modal";
import ButtonSmall from "../../../Common/ButtonSmall/ButtonSmall";
import {faClose} from "@fortawesome/free-solid-svg-icons";
import EditDealComany from "../EditDealCompany/EditDealCompany";
import Button from "../../../Common/Button/Button";


interface Porps {
    company: GrowthCompany;
    setCompany: React.Dispatch<React.SetStateAction<GrowthCompany>>;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}


const EditDealCompanyModal  = ({company, setCompany, setShowModal}: Porps) => {
    const [loading, setLoading] = useState<boolean>(false);
    const { setToast } = useSettings();

    const isValid = () => {
        if (!company.name) {
            setToast({
                show: true,
                message: "Please set a company name.",
                type: "error",
            });
            return false;
        }

        if (!company.url) {
            setToast({
                show: true,
                message: "Please set a domain for the company.",
                type: "error",
            });
            return false;
        }

        const domain = getDomainFromUrl(company.url);
        if (company.url && !domain) {
            setToast({
                show: true,
                message: "Domain is invalid. Please set a valid domain.",
                type: "error",
            });
            return false;
        }

        return true;
    }

    const setChanges = () => {
        if (!isValid()) {
            return;
        }

        setShowModal(false)
    }

    return (
        <EditCompanyModalContainer>
            <Modal loading={loading} width={"500px"}>
                <div className={"btn-close-container"}>
                    <ButtonSmall
                        color={"flat"}
                        text={"Close"}
                        icon={faClose}
                        click={() => {setShowModal(false)}}
                    />
                </div>
                <EditDealComany company={company} setCompany={setCompany}/>
                <div className={"btn-add-container"}>
                    <Button text="OK" color="primary"   click={() => setChanges()} />
                </div>
            </Modal>
        </EditCompanyModalContainer>
    );
};

export default EditDealCompanyModal
