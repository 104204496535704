import styled from "styled-components";

export default styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    min-height: 60px;
    max-height: 60px;
    flex: 1 1;
    margin: 10px;
    border-radius: 5px;

    font-size: 50px;

    background-color: ${(props) => props.theme.votingCellBackground};
    color: ${(props) => props.theme.votingCellText};
    border: 1px solid ${(props) => props.theme.votingCellBorder};

    user-select: none;

    &.active {
        :hover {
            cursor: pointer;
            color: #004453;
            border: 2px solid #004453;
            background-color: rgba(250, 250, 250, 1);

            margin: 9px 9px;
        }
    }

    &.selected {
        color: black;
        border: 2px solid #004453;
        background-color: #004453;
        margin: 9px 9px;

        &.score-1 {
            background-color: ${(props) => props.theme.bqmqVoteScore1};
            border: 2px solid ${(props) => props.theme.bqmqVoteScore1};
            color: black;
        }
        &.score-2 {
            background-color: ${(props) => props.theme.bqmqVoteScore2};
            border: 2px solid ${(props) => props.theme.bqmqVoteScore2};
            color: black;
        }
        &.score-3 {
            background-color: ${(props) => props.theme.bqmqVoteScore3};
            border: 2px solid ${(props) => props.theme.bqmqVoteScore3};
            color: black;
        }
        &.score-4 {
            background-color: ${(props) => props.theme.bqmqVoteScore4};
            border: 2px solid ${(props) => props.theme.bqmqVoteScore4};
            color: black;
        }
        &.score-5 {
            background-color: ${(props) => props.theme.bqmqVoteScore5};
            border: 2px solid ${(props) => props.theme.bqmqVoteScore5};
            color: black;
        }
        &.score-AGREE {
            background-color: ${(props) => props.theme.bqmqVoteScore1};
            border: 2px solid ${(props) => props.theme.bqmqVoteScore1};
            color: black;
        }
        &.score-NEUTRAL {
            background-color: ${(props) => props.theme.bqmqVoteScore3};
            border: 2px solid ${(props) => props.theme.bqmqVoteScore3};
            color: black;
        }
        &.score-DISAGREE {
            background-color: ${(props) => props.theme.bqmqVoteScore5};
            border: 2px solid ${(props) => props.theme.bqmqVoteScore5};
            color: black;
        }
    }
`;
