import styled from "styled-components";

export const ProfileButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .avatar {
        width: 30px;
        height: 30px;
        background-color: ${(props) => props.theme.mainMenuForeground};
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);

        p {
            font-size: 20px;
            color: ${(props) => props.theme.mainMenuBackground};
            font-weight: bolder;
        }
    }

    .menuItem {
        &:hover {
            background-color: ${(props) => props.theme.subMenuBackground};

            .iconContainer {
                .avatar {
                    cursor: pointer;
                    background-color: ${(props) =>
                        props.theme.mainMenuBackground};

                    p {
                        color: ${(props) => props.theme.mainMenuForeground};
                    }
                }
            }
        }
        .iconContainer {
            position: relative;
        }
    }
`;
