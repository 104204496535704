// @ts-nocheck
import { useHistory } from "react-router-dom";

// MaterialUI
import { Tooltip } from "@mui/material";

// FontAwesome
import {
    faArrowRight,
    faFile,
    faFilm,
    faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Utils
import { toDateAndTime } from "utils/general.utils";

// Styles
import NewCompanyRowContainer from "./NewCompanyRow.styles";
import { teamLabelDict } from "../../../../../../assets/data/lists";

type Props = {
    data: any;
    index: number;
};

export default function NewCompanyRow({ data, index }: Props) {
    const history = useHistory();

    return (
        <NewCompanyRowContainer>
            <div className={"info"}>
                <div className={"name"}>{data.scraped_company}</div>
                <div className={"details"}>
                    <Tooltip title={"TEAM"}>
                        <div className={"detail"}>
                            <FontAwesomeIcon icon={faUsers} fixedWidth />
                            <p>{teamLabelDict[data.scraped_team]}</p>
                        </div>
                    </Tooltip>

                    <Tooltip title={"TICKER"}>
                        <div className={"detail"}>
                            <FontAwesomeIcon icon={faFilm} fixedWidth />
                            <p>{data.scraped_ticker}</p>
                        </div>
                    </Tooltip>

                    <Tooltip title={"FILENAME"}>
                        <div className={"detail"}>
                            <FontAwesomeIcon icon={faFile} fixedWidth />
                            <p>
                                {data.scraped_excel_model
                                    ? data.scraped_excel_model
                                    : "-"}
                            </p>
                        </div>
                    </Tooltip>
                </div>
            </div>

            <div className={"date"}>
                {toDateAndTime(data.first_detected_datetime)}
            </div>

            <div
                className={"actions"}
                onClick={() => history.push("/data/new-companies")}
            >
                {index === 0 && (
                    <FontAwesomeIcon icon={faArrowRight} fixedWidth />
                )}
            </div>
        </NewCompanyRowContainer>
    );
}
