import styled from "styled-components";

export default styled.div`
    flex-direction: column;
    display: flex;

    margin-top: 20px;

    .titleRow {
        justify-content: space-between;
        display: flex;
    }

    h1 {
        font-size: 30px;
        color: ${(props) => props.theme.pageTitleText};
        letter-spacing: 1.7px;
        margin: 0 0 10px 0;
        font-weight: 400;
        display: flex;
        flex-direction: row;

        svg {
            font-size: 25px;
            margin-left: 10px;
        }
    }

    p {
        margin: 0 0 10px 0;
        line-height: 150%;
    }
`;
