// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCheck,
    faClock,
    faExclamation,
    faSync,
} from "@fortawesome/free-solid-svg-icons";

// Utils
import { toDateAndTimeSeconds } from "utils/general.utils";

// Styles
import AnalyticsQueueDropDownItemContainer from "./AnalyticsQueueDropDownItem.styles";

type Props = {
    item: AnalyticsQueueItem;
};

export default function AnalyticsQueueDropDownItem({ item }: Props) {
    const statusDict = {
        FAILED: {
            color: "red",
            icon: faExclamation,
        },
        QUEUED: {
            color: "grey",
            icon: faClock,
        },
        PROCESSING: {
            color: "blue",
            icon: faSync,
        },
        SUCCEEDED: {
            color: "green",
            icon: faCheck,
        },
    };

    return (
        <AnalyticsQueueDropDownItemContainer>
            <div className="type">
                <div className={"circle " + statusDict[item.status].color}>
                    <FontAwesomeIcon
                        icon={statusDict[item.status].icon}
                        fixedWidth
                    />
                </div>
            </div>
            <div className="info">
                <p className="description">{item.description}</p>
                <p className="date">{toDateAndTimeSeconds(item.start_time)}</p>
            </div>
        </AnalyticsQueueDropDownItemContainer>
    );
}
