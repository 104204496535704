import React, { useEffect, useState } from "react";

// Styles
import IncomingFilesContainer from "./IncomingFiles.styles";

// FontAwesome
import {faDownload, faPlus} from "@fortawesome/free-solid-svg-icons";

// Components
import TableCell from "components/Common/TableCell/TableCell";
import ButtonSmall from "components/Common/ButtonSmall/ButtonSmall";
import Button from "components/Common/Button/Button";
import Table from "components/Common/Table/Table";
import AddIncomingFileModal from "components/Screens/DailyRecs/IncomingFiles/AddInomingFileModal/AddIncomingFileModal";
import { createLocalDownload } from "utils/export.utils";

// Utils
import * as Database from "utils/database.utils";
import {useSettings} from "../../../../context/Settings.context";


interface IncomingFilesProps {}

const IncomingFiles = ({}: IncomingFilesProps) => {
    const [reload, setReload] = useState(0);
    const [filterQuery, setFilterQuery] = useState<string>("");
    const [showAddInputFileModal, setShowAddInputFileModal] = useState<boolean>(false);
    const { setToast, setShowLoadingToast } = useSettings();

    const downloadFile = async (input_file_id: string) => {
        setToast({
            show: true,
            type: "success",
            message: "Downloading file...",
        });

        let res = await Database.get("api/nav/incoming_files/download/" + input_file_id + "/");

        let filename: any = "Unknown";

        if (res?.ok) {
            filename = res?.headers?.get("Content-Disposition")?.split("filename=")[1];
            setToast({show:false, type:"", message:""})
        } else {
           setToast({
            show: true,
            type: "error",
            message: "Downloading file failed.",
        });
        }

        const file = await res.blob();
        createLocalDownload(filename, file);
    }

    const columns = [
        {
            id: 1,
            title: "PROVIDER",
            sortable: false,
            value: "provider",
            cell: (data: any) => <TableCell data={data.provider} />,
            show: true,
            fullDataRow: true,
        },
        {
            id: 2,
            title: "FILE",
            sortable: false,
            value: "data_sheet_name",
            cell: (data: any) => <TableCell data={data.data_sheet_name} />,
            show: true,
            fullDataRow: true,
        },
        {
            id: 4,
            title: "STATUS",
            sortable: false,
            value: "status",
            cell: (data: any) => <TableCell data={data.status} />,
            show: true,
            fullDataRow: true,
        },
        {
            id: 3,
            title: "PROVIDED ON",
            sortable: false,
            value: "provided_on_dt",
            cell: (data: any) => (
                <TableCell
                    data={
                        data.provided_on_dt
                            ? new Date(data.provided_on_dt).toLocaleDateString()
                            : "Not yet provided."
                    }
                />
            ),
            show: true,
            fullDataRow: true,
        },
         {
            id: 5,
            title: "DOWNLOAD",
            sortable: false,
            value: "input_file_id",
            cell: (data: any) => (
                (data.status == "Processed" && <ButtonSmall
                    text="Download File"
                    className=""
                    icon={faDownload}
                    click={() => {
                        downloadFile(data.input_file_id);
                    }}
                />)
            ),
            show: true,
            fullDataRow: true,
        },
    ];

    return (
        <IncomingFilesContainer>
            {showAddInputFileModal && (
                <AddIncomingFileModal
                    setShowModal={setShowAddInputFileModal}
                />
            )}

            <p data-cy="navs-main-title" className={"sectionTitle"}>
                INCOMING FILES
            </p>

            <div className={"actions"}>
                <Button
                    text="UPLOAD FILE"
                    className=""
                    icon={faPlus}
                    click={() => setShowAddInputFileModal(!showAddInputFileModal)}
                />
            </div>
            <div data-cy="table" className="tableDiv">
                <Table
                    reload={reload}
                    columns={columns}
                    defaultLimit={25}
                    filterQuery={filterQuery}
                    large
                    defaultSortKey="provider"
                    defaultSortOrder="DESC"
                    url="api/nav/data_sources/"
                />
            </div>
        </IncomingFilesContainer>
    );
};
export default IncomingFiles;
