import moment from "moment";

export const uppercaseFirstLetter = (string) => {
    return string[0].toUpperCase() + string.substring(1);
};

export const resultIsJson = async (result) => {
    const resultTest = result.clone();
    const text = await resultTest.text();
    try {
        JSON.parse(text);
        return true;
    } catch (error) {
        return false;
    }
};

export const formatDecimalNumberToPercentage = (number) => {
    if (!number) return null;
    return `${(number * 100).toFixed(2)}%`;
};

export const formatPercentageNumberToDecimal = (number) => {
    return parseFloat(number) / 100;
};

export const bankersRound = (num, decimalPlaces) => {
    let d = decimalPlaces || 0;
    let m = Math.pow(10, d);
    let n = +(d ? num * m : num).toFixed(8); // Avoid rounding errors
    let i = Math.floor(n),
        f = n - i;
    let e = 1e-8; // Allow for rounding errors in f
    let r =
        f > 0.5 - e && f < 0.5 + e ? (i % 2 == 0 ? i : i + 1) : Math.round(n);
    return d ? r / m : r;
};

export const formatstringforTests = (string) => {
    return string
        .toLowerCase()
        .split(" ")
        .join("-")
        .replace("/", "-")
        .replace("&", "");
};

export const applyTimeZoneOffset = (date, format = "LLLL") => {
    const timeOffset = new Date(date).getTimezoneOffset();
    const dateTime = moment.utc(date).zone(timeOffset).format(format);
    return dateTime;
};

export const hideBasedOnRoutes = (routes) => {
    return routes.includes(window.location.pathname);
};

export const formatNumberFixDecimal = (value, fractionDigits = 2) => {
    if (!value && value !== 0) {
        return value
    }

     let config = {
         useGrouping:  'always',
         maximumFractionDigits: fractionDigits,
         minimumFractionDigits: fractionDigits,
     }

     let formattedValue = new Intl.NumberFormat('en-GB', config).format(value)

    //just because it looks srange if we display -0
    // this can happen if it is a smaller number compared to the displayed fraction digits
    if (formattedValue === "-0") {
        formattedValue = "0"
    }

     return formattedValue
}


export const formatNumber = (value)  => {
    // format number with minimal change (only chop the fraction digits
    if (value < 1 && value > -1) {
        // allow more fraction digits for small numbers
        return new Intl.NumberFormat('en-GB', {maximumFractionDigits: 7}).format(value);
    } else {
        return new Intl.NumberFormat('en-GB', {maximumFractionDigits: 4}).format(value);
    }
}

export const isValidDomain = (domain) => {
    const domainRegex = new RegExp(/^(?:(?:(?:[a-zA-z\-]+)\:\/{1,3})?(?:[a-zA-Z0-9])(?:[a-zA-Z0-9\-\.]){1,61}(?:\.[a-zA-Z]{2,})+|\[(?:(?:(?:[a-fA-F0-9]){1,4})(?::(?:[a-fA-F0-9]){1,4}){7}|::1|::)\]|(?:(?:[0-9]{1,3})(?:\.[0-9]{1,3}){3}))(?:\:[0-9]{1,5})?$/);
    return domainRegex.test(domain);
};

export const getDomainFromUrl = (urlString) => {
    if (urlString === "") {
        return "";
    }

    let url = urlString;

    const httpPrefix = "http://";
    const httpsPrefix = "https://";

    if (urlString.slice(0, httpPrefix.length) !== httpPrefix && urlString.slice(0, httpsPrefix.length) !== httpsPrefix) {
        // add https if not in the url
        url = httpsPrefix + urlString;
    }

    try {
        const urlObj = new URL(url);

        let domain = urlObj.hostname;

        if (domain.startsWith("www.")) {
            // remove the www prefix
            domain = domain.replace("www.", "");
        }

        if (isValidDomain(domain)) {
            return domain;
        } else {
            throw new Error("Invalid domain");
        }
    } catch (e) {
            return "";
    }
};
