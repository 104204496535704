import { CellMapGetter, GeneratedColumn } from "./types";

const GenerateQtrRepeatedCols = (
    start: number,
    qtr: number,
    headerColor: string,
    textColor: string,
    cellMap: CellMapGetter
): Array<GeneratedColumn> => {
    const toolTips: Record<string, string | null> = {
        IOO: null,
        "CUSTODY NAV": null,
        "CUSTODY ACCRUED EXPENSES": null,
        VARIANCE: "IOO - CUSTODY NAV",
        "VARIANCE %": "VARIANCE / IOO",
    };

    return Object.keys(cellMap(0)).map((column: string, index: number) => {
        const value = column.split(" ").join("_").toLowerCase();
        return {
            id: index + start,
            title: column,
            sortable: false,
            value: value,
            cell: cellMap(qtr)[column],
            show: true,
            fullDataRow: true,
            headerColor: headerColor,
            textColor: textColor,
            groupingKey: `Month ${qtr}`,
            tooltip: toolTips[column],
        };
    }, []);
};

export default GenerateQtrRepeatedCols;
