import React, { useEffect, useState } from "react";

// Styles
import EditDealCompanyContainer from "./EditDealCompany.styles";

// Types
import {DealTeam, GrowthCompany} from "assets/interfaces/growth_voting";

// Components
import TextField from "../../../Common/TextField/TextField";
import SearchableDropDown from "../../../Common/SearchableDropDown/SearchableDropDown";
import ButtonSmall from "components/Common/ButtonSmall/ButtonSmall";

// Context
import {useGrowthCompanies} from "context/growth/GrowthCompany.context";
import SeparateAccountsAsiaStyles from "screens/Fees/SeparateAccounts/Asia/SeparateAccountsAsia.styles";
import { useSettings } from "context/Settings.context";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRemove, faInfoCircle } from "@fortawesome/free-solid-svg-icons";

// Utils
import { getDomain } from "utils/growth_voting.utils";

// MUI
import { Tooltip } from "@mui/material"

interface Porps {
    company: GrowthCompany;
    setCompany: React.Dispatch<React.SetStateAction<GrowthCompany>>;
}


const EditDealCompany  = ({company, setCompany}: Porps) => {
    const {companies} = useGrowthCompanies();
    const {setToast} = useSettings();
    const [selectedOption, setSelectedOption] = useState<Option>({
        label: "",
        value: "",
    });

    const updateName = (name: string) => {
        company = {...company, name: name};
        setCompany(company);
    };

    const updateUrl = (urlStr: string) => {
        let domain = getDomain(urlStr);
        company = {...company, url: domain};
        setCompany(company);
    };

    const setSelectedCompany = () => {
        const company = companies.find((company: GrowthCompany) => company.id === selectedOption.value);

        if (!company) {
            setCompany({name: "", url: ""});
            return;
        }

        setCompany(company);
    }

    const removeSelectedFile = () => {
        setSelectedOption({label: "", value: ""});
    }

    useEffect(() => {
        setSelectedCompany();
    }, [selectedOption]);

    return (
        <EditDealCompanyContainer>
            <div className="title">
                Company
            </div>

            <div className={"company"}>
                <SearchableDropDown
                    placeholder="Search"
                    options={companies.map((company: GrowthCompany) => {
                        return {
                            value: company?.id,
                            label: company?.name,
                        };
                    })}
                    setValue={setSelectedOption}
                    value={selectedOption}
                    error={false}
                />
                {selectedOption.value && <ButtonSmall
                    color="accent"
                    className="download-signed-url"
                    text={"Remove " + selectedOption.label}
                    click={() => removeSelectedFile()}
                    icon={faRemove}
                />
            }
            </div>

            <div className={"form"}>
                <div className="inputContainer">
                    <label>Name</label>
                    <TextField
                        disabled={(selectedOption.value) ? true : false}
                        value={company.name}
                        change={updateName}
                        placeholder={""}
                    />
                </div>

                <div className="inputContainer">
                    <Tooltip title={"Example of a valid domain: 'example.com'. HTTP or www is not required."}>
                        <label>Domain <FontAwesomeIcon className="icon" icon={faInfoCircle} fixedWidth /></label>
                    </Tooltip>
                    <TextField
                        disabled={(selectedOption.value) ? true : false}
                        value={company.url}
                        change={updateUrl}
                        placeholder={"xyz.com"}
                    />
                </div>
            </div>
        </EditDealCompanyContainer>
    );
};

export default EditDealCompany
