// Styles
import CRNTCashStockContainer from "./CRNTCashStock.styles";
import ReportsTab from "../../../../components/Screens/DailyRecs/DailyFlowsCheck/ReportsTab/ReportsTab";
import AlertsTab from "../../../../components/Screens/DailyRecs/DailyFlowsCheck/AlertsTab/AlertsTab";
import ChangeLogTab from "../../../../components/Screens/DailyRecs/DailyFlowsCheck/ChangeLogTab/ChangeLogTab";
import Tabs from "../../../../components/Common/Tabs/Tabs";

interface CRNTCashStockProps {}

const powerBI =
    "https://app.powerbi.com/reportEmbed?reportId=1a5b216a-eed4-4e59-9d4b-5a0cb075207a&autoAuth=true&ctid=bf4450a2-bfcb-472d-8c40-1425eafb1a3b&filterPaneEnabled=false";
const checkName = "cr_nt_cash_stock";

const CRNTCashStock = ({}: CRNTCashStockProps) => {
    return (
        <CRNTCashStockContainer>
            <p className={"sectionTitle"}>CR VS NT CASH + STOCK</p>
            <Tabs
                defaultTab="reports"
                tabs={[
                    {
                        value: "reports",
                        title: "REPORTS",
                        permissions: ["p_can_view_navs", "p_can_action_navs"],
                        content: <ReportsTab powerBI={powerBI} />,
                    },
                    {
                        value: "alerts",
                        title: "ALERTS",
                        permissions: ["p_can_view_navs", "p_can_action_navs"],
                        content: <AlertsTab checkName={checkName} />,
                    },
                    {
                        value: "change_log",
                        title: "CHANGE LOG",
                        permissions: ["p_can_view_navs", "p_can_action_navs"],
                        content: <ChangeLogTab checkName={checkName} />,
                    },
                ]}
            />
        </CRNTCashStockContainer>
    );
};

export default CRNTCashStock;
