import colors from "assets/styles/colors";

export default {
    homeListTitleRowBackground: colors.textNavy,
    homeListTitleRowText: "white",
    homeListCountBackgroundDefault: colors.textNavyDark,
    homeListCountTextDefault: "white",

    homeListBodyBorderColor: colors.grey90,
    homeListBodyBackgroundHover: colors.grey100,

    homeListSeeAllText: colors.accentBlue,

    homeListRowHeader: colors.textNavy,
    homeListRowText: colors.grey40,
};
