import styled from "styled-components";

export default styled.div`
    height: 100%;
    margin: 0px -20px 0px -20px;

    iframe {
        border: none;
    }

    .iframeContainer {
        height: 99vh;
        max-height: 100vh;
    }
`;
