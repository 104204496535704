import React from "react";

// Styles
import MeetingDetailBoxesContainer from "./MeetingDetailBoxes.styles";
import DetailBox from "./DetailBox/DetailBox";

interface MeetingDetailBoxesProps {
    bqScore: number;
    mqScore: number;
    lastVoteDate: string;
    investmentThesis: string;
    portfolioWeight: number;
    analyst?: string;
}

const MeetingDetailBoxes = ({
    bqScore,
    mqScore,
    lastVoteDate,
    investmentThesis,
    portfolioWeight,
    analyst,
}: MeetingDetailBoxesProps) => {
    return (
        <MeetingDetailBoxesContainer>
            <div className="boxContainer">
                {/* portfolio weight */}
                <DetailBox
                    title="Portfolio Weight"
                    data={portfolioWeight ? portfolioWeight : "-"}
                />
                {/* bq score */}
                <DetailBox title="BQ Score" data={bqScore ? bqScore : "-"} />
                {/* mq score */}
                <DetailBox title="MQ Score" data={mqScore ? mqScore : "-"} />
                {/* last vote date */}
                <DetailBox
                    title="Last Vote Date"
                    data={lastVoteDate ? lastVoteDate : "-"}
                />
                {/* analyst */}
                <DetailBox title="Analyst" data={analyst ? analyst : "-"} />
            </div>
            <div className="investmentThesis">
                <p className="title">Investment Thesis</p>
                <p className="text">
                    {investmentThesis ? investmentThesis : "-"}
                </p>
            </div>
        </MeetingDetailBoxesContainer>
    );
};
export default MeetingDetailBoxes;
