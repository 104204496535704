import { useHistory } from "react-router-dom";

// FontAwesome
import {
    faChartLine,
    faClipboardCheck,
    faGlobe,
} from "@fortawesome/free-solid-svg-icons";

// Components
import SubMenuItem from "../../SubMenuItem/SubMenuItem";
import SubMenuTitle from "../../SubMenuTitle/SubMenuTitle";

// Styles
import FeesContainer from "./Fees.styles";

export default function Fees() {
    const history = useHistory();

    return (
        <FeesContainer className={"submenu"}>
            <div className={"top"}>
                <h1>FEES</h1>
                <hr />
                <SubMenuTitle
                    text={"FUNDS"}
                    permissions={["p_can_action_fees", "p_can_view_fees"]}
                />

                <SubMenuItem
                    text={"Dublin"}
                    active={
                        history.location.pathname === "/fees/dublin" ||
                        history.location.pathname.includes(
                            "/fees/dublin/report"
                        )
                    }
                    icon={faGlobe}
                    click={() => history.push("/fees/dublin?tab=global")}
                    permissions={["p_can_action_fees", "p_can_view_fees"]}
                />

                <SubMenuItem
                    text={"Delaware"}
                    active={
                        history.location.pathname === "/fees/delaware" ||
                        history.location.pathname.includes(
                            "/fees/delaware/report"
                        )
                    }
                    icon={faGlobe}
                    click={() => history.push("/fees/delaware?tab=global")}
                    permissions={["p_can_action_fees", "p_can_view_fees"]}
                />

                <SubMenuItem
                    text={"Separate Accounts"}
                    active={
                        history.location.pathname ===
                            "/fees/separate-accounts" ||
                        history.location.pathname.includes(
                            "/fees/separate-accounts/report"
                        )
                    }
                    icon={faGlobe}
                    click={() =>
                        history.push("/fees/separate-accounts?tab=global")
                    }
                    permissions={["p_can_action_fees", "p_can_view_fees"]}
                />

                <SubMenuTitle
                    text={"FORECASTING"}
                    permissions={["p_can_action_fees", "p_can_view_fees"]}
                />

                <SubMenuItem
                    text={"Forecasts"}
                    active={history.location.pathname === "/fees/forecasts"}
                    icon={faChartLine}
                    click={() => history.push("/fees/forecasts")}
                    permissions={["p_can_action_fees", "p_can_view_fees"]}
                />
            </div>
            <div className={"bottom"}>
                <SubMenuTitle
                    text={"REFERENCE"}
                    permissions={["p_can_action_fees", "p_can_view_fees"]}
                />
                <SubMenuItem
                    text={"Validation Rules"}
                    active={
                        history.location.pathname === "/fees/validation-rules"
                    }
                    icon={faClipboardCheck}
                    click={() => history.push("/fees/validation-rules")}
                    permissions={["p_can_action_fees", "p_can_view_fees"]}
                />
            </div>
        </FeesContainer>
    );
}
