import { useHistory } from "react-router-dom";

// Styles
import DailyRecsContainer from "./DailyRecs.styles";

// Components
import SubMenuItem from "../../SubMenuItem/SubMenuItem";
import SubMenuTitle from "../../SubMenuTitle/SubMenuTitle";

// FontAwesome
import {
    faArrowDown,
    faBoxArchive,
    faCalendarDay,
    faChartLine,
    faCog,
    faDollar,
    faMoneyBill,
    faPercent,
    faPersonCircleCheck,
    faRefresh,
    faSheetPlastic,
    faTasks,
    faList,
    faVault
} from "@fortawesome/free-solid-svg-icons";

interface DailyRecsProps {}

const DailyRecs = ({}: DailyRecsProps) => {
    const history = useHistory();

    return (
        <DailyRecsContainer className="submenu">
            <div className={"top"}>
                <h2>DAILY RECONCILIATION</h2>
                <hr />

                {/* INPUTS */}
                <SubMenuTitle text="INPUTS" permissions={["p_can_view_navs"]} />
                <SubMenuItem
                    data-cy="navs-submenu-item"
                    text={"Incoming Files"}
                    active={
                        history.location.pathname ===
                        "/reconciliation/inputs/incoming_files"
                    }
                    icon={faArrowDown}
                    click={() =>
                        history.push("/reconciliation/inputs/incoming_files")
                    }
                    permissions={["p_can_view_navs"]}
                />
                <SubMenuItem
                    data-cy="navs-submenu-item"
                    text={"Daily Flows"}
                    active={
                        history.location.pathname ===
                        "/reconciliation/inputs/daily_flows"
                    }
                    icon={faCalendarDay}
                    click={() =>
                        history.push("/reconciliation/inputs/daily_flows")
                    }
                    permissions={["p_can_view_navs"]}
                />
                <SubMenuItem
                    data-cy="navs-submenu-item"
                    text={"Configurations"}
                    active={
                        history.location.pathname ===
                        "/reconciliation/inputs/configurations"
                    }
                    icon={faCog}
                    click={() =>
                        history.push("/reconciliation/inputs/configurations")
                    }
                    permissions={["p_can_view_navs"]}
                />
                <SubMenuItem
                    data-cy="navs-submenu-item"
                    text={"Accruals"}
                    active={
                        history.location.pathname ===
                        "/reconciliation/inputs/accruals"
                    }
                    icon={faMoneyBill}
                    click={() =>
                        history.push("/reconciliation/inputs/accruals")
                    }
                    permissions={["p_can_view_navs"]}
                />
                 <SubMenuItem
                    data-cy="navs-submenu-item"
                    text={"Incoming File History"}
                    active={
                        history.location.pathname ===
                        "/reconciliation/inputs/incoming_file_history"
                    }
                    icon={faList}
                    click={() =>
                        history.push("/reconciliation/inputs/incoming_file_history")
                    }
                    permissions={["p_can_view_navs"]}
                />

                {/* OVERVIEW */}
                <SubMenuTitle
                    text="OVERVIEWS"
                    permissions={["p_can_view_navs"]}
                />
                <SubMenuItem
                    text={"Check Overview"}
                    active={
                        history.location.pathname ===
                        "/reconciliation/overviews/checks"
                    }
                    icon={faTasks}
                    click={() =>
                        history.push("/reconciliation/overviews/checks")
                    }
                    permissions={["p_can_view_navs"]}
                />

                {/* CHECKS */}
                <SubMenuTitle text="CHECKS" permissions={["p_can_view_navs"]} />
                <SubMenuItem
                    text={"Daily Flows"}
                    active={
                        history.location.pathname ===
                        "/reconciliation/checks/daily_flows"
                    }
                    icon={faCalendarDay}
                    click={() =>
                        history.push("/reconciliation/checks/daily_flows")
                    }
                    permissions={["p_can_view_navs"]}
                />
                <SubMenuItem
                    text={"Cashflow Forecast"}
                    active={
                        history.location.pathname ===
                        "/reconciliation/checks/cashflow_forecast"
                    }
                    icon={faDollar}
                    click={() =>
                        history.push("/reconciliation/checks/cashflow_forecast")
                    }
                    permissions={["p_can_view_navs"]}
                />
                <SubMenuItem
                    text={"Daily Movements"}
                    active={
                        history.location.pathname ===
                        "/reconciliation/checks/daily_movements"
                    }
                    icon={faCalendarDay}
                    click={() =>
                        history.push("/reconciliation/checks/daily_movements")
                    }
                    permissions={["p_can_view_navs"]}
                />
                <SubMenuItem
                    text={"FormPF"}
                    active={
                        history.location.pathname ===
                        "/reconciliation/checks/form_pf"
                    }
                    icon={faVault}
                    click={() =>
                        history.push("/reconciliation/checks/form_pf")
                    }
                    permissions={["p_can_view_navs"]}
                />
                <SubMenuItem
                    text={"FX Rates"}
                    active={
                        history.location.pathname ===
                        "/reconciliation/checks/fx_rates"
                    }
                    icon={faPercent}
                    click={() =>
                        history.push("/reconciliation/checks/fx_rates")
                    }
                    permissions={["p_can_view_navs"]}
                />
                <SubMenuItem
                    text={"Benchmarks"}
                    active={
                        history.location.pathname ===
                        "/reconciliation/checks/benchmarks"
                    }
                    icon={faChartLine}
                    click={() =>
                        history.push("/reconciliation/checks/benchmarks")
                    }
                    permissions={["p_can_view_navs"]}
                />
                <SubMenuItem
                    text={"CR vs NT NAV"}
                    active={
                        history.location.pathname ===
                        "/reconciliation/checks/cr_nt_nav"
                    }
                    icon={faBoxArchive}
                    click={() =>
                        history.push("/reconciliation/checks/cr_nt_nav")
                    }
                    permissions={["p_can_view_navs"]}
                />
                <SubMenuItem
                    text={"CR vs NT Cash + Stock"}
                    active={
                        history.location.pathname ===
                        "/reconciliation/checks/cr_ntcash_stock"
                    }
                    icon={faMoneyBill}
                    click={() =>
                        history.push("/reconciliation/checks/cr_ntcash_stock")
                    }
                    permissions={["p_can_view_navs"]}
                />

                <SubMenuItem
                    text={"Hedging"}
                    active={
                        history.location.pathname ===
                        "/reconciliation/checks/hedging"
                    }
                    icon={faRefresh}
                    click={() => history.push("/reconciliation/checks/hedging")}
                    permissions={["p_can_view_navs"]}
                />
                <SubMenuItem
                    text={"Currency Exposure"}
                    active={
                        history.location.pathname ===
                        "/reconciliation/checks/currency_exposure_checks"
                    }
                    icon={faDollar}
                    click={() =>
                        history.push("/reconciliation/checks/currency_exposure_checks")
                    }
                    permissions={["p_can_view_navs"]}
                />
                <SubMenuItem
                    text={"Forwards + NDF Exposure + EMIR"}
                    active={
                        history.location.pathname ===
                        "/reconciliation/checks/forwards_ndfexposure_emir"
                    }
                    icon={faDollar}
                    click={() =>
                        history.push(
                            "/reconciliation/checks/forwards_ndfexposure_emir"
                        )
                    }
                    permissions={["p_can_view_navs"]}
                />
                <SubMenuItem
                    text={"Broker Exposure"}
                    active={
                        history.location.pathname ===
                        "/reconciliation/checks/broker_exposure"
                    }
                    icon={faPersonCircleCheck}
                    click={() =>
                        history.push("/reconciliation/checks/broker_exposure")
                    }
                    permissions={["p_can_view_navs"]}
                />
                <SubMenuItem
                    text={"Leverage + Collateral + Future"}
                    active={
                        history.location.pathname ===
                        "/reconciliation/checks/leverage_collateral_future"
                    }
                    icon={faDollar}
                    click={() =>
                        history.push(
                            "/reconciliation/checks/leverage_collateral_future"
                        )
                    }
                    permissions={["p_can_view_navs"]}
                />
            </div>
        </DailyRecsContainer>
    );
};
export default DailyRecs;
