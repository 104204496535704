import { createContext, useContext, useEffect, useState } from "react";

// Logger
import Logger from "utils/logger.utils";
const Log = new Logger();
Log.init("Settings.Context");

const SettingsContext = createContext();

export function SettingsProvider({ children }) {
    const [route, setRoute] = useState(window.location.pathname);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    const [reloadHomePage, setReloadHomePage] = useState(0);
    const [reloadResultsPage, setReloadResultsPage] = useState(0);

    // Side Menu
    const [menuMode, setMenuMode] = useState("init");
    const [subMenuMode, setSubMenuMode] = useState("init");
    const [subMenuWidth, setSubMenuWidth] = useState(250);

    // Show UI
    const [showGlobalSearchBox, setShowGlobalSearchBox] = useState(false);
    const [showSettingsMenu, setShowSettingsMenu] = useState(false);
    const [showLoadingScreen, setShowLoadingScreen] = useState(true);
    const [showLoadingToast, setShowLoadingToast] = useState({
        show: false,
        message: "LOADING",
    });
    const [toast, setToast] = useState({
        show: false,
        type: "",
        message: "",
    });

    const [defaultFilters, setDefaultFilters] = useState({});

    // Settings
    const [theme, setTheme] = useState(
        localStorage.getItem("settings.theme") || "light"
    );
    const [fullScreen, setFullScreen] = useState(
        JSON.parse(localStorage.getItem("settings.full-screen")) || false
    );
    const [stickyHeaders, setStickyHeaders] = useState(
        JSON.parse(localStorage.getItem("settings.sticky-table-headers")) ||
            false
    );
    const [fairValueDefault, setFairValueDefault] = useState(
        JSON.parse(localStorage.getItem("settings.fair-value-default"))
            ? localStorage.getItem("settings.fair-value-default")
            : "fv_yearly"
    );

    // Docs
    const [doc, setDoc] = useState("");
    const [showDocsPanel, setShowDocsPanel] = useState(false);

    const onWindowResize = () => {
        setWindowWidth(window.innerWidth);
        setWindowHeight(window.innerHeight);
    };

    useEffect(() => {
        window.addEventListener("resize", onWindowResize, false);

        return () => {
            window.removeEventListener("resize", onWindowResize, false);
        };
    }, []);

    useEffect(() => {
        Log.info("Route Changed:", route);
    }, [route]);

    return (
        <SettingsContext.Provider
            value={{
                route,
                setRoute,
                menuMode,
                setMenuMode,
                subMenuWidth,
                setSubMenuWidth,
                subMenuMode,
                setSubMenuMode,
                windowWidth,
                windowHeight,
                showGlobalSearchBox,
                setShowGlobalSearchBox,
                showLoadingScreen,
                setShowLoadingScreen,
                showSettingsMenu,
                setShowSettingsMenu,
                theme,
                setTheme,
                fullScreen,
                setFullScreen,
                stickyHeaders,
                setStickyHeaders,
                fairValueDefault,
                setFairValueDefault,
                toast,
                setToast,
                showLoadingToast,
                setShowLoadingToast,
                defaultFilters,
                setDefaultFilters,
                doc,
                setDoc,
                showDocsPanel,
                setShowDocsPanel,
                reloadHomePage,
                setReloadHomePage,
                reloadResultsPage,
                setReloadResultsPage,
            }}
        >
            {children}
        </SettingsContext.Provider>
    );
}

export const useSettings = () => {
    return useContext(SettingsContext);
};
