import {
    IQuestion,
    IOption,
} from "components/Screens/SFDRDataCapture/SFDRDataCapture.types";
import { isObjectEmpty } from "./general.utils";
import queryString from "query-string";

export const mapOptionsToQuestions = (
    questions: IQuestion[],
    questionOptions: IOption[]
) => {
    const questionsWithOptions = questions
        .map((question: any) => ({
            ...question,
            options: [
                ...questionOptions.filter(
                    (option: IOption) => option.question_id == question.id
                ),
            ].sort((a: any, b: any) => a.order - b.order),
        }))
        .filter((quesion: any) => quesion.parent_option_id == null);
    return questionsWithOptions;
};

export const getOptionChildQuestions = (
    questions: IQuestion[],
    questionOptions: IOption[]
) => {
    const childQuestions = questions
        .map((question: any) => ({
            ...question,
            options: [
                ...questionOptions.filter(
                    (option: IOption) => option?.question_id == question.id
                ),
            ].sort((a: any, b: any) => a.order - b.order),
        }))
        .filter((quesion: any) => quesion.parent_option_id !== null);
    return childQuestions;
};

export const clearUrlQueryString = (urlQuery: {}) => {
    if (!isObjectEmpty(urlQuery)) {
        const urlWithoutQueryString = window.location.href.split("?")[0];
        const newUrl = queryString.stringifyUrl({
            url: urlWithoutQueryString,
            query: {},
        });
        window.history.replaceState({}, document.title, newUrl);
        return;
    }
};
