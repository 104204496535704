import { useState, useEffect } from "react";

// Context
import { useSettings } from "context/Settings.context";

// Styles
import ItemContainer from "./Item.styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFile,
    faFolderClosed,
    faFolderOpen,
    faHome,
} from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";

type Props = {
    item: any;
    doc: string;
    type: string;
};

export default function Item({ item, doc, type }: Props) {
    const history = useHistory();

    const { setDoc } = useSettings();

    const [active, setActive] = useState(false);

    useEffect(() => {
        setActive(doc === item.doc);
    }, [doc]);

    const changeDoc = () => {
        if (item.path) {
            history.push("/" + type + "/" + item.path);
        }
    };

    return (
        <>
            <ItemContainer
                onClick={changeDoc}
                className={
                    history.location.pathname === "/" + type + "/" + item.path
                        ? "active"
                        : ""
                }
            >
                <FontAwesomeIcon
                    className="icon"
                    icon={item.name === "Home" ? faHome : faFile}
                    fixedWidth
                />
                <p className={active ? "text active" : "text"}>{item.name}</p>
            </ItemContainer>

            {item.border && <hr />}
        </>
    );
}
