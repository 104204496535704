import { useState } from "react";
import { SeparateAccountsRow } from "../../utils/types";

//Context
import { useFees } from "context/Fees.context";

// Components
import Table from "components/Common/Table/Table";
import TableCell from "components/Common/TableCell/TableCell";
import EditableCell from "components/Screens/Admin/ValidationRules/EditableCell/EditableCell";

// Font Awesome

// Utils
import FloatToCurrencyParser from "../../utils/FloatToCurrencyParser";
import FloatToPercParser from "../../utils/FloatToPercParser";
import formatDate from "../../utils/FormatDate";
import StringToFloatParser from "../../utils/StringToFloatParser";
import PerformanceFeesContainer from "./PerformanceFee.styles";
interface RedemptionsProps {
    fund?: string;
    region?: string;
    period?: string;
}

const PerformanceFees = ({ fund, region, period }: RedemptionsProps) => {
    const { performanceFees, updateFeesData, prevPerformanceFees, period: selectedPeriod } = useFees();
    const [filterQuery, setFilterQuery] = useState<string>("");
    const [tableIsLoading, setTableIsLoading] = useState<boolean>(false);
    const [reload, setReload] = useState(0);

    const paidColumns = [
        {
            id: 0,
            title: "ACCOUNT ID",
            sortable: false,
            value: "account_id",
            cell: (data: SeparateAccountsRow) => (
                <TableCell data={data.account_id} />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 1,
            title: "ACCOUNT NAME",
            sortable: false,
            value: "account_id",
            cell: (data: SeparateAccountsRow) => (
                <TableCell data={data.account_name} />
            ),
            show: true,
            fullDataRow: true,
        },

        {
            id: 2,
            title: "PERFORMANCE FEE DATE",
            sortable: false,
            value: "performance_fee_date",
            cell: (data: SeparateAccountsRow) => (
                <EditableCell
                    rowId={data.id}
                    cellValueType="string"
                    cellValue="comments"
                    rowIdKey={"id"}
                    initialValue={
                        data.performance_fee_date
                            ? formatDate(data.performance_fee_date)
                            : "-"
                    }
                    type="dateField"
                    setTableIsLoading={setTableIsLoading}
                    updateTable={() => setReload(reload + 1)}
                    onUpdate={(idx: number, value: string) => {
                        return updateFeesData(
                            "performance_fee_date",
                            data.account_id,
                            selectedPeriod,
                            data.tranche,
                            value,
                            {
                                id: data.uuid,
                                version: data.version,
                            }
                        );
                    }}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 4,
            title: "TRANCHE",
            sortable: false,
            value: "tranche",
            cell: (data: SeparateAccountsRow) => (
                <TableCell data={data.tranche} />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 5,
            title: "PERFORMANCE FEE AMOUNT",
            sortable: false,
            value: "performance_fee_amount",
            cell: (data: SeparateAccountsRow) => (
                <EditableCell
                    rowId={data.id}
                    cellValueType="string"
                    cellValue="comments"
                    rowIdKey={"id"}
                    initialValue={FloatToCurrencyParser(
                        data.performance_fee_amount,
                        data.currency
                    )}
                    type="numericalField"
                    setTableIsLoading={setTableIsLoading}
                    updateTable={() => setReload(reload + 1)}
                    inputParser={StringToFloatParser}
                    onUpdate={(idx: number, value: string) => {
                        return updateFeesData(
                            "performance_fee_amount",
                            data.account_id,
                            selectedPeriod,
                            data.tranche,
                            value,
                            {
                                id: data.uuid,
                                version: data.version,
                                total_account: data.max_nav - data.total_events,
                                gross_amount: data.gross_market_value_account,
                            }
                        );
                    }}
                />
            ),

            show: true,
            fullDataRow: true,
        },
        {
            id: 3,
            title: "OWNERSHIP AT PERFORMANCE FEE PAYMENT DATE",
            sortable: false,
            value: "ownership_at_performance_fee_payment_date",
            cell: (data: SeparateAccountsRow) => (
                <TableCell
                    data={FloatToPercParser(
                        data.ownership_at_performance_fee_payment_date
                    )}
                />
            ),
            show: true,
            fullDataRow: true,
        },

        {
            id: 6,
            title: "GROSS MARKET VALUE ACCOUNT",
            sortable: false,
            value: "gross_market_value_account",
            cell: (data: SeparateAccountsRow) => (
                <TableCell
                    data={FloatToCurrencyParser(
                        data.gross_market_value_account,
                        data.currency
                    )}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 14,
            title: "PERFORMANCE FEE SPLIT",
            sortable: false,
            value: "performance_fee_split",
            cell: (data: SeparateAccountsRow) => (
                <EditableCell
                    rowId={data.id}
                    rowIdKey={"id"}
                    initialValue={FloatToPercParser(data.performance_fee_split)}
                    cellValue="comments"
                    type="numericalField"
                    setTableIsLoading={setTableIsLoading}
                    updateTable={() => {}}
                    inputParser={StringToFloatParser}
                    onUpdate={(idx: number, value: string) => {
                        return updateFeesData(
                            "performance_fee_split",
                            data.account_id,
                            selectedPeriod,
                            data.tranche,
                            value,
                            {
                                id: data.uuid,
                                version: data.version,
                            }
                        );
                    }}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 13,
            title: "NEW OWNERSHIP SPLIT",
            sortable: false,
            value: "new_ownship_split",
            cell: (data: SeparateAccountsRow) => (
                <TableCell data={FloatToPercParser(data.new_ownship_split)} />
            ),
            show: true,
            fullDataRow: true,
        },
    ];

    const registerColumns = [
        {
            id: 0,
            title: "ACCOUNT ID",
            sortable: false,
            value: "account_id",
            cell: (data: SeparateAccountsRow) => (
                <TableCell data={data.account_id} />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 1,
            title: "ACCOUNT NAME",
            sortable: false,
            value: "account_id",
            cell: (data: SeparateAccountsRow) => (
                <TableCell data={data.account_name} />
            ),
            show: true,
            fullDataRow: true,
        },

        {
            id: 2,
            title: "TRANCHE",
            sortable: false,
            value: "tranche",
            cell: (data: SeparateAccountsRow) => (
                <TableCell data={data.tranche} />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 5,
            title: "PERFORMANCE FEE DUE",
            sortable: false,
            value: "performance_fee_payment_amount",
            cell: (data: SeparateAccountsRow) => (
                <TableCell
                    data={FloatToCurrencyParser(
                        data.performance_fee_amount,
                        data.currency
                    )}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 6,
            title: "STATUS",
            sortable: false,
            value: "status",
            cell: (data: SeparateAccountsRow) => (
                <TableCell data={data.status} />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 7,
            title: "SIGN OFF",
            sortable: false,
            value: "sign_off",
            cell: (data: SeparateAccountsRow) => (
                <EditableCell
                    rowId={data.id}
                    cellValueType="string"
                    cellValue="comments"
                    rowIdKey={"id"}
                    initialValue={data.performance_fee_sign_off}
                    type="selectOptions"
                    selectOptions={[
                        {
                            label: "yes",
                            value: "yes",
                        },
                        {
                            label: "no",
                            value: "no",
                        },
                    ]}
                    setTableIsLoading={setTableIsLoading}
                    updateTable={() => setReload(reload + 1)}
                    tableIsLoading={tableIsLoading}
                    onUpdate={(idx: number, value: string) => {
                        return updateFeesData(
                            "performance_fee_sign_off",
                            data.account_id,
                            selectedPeriod,
                            data.tranche,
                            value,
                            {
                                version: data.report_version,
                                report_id: data.report_id,
                            }
                        );
                    }}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {

            id: 8,
            title: "SIGNED OFF UPDATED BY",
            sortable: false,
            value: "status",
            cell: (data: SeparateAccountsRow) => (
                <TableCell data={data.performance_fee_sign_off_updated_by_name} />
            ),
            show: true,
            fullDataRow: true,
        },
    ];
    return (
        <PerformanceFeesContainer>
            <div className="tableDiv">
                <div className="underline">
                    <h3>PAID</h3>
                </div>
                <Table
                    emptyText={"No Data"}
                    showEmptyIcon={false}
                    data={performanceFees}
                    columns={paidColumns}
                    filterQuery={filterQuery + "&period=" + period}
                    reload={reload}
                    pagination
                    defaultSortKey="event_date"
                    defaultSortOrder="DESC"
                    stickyColumns={[0, 1]}
                />
            </div>
            <div className="tableDiv">
                <div className="underline">
                    <h3>REGISTER</h3>
                </div>
                <Table
                    emptyText={"No Data"}
                    showEmptyIcon={false}
                    data={prevPerformanceFees}
                    columns={registerColumns}
                    filterQuery={filterQuery + "&period=" + period}
                    reload={reload}
                    defaultSortKey="event_date"
                    defaultSortOrder="DESC"
                    stickyColumns={[0, 1]}
                />
            </div>
        </PerformanceFeesContainer>
    );
};
export default PerformanceFees;
