import colors from "assets/styles/colors";

export default {
    labelSuccessBg: colors.accentGreen,
    labelSuccessColor: "white",

    labelAlertBg: "red",
    labelAlertColor: "white",

    labelWarningBg: colors.accentOrange,
    labelWarningColor: "white",

    labelPrimaryBg: colors.darkAccentBlue,
    labelPrimaryColor: "white",

    labelSecondaryBg: colors.grey80,
    labelSecondaryColor: "white",
};
