import { useState } from "react";

// Components
import Button from "components/Common/Button/Button";
import Alert from "components/Common/Alert/Alert";

// Microsoft Auth Library
import { useMsal } from "@azure/msal-react";

// Utils
import {hardLogout} from "utils/user.utils";

// Context
import { useAuth } from "context/Auth.context";
import { useWebSockets } from "context/Websocket.context";

// Styles
import ProfileContainer from "./Profile.styles";

export default function Profile() {
    const { user } = useAuth();
    const { instance } = useMsal();
    const { disconnectWebSocket } = useWebSockets()

    const [showSignOutModal, setShowSignOutModal] = useState(false);

    const handleLogOut = async () => {
        disconnectWebSocket();
        await hardLogout();
    };

    return (
        <ProfileContainer>
            <div className={"body"}>
                <div className={"avatar"}>
                    <p>{user.name[0]}</p>
                </div>

                <p className={"name"}>{user.name.toUpperCase()}</p>
                <p className={"email"}>{user.email}</p>
            </div>

            <div
                className="logoutBtn"
                onClick={() => setShowSignOutModal(true)}
            >
                <p>LOGOUT</p>
            </div>

            {showSignOutModal && (
                <Alert>
                    <h2>LOGOUT</h2>
                    <p>Are you sure you would like to logout?</p>

                    <Button
                        text="LOGOUT"
                        color="primary"
                        click={handleLogOut}
                    />

                    <Button
                        text="CLOSE"
                        color="flat"
                        click={() => setShowSignOutModal(false)}
                    />
                </Alert>
            )}
        </ProfileContainer>
    );
}
