import styled from "styled-components";

export default styled.div`
    display: flex;
    flex-direction: column;

    .graphContainer {
        margin-top: 10px;
        height: 200px;
        flex-grow: 1;
        display: flex;
    }

    .filterBtn {
        width: fit-content;
    }
`;
