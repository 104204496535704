import styled from "styled-components";
import colors from "assets/styles/colors";

export default styled.div`
    flex-direction: column;
    display: flex;
    padding: 10px 0 0 0;
    width: 300px;
    margin-top: 10px;

    background-color: white;
    box-shadow: 5px 5px 10px 5px rgba(0, 0, 0, 0.28);

    .loadingSpinner {
        margin: 30px 0;
    }

    h3 {
        margin: 0 0 10px 0;
        font-size: 18px;
        color: ${colors.textTeal};
        letter-spacing: 0.1em;
        text-align: center;
    }

    p {
        margin: 0;
        font-size: 14px;
        line-height: 120%;
    }

    .list {
        .extItem {
            height: 50px;
            border-top: 1px solid #dddddd;
            justify-content: space-between;
            align-items: center;
            display: flex;
            padding: 0 10px;

            .statusCell,
            .date {
                display: flex;
                width: 50%;
                justify-content: flex-start;
            }

            .goToBtn {
                font-size: 16px;
                color: ${colors.textNavy};
            }

            :hover {
                background-color: #efefef;
                cursor: pointer;
            }
        }
    }
`;
