import styled from "styled-components";

export default styled.div`
    .col {
        display: flex;
        flex-direction: column;
    }
    .row {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;

        margin: 0 -10px;

        min-width: 100%;

        .half {
            display: flex;
            flex-direction: column;

            width: 50%;

            .filterBtn,
            .dateSelect {
                margin: 10px;

                .dateBox {
                    min-width: unset;
                    max-width: unset;
                }
            }

            .MuiFormControlLabel-root {
                margin-left: 0;
            }

            .Mui-checked {
                .MuiSvgIcon-root {
                    color: ${(props) => props.theme.primaryButtonBackground};
                }
            }
        }
    }

    .actions {
        .button {
            flex-grow: 1;
        }
    }
`;
