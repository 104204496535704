// React
import { useState, useEffect } from "react";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

// Components
import SearchOptions from "components/Common/SearchOptions/SearchOptions";
import Table from "components/Common/Table/Table";
import CheckCell from "components/Screens/Admin/Permissions/CheckCell/CheckCell";
import NewPermissionModal from "components/Screens/Admin/Permissions/NewPermissionModal/NewPermissionModal";
import TableCell from "components/Common/TableCell/TableCell";
import CustomPermissionModal from "../CustomPermissionModal/CustomPermissionModal";

// Utils
import * as Database from "utils/database.utils";

// Context
import { useSettings } from "context/Settings.context";

// Styles
import PermissionsContainer from "./Permissions.styles";

export default function Permissions() {
    const { setShowLoadingToast } = useSettings();

    const uneditable = ["s_update_permissions", "s_view_permissions"];

    const [loading, setLoading] = useState<boolean>(true);
    const [filterQuery, setFilterQuery] = useState<string>("");
    const [showNewPermissionModal, setShowNewPermissionModal] =
        useState<boolean>(false);

    const [showCustomPermissionsModal, setShowCustomPermissionsModal] =
        useState<boolean>(false);

    const [permissionsList, setPermissionsList] = useState<any[]>([]);
    const [groupIDs, setGroupIDs] = useState<any>({
        admin: "",
        global: "",
        research: "",
        platform: "",
        trading: "",
        company: "",
        bqmqAssistant: "",
        bqmqUser: "",
        bqmqViewer: "",
        feesUser: "",
        feesSuperUser: "",
        chronUser: "",
        missionControlUser: "",
        missionControlPublicUser: "",
        growthVotingUser: "",
    });

    const columns = [
        {
            id: 1,
            title: "NAME",
            sortable: false,
            value: "permission",
            cell: (data: string) => <TableCell data={data} />,
            show: true,
        },
        {
            id: 2,
            title: "DESCRIPTION",
            sortable: false,
            value: "description",
            cell: (data: string) => <TableCell data={data} />,
            show: true,
        },
        {
            id: 3,
            title: "ADMIN",
            sortable: false,
            value: "",
            cell: (data: any) => (
                <CheckCell
                    selected={data.admin}
                    group={groupIDs.admin}
                    permission={data.id}
                    reload={getPermissionsList}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 4,
            title: "GLOBAL",
            sortable: false,
            value: "",
            cell: (data: any) => (
                <CheckCell
                    selected={data.global}
                    group={groupIDs.global}
                    permission={data.id}
                    reload={getPermissionsList}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 5,
            title: "RESEARCH",
            sortable: false,
            value: "",
            cell: (data: any) => (
                <CheckCell
                    selected={data.research}
                    group={groupIDs.research}
                    permission={data.id}
                    reload={getPermissionsList}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 6,
            title: "PORTFOLIO",
            sortable: false,
            value: "",
            cell: (data: any) => (
                <CheckCell
                    selected={data.portfolio}
                    group={groupIDs.portfolio}
                    permission={data.id}
                    reload={getPermissionsList}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 7,
            title: "TRADING",
            sortable: false,
            value: "",
            cell: (data: any) => (
                <CheckCell
                    selected={data.trading}
                    group={groupIDs.trading}
                    permission={data.id}
                    reload={getPermissionsList}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 8,
            title: "COMPANY",
            sortable: false,
            value: "",
            cell: (data: any) => (
                <CheckCell
                    selected={data.company}
                    group={groupIDs.company}
                    permission={data.id}
                    reload={getPermissionsList}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 9,
            title: "BQMQ ASSISTANT",
            sortable: false,
            value: "",
            cell: (data: any) => (
                <CheckCell
                    selected={data.bqmqAssistant}
                    group={groupIDs.bqmqAssistant}
                    permission={data.id}
                    reload={getPermissionsList}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 10,
            title: "BQMQ USER",
            sortable: false,
            value: "",
            cell: (data: any) => (
                <CheckCell
                    selected={data.bqmqUser}
                    group={groupIDs.bqmqUser}
                    permission={data.id}
                    reload={getPermissionsList}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 11,
            title: "BQMQ VIEWER",
            sortable: false,
            value: "",
            cell: (data: any) => (
                <CheckCell
                    selected={data.bqmqViewer}
                    group={groupIDs.bqmqViewer}
                    permission={data.id}
                    reload={getPermissionsList}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 13,
            title: "FEES USER",
            sortable: false,
            value: "",
            cell: (data: any) => (
                <CheckCell
                    selected={data.feesUser}
                    group={groupIDs.feesUser}
                    permission={data.id}
                    reload={getPermissionsList}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 14,
            title: "FEES SUPER USER",
            sortable: false,
            value: "",
            cell: (data: any) => (
                <CheckCell
                    selected={data.feesSuperUser}
                    group={groupIDs.feesSuperUser}
                    permission={data.id}
                    reload={getPermissionsList}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 15,
            title: "NAVS USER",
            sortable: false,
            value: "",
            cell: (data: any) => (
                <CheckCell
                    selected={data.dailyNavsUser}
                    group={groupIDs.dailyNavsUser}
                    permission={data.id}
                    reload={getPermissionsList}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        // {
        //     id: 16,
        //     title: "NAVS SUPER USER",
        //     sortable: false,
        //     value: "",
        //     cell: (data: any) => (
        //         <CheckCell
        //             selected={data.dailyNavsSuperUser}
        //             group={groupIDs.dailyNavsSuperUser}
        //             permission={data.id}
        //             reload={getPermissionsList}
        //         />
        //     ),
        //     show: true,
        //     fullDataRow: true,
        // },
        {
            id: 17,
            title: "SFDR DATA CAPTURE USER",
            sortable: false,
            value: "",
            cell: (data: any) => (
                <CheckCell
                    selected={data.sfdrEsgUser}
                    group={groupIDs.sfdrEsgUser}
                    permission={data.id}
                    reload={getPermissionsList}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 18,
            title: "SFDR DATA CAPTURE SUPER USER",
            sortable: false,
            value: "",
            cell: (data: any) => (
                <CheckCell
                    selected={data.sfdrEsgSuperUser}
                    group={groupIDs.sfdrEsgSuperUser}
                    permission={data.id}
                    reload={getPermissionsList}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 16,
            title: "CHRONOGRAPH USER",
            sortable: false,
            value: "",
            cell: (data: any) => (
                <CheckCell
                    selected={data.chronUser}
                    group={groupIDs.chronUser}
                    permission={data.id}
                    reload={getPermissionsList}
                />
            ),
            show: false,
            fullDataRow: true,
        },
        {
            id: 17,
            title: "MISSION CONTROL USER",
            sortable: false,
            value: "",
            cell: (data: any) => (
                <CheckCell
                    selected={data.missionControlUser}
                    group={groupIDs.missionControlUser}
                    permission={data.id}
                    reload={getPermissionsList}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 18,
            title: "MISSION CONTROL PUBLIC USER",
            sortable: false,
            value: "",
            cell: (data: any) => (
                <CheckCell
                    selected={data.missionControlPublicUser}
                    group={groupIDs.missionControlPublicUser}
                    permission={data.id}
                    reload={getPermissionsList}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 19,
            title: "GROWTH V. USER",
            sortable: false,
            value: "",
            cell: (data: any) => (
                <CheckCell
                    selected={data.growthVotingUser}
                    group={groupIDs.growthVotingUser}
                    permission={data.id}
                    reload={getPermissionsList}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 12,
            title: "",
            sortable: false,
            value: "id",
            cell: (data: any) =>
                !data.admin &&
                !data.global &&
                !data.company && (
                    <FontAwesomeIcon
                        className="deleteBtn"
                        icon={faTrash}
                        fixedWidth
                        onClick={() => deletePermission(data.id)}
                    />
                ),
            show: true,
            fullDataRow: true,
        },
    ];

    const filterOptions = [
        {
            id: 17,
            name: "Group",
            value: "group",
            type: "DROPDOWN",
            searchable: false,
            options: [
                { label: "ADMIN", value: "admin" },
                { label: "GLOBAL", value: "global" },
                { label: "COMPANY", value: "company" },
            ],
        },
    ];

    const deletePermission = async (id: string) => {
        setShowLoadingToast({ show: true, message: "UPDATING" });
        let res = await Database.remove("api/permissions/" + id);

        if (res.ok) {
            getPermissionsList();
        }
    };

    const getPermissionsList = async () => {
        setShowLoadingToast({ show: true, message: "LOADING" });

        let res = await Database.get("api/permissions");

        if (res.ok) {
            let data = await res.json();
            let filteredList = filterPermissions(data.data);
            setPermissionsList(
                filteredList.map((perm) => ({
                    ...perm,
                    disabled: uneditable.includes(perm.permission),
                }))
            );
            setLoading(false);
            setGroupIDs(data.groupIDs);
            setShowLoadingToast({ show: false, message: "" });
        }
    };

    const filterPermissions = (list: any[]) => {
        let filteredList: any[] = [];
        let group = filterQuery.split("=")[1];

        list.forEach((perm) => {
            if (perm[group] || !group) {
                filteredList.push(perm);
            }
        });
        return filteredList;
    };

    useEffect(() => {
        getPermissionsList();
    }, [filterQuery]);

    return (
        <PermissionsContainer>
            <div className="searchBar">
                <SearchOptions
                    setFilterQuery={setFilterQuery}
                    options={filterOptions}
                />

                <div
                    className="addNewButton new"
                    onClick={() => setShowNewPermissionModal(true)}
                >
                    <p>CREATE NEW</p>
                </div>

                <div
                    className="addNewButton custom"
                    onClick={() => setShowCustomPermissionsModal(true)}
                >
                    <p>CUSTOM</p>
                </div>
            </div>

            <Table
                data={permissionsList}
                loading={loading}
                columns={columns}
                filterQuery={filterQuery}
                defaultSortKey="none"
            />

            {Boolean(showNewPermissionModal) && (
                <NewPermissionModal
                    setShowModal={setShowNewPermissionModal}
                    reload={getPermissionsList}
                />
            )}

            {showCustomPermissionsModal && (
                <CustomPermissionModal
                    groupIDs={groupIDs}
                    permissionsList={permissionsList}
                    setShowModal={setShowCustomPermissionsModal}
                />
            )}
        </PermissionsContainer>
    );
}
