import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// Utils
import queryString from "query-string";
// Context
import { useBQMQ } from "context/BQMQ.context";
import { useSettings } from "context/Settings.context";
// Components
import HomeList from "../../../Home/HomeList/HomeList";
import CompletedSessionRow from "./CompletedSessionRow/CompletedSessionRow";

// Styles
import CompletedSessionsListContainer from "./CompletedSessionsList.styles";

type Props = {
    filterQuery: string;
    orderBy: string;
};

const orderSessions = (newFilteredData: BQMQSession[], orderBy: string) => {
    switch (orderBy) {
        case "start_date":
            newFilteredData = newFilteredData.sort(
                (a: any, b: any) => a.date - b.date // TODO: date must be processed somewhere, perhaps in utils func. When that is refactoed this needs type checking
            );
            break;
        case "company_name":
            newFilteredData = newFilteredData.sort(
                (a: BQMQSession, b: BQMQSession) =>
                    a.company.name.localeCompare(b.company.name)
            );
            break;
        case "gim_subteam":
            newFilteredData = newFilteredData.sort(
                (a: BQMQSession, b: BQMQSession) =>
                    (a.company?.sector?.name || "Unknown").localeCompare(
                        b.company?.sector?.name ||
                            a.company.sector.name ||
                            "Unknown"
                    )
            );
            break;
        case "analyst_name":
            newFilteredData = newFilteredData.sort(
                (a: BQMQSession, b: BQMQSession) =>
                    (a.presenter.name || "Unknown").localeCompare(
                        b.presenter.name || "Unknown"
                    )
            );
            break;
        default:
            break;
    }
    return newFilteredData;
};

const filterSessions = (
    newFilteredData: BQMQSession[],
    query: any,
    teams: any
) => {
    if (query.filter_search) {
        newFilteredData = newFilteredData.filter((session: BQMQSession) =>
            session?.company.name
                .toUpperCase()
                .includes(query.filter_search.toUpperCase())
        );
    }

    if (query.filter_company_id) {
        newFilteredData = newFilteredData.filter(
            (session: any) => session.company.id === query.filter_company_id
        );
    }

    if (query.filter_gim_subteam) {
        newFilteredData = newFilteredData.filter((session: any) => {
            const team = teams.find(
                (team: any) => team.value === query.filter_gim_subteam
            );
            return session?.company?.sector?.name === team.label;
        });
    }

    if (query.filter_analyst_id) {
        newFilteredData = newFilteredData.filter(
            (session: any) => session.presenter_id === query.filter_analyst_id
        );
    }

    return newFilteredData;
};

export default function CompletedSessionsList({ filterQuery, orderBy }: Props) {
    const history = useHistory();
    const { sessions, teams } = useBQMQ();
    const { reloadHomePage } = useSettings();
    const [data, setData] = useState<BQMQSession | []>([]);
    const [filteredData, setFilteredData] = useState<BQMQSession[]>([]);
    const [total, setTotal] = useState(0);

    const getCompletedSessionsList = async () => {
        setData(
            sessions.map((row: any, index: number) => ({
                ...row,
                tableID: row.id + row.date + index,
            }))
        );
        setTotal(sessions.length);
    };

    useEffect(() => {
        if (data) {
            let newFilteredData = [...sessions];
            let query = queryString.parse(filterQuery);
            newFilteredData = filterSessions(newFilteredData, query, teams);
            newFilteredData = orderSessions(newFilteredData, orderBy);
            setTotal(newFilteredData.length);
            setFilteredData(newFilteredData);
        }
    }, [sessions, filterQuery, orderBy]);

    useEffect(() => {
        getCompletedSessionsList();
    }, [reloadHomePage]);

    return (
        <CompletedSessionsListContainer>
            <HomeList
                data={filteredData}
                row={(row: any) => <CompletedSessionRow data={row} />}
                title={"LATEST SESSIONS"}
                count={total}
                countColor={""}
                seeAll={() => history.push("/voting/results")}
                maxRows={1000}
            />
        </CompletedSessionsListContainer>
    );
}
