// @ts-nocheck
// Utils
import { toDateAndTime } from "utils/general.utils";

// Components
import Actions from "../../CompanyModel/Actions/Actions";
import Status from "components/Screens/Data/CompanyModel/Status/Status";

// Styles
import ExtractionRowContainer from "./ExtractionRow.styles";
import { Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faFilm, faUsers } from "@fortawesome/free-solid-svg-icons";
import { teamLabelDict } from "../../../../../assets/data/lists";

type Props = {
    data: any;
};

export default function ExtractionRow({ data }: Props) {
    return (
        <ExtractionRowContainer>
            <div className={"info"}>
                <div className={"name"}>{data.company}</div>
                <div className={"details"}>
                    <Tooltip title={"TEAM"}>
                        <div className={"detail"}>
                            <FontAwesomeIcon icon={faUsers} fixedWidth />
                            <p>{teamLabelDict[data.sub_team]}</p>
                        </div>
                    </Tooltip>
                </div>
            </div>

            <div className={"date"}>
                {toDateAndTime(data.extraction_datetime)}
            </div>

            <Status
                status={
                    data.num_errors_critical > 0 ? "FAI" : data.model_status
                }
                extractionId={data.id}
                info={data.num_errors_info}
                warning={data.num_errors_warning}
                critical={data.num_errors_critical}
                noText
            />

            <div className={"actions"}>
                <Actions
                    status={
                        data.num_errors_critical > 0 ? "FAI" : data.model_status
                    }
                    id={data.id}
                    companyName={data.company}
                    companyID={data.company_id}
                    extraction_datetime={data.extraction_datetime}
                    processing_datetime={data.processing_datetime}
                    archiveFolderPath={data.archive_folder_path}
                    reload={() => {}}
                />
            </div>
        </ExtractionRowContainer>
    );
}
