import { useSettings } from "context/Settings.context";
import React, { useState } from "react";
import { ConfigurationsInputValues } from "../types";

// Styles
import UpdateConfigurationsModalContainer from "./UpdateConfigurationsModal.styles";

// Utils
import * as Database from "utils/database.utils";
import Modal from "components/Common/Modal/Modal";
import ConfigurationsInputFields from "../ConfigurationsInputFields/ConfigurationsInputFields";
import Button from "components/Common/Button/Button";

interface UpdateConfigurationsModalProps {
    setShowModal: Function;
    runUpdate: Function;
    configurationsValues: ConfigurationsInputValues;
}

const UpdateConfigurationsModal = ({
    setShowModal,
    runUpdate,
    configurationsValues,
}: UpdateConfigurationsModalProps) => {
    const { setToast, setShowLoadingToast } = useSettings();

    const [loading, setLoading] = useState<boolean>(false);
    const [newConfigurationsValues, setNewConfigurationsValues] =
        useState<any>(configurationsValues);

    const handleChange = (value: number | string, name: string) => {
        setNewConfigurationsValues({
            ...newConfigurationsValues,
            [name]: value,
        });
    };

    const handleUpdate = async () => {
        setShowLoadingToast({
            show: true,
            message: "UPDATING",
        });
        for (const [key, value] of Object.entries(newConfigurationsValues)) {
            if (typeof value === "string")
                newConfigurationsValues[
                    key as keyof ConfigurationsInputValues
                ] = parseFloat(value);
        }

        //remove keys that should not be passed to post obj
        //by use of destructuring
        const {
            id,
            signed_off_dt_1,
            signed_off_dt_2,
            signed_off_by_1,
            signed_off_by_2,
            version,
            ...filteredPostObj
        } = newConfigurationsValues;

        let response = await Database.post(
            "api/nav/user_inputs/configurations/",
            filteredPostObj
        );
        if (response.ok) {
            setToast({
                show: true,
                type: "success",
                message: "Update configurations data.",
            });
            runUpdate();
            setShowModal(false);
        }
        setShowLoadingToast({
            show: false,
            message: "",
        });
    };

    return (
        <UpdateConfigurationsModalContainer>
            <Modal loading={loading} width={"65vw"}>
                <div className="main">
                    <div className="text">
                        <h3>UPDATE CONFIGURATIONS</h3>
                    </div>
                    <ConfigurationsInputFields
                        handleChange={handleChange}
                        newConfigurationsValues={newConfigurationsValues}
                    />
                </div>
                <div className="actions">
                    <Button
                        text="CANCEL"
                        color="flat"
                        click={() => setShowModal(false)}
                    />
                    <Button text="SAVE" click={() => handleUpdate()} />
                </div>
            </Modal>
        </UpdateConfigurationsModalContainer>
    );
};
export default UpdateConfigurationsModal;
