import styled from "styled-components";

export default styled.div`
    position: fixed;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background-color: ${(props) => props.theme.modalBackground};
    backdrop-filter: ${(props) => props.theme.modalBackgroundFilter};

    justify-content: center;
    align-items: center;
    display: flex;
    animation: growModal 0.2s both linear;

    z-index: 250;

    .modalBox {
        flex-direction: column;
        align-items: center;
        display: flex;

        animation: growModal 0.2s both linear;

        color: ${(props) => props.theme.alertText};

        h2 {
            margin: 0 0 10px 0;
            font-size: 35px;
            font-weight: 900;
            letter-spacing: 0.2em;
            color: ${(props) => props.theme.alertTitle};
        }

        p {
            margin: 10px 0 30px 0;
            font-size: 18px;
            letter-spacing: 0.1em;
        }

        .button {
            min-width: 250px;
            margin-top: 10px;
            p {
                margin: 0;
            }
        }
    }

    @keyframes growModal {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
`;
