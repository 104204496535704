import styled from "styled-components";

export default styled.div`
    .back-button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color: ${(props) => props.theme.primaryButtonBackground};
        margin: 10px;
        cursor: pointer;
        width: fit-content;
        border: 1px solid ${(props) => props.theme.primaryButtonBackground};
        border-radius: 5px;
        padding: 5px;
        margin-left: -1px;
    }
    .titleRow {
        .actions {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        h1 {
            margin-bottom: 0;
        }
    }

    .details {
        display: flex;
        flex-direction: row;
        margin: 0 -5px 20px -5px;

        .detail {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 0 5px;

            p {
                color: black;
                font-size: 14px;
            }

            svg {
                color: #999999;
                font-size: 10px;
                margin-right: 4px;
                margin-bottom: 2px;
            }
        }
    }
`;
