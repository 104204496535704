class ChangeLog {
    private id: string;
    private change_type: string;
    private check_name: string;
    private created_at: string;
    private created_by: string;
    private reason: string;
    private report_date: Date|undefined;

    constructor(obj: any) {
        this.id = obj?.id;
        this.change_type = obj?.change_type;
        this.check_name = obj?.check_name;
        this.created_at = obj?.created_at;
        this.created_by = obj?.created_by;
        this.reason = obj?.reason;

        if (obj?.report_date) {
             this.report_date = new Date(obj.report_date)
        }
    }
}


class BaseAlert {
    public id: string;
    public acc_name: string;
    public account_number: string;
    public changelog: any;
    public report_date: Date|undefined;

    constructor(obj: any) {
        this.id = obj?.id;
        this.acc_name = obj?.acc_name;
        this.account_number = obj?.acc_number;
        this.changelog = new ChangeLog(obj?.changelog);

        if (obj?.report_date) {
             this.report_date = new Date(obj.report_date)
        } else if (this.changelog.report_date) {
            // bubble up the report date from the change log as a fallback
            this.report_date = this.changelog.report_date
        }
    }
}


export class AlertDailyFlow extends BaseAlert {
    public deviation: number;

    constructor(obj: any) {
        super(obj)
        this.deviation = obj?.deviation;

    }
}

export class AlertDailyMovements extends BaseAlert {
    public deviation_from_median: number;

    constructor(obj: any) {
        super(obj)
        this.deviation_from_median = obj?.deviation_from_median;
        this.account_number = obj?.ioo_acct;
    }
}

export class AlertBrokerExposures extends BaseAlert {
    public abs_fwd_notional_exposure: number;
    public broker: string;
    public chk1_notional_exp: boolean;
    public chk2_fx_gl_exp: boolean;
    public fwd_gain_loss_exposure: number;

    constructor(obj: any) {
        super(obj)
        this.account_number = obj?.account_number;
        this.abs_fwd_notional_exposure = obj?.abs_fwd_notional_exposure;
        this.broker = obj?.broker;
        this.chk1_notional_exp = obj?.chk2_fx_gl_exp;
        this.chk2_fx_gl_exp = obj?.chk2_fx_gl_exp;
        this.fwd_gain_loss_exposure = obj?.fwd_gain_loss_exposure;
    }
}

export class AlertHedgingRestrictions extends BaseAlert {
    public check1: boolean;
    public check2: boolean;
    public currencyCode: string
    public net_rel_exp: number;

    constructor(obj: any) {
        super(obj)

        this.account_number = obj?.account_number;
        this.check1 = obj?.check1;
        this.check2 = obj?.check2;
        this.currencyCode = obj?.currency_code;
        this.net_rel_exp = obj?.net_rel_exp;
    }
}

export class AlertLeverageCollateral extends BaseAlert {
    public check1_margin: boolean;
    public base_adjusted_collateral_value_held_by_principal: number;
    public base_adjusted_collateral_value_held_by_cpty: number;
    public collateral_value: number;
    public nav_usd_curr: number
    public agg_margin: number;

    constructor(obj: any) {
        super(obj)

        this.check1_margin = obj?.check1_margin;
        this.base_adjusted_collateral_value_held_by_principal = obj?.base_adjusted_collateral_value_held_by_principal;
        this.base_adjusted_collateral_value_held_by_cpty = obj?.base_adjusted_collateral_value_held_by_cpty;
        this.collateral_value = obj?.collateral_value;
        this.account_number = obj?.ioo_acct;
        this.nav_usd_curr = obj?.nav_usd_curr;
        this.agg_margin = obj?.agg_margin;
    }
}

export class AlertCRNTNav extends BaseAlert {
    public price: number;
    public a_ast_mv_bse: number;
    public monetary_dff: number;
    public bps_dff: number;
    public check: boolean;

    constructor(obj: any) {
        super(obj)

        this.account_number = obj?.ioo_acct;
        this.price = obj?.price;
        this.a_ast_mv_bse = obj?.a_ast_mv_bse;
        this.monetary_dff = obj?.monetary_dff;
        this.bps_dff = obj?.bps_dff;
        this.check = obj?.check;
    }
}

export class AlertCRNTCashStock extends BaseAlert {
    public stock_break: number;
    public bps_dispersion: number;
    public cashflow_forecast: number;
    public check: boolean;

    constructor(obj: any) {
        super(obj)

        this.account_number = obj?.ioo_acct;
        this.stock_break = obj?.stock_break;
        this.bps_dispersion = obj?.bps_dispersion;
        this.cashflow_forecast = obj?.cashflow_forecast;
        this.check = obj?.check;
    }
}


export class AlertCurrencyExposure {
    public id: string;
    public changelog: any;
    public report_date: Date|undefined;

    public check_name: string;
    public total_percentage: number;
    public check: boolean;

    constructor(obj: any) {
        this.id = obj?.id;
        this.changelog = new ChangeLog(obj?.changelog);

        if (obj?.report_date) {
             this.report_date = new Date(obj.report_date)
        } else if (this.changelog.report_date) {
            // bubble up the report date from the change log as a fallback
            this.report_date = this.changelog.report_date
        }

        this.check_name = obj?.check_name;
        this.total_percentage = obj?.total_percentage;
        this.check = obj?.check;
    }
}


export class AlertNDFExposure extends BaseAlert {
    public account_number: string;
    public ndf_exposure: number;
    public agg_margin: number;
    public ndf_exposure_2: number;
    public check: boolean;

    constructor(obj: any) {
        super(obj)

        this.account_number = obj?.account_number;
        this.ndf_exposure = obj?.ndf_exposure;
        this.agg_margin = obj?.agg_margin;
        this.ndf_exposure_2 = obj?.ndf_exposure_2;
        this.check = obj?.check;
    }
}


export class AlertForwards extends BaseAlert {
    public account_number: string;
    public abs_notional_exp: number;
    public check: boolean;

    constructor(obj: any) {
        super(obj)

        this.account_number = obj?.account_number;
        this.abs_notional_exp = obj?.abs_notional_exp;
        this.check = obj?.check;
    }
}


export class AlertEmir extends BaseAlert {
    public account_number: string;
    public abs_notional_exp: number;
    public eur_forward_exposure: number;
    public check: boolean;

    constructor(obj: any) {
        super(obj)

        this.account_number = obj?.account_number;
        this.abs_notional_exp = obj?.abs_notional_exp;
        this.eur_forward_exposure = obj?.eur_forward_exposure;
        this.check = obj?.check;
    }
}


export class AlertFormPf extends BaseAlert {
    public account_name: string;
    public account_number: string;
    public current_nav: number;
    public ten_day_nav: number;
    public sum: number;
    public rolling_calc: boolean;
    public check: boolean;

    constructor(obj: any) {
        super(obj)

        this.account_name = obj?.account_name;
        this.account_number = obj?.account_number;
        this.current_nav = obj?.current_nav;
        this.ten_day_nav = obj?.ten_day_nav;
        this.sum = obj?.sum;
        this.rolling_calc = obj?.rolling_calc;
        this.check = obj?.check;
    }
}

export class AlertAdverseCollateral extends BaseAlert {
    public account_name: string;
    public account_number: string;
    public agg_margin: number;
    public check: boolean;

    constructor(obj: any) {
        super(obj)

        this.account_number = obj?.account_number;
        this.account_name = obj?.account_name;
        this.agg_margin = obj?.agg_margin;
        this.check = obj?.check;
    }
}


export class AlertAdverseRedemption extends BaseAlert {
    public account_name: string;
    public account_number: string;
    public redemption_check: number;
    public check: boolean;

    constructor(obj: any) {
        super(obj)

        this.account_number = obj?.account_number;
        this.account_name = obj?.account_name;
        this.redemption_check = obj?.redemption_check;
        this.check = obj?.check;
    }
}
