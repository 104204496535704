import styled from 'styled-components';
import colors from "assets/styles/colors";

export default styled.div`
    .count {
        display: inline;
        flex-direction: row;
        align-items: center;
        padding: 0 10px;

        p {
            margin: 0;
        }
        &.green {
            color: ${colors.accentGreen};
        }
        &.red {
            color: ${colors.accentRed};
        }
    }

    .icon {
        width: 16px;
        height: 16px;
    }

    .downloadBtn {
        margin-bottom: 15px;
        margin-top: -60px;
    }

    .tableDiv {
        clear: right;
    }   

`;