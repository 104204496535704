import styled from 'styled-components';

export default styled.div`
    .textFieldDiv {
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;
    }

    .textField {
        min-width: 250px;
        max-width: 250px;
    }   

    .buttonDiv {
        display: flex;
    }

    .select {
        min-width: 262px;
        max-width: 262px;
        display: inline;
        float: left;
    }
`;