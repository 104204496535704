import styled from "styled-components";

export default styled.div`
    display: flex;
    flex-direction: column;

    min-width: calc((100% - 140px) / 7);
    max-width: calc((100% - 140px) / 7);

    aspect-ratio: 1/1;

    background: ${(props) => props.theme.cardBackground};
    transition: box-shadow 0.2s;
    border: ${(props) => props.theme.cardBorder};
    margin: 10px;
    border-radius: 5px;

    overflow: hidden;

    cursor: pointer;

    .fastLinkMain {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-grow: 1;

        svg {
            font-size: 50px;
            color: ${(props) => props.theme.squareButtonIcon};
            margin-bottom: 15px;
        }

        .title {
            display: flex;
            width: 100%;

            justify-content: center;
            text-align: center;
            font-size: 20px;

            padding: 0 20px;
        }
    }

    .seeAll {
        display: flex;
        justify-content: center;
        align-items: center;

        min-width: 100%;
        max-width: 100%;
        min-height: 20px;
        max-height: 20px;

        font-size: 12px;

        background-color: ${(props) => props.theme.mainMenuBackground};
        color: ${(props) => props.theme.mainMenuForeground};

        svg {
            font-size: 10px;
            margin-left: 5px;
            margin-bottom: 1px;
        }
    }

    :hover {
        position: relative;
        box-shadow: ${(props) => props.theme.cardBoxShadowHover};
        transition: margin-top 0.2s;
        margin-top: 5px;
    }

    &.disabled {
        cursor: auto;

        :hover {
            background-color: ${(props) => props.theme.squareButtonBackground};
        }
    }

    @media screen and (max-width: 1500px) {
        min-width: calc((100% - 140px) / 8);
        max-width: calc((100% - 140px) / 8);

        .fastLinkMain {
            svg {
                font-size: 35px;
                margin-bottom: 10px;
            }

            .title {
                font-size: 16px;
            }
        }
    }

    @media screen and (max-width: 1300px) {
        min-width: 150px;
        max-width: 150px;

        .fastLinkMain {
            svg {
                font-size: 40px;
                margin-bottom: 15px;
            }

            .title {
                font-size: 18px;
            }
        }
    }

    @media screen and (max-width: 900px) {
        min-width: 150px;
        max-width: 150px;

        .fastLinkMain {
            svg {
                font-size: 40px;
                margin-bottom: 15px;
            }

            .title {
                font-size: 18px;
            }
        }
    }
`;
