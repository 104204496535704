import styled from "styled-components";

export default styled.div`
    height: 40px;
    width: 70px;
    cursor: pointer;
    border-radius: 10px;
    margin: 2px 0 5px;

    .iconContainer {
        margin-top: 4px;
        height: 40px;
        min-width: 70px;
        max-width: 70px;

        text-align: center;
        justify-content: center;
        align-items: center;

        svg {
            font-size: 16px;
            color: ${(props) => props.theme.mainMenuForeground};
        }
        
    }

    .menuItemText {
        text-align: center;
        font-size: 10px;
        text-overflow: ellipsis;
        max-width: 72px;
        min-width: 72px;
        white-space: nowrap;
        overflow: hidden; 
        padding: 2px;
        color: ${(props) => props.theme.mainMenuForeground};
    }

    &:hover {
        background-color: ${(props) => props.theme.subMenuBackground};
        width: 70px;

        .iconContainer {
            svg {
                color: ${(props) => props.theme.mainMenuActiveForeground};
            }
        }

        .menuItemText { 
            color: ${(props) => props.theme.mainMenuActiveForeground};
        }
    }

    &.active {
        background-color: ${(props) => props.theme.subMenuBackground};
        width: 70px;
        border-radius: 10px;

        .iconContainer {
            svg {
                color: ${(props) => props.theme.mainMenuActiveForeground};
            }    
        }

        .menuItemText { 
            color: ${(props) => props.theme.mainMenuActiveForeground};
        }
    }
`;
