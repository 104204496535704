import styled from "styled-components";

export default styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 100%;

    padding: 20px;

    .votesIcons {
        color: ${(props) => props.theme.primaryButtonBackground};
        margin-right: 3px;
    }
`;
