import styled from "styled-components";
import colors from "assets/styles/colors";

export default styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 20px;

    .MuiFormControlLabel-label,
    label {
        font-family: Calibri;
        font-size: 16px;
        letter-spacing: 0.1em;
        margin-top: 5px;
        color: ${(props) => props.theme.textMain};
    }

    h3 {
        letter-spacing: 0.1em;
        margin-bottom: 5px;
        color: ${(props) => props.theme.pageSubTitleText};
    }

    hr {
        border: none;
        border-bottom: 1px solid ${(props) => props.theme.subMenuSeparator};
    }

    .settingGroup {
        margin-bottom: 20px;
        margin-top: 20px;
    }

    .filterBtn {
        width: fit-content;
        margin-top: 5px;
    }

    .switch {
        padding-left: 10px;
        margin-bottom: 0px;

        .MuiSwitch-thumb {
            color: ${(props) => colors.grey50} !important;
        }
        .MuiSwitch-track {
            background-color: ${(props) => colors.grey70} !important;
        }

        .Mui-checked {
            .MuiSwitch-thumb {
                color: ${(props) => props.theme.switchChecked} !important;
            }
        }

        label {
            color: ${(props) => props.theme.textPrimary};
            letter-spacing: 1px;
            font-size: 16px;
        }
    }
`;
