import PDFLogo from "assets/images/file_logos/icon_pdf.svg";
import DOCLogo from "assets/images/file_logos/icon_doc.svg";
import PPTLogo from "assets/images/file_logos/icon_ppt.svg";
import MISCLogo from "assets/images/file_logos/icon_misc.svg";

// Styles
import FileDisplayContainer from "./FileDisplay.styles";

type Props = {
    value?: string; // original value of the field
    onClick: () => void;
};

export default function FileDisplay({ value = "", onClick }: Props) {
    const fileExtension = value.split(".").at(-1)?.toLowerCase();
    const fileLogoMap: { [key: string]: string } = {
        pdf: PDFLogo,
        doc: DOCLogo,
        docx: DOCLogo,
        ppt: PPTLogo,
        pptx: PPTLogo,
        misc: MISCLogo,
    };

    const logoSrc: string =
        fileExtension && fileLogoMap[fileExtension]
            ? fileLogoMap[fileExtension]
            : fileLogoMap["misc"];

    return (
        <FileDisplayContainer>
            <button className="file" onClick={onClick}>
                <div className="filename" title={value}>
                    <div>{value}</div>
                </div>
                <div className="logo">
                    <img
                        src={logoSrc}
                        alt="button to download file related to the note"
                        height={40}
                    />
                </div>
            </button>
        </FileDisplayContainer>
    );
}
