// Styles
import DailyMovementsContainer from "./DailyMovements.styles";
import Tabs from "../../../../components/Common/Tabs/Tabs";
import ReportsTab from "../../../../components/Screens/DailyRecs/DailyFlowsCheck/ReportsTab/ReportsTab";
import AlertsTab from "../../../../components/Screens/DailyRecs/DailyFlowsCheck/AlertsTab/AlertsTab";
import ChangeLogTab from "../../../../components/Screens/DailyRecs/DailyFlowsCheck/ChangeLogTab/ChangeLogTab";

interface DailyMovementsProps {}

const powerBI = "https://app.powerbi.com/reportEmbed?reportId=2db8489b-448a-42d6-9cb7-169551777b62&autoAuth=true&ctid=bf4450a2-bfcb-472d-8c40-1425eafb1a3b&filterPaneEnabled=false";
const checkName = "daily_movements";

const DailyMovements = ({}: DailyMovementsProps) => {
    return (
        <DailyMovementsContainer>
            <p className={"sectionTitle"}>DAILY MOVEMENTS</p>
             <Tabs
                defaultTab="reports"
                tabs={[
                    {
                        value: "reports",
                        title: "REPORTS",
                        permissions: ["p_can_view_navs", "p_can_action_navs"],
                        content: <ReportsTab powerBI={powerBI}/>,
                    },
                    {
                        value: "alerts",
                        title: "ALERTS",
                        permissions: ["p_can_view_navs", "p_can_action_navs"],
                        content: <AlertsTab  checkName={checkName}/>,
                    },
                    {
                        value: "change_log",
                        title: "CHANGE LOG",
                        permissions: ["p_can_view_navs", "p_can_action_navs"],
                        content: <ChangeLogTab checkName={checkName} />,
                    },
                ]}
            />
        </DailyMovementsContainer>
    );
};

export default DailyMovements;
