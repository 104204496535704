import React, { useState } from "react";

// FontAwesome
import {
    faEllipsisH,
    faTrash,
    faCheck,
    faPen,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Components
import DropDownMenu from "components/Common/DropDownMenu/DropDownMenu";
import DropDownMenuItem from "components/Common/DropDownMenu/DropDownMenuItem/DropDownMenuItem";
import StockSplitModal from "../StockSplitModal/StockSplitModal";
import DeleteRejectModal from "../DeleteRejectModal/DeleteRejectModal";

// Styles
import ActionsContainer from "./Actions.styles";

type Props = {
    newSS?: boolean;
    reloadData: Function;
    data: any;
};

export default function Actions({ newSS, reloadData, data }: Props) {
    const [anchorEl, setAnchorEl] = useState<any>(null);

    const [selectedStockSplit, setSelectedStockSplit] = useState<any>(false);
    const [showStockSplitModal, setShowStockSplitModal] = useState<
        false | "NEW" | "CONFIRM" | "EDIT"
    >(false);
    const [showDeleteRejectModal, setShowDeleteRejectModal] = useState<
        false | "reject" | "delete"
    >(false);

    return (
        <ActionsContainer>
            <FontAwesomeIcon
                icon={faEllipsisH}
                fixedWidth
                onClick={(e: React.MouseEvent) => setAnchorEl(e.currentTarget)}
            />

            <DropDownMenu
                open={Boolean(anchorEl)}
                setOpen={setAnchorEl}
                anchorEl={anchorEl}
            >
                {newSS ? (
                    <>
                        <DropDownMenuItem
                            icon={<FontAwesomeIcon icon={faCheck} fixedWidth />}
                            text="ACCEPT"
                            click={() => {
                                setSelectedStockSplit(data);
                                setShowStockSplitModal("CONFIRM");
                            }}
                        />
                        <DropDownMenuItem
                            icon={<FontAwesomeIcon icon={faTrash} fixedWidth />}
                            text="REJECT"
                            click={() => {
                                setSelectedStockSplit(data);
                                setShowDeleteRejectModal("reject");
                            }}
                        />
                    </>
                ) : (
                    <>
                        <DropDownMenuItem
                            icon={<FontAwesomeIcon icon={faPen} fixedWidth />}
                            text="EDIT"
                            click={() => {
                                setSelectedStockSplit(data);
                                setShowStockSplitModal("EDIT");
                            }}
                        />

                        <DropDownMenuItem
                            icon={<FontAwesomeIcon icon={faTrash} fixedWidth />}
                            text="DELETE"
                            click={() => {
                                setSelectedStockSplit(data);
                                setShowDeleteRejectModal("delete");
                            }}
                        />
                    </>
                )}
            </DropDownMenu>

            {showStockSplitModal && (
                <StockSplitModal
                    mode={showStockSplitModal!}
                    setShowModal={setShowStockSplitModal}
                    selectedStockSplit={selectedStockSplit}
                    reload={reloadData}
                />
            )}

            {showDeleteRejectModal && (
                <DeleteRejectModal
                    mode={showDeleteRejectModal}
                    setShowModal={setShowDeleteRejectModal}
                    selectedStockSplit={selectedStockSplit}
                    reload={reloadData}
                />
            )}
        </ActionsContainer>
    );
}
