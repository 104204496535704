import { useState } from "react";

// Components
import Table from "components/Common/Table/Table";
import Actions from "components/Screens/Data/Companies/StockSplits/Actions/Actions";
import TableCell from "components/Common/TableCell/TableCell";

// Styles
import CompanyStockSplitsContainer from "./CompanyStockSplits.styles";

type Props = { companyID: number };

export default function CompanyStockSplits({ companyID }: Props) {
    const [reload, setReload] = useState(0);

    const columnsConfirmed = [
        {
            id: 1,
            title: "COMPANY",
            sortable: false,
            value: "company",
            cell: (data: string) => (
                <TableCell styleTypes="capitalize" data={data.toLowerCase()} />
            ),
            show: true,
        },
        {
            id: 2,
            title: "SPLIT DATE",
            sortable: false,
            value: "split_date",
            cell: (data: string) => <TableCell data={data} />,
            show: true,
        },
        {
            id: 3,
            title: "SPLIT FACTOR",
            sortable: false,
            value: "split_factor",
            cell: (data: number) => <TableCell data={data} />,
            show: true,
        },
        {
            id: 4,
            title: "",
            sortable: false,
            value: "",
            cell: (data: any) => (
                <Actions data={data} reloadData={() => setReload(reload + 1)} />
            ),
            show: true,
            fullDataRow: true,
        },
    ];

    return (
        <CompanyStockSplitsContainer>
            <Table
                url={"/api/stock_splits/confirmed"}
                columns={columnsConfirmed}
                filterQuery={"&filter_company_id=" + companyID}
                defaultSortKey="split_date"
                emptyText="NO CONFIRMED STOCK SPLITS"
                reload={reload}
                skeletonLength={3}
            />
        </CompanyStockSplitsContainer>
    );
}
