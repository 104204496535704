/*
RichTextEditor is a WYSIWYG editor based on TinyMCE.

Note:
- There is RichText component which handle the read-only and edit mode as well.
  In case you need to display the html text and edit sometimes use that component.
*/

import React, { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Editor as TinyMCEEditor } from 'tinymce';

// Styles
import RichTextEditorContainer from "./RichTextEditor.styles";

type Props = {
    setText: React.Dispatch<React.SetStateAction<string>>
    value?: string // original value of the field
};


export default function RichTextEditor({setText, value=""}:Props) {
  const tiny_url = process.env.REACT_APP_FRONTEND_URL + '/tinymce/tinymce.min.js';
  const editorRef = useRef<TinyMCEEditor | null>(null);


  return (
      <RichTextEditorContainer>
          <div className={"rich-text-editor"}>
              <Editor
                  tinymceScriptSrc={tiny_url}
                  onInit={(evt, editor) => editorRef.current = editor}
                  value={value}
                  onEditorChange={setText}
                  init={{
                      promoton: false,
                      branding: false,
                      height: 300,
                      menubar: false,  // remove menu bar
                      paste_as_text: true, // remove formatting at paste
                      elementpath: false, // remove element path
                      plugins: [
                          'lists', 'links', 'anchor', 'wordcount', 'paste'
                      ],
                      toolbar: 'undo redo | bold | bullist numlist | removeformat | fontsize' ,
                      content_style: "body { line-height: 1; }",
                  }}
              />
          </div>
      </RichTextEditorContainer>

  );
}
