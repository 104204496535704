// Styles
import Tabs from "components/Common/Tabs/Tabs";
import ConfigurationsContainer from "./Configurations.styles";

// Components
import ConfigurationsTab from "components/Screens/DailyRecs/Configurations/ConfigurationsTab/ConfigurationsTab";
import PowerBIDashboard from "components/Common/PowerBIDashboard/PowerBIDashboard";

interface ConfigurationsProps {}

const Configurations = ({}: ConfigurationsProps) => {
    return (
        <ConfigurationsContainer>
            <p data-cy="navs-main-title" className={"sectionTitle"}>
                CONFIGURATIONS
            </p>
            <Tabs
                defaultTab="current_inputs"
                tabs={[
                    {
                        value: "current_inputs",
                        title: "CURRENT INPUTS",
                        permissions: ["p_can_view_navs", "p_can_action_navs"],
                        content: <ConfigurationsTab noSignOff />,
                    },
                    {
                        value: "proposed_inputs",
                        title: "PROPOSED INPUTS",
                        permissions: ["p_can_view_navs", "p_can_action_navs"],
                        content: <ConfigurationsTab />,
                    },
                    {
                        value: "report",
                        title: "REPORT",
                        permissions: ["p_can_view_navs", "p_can_action_navs"],
                        content: (
                            <div className="iframeContainer">
                                <iframe
                                    src={
                                        "https://app.powerbi.com/reportEmbed?reportId=dae37a88-69ae-4214-a44b-b2a41712914c&autoAuth=true&ctid=bf4450a2-bfcb-472d-8c40-1425eafb1a3b&filterPaneEnabled=false"
                                    }
                                    width="100%"
                                    height="100%"
                                    allowFullScreen
                                    frameBorder={0}
                                />
                            </div>
                        ),
                    },
                ]}
            />
        </ConfigurationsContainer>
    );
};
export default Configurations;
