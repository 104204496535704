import styled from 'styled-components';

export default styled.div`
    padding: 10px 0px;

    .vote-header {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 10px;
    }
    .vote-footer {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 30px;
        .state-comment {
            color: ${(props) => props.theme.subMenuForeground};
        }
    }
`;
