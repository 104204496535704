import { useState, useEffect } from "react";

// FontAwesome
import {
    faCaretDown,
    faCaretRight,
    faFolderClosed,
    faFolderOpen,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Components
import Item from "components/Docs/DocsMenu/Item/Item";

// Styles
import CategoryContainer from "./Category.styles";
import React from "react";
import { useAuth } from "../../../../context/Auth.context";
import { hasPermissions } from "utils/permissions.utils";

type Props = {
    item: any;
    doc: string;
    type: string;
};

export default function Category({ item, doc, type }: Props) {
    const { user } = useAuth();

    const [showItems, setShowItems] = useState(false);
    const [active, setActive] = useState(false);

    const checkChildrenAreActive = () => {
        let active = false;

        const checkChildren = (item: any) => {
            for (let i of item.children) {
                if (i.doc === doc) {
                    return true;
                } else if (i.children) {
                    if (checkChildren(i)) {
                        return true;
                    }
                }
            }
            return false;
        };

        for (let i of item.children) {
            if (i.doc === doc) {
                active = true;
                break;
            } else if (i.children) {
                if (checkChildren(i)) {
                    active = true;
                    break;
                }
            }
        }

        setActive(active);
        setShowItems(active);
    };

    useEffect(() => {
        checkChildrenAreActive();
    }, [doc]);

    return (
        <>
            {hasPermissions(item.permissions || [], user.permissions) && (
                <CategoryContainer>
                    <div
                        className={"item"}
                        onClick={() => setShowItems(!showItems)}
                    >
                        <FontAwesomeIcon
                            icon={showItems ? faFolderOpen : faFolderClosed}
                            fixedWidth
                        />

                        <p className={active ? "text active" : "text"}>
                            {item.name}
                        </p>
                    </div>

                    {showItems && (
                        <div className="catList">
                            {item.children
                                .sort((a: any, b: any) => a.order - b.order)
                                .map((subItem: any) => (
                                    <React.Fragment key={subItem.name}>
                                        {subItem.type === "FOLDER" ? (
                                            <Category
                                                item={subItem}
                                                doc={doc}
                                                type={type}
                                            />
                                        ) : (
                                            <Item
                                                item={subItem}
                                                doc={doc}
                                                type={type}
                                            />
                                        )}
                                    </React.Fragment>
                                ))}
                        </div>
                    )}
                </CategoryContainer>
            )}

            {item.border && <hr />}
        </>
    );
}
