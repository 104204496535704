import { useState } from "react";

// Components
import ResultCell from "../ResultCell/ResultCell";

// Utils
import {
    createPartial,
    sortAnswersForResults,
    sortVotes,
} from "utils/bqmq.utils";

// Styles
import { toDateAndTime } from "utils/general.utils";
import UserVotesTableContainer from "./UserVotesTable.styles";

type Props = {
    usersVotes: Vote[];
    results: Results;
    factor: string;
    setHighlightedFactor: Function;
};

const QuestionMap: QuestionMapType = {
    BQ: {
        bq_overall: "BQ Overall",
        business_stability: "Business Stability",
        attractiveness_of_castle: "Castle",
        pricing_power: "Pricing Power",
        degree_of_difficulty: "Degree of Difficulty",
        moat: "Moat",
        critical_sustainability_factors: "Sustainability",
        ecosystem: "Ecosystem",
    },
    MQ: {
        mq_overall: "MQ Overall",
        culture: "Culture",
        people: "People",
        operations: "Operations",
        capital_allocation: "Capital Allocation",
        governance: "Governance",
        strategy: "Strategy",
        alignment: "Alignment",
    },
    FADE: { fade: "Fade" },
    MQSTAR: { mqstar: "MQ*" },
};

const formatQuestion = (question: string, factor: string): string => {
    return QuestionMap[factor][question];
};

const setUserLastUpdated = (answers: Vote[], factor: string) => {
    const factorKey = answers.find(
        (key: Vote) =>
            key.question.factor.text.includes(factor) &&
            key.choice.text !== "Comment"
    );

    if (factorKey) {
        const date = factorKey.historic_date
            ? factorKey.historic_date
            : factorKey.created;
        return toDateAndTime(date);
    }
    return "Not voted";
};

const groupObjects = (usersVotes: Vote[]) => {
    return usersVotes.reduce((acc: GroupedVotes, obj: Vote) => {
        if (obj.analyst) {
            acc[obj.analyst.name] = [...(acc[obj.analyst.name] || []), obj];
        }
        return acc;
    }, {});
};

const setHeaders = (
    highlightedColumn: string,
    setHighlightedColumn: Function,
    setHighlightedFactor: Function,
    factor: string
) =>
    Object.keys(QuestionMap[factor]).map((key: string) => {
        return (
            <div
                className={
                    "question " +
                    (key === highlightedColumn ? "highlight " : "")
                }
                key={key}
                onMouseEnter={() => {
                    setHighlightedColumn(key);
                    setHighlightedFactor(key);
                }}
                onMouseLeave={() => {
                    setHighlightedColumn("");
                    setHighlightedFactor("");
                }}
            >
                <p>{formatQuestion(key, factor)}</p>
            </div>
        );
    });

const addResultsCell = (
    factor: string,
    results: Results,
    highlightedColumn: string,
    setHighlightedColumn: Function,
    setHighlightedFactor: Function,
    votes: Vote[]
) => {
    return Object.keys(QuestionMap[factor]).map((questionKey: string) => {
        const sortedVotes = sortVotes(votes, sortAnswersForResults(results));
        const matchingVote = sortedVotes.find(
            (vote: Vote) => vote.question.text === questionKey
        );
        if (matchingVote) {
            return (
                matchingVote.question.text !== "mode" &&
                matchingVote.question.factor.text === factor &&
                matchingVote.choice.text !== "Comment" && (
                    <div
                        className={
                            "answerCell " +
                            (matchingVote.question.text === highlightedColumn
                                ? "highlight"
                                : "")
                        }
                        key={matchingVote.question.text}
                        onMouseEnter={() => {
                            setHighlightedColumn(matchingVote.question.text);
                            setHighlightedFactor(matchingVote.question.text);
                        }}
                        onMouseLeave={() => {
                            setHighlightedColumn("");
                            setHighlightedFactor("");
                        }}
                    >
                        <ResultCell
                            value={matchingVote.choice.text
                                .toString()
                                .toUpperCase()}
                        />
                    </div>
                )
            );
        }
        return (
            <div
                className={
                    "answerCell " +
                    (questionKey === highlightedColumn ? "highlight" : "")
                }
                key={questionKey}
                onMouseEnter={() => {
                    setHighlightedColumn(questionKey);
                    setHighlightedFactor(questionKey);
                }}
                onMouseLeave={() => {
                    setHighlightedColumn("");
                    setHighlightedFactor("");
                }}
            >
                <ResultCell value={{ value: "" }} />
            </div>
        );
    });
};

const addRow = (
    usersVotes: Vote[],
    resultCellAdder: Function,
    factor: string
) => {
    return Object.entries(groupObjects(usersVotes))
        .sort((a: Result, b: Result) => a[0].localeCompare(b[0]))
        .map(([key, votes]) => (
            <div className={"userRow"} key={key}>
                <div className="user-details-wrapper">
                    <div className={"name"}>{`${key}`}</div>
                    <div className={"name"}>{`${setUserLastUpdated(
                        votes as Vote[],
                        factor as string
                    )}`}</div>
                </div>

                {resultCellAdder(votes)}
            </div>
        ));
};

export default function UserVotesTable({
    usersVotes,
    results,
    factor,
    setHighlightedFactor,
}: Props) {
    const [highlightedColumn, setHighlightedColumn] = useState<string>("");

    return (
        <UserVotesTableContainer>
            <div
                className={
                    "questionsRow " +
                    (["FADE", "MQSTAR"].includes(factor) ? "short" : "")
                }
            >
                <div className={"name"}></div>

                {setHeaders(
                    highlightedColumn,
                    setHighlightedColumn,
                    setHighlightedFactor,
                    factor
                )}
            </div>

            {addRow(
                usersVotes,
                createPartial(
                    addResultsCell,
                    factor,
                    results,
                    highlightedColumn,
                    setHighlightedColumn,
                    setHighlightedFactor
                ),
                factor
            )}
        </UserVotesTableContainer>
    );
}
