// Core
import { useState } from "react";

// Styles
import SettingsContainer from "./Settings.styles";

// Components
import PageTitle from "components/Common/PageTitle/PageTitle";
import Tabs from "components/Common/Tabs/Tabs";
import Companies from "components/Screens/Data/Companies/Companies/Companies";
import UserSettings from "./UserSettings/UserSettings";

export default function Settings() {
    const [tab, setTab] = useState<"users" | "questions">("users");

    return (
        <SettingsContainer className={"pageContainer"}>
            <PageTitle title="SETTINGS" />
            <Tabs
                defaultTab={tab}
                setTab={setTab}
                dontChangeUrl
                tabs={[
                    {
                        title: "USERS",
                        value: "users",
                        content: <UserSettings />,
                    },
                    // {
                    //     title: "ADMIN",
                    //     value: "admin",
                    //     content: <AdminSettings />,
                    //     permissions: ["p_bqmq_can_migrate_data"],
                    // },
                    {
                        title: "COMPANIES",
                        value: "companies",
                        content: <Companies />,
                    },
                    // {
                    //     title: "MIGRATE DATA",
                    //     value: "data",
                    //     content: <MigrateData />,
                    //     permissions: ["p_bqmq_can_migrate_data"],
                    // },
                ]}
            />
        </SettingsContainer>
    );
}
