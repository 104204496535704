//React
import { useEffect, useState } from "react";

// MaterialUI
import { Switch, Tooltip } from "@mui/material";

// FontAwesome
import { faRotate, faSearch, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Styles
import SelectUsersContainer from "./SelectUsers.styles";
import LoadingSpinner from "../../../../Common/LoadingSpinner/LoadingSpinner";

type Props = {
    users: Array<User>;
    setUsers: Function;
    chair: any;
    analyst: any;
};

type User = {
    active: boolean,
    completed: false,
    core: boolean,
    name: string,
    id: string,
    invited: boolean,
}

export default function SelectUsers({
    users,
    setUsers,
    chair,
    analyst,
}: Props) {
    const [searchString, setSearchString] = useState("");
    const [filteredUserList, setFilteredUserList] = useState<any[]>(users);
    const [allCoreUsersState, setAllCoreUsersState] = useState<boolean>(false)

    const changeValue = (type: string, userId: string) => {
        const updatedUser = users.find((user) => user.id == userId);
        if(updatedUser) {
            updatedUser.core = !updatedUser.core;
        } else {
            console.error('cannot find user')
        }

        let newUsers = users.filter((user) => user.id !== userId);

        setUsers(
            [updatedUser, ...newUsers].sort((a, b) =>
                a!.name.localeCompare(b!.name)
            )
        );
    };

    const toggleUsersCore = () => {
        const allCoreUsers = users.map((user) => {
            if(allCoreUsersState) {
                return {...user, core: false}
            } else {
                return {...user, core: true}
            }
        })
        setUsers(allCoreUsers)
        setAllCoreUsersState(!allCoreUsersState)
    }

    const reset = () => {
        setAllCoreUsersState(false)
        let newUsers = [...users];

        setUsers(
            newUsers.map((u: any) => {
                if (u.id === chair.id || u.id === analyst.id) {
                    return u;
                } else {
                    return {
                        ...u,
                        core: false,
                        invited: true,
                    };
                }
            })
        );
    };

    useEffect(() => {
        setFilteredUserList(
            users.filter((user: any) =>
                user.name.toLowerCase().includes(searchString.toLowerCase())
            )
        );
    }, [users, searchString]);

    return (
        <SelectUsersContainer>
            <div className={"heading"}>
                <p>SELECT ATTENDEES</p>

                <div className="toggles">
                    <Tooltip title={<h2>SET ALL ATTENDEES TO CORE</h2>}>
                        <button className={"allCoreBtn core" + (allCoreUsersState ? ' active' : '')} onClick={toggleUsersCore}>ALL CORE</button>
                    </Tooltip>
                    <Tooltip title={<h2>RESET</h2>}>
                        <div className={"resetBtn"} onClick={reset}>
                            <FontAwesomeIcon icon={faRotate} fixedWidth />
                        </div>
                    </Tooltip>
                </div>
            </div>


            <div className={"searchRow"}>
                <div className={"searchIcon"}>
                    <FontAwesomeIcon icon={faSearch} fixedWidth />
                </div>

                <input
                    data-cy="user-search"
                    className={"searchBox"}
                    placeholder={"Search Attendees"}
                    value={searchString}
                    onChange={(e) => setSearchString(e.target.value)}
                />
            </div>

            {filteredUserList.map((user: any, index: number) => (
                <div className={"userRow"} key={user.id} data-cy="user-row">
                    <div className={"icon " + (user.core ? "core " : "")}>
                        <FontAwesomeIcon icon={faUser} fixedWidth />
                    </div>

                    <div
                        data-cy="invited-user-name"
                        className={"name " + user.invited + " " + user.core}
                    >
                        {user.name}
                        {user.core && (
                            <div className={"coreTag hideSM"}>CORE</div>
                        )}

                        {user.id === chair.id && (
                            <div className={"coreTag hideSM chair"}>CHAIR</div>
                        )}

                        {user.id === analyst.id && (
                            <div className={"coreTag hideSM analyst"}>
                                PRESENTER
                            </div>
                        )}
                    </div>

                    {![chair.id, analyst.id].includes(user.id) && (
                        <div className={"actions"}>
                            <div
                                className={
                                    "actionBtn core " +
                                    (user.core ? "active" : "")
                                }
                                onClick={() => changeValue("core", user.id)}
                                data-cy="action-core"
                            >
                                CORE
                            </div>
                        </div>
                    )}
                </div>
            ))}

            {users.length === 0 && (
                <div className="loader">
                    <LoadingSpinner message={"LOADING ANALYSTS"} />
                </div>
            )}
        </SelectUsersContainer>
    );
}
