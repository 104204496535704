// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

// Components
import Table from "components/Common/Table/Table";
import TableCell from "components/Common/TableCell/TableCell";

// Styles
import CompanyHistoryContainer from "./CompanyHistory.styles";

type Props = { companyID: number };

export default function CompanyHistory({ companyID }: Props) {
    const columns = [
        {
            id: 1,
            title: "NAME",
            sortable: false,
            value: "company",
            cell: (data: string) => (
                <TableCell
                    styleTypes="capitalize companyName"
                    data={data.toLowerCase()}
                />
            ),
            show: true,
        },
        {
            id: 2,
            title: "TEAM",
            sortable: false,
            value: "gim_subteam",
            cell: (data: string) => (
                <TableCell styleTypes="capitalize" data={data.toLowerCase()} />
            ),
            show: true,
        },
        {
            id: 3,
            title: "ANALYST",
            sortable: false,
            value: "analyst_name",
            cell: (data: string) => <TableCell data={data} />,
            show: true,
        },
        {
            id: 4,
            title: "CURRENT",
            sortable: false,
            value: "current_company_ref_flag",
            cell: (data: number) =>
                data === 1 && (
                    <FontAwesomeIcon
                        className="checkIcon"
                        icon={faCheckCircle}
                        fixedWidth
                    />
                ),
            show: true,
        },
        {
            id: 5,
            title: "LIVE",
            sortable: false,
            value: "live_company_flag",
            cell: (data: number) =>
                data === 1 && (
                    <FontAwesomeIcon
                        className="checkIcon"
                        icon={faCheckCircle}
                        fixedWidth
                    />
                ),
            show: true,
        },
        {
            id: 6,
            title: "START DATE",
            sortable: false,
            value: "ref_start_date",
            cell: (data: string) => <TableCell data={data} />,
            show: true,
        },
        {
            id: 7,
            title: "END DATE",
            sortable: false,
            value: "ref_end_date",
            cell: (data: string) => <TableCell data={data} />,
            show: true,
        },
        {
            id: 8,
            title: "BBG TICKER",
            sortable: false,
            value: "bbg_ticker",
            cell: (data: string) => <TableCell data={data} />,
            show: true,
        },
        {
            id: 9,
            title: "RISK BUCKET",
            sortable: true,
            value: "bespoke_sector",
            cell: (data: string) => <TableCell data={data} />,
            show: true,
        },
    ];

    return (
        <CompanyHistoryContainer>
            <Table
                url={"api/companies"}
                columns={columns}
                filterQuery={"&filter_company_id=" + companyID}
                defaultSortKey="ref_start_date"
                defaultSortOrder={"DESC"}
                emptyText="NO COMPANIES"
                skeletonLength={3}
            />
        </CompanyHistoryContainer>
    );
}
