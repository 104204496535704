import styled from "styled-components";

export default styled.div`
    display: flex;

    flex-direction: column;
    align-items: center;

    .addNotesBtn {
        margin: -20px 0 0 0 !important;
        font-size: 14px !important;

        :hover {
            color: black;
            cursor: pointer;
        }
    }
`;
