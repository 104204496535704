import colors from "assets/styles/colors";

export default {
    modalBackground: "white",
    modalBackgroundFilter: "blur(10px)",
    modalBoxShadow: "0px 0px 20px 9px rgba(0, 0, 0, 0.1)",

    modalInnerBackground: "black",

    modalTitle: "white",
    modalText: "white",

    alertTitle: "white",
    alertText: "white",
};
