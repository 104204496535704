// FontAwesome
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Context
import { useSettings } from "context/Settings.context";

// Styles
import LoadingScreenContainer from "./LoadingScreen.styles";

export default function LoadingScreen() {
    const { showLoadingScreen } = useSettings();

    return (
        showLoadingScreen && (
            <LoadingScreenContainer>
                <div className="spinner">
                    <FontAwesomeIcon icon={faSpinner} fixedWidth />
                </div>
            </LoadingScreenContainer>
        )
    );
}
