// Components
import Button from "components/Common/Button/Button";
import Alert from "components/Common/Alert/Alert";

// Utils
import * as Database from "utils/database.utils";
import { toAnalyticsDate } from "utils/general.utils";

// Context
import { useSettings } from "context/Settings.context";

// Styles
import DeleteRejectModalContainer from "./DeleteRejectModal.styles";
import { useState } from "react";

type Props = {
    mode: "delete" | "reject";
    selectedStockSplit: any;
    setShowModal: Function;
    reload: Function;
};

const DeleteRejectModal: React.FC<Props> = ({
    mode,
    selectedStockSplit,
    setShowModal,
    reload,
}) => {
    const { setToast, setShowLoadingToast } = useSettings();

    const [loading, setLoading] = useState(false);

    const deleteReject = async () => {
        setLoading(true);
        setShowLoadingToast({
            show: true,
            message: mode.toUpperCase() + "ING",
        });

        let endpoints = {
            delete: "api/stock_splits/confirmed/delete",
            reject: "api/stock_splits/auto_detected/reject",
        };

        let res = await Database.post(endpoints[mode], {
            ...selectedStockSplit,
            update_datetime: toAnalyticsDate(new Date()),
            user_notes: "",
        });

        if (res.ok) {
            setToast({
                show: true,
                type: "success",
                message:
                    "Stock split has been " +
                    (mode === "delete" ? "deleted" : "rejected"),
            });

            setShowModal(false);
            reload();
        }
    };

    return (
        <Alert>
            <DeleteRejectModalContainer>
                <h2>{(mode && mode.toUpperCase()) + " STOCK SPLIT"}</h2>

                <p>Are you sure you would like to {mode} this stock split?</p>

                <Button
                    text={mode?.toUpperCase()}
                    color="accent"
                    click={deleteReject}
                    disabled={loading}
                />
                <Button
                    text="CANCEL"
                    color="flat"
                    click={() => setShowModal(false)}
                />
            </DeleteRejectModalContainer>
        </Alert>
    );
};

export default DeleteRejectModal;
