import colors from "assets/styles/colors";

export default {
    mainMenuBackground: colors.darkThemeDark,
    mainMenuForeground: "white",
    mainMenuActiveForeground: "white",

    subMenuBackground: colors.darkThemeMain,
    subMenuTitleText: "white",
    subMenuText: colors.grey80,
    subMenuHighlight: colors.darkThemeDark,
    subMenuActive: colors.darkThemeLight,
    subMenuSeparator: colors.grey80,
    subMenuHandleBorder: colors.darkThemeLight,

    subMenuButtonBackground: colors.darkThemeMain,
    subMenuButtonForeground: "white",
    subMenuButtonBackgroundHover: colors.darkThemeLight,
    subMenuButtonForegroundHover: "white",

    subMenuBadgeBackground: colors.accentRed,
    subMenuBadgeText: "white",
    subMenuBadgeBackgroundSecondary: colors.accentOrange,
    subMenuBadgeTextSecondary: "white",

    mobileMenuButtonBackground: colors.darkThemeLight,
    mobileMenuButtonForeground: "white",

    notificationsBackground: colors.darkThemeDark,
    notificationsHeaderBackground: colors.darkThemeLight,
    notificationsHeaderText: "white",
    notificationsRowText: "white",
    notificationsRowBackgroundHover: colors.darkThemeMain,
};
