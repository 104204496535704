// Styles
import SubMenuTitleContainer from "./SubMenuTitle.styles";
import Can from "components/Common/Can/Can";

type Props = {
    text: string;
    permissions?: string[];
};

export default function SubMenuTitle({ text, permissions = [] }: Props) {
    return (
        <Can
            yes={
                <SubMenuTitleContainer>
                    <p className={"text"}>{text}</p>
                    <div className={"separator"} />
                </SubMenuTitleContainer>
            }
            permissions={permissions}
        />
    );
}
