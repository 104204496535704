import React, {useEffect, useReducer, useState} from "react";
import { useHistory } from "react-router-dom";

//Components
import PageTitle from "components/Common/PageTitle/PageTitle";
import SearchOptions from "components/Common/SearchOptions/SearchOptions";
import Table from "components/Common/Table/Table";
import TableCell from "components/Common/TableCell/TableCell";
import { Checkbox, Tooltip } from "@mui/material";
import ButtonSmall from "components/Common/ButtonSmall/ButtonSmall";

//Context
import { useSFDRDataCapture } from "context/SFDRDataCapture.context";
import { useSettings } from "context/Settings.context";

//Utils
import { queryDatabase } from "services/api";
import { parseBool } from "utils/notifications.utlis";
import { createLocalDownload } from "utils/export.utils";
import * as Database from "utils/database.utils";
import {formatDateTime} from "utils/date.utils";

// Styles
import SFDRDataCaptureSubmissionsContainer from "./SFDRDataCaptureSubmissions.styles";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faArrowRight, faCheck, faSearch } from "@fortawesome/free-solid-svg-icons";

const DEFAULT_PAGE_SIZE = 25;
const BASE_API_URL = "api/sfdr_esg_data_capture/submissions";
const BASE_WEB_URL = "/sustainability/sfdr-data-capture/submissions";

type Props = {};

interface ISubmission {
    company_id: string;
    company_name: string;
    id: string;
    signed_off: boolean;
    signed_off_by: string;
    signed_off_datetime: string;
    submitted_datetime: string;
    user_id: string;
    user_name: string;
}


export default function SFDRDataCaptureSubmissions({}: Props) {
    const userId = localStorage.getItem("gim-user-id");

    const history = useHistory();
    const {setShowLoadingToast, setToast} = useSettings();
    const [total, setTotal] = useState<number | undefined>(undefined);
    const [sfdrData, setSfdrData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filterQuery, setFilterQuery] = useState("");
    const [url, setUrl] = useState("");
    const [filters, setFilters] = useState({
        company_id: false,
        signed_off: false,
        submitted_by: false,
    });

    const {
        dataCaptureContext,
        setDataCaptureContext,
        getCompaniesAndAnalystOptions,
    } = useSFDRDataCapture();

    const { companyList, analystList } = dataCaptureContext;

    const exportData = () => {
        const company =
            filters.company_id == false ? "all" : filters.company_id;
        const user =
            filters.submitted_by == false
                ? "all"
                : filters.submitted_by;

        handleDownload(
            `api/sfdr_esg_data_capture/${company}/${user}/download`,
            `SFDR Export.csv`
        );
    };

    const getSubmissions = async (query: string = "") => {
        let params = "";

        if (query) {
            params = `${query}`;
        } else {
            params = `?pageSize=${DEFAULT_PAGE_SIZE}&page=1`
        }

        if (filterQuery) {
            // if filter change reset pagination
            params += filterQuery;
        }

         // TODO: URL based filter and pagination not working correctly the Table trigger multiple fetch..
         //history.push(BASE_WEB_URL + params)
         setUrl(BASE_API_URL + params);
    };

    const fetchSubmissions = async () => {
        setShowLoadingToast({ show: true, message: "LOADING" });

        const result = await Database.get(url);
        let data = await result.json();

        setShowLoadingToast({ show: false});

        setLoading(false);
        setTotal(data.total);
        setSfdrData(data.data);
    }

    const handleDownload = async (url: string, filename: string) => {
        setShowLoadingToast({
            show: true,
            message: "DOWNLOADING",
        });

        const res = await Database.get(url);
        const file = await res.blob();
        createLocalDownload(filename, file);

        setShowLoadingToast({
            show: false,
            message: "",
        });
    };

    const setupFilterQuery = () => {
        let query = "";

        if (filters.company_id) {
            query += `&companyId=${filters.company_id}`;
        }

        if (filters.signed_off) {
            query += `&signedOff=${filters.signed_off}`;
        }

        if (filters.submitted_by) {
            query += `&submittedBy=${filters.submitted_by}`;
        }

        setFilterQuery(query);
    }

    const goTo = (data: any) => {
        history.push(
            `/sustainability/sfdr-data-capture/form?submission_id=${data.id}&user_id=${data.user_id}&company_id=${data.company_id}&signed_off=${data.signed_off}&submitted_on=${data.submitted_datetime}`
        )
    }

    const signOff = async (data: any) => {
        let url = `api/sfdr_esg_data_capture/submission/sign-off`;
         setShowLoadingToast({ show: true, message: "SAVING" });

        const res = await Database.post(url, {
            submission_id: data.id,
            signed_off_by: userId,
        });

        setShowLoadingToast({ show: false});

        if (res.ok) {
            setToast({
                show: true,
                type: "success",
                message: "SAVED",
            });

        } else {
            setToast({
                show: true,
                type: "error",
                message: "Error on sign off.",
            });
        }

        setShowLoadingToast({ show: false});

        setLoading(false);

        // set signed off to true, to signal on the UI
        sfdrData.forEach((submission: any) => {
            if (submission.id === data.id) {
                submission.signed_off = true;
                submission.signed_off_by = "You";
                submission.signed_off_datetime = formatDateTime(new Date());
            }
        });

        setSfdrData([...sfdrData]);
    }

    useEffect(() => {
        setupFilterQuery();
    }, [filters]);

    useEffect(() => {
        fetchSubmissions();
    }, [url]);

    useEffect(() => {
        getCompaniesAndAnalystOptions();
        setDataCaptureContext({
            selectedAnalyst: null,
            newSubmission: null,
        });
    }, []);

    const columns = [
        {
            id: 1,
            title: "SUBMITTED BY",
            sortable: true,
            value: "user_name",
            cell: (data: any) => <TableCell data={data.user_name} />,
            show: true,
            fullDataRow: true,
        },
        {
            id: 3,
            title: "COMPANY",
            sortable: true,
            value: "company_name",
            cell: (data: any) => <TableCell data={data.company_name} />,
            show: true,
            fullDataRow: true,
        },
        {
            id: 2,
            title: "SUBMISSION DATE",
            sortable: true,
            value: "submitted_datetime",
            cell: (data: any) => <TableCell data={data.submitted_datetime} />,
            show: true,
            fullDataRow: true,
        },
        {
            id: 4,
            title: "SIGNED OFF",
            sortable: true,
            value: "signed_off",
            cell: (data: any) => (
                <>
                {!data.signed_off && <Checkbox onClick={() => signOff(data)}  checked={data.signed_off} disableRipple disabled={data.signed_off ? true : false} />}
                {data.signed_off &&  <FontAwesomeIcon icon={faCheck} fixedWidth />}
                </>
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 5,
            title: "SIGNED OFF BY",
            sortable: true,
            value: "signed_off_by",
            cell: (data: any) => (
                <TableCell
                    data={data.signed_off_by ? data.signed_off_by : "N/A"}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 6,
            title: "SIGNED OFF DATE",
            sortable: true,
            value: "signed_off_datetime",
            cell: (data: any) => <TableCell data={data.signed_off_datetime} />,
            show: true,
            fullDataRow: true,
        },
         {
            id: 7,
            title: "",
            sortable: false,
            value: "id",
            cell: (data: any) => (
                <ButtonSmall
                    text="Details"
                    className=""
                    icon={faArrowRight}
                    click={() => { goTo(data);}}
                />
            ),
            show: true,
            fullDataRow: true,
        },
    ];

    const filterOptions = [
        {
            id: 2,
            name: "Company",
            value: "company_id",
            type: "DROPDOWN",
            searchable: true,
            options: companyList?.map((company: any) => ({
                label: company.company,
                value: company.company_id,
            })),
        },
        {
            id: 3,
            name: "Signed Off",
            value: "signed_off",
            type: "DROPDOWN",
            searchable: false,
            options: [
                { label: "All", value: null },
                { label: "Signed off", value: "true" },
                { label: "Not signed off", value: "false" },
            ],
        },
        {
            id: 4,
            name: "Analyst",
            value: "submitted_by",
            type: "DROPDOWN",
            searchable: true,
            options: analystList?.map((analyst: any) => ({
                label: analyst.displayName,
                value: analyst.id,
            })),
        },
    ];

    return (
        <SFDRDataCaptureSubmissionsContainer>
            <PageTitle title="SFDR Data Capture" />
            <div className="filter-row">
                <div className={"left"}>
                    <div className={"search-options"}>
                        <SearchOptions
                            setFilterQuery={(value: any) =>
                                setFilters(value)
                            }
                            options={filterOptions}
                            localFilter
                        />
                    </div>
                    <ButtonSmall
                        className={"search"}
                        text={"Filter results"}
                        icon={faSearch}
                        click={() => {getSubmissions()}}
                    />
                </div>
                <ButtonSmall
                    className={"hideSM"}
                    text={"EXPORT DATA"}
                    icon={faDownload}
                    click={exportData}
                />
            </div>
            <Table
                large
                total={total}
                data={sfdrData as any[]}
                columns={columns}
                defaultSortKey="submitted_datetime"
                defaultSortOrder="DESC"
                loading={loading}
                pagination={true}
                filterQuery={filterQuery}
                emptyText="NO SUBMISSIONS"
                defaultLimit={DEFAULT_PAGE_SIZE}
                changePageQuery={(query: string) => getSubmissions(query)}
            />
        </SFDRDataCaptureSubmissionsContainer>
    );
}
