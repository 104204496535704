// React
import { useState, useEffect } from "react";

// Components
import SearchOptions from "components/Common/SearchOptions/SearchOptions";
import Table from "components/Common/Table/Table";
import Status from "components/Screens/Data/AnalyticsQueue/Status/Status";
import TableCell from "components/Common/TableCell/TableCell";

// Utils
import * as Database from "utils/database.utils";
import { toDateAndTimeSeconds } from "utils/general.utils";

// Styles
import AnalyticsQueueContainer from "./AnalyticsQueue.styles";

export default function AnalyticsQueue() {
    const [filterQuery, setFilterQuery] = useState("");

    const [analyticsQueueOptions, setAnalyticsQueueOptions] = useState([]);

    const getAnalyticsQueueOptions = async () => {
        let res = await Database.get("api/analytics-requests/status-list");

        if (res.ok) {
            let data = await res.json();
            setAnalyticsQueueOptions(data);
        }
    };

    useEffect(() => {
        getAnalyticsQueueOptions();
    }, []);

    const columns = [
        {
            id: 1,
            title: "DESCRIPTION",
            sortable: false,
            value: "description",
            cell: (data: string) => <TableCell data={data.toLowerCase()} />,
            show: true,
        },
        {
            id: 2,
            title: "START TIME",
            sortable: true,
            value: "start_time",
            cell: (data: Date) => (
                <TableCell data={toDateAndTimeSeconds(data)} />
            ),
            show: true,
        },
        {
            id: 3,
            title: "END TIME",
            sortable: true,
            value: "end_time",
            cell: (data: Date) =>
                data && <TableCell data={toDateAndTimeSeconds(data)} />,
            show: true,
        },
        {
            id: 4,
            title: "STATUS",
            sortable: true,
            value: "status",
            cell: (data: AnalyticsQueueStatus) => <Status status={data} />,
            show: true,
        },
        {
            id: 5,
            title: "OUTPUT",
            sortable: false,
            value: "output_message",
            cell: (data: string) => (
                <TableCell styleTypes="output" data={data} />
            ),
            show: true,
        },
    ];

    const filterOptions = [
        {
            id: 1,
            name: "Status",
            value: "status",
            type: "DROPDOWN",
            searchable: false,
            options: analyticsQueueOptions,
        },
    ];

    return (
        <AnalyticsQueueContainer>
            <SearchOptions
                setFilterQuery={setFilterQuery}
                options={filterOptions}
            />

            <Table
                url={"api/analytics-requests"}
                columns={columns}
                filterQuery={filterQuery}
                defaultSortKey="start_time"
                defaultSortOrder="DESC"
                pagination
                large
                emptyText="NO REQUESTS IN QUEUE"
            />
        </AnalyticsQueueContainer>
    );
}
