// Components
import PageTitle from "components/Common/PageTitle/PageTitle";
import StockSplits from "components/Screens/Data/Companies/StockSplits/StockSplits";

// Styles
import StockSplitsContainer from "./StockSplits.styles";

const StockSplitsPage: React.FC = () => {
    return (
        <StockSplitsContainer>
            <PageTitle title="STOCK SPLITS" />
            <StockSplits />
        </StockSplitsContainer>
    );
};

export default StockSplitsPage;
