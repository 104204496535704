import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import TableCell from "components/Common/TableCell/TableCell";

export const dataDictionaryColumns = [
    {
        id: 1,
        title: "SOURCE",
        sortable: true,
        value: "source",
        cell: (data) => <TableCell data={data} />,
        show: true,
    },
    {
        id: 2,
        title: "WS",
        sortable: true,
        value: "ws",
        cell: (data) => <TableCell data={data} />,
        show: true,
    },
    {
        id: 3,
        title: "TABLE",
        sortable: true,
        value: "excel_table",
        cell: (data) => <TableCell data={data} />,
        show: true,
    },
    {
        id: 4,
        title: "INCLUDE",
        sortable: true,
        value: "include_metric",
        cell: (data) =>
            data === 1 && (
                <FontAwesomeIcon
                    className="checkIcon"
                    icon={faCheckCircle}
                    fixedWidth
                />
            ),
        show: true,
    },
    {
        id: 5,
        title: "NAME DB",
        sortable: true,
        value: "metric_name_db",
        cell: (data) => <TableCell data={data} />,
        show: true,
    },
    {
        id: 6,
        title: "NAME RAW",
        sortable: true,
        value: "metric_name_raw",
        cell: (data) => <TableCell data={data} />,
        show: true,
    },
    {
        id: 7,
        title: "DATA TYPE",
        sortable: true,
        value: "metric_data_type",
        cell: (data) => <TableCell data={data} />,
        show: true,
    },
    {
        id: 8,
        title: "FORMULA",
        sortable: true,
        value: "metric_formula",
        cell: (data) => <TableCell data={data} />,
        show: false,
    },
    {
        id: 9,
        title: "ID",
        sortable: true,
        value: "metric_id",
        cell: (data) => <TableCell data={data} />,
        show: false,
    },
    {
        id: 10,
        title: "METRIC",
        sortable: true,
        value: "metric",
        cell: (data) => <TableCell data={data} />,
        show: false,
    },
    {
        id: 11,
        title: "DICT ENTRY ID",
        sortable: true,
        value: "metric_dictionary_entry_id",
        cell: (data) => <TableCell data={data} />,
        show: false,
    },
    {
        id: 12,
        title: "CALC OR BASE",
        sortable: true,
        value: "metric_calc_or_base",
        cell: (data) => <TableCell data={data} />,
        show: false,
    },
    {
        id: 13,
        title: "ROOT",
        sortable: true,
        value: "metric_root",
        cell: (data) => <TableCell data={data} />,
        show: false,
    },
    {
        id: 14,
        title: "ROOT NAME DB",
        sortable: true,
        value: "metric_root_name_db",
        cell: (data) => <TableCell data={data} />,
        show: false,
    },
    {
        id: 15,
        title: "UNIT",
        sortable: true,
        value: "metric_unit",
        cell: (data) => <TableCell data={data} />,
        show: false,
    },
    {
        id: 16,
        title: "YEAR NOTATION",
        sortable: true,
        value: "metric_year_notation",
        cell: (data) => <TableCell data={data} />,
        show: false,
    },
    {
        id: 17,
        title: "CURRENT METRIC",
        sortable: true,
        value: "current_metric_dict_flag",
        cell: (data) => <TableCell data={data} />,
        show: false,
    },
    {
        id: 18,
        title: "EXAMPLE",
        sortable: true,
        value: "example",
        cell: (data) => <TableCell data={data} />,
        show: false,
    },
    {
        id: 19,
        title: "DESCRIPTION",
        sortable: true,
        value: "description",
        cell: (data) => <TableCell data={data} />,
        show: false,
    },
    {
        id: 20,
        title: "NOTES",
        sortable: true,
        value: "notes",
        cell: (data) => <TableCell data={data} />,
        show: false,
    },
];
