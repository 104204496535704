import styled from 'styled-components';
import colors from "assets/styles/colors";

export default styled.div`
    .sectionTitle {
        font-size: 25px;
        letter-spacing: 0.1em;

        color: ${(props) => props.theme.pageTitleText};
        margin-bottom: 10px;
        margin-top: 20px;
    }

    .subtitle {
        letter-spacing: 0.1em;
        float: left;
        color: ${(props) => props.theme.pageTitleText};
    }


    .textFieldDiv {
        width: 300px;
        margin-top: 10px;
    }

    .inputContainerDiv {
        margin-top: 20px;
        margin-bottom: 40px;
    }

    .inputLabel {
        margin-bottom: 10px;
    }

    label {
        letter-spacing: 0.1em;
        color: ${colors.textNavy};
        margin-top: 10px;
        margin-bottom: 20px;
    }

    .createBtn {
        margin-top: 20px;
    }


    .noData {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 250px;

        svg {
            font-size: 50px;
            color: ${(props) => props.theme.tableHeadCellBackground};
            margin-bottom: 20px;
        }

        .spinner {
            animation: spin 2s linear infinite;
        }

        p {
            color: ${(props) => props.theme.tableHeadCellBackground};
            letter-spacing: 0.1em;
        }
    }

    .createBtn {
        margin-top: 10px;
        float: left;
        margin-left: 0px;
    }

    .blankDiv {
        align-items: center;
        max-width: 250px;
        min-width: 250px;
        height: 40px;
        display: flex;


        cursor: pointer;

        background-color: ${(props) => props.theme.filterButtonBackground};
        color: ${(props) => props.theme.filterButtonForeground};

        border-radius: 5px;
        overflow: hidden;
        
    }

    .updateSection {
        margin-top: 20px;
        width: 400px;
    }

    .topActionDiv {
        width: 100%;
        height: 5px;
    }

    .saveButton {
        float: right;
    }
`;