/*
* handle the 2 steps process
* 1. get signed url when the user try to download
* 2. download the file with the signed url
* */

import React, { useState } from "react";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

// Components
import ButtonSmall from "components/Common/ButtonSmall/ButtonSmall";

// Styles
import { DownloadSignedUrlContainer } from "./DownloadSignedUrl.styles";
import {useSettings} from "../../../context/Settings.context";
import * as Database from "../../../utils/database.utils";


type Props = {
    identifier: string,
    text: string,
};

export default function DownloadSignedUrl({
    text, identifier
}: Props) {
    const { setShowLoadingToast, setToast } = useSettings();

    const getFile = async () => {
         setShowLoadingToast({
            show: true,
            message: "DOWNLOADING",
        });

        const signedUrl: string|null = await fetchSignedUrl();

        await downloadFile(signedUrl);

        setShowLoadingToast({
            show: false,
            message: "",
        });
    }

    const fetchSignedUrl = async () => {
        // get signed url from the server
        let response = await Database.post("api/nav/downloads", {
            template_name: identifier,
        });

        let signedUrl = null;

        if (response.ok) {
            response = await response.json();
            signedUrl = response["url"]
        } else {
            setToast({
                show: true,
                message: "There was an error downloading the report. ",
                type: "error",
            });
        }

        return signedUrl
    }

    const downloadFile = async (signedUrl: string|null) => {
        if (!signedUrl) {
            return
        }

        const url = signedUrl
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", signedUrl);
        document.body.appendChild(link);
        link.click();
    }

    return (
        <DownloadSignedUrlContainer className="download-signed-url">
            <ButtonSmall
                className="download-signed-url"
                text={text}
                click={() => getFile()}
                icon={faDownload}
            />

        </DownloadSignedUrlContainer>
    );
}
