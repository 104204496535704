import colors from "assets/styles/colors";

export default {
    modalBackground: "rgba(255, 255, 255, 0.8)",
    modalBackgroundFilter: "blur(5px)",
    modalBoxShadow: "0px 0px 20px 9px rgba(0, 0, 0, 0.1)",

    modalInnerBackground: "white",

    modalTitle: colors.textNavy,
    modalText: colors.grey20,

    alertTitle: colors.textNavy,
    alertText: colors.grey20,
};
