import { useState } from "react";
import { useHistory } from "react-router-dom";

// Material UI
import { Tooltip } from "@mui/material";

// FontAwesome
import {
    faDownload,
    faTrash,
    faHammer,
    faFileExcel,
    faForward,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Components
import Can from "components/Common/Can/Can";
import DeleteExtractionModal from "components/Screens/Data/CompanyModel/DeleteExtractionModal/DeleteExtractionModal";
import IgnoreExtractionModal from "components/Screens/Data/CompanyModel/IgnoreExtractionModal/IgnoreExtractionModal";

// Utils
import { toDateAndTimeSeconds } from "utils/general.utils";
import { exportFromS3, exportCSV } from "utils/export.utils";

// Context
import { useSettings } from "context/Settings.context";

// Styles
import ActionsTrayContainer from "./ActionsTray.styles";

type Props = {
    id: string;
    companyName: string;
    archiveFolderPath: string;
    status: Status;
    companyID: number;
    processing_datetime: Date;
    extraction_datetime: Date;
    reload: Function;
};

const ActionsTray: React.FC<Props> = ({
    id,
    companyName,
    archiveFolderPath,
    status,
    companyID,
    processing_datetime,
    extraction_datetime,
    reload,
}) => {
    const history = useHistory();
    const { setShowLoadingToast } = useSettings();

    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [showIgnoreModal, setShowIgnoreModal] = useState<boolean>(false);

    const handleExportS3 = () => {
        setShowLoadingToast({ show: true, message: "EXPORTING" });

        exportFromS3(id, archiveFolderPath).then((res) => {
            setShowLoadingToast({
                show: false,
                message: "Exporting...",
            });
        });
    };

    const handleExportCSV = () => {
        setShowLoadingToast({ show: true, message: "EXPORTING" });

        exportCSV(id, archiveFolderPath).then((res) => {
            setShowLoadingToast({
                show: false,
                message: "Exporting...",
            });
        });
    };

    return (
        <ActionsTrayContainer>
            {["HLD", "FAI"].includes(status) && (
                <Can
                    yes={
                        <Tooltip title="FIX" placement="top">
                            <div
                                className="action"
                                onClick={() =>
                                    history.push(
                                        `upload-data?company=${companyID}&extractionDate=${toDateAndTimeSeconds(
                                            extraction_datetime
                                        )}&extractionID=${id}&company_name=${companyName}`
                                    )
                                }
                            >
                                <FontAwesomeIcon icon={faHammer} fixedWidth />
                            </div>
                        </Tooltip>
                    }
                    permissions={["p_fix_extraction"]}
                />
            )}

            {["HLD"].includes(status) && (
                <Can
                    yes={
                        <Tooltip title="IGNORE" placement="top">
                            <div
                                className="action"
                                onClick={() => setShowIgnoreModal(true)}
                            >
                                <FontAwesomeIcon icon={faForward} fixedWidth />
                            </div>
                        </Tooltip>
                    }
                    permissions={["p_ignore_extraction_errors"]}
                />
            )}

            {["HLD", "FAI"].includes(status) && (
                <Can
                    yes={
                        <Tooltip title="DELETE" placement="top">
                            <div
                                className="action"
                                onClick={() => setShowDeleteModal(true)}
                            >
                                <FontAwesomeIcon icon={faTrash} fixedWidth />
                            </div>
                        </Tooltip>
                    }
                    permissions={["p_remove_extraction"]}
                />
            )}

            {archiveFolderPath && (
                <Can
                    yes={
                        <>
                            <Tooltip title="EXPORT RAW" placement="top">
                                <div className="action">
                                    <FontAwesomeIcon
                                        icon={faFileExcel}
                                        fixedWidth
                                        onClick={handleExportS3}
                                    />
                                </div>
                            </Tooltip>

                            {companyID !== 0 && (
                                <Tooltip title="EXPORT CSV" placement="top">
                                    <div className="action">
                                        <FontAwesomeIcon
                                            icon={faDownload}
                                            fixedWidth
                                            onClick={handleExportCSV}
                                        />
                                    </div>
                                </Tooltip>
                            )}
                        </>
                    }
                    permissions={["p_upload_model"]}
                />
            )}

            {showDeleteModal && (
                <DeleteExtractionModal
                    id={id}
                    setShowModal={setShowDeleteModal}
                    reload={reload}
                />
            )}
            {showIgnoreModal && (
                <IgnoreExtractionModal
                    id={id}
                    setShowModal={setShowIgnoreModal}
                    extraction_datetime={extraction_datetime}
                    processing_datetime={processing_datetime}
                    reload={reload}
                />
            )}
        </ActionsTrayContainer>
    );
};

export default ActionsTray;
