import styled from "styled-components";
import colors from "assets/styles/colors";

export default styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    width: 100%;
    height: 100%;

    z-index: 150;

    background-color: ${colors.textNavy};
    color: white;

    /* background-color: white;
    color: ${colors.textNavy}; */

    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    padding: 10px 20px;
    border-radius: 5px;

    p {
        font-size: 20px;
        letter-spacing: 2px;
        margin: 10px 0 0 0;
        display: flex;
        color: #dddddd;
    }

    .spinner {
        display: flex;
        width: 50px;
        justify-content: center;
        align-items: center;

        svg {
            animation: spin 1s linear infinite;
            font-size: 40px;
        }
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;
