import styled from "styled-components";
import colors from "assets/styles/colors";

export default styled.div`
    width: 100%;
    flex-direction: column;
    display: flex;

    .breadcrumbsRow {
        margin: 10px -10px;
        width: 100%;
        display: flex;

        .breadcrumb {
            background-color: ${(props) => props.theme.filterButtonBackground};
            justify-content: center;
            align-items: center;
            display: flex;
            padding: 0 15px;
            height: 40px;
            margin: 10px;
            border-radius: 5px;

            cursor: pointer;

            p {
                margin: 0;
            }

            :hover {
                background-color: ${(props) =>
                    props.theme.filterButtonBackgroundHover};
            }

            &.active {
                background-color: ${(props) =>
                    props.theme.filterButtonBackgroundActive};
                color: ${(props) => props.theme.filterButtonForegroundActive};
            }
        }
    }

    .optionWrapper {
        width: 100%;
        margin: 0;
        flex-wrap: wrap;
        display: flex;

        .option {
            height: 130px;
            width: 130px;
            margin: 0 10px;
            flex-direction: column;
            justify-content: space-evenly;
            display: flex;
            align-items: center;
            border-radius: 5px;
            cursor: pointer;
            color: ${(props) => props.theme.primaryButtonBackground};

            svg {
                font-size: 50px;
            }

            p {
                color: ${(props) => props.theme.pageSubTitleText};

                margin: 0;
                font-size: 18px;
                letter-spacing: 1px;
                word-break: break-all;
            }

            :hover {
                background-color: #dddddd;
            }
        }

        &.table {
            flex-direction: column;
            margin: 0 -10px;

            .option {
                height: unset;
                padding: 10px;
                width: 100%;
                margin: 5px 10px;
                flex-direction: row;
                display: flex;

                justify-content: flex-start;
                align-items: center;
                p {
                    margin-left: 15px;
                }
            }
        }

        &.database {
            margin: 0 -10px;
        }
    }
`;
