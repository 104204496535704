// Styles
import CashflowForecastContainer from "./CashflowForecast.styles";
import ReportsTab from "../../../../components/Screens/DailyRecs/DailyFlowsCheck/ReportsTab/ReportsTab";
import Tabs from "../../../../components/Common/Tabs/Tabs";

interface CashflowForecastProps {}

const powerBI = "https://app.powerbi.com/reportEmbed?reportId=2745ada0-1a19-4aa5-ab0a-5c0e5b3f32d6&autoAuth=true&ctid=bf4450a2-bfcb-472d-8c40-1425eafb1a3b&filterPaneEnabled=false";
const checkName = "cashflow_forecast";

const CashflowForecast = ({}: CashflowForecastProps) => {
    return (
        <CashflowForecastContainer>
            <p className={"sectionTitle"}>CASHFLOW FORECAST</p>
            <Tabs
                defaultTab="reports"
                tabs={[
                    {
                        value: "reports",
                        title: "REPORTS",
                        permissions: ["p_can_view_navs", "p_can_action_navs"],
                        content: <ReportsTab powerBI={powerBI} />,
                    },
                ]}
            />
        </CashflowForecastContainer>
    );
};

export default CashflowForecast;
