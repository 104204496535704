import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import ReactDOM from "react-dom";

// Auth
import { msalConfig } from "utils/msal.config";
import { MsalProvider } from "@azure/msal-react";

// Components
import App from "./App";

// Context
import { SettingsProvider } from "context/Settings.context";
import { AuthProvider } from "./context/Auth.context";
import { NotificationsProvider } from "./context/Notifications.context";
import { WebSocketProvider } from "./context/Websocket.context";
import { BQMQProvider } from "context/BQMQ.context";
import { SFDRDataCaptureProvider } from "context/SFDRDataCapture.context";

// Styles
import "assets/styles/index.css";

ReactDOM.render(
    <SettingsProvider>
        <MsalProvider instance={msalConfig}>
            <Router>
                <AuthProvider>
                    <WebSocketProvider>
                        <NotificationsProvider>
                            <BQMQProvider>
                                <SFDRDataCaptureProvider>
                                    <App />
                                </SFDRDataCaptureProvider>
                            </BQMQProvider>
                        </NotificationsProvider>
                    </WebSocketProvider>
                </AuthProvider>
            </Router>
        </MsalProvider>
    </SettingsProvider>,
    document.getElementById("root")
);
