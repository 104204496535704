import React, { useEffect, useState } from "react";

// Styles
import InputTabContainer from "./InputTab.styles";

// Components
import TextField from "components/Common/TextField/TextField";
import Select from "components/Common/Select/Select";
import Button from "components/Common/Button/Button";

// Utils
import * as Database from "utils/database.utils";
import { useSettings } from "context/Settings.context";

interface InputTabProps {}

type FutureIndexResponseType = {
    index: string;
    metric: string;
    value: number;
};

const InputTab = ({}: InputTabProps) => {
    const { setShowLoadingToast, setToast } = useSettings();

    const [index, setIndex] = useState<string>("esm3");
    const [metric, setMetric] = useState<string>("PX_LAST");
    const [value, setValue] = useState<number>(0);

    const getIndexValue = async () => {
        setShowLoadingToast({
            show: true,
            message: "LOADING",
        });
        const response = await Database.get(`api/nav/future_index/${index}/`);
        if (response.ok) {
            setToast({
                type: "success",
                show: true,
                message: `Future Index loaded for ${index.toLocaleUpperCase()}`,
            });
            const data = (await response.json()) as FutureIndexResponseType;
            setValue(data.value);
        } else {
            setValue(0);
            setToast({
                type: "error",
                show: true,
                message: `Data not found for ${index.toLocaleUpperCase()}`,
            });
        }
        setShowLoadingToast({
            show: false,
            message: "",
        });
    };

    const saveInput = async () => {
        setShowLoadingToast({
            show: true,
            message: "LOADING",
        });
        const response = await Database.post(
            "api/nav/future_index/future_index/",
            {
                index,
                metric,
                value,
            }
        );
        if (response.ok) {
            const data = await response.json();
            setToast({
                show: true,
                type: "success",
                message: "Saving future index was a success",
            });
            setShowLoadingToast({
                show: false,
                message: "",
            });
        }
    };

    useEffect(() => {
        setValue(0);
        getIndexValue();
    }, [index]);

    return (
        <InputTabContainer>
            <div className="mainContainer">
                <p className="title mt20 mb20">Input Futures Index values</p>
                <div className="inputContainer">
                    <label>Index</label>
                    <Select
                        filterName="Index"
                        options={[
                            { label: "ESM3", value: "esm3" },
                            { label: "VGM3", value: "vgm3" },
                            { label: "KMM3", value: "kmm3" },
                            { label: "HIM3", value: "him3" },
                        ]}
                        value={index}
                        setValue={setIndex}
                    />
                </div>
                <div className="inputContainer">
                    <label>Metric</label>
                    <TextField
                        value={metric}
                        disabled
                        placeholder={"Metric"}
                        change={() => {}}
                    />
                </div>
                <div className="inputContainer">
                    <label>Value</label>
                    <TextField
                        value={value}
                        change={setValue}
                        placeholder={"Value"}
                    />
                </div>
                <div>
                    <Button text="SAVE" click={() => saveInput()} />
                </div>
            </div>
        </InputTabContainer>
    );
};
export default InputTab;
