import { useEffect, useState } from "react";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

// Components
import Table from "components/Common/Table/Table";
import SearchOptions from "components/Common/SearchOptions/SearchOptions";
import FullList from "components/Screens/Data/Companies/NewCompanies/FullList/FullList";
import NewCompanyModal from "components/Screens/Data/Companies/NewCompanies/NewCompanyModal/NewCompanyModal";
import UpdateCompanyModal from "components/Screens/Data/Companies/NewCompanies/UpdateCompanyModal/UpdateCompanyModal";
import IgnoreCompanyModal from "components/Screens/Data/Companies/NewCompanies/IgnoreCompanyModal/IgnoreCompanyModal";
import Button from "components/Common/Button/Button";
import TableCell from "components/Common/TableCell/TableCell";

// Utils
import * as Database from "utils/database.utils";

// Data
import { teamOptions } from "assets/data/lists";

// Context
import { useNotifications } from "context/Notifications.context";

// Styles
import NewCompaniesContainer from "./NewCompanies.styles";

type Props = {
    homePage?: boolean;
};

const NewCompanies: React.FC<Props> = ({ homePage }) => {
    const { setUnknownCompanies } = useNotifications();

    const [loading, setLoading] = useState<boolean>(true);
    const [filterQuery, setFilterQuery] = useState<string>("");

    const [showAll, setShowAll] = useState<boolean>(false);

    const [selectedCompany, setSelectedCompany] = useState<any[]>([]);
    const [showMainCompanyTable, setShowMainCompanyTable] =
        useState<boolean>(false);

    const [oldCompany, setOldCompany] = useState<any>(false);

    const [showNewCompanyModal, setShowNewCompanyModal] =
        useState<boolean>(false);
    const [showUpdateCompanyModal, setShowUpdateCompanyModal] =
        useState<boolean>(false);
    const [showIgnoreCompanyModal, setShowIgnoreCompanyModal] =
        useState<boolean>(false);

    const getNewCompanies = () => {
        setLoading(true);
        Database.get(
            "api/companies/new-companies?pageSize=1&page=1&sortKey=first_detected_datetime&sortOrder=ASC"
        ).then((res) => {
            if (res.ok) {
                res.json().then((data) => {
                    setSelectedCompany(data.data);
                    setUnknownCompanies(data.total);
                    setLoading(false);
                });
            }
        });
    };

    useEffect(() => {
        getNewCompanies();
        // setDoc("ada/newCompanies.md")
        //
        // return () => {
        //     setDoc("")
        // };
    }, []);

    const filterOptionsSelected = [
        { id: 1, name: "Search COMPANY", value: "company", type: "SEARCH" },
        {
            id: 2,
            name: "Team",
            value: "gim_subteam",
            type: "DROPDOWN",
            options: teamOptions,
        },
    ];

    const columnsSelected = [
        {
            id: 1,
            title: "COMPANY",
            sortable: false,
            value: "scraped_company",
            cell: (data: string) => (
                <TableCell styleTypes="capitalize" data={data.toLowerCase()} />
            ),
            show: true,
        },
        {
            id: 2,
            title: "TEAM",
            sortable: false,
            value: "scraped_team",
            cell: (data: string) => (
                <TableCell styleTypes="capitalize" data={data} />
            ),
            show: true,
        },
        {
            id: 3,
            title: "TICKER",
            sortable: false,
            value: "scraped_ticker",
            cell: (data: string) => <TableCell data={data} />,
            show: true,
        },
        {
            id: 4,
            title: "FILE NAME",
            sortable: false,
            value: "scraped_excel_model",
            cell: (data: string) => <TableCell data={data} />,
            show: true,
        },
        {
            id: 5,
            title: "DETECTED DATE",
            sortable: false,
            value: "first_detected_date",
            cell: (data: string) => <TableCell data={data} />,
            show: true,
        },
    ];

    const columnsCompanies = [
        {
            id: 1,
            title: "COMPANY",
            sortable: false,
            value: "company",
            cell: (data: string) => (
                <TableCell styleTypes="capitalize" data={data.toLowerCase()} />
            ),
            show: true,
        },
        {
            id: 2,
            title: "TEAM",
            sortable: false,
            value: "gim_subteam",
            cell: (data: string) => (
                <TableCell styleTypes="capitalize" data={data} />
            ),
            show: true,
        },
        {
            id: 3,
            title: "TICKER",
            sortable: false,
            value: "bbg_ticker",
            cell: (data: string) => <TableCell data={data} />,
            show: true,
        },
        {
            id: 4,
            title: "FILE NAME",
            sortable: false,
            value: "company_model_excel_name",
            cell: (data: string) => <TableCell data={data} />,
            show: true,
        },
        {
            id: 5,
            title: "",
            sortable: false,
            value: "id",
            cell: (data: any) => (
                <FontAwesomeIcon
                    icon={faArrowRight}
                    fixedWidth
                    className="selectIcon"
                    onClick={() => {
                        setOldCompany(data);
                        setShowUpdateCompanyModal(true);
                    }}
                />
            ),
            show: true,
            fullDataRow: true,
        },
    ];

    const reload = () => {
        getNewCompanies();
        setSelectedCompany([]);
        setOldCompany(false);
        setShowMainCompanyTable(false);
    };

    return (
        <NewCompaniesContainer>
            {!showAll ? (
                <>
                    {!homePage && (
                        <>
                            <p className="title mt20 mb10">
                                ALL AUTO DETECTED COMPANIES
                            </p>

                            <p className="mb20">
                                Handle auto detected companies individually,
                                starting with the first detected.
                                <span onClick={() => setShowAll(true)}>
                                    See full list
                                </span>
                            </p>
                        </>
                    )}
                    <Table
                        loading={loading}
                        data={selectedCompany}
                        columns={columnsSelected}
                        filterQuery={""}
                        defaultSortKey="first_detected_datetime"
                        skeletonLength={1}
                    />

                    {selectedCompany.length > 0 && (
                        <div className="buttonsRow">
                            {showMainCompanyTable ? (
                                <Button
                                    text="BACK"
                                    color="primary"
                                    click={() => setShowMainCompanyTable(false)}
                                />
                            ) : (
                                <>
                                    <div className="buttonsRowNewUpdate">
                                        <Button
                                            text="NEW"
                                            color="primary"
                                            click={() =>
                                                setShowNewCompanyModal(true)
                                            }
                                        />

                                        <Button
                                            text="UPDATE"
                                            color="third"
                                            click={() =>
                                                setShowMainCompanyTable(true)
                                            }
                                        />
                                    </div>
                                    <Button
                                        text="IGNORE"
                                        color="accent"
                                        click={() =>
                                            setShowIgnoreCompanyModal(true)
                                        }
                                    />
                                </>
                            )}
                        </div>
                    )}

                    {showMainCompanyTable && (
                        <>
                            <p className="title mt40">UPDATE COMPANY</p>
                            <p className="mt10">
                                Select the company to update from the table
                                below.
                            </p>

                            <div className="searchBar">
                                <SearchOptions
                                    setFilterQuery={setFilterQuery}
                                    options={filterOptionsSelected}
                                />
                            </div>

                            <Table
                                url={"api/companies"}
                                columns={columnsCompanies}
                                filterQuery={
                                    filterQuery +
                                    "&filter_current_company_ref_flag=true"
                                }
                                defaultSortKey="company"
                                defaultSortOrder="ASC"
                                pagination
                                emptyText="NO COMPANIES MATCH YOUR SEARCH"
                            />
                        </>
                    )}
                </>
            ) : (
                <FullList setShowAll={setShowAll} />
            )}

            {showNewCompanyModal && (
                <NewCompanyModal
                    selectedCompany={selectedCompany[0]}
                    setShowModal={setShowNewCompanyModal}
                    reload={reload}
                />
            )}

            {showUpdateCompanyModal && (
                <UpdateCompanyModal
                    selectedCompany={selectedCompany[0]}
                    oldCompany={oldCompany}
                    setShowModal={setShowUpdateCompanyModal}
                    reload={reload}
                />
            )}

            {showIgnoreCompanyModal && (
                <IgnoreCompanyModal
                    selectedCompany={selectedCompany[0]}
                    setShowModal={setShowIgnoreCompanyModal}
                    reload={reload}
                />
            )}
        </NewCompaniesContainer>
    );
};

export default NewCompanies;
