import React, { useEffect, useState } from "react";

// Styles
import IncomingFileListContainer from "./IncomingFileList.styles";

// FontAwesome
import {faDownload} from "@fortawesome/free-solid-svg-icons";

// Components
import TableCell from "components/Common/TableCell/TableCell";
import ButtonSmall from "components/Common/ButtonSmall/ButtonSmall";
import Button from "components/Common/Button/Button";
import Table from "components/Common/Table/Table";
import AddIncomingFileModal from "components/Screens/DailyRecs/IncomingFiles/AddInomingFileModal/AddIncomingFileModal";
import { createLocalDownload } from "utils/export.utils";

// Utils
import * as Database from "utils/database.utils";
import {useSettings} from "../../../../context/Settings.context";


const IncomingFileList = () => {
    const { setToast, setShowLoadingToast } = useSettings();
    const [ loading, setLoading ] = useState<boolean>(true);

    const downloadFile = async (input_file_id: string) => {
        setToast({
            show: true,
            type: "success",
            message: "Downloading file...",
        });

        let res = await Database.get("api/nav/incoming_files/download/" + input_file_id + "/");

        let filename: any = "Unknown";

        if (res?.ok) {
            filename = res?.headers?.get("Content-Disposition")?.split("filename=")[1];
            setToast({show:false, type:"", message:""})
        } else {
           setToast({
            show: true,
            type: "error",
            message: "Downloading file failed.",
        });
        }

        const file = await res.blob();
        createLocalDownload(filename, file);
    }

    const columns = [
        {
            id: 1,
            title: "RECEIVED",
            sortable: false,
            value: "date_received",
            cell: (data: any) => <TableCell data={data.date_received} />,
            show: true,
            fullDataRow: true,
        },
        {
            id: 6,
            title: "PROVIDED BY",
            sortable: false,
            value: "provided_by",
            cell: (data: any) => <>
                   <span className={`uppercase badge ${data.provided_by === "gim" ? "badge-success" : "badge-primary"}`}>{data.provided_by}</span>
                </>,
            show: true,
            fullDataRow: true,
        },
        {
            id: 2,
            title: "FILE NAME",
            sortable: false,
            value: "source_file",
            cell: (data: any) => <TableCell data={(data.provided_by === "gim") ? data.original_source_file : data.source_file}/>,
            show: true,
            fullDataRow: true,
        },
        {
            id: 7,
            title: "VERSION",
            sortable: false,
            value: "version",
            cell: (data: any) => <>
                   <span className={`badge badge-secondary`}>v{data.version}</span>
                </>,
            show: true,
            fullDataRow: true,
        },
        {
            id: 3,
            title: "PROVIDER",
            sortable: false,
            value: "provider",
            cell: (data: any) => <TableCell data={data.provider} />,
            show: true,
            fullDataRow: true,
        },
        {
            id: 4,
            title: "STATUS",
            sortable: false,
            value: "status",
            cell: (data: any) => <TableCell data={data.status} />,
            show: true,
            fullDataRow: true,
        },
         {
            id: 5,
            title: "DOWNLOAD",
            sortable: false,
            value: "id",
            cell: (data: any) => (
                <ButtonSmall
                    text="Download File"
                    className=""
                    icon={faDownload}
                    click={() => {
                        downloadFile(data.id);
                    }}
                />
            ),
            show: true,
            fullDataRow: true,
        },
    ];

    return (
        <IncomingFileListContainer>
            <div data-cy="table" className="tableDiv">
                <Table
                    pagination
                    columns={columns}
                    defaultLimit={25}
                    filterQuery={""}
                    defaultSortKey="date_received"
                    defaultSortOrder="DESC"
                    url="api/nav/incoming_files/"
                />
            </div>
        </IncomingFileListContainer>
    );
};
export default IncomingFileList;
