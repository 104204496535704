import * as Database from "utils/database.utils";

export const getAllCompanies = async (
    pageQuery = "?page=1&pageSize=1000&sortKey=company&sortOrder=ASC",
    queryString = ""
) => {
    return await Database.get("api/companies" + pageQuery + queryString);
};

export const getOneCompany = async (comp_id) => {
    return await Database.get("api/companies/" + comp_id);
};
