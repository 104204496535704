// MaterialUI
import { Tooltip } from "@mui/material";

// FontAwesome
import { faDownload, faUser, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Components
import ButtonSmall from "components/Common/ButtonSmall/ButtonSmall";
import PageTitle from "components/Common/PageTitle/PageTitle";

// Utils
import { exportCompanyBQMQData } from "utils/export.utils";

// Context
import { useSettings } from "context/Settings.context";

// Data
import { teamLabelDict } from "assets/data/lists";

// Styles
import CompanyHeaderContainer from "./CompanyHeader.styles";

type Props = {
    companyName: string;
    companyID: string;
    team: string;
    analyst: string;
};

export default function CompanyHeader({
    companyName,
    companyID,
    team,
    analyst,
}: Props) {
    const { setShowLoadingToast, setToast } = useSettings();

    const exportToCSV = async () => {
        const exportName = `${companyName}`;
        const exportBody = {
            start_date: null,
            end_date: null,
            only_current_employees: null,
            only_latest_vote: null,
            analyst_id: null,
            company_id: companyID,
            session_id: null,
        };
        await exportCompanyBQMQData(
            exportName,
            exportBody,
            setShowLoadingToast,
            setToast
        );
    };

    const getTeam = () => {
        // @ts-ignore
        return teamLabelDict[team];
    };

    return (
        <CompanyHeaderContainer>
            <PageTitle
                title={companyName.toUpperCase()}
                actions={
                    <ButtonSmall
                        text={"EXPORT DATA"}
                        icon={faDownload}
                        click={exportToCSV}
                    />
                }
            />
            <div className={"details"}>
                {team && (
                    <Tooltip title={<h2>TEAM</h2>}>
                        <div className={"detail"}>
                            <FontAwesomeIcon icon={faUsers} fixedWidth />
                            <p>{getTeam()}</p>
                        </div>
                    </Tooltip>
                )}

                {analyst && (
                    <Tooltip title={<h2>ANALYST</h2>}>
                        <div className={"detail"}>
                            <FontAwesomeIcon icon={faUser} fixedWidth />
                            <p>{analyst}</p>
                        </div>
                    </Tooltip>
                )}
            </div>
        </CompanyHeaderContainer>
    );
}
