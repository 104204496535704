import React, { useEffect, useState } from "react";

// Styles
import EditDealTeamContainer from "./EditDealTeam.styles";

// Types
import {DealTeam, DealTeamMember } from "assets/interfaces/growth_voting";

//Enums
import { EnumGrowthVotingTeams } from "assets/enums/growth_voting";

// Components
import SelectUsersModal from "../../SelectUsersModal/SelectUsersModal";
import ButtonSmall from "../../../../Common/ButtonSmall/ButtonSmall";

// Icons
import {faPencil} from "@fortawesome/free-solid-svg-icons";

// Utils
import { getTeamNameByType } from "utils/growth_voting.utils";


interface Props {
    team: DealTeam
    setTeam: React.Dispatch<React.SetStateAction<DealTeam>>
}


const EditDealTeam  = ({team, setTeam}: Props) => {
    const [showSelectUsers, setShowSelectUsers] = useState<boolean>(false);
    const [selectedUsers, setSelectedUsers] = useState<DealTeamMember[]>(team?.members ? team.members : []);

    const addMembersToTeam = () => {
        if(!team?.members) {
            team["members"] = [];
        }

        team.members = selectedUsers;
        setTeam({...team})
    }

    useEffect(() => {
        addMembersToTeam();
    }, [selectedUsers])

    useEffect(() => {
        if (showSelectUsers) {
            setSelectedUsers(team.members);
        }
    }, [showSelectUsers])

    return (
        <EditDealTeamContainer >
            {showSelectUsers && (
                <SelectUsersModal
                    showModal={showSelectUsers}
                    setShowModal={setShowSelectUsers}
                    selectedUsers={selectedUsers}
                    setSelectedUsers={setSelectedUsers}
                />
            )}
            <div className={"team"}>
                <div className={"btn-add-container"}>
                      <ButtonSmall
                        color={"primary"}
                        text={"Edit Users"}
                        icon={faPencil}
                        click={() => {setShowSelectUsers(!showSelectUsers)}}
                    />
                </div>
                <div className={"team-name"}>
                    {getTeamNameByType(team.type)}
                </div>

                <div className={"team-members"}>
                    {team.members.map((member: DealTeamMember) => (
                        <div className={"member"}>
                            {member.name}
                        </div>
                    ))}
                </div>
            </div>

        </EditDealTeamContainer>
    );
};

export default EditDealTeam
