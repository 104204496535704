import styled from "styled-components";

export default styled.div`
    justify-content: flex-end;
    align-items: center;
    display: flex;

    padding: 15px 15px;
    border-radius: 5px;

    background-color: ${(props) => props.theme.selectedOptionsBackground};
    color: ${(props) => props.theme.selectedOptionsText};

    position: absolute;

    bottom: 30px;

    z-index: 10;

    p {
        margin: 0 0 0 0;
        line-height: 100%;
    }

    svg {
        margin-left: 10px;
        cursor: pointer;
        color: ${(props) => props.theme.selectedOptionsIcon};

        :hover {
            color: ${(props) => props.theme.selectedOptionsIconHover};
        }
    }
`;
