import { useHistory } from "react-router-dom";

// FontAwesome
import {
    faClipboardCheck,
    faUserShield,
} from "@fortawesome/free-solid-svg-icons";

// Components
import SubMenuItem from "../../SubMenuItem/SubMenuItem";

// Styles
import AdminContainer from "./Admin.styles";

export default function Admin() {
    const history = useHistory();

    return (
        <AdminContainer className={"submenu"}>
            <div className={"top"}>
                <h1>ADMIN</h1>
                <hr />
                <SubMenuItem
                    text={"Permissions"}
                    active={history.location.pathname === "/admin/permissions"}
                    icon={faUserShield}
                    click={() => history.push("/admin/permissions")}
                    permissions={["s_update_permissions"]}
                />
            </div>

            <div className={"bottom"}></div>
        </AdminContainer>
    );
}
