import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@mui/material";
import Button from "components/Common/Button/Button";
import Table from "components/Common/Table/Table";
import TableCell from "components/Common/TableCell/TableCell";
import { useSettings } from "context/Settings.context";
import React, { useEffect, useState } from "react";

import * as Database from "utils/database.utils";
import { createLocalDownload } from "utils/export.utils";

// Styles
import ArchiveContainer from "./Archive.styles";
import { dataTableType, fileNameMap, fileNameMapType } from "./types";

interface ArchiveProps {
    fund: string;
    region: string;
}

const Archive = ({ fund, region }: ArchiveProps) => {
    const { setShowLoadingToast, setToast } = useSettings();

    const [reload, setReload] = useState(0);
    const [filterQuery, setFilterQuery] = useState<string>("");
    const allDataSourcesApiUrl = `api/fees/data_sources/${region}/${fund}/all`;

    const handleDownload = async (url: string, filename: string) => {
        setShowLoadingToast({
            show: true,
            message: "DOWNLOADING",
        });

        const res = await Database.get(url);
        const file = await res.blob();
        createLocalDownload(filename, file);

        setShowLoadingToast({
            show: false,
            message: "",
        });
    };

    const downloadMonthlyFiles = async () => {
        setShowLoadingToast({
            show: true,
            message: "DOWNLOADING MONTHLY FILES",
        });
        await Database.get(allDataSourcesApiUrl).then(async (d) => {
            let data = await d.json();

            // get correct table_id name
            let seriesMonthly = data.data.find((o: dataTableType) =>
                o.table_id.includes("dbn_nt_pf_report_series_monthly")
            )?.table_id;
            let benchmarkMonthly = data.data.find((o: dataTableType) =>
                o.table_id.includes("dbn_nt_pf_report_benchmark_monthly")
            )?.table_id;

            let filesArr = [seriesMonthly, benchmarkMonthly];

            if (region === "global") {
                let currencyRatesMonthly = data.data.find((o: dataTableType) =>
                    o.table_id.includes(
                        "dbn_nt_pf_report_currency_rates_monthly"
                    )
                )?.table_id;
                filesArr.push(currencyRatesMonthly);
            }

            for (let i = 0; i < filesArr.length; i++) {
                if (typeof filesArr[i] !== "undefined") {
                    const res = await Database.get(
                        `api/fees/data_sources/${region}/${fund}/latest/download?table_id=${filesArr[i]}`
                    );
                    const file = await res.blob();
                    createLocalDownload(filesArr[i] + ".csv", file);
                }
            }
        });

        setShowLoadingToast({
            show: false,
            message: "DOWNLOADING MONTHLY FILES",
        });
    };

    const columns = [
        {
            id: 1,
            title: "PROVIDER",
            sortable: false,
            value: "provider",
            cell: (data: any) => <TableCell data={data.provider} />,
            show: true,
            fullDataRow: true,
        },
        {
            id: 2,
            title: "FILE",
            sortable: false,
            value: "report",
            cell: (data: any) => (
                <TableCell
                    data={
                        data.report in fileNameMap
                            ? fileNameMap[data.report as keyof fileNameMapType]
                            : data.report
                    }
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 8,
            title: "STATUS",
            sortable: false,
            value: "status",
            cell: (data: any) => <TableCell data={data.status} />,
            show: true,
            fullDataRow: true,
        },
        {
            id: 3,
            title: "PROVIDED ON",
            sortable: false,
            value: "file_received_date",
            cell: (data: any) => (
                <div
                    className={
                        data.file_received_date ? "provided" : "notProvided"
                    }
                >
                    <p>
                        {!data.file_received_date
                            ? "Not yet provided"
                            : new Date(
                                  data.file_received_date
                              ).toLocaleDateString()}
                    </p>
                </div>
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 4,
            title: "SIGN OFF DATE",
            sortable: false,
            value: "signed_off_dt",
            cell: (data: any) => (
                <TableCell
                    data={
                        data.signed_off_dt
                            ? new Date(data.signed_off_dt).toLocaleDateString()
                            : "-"
                    }
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 5,
            title: "SIGN OFF BY",
            sortable: false,
            value: "signed_off_responsible",
            cell: (data: any) => (
                <TableCell
                    data={
                        data.signed_off_responsible
                            ? data.signed_off_responsible
                            : "-"
                    }
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 7,
            title: "",
            sortable: false,
            value: "",
            cell: (data: any) => (
                <Tooltip title={"DOWNLOAD"}>
                    <div className="downloadIcon">
                        <FontAwesomeIcon
                            icon={faDownload}
                            fixedWidth
                            onClick={() =>
                                handleDownload(
                                    `api/fees/data_sources/${region}/${fund}/latest/download?table_id=${data.table_id}`,
                                    `${fund} - Data Source Export.csv`
                                )
                            }
                        />
                    </div>
                </Tooltip>
            ),
            show: true,
            fullDataRow: true,
        },
    ];

    return (
        <ArchiveContainer>
            {fund === "dublin" && (
                <Button
                    click={() => downloadMonthlyFiles()}
                    text={"DOWNLOAD MONTHLY AND QUARTERLY FILES"}
                    icon={faDownload}
                    className="generateBtn"
                />
            )}
            <div className="tableDiv">
                <Table
                    showHeader
                    reload={reload}
                    columns={columns}
                    defaultLimit={10}
                    filterQuery={filterQuery}
                    large
                    pagination
                    url={allDataSourcesApiUrl}
                    defaultSortKey="provider"
                    defaultSortOrder="DESC"
                />
            </div>
        </ArchiveContainer>
    );
};
export default Archive;
