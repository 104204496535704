const size = {
    sm: "414px",
    md: "820px",
    lg: "1024px",
};
const device = {
    sm: `max-width: ${size.sm}`,
    md: `max-width: ${size.md}`,
    lg: `max-width: ${size.lg}`,
};
export default { size, device };
