import styled from "styled-components";
import colors from "assets/styles/colors";

export default styled.div`
    flex-direction: column;
    display: flex;
    flex-grow: 1;

    .companyName {
        display: flex;
        flex-direction: row;
        align-items: center;

        .currentModel {
            color: ${(props) => props.theme.tableIconHighlight};
            font-size: 16px;
            margin-left: 10px;
        }
    }
`;
