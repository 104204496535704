import React, { useEffect, useState } from "react";

// Styles
import EditVoteModalContainer from "./EditVoteModal.styles";

// Types
import {GrowthQuestion, GrowthAnswer, DealVote, DealTeamMember} from "assets/interfaces/growth_voting";


// Components
import Modal from "components/Common/Modal/Modal";
import ButtonSmall from "../../../Common/ButtonSmall/ButtonSmall";
import {faClose} from "@fortawesome/free-solid-svg-icons";
import Button from "../../../Common/Button/Button";
import EditVote from "../EditVote/EditVote";


interface Porps {
    vote: DealVote;
    questions: GrowthQuestion[];
    answers: GrowthAnswer[];
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    member : DealTeamMember | undefined;
}


const EditVoteModal  = ({vote, questions, answers, setShowModal, member}: Porps) => {
    const [loading, setLoading] = useState<boolean>(false);

    return (
        <EditVoteModalContainer>
            <Modal loading={loading} width={"600px"}>
                <div className={"btn-close-container"}>
                    <ButtonSmall
                        color={"flat"}
                        text={"Close"}
                        icon={faClose}
                        click={() => {setShowModal(false)}}
                    />
                </div>
                <EditVote vote={vote} questions={questions} answers={answers} setShowModal={setShowModal} member={member}/>
            </Modal>
        </EditVoteModalContainer>
    );
};

export default EditVoteModal
