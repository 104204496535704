import React, { useEffect, useState } from "react";

// Styles
import EditDealDetailsContainer from "./EditDealDetails.styles";
import TextField from "components/Common/TextField/TextField";
import TextArea from "components/Common/TextArea/TextArea";

// Types
import { DealDetails, GrowthCompany } from "assets/interfaces/growth_voting";
import EditDealCompanyModal from "../EditDealCompanyModal/EditDealCompanyModal";
import ButtonSmall from "../../../Common/ButtonSmall/ButtonSmall";
import {faPencil} from "@fortawesome/free-solid-svg-icons";


interface Props {
    setDetails: React.Dispatch<React.SetStateAction<DealDetails>>
    details: DealDetails
}


const EditDealDetails  = ({setDetails, details}: Props) => {
    const [funding_round, setFundingRound] = useState<string|null>(null);
    const [description, setDescription] = useState<string|null>(null);
    const [showCompanyModal, setShowCompanyModal] = useState<boolean>(false);
    const [company, setCompany] = useState<GrowthCompany>({name: "", url: ""});


    useEffect(() => {
        if (funding_round !== null) {
            setDetails({...details, funding_round})
        }
    }, [funding_round])

    useEffect(() => {
        if (description !== null) {
            setDetails({...details, description})
        }
    }, [description])

    useEffect(() => {
        if (company.name !== "" && company.url !== "") {
            // only set if both fields are filled
            setDetails({
                ...details,
                company: {
                    ...company
                }
            })
        }

    }, [company])

    return (
        <EditDealDetailsContainer>
             {showCompanyModal && (
                <EditDealCompanyModal company={company} setCompany={setCompany} setShowModal={setShowCompanyModal}/>
            )}

            <div className={"form"}>
                <div className="inputContainer">
                    <label className={"required"}>Milestone</label>
                    <TextField
                        value={details.funding_round}
                        change={setFundingRound}
                        placeholder={""}
                    />
                </div>

                <div className="company">
                    <label className={"label"}>Company</label>
                    <div className={"company-edit-container"}>
                        <ButtonSmall
                            className={"btn-edit-company"}
                            icon={faPencil}
                            text={"Select Company"}
                            click={() => {setShowCompanyModal(true)}}
                        />
                        <div className={"field"}>
                           <TextField
                                disabled={true}
                                value={details.company.name + " - " + details.company.url}
                                change={(value: any) => setCompany({...company, name: value})}
                                placeholder={"No Company Selected"}
                            />
                        </div>

                    </div>
                </div>

                <div className="inputContainer">
                    <TextArea
                        label="Description"
                        rows={3}
                        placeholder=""
                        change={setDescription}
                        value={details.description}
                    />
                </div>
            </div>
        </EditDealDetailsContainer>
    );
};

export default EditDealDetails
