// Styles
import Tabs from "components/Common/Tabs/Tabs";
import AlertsTab from "components/Screens/DailyRecs/DailyFlowsCheck/AlertsTab/AlertsTab";
import ChangeLogTab from "components/Screens/DailyRecs/DailyFlowsCheck/ChangeLogTab/ChangeLogTab";
import ReportsTab from "components/Screens/DailyRecs/DailyFlowsCheck/ReportsTab/ReportsTab";
import DailyFlowsCheckContainer from "./DailyFlowsCheck.styles";

interface DailyFlowsCheckProps {}

const powerBI = "https://app.powerbi.com/reportEmbed?reportId=273a79d5-e423-4706-a5f0-9a608d07933a&autoAuth=true&ctid=bf4450a2-bfcb-472d-8c40-1425eafb1a3b&filterPaneEnabled=false";
const checkName = "daily_flows";

const DailyFlowsCheck = ({}: DailyFlowsCheckProps) => {
    return (
        <DailyFlowsCheckContainer>
            <p className={"sectionTitle"}>DAILY FLOWS</p>
            <Tabs
                defaultTab="reports"
                tabs={[
                    {
                        value: "reports",
                        title: "REPORTS",
                        permissions: ["p_can_view_navs", "p_can_action_navs"],
                        content: <ReportsTab powerBI={powerBI} />,
                    },
                    {
                        value: "alerts",
                        title: "ALERTS",
                        permissions: ["p_can_view_navs", "p_can_action_navs"],
                        content: <AlertsTab checkName={checkName} />,
                    },
                    {
                        value: "change_log",
                        title: "CHANGE LOG",
                        permissions: ["p_can_view_navs", "p_can_action_navs"],
                        content: <ChangeLogTab checkName={checkName} />,
                    },
                ]}
            />
        </DailyFlowsCheckContainer>
    );
};

export default DailyFlowsCheck;
