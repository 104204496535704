import styled from 'styled-components';

export default styled.div`
    .inputLabel {
        margin-bottom: 10px;
    }

    .blankDiv {
        align-items: center;
        max-width: 250px;
        min-width: 250px;
        height: 40px;
        display: flex;

        margin-top: 10px;

        cursor: pointer;

        background-color: ${(props) => props.theme.filterButtonBackground};
        color: ${(props) => props.theme.filterButtonForeground};

        border-radius: 5px;
        overflow: hidden;

    }

    .filters {
        display: flex;
        margin-bottom: 40px;

        .filter-label {
            padding-right: 20px;
        }
    }

    .fields {
        margin-bottom: 40px;
    }
`;
