import styled from "styled-components";
import colors from "assets/styles/colors";

export default styled.div`
    flex-direction: column;
    max-width: 100%;
    min-width: 100%;
    display: flex;

    .actions {
        flex-direction: row;
        display: flex;
        svg {
            color: #777777;
            margin: 0 10px;
            cursor: pointer;
            :hover {
                color: black;
            }
        }
    }

    .searchBar {
        max-width: 100%;
        min-width: 100%;
        display: flex;
        align-items: center;
        z-index: 10;

        .actionBtns {
            display: flex;
            flex-direction: row;

            margin: 0 -10px;
        }
    }
`;
