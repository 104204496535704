import { useEffect, useState } from "react";

// FontAwesome
import { faDownload } from "@fortawesome/free-solid-svg-icons";

// Components
import ButtonSmall from "components/Common/ButtonSmall/ButtonSmall";
import OrderByButton from "components/Common/SearchOptions/OrderByButton/OrderByButton";
import SearchOptions from "components/Common/SearchOptions/SearchOptions";
import CompletedSessionsList from "components/Screens/BQMQ/Home/CompletedSessionsList/CompletedSessionsList";
import ExportModal from "components/Screens/BQMQ/Home/ExportModal/ExportModal";

// Context
import { useBQMQ } from "context/BQMQ.context";

// Styles
import HomeContainer from "./Sessions.styles";
// Utils
import { getSelectedID } from "utils/bqmq.utils";
// Logger
import Logger from "utils/logger.utils";
const Log = new Logger();
Log.init("Home");

export default function Home() {
    const { setMyVotes, companies, analysts, teams } = useBQMQ();

    const [filterQuery, setFilterQuery] = useState("");
    const [orderBy, setOrderBy] = useState(["start_date", "DESC"]);

    const [selectedCompany, setSelectedCompany] = useState<string>("");

    const [exportModalOpen, setExportModalOpen] = useState<boolean>(false);

    useEffect(() => {
        getSelectedID(filterQuery, setSelectedCompany, "company_id=");
    }, [filterQuery]);

    useEffect(() => {
        setMyVotes({});
    }, []); // eslint-disable-line

    const filterOptions = [
        {
            id: 1,
            name: "Search",
            value: "search",
            type: "SEARCH",
        },
        {
            id: 2,
            name: "Company",
            value: "company_id",
            type: "DROPDOWN",
            searchable: true,
            options: companies,
        },
        {
            id: 3,
            name: "Team",
            value: "gim_subteam",
            type: "DROPDOWN",
            options: teams,
        },
        {
            id: 4,
            name: "Analyst",
            value: "analyst_id",
            searchable: true,
            type: "DROPDOWN",
            options: analysts.filter((analyst: any) => analyst.active),
        },
    ];

    const orderOptions = [
        {
            label: "Start Date",
            value: ["start_date", "DESC"],
        },
        {
            label: "Company",
            value: ["company_name", "ASC"],
        },
        {
            label: "Team",
            value: ["gim_subteam", "ASC"],
        },
        {
            label: "Analyst",
            value: ["analyst_name", "ASC"],
        },
    ];

    return (
        <HomeContainer className={"pageContainer"}>
            <SearchOptions
                setFilterQuery={setFilterQuery}
                options={filterOptions}
                noDebounce
                actions={
                    <>
                        <OrderByButton
                            name={"Order By"}
                            options={orderOptions}
                            value={orderBy}
                            setValue={setOrderBy}
                        />

                        <ButtonSmall
                            text={"EXPORT DATA"}
                            icon={faDownload}
                            click={() => setExportModalOpen(true)}
                        />
                    </>
                }
            />

            <CompletedSessionsList
                filterQuery={filterQuery}
                orderBy={orderBy[0]}
            />

            {exportModalOpen && (
                <ExportModal
                    companyOptions={companies}
                    analystOptions={analysts}
                    selectedCompany={selectedCompany}
                    open={setExportModalOpen}
                />
            )}
        </HomeContainer>
    );
}
