// Styles
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

// Icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ButtonContainer from "./Button.styles";

type Props = {
    text: string;
    color?: "primary" | "secondary" | "third" | "accent" | "flat" | "confirm";
    click: Function;
    disabled?: boolean;
    className?: string;
    icon?: IconDefinition;
};

export default function Button({
    text,
    color = "primary",
    click,
    disabled = false,
    className,
    icon,
}: Props) {
    const handleClick = () => {
        if (!disabled) {
            click();
        }
    };

    return (
        <ButtonContainer
            data-cy="button"
            className={
                className + " button " + color + (disabled ? " disabled" : "")
            }
            onClick={handleClick}
            role={"button"}
        >
            {icon && (
                <FontAwesomeIcon className="icon" icon={icon} fixedWidth />
            )}
            <label className="btnText">{text}</label>
        </ButtonContainer>
    );
}
