import {
    faGlobe,
    faDollar,
    faDownload,
    faLineChart,
} from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import SubMenuItem from "../../SubMenuItem/SubMenuItem";
import SubMenuTitle from "../../SubMenuTitle/SubMenuTitle";

// Styles
import ADAContainer from "./ADA.styles";

// Components
import ButtonSmall from "components/Common/ButtonSmall/ButtonSmall";
import Can from "components/Common/Can/Can";

// Utils
import { useSettings } from "context/Settings.context";
import * as Database from "utils/database.utils";

interface ADAProps {}

const ADA = ({}: ADAProps) => {
    const history = useHistory();

    const { setShowLoadingToast, setToast } = useSettings();

    const downloadReport = async () => {
        setShowLoadingToast({
            show: true,
            message: "DOWNLOADING",
        });
        const report = history.location.pathname.split("/")[2];
        const outputFileName = `ADA Snapshot ${Date.now()}.xlsm`;

        const response = await Database.post("api/download_ada_excel", {
            excel_template_name: `ada_${report.toLocaleLowerCase()}`,
        });
        if (response.ok) {
            const blob = await response.blob();
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", outputFileName);
            document.body.appendChild(link);
            link.click();
        } else {
            setToast({
                show: true,
                message: "There was an error downloading the report.",
                type: "error",
            });
        }

        setShowLoadingToast({
            show: false,
            message: "",
        });
    };

    return (
        <ADAContainer className={"submenu"}>
            <div className={"top"}>
                <div className="adaTopContainer">
                    <h1 style={{ width: "90%" }}>ADA</h1>
                    {history.location.pathname === "/ada/snapshot" && (
                        <Can
                            yes={
                                <ButtonSmall
                                    className="excelExportBtn"
                                    text="EXPORT TO EXCEL"
                                    click={() => downloadReport()}
                                    icon={faDownload}
                                />
                            }
                            permissions={"p_gbl_dashboard"}
                        />
                    )}
                </div>
                <hr />
                <SubMenuTitle
                    text={"PORTFOLIO"}
                    permissions={["p_bqmq_can_view_results"]}
                />

                <SubMenuItem
                    text={"Snapshot"}
                    active={history.location.pathname === "/ada/snapshot"}
                    icon={faGlobe}
                    click={() => history.push("/ada/snapshot")}
                    permissions={["p_bqmq_can_view_results"]}
                />
                <SubMenuItem
                    text={"Trends"}
                    active={history.location.pathname === "/ada/trends"}
                    icon={faGlobe}
                    click={() => history.push("/ada/trends")}
                    permissions={["p_bqmq_can_view_results"]}
                />

                <SubMenuItem
                    text={"Supplementary Information"}
                    active={
                        history.location.pathname ===
                        "/ada/supplementary-information"
                    }
                    icon={faGlobe}
                    click={() => history.push("/ada/supplementary-information")}
                    permissions={["p_bqmq_can_view_results"]}
                />
                <SubMenuItem
                    text="Quality Matrix"
                    active={history.location.pathname === "/ada/quality-matrix"}
                    icon={faGlobe}
                    click={() => history.push("/ada/quality-matrix")}
                    permissions={["p_bqmq_can_view_results"]}
                />

                <SubMenuTitle
                    text={"VALUATION"}
                    permissions={["p_bqmq_can_view_results"]}
                />
                <SubMenuItem
                    text={"Financials"}
                    active={history.location.pathname === "/ada/financials"}
                    icon={faDollar}
                    click={() => history.push("/ada/financials")}
                    permissions={["p_bqmq_can_view_results"]}
                />
                <SubMenuItem
                    text={"NOPAT Assumptions"}
                    active={
                        history.location.pathname === "/ada/nopat_assumptions"
                    }
                    icon={faDollar}
                    click={() => history.push("/ada/nopat_assumptions")}
                    permissions={["p_bqmq_can_view_results"]}
                />
                <SubMenuItem
                    text={"Fair Value Charts"}
                    active={
                        history.location.pathname === "/ada/fair-value-charts"
                    }
                    icon={faDollar}
                    click={() => history.push("/ada/fair-value-charts")}
                    permissions={["p_bqmq_can_view_results"]}
                />
                <SubMenuTitle
                    text={"INDUSTRY SCORECARDS"}
                    permissions={["p_bqmq_can_view_results"]}
                />
                <SubMenuItem
                    text={"GEF Sectors"}
                    active={history.location.pathname === "/ada/gef_sectors"}
                    icon={faLineChart}
                    click={() => history.push("/ada/gef_sectors")}
                    permissions={["p_bqmq_can_view_results"]}
                />
                <SubMenuItem
                    text={"Asia PL Dashboard"}
                    active={history.location.pathname === "/ada/asia_pl"}
                    icon={faLineChart}
                    click={() => history.push("/ada/asia_pl")}
                    permissions={["p_bqmq_can_view_results"]}
                />
            </div>
        </ADAContainer>
    );
};
export default ADA;
