import styled from "styled-components";
import colors from "assets/styles/colors";

export default styled.div`
    display: flex;

    .title {
        text-align: center;
        padding: 20px 0 0 0;
        font-size: 25px;

        color: ${colors.textNavy};
    }

    .switches {
        display: flex;
        flex-direction: column;

        padding: 20px;

        .MuiFormControlLabel-label,
        label {
            font-family: Calibri;
            font-size: 16px;
            letter-spacing: 0.1em;
            margin-top: 5px;
            color: #555555;
        }

        .switch {
            padding-left: 10px;
            margin-bottom: 0px;
            .MuiSwitch-thumb {
                color: #004453;
            }
            .MuiSwitch-track {
                background-color: #004453 !important;
            }
            label {
                color: #333333;
                letter-spacing: 1px;
                font-size: 16px;
            }
        }
    }
`;
