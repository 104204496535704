import React from "react";

// Styles
import ReportsTabContainer from "./ReportsTab.styles";

interface ReportsTabProps {
    powerBI: string;
}

const ReportsTab = ({ powerBI }: ReportsTabProps) => {
    return (
        <ReportsTabContainer>
            {powerBI !== "" ? (
                <div className="iframeContainer">
                    <iframe
                        src={powerBI}
                        width="100%"
                        height="100%"
                        allowFullScreen
                        frameBorder={0}
                    />
                </div>
            ) : (
                <p>PowerBI dashboard not specified.</p>
            )}
        </ReportsTabContainer>
    );
};
export default ReportsTab;
