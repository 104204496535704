export const riskBuckets = [
    { label: "Consumer Discretionary", value: "Consumer Discretionary" },
    { label: "Emerging Markets", value: "Emerging Markets" },
    { label: "Energy", value: "Energy" },
    { label: "Enterprise IT", value: "Enterprise IT" },
    { label: "Financial Markets", value: "Financial Markets" },
    { label: "Health Care Core", value: "Health Care Core" },
    { label: "Industrial Commercial / Residential", value: "Industrial Commercial / Residential" },
    { label: "Semiconductors", value: "Semiconductors" },
    { label: "Staples", value: "Staples" },
]

export const teamOptions = [
    { label: "Consumer", value: "CONSUMER" },
    { label: "Financials", value: "FINANCIALS" },
    { label: "Healthcare", value: "HEALTHCARE" },
    { label: "Industrials", value: "INDUSTRIALS" },
    { label: "Technology", value: "TECH_AND_TELCO" },
    { label: "Unknown", value: "UNKNOWN" },
];



export const teamLabelDict = {
    CONSUMER: "Consumer",
    FINANCIALS: "Financials",
    HEALTHCARE: "Healthcare",
    INDUSTRIALS: "Industrials",
    TECH_AND_TELCO: "Technology",
    "TECH_&_TELCO": "Technology",
    "TECH & TELCO": "Technology",
    "HEALTH & LUX": "Health & Luxury",
    UNKNOWN: "-",
};

export const enabledOptions = [
    {
        label: "Enabled",
        value: "true",
    },
    {
        label: "Disabled",
        value: "false",
    },
];

export const statusOptions = [
    { label: "Successful", value: "SCS" },
    { label: "Holding", value: "HLD" },
    { label: "Failed", value: "FAI" },
    { label: "Ignored", value: "IGN" },
    { label: "Deleted", value: "DLT" },
];

export const methodOptions = [
    { label: "Auto", value: "AUTO" },
    { label: "Manual", value: "MANUAL" },
];

export const severityOptions = [
    { label: "Info", value: "Info" },
    { label: "Warning", value: "Warning" },
    { label: "Critical", value: "Critical" },
];

export const wsOptions = [
    { label: "SUMMARY SHEET", value: "SUMMARY SHEET" },
    { label: "O2", value: "O2" },
    { label: "P2", value: "P2" },
    { label: "V2", value: "V2" },
];

export const excelTableOptions = [
    { label: "O2_Additional and Futures", value: "O2_Additional and Futures" },
    {
        label: "O2_Bear Base Bull Key Points",
        value: "O2_Bear Base Bull Key Points",
    },
    {
        label: "O2_Bear Base Bull Modelling",
        value: "VO2_Bear Base Bull Modelling2",
    },
    { label: "O2_Company Details", value: "O2_Company Details" },
    { label: "O2_Comparators", value: "O2_Comparators" },
    { label: "O2_Currency", value: "O2_Currency" },
    { label: "O2_Currency Revenue Mix", value: "O2_Currency Revenue Mix" },
    {
        label: "O2_EPS Comparison to Market",
        value: "O2_EPS Comparison to Market",
    },
    {
        label: "O2_Expected Value Projection 1",
        value: "O2_Expected Value Projection 1",
    },
    {
        label: "O2_Expected Value Projection 2",
        value: "O2_Expected Value Projection 2",
    },
    { label: "O2_Fair Value Attribution", value: "O2_Fair Value Attribution" },
    { label: "O2_Fair Value Calculation", value: "O2_Fair Value Calculation" },
    {
        label: "O2_Financial Statement Summary",
        value: "O2_Financial Statement Summary",
    },
    { label: "O2_Holt Variables", value: "O2_Holt Variables" },
    { label: "O2_Investment Thesis", value: "O2_Investment Thesis" },
    { label: "O2_Ke and WACC", value: "O2_Ke and WACC" },
    {
        label: "O2_Net Debt / Expected Value",
        value: "O2_Net Debt / Expected Value",
    },
    { label: "O2_NoPat Calculations", value: "O2_NoPat Calculations" },
    { label: "O2_OSV", value: "O2_OSV" },
    { label: "O2_Range of Outcomes", value: "O2_Range of Outcomes" },
    { label: "O2_Region Revenue Mix", value: "O2_Region Revenue Mix" },
    { label: "O2_Supplements", value: "O2_Supplements" },
    {
        label: "O2_Supporting Calculations",
        value: "O2_Supporting Calculations",
    },
    {
        label: "O2_Time Adjusted Fair Value",
        value: "O2_Time Adjusted Fair Value",
    },
    { label: "O2_Undefined Table", value: "O2_Undefined Table" },
    { label: "P2_CFROI Over Time", value: "P2_CFROI Over Time" },
    { label: "P2_CFROI and Fade", value: "P2_CFROI and Fade" },
    { label: "P2_Company Overview", value: "P2_Company Overview" },
    { label: "P2_Industry Overview", value: "P2_Industry Overview" },
    { label: "P2_Master Peer List", value: "P2_Master Peer List" },
    { label: "P2_OSV", value: "P2_OSV" },
    { label: "P2_OpRoce Over Time", value: "P2_OpRoce Over Time" },
    { label: "P2_Summary Variables", value: "P2_Summary Variables" },
    { label: "P2_Undefined Table", value: "P2_Undefined Table" },
    { label: "V2_DCF Summary", value: "V2_DCF Summary" },
    { label: "V2_EVA DCF Model", value: "V2_EVA DCF Model" },
    { label: "V2_OpRoCE DCF Model", value: "V2_OpRoCE DCF Model" },
    { label: "V2_OpRoCE DCF Summary", value: "V2_OpRoCE DCF Summary" },
];

export const fairValueDateOptions = [
    {
        label: "Yearly",
        value: "fv_yearly",
    },
    {
        label: "Monthly",
        value: "fv_monthly",
    },
    {
        label: "Weekly",
        value: "fv_weekly",
    },
    {
        label: "Daily",
        value: "fv_daily",
    },
];

export const themeOptions = [
    {
        label: "Light",
        value: "light",
    },
    {
        label: "Dark",
        value: "dark",
    },
    {
        label: "High Contrast",
        value: "highContrast",
    },
];
