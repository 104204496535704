import styled from "styled-components";

export default styled.div`
    .userList {
        display: flex;

        flex-direction: column;

        max-height: 70vh;

        overflow-y: scroll;
    }

    .header {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: relative;

        h3 {
            font-size: 25px;
            padding: 20px 0 30px 0;

            color: ${(props) => props.theme.pageTitleText};
        }

        .button {
            position: absolute;
            right: -5px;
        }
    }

    .modalBackdrop {
        background-color: unset;
        backdrop-filter: unset;

        .main {
            padding: 0;
        }

        .actions {
            .button {
                &.flat {
                    .btnText {
                        color: ${(props) => props.theme.flatButtonForeground};
                    }
                }
                &.primary {
                    .btnText {
                        color: ${(props) =>
                            props.theme.primaryButtonForeground};
                    }
                }
            }
        }
    }
`;
