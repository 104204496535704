import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// Components
import HomeList from "../../../Home/HomeList/HomeList";
import UpcomingFocusListRow from "./UpcomingFocusListRow/UpcomingFocusListRow";

// Context
import { useBQMQ } from "context/BQMQ.context";
import { useSettings } from "context/Settings.context";
import { addTableId } from "utils/bqmq.utils";

// Styles
import UpcomingFocusListContainer from "./UpcomingFocusList.styles";

type Props = {
    filterQuery: string;
    filterResults: any;
    showFocusList: boolean;
};

export default function UpcomingFocusList({
    showFocusList,
    filterQuery,
    filterResults,
}: Props) {
    const history = useHistory();
    const { reloadHomePage } = useSettings();
    const { upcomingSessions } = useBQMQ();
    const [data, setData] = useState<BQMQSession[]>([]);
    const [filteredData, setFilteredData] = useState<any>([]);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        setData(addTableId(upcomingSessions));
        setTotal(upcomingSessions.length);
    }, [reloadHomePage, upcomingSessions]);

    useEffect(() => {
        if (data) {
            const filteredResults = filterResults(data);
            setTotal(filteredResults.length);
            setFilteredData(filteredResults);
        }
    }, [data, filterQuery]);

    return showFocusList ? (
        <UpcomingFocusListContainer>
            <HomeList
                data={filteredData}
                row={(row: any) => <UpcomingFocusListRow session={row} />}
                title={"UPCOMING COMPANY VOTES"}
                count={total}
                countColor={""}
                seeAll={() => history.push("/voting/focus-list")}
                maxRows={1000}
            />
        </UpcomingFocusListContainer>
    ) : null;
}
