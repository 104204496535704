import styled from "styled-components";

export default styled.div`
    .page-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }
    .title {
        font-size: 25px;
        letter-spacing: 0.1em;

        color: ${(props) => props.theme.pageTitleText};
        margin-bottom: 10px;
        margin-top: 20px;
    }

    .section {
        padding: 15px;
        font-size: 20px;
        letter-spacing: 0.1em;
        background-color: ${(props) => props.theme.homeListTitleRowBackground};
        color: ${(props) => props.theme.homeListTitleRowText};
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    .margin-top-50 {
        margin-top: 50px;
    }

    .btn-close-container {
        display:flex;
        justify-content: flex-end;
        padding-top: 8px;
    }
    .no-result {
        text-align: center;
        padding: 10px;
        border: 1px solid ${(props) => props.theme.homeListTitleRowBackground};
    }
`;
