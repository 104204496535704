// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCheckCircle,
    faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

// Styles
import SwitchContainer from "./Switch.styles";

type Props = {
    label: string;
    value: boolean;
    setValue: Function;
};

export default function Switch({ value, setValue, label }: Props) {
    return (
        <SwitchContainer
            className={"switch " + (value ? "active" : "")}
            onClick={() => setValue(!value)}
        >
            <p>{label}</p>
            <p className="value">{value ? "ON" : "OFF"}</p>

            <FontAwesomeIcon
                icon={value ? faCheckCircle : faTimesCircle}
                fixedWidth
            />
        </SwitchContainer>
    );
}
