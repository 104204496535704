import colors from "assets/styles/colors";

export default {
    modalBackground: "rgba(0, 0, 0, 0.5)",
    modalBackgroundFilter: "blur(10px)",
    modalBoxShadow: "0px 0px 20px 9px rgba(0, 0, 0, 0.1)",

    modalInnerBackground: colors.darkThemeDark,

    modalTitle: "white",
    modalText: "white",

    alertTitle: colors.darkThemeLighter,
    alertText: "white",
};
