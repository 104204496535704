import styled from "styled-components";

export default styled.div`
    user-select: none;
    flex-direction: column;
    .res-container {
        position: relative;
      }
      
      .overlay {
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        backdrop-filter: blur(10px);
      }
    .tabItem {
        .resultsCell {
            margin-left: 10px;
        }

        :hover {
            .resultsCell {
                margin-top: 2px;
            }
        }

        &.active {
            .resultsCell {
                margin-top: 2px;
            }
        }
    }

    .loadingScreen {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
    }

    hr {
        width: calc(100% - 10px);
        outline: none;
        border: none;

        margin: 0 5px;

        border-bottom: 1px solid #efefef;
    }

    .summaryRow {
        display: flex;

        position: relative;

        flex-direction: row;

        flex-wrap: wrap;
        margin: 0 -10px;
        padding-top: 10px;

        .sectionTitle {
            width: calc(100% - 10px);
            text-align: center;
            font-size: 25px;
            position: absolute;
            top: 0;
            margin: 0 5px;
            padding: 0 0 5px 0;

            border-bottom: 2px solid ${(props) => props.theme.tabsBottomBorder};
        }
    }
`;
