import styled from "styled-components";

export default styled.div`
    display: flex;
    flex-direction: row-reverse;

    height: 100%;

    background-color: ${(props) => props.theme.subMenuBackground};
    overflow: visible;

    z-index: 15;

    .submenu {
        display: flex;
        flex-direction: column;

        flex-grow: 1;

        padding: 20px 0 20px 20px;
        margin-right: -5px;

        overflow-y: auto;

        hr {
            width: 100%;
            border: none;
            border-bottom: 1px solid ${(props) => props.theme.subMenuSeparator};
        }

        h1 {
            font-size: 25px;
            margin-bottom: 0px;
            margin-left: 10px;

            color: ${(props) => props.theme.subMenuTitleText};
            letter-spacing: 1.7px;
            font-weight: 400;

        }

        h2 {
            font-size: 20px;
            margin-bottom: 0px;
            margin-left: 10px;

            color: ${(props) => props.theme.subMenuTitleText};
            letter-spacing: 1.7px;
            font-weight: 400;

        }

        .top {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
        }
        .bottom {
            display: flex;
            flex-direction: column;

            .button {
                width: 100%;
                margin: 0;
            }
        }
    }

    .handle {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        min-width: 5px;
        max-width: 5px;

        height: 100%;

        :hover,
        &.true {
            cursor: ew-resize;
            border-right: ${(props) => props.theme.subMenuHandleBorder} 3px
                solid;
        }
    }

    .minimiseBtn {
        display: flex;

        justify-content: center;
        align-items: center;

        min-width: 25px;
        max-width: 25px;
        min-height: 25px;
        max-height: 25px;

        border-radius: 50%;

        background-color: ${(props) => props.theme.subMenuButtonBackground};
        border: 3px solid ${(props) => props.theme.mainBackground};
        color: ${(props) => props.theme.subMenuButtonForeground};

        font-size: 12px;

        position: relative;

        top: 45%;
        right: -17px;

        :hover {
            background-color: ${(props) =>
                props.theme.subMenuButtonBackgroundHover};
            color: ${(props) => props.theme.subMenuButtonForegroundHover};
            cursor: w-resize;
        }

        &.close {
            cursor: e-resize;
        }
    }

    @media screen and (max-width: 600px) {
        padding-right: 15px;

        .minimiseBtn {
            display: none;
        }
    }

    &.init {
        min-width: 250px;
        max-width: 250px;
    }

    &.close {
        animation: closeSubMenu 200ms ease-in-out both;
    }

    &.open {
        animation: openSubMenu 200ms ease-in-out both;
    }

    @keyframes closeSubMenu {
        0% {
            min-width: ${(props: any) => props.width} + "px";
            max-width: ${(props: any) => props.width} + "px";
        }
        100% {
            min-width: 15px;
            max-width: 15px;
        }
    }

    @keyframes openSubMenu {
        0% {
            min-width: 15px;
            max-width: 15px;
        }
        100% {
            min-width: 250px;
            max-width: 250px;
        }
    }
`;
