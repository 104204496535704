import React, { useEffect, useState } from "react";

// Styles
import EditcPercAnswerContainer from "./EditPercAnswer.styles";

// Types
import {GrowthAnswer} from "assets/interfaces/growth_voting";

// Components
import Slider from '@mui/material/Slider';
import Select from "components/Common/Select/Select";

interface Porps {
    answer: GrowthAnswer;
    addToUpdatedAnsweres: (updated: GrowthAnswer) => void;
    open: boolean|undefined;
}


const EditPercAnswer  = ({answer, addToUpdatedAnsweres, open}: Porps) => {
    const [value, setValue] = useState<number|null>(null);

    const handleSliderChange = (event: Event, newValue: number | number[]) => {
      setValue(newValue as number);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value === '' ? 0 : Number(event.target.value));
    };

    const setInputValue = (value: string) => {
        if (!open) return;

        let perc = parseInt(value);

        if (isNaN(perc)) {
            perc = 0;
        }

        setValue(perc);
    }

    const handleChange = (value: number) => {
        let updated: GrowthAnswer = {
            ...answer,
            answer_percent: value
        }

        addToUpdatedAnsweres(updated)
    }

    useEffect(() => {
        if (!open) return;

        if (answer.answer_percent === value) return;

        if (value !== null) {
            handleChange(value)
        }
    }, [value])

    useEffect(() => {
        if (answer.answer_percent === undefined) return;

        setValue(answer.answer_percent);
    }, [])


    return (
        <EditcPercAnswerContainer>
            <div className={"slider-inputs"}>
                <div className={"field"}>
                    <Select
                        filterName="?"
                        options={[
                            { label: "0%", value: 0 },
                            { label: "20%", value: 20 },
                            { label: "40%", value: 40 },
                            { label: "60%", value: 60 },
                            { label: "80%", value: 80 },
                            { label: "100%", value: 100 },
                        ]}
                        value={value}
                        setValue={setInputValue}
                        disabled={!open}
                        showAll={false}
                    />
                </div>
                {open && <div className={"slider"}>
                    <Slider
                        value={typeof value === 'number' ? value : 0}
                        onChange={handleSliderChange}
                        aria-labelledby="input-slider"
                        disabled={!open}
                        step={20}
                        marks
                        min={0}
                        max={100}
                    />
                </div>}
            </div>
        </EditcPercAnswerContainer>
    );
};

export default EditPercAnswer
