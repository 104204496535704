export const getEnvironment = () : string => {
    const env: string|undefined = process.env.REACT_APP_GIM_ENVIRONMENT;

    if (!env) {
        return 'development';
    }

    return env;
}

export class EnvService {
    environment: string;

    constructor() {
        this.environment = getEnvironment();
    }

     isDevelopment() :boolean {
        return this.environment === 'development';
    }

    isProduction() :boolean {
        return this.environment === 'production';
    }

    isStaging() :boolean {
        return this.environment === 'staging';
    }
}
