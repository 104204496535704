import React, {useEffect, useState} from "react";

// Styles
import AddClientModalContainer from "./AddClientModal.styles";

// Components
import Button from "components/Common/Button/Button";
import Modal from "components/Common/Modal/Modal";
import Select from "components/Common/Select/Select";
import TextField from "components/Common/TextField/TextField";

//utils
import {formatNumberFixDecimal} from "utils/common.utils";
import {getCurrentQuarterFromStartMonth} from "utils/fees.utils";

// Context
import { useSettings } from "context/Settings.context";

// MaterialUI
import { Grid } from "@mui/material";

// Types
import DateSelect from "components/Common/DateSelect/DateSelect";
import { clientInputs, ClientValues } from "../../../utils/types";

//services
import { ApiHandler } from "services/api_handler";


interface AddClientModalProps {
    newClientValues: ClientValues;
    setNewClientValues: Function;
    handleChange: (value: string | number, name: string, type: string) => void;
    handleAddClient: (client: ClientValues) => void;
    errors?: any;
    setShowModal: Function;
    handleDateChange: (date: Date) => void;
}

interface DailyRecsAccount {
    gen_acct: string;
    ioo_acct: string;
    account_name: string;
    base_currency: string;
    benchmark: string;
    custodian: string;
    custodian_id: string;
}

const AddClientModal = ({
    newClientValues,
    setNewClientValues,
    handleChange,
    handleAddClient,
    errors,
    setShowModal,
    handleDateChange,
}: AddClientModalProps) => {
    const [loading, setLoading] = useState<boolean>(false);
    const { setToast, setShowLoadingToast } = useSettings();

    const [fieldErrors, setFieldErrors] = useState<any>({});

    const [accounts, setAccounts] = useState<DailyRecsAccount[]>([]);
    const [selectedAccount, setSelectedAccount] = useState<DailyRecsAccount | undefined>(undefined);

    const setAccountsFromDailyRecs = async () => {
        const url = "api/fees/client_config/daily-recs-accounts";

        const response = await new ApiHandler(url, setShowLoadingToast, setToast).get();

        if (response) {
            setAccounts(response.data);
        }
    };

    const handelAccountChange = (accountNumber: string) => {
        const account = accounts.find((account) => account.ioo_acct === accountNumber);
        setSelectedAccount(account)
    }

    const setValuesByDailyRecsAccount = () => {
        if (selectedAccount) {
            setNewClientValues({
                ...newClientValues,
                account_id: selectedAccount.ioo_acct,
                separate_account_name: selectedAccount.account_name,
                currency: selectedAccount.base_currency,
            });
        }
    }

    const formattedValue = (value: any, input: any) => {
        if (value === null || value === undefined || value === NaN || value === "") {
            return;
        }

        const formatType = input?.format;

        if (!formatType) {
            return;
        }

        let formattedValue = "";

        if (formatType === "percentage") {
            formattedValue = value + "%";
        } else if (formatType === "pound") {
            formattedValue = "£" + formatNumberFixDecimal(value);
        } else if (formatType === "dollar") {
            formattedValue = "$" + formatNumberFixDecimal(value);
        }

        return formattedValue;
    }

    useEffect(() => {
        setAccountsFromDailyRecs();
    }, []);

    useEffect(() => {
        if (selectedAccount) {
            setValuesByDailyRecsAccount();
        }
    }, [selectedAccount]);

    return (
        <AddClientModalContainer>
            <Modal loading={loading}>
                <div className="main">
                    <div className="text">
                        <h3>CLIENT CONFIG for {getCurrentQuarterFromStartMonth(newClientValues.month)}</h3>
                    </div>

                    <div>
                        <Select
                            label={"Select an Account"}
                            showAll={false}
                            searchable={true}
                            options={
                                accounts
                                    ? accounts
                                        .sort((a, b) =>
                                            a.account_name.localeCompare(
                                                b.account_name
                                            )
                                        )
                                        .map((account) => {
                                            return {
                                                value: account?.ioo_acct,
                                                label: account?.ioo_acct + " | " + account?.account_name,
                                            };
                                        })
                                    : [{ value: "", label: "" }]
                            }
                            filterName="Account Name"
                            setValue={(e: any) => handelAccountChange(e)}
                            value={selectedAccount?.ioo_acct || null}
                        />
                    </div>

                    {selectedAccount && <div className={"config-from-daily-recs"}>
                        <div className={"header"}>
                            Daily Recs configuration values
                        </div>
                        <div className={"account"}>
                            <span className={"account-label label"}>{newClientValues.account_id}</span> {newClientValues.separate_account_name}
                        </div>
                        <div className={"currency"}>
                            <span className={"currency-label label"}>{newClientValues.currency}</span> Currency
                        </div>
                    </div>}

                    {selectedAccount && <Grid container spacing={2}>
                        {clientInputs.map((input) => {
                            let name = input.name;
                            let label = input.label;
                            if (input.type === "input") {
                                return (
                                    <Grid
                                        key={name}
                                        item
                                        xs={12}
                                        sm={12}
                                        md={6}
                                    >
                                        <TextField
                                            placeholder={
                                                newClientValues[
                                                    name as keyof ClientValues
                                                ]
                                            }
                                            type={
                                                typeof newClientValues[
                                                    name as keyof ClientValues
                                                ] === "string"
                                                    ? "text"
                                                    : "number"
                                            }
                                            change={(e: any) =>
                                                handleChange(
                                                    e,
                                                    name,
                                                    typeof newClientValues[
                                                        name as keyof ClientValues
                                                    ]
                                                )
                                            }
                                            label={label}
                                            value={
                                                newClientValues[
                                                    name as keyof ClientValues
                                                ]
                                            }
                                            error={
                                                Object.hasOwn(fieldErrors, name)
                                                    ? true
                                                    : false
                                            }
                                            errorText={
                                                Object.hasOwn(fieldErrors, name)
                                                    ? fieldErrors[name]
                                                    : null
                                            }
                                        />
                                        <div className="formatted">{formattedValue(newClientValues[name as keyof ClientValues], input)}</div>
                                    </Grid>
                                );
                            }
                            if (input.type === "select" && input.options) {
                                return (
                                    <Grid
                                        key={name}
                                        item
                                        xs={12}
                                        sm={12}
                                        md={6}
                                    >
                                        <div className="select">
                                            <Select
                                                filterName={name}
                                                options={input.options}
                                                setValue={(e: any) =>
                                                    handleChange(
                                                        e,
                                                        name,
                                                        typeof newClientValues[
                                                            name as keyof ClientValues
                                                        ]
                                                    )
                                                }
                                                value={
                                                    newClientValues[
                                                        name as keyof ClientValues
                                                    ]
                                                }
                                                searchable={false}
                                                showAll={false}
                                                label={label}
                                            />
                                        </div>
                                    </Grid>
                                );
                            }
                            if (input.type === "date") {
                                return (
                                    <Grid
                                        key={name}
                                        item
                                        xs={12}
                                        sm={12}
                                        md={6}
                                    >
                                        <div className="select">
                                            <DateSelect
                                                label={"Fund Entry Date"}
                                                placeholder={
                                                    newClientValues[
                                                        "fund_entry_date"
                                                    ]
                                                }
                                                setValue={handleDateChange}
                                                value={
                                                    new Date(
                                                        newClientValues[
                                                            "fund_entry_date"
                                                        ]
                                                    )
                                                }
                                                error={false}
                                            />
                                        </div>
                                    </Grid>
                                );
                            }
                        })}
                    </Grid>}
                </div>

                <div className="actions">
                    <Button
                        text="CANCEL"
                        color="flat"
                        click={() => setShowModal(false)}
                    />
                    <Button
                        text="SAVE"
                        click={() => handleAddClient(newClientValues)}
                        disabled={selectedAccount ? false : true}
                    />
                </div>
            </Modal>
        </AddClientModalContainer>
    );
};
export default AddClientModal;
