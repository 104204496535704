// Components
import Button from "components/Common/Button/Button";
import Table from "components/Common/Table/Table";
import TableCell from "components/Common/TableCell/TableCell";

// Styles
import FullListContainer from "./FullList.styles";

type Props = {
    setShowAll?: Function;
    homePage?: boolean;
};

export default function FullList({ setShowAll }: Props) {
    const columns = [
        {
            id: 1,
            title: "COMPANY",
            sortable: false,
            value: "scraped_company",
            cell: (data: string) => (
                <TableCell styleTypes="capitalize" data={data.toLowerCase()} />
            ),
            show: true,
        },
        {
            id: 2,
            title: "TEAM",
            sortable: false,
            value: "scraped_team",
            cell: (data: string) => (
                <TableCell styleTypes="capitalize" data={data} />
            ),
            show: true,
        },
        {
            id: 3,
            title: "TICKER",
            sortable: false,
            value: "scraped_ticker",
            cell: (data: string) => <TableCell data={data} />,
            show: true,
        },
        {
            id: 4,
            title: "FILE NAME",
            sortable: false,
            value: "scraped_excel_model",
            cell: (data: string) => <TableCell data={data} />,
            show: true,
        },
        {
            id: 5,
            title: "DETECTED DATE",
            sortable: false,
            value: "first_detected_date",
            cell: (data: string) => <TableCell data={data} />,
            show: true,
        },
    ];

    return (
        <FullListContainer>
            <p className="title mt20 mb10">ALL AUTO DETECTED COMPANIES</p>
            {setShowAll && (
                <>
                    <div className="buttonsRow">
                        <Button
                            text="BACK"
                            color="primary"
                            click={() => setShowAll(false)}
                        />
                    </div>
                </>
            )}

            <Table
                url="api/companies/new-companies"
                columns={columns}
                filterQuery={""}
                defaultSortKey="first_detected_datetime"
                defaultSortOrder="DESC"
                skeletonLength={3}
            />
        </FullListContainer>
    );
}
