import { useEffect } from "react";

// Components
import PageTitle from "components/Common/PageTitle/PageTitle";
import Companies from "components/Screens/Data/Companies/Companies/Companies";

// Styles
import CompanyListContainer from "./CompanyList.styles";

export default function CompaniesPage() {
    useEffect(() => {
        return () => {
            localStorage.removeItem("analystList");
        };
    }, []);

    return (
        <CompanyListContainer>
            <PageTitle title="COMPANY LIST" />
            <Companies />
        </CompanyListContainer>
    );
}
