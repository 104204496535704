// Styles
import PageTitleContainer from "./PageTitle.styles";

type Props = {
    title: string | JSX.Element;
    actions?: React.ReactNode;
};

export default function PageTitle({ title, actions }: Props) {
    return (
        <PageTitleContainer>
            <div className="titleRow">
                <h1>{title}</h1>
                <div className="actions">{actions}</div>
            </div>
        </PageTitleContainer>
    );
}
