import styled from "styled-components";

export default styled.div`
    .sectionTitle {
        font-size: 25px;
        letter-spacing: 0.1em;

        color: ${(props) => props.theme.pageTitleText};
        margin-top: 20px;
        float: left;
    }

    .periodSelect {
        height: 40px;
        margin-top: 20px;
        width: 300px;
        float: right;
    }
`;
