// FontAwesome
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Context
import { useSettings } from "context/Settings.context";

// Styles
import LoadingToastContainer from "./LoadingToast.styles";

export default function LoadingToast() {
    const { showLoadingToast } = useSettings();

    return (
        showLoadingToast.show && (
            <LoadingToastContainer>
                <div className="text">
                    <p>{showLoadingToast.message}</p>
                </div>
                <div className="spinner">
                    <FontAwesomeIcon icon={faSpinner} fixedWidth />
                </div>
            </LoadingToastContainer>
        )
    );
}
