// Components
import PageTitle from "components/Common/PageTitle/PageTitle";
import AnalyticsQueue from "components/Screens/Data/AnalyticsQueue/AnalyticsQueue";

// Styles
import AdminContainer from "./AnalyticsQueue.styles";

const AnalyticsQueuePage: React.FC = () => {
    return (
        <AdminContainer>
            <PageTitle title="ANALYTICS QUEUE" />
            <AnalyticsQueue />
        </AdminContainer>
    );
};

export default AnalyticsQueuePage;
