import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

// Components
import LoadingSpinner from "components/Common/LoadingSpinner/LoadingSpinner";
import WarningCounts from "components/Screens/Data/CompanyModel/WarningCounts/WarningCounts";
import ErrorLevel from "components/Screens/Data/CompanyModel/Validation/ErrorLevel/ErrorLevel";

// Utils
import * as Database from "utils/database.utils";

// Styles
import ErrorDetailContainer from "./ErrorDetail.styles";

type Props = {
    extractionId: string;
    info: number;
    warning: number;
    critical: number;
};

export default function ErrorDetail({
    extractionId,
    info,
    warning,
    critical,
}: Props) {
    const history = useHistory();

    const [loading, setLoading] = useState<boolean>(true);
    const [errorList, setErrorList] = useState<any[]>([]);

    const getExtractionBatch = () => {
        Database.get(
            "api/extractions/validation/" +
                extractionId +
                "?page=1&pageSize=5&sortKey=error_severity_rank&sortOrder=DESC"
        ).then((res) => {
            if (res.ok) {
                res.json().then((data) => {
                    setErrorList(data.data);
                    setLoading(false);
                });
            }
        });
    };

    const seeAllErrors = () => {
        history.push(
            "/data/company-model?id=" + extractionId + "&tab=validation"
        );
    };

    useEffect(() => {
        getExtractionBatch();
    }, []);

    return (
        <ErrorDetailContainer>
            <h3>ERRORS</h3>

            <WarningCounts info={info} warning={warning} critical={critical} />
            <div className="errorList">
                {!loading ? (
                    <>
                        {errorList?.map((error: any) => (
                            <div className="errorRow">
                                <ErrorLevel
                                    errorLevel={error.error_severity_rank}
                                    noText
                                />
                                <p>{error.error_desc} </p>
                            </div>
                        ))}

                        {errorList?.length > 0 ? (
                            <div
                                className="seeAllErrors"
                                onClick={seeAllErrors}
                            >
                                <p>SEE ALL ERRORS</p>
                                <FontAwesomeIcon
                                    icon={faArrowRight}
                                    fixedWidth
                                />
                            </div>
                        ) : (
                            <div className="noErrorInfo">
                                <p>NO ERROR INFO</p>
                            </div>
                        )}
                    </>
                ) : (
                    <LoadingSpinner />
                )}
            </div>
        </ErrorDetailContainer>
    );
}
