import styled from "styled-components";

export default styled.div`
    background-color: white;
    z-index: 100;
    display: flex;
    flex-direction: column;
    user-select: none;

    .MuiListItemIcon-root {
        min-width: 30px;
        color: red;
    }
`;
