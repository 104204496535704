// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";

// Components
import Table from "components/Common/Table/Table";

// Styles
import PropsTableContainer from "./PropsTable.styles";

type Props = { props: string };

export default function PropsTable({ props }: Props) {
    let data = JSON.parse(props);

    const columns = [
        {
            id: 1,
            title: "NAME",
            sortable: false,
            value: "name",
            cell: (data: any) => <p className={"cell"}>{data}</p>,
            show: true,
        },
        {
            id: 2,
            title: "REQUIRED",
            sortable: false,
            value: "required",
            cell: (data: any) =>
                data ? <FontAwesomeIcon icon={faAsterisk} fixedWidth /> : null,
            show: true,
        },
        {
            id: 3,
            title: "DESCRIPTION",
            sortable: false,
            value: "description",
            cell: (data: any) => <p className={"cell"}>{data}</p>,
            show: true,
        },
        {
            id: 4,
            title: "OPTIONS",
            sortable: false,
            value: "options",
            cell: (data: any) => <p className={"cell"}>{data}</p>,
            show: true,
        },
        {
            id: 5,
            title: "DEFAULT",
            sortable: false,
            value: "default",
            cell: (data: any) => <p className={"cell"}>{data}</p>,
            show: true,
        },
    ];

    return (
        <PropsTableContainer>
            <Table data={data} columns={columns} filterQuery={""} large />
        </PropsTableContainer>
    );
}
