import React from "react";
import {useState, useEffect} from "react";

// Components
import Tabs from "components/Common/Tabs/Tabs";
import Historic from "components/Screens/Fees/Common/FundTabs/HistoricTab/Historic";
import Latest from "components/Screens/Fees/Common/FundTabs/LatestTab/Latest";
import Reports from "components/Screens/Fees/Common/FundTabs/ReportsTab/Reports";

// Styles
import DublinGlobalContainer from "./DublinGlobal.styles";
import SubTabs from "components/Common/SubTabs/SubTabs";
import Archive from "components/Screens/Fees/Common/FundTabs/ArchiveTab/Archive";

//utils
import { feesDashboardUrl } from "utils/fees.utils";

interface DublinGlobalProps {
    region: string;
}

const DublinGlobal = ({ region }: DublinGlobalProps) => {
    const [feesForcastUrl, setFeesForcastUrl] = useState("");
    const [feesDiscrepancyUrl, setFeesDiscrepancyUrl] = useState("");
    const [feesMonthlyUrl, setFeesMonthlyUrl] = useState("");

    useEffect(() => {
       feesDashboardUrl("DUBLIN_GLOBAL_FEES", setFeesForcastUrl);
       feesDashboardUrl("DUBLIN_GLOBAL_DISCREPANCY", setFeesDiscrepancyUrl);
       feesDashboardUrl("DUBLIN_GLOBAL_MONTHLY", setFeesMonthlyUrl);
    }, []);

    return (
        <DublinGlobalContainer>
            <SubTabs
                defaultTab="latest"
                subTabs={[
                    {
                        value: "latest",
                        title: "LATEST",
                        content: <Latest fund="dublin" region="global" period="latest" />,
                        permissions: ["p_can_view_fees", "p_can_action_fees"],
                    },
                    {
                        value: "reports",
                        title: "REPORTS SIGN OFF",
                        content: <Reports fund="dublin" region="global" />,
                        permissions: ["p_can_view_fees", "p_can_action_fees"],
                    },
                    {
                        value: "fee_report",
                        title: "FEE REPORT",
                        content: (
                            <div className="iframeContainer">
                                <iframe
                                    src={feesForcastUrl}
                                    width="100%"
                                    height="100%"
                                    allowFullScreen
                                    frameBorder={0}
                                />
                            </div>
                        ),
                        permissions: ["p_can_view_fees", "p_can_action_fees"],
                    },
                    {
                        value: "discrepancy_report",
                        title: "DISCREPANCY REPORT",
                        content: (
                            <div className="iframeContainer">
                                <iframe
                                    src={feesDiscrepancyUrl}
                                    width="100%"
                                    height="100%"
                                    allowFullScreen
                                    frameBorder={0}
                                />
                            </div>
                        ),
                        permissions: ["p_can_view_fees", "p_can_action_fees"],
                    },
                    {
                        value: "monthly_report",
                        title: "MONTHLY NAV REPORT",
                        content: (
                            <div className="iframeContainer">
                                <iframe
                                    src={feesMonthlyUrl}
                                    width="100%"
                                    height="100%"
                                    allowFullScreen
                                    frameBorder={0}
                                />
                            </div>
                        ),
                        permissions: ["p_can_view_fees", "p_can_action_fees"],
                    },
                    {
                        value: "archive",
                        title: "ARCHIVE",
                        content: <Archive fund="dublin" region="global" />,
                        permissions: ["p_can_view_fees", "p_can_action_fees"],
                    },
                ]}
            />
        </DublinGlobalContainer>
    );
};
export default DublinGlobal;
