import styled from "styled-components";
import colors from "assets/styles/colors";

export default styled.div`
    display: flex;
    flex-direction: column;

    width: fit-content;
    margin: 0 0 0 auto;

    border-radius: 40px 10px 10px 40px;
    margin-right: 20px;

    padding: 10px 10px 10px 10px;

    background-color: ${colors.teal100};

    &.blue {
        background-color: ${colors.textTeal};
    }
    &.orange {
        background-color: ${colors.accentOrange};
    }
    &.red {
        background-color: ${colors.accentRed};
    }
    &.green {
        background-color: ${colors.accentGreen};
        .status {
            .statusIcon {
                font-size: 35px;
                margin-left: 5px;
            }
        }
    }

    .status {
        display: flex;
        flex-direction: row;
        align-items: center;

        .statusIcon {
            font-size: 40px;
            margin-left: -7px;
            margin-right: 10px;
            color: white;
        }

        .statusText {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: flex-start;
            flex-grow: 1;
            color: white;

            p {
                font-size: 14px;
                margin: 0;
                letter-spacing: 0.1em;
                margin-left: 0;
            }
            h5 {
                font-size: 24px;
                margin: 0;
                letter-spacing: 0.2em;
                margin-left: 0;
                line-height: 1;
            }
        }
    }

    .actions {
        margin-top: 20px;
        display: flex;
        flex-direction: row;

        .action {
            background-color: white;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 5px;
            margin: 5px;
            width: 50%;

            border-radius: 5px;

            .iconCircle {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: ${colors.accentOrange};
                width: 40px;
                height: 40px;
                border-radius: 50%;
                margin: 10px;
                svg {
                    font-size: 24px;
                    color: white;
                }

                &.blue {
                    background-color: ${colors.textTeal};
                }
                &.orange {
                    background-color: ${colors.accentOrange};
                }
            }

            p {
                margin: 0 10px;
                font-size: 18px;
                letter-spacing: 0.15em;
                white-space: pre-wrap;
                text-align: center;
            }

            :hover {
                background-color: #dddddd;
                cursor: pointer;
            }
        }
    }
`;
