import colors from "assets/styles/colors";

export default {
    homeListTitleRowBackground: colors.darkThemeLight,
    homeListTitleRowText: "white",
    homeListCountBackgroundDefault: colors.darkThemeLighter,
    homeListCountTextDefault: "white",

    homeListBodyBorderColor: colors.grey90,
    homeListBodyBackgroundHover: colors.darkThemeMain,

    homeListSeeAllText: "white",

    homeListRowHeader: colors.darkThemeLighter,
    homeListRowText: "white",
};
