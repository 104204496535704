// Material UI
import { faSort } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TableCell from "@mui/material/TableCell";
type ColSpan = {
    text?: string;
    colSpan: number;
};

export const getHeaderCell = (
    column: Column,
    sortKey: string | null,
    changeSort: (column: Column) => void,
    stickyHeader: boolean
) => {
    return (
        <TableCell
            key={`${column.id}-${column.title}`}
            className={
                (sortKey === column.value
                    ? "sortActive tableHeadCell"
                    : "tableHeadCell") +
                (column.sortable ? " sortable" : "") +
                (stickyHeader ? " stickyHeader" : "")
            }
            onClick={() => changeSort(column)}
            style={{
                minWidth: column.width ? column.width : "fit-content",
                backgroundColor: column.headerColor || undefined,
                color: column.textColor || undefined,
            }}
        >
            {column.title}
            {column.sortable && <FontAwesomeIcon icon={faSort} fixedWidth />}
        </TableCell>
    );
};

export const setGroupCols = (columns: Column[]) => {
    let colSpans: ColSpan[] = [];
    let currentGroupingKey = columns[0].groupingKey;
    let currentCount = 0;

    for (let column of columns) {
        if (column.groupingKey === currentGroupingKey) {
            currentCount++;
        } else {
            colSpans.push({ text: currentGroupingKey, colSpan: currentCount });
            currentGroupingKey = column.groupingKey;
            currentCount = 1;
        }
    }
    if (currentCount > 0) {
        colSpans.push({ text: currentGroupingKey, colSpan: currentCount });
    }

    return colSpans.map((column, index) => {
        return (
            <TableCell
                colSpan={column.colSpan}
                align="center"
                className="no-border-head"
                key={`${index}-${column.text}`}
            >
                <b>{column.text}</b>
            </TableCell>
        );
    });
};
export default setGroupCols;
