// Styles
import FileUploadContainer from "./MultipleFileUpload.styles";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import deleteIcon from "assets/images/delete_icon.svg";

type Props = {
    setSelectedFiles: Function;
    selectedFiles: File[] | null;
};

const MultipleFileUpload: React.FC<Props> = ({
    selectedFiles,
    setSelectedFiles,
}) => {
    const [errors, setErrors] = useState<any>(false);

    const onSelectFile = (files: FileList | null) => {
        if (files) {
            const filesArray: Array<File> = Array.from(files);
            if (selectedFiles && selectedFiles.length > 0) {
                filesArray.forEach((newFile) => {
                    const index: number = selectedFiles.findIndex(
                        (file) => file.name === newFile.name
                    );
                    if (index !== -1) {
                        selectedFiles[index] = newFile;
                    } else {
                        selectedFiles.push(newFile);
                    }
                });
                setSelectedFiles([...selectedFiles]);
            } else {
                setSelectedFiles(filesArray);
            }
        }
    };

    const removeFile = (filename: string) => {
        if (selectedFiles) {
            const fileExists = selectedFiles.some(
                (file) => file.name === filename
            );
            if (fileExists) {
                const updatedFiles = selectedFiles.filter(
                    (file) => file.name !== filename
                );
                setSelectedFiles(updatedFiles);
            }
        }
    };

    return (
        <FileUploadContainer>
            <div className="form">
                <label
                    id="file-upload-container"
                    className={
                        "uploadBox " + (errors.length > 0 && !selectedFiles)
                    }
                    htmlFor="getFiles"
                    onDrop={(e) => {
                        e.preventDefault();
                        onSelectFile(e.dataTransfer.files);
                    }}
                    onDragOver={(e) => e.preventDefault()}
                >
                    <FontAwesomeIcon icon={faUpload} fixedWidth />
                    {selectedFiles && selectedFiles.length > 0 ? (
                        Array.from(selectedFiles).map((file, index) => (
                            <p key={index}>
                                {file.name}{" "}
                                <img
                                    src={deleteIcon}
                                    alt=""
                                    height={20}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        removeFile(file.name);
                                    }}
                                />
                            </p>
                        ))
                    ) : (
                        <p>Select Files...</p>
                    )}
                </label>
                <input
                    type="file"
                    name="file"
                    id="getFiles"
                    data-testid="fileInputId"
                    className="inputHide"
                    onChange={(e) => onSelectFile(e.target.files)}
                    multiple
                    style={{ display: "none" }}
                    accept="application/pdf,application/msword,
                    application/vnd.ms-powerpoint,
                    application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                />
            </div>
        </FileUploadContainer>
    );
};

export default MultipleFileUpload;
