import { useHistory } from "react-router-dom";

// FontAwesome
import {faTasks, faListDots, faVoteYea, faTemperature4} from "@fortawesome/free-solid-svg-icons";

// Components
import SubMenuItem from "../../SubMenuItem/SubMenuItem";

// Styles
import GrowthVotingContainer from "./GrowthVoting.styles";

export default function AskAda() {
    const history = useHistory();

    return (
        <GrowthVotingContainer className={"submenu"}>
            <div className={"top"}>
                <h1>Growth Voting</h1>
                <hr />
                <SubMenuItem
                    text={"Home"}
                    active={history.location.pathname === "/growth-voting"}
                    icon={faTemperature4}
                    click={() => history.push("/growth-voting")}
                    permissions={["p_can_view_growth_voting"]}
                />
                 <SubMenuItem
                    text={"Deals"}
                    active={history.location.pathname.includes("/growth-voting/deals")}
                    icon={faVoteYea}
                    click={() => history.push("/growth-voting/deals")}
                    permissions={["p_can_view_growth_voting"]}
                />
                <SubMenuItem
                    text={"Companies"}
                    active={history.location.pathname.includes("/growth-voting/companies")}
                    icon={faListDots}
                    click={() => history.push("/growth-voting/companies")}
                    permissions={["p_can_view_growth_voting"]}
                />
            </div>
        </GrowthVotingContainer>
    );
}
