import React, { useState } from "react";
import { useHistory } from "react-router-dom";

// FontAwesome
import {
    faEllipsisH,
    faEye,
    faTrash,
    faHammer,
    faDownload,
    faFileExcel,
    faForward,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Components
import DropDownMenu from "components/Common/DropDownMenu/DropDownMenu";
import DropDownMenuItem from "components/Common/DropDownMenu/DropDownMenuItem/DropDownMenuItem";
import Can from "components/Common/Can/Can";
import DeleteExtractionModal from "components/Screens/Data/CompanyModel/DeleteExtractionModal/DeleteExtractionModal";
import IgnoreExtractionModal from "components/Screens/Data/CompanyModel/IgnoreExtractionModal/IgnoreExtractionModal";

// Utils
import { toDateAndTimeSeconds } from "utils/general.utils";
import { exportFromS3, exportCSV } from "utils/export.utils";

// Context
import { useSettings } from "context/Settings.context";

// Styles
import ActionsContainer from "./Actions.styles";
import colors from "assets/styles/colors";

type Props = {
    id: string;
    companyName: string;
    archiveFolderPath: string;
    status: Status;
    companyID: number;
    processing_datetime: Date;
    extraction_datetime: Date;
    reload: Function;
};

const Actions: React.FC<Props> = ({
    id,
    companyName,
    archiveFolderPath,
    status,
    companyID,
    processing_datetime,
    extraction_datetime,
    reload,
}) => {
    const history = useHistory();
    const { setShowLoadingToast } = useSettings();

    const [anchorEl, setAnchorEl] = useState<any>(null);
    const [openActions, setOpenActions] = useState<boolean>(false);

    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [showIgnoreModal, setShowIgnoreModal] = useState<boolean>(false);

    const handleClickActions = (event: React.MouseEvent) => {
        setAnchorEl(event.currentTarget);
        setOpenActions(!openActions);
    };

    const handleExportS3 = () => {
        setAnchorEl(null);
        setOpenActions(false);
        setShowLoadingToast({ show: true, message: "EXPORTING" });

        exportFromS3(id, archiveFolderPath).then((res) => {
            setShowLoadingToast({
                show: false,
                message: "Exporting...",
            });
        });
    };

    const handleExportCSV = () => {
        setAnchorEl(null);
        setOpenActions(false);
        setShowLoadingToast({ show: true, message: "EXPORTING" });

        exportCSV(id, archiveFolderPath).then((res) => {
            setShowLoadingToast({
                show: false,
                message: "EXPORTING",
            });
        });
    };

    return (
        <ActionsContainer>
            <FontAwesomeIcon
                className={"actionsMenuBtn"}
                data-testid="actionEllipsis"
                icon={faEllipsisH}
                fixedWidth
                onClick={handleClickActions}
            />

            <DropDownMenu
                open={openActions}
                setOpen={setOpenActions}
                anchorEl={anchorEl}
            >
                <DropDownMenuItem
                    icon={<FontAwesomeIcon icon={faEye} fixedWidth />}
                    text="VIEW"
                    click={() => history.push("/data/company-model?id=" + id)}
                />

                {["HLD", "FAI"].includes(status) && (
                    <DropDownMenuItem
                        icon={<FontAwesomeIcon icon={faHammer} fixedWidth />}
                        text="FIX"
                        click={() =>
                            history.push(
                                `/data/upload-data?company=${companyID}&extractionDate=${toDateAndTimeSeconds(
                                    extraction_datetime
                                )}&extractionID=${id}&company_name=${companyName}`
                            )
                        }
                        permissions={["p_upload_model", "p_fix_extraction"]}
                    />
                )}

                {["HLD"].includes(status) && (
                    <DropDownMenuItem
                        icon={<FontAwesomeIcon icon={faForward} fixedWidth />}
                        text="IGNORE"
                        click={() => setShowIgnoreModal(true)}
                        permissions={["p_ignore_extraction_errors"]}
                    />
                )}

                {["HLD", "FAI"].includes(status) && (
                    <DropDownMenuItem
                        icon={<FontAwesomeIcon icon={faTrash} fixedWidth />}
                        text="DELETE"
                        click={() => setShowDeleteModal(true)}
                        permissions={["p_remove_extraction"]}
                    />
                )}

                {archiveFolderPath && (
                    <DropDownMenuItem
                        icon={<FontAwesomeIcon icon={faFileExcel} fixedWidth />}
                        text="EXPORT RAW"
                        click={handleExportS3}
                        permissions={["p_upload_model"]}
                    />
                )}

                {companyID !== 0 && (
                    <DropDownMenuItem
                        icon={<FontAwesomeIcon icon={faDownload} fixedWidth />}
                        text="EXPORT CSV"
                        click={handleExportCSV}
                        permissions={["p_upload_model"]}
                    />
                )}
            </DropDownMenu>

            {showDeleteModal && (
                <DeleteExtractionModal
                    id={id}
                    setShowModal={setShowDeleteModal}
                    reload={reload}
                />
            )}
            {showIgnoreModal && (
                <IgnoreExtractionModal
                    id={id}
                    setShowModal={setShowIgnoreModal}
                    extraction_datetime={extraction_datetime}
                    processing_datetime={processing_datetime}
                    reload={reload}
                />
            )}
        </ActionsContainer>
    );
};

export default Actions;
