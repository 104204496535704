import styled from "styled-components";

export default styled.div<{ maxHeight: string }>`
    .title {
        font-size: 25px;
        letter-spacing: 0.1em;

        color: ${(props) => props.theme.pageTitleText};
        margin-bottom: 10px;
        margin-top: 20px;
    }

    .textFieldDiv {
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
    }

    .textField {
        min-width: 200px;
        max-width: 200px;
        margin-right: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .buttonDiv {
        display: flex;
    }

    .select {
        min-width: 200px;
        max-width: 200px;
        display: inline;
        float: left;
    }

    .deleteIcon {
        cursor: pointer;

        svg {
            color: ${(props) => props.theme.tableIconHighlight};
            font-size: 20px;
        }
    }

    .generateBtn {
        margin-top: -45px;
        margin-bottom: 15px;
        float: right;
    }

    .tableDiv {
        clear: right;
        overflow-y: hidden;

      }
      #tableContScroll {
        max-height: ${(props) => props.maxHeight};
        
    }
    }

   
`;
