// Context
import { useBQMQ } from "context/BQMQ.context";

// Styles
import SummaryCardContainer from "./SummaryCard.styles";

// Logger
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import Logger from "utils/logger.utils";
import Comments from "../Comments/Comments";
import UserVotesTable from "../UserVotesTable/UserVotesTable";
import VoteCountsTable from "../VoteCountsTable/VoteCountsTable";

const Log = new Logger();
Log.init("SummaryCard");

type Props = {
    factor: string;
    results: any;
    usersVotes: any;
    mode: string;
    comments: any;
    voteCountMode: string;
    setVoteCountMode: React.Dispatch<React.SetStateAction<"QUESTION" | "USER">>;
    all?: boolean;
    sessionDate?: string;
    editVote?: boolean;
    sessionId?: string;
};

export default function SummaryCard({
    factor,
    results,
    mode,
    usersVotes,
    comments,
    setVoteCountMode,
    voteCountMode,
    all = false,
}: Props) {
    const { allFactorsList } = useBQMQ();
    const [showComments, setShowComments] = useState(true);

    return (
        <SummaryCardContainer>
            <div className={"cardInner"}>
                <div className={"title"}>
                    <div className={"left"}>
                        <div
                            className={
                                "mode score-" + mode?.toString().toUpperCase()
                            }
                        >
                            {mode?.toString().toUpperCase()}
                        </div>
                        {allFactorsList[factor]?.name.toUpperCase() +
                            (all ? ` | ALL ` : "")}
                    </div>
                    <div
                        style={{
                            maxWidth: "50%",
                            display: "flex",
                            flexDirection: "row",
                        }}
                    >
                        <div
                            data-cy="table-mode-button"
                            className={"tableModeBtn"}
                            onClick={() =>
                                setVoteCountMode(
                                    voteCountMode === "QUESTION"
                                        ? "USER"
                                        : "QUESTION"
                                )
                            }
                        >
                            {voteCountMode === "USER"
                                ? "SHOW BY QUESTION"
                                : "SHOW BY USER"}
                        </div>
                    </div>
                </div>

                {voteCountMode === "QUESTION" ? (
                    <VoteCountsTable
                        results={results}
                        usersVotes={usersVotes}
                        factor={factor}
                        setHighlightedFactor={() => {}}
                    />
                ) : (
                    <UserVotesTable
                        usersVotes={usersVotes}
                        results={results}
                        factor={factor}
                        setHighlightedFactor={() => {}}
                    />
                )}

                <div
                    className={
                        "title dropdown score-" +
                        results[factor]?.mode +
                        " " +
                        showComments
                    }
                    onClick={() => setShowComments(!showComments)}
                >
                    {"COMMENTS"}

                    <FontAwesomeIcon
                        icon={showComments ? faCaretUp : faCaretDown}
                        fixedWidth
                    />
                </div>

                {showComments && <Comments comments={comments} />}
            </div>
        </SummaryCardContainer>
    );
}
