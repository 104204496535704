import styled from 'styled-components';

export default styled.div`
     .vote {
        margin-right: 5px;
        padding: 2px 4px 1px 4px;
        background-color: ${(props) => props.theme.votingCellBorder};
        border-radius: 5px;
        font-size: 0.8rem;
    }
    .open {
        background-color: ${(props) => props.theme.labelSuccessBg};
        color: ${(props) => props.theme.labelSuccessColor};
    }
    .closed {
        background-color: ${(props) => props.theme.labelAlertBg};
        color: ${(props) => props.theme.labelAlertColor};
    }
`;
