
import React, { useEffect, useState } from "react";

import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { styled } from '@mui/material/styles';

// Styles
import VoteCommentsContainer from "./VoteComments.styles";

// Interface
import {DealVote, GrowthVoteComment, DealTeamMember} from "assets/interfaces/growth_voting";

// Components
import ButtonExtraSmall from "components/Common/ButtonExtraSmall/ButtonExtraSmall";
import EditCommentModal from "components/Screens/GrowthVoting/VoteDetail/VoteComments/EditCommentModal/EditCommentModal";

// Service
import { ApiHandler } from "services/api_handler";

// Context
import {useSettings} from "context/Settings.context";
import {useGrowthDeal} from "context/growth/GrowthDeal.context";
import { useAuth } from "context/Auth.context";


// Fontawsome
import { faChevronDown, faPlus, faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// utils
import { formatDate } from "utils/date.utils";


interface Props {
    vote: DealVote;
    voteTeamMembers: DealTeamMember[]
}

const URL_DEAL_VOTES = "api/growth_voting/votes";

const CustomAccordion = styled(Accordion)(({ theme }) => {
    return {
      boxShadow: 'none', // this styles directly apply to accordion
      border: `1px solid ${theme.palette.grey[200]}`,
      borderRadius: '0px',
      '.MuiAccordionDetails-root': {
        borderRadius: '0px',
      },
      '.MuiAccordionSummary-root': {
        padding: '0 10px',
        minHeight: '30px',
        maxHeight: '30px',
        backgroundColor: theme.palette.grey[200],
        borderRadius: '0px',
      }, // this apply to Summary
    };
  });

const VoteComments = ({vote, voteTeamMembers}: Props) => {
    const { setShowLoadingToast, setToast } = useSettings();
    const [comments, setComments] = useState<GrowthVoteComment[]>([]);
    // selected comment
    const [selectedComment, setSelectedComment] = useState<GrowthVoteComment>({vote_id: "", team_member_id: "", comment: ""});
    // Comments already loaded or not
    const [loaded, setLoaded] = useState<boolean>(false);
    const [dirty, setDirty] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    // Vote Team Member details of the current user
    const [teamMember, setTeamMember] = useState<DealTeamMember>();

    const { user } = useAuth();

    const fetchComments = async () => {
        if (loaded && !dirty) return;
        const url = `${URL_DEAL_VOTES}/${vote.id}/comments`;

        const resp = await new ApiHandler(url, setShowLoadingToast, setToast).get();

        setComments(resp.data);
        setLoaded(true);
        setDirty(false);
        setSelectedComment({vote_id: "", team_member_id: "", comment: ""});
    }

    const showComments = (expanded: boolean) => {
        if (expanded && !loaded) {
            fetchComments();
        }
    }

    const getMemberByUser = () => {
        return voteTeamMembers.find((member) => member.user_id === user.id);
    }

    const addNewComment = () => {
        setSelectedComment({
            comment: "",
            team_member_id: teamMember?.id ? teamMember.id : "",
            vote_id: vote?.id ? vote.id : "",
        });

        setShowModal(true);
    }

    const editComment = (comment: GrowthVoteComment) => {
        setSelectedComment(comment);
        setShowModal(true);
    }

    const addTeamMemberToComment = () => {
        comments.forEach((comment) => {
            const member = voteTeamMembers.find((member) => member.id === comment.team_member_id);
            comment.team_member = member;
        });
    }

    useEffect(() => {
        if (!voteTeamMembers) return;
        const teamMember = getMemberByUser();

        if (teamMember) {
            setTeamMember(teamMember);
        }
    }, [voteTeamMembers]);

    useEffect(() => {
        addTeamMemberToComment();
    }, [comments]);

    useEffect(() => {
        if (dirty) {
            fetchComments();
        }
    }, [dirty]);

    return (
        <VoteCommentsContainer>
            {showModal && selectedComment && (
                        <EditCommentModal
                            showModal={showModal}
                            setShowModal={setShowModal}
                            comment={selectedComment}
                            setComment={setSelectedComment}
                            setDirty={setDirty}
                        />
                    )}
            <CustomAccordion onChange={(e, expanded) => showComments(expanded)}>
                <AccordionSummary id="panel-header" aria-controls="panel-content" expandIcon={<FontAwesomeIcon className="icon" icon={faChevronDown} fixedWidth />}>
                    <div className={"header"}>
                        <div>Comments</div>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <div className={"comments-header"}>
                        <ButtonExtraSmall
                            className="add-comment"
                            text={"Add Comment"}
                            click={() => addNewComment()}
                            icon={faPlus}
                        />
                        {comments.length == 0 && <div className="no-result">No Comment</div>}
                        {comments.length > 0 && <div>{comments.length} Comments</div>}
                    </div>
                    {comments.length > 0 ? (
                        comments.map((comment, index) => (
                            <div className="comment" key={index} >
                                <div className={"comment-header"}>
                                    {comment?.created && <div>{formatDate(new Date(comment.created))}</div>}
                                    {comment?.team_member && <div>{comment.team_member.name}</div>}
                                    {comment?.team_member_id && comment?.team_member_id === teamMember?.id &&
                                        <div><ButtonExtraSmall
                                            className="edit-comment"
                                            color={"secondary"}
                                            text={"Edit Comment"}
                                            click={() => editComment(comment)}
                                            icon={faPencil}
                                        /></div>
                                    }
                                </div>
                                <div className={"comment-body"}>{comment.comment}</div>
                                {comment?.updated && <div>{formatDate(new Date(comment.updated))}</div>}
                            </div>
                        ))
                    ): ""}
                </AccordionDetails>
            </CustomAccordion>
        </VoteCommentsContainer>
    );
};

export default VoteComments;
