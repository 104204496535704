import styled from "styled-components";

export default styled.div`
    display: flex;
    flex-direction: column;

    .title {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 100%;
        padding: 20px 0;

        font-size: 30px;
        color: ${(props) => props.theme.pageTitleText};
    }

    .body {
        display: flex;
        flex-direction: column;

        padding: 0 20px;
    }
`;
