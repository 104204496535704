import styled from "styled-components";

export default styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    .form-row {
        display: flex;
        flex-direction: row;

        margin: 20px -10px 0 -10px;

        .dateSelect {
            margin: 0 10px 10px 10px;
        }
    }

    .MuiCheckbox-colorPrimary {
        svg {
            color: ${(props) => props.theme.pageTitleText};
        }
    }

    .button {
        margin: 10px 0;
    }

    @media screen and (max-width: 600px) {
        .form-row {
            flex-direction: column;
            padding-right: 20px;

            .dateSelect {
                width: 100%;

                .dateBox {
                    min-width: 100%;
                    max-width: 100%;
                }
            }
        }
    }
`;
