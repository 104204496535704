import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// QueryString
import queryString from "query-string";

// Components
import Button from "components/Common/Button/Button";
import AdHocHeader from "components/Screens/BQMQ/Voting/AdHocHeader/AdHocHeader";
import VotingComment from "components/Screens/BQMQ/Voting/VotingComment/VotingComment";
import VotingRow from "components/Screens/BQMQ/Voting/VotingRow/VotingRow";

// Utils
import {
    QUESTIONMAP,
    allFactorsList,
    getUserAnswerHistory,
} from "utils/bqmq.utils";
import { applyTimeZoneOffset } from "utils/common.utils";
import * as Database from "utils/database.utils";
import { getResults, getUserPreviousVotes, getQuestionPreviousUserVote } from "utils/bqmq.utils";

// Context
import { useAuth } from "context/Auth.context";
import { useBQMQ } from "context/BQMQ.context";
import { useSettings } from "context/Settings.context";

// Styles
import BlankVotingPageContainer from "./BlankVotingPage.styles";

// Logger
import Logger from "utils/logger.utils";
const Log = new Logger();
Log.init("BQMQ: Session");

interface Question {
    id: number;
    text: string;
    choices: { text: string; id: number }[];
    factor: { text: string };
}

export default function BlankVotingPage() {
    const history = useHistory();
    const { user } = useAuth();
    const { setToast, setShowLoadingToast } = useSettings();
    const { allQuestionList, myVotes, setMyVotes, getDropDowns } = useBQMQ();
    const [sendingAnswers, setSendingAnswers] = useState(false);
    const [dates, setDates] = useState<any>(null);
    const [shouldUpdate, setShouldUpdate] = useState<boolean>(false);
    const companyName = queryString.parse(window.location.search).company_name;
    const companyID = queryString.parse(window.location.search).company_id;
    const sessionID = queryString.parse(window.location.search).session_id;
    const date = queryString.parse(window.location.search).date;
    const isEditing = queryString.parse(window.location.search, {
        parseBooleans: true,
    }).editing;

    const [sessionCoreResults, setSessionCoreResults] = useState<any>(null);
    const [previousVotes, setPreviousVotes] = useState<any>(null);
    const [sessionAllResults, setSessionAllResults] = useState<any>(null);
    const { showFullUserList, setDefaultChoices } = useBQMQ();
    const [previousUserVotes, setPreviousUserVotes] = useState<any>(null);
    const [isFormValid, setIsFormValid] = useState<boolean>(false);
    const [sectionCompleteStatus, setSectionCompleteStatus] = useState<{ [key: string]: boolean }>({
        BQ: false,
        MQ: false,
        FADE: false,
        MQSTAR: false
    })

    const sectionsToComplete = Object.entries(sectionCompleteStatus).map(([key, value]) => key)

    const updateSectionCompletionStatus = (section: string) => {
        const questionsInSection = allQuestionList.filter(
            (question: Question) => question.factor.text === section && !question.text.includes('comment')
        );
    
        const isCompleted = questionsInSection.every((question: Question) => {
            return myVotes[question.id] !== undefined && myVotes[question.id] !== null;
        });
    
        setSectionCompleteStatus(prevState => ({
            ...prevState,
            [section]: isCompleted,
        }));
    
        validateForm();
    };
    
    const validateForm = () => {
        const hasIncompleteSection = Object.keys(sectionCompleteStatus).some(section => {
            const questionsInSection = allQuestionList.filter(
                (question: Question) => question.factor.text === section && !question.text.includes('comment')
            );
    
            const isStarted = questionsInSection.some((question:Question) => myVotes[question.id] !== undefined && myVotes[question.id] !== null);
            const isCompleted = questionsInSection.every((question:Question) => myVotes[question.id] !== undefined && myVotes[question.id] !== null);
    
            return isStarted && !isCompleted;
        });
    
        setIsFormValid(!hasIncompleteSection);
    };

    const prepareVotePayload = () => {
        const votes = [];
        for (const [key, value] of Object.entries(myVotes)) {
            if (value) {
                const question = allQuestionList.find(
                    (question: any) => question.id === key
                );
                let newValue = (value as string).toString().toLowerCase();
                if (newValue.length > 1) {
                    newValue =
                        newValue.charAt(0).toUpperCase() + newValue.slice(1);
                }
                const commentChoice = question.choices.find(
                    (choice: any) => choice.text === "Comment"
                );
                const choice = question.choices.find(
                    (choice: any) => choice.text === newValue
                );
                const vote = {
                    analyst_id: user.id,
                    question_id: key,
                    choice_id: choice ? choice.id : commentChoice.id,
                    session_id: sessionID,
                    comment: choice?.text === "Comment" ? "" : value,
                };
                votes.push(vote);
            }
        }
        return votes;
    };

    const saveVotes = async (update: boolean) => {
        setSendingAnswers(true);
        setShowLoadingToast({ show: true, message: "SAVING ANSWERS" });

        const votes = prepareVotePayload();
        const res = await Database.put("api/bqmq_voting/answers/update", votes);

        if (res.ok) {
            history.replace(
                `result/company?session_id=${sessionID}&company_name=${companyName}&date=${date}`
            );
            setMyVotes({});
            setToast({
                show: true,
                type: "success",
                message: "Your answers have been saved",
            });
            getDropDowns();
        } else {
            setSendingAnswers(false);
            setToast({
                show: true,
                type: "error",
                message: "There was an error saving your answers",
            });
        }
        setShowLoadingToast({ show: false, message: "SAVING ANSWERS" });
    };

    useEffect(() => {
        setShowLoadingToast({ show: true, message: "Loading" });
        getUserAnswerHistory(user.id, sessionID, setDates, setMyVotes);
        setShowLoadingToast({ show: false, message: "Loading" });
    }, []);

    useEffect(() => {
        getResults(
            companyID,
            sessionID,
            setDefaultChoices,
            setSessionAllResults,
            setSessionCoreResults,
            setPreviousVotes
        );
    }, []);

    useEffect(() => {
        if (sessionAllResults) {
            getUserPreviousVotes(previousVotes, setPreviousUserVotes);
        }
    }, [previousVotes, sessionAllResults]);

    useEffect(() => {
        validateForm();
    }, [myVotes, allQuestionList]);

    useEffect(() => {
        sectionsToComplete.forEach(section => {
            updateSectionCompletionStatus(section);
        });
    }, [myVotes]);
    
    const SectionHeader = ({ title, dates }: { title: string; dates: string }) => (
        <h3 className="sectionTitle">
            {dates && `${title.toUpperCase()} - Last updated: ${applyTimeZoneOffset(dates)}`}
        </h3>
    );

    const getOrderFromQuestionMap = (questionText: string): number => {
        const sectionKey = Object.keys(QUESTIONMAP).find(key => QUESTIONMAP[key][questionText]);
        return sectionKey ? QUESTIONMAP[sectionKey][questionText].order : 0;
    };

    const renderVotingRow = (question: Question) => {
        const sectionKey = Object.keys(QUESTIONMAP).find(key => QUESTIONMAP[key][question.text]);
        const sectionName = question.factor.text;
    
        if (question.choices.map((obj: any) => obj.text).some((value: string) => ["1", "2", "3", "4", "5"].includes(value))) {
            return (
                <VotingRow
                    questionID={question.id.toString()}
                    question={sectionKey ? QUESTIONMAP[sectionKey][question.text].label : ''}
                    previousVote={getQuestionPreviousUserVote(previousUserVotes, question.id.toString())}
                    disabled={sendingAnswers}
                    updateSectionCompletionStatus={() => updateSectionCompletionStatus(sectionName)}
                />
            );
        }
        return null;
    };
    
    const renderAgreeDisagreeRow = (question: Question) => {
        const sectionKey = Object.keys(QUESTIONMAP).find(key => QUESTIONMAP[key][question.text]);
        const sectionName = question.factor.text;
    
        if (question.choices.map((obj: any) => obj.text).some((value: string) => ["Agree", "Disagree", "Neutral"].includes(value))) {
            return (
                <VotingRow
                    questionID={question.id.toString()}
                    question={sectionKey ? QUESTIONMAP[sectionKey][question.text].label : ''}
                    previousVote={getQuestionPreviousUserVote(previousUserVotes, question.id.toString())}
                    agree
                    disabled={sendingAnswers}
                    updateSectionCompletionStatus={() => updateSectionCompletionStatus(sectionName)}
                />
            );
        }
        return null;
    };

    const renderVotingComment = (question: Question) => {
        const sectionKey = Object.keys(QUESTIONMAP).find(key => QUESTIONMAP[key][question.text]);
        const sectionName = question.factor.text;
    
        if (question.choices.map((obj: any) => obj.text).includes("Comment")) {
            return (
                <VotingComment
                    questionID={question.id.toString()}
                    label={sectionKey ? QUESTIONMAP[sectionKey][question.text].label : ''}
                    // disabled={!sectionCompleteStatus[sectionName] || sendingAnswers}
                    // ENABLE THE ABOVE LINE IF YOU WANT COMMENT SECTION TO BE ENABLED ONLY IF SECTION COMPLETE
                    disabled={sendingAnswers} 
                />
            );
        }
        return null;
    };
    const ActionRow = ({ children }: { children: React.ReactNode }) => (
        <div className="actionRow">{children}</div>
    );

    const isEverySectionUncompleted = Object.values(sectionCompleteStatus).every(status => status === false)

    return (
        <BlankVotingPageContainer className="pageContainer">
            <AdHocHeader isEditing={isEditing} session={{ company_name: companyName }} />
    
            {allFactorsList.map((factor: any, index: number) => {
                const filteredQuestions = allQuestionList
                    ?.filter((question: Question) => question.factor.text === factor.short_name)
                    ?.sort((a: Question, b: Question) => {
                        const orderA = getOrderFromQuestionMap(a.text);
                        const orderB = getOrderFromQuestionMap(b.text);
                        return orderA - orderB;
                    });
    
                return (
                    <React.Fragment key={`${factor.name}_${index}`}>
                        <SectionHeader
                            title={factor.name}
                            dates={dates}
                        />
                        
                        {filteredQuestions.map((question: Question, index: number) => (
                            <React.Fragment key={`${question.id}_${index}`}>
                                {renderVotingRow(question)}
                                {renderAgreeDisagreeRow(question)}
                                {renderVotingComment(question)}
                            </React.Fragment>
                        ))}
    
                        <hr />
                    </React.Fragment>
                );
            })}
    
            <ActionRow>
                <Button
                    text="SAVE"
                    click={() => saveVotes(shouldUpdate)}
                    color="primary"
                    disabled={!isFormValid || sendingAnswers || isEverySectionUncompleted}
                />
            </ActionRow>
        </BlankVotingPageContainer>
    );
}
