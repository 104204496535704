import styled from "styled-components";
import colors from "assets/styles/colors";

export default styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;

    .menu {
        position: sticky;
        top: 0;
        display: flex;
        flex-direction: column;
        min-width: 250px;
        padding: 20px 0 0 30px;
        height: fit-content;
        border-left: 2px solid ${colors.teal60};

        .item {
            display: flex;
            flex-direction: column;
            font-size: 16px;
            color: ${colors.textGrey};
            margin-bottom: 5px;

            :hover {
                cursor: pointer;
                color: ${colors.accentBlue};
            }

            &.active {
                color: ${colors.accentRed};
            }
        }
    }

    @media screen and (max-width: 1050px) {
        display: none;
    }
`;
