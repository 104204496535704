import colors from "assets/styles/colors";

export default {
    tabsBottomBorder: "white",
    tabItemText: "white",

    tabItemBorderActive: colors.accentOrange,
    tabItemTextActive: colors.accentOrange,

    tabItemBorderHover: "white",
    tabItemBackgroundHover: "white",
    tabItemTextHover: "black",
};
