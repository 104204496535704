import { useEffect, useState } from "react";

// Styles
import AddIncomingFileModalContainer from "./AddIncomingFileModal.styles";

// Utils
import { useSettings } from "context/Settings.context";
import * as Database from "utils/database.utils";

// Components
import Button from "components/Common/Button/Button";
import Modal from "components/Common/Modal/Modal";
import FileUpload from "components/Common/FileUpload/FileUpload";
import Select from "components/Common/Select/Select";

interface AddInputFileModalProps {
    setShowModal: Function;
}

interface FileType {
    short_code: string,
    name: string,
    provider: string,
    file_name: string,
    file_type: string,
}

interface SuccessResponse {
    original_file_name: string,
    ingest_file_type: string,
    provider: string,
    report_date: string,
}

const AddInputFileModal = ({
    setShowModal,
}: AddInputFileModalProps) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [fileTypes, setFileTypes] = useState<FileType[]>([]);
    const [selectedShortCode, setSelectedShortCode] = useState<string>("");
    const {setToast, setShowLoadingToast } = useSettings();
    const [selectedFile, setSelectedFile] = useState<any>(null);
    const [successResponse, setSuccessResponse] = useState<SuccessResponse | null>(null);

    const isValidUpload = () => {
       if (!selectedShortCode) {
          setToast({
                type: "error",
                show: true,
                message: `Please select a file type`,
            });
          return false;
       }

       if (!selectedFile) {
           setToast({
               type: "error",
               show: true,
               message: `Please select a file`,
           });
           return false;
       }

       return true;
    }

    const uploadFile = async () => {
        if (!isValidUpload()) {
            return;
        }

        setLoading(true);

        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("provider_short_code", selectedShortCode);

        let res = await Database.postFile("api/nav/incoming_files/file_ingest/", formData);
        let data = await res.json();

        setLoading(false);

        if (res.ok) {
            setToast({
                type: "success",
                show: true,
                message: "The file successfully uploaded and ingested",
            });

            setSuccessResponse({
                original_file_name: data.original_file_name,
                ingest_file_type: data.ingest_file_type,
                provider: data.provider,
                report_date: data.report_date,
            })

            setLoading(false)
        } else if (res.status == 400) {
            setToast({
                type: "error",
                show: true,
                message: "Invalid File"
            });

            setLoading(false)
        } else {
            setToast({
                type: "error",
                show: true,
                message: "Unknown error during the file upload",
            });

            setLoading(false)
        }

        setSelectedFile(null);
        setSelectedShortCode("");
    }

    const fetchInputTypes = async () => {
        let res = await Database.get(`api/nav/incoming_files/file_types/`);

        if (res.ok) {
            let response = await res.json();
            setFileTypes(response.file_types)
        } else {
           setToast({
                type: "error",
                show: true,
                message: `Error while collecting the file types`,
            });
        }
    };

    const uploadAnotherFile = () => {
        setSelectedFile(null);
        setSelectedShortCode("");
        setSuccessResponse(null);
    }

    useEffect(() => {
        fetchInputTypes();
    }, []);

    return (
        <AddIncomingFileModalContainer>
            <Modal loading={loading} width={"58vw"}>
                <div className="main">
                    <div className="text">
                        <h3>Upload File</h3>
                    </div>

                    {!successResponse && (
                        <>
                            <div className={"file-type-container"}>
                                <Select
                                    filterName={(selectedShortCode) ? selectedShortCode : "Select File Type"}
                                    value={selectedShortCode}
                                    setValue={setSelectedShortCode}
                                    options={fileTypes.map((fileType: FileType) => {
                                        return {
                                            value: fileType?.short_code,
                                            label: fileType?.name,
                                        };
                                    })}
                                    showAll={false}
                                />
                            </div>
                            <div className={"upload-container"}>
                                <FileUpload  selectedFile={selectedFile} setSelectedFile={setSelectedFile}/>
                            </div>
                        </>
                    )}

                    {successResponse && (
                        <div className={"upload-success"}>
                            <div className={"success-message"}>
                                <h3>File successfully uploaded and ingested</h3>
                            </div>
                            <div className={"success-response"}>
                                <p><span className={"name"}>Original File Name:</span> {successResponse.original_file_name}</p>
                                <p><span className={"name"}>Ingest File Type:</span> {successResponse.ingest_file_type}</p>
                                <p><span className={"name"}>Provider:</span>  {successResponse.provider}</p>
                                <p><span className={"name"}>Report Date:</span>  {new Date(successResponse.report_date).toLocaleDateString()}</p>
                            </div>
                            <div className={"upload-new-file"}>
                               <Button
                                   text="UPLOAD ANOTHER FILE"
                                   click={uploadAnotherFile}
                               />
                            </div>
                        </div>
                    )}
                </div>

                <div className="actions">
                    <Button
                        text="CANCEL"
                        color="flat"
                        click={() => setShowModal(false)}
                    />
                    {!successResponse && (<Button
                        text="UPLOAD"
                        click={uploadFile}
                    />)}
                </div>




            </Modal>
        </AddIncomingFileModalContainer>
    );
};
export default AddInputFileModal
