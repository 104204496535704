import { useState } from "react";

// Styles
import FeeCalculationsContainer from "./FeeCalculations.styles";

// Components
import TableCell from "components/Common/TableCell/TableCell";
import Table from "components/Common/Table/Table";
import SearchOptions from "components/Common/SearchOptions/SearchOptions";

interface FeeCalculationsProps {
    url: string;
    fund: string;
}

const FeeCalculations = ({ url, fund }: FeeCalculationsProps) => {
    const [filterQuery, setFilterQuery] = useState<string>("");

    const columns = [
        {
            id: 1,
            title: "METRIC",
            sortable: true,
            value: "metric",
            cell: (data: any) => <TableCell data={data.metric} />,
            show: true,
            fullDataRow: true,
        },
        {
            id: 2,
            title: "VALUE",
            sortable: true,
            value: "value",
            cell: (data: any) => <TableCell data={data.value} />,
            show: true,
            fullDataRow: true,
        },
    ];

    const filterOptions = [
        {
            id: 1,
            name: "Select Series",
            value: "series",
            type: "DROPDOWN",
            options: [
                { label: "Temp1", value: "TEST1" },
                { label: "Temp2", value: "TEST2" },
            ],
        },
        {
            id: 2,
            name: "Select Shareholder",
            value: "shareholder",
            type: "DROPDOWN",
            options: [
                { label: "Temp1", value: "TEST1" },
                { label: "Temp2", value: "TEST2" },
            ],
        },
    ];

    return (
        <FeeCalculationsContainer>
            {/* <SearchOptions
                    setFilterQuery={setFilterQuery}
                    options={filterOptions}
                />
                <Table
                    columns={columns}
                    url={url}
                    filterQuery={filterQuery}
                    defaultSortKey="metric"
                    defaultSortOrder="DESC"
                /> */}
            <iframe
                src={
                    fund === "delaware"
                        ? "https://app.powerbi.com/reportEmbed?reportId=dab34e16-8e0b-4a27-8321-12d08e14cfbc&autoAuth=true&ctid=bf4450a2-bfcb-472d-8c40-1425eafb1a3b&filterPaneEnabled=false"
                        : ""
                }
                width="100%"
                height="100%"
                allowFullScreen
            />
        </FeeCalculationsContainer>
    );
};
export default FeeCalculations;
