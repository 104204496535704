import { useState } from "react";
import { useHistory } from "react-router-dom";

// Material UI
import Tooltip from "@mui/material/Tooltip";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faEye } from "@fortawesome/free-solid-svg-icons";

// Components
import PageTitle from "components/Common/PageTitle/PageTitle";
import SearchOptions from "components/Common/SearchOptions/SearchOptions";
import Table from "components/Common/Table/Table";
import SummarySheetCounts from "components/Screens/Data/SummarySheets/SummarySheetCounts/SummarySheetCounts";
import TableCell from "components/Common/TableCell/TableCell";

// Utils
import { toDateAndTimeSeconds } from "utils/general.utils";

// Data
import { methodOptions } from "assets/data/lists";

// Styles
import SummarySheetListContainer from "./SummarySheetList.styles";

type Props = {
    apiUrl: string;
    region: string;
};

const SummarySheet: React.FC<Props> = ({ apiUrl, region }) => {
    const history = useHistory();

    const [filterQuery, setFilterQuery] = useState<string>("");

    const columns = [
        {
            id: 1,
            title: "EXTRACTION DATE",
            sortable: true,
            value: "extraction_datetime",
            cell: (data: Date) => (
                <p className="capitalize">{toDateAndTimeSeconds(data)}</p>
            ),
            show: true,
        },
        {
            id: 2,
            title: "LAST MODIFIED",
            sortable: true,
            value: "last_modified_datetime",
            cell: (data: Date) => (
                <p className="capitalize">{toDateAndTimeSeconds(data)}</p>
            ),
            show: true,
        },
        {
            id: 3,
            title: "MODIFIED BY",
            sortable: true,
            value: "last_modified_by",
            cell: (data: string) => (
                <TableCell styleTypes="capitalize" data={data} />
            ),
            show: true,
        },
        {
            id: 4,
            title: "",
            sortable: false,
            value: "model_status",
            cell: (data: any) => (
                <SummarySheetCounts
                    focus={data.num_focus_list_companies}
                    portfolio={data.num_portfolio_companies}
                    error={data.num_errors}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 5,
            title: "",
            sortable: false,
            value: "id",
            cell: (id: string) => (
                <Tooltip title={"VIEW"}>
                    <div className="viewIcon">
                        <FontAwesomeIcon
                            icon={faArrowRight}
                            fixedWidth
                            onClick={() =>
                                history.push(
                                    `/data/summary-sheet?id=${id}&region=${region}`
                                )
                            }
                        />
                    </div>
                </Tooltip>
            ),
            show: true,
        },
    ];

    const filterOptions = [
        { id: 1, name: "Extracted On", value: "date", type: "DATE" },
    ];

    return (
        <SummarySheetListContainer>
            <SearchOptions
                setFilterQuery={setFilterQuery}
                options={filterOptions}
            />

            <Table
                url={apiUrl}
                columns={columns}
                filterQuery={filterQuery}
                defaultSortKey="extraction_datetime"
                defaultSortOrder="DESC"
                pagination
                large
                emptyText="NO SUMMARY SHEETS"
            />
        </SummarySheetListContainer>
    );
};

export default SummarySheet;
