import styled from "styled-components";

export default styled.div`
    flex-direction: column;
    display: flex;
    flex-grow: 1;

    .metadata {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .topGrid {
        margin-bottom: 30px;
    }

    .info {
        flex-direction: column;
        margin: 0 0 20px 0;
        display: flex;

        label {
            font-size: 14px;
            color: #777777;
        }

        p {
            font-size: 18px;
            letter-spacing: 1px;
        }
    }
    .tabItem {
        .warningCounts {
            margin-left: 5px;
        }
        :hover {
            .warningCounts {
                margin-top: 0;

                svg {
                    margin-top: 2px;
                }
            }
        }
    }
`;
