import { SeparateAccountsRow } from "./types";

const addCalculatedFeesFields = (data: SeparateAccountsRow[]) => {
    data.forEach((account: SeparateAccountsRow) => {
        account.variance_1 = account.variance_1 ? account.variance_1 : 0;
        account.variance_2 = account.variance_2 ? account.variance_2 : 0;
        account.variance_3 = account.variance_3 ? account.variance_3 : 0;
        account["variance_%_1"] = !isNaN(account.variance_1 / account.ioo_1)
            ? ((account.variance_1 / account.ioo_1) * 100).toFixed(0)
            : "0";
        account["variance_%_2"] = !isNaN(account.variance_2 / account.ioo_2)
            ? ((account.variance_2 / account.ioo_2) * 100).toFixed(0)
            : "0";
        account["variance_%_3"] = !isNaN(account.variance_3 / account.ioo_3)
            ? ((account.variance_3 / account.ioo_3) * 100).toFixed(0)
            : "0";
        account.variance =
            account.management_fee - account.management_fee_billed;
        account.updated_annual_fee =
            (account.administration_fee + account.annual_fee_rate) *
            (1 + account.uk_rpi);
    });
    return data;
};

export default addCalculatedFeesFields;
