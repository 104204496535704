// Styles
import { toDate } from "utils/general.utils";
import CommentsContainer from "./Comments.styles";

type Props = { comments: UserComment[] };

const commentCell = (comment: UserComment) => {
    return (
        <div className={"comment"} key={comment.user}>
            <div data-cy="comment-name" className={"name"}>
                {comment.user === "0" ? null : (
                    <div>
                        {`${comment.user}`}{" "}
                        <span>{`${toDate(comment.comment.date)}`}</span>{" "}
                    </div>
                )}
            </div>
            <div data-cy="comment-answer" className={"answer"}>
                {comment.comment.value === "0" ? null : comment.comment.value}
            </div>
        </div>
    );
};

export default function Comments({ comments }: Props) {
    return (
        <CommentsContainer className={"comments"}>
            {comments?.length > 0 ? (
                comments
                    .sort((a: UserComment, b: UserComment) =>
                        a.user.localeCompare(b.user)
                    )
                    .map((comment: UserComment) =>
                        comment.comment.value === "0"
                            ? null
                            : commentCell(comment)
                    )
            ) : (
                <div className={"noComments"}>
                    <p>NO COMMENTS</p>
                </div>
            )}
        </CommentsContainer>
    );
}
