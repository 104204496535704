// MaterialUI
import { Tooltip } from "@mui/material";

import queryString from "query-string";
import { useHistory } from "react-router-dom";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faChair, faUser } from "@fortawesome/free-solid-svg-icons";

// Components
import PageTitle from "components/Common/PageTitle/PageTitle";

// Utils
import { toDate, toDateAndTime } from "utils/general.utils";

import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

// Styles
import AdHocHeaderContainer from "./AdHocHeader.styles";
import { useBQMQ } from "context/BQMQ.context";

type Props = {
    session: any;
    isEditing?: boolean;
};

export default function AdHocHeader({ session, isEditing }: Props) {
    const history = useHistory();
    const companyID = queryString.parse(window.location.search).company_id;
    const companyName = queryString.parse(window.location.search).company_name;

    const { setMyVotes } = useBQMQ();

    const handleGoBack = () => {
        setMyVotes({});
        localStorage.removeItem("bqmq");
        history.replace(
            `result/company?company_id=${companyID}&company_name=${companyName}`
        );
    };

    return (
        <AdHocHeaderContainer>
            <div className="page-title">
                <PageTitle
                    title={
                        session?.company_name
                            ? session.company_name.toUpperCase()
                            : "LOADING"
                    }
                />
            </div>
            {companyName && isEditing && (
                <div className="back-button" onClick={handleGoBack}>
                    <FontAwesomeIcon size="sm" icon={faArrowLeft} fixedWidth />
                    <div> Back to results </div>
                </div>
            )}

            <div className={"details"}>
                {session?.analyst_name && (
                    <Tooltip title={<h2>ANALYST</h2>}>
                        <div className={"detail"}>
                            <FontAwesomeIcon icon={faUser} fixedWidth />
                            <p>{session.analyst_name}</p>
                        </div>
                    </Tooltip>
                )}

                {session?.host_name && (
                    <Tooltip title={<h2>CHAIR</h2>}>
                        <div className={"detail"}>
                            <FontAwesomeIcon icon={faChair} fixedWidth />
                            <p>{session.host_name}</p>
                        </div>
                    </Tooltip>
                )}

                {session.start_date && (
                    <Tooltip
                        title={
                            <h2>
                                {"MEETING DATE: " +
                                    toDateAndTime(session.start_date)}
                            </h2>
                        }
                    >
                        <div className={"detail"}>
                            <FontAwesomeIcon icon={faCalendar} fixedWidth />
                            <p>
                                {session.start_date
                                    ? toDate(session.start_date)
                                    : "-"}
                            </p>
                        </div>
                    </Tooltip>
                )}
            </div>
        </AdHocHeaderContainer>
    );
}
