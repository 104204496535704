// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCheckCircle,
    faPauseCircle,
    faRetweet,
    faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

// Components
import ActionsTray from "components/Screens/Data/CompanyModel/ActionsTray/ActionsTray";

// Styles
import DetailStatusContainer from "./DetailStatus.styles";

type Props = {
    extractionMetaData: {
        id: string;
        company: string;
        num_errors_critical: number;
        model_status: Status;
        company_id: number;
        archive_folder_path: string;
        processing_datetime: Date;
        extraction_datetime: Date;
    };
    reload: Function;
};

export default function DetailStatus({ extractionMetaData, reload }: Props) {
    let status =
        extractionMetaData.num_errors_critical > 0
            ? "FAI"
            : extractionMetaData.model_status;

    const statusDict = {
        SCS: {
            icon: faCheckCircle,
            text: "SUCCESS",
            color: "blue",
        },
        FAI: {
            icon: faTimesCircle,
            text: "FAILED",
            color: "red",
        },
        HLD: {
            icon: faPauseCircle,
            text: "HOLDING",
            color: "orange",
        },
        IGN: {
            icon: faCheckCircle,
            text: "SUCCESS",
            color: "blue",
        },
        DLT: {
            icon: faTimesCircle,
            text: "DELETED",
            color: "red",
        },
        RPL: {
            icon: faRetweet,
            text: "REPLACED",
            color: "green",
        },
    };

    return (
        <DetailStatusContainer className={statusDict[status].color}>
            <div className="status">
                <FontAwesomeIcon
                    className="statusIcon"
                    icon={statusDict[status].icon}
                    fixedWidth
                />
                <div className="statusText">
                    <h5>{statusDict[status].text}</h5>
                    {status === "IGN" && (
                        <p className="ignoredText">WARNINGS IGNORED</p>
                    )}
                </div>
                <ActionsTray
                    status={status}
                    id={extractionMetaData.id}
                    companyName={extractionMetaData.company}
                    companyID={extractionMetaData.company_id}
                    archiveFolderPath={extractionMetaData.archive_folder_path}
                    extraction_datetime={extractionMetaData.extraction_datetime}
                    processing_datetime={extractionMetaData.processing_datetime}
                    reload={reload}
                />
            </div>
        </DetailStatusContainer>
    );
}
