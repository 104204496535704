import { useEffect, useState } from "react";

// Styles
import CheckCellContainer from "./CheckCell.styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCheckCircle,
    faSync,
    faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

import { CheckCellProps } from "./CheckCell.types";

const CheckCell: React.FC<CheckCellProps> = ({
    enabled,
    companyRefId,
    updateCompanySelection,
    userCompanyMapId,
    updateConfig,
    configId,
    source,
    method,
    description,
    identifier
}) => {
    const [userEnabled, setUserEnabled] = useState<boolean>(enabled);
    const [loading, setLoading] = useState<boolean>(false);

    const setEnabled = () => {
        setUserEnabled(enabled);
        setLoading(false);
    };

    useEffect(() => {
        setEnabled();
    }, [enabled]);

    const handleClick = () => {
        if (updateCompanySelection)
            updateCompanySelection(
                !userEnabled,
                companyRefId,
                userCompanyMapId
            );
        if (updateConfig){
            updateConfig(!userEnabled, configId, source, description, method, identifier);
        }
        setLoading(true);
    };

    return (
        <CheckCellContainer onClick={handleClick}>
            {loading ? (
                <div className="loading">
                    <FontAwesomeIcon
                        className="white"
                        icon={faSync}
                        fixedWidth
                    />
                </div>
            ) : enabled ? (
                <FontAwesomeIcon icon={faCheckCircle} fixedWidth />
            ) : (
                <FontAwesomeIcon
                    className="inactive"
                    icon={faTimesCircle}
                    fixedWidth
                />
            )}
        </CheckCellContainer>
    );
};

export default CheckCell;
