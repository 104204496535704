import { useContext, createContext, useReducer, useEffect } from "react";

// Utils
import { queryDatabase, submitToDatabase } from "services/api";
import {
    mapOptionsToQuestions,
    getOptionChildQuestions,
} from "utils/sfdrEsg.utils";

import { useAuth } from "context/Auth.context";

//Settings
import { useSettings } from "context/Settings.context";

// Logger
import Logger from "utils/logger.utils";
import { useHistory } from "react-router-dom";
import { isObjectEmpty } from "utils/general.utils";
const Log = new Logger();
Log.init("SFDRDataCapture.Context");

const SFDRDataCaptureContext = createContext();

const initialState = {
    isLoading: true,
    analystList: null,
    selectedAnalyst: null,
    companyList: null,
    selectedCompany: null,
    selectedCompanyName: null,
    answers: {},
    answersToUpdate: {},
    questionsWithOptions: [],
    keyQuestionsWithOptions: [],
    childQuestions: [],
    newSubmission: null,
    siblingToUpdate: null,
    submissionId: null,
    urlQuery: {},
};

export const SFDRDataCaptureProvider = ({ children }) => {
    const { setToast, setShowLoadingToast } = useSettings();
    const { user } = useAuth();
    const history = useHistory();

    const [dataCaptureContext, setDataCaptureContext] = useReducer(
        (prev, next) => {
            return { ...prev, ...next };
        },
        initialState
    );

    const {
        siblingToUpdate,
        selectedAnalyst,
        selectedCompany,
        newSubmission,
        answersToUpdate,
        answers,
        childQuestions,
        companyList,
        submissionId,
        urlQuery,
    } = dataCaptureContext;

    const handleSignOff = async () => {
        setShowLoadingToast({
            show: true,
            message: "SIGNING OFF",
        });

        const result = await submitToDatabase(
            "api/sfdr_esg_data_capture/submission/sign-off",
            {
                submission_id: submissionId,
                signed_off_by: user.id,
            }
        );

        const data = await result.json();

        if (result.status == 201) {
            setToast({
                show: true,
                type: "success",
                message: "Signed Off",
            });
        } else {
            setToast({
                show: true,
                type: "error",
                message: "Error on sign off.",
            });
        }

        history.push("/sustainability/sfdr-data-capture/submissions");
    };

    const submitAnswers = async () => {
        let answersToSubmit = newSubmission ? answers : answersToUpdate;

        if (answersToSubmit["d8f858c9-6ce3-4d58-bc04-9182e2a5c5c5"] == "No") {
            answersToSubmit = {
                "d8f858c9-6ce3-4d58-bc04-9182e2a5c5c5": "No",
                "e7ed010d-232b-4174-89b9-8c18d970bfef":
                    answers["e7ed010d-232b-4174-89b9-8c18d970bfef"],
            };
        }

        const answersArray = Object.entries(answersToSubmit).map(
            ([key, value]) => ({
                question_id: key,
                user_id: selectedAnalyst,
                company_id: selectedCompany,
                answer: value,
            })
        );

        const company_name = companyList.find(
            (company) => company.company_id == selectedCompany
        ).company;

        setShowLoadingToast({
            show: true,
            message: "SAVING",
        });

        let result;
        if (newSubmission == true) {
            result = await submitToDatabase(
                "api/sfdr_esg_data_capture/answers",
                {
                    answers: answersArray,
                    selected_analyst: selectedAnalyst,
                    selected_company: selectedCompany,
                    company_name: company_name,
                }
            );
        }
        if (newSubmission == false) {
            result = await submitToDatabase(
                "api/sfdr_esg_data_capture/answers/update",
                {
                    answers: answersArray,
                    selected_analyst: selectedAnalyst,
                    selected_company: selectedCompany,
                    sibling_to_update: siblingToUpdate,
                    company_name: company_name,
                }
            );
        }

        const data = await result.json();

        if (result.status == 201) {
            setToast({
                show: true,
                type: "success",
                message: `${data.message}`,
            });
            localStorage.removeItem(
                `sfdr${selectedAnalyst}+${selectedCompany}`
            );
        } else {
            setToast({
                show: true,
                type: "error",
                message: "Error on sign off.",
            });
        }

        setShowLoadingToast({
            show: false,
            message: "",
        });
    };

    const fetchLatestAnswers = async () => {
        const existingCompanyAnswers = await queryDatabase(
            `/api/sfdr_esg_data_capture/user/${selectedAnalyst}/answers/company/${selectedCompany}/latest`
        );

        let answersList = {};
        if (existingCompanyAnswers.length > 0) {
                answersList = existingCompanyAnswers.reduce(
                    (acc, { question_id, answer }) => {
                        acc[question_id] = answer;
                        return acc;
                    },
                    {}
                );
            }

        return answersList;
    }

    const getCompaniesAndAnalystOptions = async () => {
        const companies = await queryDatabase(
            "/api/sfdr_esg_data_capture/companies?pageSize=1000&page=1&sortKey=company&sortOrder=ASC&filter_current_company_ref_flag=true"
        );

        const users = await queryDatabase("/api/sfdr_esg_data_capture/users");

        setDataCaptureContext({
            companyList: companies,
            analystList: users,
            isLoading: false,
        });
    };

    const deleteEntryFromAnswers = (id) => {
        delete answers[id];
        setDataCaptureContext({
            answers: {
                ...answers,
            },
        });
    };

    const updateAnswersObject = (questionId, value) => {
        setDataCaptureContext({
            answers: {
                ...answers,
                [questionId]: value,
            },
        });
        if (newSubmission == false) {
            const siblingToUpdate = childQuestions.find(
                (question) => question.id !== questionId
            ).id;

            setDataCaptureContext({
                answersToUpdate: {
                    ...answersToUpdate,
                    [questionId]: value,
                },
                siblingToUpdate: siblingToUpdate,
            });
        }
    };

    const createQuestionnaire = async () => {
        const draftInStorage = localStorage.getItem(
            `sfdr${selectedAnalyst}+${selectedCompany}`
        );

        let submissionId;

        if (!urlQuery?.signed_off || !urlQuery.submission_id) {
            submissionId = "latest";
        } else {
            submissionId = urlQuery.submission_id;
        }

        const questions = await queryDatabase(
            "/api/sfdr_esg_data_capture/questions"
        );
        const questionsOptions = await queryDatabase(
            "/api/sfdr_esg_data_capture/questions/options"
        );
        const existingCompanyAnswers = await queryDatabase(
            `/api/sfdr_esg_data_capture/user/${selectedAnalyst}/answers/company/${selectedCompany}/${submissionId}`
        );

        let answersList = {};
        let isNewSubmission = existingCompanyAnswers.length < 1;

        addOrderNumberToQuestions(questions);

        if (
            draftInStorage &&
            draftInStorage !== "{}" &&
            isObjectEmpty(urlQuery)
        ) {
            answersList = JSON.parse(draftInStorage);
        } else {
            if (existingCompanyAnswers.length > 0) {
                answersList = existingCompanyAnswers.reduce(
                    (acc, { question_id, answer }) => {
                        acc[question_id] = answer;
                        return acc;
                    },
                    {}
                );
            }
        }

        const childQuestions = getOptionChildQuestions(
            questions,
            questionsOptions
        );

        const allQuestionsWithOptions = mapOptionsToQuestions(
            questions,
            questionsOptions
        );

        const keyQuestionsWithOptions = allQuestionsWithOptions.slice(0, 2);
        const questionsWithOptions = allQuestionsWithOptions.slice(2);

        setDataCaptureContext({
            keyQuestionsWithOptions: keyQuestionsWithOptions,
            questionsWithOptions: questionsWithOptions,
            allOptions: questionsOptions,
            childQuestions: childQuestions,
            answers: answersList,
            newSubmission: isNewSubmission,
        });
    };

    const addOrderNumberToQuestions = (questions) => {
        let displayed_number = 1
        questions.forEach((question, index) => {
            if (!question.parent_option_id) {
                question.display_order = displayed_number;
                displayed_number++;
            }
        });
    }

    const clearLatestFlag = async () => {
     // remove latest flag at the backend and remove the selected user and company locally
        const result = await submitToDatabase(
            "api/sfdr_esg_data_capture/answers/clear-latest-flag",
            {analyst: selectedAnalyst,
                company: selectedCompany,
            }
        );

        if (result.status == 200) {
            setToast({
                show: true,
                type: "success",
                message: "Questionnaire cleared successfully"
            });
        } else {
            setToast({
                show: true,
                type: "error",
                message: "Failed to clear questionnaire"
            });
        }
    }

    const clearQuestionnaire =  () => {
        localStorage.removeItem(`sfdr${selectedAnalyst}+${selectedCompany}`);
        setDataCaptureContext({
            answersToUpdate: {},
            answers: {},
        });
    };

    // useEffect(() => {
    //     if (answers == "{}") return;
    //     if (selectedAnalyst == null) return;
    //     if (selectedCompany == null) return;
    //     localStorage.setItem(
    //         `sfdr${selectedAnalyst}+${selectedCompany}`,
    //         JSON.stringify(answers)
    //     );
    // }, [answers]);

    useEffect(() => {
        return () => {
            setDataCaptureContext(initialState);
        };
    }, []);

    const sfdrContextData = {
        dataCaptureContext,
        setDataCaptureContext,
        deleteEntryFromAnswers,
        createQuestionnaire,
        clearQuestionnaire,
        getCompaniesAndAnalystOptions,
        submitAnswers,
        updateAnswersObject,
        handleSignOff,
        clearLatestFlag,
        fetchLatestAnswers,
    };

    return (
        <SFDRDataCaptureContext.Provider value={sfdrContextData}>
            {children}
        </SFDRDataCaptureContext.Provider>
    );
};

export const useSFDRDataCapture = () => {
    return useContext(SFDRDataCaptureContext);
};
