import styled from 'styled-components';

export default styled.div`
.deals {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 1px;
    .deal {
        display: contents;

        & > div {
        border-top: 1px solid ${(props) => props.theme.votingCellBorder};
        padding: 15px;
        }
        .name {
            font-weight: bold;
            text-transform: capitalize;

            .created {
                padding-top: 5px;
                font-weight: normal;
                font-size: 0.9rem;
                margin-left: 25px;
            }
        }
        .vote {
            .vote-score {
                display: flex;
                align-items: center;
                .vote-type {
                    font-weight: normal;
                    display: flex;
                }
            }
        }

     }
    .header {
        display: contents;
        & > div {
            padding: 10px 20px;
            border-top: 1px solid ${(props) => props.theme.votingCellBorder};
            background-color: ${(props) => props.theme.secondaryButtonBackground};
            color: ${(props) => props.theme.secondaryButtonColor};
        }
    }
}

`;
