import styled from "styled-components";

export default styled.div`
    flex-direction: column;
    flex-grow: 1;
    display: flex;

    .checkIcon {
        color: ${(props) => props.theme.tableIconHighlight};
    }

    .searchBar {
        .actionBtns {
            margin-left: 10px;
        }
    }
`;
