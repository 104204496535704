// Styles
import MarkdownImageContainer from "./MarkdownImage.styles";

type Props = {
    src: string;
    alt: string;
};

export default function MarkdownImage({ src, alt }: Props) {
    return (
        <MarkdownImageContainer>
            <img src={src} alt={alt} />
        </MarkdownImageContainer>
    );
}
