import colors from "assets/styles/colors";

export default {
    askAdaBackground: colors.darkThemeMain,
    askAdaSeparators: colors.grey80,
    askAdaText: "white",
    askAdaBubbleBackground: "white",
    askAdaBubbleColor: colors.darkAccentBlue,
    askAdaAvatarColor: colors.teal100,
};
