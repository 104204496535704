import colors from "assets/styles/colors";

export default {
    askAdaBackground: colors.grey100,
    askAdaSeparators: colors.grey80,
    askAdaText: colors.grey40,
    askAdaBubbleBackground: "white",
    askAdaBubbleColor: colors.textNavy,
    askAdaAvatarColor: colors.textTeal,
};
