import styled from "styled-components";
import colors from "assets/styles/colors";

export default styled.div`
    display: flex;
    justify-content: center;

    position: fixed;

    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;

    background-color: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(5px);

    z-index: 200;

    .searchModal {
        display: flex;
        flex-direction: column;

        width: 100%;
        max-width: 1200px;

        .searchBox {
            display: flex;
            flex-direction: row;
            align-items: center;

            min-width: 100%;

            min-height: 60px;
            max-height: 60px;

            margin: 20px 0;

            border-bottom: 3px solid #004453;
            padding: 20px 0 20px 10px;

            input {
                display: flex;
                flex-grow: 1;

                border: none;
                outline: none;
                background: none;

                font-size: 40px;

                color: #004453;
            }

            svg {
                display: flex;

                font-size: 35px;

                margin-bottom: 5px;
                margin-right: 10px;

                color: #004453;
            }
        }

        .results {
            display: flex;

            height: 300px;
            width: 100%;
        }
    }

    .closeBtn {
        display: flex;
        justify-content: center;
        align-items: center;

        position: absolute;

        top: 20px;
        left: 20px;

        min-height: 40px;
        max-height: 40px;
        min-width: 40px;
        max-width: 40px;

        background-color: #004453;
        color: white;

        border-radius: 5px;

        cursor: pointer;

        svg {
            font-size: 30px;
        }
    }
`;
