// Styles
import IframeContainer from "./IframeContainer.styles";
type Props = {};

export default function ClimateChronograph({}: Props) {
    return (
        <IframeContainer>
            <div className="iframeContainer">
                <iframe
                    src="https://app.powerbi.com/reportEmbed?reportId=9e5f5266-6e45-4932-8d43-1392570c7583&autoAuth=true&ctid=bf4450a2-bfcb-472d-8c40-1425eafb1a3b&filterPaneEnabled=false" 
                    title="Sustainability vLive (QS)"
                    width="100%"
                    height="100%"
                    allowFullScreen
                    frameBorder={0}
                />
            </div>
        </IframeContainer>
    );
}