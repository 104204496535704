import styled from "styled-components";

export default styled.div`
    .btn-close-container {
        display:flex;
        justify-content: flex-end;
        padding-top: 8px;
    }
    .sectionTitle {
        font-size: 25px;
        letter-spacing: 0.1em;

        color: ${(props) => props.theme.pageTitleText};
        margin-bottom: 10px;
        margin-top: 20px;
    }

    .total {
        text-align: right;
        color: ${(props) => props.theme.pageTitleText};
    }

    .header-actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }

    .pagination {
        padding: 20px;
        border-top: 1px solid ${(props) => props.theme.pageTitleText};
        display: flex;
        justify-content: center;
    }
    .no-content {
        display: flex;
        margin-top: 5px;
        justify-content: center;
        padding: 20px;
    }
`;
