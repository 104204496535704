import React, { useState } from "react";
import { useHistory } from "react-router-dom";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faCog } from "@fortawesome/free-solid-svg-icons";

// Material UI
import Badge from "@mui/material/Badge";

// Components
import MenuItem from "../MenuItem/MenuItem";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import NotificationsDropDownItem from "./NotificationsDropDownItem/NotificationsDropDownItem";
import Can from "components/Common/Can/Can";

// Context
import { useNotifications } from "context/Notifications.context";

// Styles
import NotificationsListContainer from "./NotificationsList.styles";
import { MenuTrayDropDownContainer } from "../MainMenu.styles";

export default function NotificationsList() {
    const history = useHistory();
    const { userNotifications } = useNotifications();
    const [open, setOpen] = useState(false);

    const hasNotifications = userNotifications?.data?.length ? true : false;

    const handleItemClick = (notification: any) => {
        history.push(
            `/notifications?notificationId=${notification.app_notification_id}`
        );
        setOpen(false);
    };

    return (
        <NotificationsListContainer id={"notificationsBtn"}>
            <Badge
                invisible={!hasNotifications}
                color="primary"
                overlap="circular"
                variant="dot"
            >
                <MenuItem
                    text={"NOTIFICATIONS"}
                    isActive={false}
                    icon={<FontAwesomeIcon icon={faBell} fixedWidth />}
                    click={() => setOpen(true)}
                    permissions={["p_read_own_notification_web"]}
                    hideTooltip={open}
                />
            </Badge>

            {open && (
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                    <MenuTrayDropDownContainer id="notificationsTray">
                        <div className="title">
                            <p>NOTIFICATIONS</p>
                            <Can
                                yes={
                                    <FontAwesomeIcon
                                        className={"settingsBtn"}
                                        icon={faCog}
                                        fixedWidth
                                        onClick={() => {
                                            history.push(
                                                "/notifications?tab=settings"
                                            );
                                            setOpen(false);
                                        }}
                                    />
                                }
                                no={null}
                                permissions={["p_list_extractions"]}
                            />
                        </div>

                        <div className="body">
                            {hasNotifications ? (
                                userNotifications?.data.map(
                                    (notification: any) => (
                                        <div
                                            key={notification.id}
                                            className="menu-item"
                                            onClick={() =>
                                                handleItemClick(notification)
                                            }
                                        >
                                            <NotificationsDropDownItem
                                                item={notification}
                                            />
                                        </div>
                                    )
                                )
                            ) : (
                                <div className="loading">
                                    <div className="no-results">
                                        <p>NO NEW NOTIFICATIONS</p>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div
                            className="footer"
                            onClick={() => {
                                history.push("/notifications");
                                setOpen(false);
                            }}
                        >
                            <p>SEE ALL</p>
                        </div>
                    </MenuTrayDropDownContainer>
                </ClickAwayListener>
            )}
        </NotificationsListContainer>
    );
}
