import { useEffect, useState } from "react";

// Material UI
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Popper from "@mui/material/Popper";

// FontAwesome
import { faCaretDown, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Components
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

// Styles
import { SelectContainer, PopUpContainer } from "./Select.styles";

type Props = {
    filterName: string;
    options: Array<Option>;
    value: string | number | null;
    setValue: Function;
    searchable?: boolean;
    disabled?: boolean;
    loadingOptions?: boolean;
    showAll?: boolean;
    label?: string;
};

export default function Select({
    filterName,
    options,
    value,
    setValue,
    searchable,
    disabled,
    showAll = true,
    loadingOptions = false,
    label,
}: Props) {
    const [open, setOpen] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<any>(null);

    const [results, setResults] = useState<Array<Option>>(options || []);
    const [searchString, setSearchString] = useState<string>("");

    const handleClick = (event: React.MouseEvent) => {
        if (disabled) return;
        setSearchString("");
        setResults(options || []);
        setAnchorEl(event.currentTarget);
        setOpen(!open);
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchString(event.target.value);
        setResults(
            options.filter((option) =>
                option.label
                    .toLowerCase()
                    .includes(event.target.value.toLowerCase())
            )
        );
    };

    const handleSelectFilter = (option: Option) => {
        setOpen(false);
        setValue(option.value);
    };

    const getValue = () => {
        let selectedOption = options.find((option) => option.value === value);

        if (selectedOption) {
            return selectedOption.label;
        } else return "Loading";
    };

    const isActive = () => {
        return value || value === 0;
    };

    useEffect(() => {
        setResults(options);
    }, [options]);

    return (
        <>
            {label && <label>{label}</label>}
            <SelectContainer
                style={label ? { marginTop: "10px" } : {}}
                onClick={handleClick}
                className={`${!disabled && "enabled"}`}
            >
                {isActive() ? (
                    <p className="selected">{getValue()}</p>
                ) : (
                    <>
                        <p className={"filterLabel"}>{filterName}</p>
                        {showAll && <p className="value">ALL</p>}
                    </>
                )}
                {!disabled && <FontAwesomeIcon icon={faCaretDown} fixedWidth />}
            </SelectContainer>

            <Popper open={open} anchorEl={anchorEl} style={{ zIndex: 1000 }}>
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                    <PopUpContainer>
                        {searchable && options.length > 0 && (
                            <div className="searchBox">
                                <input
                                    placeholder="Search..."
                                    value={searchString}
                                    onChange={handleSearch}
                                />
                                <FontAwesomeIcon icon={faSearch} fixedWidth />
                            </div>
                        )}
                        <div className="options">
                            {loadingOptions && (
                                <div className="loading">
                                    <LoadingSpinner />
                                </div>
                            )}

                            {results.length > 0 &&
                                results.map((option, index) => (
                                    <div
                                        className={`option`}
                                        onClick={() =>
                                            handleSelectFilter(option)
                                        }
                                        key={index}
                                    >
                                        <p className={option?.color}>
                                            {option.label}
                                        </p>
                                    </div>
                                ))}

                            {results.length === 0 && !loadingOptions && (
                                <div className="noResults">
                                    <p>No Results</p>
                                </div>
                            )}
                        </div>
                    </PopUpContainer>
                </ClickAwayListener>
            </Popper>
        </>
    );
}
