import { useState } from "react";

// Components
import Button from "components/Common/Button/Button";
import Table from "components/Common/Table/Table";
import Modal from "components/Common/Modal/Modal";

// Utils
import * as Database from "utils/database.utils";

// Context
import { useSettings } from "context/Settings.context";

// Styles
import UpdateCompanyModalContainer from "./UpdateCompanyModal.styles";

type Props = {
    oldCompany: any;
    selectedCompany: any;
    reload: Function;
    setShowModal: Function;
};

const UpdateCompanyModal: React.FC<Props> = ({
    oldCompany,
    selectedCompany,
    reload,
    setShowModal,
}: Props) => {
    const { setToast } = useSettings();
    const [loading, setLoading] = useState<boolean>(false);

    const buildData = () => {
        let data = [];

        data.push({
            ...oldCompany,
            ticker: oldCompany.bbg_ticker,
        });

        data.push({
            ...selectedCompany,
            company: selectedCompany.scraped_company,
            gim_subteam: selectedCompany.scraped_team,
            ticker: selectedCompany.scraped_ticker,
            company_model_excel_name: selectedCompany.scraped_excel_model,
        });

        return data;
    };

    const [rows, setRows] = useState<any[]>(buildData());

    const updateCompany = async () => {
        setLoading(true);
        let data = {
            auto_table: selectedCompany.auto_table,
            key_details_id: selectedCompany.key_details_id,
            first_detected_date: selectedCompany.first_detected_date,
            scraped_company: selectedCompany.scraped_company,
            scraped_excel_model: selectedCompany.scraped_excel_model,
            scraped_team: selectedCompany.scraped_team,
            scraped_ticker: selectedCompany.scraped_ticker,
            detected_source: selectedCompany.detected_source,
            asia_flag_occurs: selectedCompany.asia_flag_occurs,
        };

        let res = await Database.put(
            "api/companies/" + oldCompany.company_id,
            data
        );

        if (res.ok) {
            setToast({
                show: true,
                type: "success",
                message: "Company has been updated",
            });

            setShowModal(false);
            reload();
        }
    };

    const columns = [
        {
            id: 1,
            title: "COMPANY",
            sortable: false,
            value: "company",
            cell: (data: string) => (
                <p className={data !== oldCompany.company ? "changed" : ""}>
                    {data}
                </p>
            ),
            show: true,
        },
        {
            id: 2,
            title: "TEAM",
            sortable: false,
            value: "gim_subteam",
            cell: (data: string) => (
                <p className={data !== oldCompany.gim_subteam ? "changed" : ""}>
                    {data}
                </p>
            ),
            show: true,
        },
        {
            id: 3,
            title: "TICKER",
            sortable: false,
            value: "ticker",
            cell: (data: string) => (
                <p className={data !== oldCompany.bbg_ticker ? "changed" : ""}>
                    {data}
                </p>
            ),
            show: true,
        },
        {
            id: 4,
            title: "FILE NAME",
            sortable: false,
            value: "company_model_excel_name",
            cell: (data: string) => (
                <p
                    className={
                        data !== oldCompany.company_model_excel_name
                            ? "changed"
                            : ""
                    }
                >
                    {data}
                </p>
            ),
            show: true,
        },
    ];

    return (
        <UpdateCompanyModalContainer>
            <Modal loading={loading}>
                <div className="main">
                    <div className="text">
                        <h3>CONFIRM UPDATE COMPANY</h3>
                        <p>Are you sure these changes are correct?</p>
                    </div>
                    <Table
                        data={rows}
                        columns={columns}
                        filterQuery={""}
                        defaultSortKey="detected_date"
                    />
                </div>

                <div className="actions">
                    <Button
                        text="CANCEL"
                        color="flat"
                        click={() => setShowModal(false)}
                    />
                    <Button text="UPDATE" click={updateCompany} />
                </div>
            </Modal>
        </UpdateCompanyModalContainer>
    );
};

export default UpdateCompanyModal;
