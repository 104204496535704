import React, { useEffect, useState } from "react";

// Font Awesome
import {
    faCheck,
    faS,
    faSquare,
    faSquareCheck,
    faX,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Styles
import SignOffContainer from "./SignOff.styles";

//Utils
import * as Database from "utils/database.utils";

// Context
import { useSettings } from "context/Settings.context";

// Components
import DropDownMenu from "components/Common/DropDownMenu/DropDownMenu";
import DropDownMenuItem from "components/Common/DropDownMenu/DropDownMenuItem/DropDownMenuItem";
interface SignOffProps {
    data: any;
    signOffUrl: string;
    updateTable: () => void;
    id: string;
    from?: string;
    handleUpdates?: () => void;
}

const SignOff = ({
    from,
    signOffUrl,
    data,
    updateTable,
    id,
    handleUpdates,
}: SignOffProps) => {
    const { setShowLoadingToast, setToast } = useSettings();
    const [anchorEl, setAnchorEl] = useState<any>(null);

    const handleClick = async () => {
        if (!data.signed_off) {
            setShowLoadingToast({
                show: true,
                message: "SIGNING OFF",
            });

            let param = "";
            if (from === "latest") param = "table_id";
            else if (from === "reports") param = "report_id";

            let res = await Database.patch(signOffUrl + `?${param}=${id}`);
            if (res.ok) {
                updateTable();
                setToast({
                    type: "success",
                    message: "Signed Off    ",
                    show: true,
                });
            }
            setShowLoadingToast({
                show: false,
                message: "",
            });
            if (handleUpdates) handleUpdates();
        }
    };

    return (
        <>
            <SignOffContainer
                style={!data.signed_off ? { cursor: "pointer" } : {}}
            >
                <div
                    onClick={(e: React.MouseEvent) => {
                        if (!data.signed_off) setAnchorEl(e.currentTarget);
                    }}
                >
                    <FontAwesomeIcon
                        className="icon"
                        icon={data.signed_off ? faSquareCheck : faSquare}
                        fixedWidth
                    />
                </div>

                <DropDownMenu
                    open={Boolean(anchorEl)}
                    setOpen={setAnchorEl}
                    anchorEl={anchorEl}
                >
                    <DropDownMenuItem
                        icon={<FontAwesomeIcon icon={faCheck} fixedWidth />}
                        text={"SIGN OFF"}
                        click={() => {
                            handleClick();
                            setAnchorEl(!anchorEl);
                        }}
                    />
                    <DropDownMenuItem
                        icon={<FontAwesomeIcon icon={faX} fixedWidth />}
                        text={"CANCEL"}
                        click={() => {
                            setAnchorEl(!anchorEl);
                        }}
                    />
                </DropDownMenu>
            </SignOffContainer>
        </>
    );
};
export default SignOff;
