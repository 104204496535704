import styled from "styled-components";
import colors from "assets/styles/colors";

export default styled.div`
    flex-direction: row;
    display: flex;

    width: 100%;

    user-select: none;

    .docsMain {
        padding-top: 0;
        padding-bottom: 50px;
        padding-left: 30px;
        padding-right: 30px;

        flex-direction: column;
        display: flex;
        flex-grow: 1;
        overflow-y: scroll;

        .loadingSpinner {
            display: flex;
            flex-grow: 1;
        }
    }
`;
