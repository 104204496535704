import { useAuth } from "context/Auth.context";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

interface Props {}

const SFDRDataCaptureRedirect = ({}: Props) => {
    const history = useHistory();
    const { user } = useAuth();

    useEffect(() => {
        if (
            user.permissions.includes(
                "p_can_submit_sfdr_esg_questionnaire_on_behalf"
            )
        ) {
            history.push("/sfdr-data-capture/submissions");
            return;
        }
        if (user.permissions.includes("p_can_submit_sfdr_esg_questionnaire")) {
            history.push("/sfdr-data-capture/form");
            return;
        }
    }, []);

    return <div></div>;
};

export default SFDRDataCaptureRedirect;
