import { useState } from "react";

// Material UI
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Popper from "@mui/material/Popper";

// Utils
import { toDateAndTimeSeconds } from "utils/general.utils";

// Components
import BatchPopUp from "./BatchPopUp/BatchPopUp";

// Styles
import ExtractionBatchContainer from "./ExtractionBatch.styles";

type Props = {
    date: Date;
    companyExtractGroup?: string;
};

export default function ExtractionBatch({ date, companyExtractGroup }: Props) {
    const [open, setOpen] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<any>(null);

    const handleClick = (event: React.MouseEvent) => {
        setAnchorEl(event.currentTarget);
        setOpen(!open);
    };

    const handleClickAway = (event: any) => {
        if (!anchorEl.contains(event.target)) {
            setOpen(false);
        }
    };
    return (
        <>
            <ExtractionBatchContainer onClick={handleClick}>
                <p>{toDateAndTimeSeconds(date)}</p>
            </ExtractionBatchContainer>

            <Popper
                open={open}
                anchorEl={anchorEl}
                disablePortal
                style={{ zIndex: 10 }}
            >
                <ClickAwayListener onClickAway={handleClickAway}>
                    <div>
                        <BatchPopUp
                            companyExtractGroup={companyExtractGroup!}
                        />
                    </div>
                </ClickAwayListener>
            </Popper>
        </>
    );
}
