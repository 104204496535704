import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// Components
import HomeList from "../../../Home/HomeList/HomeList";

// Context
import { useBQMQ } from "context/BQMQ.context";
import { useSettings } from "context/Settings.context";
import { addTableId } from "utils/bqmq.utils";

// Styles
import PendingMyVoteListContainer from "./PendingMyVoteList.styles";
import PendingMyVoteRow from "./PendingMyVoteRow/PendingMyVoteRow";
type Props = {
    filterQuery: string;
    filterResults: Function;
};
export default function PendingMyVoteList({
    filterQuery,
    filterResults,
}: Props) {
    const history = useHistory();
    const { pendingSessions } = useBQMQ();
    const { reloadHomePage } = useSettings();
    const [filteredData, setFilteredData] = useState<any>([]);
    const [data, setData] = useState<BQMQSession[]>([]);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        if (data) {
            const filteredResults = filterResults(data);
            setFilteredData(filteredResults);
            setTotal(filteredResults.length);
        }
    }, [data, filterQuery]);

    useEffect(() => {
        setData(addTableId(pendingSessions));
    }, [reloadHomePage, pendingSessions]);

    return (
        <PendingMyVoteListContainer>
            <HomeList
                data={filteredData}
                row={(row: any) => (
                    <PendingMyVoteRow pendingSessionData={row} />
                )}
                title={"COMPANIES PENDING MY VOTE"}
                count={total}
                countColor={""}
                seeAll={() => history.push("/voting/focus-list")}
                maxRows={1000}
            />
        </PendingMyVoteListContainer>
    );
}
