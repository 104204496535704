import { useState } from "react";

// Components
import Button from "components/Common/Button/Button";
import Table from "components/Common/Table/Table";
import Modal from "components/Common/Modal/Modal";
import TableCell from "components/Common/TableCell/TableCell";

// Utils
import * as Database from "utils/database.utils";

// Context
import { useSettings } from "context/Settings.context";

// Styles
import NewCompanyModalContainer from "./NewCompanyModal.styles";

type Props = {
    selectedCompany: any;
    reload: Function;
    setShowModal: Function;
};

const NewCompanyModal: React.FC<Props> = ({
    selectedCompany,
    reload,
    setShowModal,
}) => {
    const { setToast } = useSettings();

    const [loading, setLoading] = useState<boolean>(false);

    const saveCompany = async () => {
        setLoading(true);

        let data = {
            auto_table: selectedCompany.auto_table,
            key_details_id: selectedCompany.key_details_id,
            first_detected_date: selectedCompany.first_detected_date,
            scraped_company: selectedCompany.scraped_company,
            scraped_excel_model: selectedCompany.scraped_excel_model,
            scraped_team: selectedCompany.scraped_team,
            scraped_ticker: selectedCompany.scraped_ticker,
            detected_source: selectedCompany.detected_source,
            asia_flag_occurs: selectedCompany.asia_flag_occurs,
        };

        let res = await Database.post("api/companies", data);

        if (res.ok) {
            setToast({
                show: true,
                type: "success",
                message: "Company has been added",
            });

            reload();
            setShowModal(false);
        }
    };

    const columns = [
        {
            id: 1,
            title: "COMPANY",
            sortable: false,
            value: "scraped_company",
            cell: (data: string) => (
                <TableCell styleTypes="capitalize" data={data.toLowerCase()} />
            ),
            show: true,
        },
        {
            id: 2,
            title: "TEAM",
            sortable: false,
            value: "scraped_team",
            cell: (data: string) => (
                <TableCell styleTypes="capitalize" data={data} />
            ),
            show: true,
        },
        {
            id: 3,
            title: "TICKER",
            sortable: false,
            value: "scraped_ticker",
            cell: (data: string) => <TableCell data={data} />,
            show: true,
        },
        {
            id: 4,
            title: "FILE NAME",
            sortable: false,
            value: "scraped_excel_model",
            cell: (data: string) => <TableCell data={data} />,
            show: true,
        },
    ];

    return (
        <NewCompanyModalContainer>
            <Modal loading={loading}>
                <div className="main">
                    <div className="text">
                        <h3>CONFIRM NEW COMPANY</h3>
                        <p>Are you sure this company doesn't already exist?</p>
                    </div>
                    <Table
                        data={[selectedCompany]}
                        columns={columns}
                        filterQuery={""}
                        defaultSortKey="detected_date"
                    />
                </div>

                <div className="actions">
                    <Button
                        text="CANCEL"
                        color="flat"
                        click={() => setShowModal(false)}
                    />
                    <Button text="SAVE" click={saveCompany} />
                </div>
            </Modal>
        </NewCompanyModalContainer>
    );
};

export default NewCompanyModal;
