import { useEffect, useState } from "react";

// Material UI
import { Tooltip } from "@mui/material";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCheckCircle,
    faDownload,
    faPencil,
} from "@fortawesome/free-solid-svg-icons";

// Components
import Table from "components/Common/Table/Table";
import SearchOptions from "components/Common/SearchOptions/SearchOptions";
import ExportCompanyListModal from "./ExportCompanyListModal/ExportCompanyListModal";
import ButtonSmall from "components/Common/ButtonSmall/ButtonSmall";
import AnalystCell from "./AnalystCell/AnalystCell";
import TableCell from "components/Common/TableCell/TableCell";

// Data
import { teamLabelDict, teamOptions } from "assets/data/lists";

// Components
import CompanyModal from "./CompanyModal/CompanyModal";

// Utils
import * as Database from "utils/database.utils";

// Context
import { useSettings } from "context/Settings.context";

// Styles
import CompaniesContainer from "./Companies.styles";

export default function Companies() {
    const [showExportCompanyListModal, setShowExportCompanyListModal] =
        useState<any>(null);

    const [selectedCompany, setSelectedCompany] = useState(false);

    const [editAnalystMode, setEditAnalystMode] = useState(false);
    const [reloadCompanies, setReloadCompanies] = useState(0);

    const [filterQuery, setFilterQuery] = useState<string>("");

    const [users, setUsers] = useState<any[]>([]);

    const getUserList = async () => {
        let res = await Database.get("api/companies/analysts");
        let data = await res.json();

        setUsers(
            data
                .filter((user: any) => user.name)
                .sort((a: any, b: any) => a.name.localeCompare(b.name))
                .map((user: any) => ({
                    label: user.name,
                    value: user.id,
                }))
        );
    };

    useEffect(() => {
        getUserList();
    }, []);

    const columns = [
        {
            id: 1,
            title: "COMPANY",
            sortable: true,
            value: "company",
            cell: (data: string) => (
                <TableCell
                    styleTypes="capitalize companyName"
                    data={data.toLowerCase()}
                />
            ),
            show: true,
        },
        {
            id: 2,
            title: "TEAM",
            sortable: true,
            value: "gim_subteam",
            cell: (data: string) => (
                // @ts-ignore
                <TableCell styleTypes="capitalize" data={teamLabelDict[data]} />
            ),
            show: true,
        },
        {
            id: 3,
            title: "ANALYST",
            sortable: true,
            value: "analyst_name",
            cell: (data: string) => (
                <AnalystCell
                    company={data}
                    editAnalystMode={editAnalystMode}
                    reloadCompanies={reloadCompanies}
                    setReloadCompanies={setReloadCompanies}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        // {
        //     id: 4,
        //     title: "CURRENT",
        //     sortable: true,
        //     value: "current_company_ref_flag",
        //     cell: (data: number) =>
        //         data === 1 && (
        //             <FontAwesomeIcon
        //                 className="checkIcon"
        //                 icon={faCheckCircle}
        //                 fixedWidth
        //             />
        //         ),
        //     show: true,
        // },
        {
            id: 5,
            title: "FOCUS LIST",
            sortable: true,
            value: "live_company_flag",
            cell: (data: any) =>
                data.live_company_flag || data.live_company_asia_flag ? (
                    <Tooltip
                        title={data.live_company_asia_flag ? "ASIA" : "GLOBAL"}
                    >
                        <div>
                            <FontAwesomeIcon
                                className="checkIcon"
                                icon={faCheckCircle}
                                fixedWidth
                            />
                        </div>
                    </Tooltip>
                ) : null,

            show: true,
            fullDataRow: true,
        },
        {
            id: 6,
            title: "START DATE",
            sortable: true,
            value: "ref_start_date",
            cell: (data: string) => <TableCell data={data} />,
            show: true,
        },
        {
            id: 7,
            title: "END DATE",
            sortable: true,
            value: "ref_end_date",
            cell: (data: string) => <TableCell data={data} />,
            show: true,
        },
        {
            id: 8,
            title: "BBG TICKER",
            sortable: true,
            value: "bbg_ticker",
            cell: (data: string) => <TableCell data={data} />,
            show: true,
        },
        {
            id: 9,
            title: "RISK BUCKET",
            sortable: true,
            value: "bespoke_sector",
            cell: (data: string) => <TableCell data={data} />,
            show: true,
        },
    ];

    const filterOptions = [
        { id: 1, name: "Search COMPANY", value: "company", type: "SEARCH" },
        {
            id: 2,
            name: "Team",
            value: "gim_subteam",
            type: "DROPDOWN",
            options: teamOptions,
        },
        {
            id: 3,
            name: "Analyst",
            value: "analyst_id",
            type: "DROPDOWN",
            searchable: true,
            options: users,
        },
        // {
        //     id: 4,
        //     name: "Current",
        //     value: "current_company_ref_flag",
        //     type: "SWITCH",
        // },
        {
            id: 5,
            name: "Focus List",
            value: "live_company_flag",
            type: "SWITCH",
        },
    ];

    return (
        <CompaniesContainer>
            <SearchOptions
                setFilterQuery={setFilterQuery}
                options={filterOptions}
                actions={
                    <>
                        {/*<ButtonSmall*/}
                        {/*    text={"EDIT ANALYSTS"}*/}
                        {/*    icon={faPencil}*/}
                        {/*    color={editAnalystMode ? "accent" : "primary"}*/}
                        {/*    click={() => setEditAnalystMode(!editAnalystMode)}*/}
                        {/*/>*/}

                        <ButtonSmall
                            text={"EXPORT"}
                            icon={faDownload}
                            click={() => setShowExportCompanyListModal(true)}
                        />
                    </>
                }
            />

            <Table
                url={"api/companies"}
                columns={columns}
                filterQuery={
                    filterQuery + "&filter_current_company_ref_flag=true"
                }
                defaultSortKey="company"
                pagination
                emptyText="NO COMPANIES"
                reload={reloadCompanies}
                onRowClick={(row: any) => setSelectedCompany(row)}
            />

            {showExportCompanyListModal && (
                <ExportCompanyListModal
                    setShowModal={setShowExportCompanyListModal}
                />
            )}

            {Boolean(selectedCompany) && (
                <CompanyModal
                    company={selectedCompany}
                    setSelectedCompany={setSelectedCompany}
                    reloadCompanies={() =>
                        setReloadCompanies(reloadCompanies + 1)
                    }
                />
            )}
        </CompaniesContainer>
    );
}
