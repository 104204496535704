import styled from "styled-components";

export default styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    min-height: 120px;
    max-height: 120px;
    min-width: 100%;

    border-radius: 5px;

    background-color: ${(props) => props.theme.filterButtonBackground};
    color: ${(props) => props.theme.filterButtonForeground};

    margin-bottom: 20px;

    font-size: 30px;
    line-height: 30px;

    user-select: none;

    p {
        margin-top: 3px;
        display: flex;
        flex-grow: 1;
        justify-content: center;
    }

    svg {
        margin: 0 20px;
        //color: #004453;
    }

    .icon {
        display: flex;
        justify-content: center;
        align-items: center;

        min-width: 120px;
        max-width: 120px;
        min-height: 120px;

        background-color: ${(props) => props.theme.homeListTitleRowBackground};
        color: ${(props) => props.theme.homeListTitleRowText};

        border-radius: 0 5px 5px 0;
    }

    :hover {
        cursor: pointer;
        filter: brightness(0.9);
    }

    &.disabled {
        cursor: unset;

        background-color: #eeeeee;
        color: #888888;

        .icon {
            background-color: #aaaaaa;
            color: white;
        }

        :hover {
            background-color: #eeeeee;
            .icon {
                background-color: #aaaaaa;
                color: white;
            }
        }
    }
`;
