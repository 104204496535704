import React, { useState } from "react";
import { useHistory } from "react-router-dom";

// FontAwesome
import {
    faEllipsisH,
    faPencilAlt,
    faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Components
import Alert from "components/Common/Alert/Alert";
import Button from "components/Common/Button/Button";
import DropDownMenu from "components/Common/DropDownMenu/DropDownMenu";
import DropDownMenuItem from "components/Common/DropDownMenu/DropDownMenuItem/DropDownMenuItem";

// Context
import { useBQMQ } from "context/BQMQ.context";
import { useSettings } from "context/Settings.context";

// Utils
import * as Database from "utils/database.utils";

// Styles
import ActionsContainer from "./Actions.styles";

type Props = {
    session: BQMQSession;
};

export default function Actions({ session }: Props) {
    const history = useHistory();

    const { setToast } = useSettings();
    const { getDropDowns } = useBQMQ();

    const [anchorEl, setAnchorEl] = useState<any>(null);
    const [showDeleteSessionModal, setShowDeleteSessionModal] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const handleOpenMenu = (event: React.MouseEvent) => {
        event.stopPropagation();
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const deleteSession = async () => {
        setAnchorEl(null);
        setIsDeleting(true);

        const res = await Database.remove(
            "api/bqmq_voting/session/delete/" + session.id
        );

        if (res.ok) {
            setShowDeleteSessionModal(false);

            if (window.location.pathname === "/voting/result") {
                history.push("/voting");
            }

            setToast({
                show: true,
                type: "success",
                message: "Session Deleted",
            });
            getDropDowns();
        } else {
            setIsDeleting(false);

            setToast({
                show: true,
                type: "error",
                message: "Error Deleting Session",
            });
        }
    };

    return (
        <ActionsContainer data-cy="actions">
            <FontAwesomeIcon
                className={"actionsMenuBtn"}
                data-testid="actionEllipsis"
                icon={faEllipsisH}
                fixedWidth
                onClick={handleOpenMenu}
            />

            <DropDownMenu
                open={Boolean(anchorEl)}
                setOpen={setAnchorEl}
                anchorEl={anchorEl}
            >
                <DropDownMenuItem
                    icon={<FontAwesomeIcon icon={faPencilAlt} fixedWidth />}
                    text="EDIT"
                    click={() =>
                        history.push("/voting/edit-session?id=" + session.id)
                    }
                    permissions={["p_bqmq_can_edit_session"]}
                />
                <DropDownMenuItem
                    icon={<FontAwesomeIcon icon={faTrash} fixedWidth />}
                    text="DELETE"
                    click={() => setShowDeleteSessionModal(true)}
                    permissions={["p_bqmq_can_delete_session"]}
                />
            </DropDownMenu>

            {showDeleteSessionModal && (
                <Alert>
                    <h2>{"DELETE SESSION"}</h2>

                    <p>Are you sure you would like to delete this session?</p>

                    <Button
                        text={isDeleting ? "DELETING..." : "DELETE"}
                        color="accent"
                        click={deleteSession}
                        disabled={isDeleting}
                    />
                    <Button
                        text="CANCEL"
                        color="flat"
                        click={() => setShowDeleteSessionModal(false)}
                    />
                </Alert>
            )}
        </ActionsContainer>
    );
}
