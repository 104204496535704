import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Styles
import LoadingSpinnerContainer from "./LoadingSpinner.styles";

type Props = {
    message?: string;
    noText?: boolean;
};

export default function LoadingSpinner({ noText = false, message }: Props) {
    return (
        <LoadingSpinnerContainer className="loadingSpinner">
            <div className="spinner">
                <FontAwesomeIcon icon={faSpinner} fixedWidth />
            </div>
            {!noText && (
                <div className="text">
                    <p>{message ? message : "LOADING"}</p>
                </div>
            )}
        </LoadingSpinnerContainer>
    );
}
