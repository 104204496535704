import styled from "styled-components";

export default styled.div`
.label-date {
    padding: 5px 10px;
    background-color: ${(props) => props.theme.labelWarningBg};
    color: ${(props) => props.theme.labelWarningColor};
    border-radius: 5px;
    position: relative;
    z-index: 1;
}

.label-archived {
    margin-left: 10px;
    padding: 5px 10px;
    background-color: ${(props) => props.theme.primaryButtonBackground};
    color: ${(props) => props.theme.labelWarningColor};
    border-radius: 5px;
    position: relative;
    z-index: 1;
}
.notes {
    margin: 25px 0px 10px 0px;
    max-width: 1000px;
    min-width: 400px;

    margin-left: 15px;
    margin-top: -10px;


    .note {
        padding: 15px;
        padding-bottom: 20px;
        border: 1px solid ${(props) => props.theme.secondaryButtonBackground};
        border-radius: 5px;
        position: relative;
        :hover {
            border: 1px solid ${(props) => props.theme.primaryButtonBackground};
            border-radius: 5px;
        }
    }
    .filter-buttons {
        margin-top: 10px;
        display: flex;
        button {
            text-transform: capitalize;
        }
        .filter-button {
            width: 120px;
        }
    }
}

.archived {
    margin-top: 20px;
    margin-bottom: 20px;
}

.modal {
    padding: 20px;
    display: flex;
    flex-direction: column;
    text-align: center;
    .button-container {
        margin-top: 10px;
        display: flex;
        justify-content: center;
    }
}

.files {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    .file-wrapper {
        position: relative;
        .delete {
            cursor: pointer;
            border: 0;
            position: absolute;
            background-color: transparent;
            bottom: 0;
            right: 10px;
            transition: scale 0.3s ease;
            :hover {
                color: ${(props) => props.theme.accentButtonBackground};
                scale: 1.3;
            }
        }
    }

}
}
`
