// Styles
import FastLinksContainer from "./FastLinks.styles";
import FastLink from "./FastLink/FastLink";
import {
    faBook,
    faChartLine,
    faChartPie,
    faDatabase,
    faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props = { children: any };

export default function FastLinks({ children }: Props) {
    return <FastLinksContainer>{children}</FastLinksContainer>;
}
