import { createContext, useContext, useState, useEffect } from 'react';
import { ApiHandler } from "services/api_handler";
import {useSettings} from "context/Settings.context";

export const GrowthUsersContext = createContext();

const URL_USERS_LIST = "api/growth_voting/users";

export function GrowthUsersProvider({ children }) {
    const [users, setUsers] = useState([]);

    const fetchUsers = async () => {
        // all growth users
        if (users.length > 0) {
            return;
        }

        let response = await new  ApiHandler(URL_USERS_LIST).list();

        let adUsers = response.data;

        for (let i = 0; i < adUsers.length; i++) {
            adUsers[i]["user_id"] = adUsers[i].id;
            delete adUsers[i]["id"]
        }

        setUsers(adUsers);
    }

    useEffect(() => {
        fetchUsers();
    }, []);

    return (
        <GrowthUsersContext.Provider
            value={{
                users,
            }}
        >
            {children}
        </GrowthUsersContext.Provider>
    );
}

export const useGrowthUsers = () => {
    return useContext(GrowthUsersContext);
};
