import colors from "assets/styles/colors";

export default {
    filterButtonBackground: "white",
    filterButtonBackgroundHover: colors.grey80,
    filterButtonForeground: "black",
    filterButtonBackgroundActive: "red",
    filterButtonForegroundActive: "white",

    dropDownMenuBackground: "white",
    dropDownMenuText: "black",
    dropDownSearchBoxIcon: "black",
    dropDownSearchBoxBorder: colors.grey60,
    dropDownBackgroundHover: colors.grey80,

    searchOptionsShowAllBackground: "orange",
    searchOptionsShowAllText: "white",
};
