import {DealDetails, GrowthAnswer, GrowthQuestion, VoteResult, VoteResultsByMember, DealTeam, FullDealDetails, DealVote} from "assets/interfaces/growth_voting";
import {EnumGrowthVotingTeams, EnumGrowthVotingVoteTypes, EnumGrowthAnswerTypes} from "assets/enums/growth_voting";
import {getDomainFromUrl} from "./common.utils";

export const getDealName = (deal: DealDetails) => {
    let names = [];

    if (deal.company) {
        names.push(deal.company.name);
    }

    if (deal.funding_round) {
        names.push(deal.funding_round);
    }

    return names.join(" | ");
}

export const getVoteName = (vote: any) => {
    if (vote == EnumGrowthVotingVoteTypes.BQ) {
        return "Business Quality";
    } else if (vote == EnumGrowthVotingVoteTypes.MQ) {
        return "Management Quality";
    } else if (vote == EnumGrowthVotingVoteTypes.ST) {
        return "Sustainability";
    } else {
        return "Unkown Vote Type";
    }
}

export const getTeamNameByType = (type: string) => {
    if (type === EnumGrowthVotingTeams.Deal) {
        return "Deal Team";
    } else if (type === EnumGrowthVotingTeams.Quic) {
        return "QUIC";
    } else if (type === EnumGrowthVotingTeams.Vote) {
        return "Vote Team";
    } else if (type === EnumGrowthVotingTeams.Lead) {
        return "Team Lead";
    } else {
        return type.toUpperCase();
    }
}

export const createVoteSummariesByQuestions = (questions: GrowthQuestion[], answeres: GrowthAnswer[]) => {
    // create totals for every question by type (boolean, percentage, rating 1-5)

    questions = questions.sort((a, b) => a.order - b.order);

    let summaries: VoteResult[] = questions.map((question) => {
        const question_answers = answeres.filter((answer) => answer.question_id === question.id);
        const summary = {
            question: question,
            answers: question_answers,
            result: 0,
        }

        let result: number = 0;
        let total = 0;

        summary.answers.forEach((answer) => {
            if (summary.question.answer_type === EnumGrowthAnswerTypes.Boolean) {
                    if (answer.answer_bool) {
                        total++;
                    }
            } else if (summary.question.answer_type === EnumGrowthAnswerTypes.Percentage) {
                    if (answer?.answer_percent && answer.answer_percent > 0) {
                        total = total + answer.answer_percent;
                    }
            } else if (summary.question.answer_type === EnumGrowthAnswerTypes.Rating_1_5) {
                    if (answer?.answer_rating && answer.answer_rating > 0) {
                        total = total + answer.answer_rating;
                    }
            }
        });

        if (summary.answers.length > 0) {
            result = total/summary.answers.length;
        }

        summary.result = result;

        return summary;
    });


    return summaries;
}

export const createVoteSummariesByMembers = (questions: GrowthQuestion[], answeres: GrowthAnswer[], team: DealTeam) => {
    // create a detaild summary for every member
     questions = questions.sort((a, b) => a.order - b.order);

    let members: VoteResultsByMember[] = team.members.map((member) => {
        return {
            member: member,
            questions: questions.map(question => {
                return {...question, answer: answeres.find((answer) => answer.question_id === question.id && answer.team_member_id === member.id)};
            }),
        }
    });

    return members;
}


export const sortDealVotes = (deal: FullDealDetails) => {
    const orderVotes = (a: DealVote, b: DealVote) => {
        // sort by type
        return a.type.localeCompare(b.type);

        //Show the open ones first
        // if (a.open !== b.open) {
        //     // return the open votes first
        //     return a.open ? -1 : 1;
        // } else {
        //     // as a secondary order we sort by created date
        //     if (createdDiff !== 0) {
        //         // sort by created date
        //         return createdDiff;
        //     } else {
        //         // as a last resort we sort it by type
        //         return a.type.localeCompare(b.type);
        //     }
        // }

    }

    deal.votes.sort(orderVotes);
}

export const getVoteParticipation = (answers: GrowthAnswer[], team: DealTeam) => {
    // return details about the vote participation
    const membersNotVoted = team.members.filter(member => !answers.find(answer => answer.team_member_id === member.id));
    const totalMembers = team.members.length;
    const numVotedMembers = totalMembers - membersNotVoted.length;
    const participationRatio = (totalMembers - membersNotVoted.length) / totalMembers;

    return {
        membersNotVoted: membersNotVoted, // list of not voted members
        participationPercentage: (participationRatio * 100).toFixed(0) + "%", // ratio of the members who has voted on any question
        participationMessage: numVotedMembers + "/" + totalMembers,
        numVotedMembers: numVotedMembers,  // number of members who has voted on any question

    }
}

export const getDomain = (urlString: string) => {
    if (!urlString) {
        return "";
    }

    const domain = getDomainFromUrl(urlString);

    if (domain) {
        return domain;
    } else {
        return urlString;
    }
}
