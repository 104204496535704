import { Grid } from "@mui/material";
import DateSelect from "components/Common/DateSelect/DateSelect";
import Select from "components/Common/Select/Select";
import TextField from "components/Common/TextField/TextField";
import React from "react";
import {
    dailyFlowInputs,
    DailyFlowValues,
} from "screens/DailyRecs/Inputs/DailyFlows/types";

// Styles
import DailyFlowInputFieldsContainer from "./DailyFlowInputFields.styles";

interface DailyFlowInputFieldsProps {
    handleChange: (value: any, name: string) => void;
    handleDateChange: (name: string, date: Date) => void;
    handleAccountChange: (account_number: string) => void;
    handleFlowDateChange: (flow_date_dt: Date) => void;
    dailyFlowsValues: any;
    accountNames: {
        gen_acct: string;
        account_name: string;
    }[];
}

const DailyFlowInputFields = ({
    handleChange,
    handleDateChange,
    handleFlowDateChange,
    handleAccountChange,
    dailyFlowsValues,
    accountNames,
}: DailyFlowInputFieldsProps) => {
    return (
        <DailyFlowInputFieldsContainer>
            <>
                <div className={"filters"}>
                    <div className={"filter-label"}>Filters:</div>
                    <div className={"filter-account"}>
                        <label>{"Account Name"}</label>
                        <div>
                            <Select
                                showAll={false}
                                options={
                                    accountNames
                                        ? accountNames
                                            .sort((a, b) =>
                                                a.account_name.localeCompare(
                                                    b.account_name
                                                )
                                            )
                                            .map((account) => {
                                                return {
                                                    value: account?.gen_acct,
                                                    label: account?.account_name,
                                                };
                                            })
                                        : [{ value: "", label: "" }]
                                }
                                filterName="Account Name"
                                setValue={(e: any) => handleAccountChange(e)}
                                value={dailyFlowsValues["account_number"]}
                            />
                        </div>
                    </div>
                    <div className={"filter-date"}>
                        <label>{"Flow Date"}</label>
                        <DateSelect
                            placeholder="Date"
                            setValue={(e: any) => {
                                handleFlowDateChange(e)
                            }}
                            value={dailyFlowsValues["flow_date_dt"]}
                            error={false}
                        />
                    </div>
               </div>
                <div className={"fields"}>
                <Grid
                    container
                    direction="row"
                    spacing={{ xs: 2, md: 2 }}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                >
                    {dailyFlowInputs
                        .filter((input) => input.name !== "account_number")
                        .map((input) => {
                            let name = input.name;
                            let label = input.label;
                            if (input.type === "input") {
                                return (
                                    <Grid key={name} item xs={12} sm={6} md={6}>
                                        <label>{input.label}</label>
                                        <div className="textFieldDiv">
                                            <TextField
                                                placeholder={
                                                    dailyFlowsValues[
                                                        name as keyof DailyFlowValues
                                                    ]
                                                }
                                                type={"formattedValue"}
                                                change={(e: any) =>
                                                    handleChange(e, name)
                                                }
                                                value={
                                                    dailyFlowsValues[
                                                        name as keyof DailyFlowValues
                                                    ]
                                                }
                                            />
                                        </div>
                                    </Grid>
                                );
                            }
                            if (input.type === "select") {
                                return (
                                    <Grid key={name} item xs={2} sm={4} md={4}>
                                        <div className="select">
                                            <Select
                                                filterName={name}
                                                options={input.options}
                                                setValue={(e: any) =>
                                                    handleChange(e, name)
                                                }
                                                value={
                                                    dailyFlowsValues[
                                                        name as keyof DailyFlowValues
                                                    ]
                                                }
                                                searchable={false}
                                                showAll={false}
                                                label={label}
                                            />
                                        </div>
                                    </Grid>
                                );
                            }
                            if (input.type === "date") {
                                return dailyFlowsValues[
                                    name as keyof DailyFlowValues
                                ] !== null ? (
                                    <Grid key={name} item xs={2} sm={4} md={4}>
                                        <div className="select">
                                            <DateSelect
                                                label={label}
                                                placeholder={new Date().toLocaleDateString()}
                                                setValue={(date: Date) =>
                                                    handleDateChange(name, date)
                                                }
                                                value={
                                                    new Date(
                                                        dailyFlowsValues[
                                                            name as keyof DailyFlowValues
                                                        ]!!
                                                    )
                                                }
                                                error={false}
                                            />
                                        </div>
                                    </Grid>
                                ) : (
                                    <Grid key={name} item xs={2} sm={4} md={4}>
                                        <label>{input.label}</label>
                                        <div className="blankDiv" />
                                    </Grid>
                                );
                            }
                        })}
                </Grid>
                </div>
            </>
        </DailyFlowInputFieldsContainer>
    );
};
export default DailyFlowInputFields;
