import { createContext, useContext, useState, useEffect } from 'react';
import { ApiHandler } from "services/api_handler";
import {useSettings} from "context/Settings.context";

export const GrowthDealContext = createContext();

export function GrowthDealProvider({ children }) {
    // TODO: move
    const [dealDirty, setDealDirty] = useState(false);
    const [answersDirty, setAnswersDirty] = useState(false);

    return (
        <GrowthDealContext.Provider
            value={{
                dealDirty,
                setDealDirty,
                answersDirty,
                setAnswersDirty,
            }}
        >
            {children}
        </GrowthDealContext.Provider>
    );
}

export const useGrowthDeal = () => {
    return useContext(GrowthDealContext);
};
