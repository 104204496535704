// Styles
import DataLinksContainer from "./DataLinks.styles";
import FastLink from "../../../Home/FastLinks/FastLink/FastLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBook,
    faBriefcase,
    faChartLine,
    faChartPie,
    faCut,
    faDatabase,
} from "@fortawesome/free-solid-svg-icons";
import FastLinks from "../../../Home/FastLinks/FastLinks";

export default function DataLinks() {
    return (
        <DataLinksContainer>
            <FastLinks>
                <FastLink
                    link={"/data/new-companies"}
                    permissions={["p_update_companies"]}
                >
                    <FontAwesomeIcon icon={faBriefcase} fixedWidth />
                    <p className={"title"}>NEW COMPANIES</p>
                </FastLink>
                <FastLink
                    link={"/data/stock-splits"}
                    permissions={["p_update_companies"]}
                >
                    <FontAwesomeIcon icon={faCut} fixedWidth />
                    <p className={"title"}>STOCK SPLITS</p>
                </FastLink>
                <FastLink
                    link={"/data/company-models"}
                    permissions={["p_list_extractions"]}
                >
                    <FontAwesomeIcon icon={faBriefcase} fixedWidth />
                    <p className={"title"}>COMPANY MODELS</p>
                </FastLink>
                <FastLink
                    link={"/data/summary-sheets"}
                    permissions={["p_gbl_dashboard"]}
                >
                    <FontAwesomeIcon icon={faChartLine} fixedWidth />
                    <p className={"title"}>SUMMARY SHEETS</p>
                </FastLink>
                <FastLink
                    link={"/data/portfolio-live-list"}
                    permissions={["p_gbl_dashboard"]}
                >
                    <FontAwesomeIcon icon={faChartPie} fixedWidth />
                    <p className={"title"}>PORTFOLIO LIVE</p>
                </FastLink>
                <FastLink
                    link={"/data/data-dictionary"}
                    permissions={["p_view_dictionary"]}
                >
                    <FontAwesomeIcon icon={faBook} fixedWidth />
                    <p className={"title"}>DATA DICTIONARY</p>
                </FastLink>
                <FastLink
                    link={"/data/database-schema"}
                    permissions={["p_view_dictionary"]}
                >
                    <FontAwesomeIcon icon={faDatabase} fixedWidth />
                    <p className={"title"}>DATABASE SCHEMA</p>
                </FastLink>
            </FastLinks>
        </DataLinksContainer>
    );
}
