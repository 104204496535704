import { useState } from "react";
import MorningMeetingNotesContainer from "./MorningMeetingNotes.styles";
import RichText from "components/Common/RichText/RichText";
import FileDisplay from "components/Common/FileDisplay/FileDisplay";
import { formatDate } from "utils/date.utils";
import * as Database from "utils/database.utils";
import { useSettings } from "context/Settings.context";
import SubMenuItem from "components/Menu/SubMenu/SubMenuItem/SubMenuItem";
import { useHistory } from "react-router-dom";
import { faPencilAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "components/Common/Modal/Modal";
import Button from "components/Common/Button/Button";

type FileType = {
    id: string;
    file_name: string;
    deleted: number;
};

type NoteType = {
    date: string;
    notes: string[];
    files: FileType[];
    id: string;
    analyst_id: string;
    archived: number
};

type MorningMeetingNotesProps = {
    notes: NoteType[];
    setNotes: React.Dispatch<React.SetStateAction<NoteType[]>>;
};

const MorningMeetingNotes = ({ notes, setNotes }: MorningMeetingNotesProps) => {
    const history = useHistory();
    const { setShowLoadingToast, setToast } = useSettings();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showDeleteNoteModal, setShowDeleteNoteModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState<{
        noteId: string;
        fileId?: string;
    } | null>(null);

    const ukDateFormat = (date: string): string => {
        const [day, month, year] = date.split("-").map(Number);
        const dt = new Date(year, month - 1, day);
        return formatDate(dt);
    };

    const downloadFile = async (meetingNoteId: string, fileId: string) => {
        setShowLoadingToast({ show: true, message: "DOWNLOADING" });
        try {
            const response = await Database.get(
                `api/bqmq_voting/meeting-note/${meetingNoteId}/${fileId}`
            );
            const data = await response.json();
            const uri: string = data.data.uri;
            const link = document.createElement("a");
            link.href = uri;
            link.download = fileId;
            link.click();

            setShowLoadingToast({ show: false, message: "" });
        } catch (error) {
            console.error("Download failed:", error);
            setToast({
                show: true,
                message: "There was an error downloading the file.",
                type: "error",
            });
        }
    };

    const deleteFile = async (note_id: string, file_id: string) => {
        setShowModal(false);
        setShowLoadingToast({ show: true, message: `Deleting file...` });

        try {
            const response = await Database.patch(
                `api/bqmq_voting/meeting-note/${note_id}/archive/${file_id}`
            );
            const data = await response.json();
            const fileDataFromResponse: FileType = data.data;

            if (fileDataFromResponse.deleted === 1) {
                setNotes((prevNotes) =>
                    prevNotes.map((note) =>
                        note.id === note_id
                            ? {
                                  ...note,
                                  files: note.files.map((file) =>
                                      file.id === fileDataFromResponse.id
                                          ? {
                                                ...file,
                                                deleted:
                                                    fileDataFromResponse.deleted,
                                            }
                                          : file
                                  ),
                              }
                            : note
                    )
                );

                setShowLoadingToast({ show: false, message: "" });
                setToast({
                    show: true,
                    type: "success",
                    message: "File has been deleted.",
                });
            }
        } catch (error) {
            console.error(error);
            setToast({
                show: true,
                message: "There was an error deleting the file.",
                type: "error",
            });
        }

        setShowLoadingToast({ show: false, message: `` });
    };

    const deleteNote = async (note_id: string) => {
        setShowDeleteNoteModal(false);
        setShowLoadingToast({ show: true, message: `Deleting note...` });

        try {
            const response = await Database.remove(
                `api/bqmq_voting/meeting-note/${note_id}`
            );
            const data = await response.json();
            const NoteDataFromResponse: NoteType = data.data;

            if (NoteDataFromResponse.archived === 1) {
                setNotes((prevNotes) =>
                    prevNotes.map((note) =>
                        note.id === note_id ? { ...NoteDataFromResponse } : note
                    )
                );

                setShowLoadingToast({ show: false, message: "" });
                setToast({
                    show: true,
                    type: "success",
                    message: "Note has been archived.",
                });
            }
        } catch (error) {
            console.error(error);
            setToast({
                show: true,
                message: "There was an error deleting the note.",
                type: "error",
            });
        }
    };


    return (
        <MorningMeetingNotesContainer className="pageContainer">
            {/* Filter active and deleted notes */}
            {notes.length === 0 ? (
                <p>No notes found.</p>
            ) : (
                <>
                    {notes
                        .filter(row => row.archived !== 1) // Filter active notes
                        .map((row, i) => (
                            <div key={i}>
                                {/* Render active notes */}
                                <div>
                                    <span className="label-date">
                                        {ukDateFormat(row.date)}
                                    </span>
                                </div>
                                <div className="notes">
                                        <div className="note" key={row.id}>
                                            <RichText value={row.notes.join('<br/><br/>')} />
                                        </div>
                                    {/* Render files */}
                                    {row.files.length > 0 && (
                                        <div className="files">
                                            {row.files.map(({ file_name, id, deleted }) =>
                                                !deleted ? (
                                                    <div className="file-wrapper" key={id}>
                                                        <FileDisplay
                                                            value={file_name}
                                                            onClick={() => downloadFile(row.id, id)}
                                                        />
                                                        {showModal && selectedFile?.noteId === row.id && selectedFile?.fileId === id && (
                                                            <Modal loading={false}>
                                                                <div className="modal">
                                                                    <p>
                                                                        Are you sure you want to delete this file? <br />
                                                                        <strong>{file_name}</strong>
                                                                    </p>
                                                                    <div className="button-container">
                                                                        <Button color="secondary" text="NO" click={() => setShowModal(false)} />
                                                                        <Button color="accent" text="YES" click={() => deleteFile(row.id, id)} />
                                                                    </div>
                                                                </div>
                                                            </Modal>
                                                        )}
                                                        <button
                                                            className="delete"
                                                            onClick={() => {
                                                                setShowModal(true);
                                                                setSelectedFile({ noteId: row.id, fileId: id });
                                                            }}
                                                        >
                                                            <FontAwesomeIcon icon={faTrash} />
                                                        </button>
                                                    </div>
                                                ) : null
                                            )}
                                        </div>
                                    )}
                                    {/* Render edit and delete buttons */}
                                    <div className="filter-buttons">
                                        <div className="filter-button">
                                            <SubMenuItem
                                                text={"Edit Note"}
                                                active={history.location.pathname.includes(`/new-notes/?meeting_note_id=${row.id}`)}
                                                icon={faPencilAlt}
                                                click={() => history.push(`/research/new-notes/?meeting_note_id=${row.id}`)}
                                                permissions={[
                                                    "P_bqmq_can_host",
                                                    "p_bqmq_can_view_results",
                                                    "p_bqmq_can_vote",
                                                ]}
                                            />
                                        </div>
                                        <div className="filter-button">
                                            {/* ARCHIVE BUTTON IS COMMENTED OUT AS PER CLIENT REQUEST */}
                                            {/* <SubMenuItem
                                                text={"Archive Note"}
                                                active={false}
                                                icon={faTrash}
                                                click={() => {
                                                    setSelectedFile({ noteId: row.id });
                                                    setShowDeleteNoteModal(true);
                                                }}
                                                permissions={[
                                                    "P_bqmq_can_host",
                                                    "p_bqmq_can_view_results",
                                                    "p_bqmq_can_vote",
                                                ]}
                                            /> */}
                                        </div>
                                        {showDeleteNoteModal && selectedFile?.noteId === row.id && (
                                            <Modal loading={false}>
                                                <div className="modal">
                                                    <p>Are you sure you want to archive this note?</p>
                                                    <div className="button-container">
                                                        <Button text="YES" click={() => deleteNote(row.id)} />
                                                        <Button text="NO" click={() => setShowDeleteNoteModal(false)} />
                                                    </div>
                                                </div>
                                            </Modal>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                    {/* Render deleted notes after active notes */}
                    {notes
                        .filter(row => row.archived === 1) // Filter deleted notes
                        .map((row, i) => (
                            <div key={i}>
                                <div>
                                    <span className="label-date">
                                        {ukDateFormat(row.date)}
                                    </span>
                                    <span className="label-archived">
                                        Archived
                                    </span>
                                </div>
                                <div className="notes">
                                    {row.notes.map((note, j) => (
                                        <div className="note" key={j}>
                                            <RichText value={note} />
                                        </div>

                                    ))}
                                    {row.files.length > 0 && (
                                        <div className="files">
                                            {row.files.map(({ file_name, id, deleted }) =>
                                                !deleted ? (
                                                    <div className="file-wrapper" key={id}>
                                                        <FileDisplay
                                                            value={file_name}
                                                            onClick={() => downloadFile(row.id, id)}
                                                        />
                                                    </div>
                                                ) : null
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))
                    }
                </>
            )}
        </MorningMeetingNotesContainer>
    );


};

export default MorningMeetingNotes;
