import { createContext, useContext, useEffect, useState } from "react";
import { io } from "socket.io-client";

import { useAuth } from "./Auth.context";

import Logger from "utils/logger.utils";
import { hasPermissions } from "../utils/permissions.utils";
import { useBQMQ } from "./BQMQ.context";
import { on } from "events";
const Log = new Logger();
Log.init("Websocket.context");

const WebSocketContext = createContext();

export function WebSocketProvider({ children }) {
    //In order to send a valid auth object to the server we must first see if there are session tokens in local storage..
    // To do this we check the user object from the auth context.
    const { user } = useAuth();
    const [socket, setSocket] = useState(null);

    // The first time the user object is loaded on sign in the tokens are null
    // We wait for the user to be initialised which in turn sets the gim-tokens
    useEffect(() => {
        if (
            user &&
            hasPermissions(["p_ws_connect"], user.permissions) &&
            socket == null
        )
            connectSocket();
        return () => {
            disconnectWebSocket();
        };
    }, [user]);

    // Then connect to the socket with a valid auth object containing not null tokens.
    const connectSocket = () => {
        let socket = io(process.env.REACT_APP_BACKEND_URL, {
            path: "/ws/socket.io",
            transports:
                window.location.hostname === "localhost"
                    ? ["polling"]
                    : ["websocket", "polling"],
            autoConnect: true,
            auth: {
                "gim-user-id": localStorage.getItem("gim-user-id"),
                "gim-token": localStorage.getItem("gim-token"),
                "gim-audience": localStorage.getItem("gim-audience"),
                "gim-expiry": localStorage.getItem("gim-expiry"),
            },
        });

        socket.on("message", (data) => {
            console.log(`%cMessage Received: ${data}`, "color: skyblue;");
        });

        setSocket(socket);
    };

    const disconnectWebSocket = () => {
        if (socket) {
            console.log(`%cDisconnecting WebSockets`, "color: orange;");
            socket.emit("end");
            setSocket(null);
        }
    };

    const connectWebsocket = () => {
        if (socket) {
            console.log(`%cConnecting WebSockets`, "color: orange;");
            connectSocket();
        }
    };

    const handleBqmqRoom = (sessionID, action) => {
        if (!sessionID) return;
        Log.info(`${action.toUpperCase()} ROOM: ${sessionID}`);
        socket?.emit(action, {
            session_id: sessionID,
            user_id: user.id,
        });
        if (action == "close") {
            socket.emit("close-all", { session_id: sessionID });
        }
    };

    return (
        <WebSocketContext.Provider
            value={{
                socket,
                disconnectWebSocket,
                connectWebsocket,
                handleBqmqRoom,
            }}
        >
            {children}
        </WebSocketContext.Provider>
    );
}

export const useWebSockets = () => {
    return useContext(WebSocketContext);
};
