import React from "react";

// Styles
import ClimateLevelsViewContainer from "./ClimateLevelsView.styles";

interface ClimateLevelsProps {}

const ClimateLevelsView = ({}: ClimateLevelsProps) => {
    return (
        <ClimateLevelsViewContainer>
            <div className="iframeContainer">
                <iframe
                    title="ESG - Climate Levels vLive (QS)"
                    src="https://app.powerbi.com/reportEmbed?reportId=48e37dba-ff38-43e2-bd83-9bdddae1e23e&autoAuth=true&ctid=bf4450a2-bfcb-472d-8c40-1425eafb1a3b&filterPaneEnabled=false"
                    width="100%"
                    height="100%"
                    allowFullScreen
                    frameBorder={0}
                />
            </div>
        </ClimateLevelsViewContainer>
    );
};
export default ClimateLevelsView;
