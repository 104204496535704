import styled from "styled-components";

export default styled.div`
    position: relative;

    .intro {
        color: #666666;
        line-height: 1.8em;
        margin-bottom: 20px;
    }

    .columns {
        display: flex;
        flex-direction: row;

        min-width: 100%;
        max-width: 100%;

        margin: 20px -10px;

        .half {
            display: flex;
            flex-direction: column;

            min-width: 50%;
            max-width: 50%;

            padding: 0 10px;
        }
    }

    .loadingData {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 1;

        min-width: 100%;
    }

    .tabItem {
        :hover {
            svg {
                margin-top: 2px;
            }
        }
    }

    .tabIcon {
        display: flex;

        margin-left: 5px;
        margin-bottom: 3px;

        color: ${(props) => props.theme.accentButtonBackground};

        &.complete {
            color: ${(props) => props.theme.primaryButtonBackground};
        }
    }

    .saveChangesButton {
        display: flex;

        position: fixed;
        right: 20px;
        bottom: 20px;

        padding: 10px 15px;
        border-radius: 5px;

        background-color: ${(props) => props.theme.primaryButtonBackground};
        color: white;

        cursor: pointer;

        svg {
            margin-left: 15px;
        }

        :hover {
            background-color: ${(props) =>
                props.theme.primaryButtonBackgroundHover};
        }
    }
`;
