// FontAwesome
import { faCalendar, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// MaterialUI
import { Tooltip } from "@mui/material";

// Styles
import EmailReminder from "../EmailReminder/EmailReminder";
import UserRowContainer from "./UserRow.styles";

import { applyTimeZoneOffset } from "utils/common.utils";
import ModesCell from "../../ModesCell/ModesCell";

type Props = { user: Analyst; companyID: string; session: any };

export default function UserRow({ user, companyID, session }: Props) {
    const roleDict: any = {
        PORTFOLIO_MANAGER: "Portfolio Manager",
        ANALYST: "Analyst",
        RESEARCH_TEAM: "Research Team",
    };

    const getTeam = () => {
        // @ts-ignore
        return "-";
    };

    const hasVoted = () => {
        return session.session.voters
            .map((voter: Vote) => {
                return voter.id;
            })
            .includes(user.id);
    };

    const isCore = () => {
        return session.core.map((core: Vote) => core.id).includes(user.id);
    };

    const userVotes = () => {
        const votes = session.votes.filter(
            (vote: Vote) => vote.analyst_id === user.id
        );
        votes.sort((a: any, b: any) => {
            return a.historic_date
                ? a.historic_date
                : a.created - b.historic_date
                ? b.historic_date
                : b.created;
        });
        const date = votes[votes.length - 1]?.historic_date
            ? votes[votes.length - 1]?.historic_date
            : votes[votes.length - 1]?.created;
        const bq = votes.find(
            (vote: Vote) => vote.question.text === "bq_overall"
        );
        const mq = votes.find(
            (vote: Vote) => vote.question.text === "mq_overall"
        );

        return {
            date: date,
            bq: bq ? bq.choice.text : null,
            mq: mq ? mq.choice.text : null,
        };
    };

    return (
        <UserRowContainer>
            <div className={"info"}>
                <div data-cy="user-row" className={"name completed"}>
                    <div className="userCompleted">
                        {`${user.name.toUpperCase()} `}
                        {hasVoted() && (
                            <FontAwesomeIcon
                                className={"completedIcon"}
                                icon={faCheckCircle}
                                fixedWidth
                            />
                        )}
                        {isCore() && <div className="coreTag">CORE</div>}
                    </div>
                </div>

                <div className={"details"}>
                    {/* <Tooltip title={<h2>ROLE</h2>}>
                        <div className={"detail"}>
                            <FontAwesomeIcon icon={faBuilding} fixedWidth />
                            <p className={"capitalize"}>{user.role}</p>
                        </div>
                    </Tooltip> */}

                    {/* <Tooltip title={<h2>TEAM</h2>}>
                        <div className={"detail"}>
                            <FontAwesomeIcon icon={faUsers} fixedWidth />
                            <p className={"capitalize"}>{user.team}</p>
                        </div>
                    </Tooltip> */}
                </div>
            </div>

            <div className="completedRow">
                {!hasVoted() && (
                    <EmailReminder
                        companyID={companyID}
                        userName={user.name}
                        userID={user.id}
                    />
                )}
                {userVotes().date && (
                    <Tooltip
                        title={
                            <h2>
                                {"LAST UPDATED: " +
                                    (userVotes().date
                                        ? applyTimeZoneOffset(userVotes().date)
                                        : "NOT VOTED")}
                            </h2>
                        }
                    >
                        <div className={"date"}>
                            <FontAwesomeIcon icon={faCalendar} fixedWidth />
                            <p>
                                {applyTimeZoneOffset(
                                    userVotes().date,
                                    "MMM d yyyy"
                                )}
                            </p>
                        </div>
                    </Tooltip>
                )}

                <ModesCell
                    mode={{
                        BQ: userVotes().bq,
                        MQ: userVotes().mq,
                    }}
                />
            </div>
        </UserRowContainer>
    );
}
