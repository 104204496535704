import colors from "assets/styles/colors";

export default {
    tableHeadCellBackground: colors.darkThemeMain,
    tableHeadCellText: "white",
    tableHeadCellSortIcon: "white",
    tableHeadCellSortIconHover: colors.grey70,

    tableBodyText: "white",

    tableHeadCellSortActiveBackground: colors.darkThemeLight,
    tableHeadCellSortActiveText: "white",
    tableHeadCellSortActiveIcon: colors.accentOrange,

    tableDisabledRowBackground: colors.grey60,
    tableDisabledRowText: colors.grey20,

    tableSkeletonBackground: "#00423e",

    tableSelectedRowBackground: colors.darkThemeLight,
    tableSelectedRowText: colors.grey20,

    tableRowCheckbox: "white",
    tableRowCheckboxSelected: colors.textNavy,

    tableIconMain: "white",
    tableIconHighlight: colors.darkThemeLight,

    selectedOptionsBackground: colors.darkThemeLight,
    selectedOptionsText: "white",
    selectedOptionsIcon: colors.textNavy,
    selectedOptionsIconHover: "white",
};
