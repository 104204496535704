// Styles
import FairValueChartsViewContainer from "./FairValueChartsView.styles";

type Props = {};

export default function FairValueChartsView({}: Props) {
    return (
        <FairValueChartsViewContainer>
            <div className="iframeContainer">
                <iframe
                    title="Company View - Fair Value Charts vLive (QS)"
                    src="https://app.powerbi.com/reportEmbed?reportId=883150ef-52f7-4b60-9d9c-7886052d1a56&autoAuth=true&ctid=bf4450a2-bfcb-472d-8c40-1425eafb1a3b&filterPaneEnabled=false"
                    width="100%"
                    height="100%"
                    allowFullScreen
                    frameBorder={0}
                />
            </div>
        </FairValueChartsViewContainer>
    );
}
