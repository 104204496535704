// @ts-nocheck
import { useHistory } from "react-router-dom";

// Markdown
import Markdown from "markdown-to-jsx";

// Components
import MarkdownImage from "components/Common/Markdown/MarkdownImage/MarkdownImage";

import componentListD from "documentation/config/dev/componentList";
import componentListU from "documentation/config/help/componentList";
import Example from "./Example/Example";
import PropsTable from "./PropsTable/PropsTable";
import FileTree from "../FileTree/FileTree";

import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { coldarkDark } from "react-syntax-highlighter/dist/esm/styles/prism";

// Styles
import MarkdownContainer from "./Markdown.styles";

function CodeBlock(props) {
    return (
        <SyntaxHighlighter language={"jsx"} style={coldarkDark}>
            {props.children.props.children}
        </SyntaxHighlighter>
    );
}

function Router(props) {
    const history = useHistory();

    const clicked = () => {
        if (props.href.includes("http")) {
            window.open(props.href);
        } else {
            history.push(props.href);
        }
    };

    return (
        <a className={"mdLink"} onClick={clicked}>
            {props.children}
        </a>
    );
}

type Props = {
    text: string;
};

export default function MarkdownWrapper({ text }: Props) {
    return (
        <MarkdownContainer>
            {text && (
                <Markdown
                    options={{
                        overrides: {
                            img: {
                                component: MarkdownImage,
                            },
                            ...componentListD,
                            ...componentListU,
                            pre: {
                                component: CodeBlock,
                            },
                            Example: {
                                component: Example,
                            },
                            PropsTable: {
                                component: PropsTable,
                            },
                            a: {
                                component: Router,
                            },
                            FileTree: {
                                component: FileTree,
                            },
                        },
                    }}
                >
                    {text}
                </Markdown>
            )}
        </MarkdownContainer>
    );
}
