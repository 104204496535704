import { useState } from "react";

// Material UI
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Popper from "@mui/material/Popper";

// DatePicker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// FontAwesome
import {
    faCaretDown,
    faSearch,
    faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Utils
import { toDate } from "utils/general.utils";

// Styles
import { FilterButtonContainer, PopUpContainer } from "./FilterButton.styles";

export default function FilterButton({
    options,
    type,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    day,
    setDay,
    value,
    setValue,
    disabled,
    searchable,
    name,
}: FilterOption) {
    const [open, setOpen] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<any>(null);

    const [results, setResults] = useState<Option[]>(options || []);
    const [searchString, setSearchString] = useState<string>("");

    const [sDate, setSDate] = useState<Date | null>(null);
    const [eDate, setEDate] = useState<Date | null>(null);
    const [date, setDate] = useState<Date | null>(null);

    const handleClick = (event: React.MouseEvent) => {
        setSearchString("");
        setResults(options || []);
        setAnchorEl(event.currentTarget);
        setOpen(!open);

        if (!startDate) {
            setSDate(null);
            setEDate(null);
        }

        if (!date) {
            setDate(null);
        }
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchString(event.target.value);
        setResults(
            options!.filter((option) =>
                option.label
                    .toLowerCase()
                    .includes(event.target.value.toLowerCase())
            )
        );
    };

    const handleSelectDate = (dates: [Date, Date]) => {
        const [start, end] = dates;
        setSDate(start);
        setEDate(end);

        if (start && end) {
            setStartDate!(start);
            setEndDate!(end);
            setOpen(false);
        }
    };

    const handleSelectDay = (day: Date) => {
        setDate(day);

        if (day) {
            setDay!(day);
            setOpen(false);
        }
    }

    const handleSelectFilter = (option: Option) => {
        setOpen(false);
        setValue!(option.value);
    };

    const getValue = () => {
        if (options?.length === 0) {
            return "";
        }

        if (type === "list")
            return options!.find((option: Option) => option.value === value)!
                .label;
        else if (type === "date" && startDate && endDate) {
            return toDate(startDate) + " - " + toDate(endDate);
        } else if (type === "singledate" && date) {
            return toDate(date);
        }
    };

    const isActive = () => {
        return value || startDate || date;
    };

    const clearFilter = () => {
        if (type === "list") setValue!(false);
        else if (type === "date") {
            setStartDate!(false);
            setEndDate!(false);
        } else if (type === "singledate") {
            setDate(null);
            setDay!(false);
        }
    };

    return (
        <>
            {!disabled && (
                <FilterButtonContainer
                    onClick={handleClick}
                    className={isActive() ? "filterBtn active" : "filterBtn"}
                >
                    {isActive() ? (
                        <p className="selected">{getValue()}</p>
                    ) : (
                        <>
                            <p>{name}</p>
                            <p className="value">ALL</p>
                        </>
                    )}
                    <FontAwesomeIcon icon={faCaretDown} fixedWidth />
                    {isActive() && (
                        <div
                            className="clearFilter"
                            onClick={(e) => {
                                e.stopPropagation();
                                clearFilter();
                            }}
                        >
                            <FontAwesomeIcon icon={faTimes} fixedWidth />
                        </div>
                    )}
                </FilterButtonContainer>
            )}

            <Popper
                open={open}
                anchorEl={anchorEl}
                disablePortal
                style={{ zIndex: 50 }}
            >
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                    <PopUpContainer>
                        {type === "list" && (
                            <>
                                {searchable && (
                                    <div className="searchBox">
                                        <input
                                            placeholder="Search..."
                                            value={searchString}
                                            onChange={handleSearch}
                                        />
                                        <FontAwesomeIcon
                                            icon={faSearch}
                                            fixedWidth
                                        />
                                    </div>
                                )}
                                <div className="options">
                                    {results.length > 0 ? (
                                        results.map((option, index) => (
                                            <div
                                                className="option"
                                                onClick={() =>
                                                    handleSelectFilter(option)
                                                }
                                                key={index}
                                            >
                                                <p>{option.label}</p>
                                            </div>
                                        ))
                                    ) : (
                                        <div className="noResults">
                                            <p>No Results</p>
                                        </div>
                                    )}
                                </div>
                            </>
                        )}

                        {type === "date" && (
                            <div className="date">
                                <DatePicker
                                    selected={sDate}
                                    onChange={handleSelectDate}
                                    startDate={sDate}
                                    endDate={eDate}
                                    selectsRange
                                    inline
                                />
                            </div>
                        )}

                        {type === "singledate" && (
                            <div className="date">
                                <DatePicker
                                    selected={date}
                                    onChange={handleSelectDay}
                                    inline
                                />
                            </div>
                        )}
                    </PopUpContainer>
                </ClickAwayListener>
            </Popper>
        </>
    );
}
