import styled from "styled-components";
import colors from "assets/styles/colors";

export default styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-width: 50px;
    max-width: 50px;
    min-height: 50px;
    max-height: 50px;
    cursor: pointer;

    svg {
        font-size: 25px;
        color: ${(props) => props.theme.permissionsCheck};
    }

    .addIcon {
        display: none;
        color: ${(props) => props.theme.permissionsAdd};
    }

    :hover {
        .addIcon {
            display: flex;
        }
    }

    &.disabled {
        cursor: not-allowed;

        .addIcon {
            display: none;
        }
    }
`;
