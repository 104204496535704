import React, { useEffect, useState } from "react";

// Styles
import EditcRatingAnswerContainer from "./EditRatingAnswer.styles";

// Types
import {GrowthAnswer} from "assets/interfaces/growth_voting";

// Components


interface Porps {
    answer: GrowthAnswer;
    addToUpdatedAnsweres: (updated: GrowthAnswer) => void;
    open: boolean|undefined;
}


const EditRatingAnswer  = ({answer, addToUpdatedAnsweres, open}: Porps) => {
    const [value, setValue] = useState<number|undefined>(undefined);

    const handleChange = (value: number) => {
        let updated: GrowthAnswer = {
            ...answer,
            answer_rating: value
        }

        addToUpdatedAnsweres(updated)
    }

    useEffect(() => {
        if (!open) return;

        if (answer.answer_rating === value) return;

        if (value !== undefined) {
            handleChange(value)
        }
    }, [value])

    useEffect(() => {
        if (answer.answer_rating === undefined) return;

        setValue(answer.answer_rating)
    }, [])

    return (
        <EditcRatingAnswerContainer>
            <div className={"ratings " + (!open ? 'closed' : '')}>
                <div onClick={() => setValue(1)} className={"rating rating-1 " + (value == 1 ? 'selected' : '')}>
                    <span>1</span>
                </div>
                <div onClick={() => setValue(2)} className={"rating rating-2 " + (value == 2 ? 'selected' : '')}>
                    <span>2</span>
                </div>
                <div onClick={() => setValue(3)} className={"rating rating-3 " + (value == 3 ? 'selected' : '')}>
                    <span>3</span>
                </div>
                <div onClick={() => setValue(4)} className={"rating rating-4 " + (value == 4 ? 'selected' : '')}>
                    <span>4</span>
                </div>
                <div onClick={() => setValue(5)} className={"rating rating-5 " + (value == 5 ? 'selected' : '')}>
                    <span>5</span>
                </div>
            </div>
        </EditcRatingAnswerContainer>
    );
};

export default EditRatingAnswer
