import { useState } from "react";

// Components
import Button from "components/Common/Button/Button";
import TextField from "components/Common/TextField/TextField";
import Modal from "components/Common/Modal/Modal";

// Context
import { useSettings } from "context/Settings.context";

// Utils
import * as Database from "utils/database.utils";

// Styles
import NewPermissionModalContainer from "./NewPermissionModal.styles";

type Props = {
    setShowModal: Function;
    reload: Function;
};

export default function NewPermissionModal({ setShowModal, reload }: Props) {
    const { setToast } = useSettings();

    const [loading, setLoading] = useState<boolean>(false);

    const [name, setName] = useState<string>("");
    const [desc, setDesc] = useState<string>("");

    const [errors, setErrors] = useState<any>({ name: false, desc: false });

    const save = async () => {
        setLoading(true);

        let data = {
            name: name,
            desc: desc,
        };

        let res = await Database.post("api/permissions", data);

        if (res.ok) {
            setToast({
                show: true,
                type: "success",
                message: "Permission has been added",
            });

            setShowModal(false);
            reload();
        }
    };

    const checkFields = () => {
        let error: any = {};

        if (!name) error.name = true;
        if (!desc) error.desc = true;

        if (Object.keys(error).length === 0) {
            setErrors({});
            save();
        } else {
            setErrors(error);
        }
    };

    return (
        <NewPermissionModalContainer>
            <Modal loading={loading}>
                <div className="main">
                    <div className="text">
                        <h3>{"NEW PERMISSION"} </h3>
                    </div>
                    <label>Permissions Name</label>

                    <TextField
                        placeholder="Permissions Name"
                        change={setName}
                        value={name}
                        error={errors.name}
                    />
                    <label className="mt20">Description</label>

                    <TextField
                        placeholder="Description"
                        change={setDesc}
                        value={desc}
                        error={errors.desc}
                    />
                </div>

                <div className="actions">
                    <Button
                        text="CANCEL"
                        color="flat"
                        click={() => setShowModal(false)}
                    />
                    <Button text="SAVE" click={checkFields} />
                </div>
            </Modal>
        </NewPermissionModalContainer>
    );
}
