import styled from 'styled-components';

export default styled.div`
    .ratings {
        display: flex;
        align-items: flex-start;
        .rating {
            padding: 6px 12px;
            font-weight: normal;
            margin-right: 10px;
            border-radius: 5px;
            border: 1px solid  ${(props) => props.theme.labelSecondaryBg};
            transition: background-color 150ms linear;
            cursor: pointer;
            &:hover {
                box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
            }
        }
        .selected
            .rating {
                transition: background-color 100ms linear;
                &:hover {
                        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
                    }
            }

            .selected.rating-1 {
                background-color: ${(props) => props.theme.bqmqVoteScore1};
            }

            .selected.rating-2 {
                background-color: ${(props) => props.theme.bqmqVoteScore2};
            }

            .selected.rating-3 {
                background-color: ${(props) => props.theme.bqmqVoteScore3};
            }
            .selected.rating-4{
                background-color: ${(props) => props.theme.bqmqVoteScore4};
            }

            .selected.rating-5 {
                background-color: ${(props) => props.theme.bqmqVoteScore5};
            }
        }
    }

    .closed {
        pointer-events:none;
        cursor: not-allowed;
        pointer: not-allowed;
    }
`;
