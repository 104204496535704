import styled from "styled-components";

export default styled.div`
    display: flex;
    flex-direction: column;

    margin-top: 20px;
    margin-bottom: 20px;

    .heading {
        display: flex;
        justify-content: center;
        align-items: center;

        position: relative;

        min-height: 50px;
        max-height: 50px;

        border-radius: 5px 5px 0 0;

        background-color: ${(props) => props.theme.homeListTitleRowBackground};
        color: ${(props) => props.theme.homeListTitleRowText};

        font-size: 20px;

        .toggles {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            right: 10px;
            .resetBtn {
                display: flex;
                margin-left: 10px;
            }
            .allCoreBtn {
                border-radius: 5px;
                background-color: #efefef;
                cursor: pointer;
                font-weight: bolder;
                font-size: 18px;
                &.core {
                    color: ${(props) =>
                        props.theme.subMenuBadgeBackgroundSecondary};

                    &.active {
                        color: white;
                        background-color: ${(props) =>
                            props.theme.subMenuBadgeBackgroundSecondary};
                    }
                }
                :hover {
                    filter: brightness(0.9);
                }
            }
        }
    }

    .userRow {
        display: flex;
        flex-direction: row;

        min-height: 60px;
        max-height: 60px;

        min-width: 100%;

        border-right: 1px solid
            ${(props) => props.theme.homeListBodyBorderColor};
        border-left: 1px solid ${(props) => props.theme.homeListBodyBorderColor};
        border-bottom: 1px solid
            ${(props) => props.theme.homeListBodyBorderColor};

        .icon {
            display: flex;
            justify-content: center;
            align-items: center;

            min-height: 60px;
            max-height: 60px;
            min-width: 50px;
            max-width: 50px;

            font-size: 20px;

            color: ${(props) => props.theme.homeListTitleRowBackground};
            /* color: ${(props) => props.theme.primaryButtonBackground}; */
            &.core {
                color: ${(props) =>
                    props.theme.subMenuBadgeBackgroundSecondary};
            }
        }

        .name {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            flex-grow: 1;
            min-height: 60px;
            max-height: 60px;
            color: ${(props) => props.theme.textPrimary};

            .coreTag {
                display: flex;
                margin-left: 10px;

                padding: 3px 10px;
                border-radius: 5px;

                color: white;
                background-color: ${(props) =>
                    props.theme.subMenuBadgeBackgroundSecondary};

                &.chair,
                &.analyst {
                    background-color: ${(props) =>
                        props.theme.subMenuBadgeBackground};
                }
            }
        }

        .actions {
            display: flex;
            flex-direction: row;

            padding: 8px;

            .actionBtn {
                display: flex;
                justify-content: center;
                align-items: center;

                margin: 0 8px;
                padding: 0 20px;

                border-radius: 5px;

                background-color: #efefef;

                cursor: pointer;

                user-select: none;

                font-weight: bolder;
                font-size: 18px;

                &.core {
                    color: ${(props) =>
                        props.theme.subMenuBadgeBackgroundSecondary};

                    &.active {
                        color: white;
                        background-color: ${(props) =>
                            props.theme.subMenuBadgeBackgroundSecondary};
                    }
                }

                &.all {
                    color: #004453;

                    &.active {
                        color: white;
                        background-color: #004453;
                    }
                }

                :hover {
                    filter: brightness(0.9);
                }
            }
        }
    }
    .searchRow {
        display: flex;
        flex-direction: row;

        justify-content: space-between;
        align-items: center;

        min-height: 50px;
        max-height: 50px;
        min-width: 100%;

        border-right: 1px solid
            ${(props) => props.theme.homeListBodyBorderColor};
        border-left: 1px solid ${(props) => props.theme.homeListBodyBorderColor};
        border-bottom: 1px solid
            ${(props) => props.theme.homeListBodyBorderColor};

        .searchBox {
            display: flex;
            justify-content: center;
            align-items: center;

            border-radius: 0 5px 5px 0;
            border: none;
            outline: none;
            padding: 10px;

            background-color: transparent;

            color: ${(props) => props.theme.textPrimary};

            font-size: 18px;

            flex-grow: 1;
        }
        .searchIcon {
            display: flex;
            justify-content: center;
            align-items: center;

            min-width: 50px;
            max-width: 50px;

            color: ${(props) => props.theme.textPrimary};
        }
    }

    .loader {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 1;

        border-radius: 0 0 5px 5px;

        min-width: 100%;
        max-width: 100%;
        min-height: 50vh;

        border-right: 1px solid
            ${(props) => props.theme.homeListBodyBorderColor};
        border-left: 1px solid ${(props) => props.theme.homeListBodyBorderColor};
        border-bottom: 1px solid
            ${(props) => props.theme.homeListBodyBorderColor};
    }
`;
