import styled from "styled-components";
import colors from "assets/styles/colors";

const StatusContainer = styled.div`
    align-items: center;
    display: flex;
    cursor: pointer;

    &.true {
        cursor: help;
    }

    .text {
        flex-direction: column;
        display: flex;
        p {
            margin: 0;
            font-weight: bolder;
            font-size: 16px;
        }

        .ignoredInfo {
            color: #777777;
            font-size: 12px;
            margin-top: -5px;
        }
    }

    .icon {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        color: white;
        display: flex;

        justify-content: center;
        align-items: center;

        margin-right: 10px;

        svg {
            width: 12px;
            height: 12px;
            font-size: 12px;
        }
    }

    &.blue {
        color: ${colors.accentBlue};
        .icon {
            background-color: ${colors.accentBlue};
        }
    }
    &.grey {
        color: #777777;
        .icon {
            background-color: #777777;
        }
    }
    &.orange {
        color: ${colors.accentOrange};
        .icon {
            background-color: ${colors.accentOrange};
        }
    }
    &.red {
        color: ${colors.accentRed};
        .icon {
            background-color: ${colors.accentRed};
        }
    }
    &.green {
        color: ${colors.accentGreen};
        .icon {
            background-color: ${colors.accentGreen};
        }
    }
`;

export default StatusContainer;
