// Styles
import FileUploadContainer from "./FileUpload.styles";
import React, { useEffect, useState } from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUpload} from "@fortawesome/free-solid-svg-icons";

type Props = {
    setSelectedFile: Function,
    selectedFile: any
};

export default function FileUpload({ selectedFile, setSelectedFile }: Props) {
    // TODO: implement any general file validation rules
    const [errors, setErrors] = useState<any>(false);

    const onSelectFile = (file: any) => {
        setSelectedFile(file)
    }

    const removeFile = () => {
        setSelectedFile(null)
    }

    return (
        <FileUploadContainer>
             <div className="form">
                    <label
                        className={"uploadBox " + (errors.length > 0 && !selectedFile)}
                        htmlFor="getFile"
                        onDrop={(e) => {
                            e.preventDefault();
                            setSelectedFile(e.dataTransfer.items[0].getAsFile());
                        }}
                        onDragOver={(e) => e.preventDefault()}
                    >
                        <FontAwesomeIcon icon={faUpload} fixedWidth />
                        <p>{selectedFile ? selectedFile.name : "Select a file ..."}</p>
                    </label>

                    <div className={"remove-container"} onClick={removeFile}>Remove</div>

                    <input
                        type="file"
                        id="getFile"
                        className="inputHide"
                        onChange={(e) => onSelectFile(e.target.files![0])}
                    />
                </div>
        </FileUploadContainer>
    );
}
