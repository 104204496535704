import React, { useEffect, useState } from "react";

// Styles
import SelectUsersModalContainer from "./SelectUsersModal.styles";

// Utils
import { useSettings } from "context/Settings.context";
import * as Database from "utils/database.utils";
// Components
import Modal from "components/Common/Modal/Modal";
import ButtonSmall from "../../../Common/ButtonSmall/ButtonSmall";
import SelectUsers from "../SelectUsers/SelectUsers";
import {faClose} from "@fortawesome/free-solid-svg-icons";
import {DealTeamMember} from "assets/interfaces/growth_voting";
import Button from "../../../Common/Button/Button";


interface CreateGrowthVotingModalProps {
    showModal: boolean;
    setShowModal: Function;
    selectedUsers: DealTeamMember[];
    setSelectedUsers: React.Dispatch<React.SetStateAction<DealTeamMember[]>>;
}


const CreateGrowthVotingModal  = ({showModal, setShowModal, selectedUsers, setSelectedUsers}: CreateGrowthVotingModalProps) => {
    const [loading, setLoading] = useState<boolean>(false);

    return (
        <SelectUsersModalContainer>
            <Modal loading={loading} width={"400px"}>
                <div className={"btn-close-container"}>
                    <ButtonSmall
                        color={"flat"}
                        text={"Close"}
                        icon={faClose}
                        click={() => {setShowModal(!showModal)}}
                    />
                </div>
                <SelectUsers selectedUsers={selectedUsers} setSelectedUsers={setSelectedUsers}/>
                <div className={"btn-add-container"}>
                    <Button text="OK" color="primary"   click={() => {setShowModal(!showModal)}} />
                </div>
            </Modal>
        </SelectUsersModalContainer>
    );
};

export default CreateGrowthVotingModal
