import { useState } from "react";

// Styles
import PrioritisedVotesContainer from "./PrioritisedVotes.styles";

// Components
import PageTitle from "components/Common/PageTitle/PageTitle";
import Table from "components/Common/Table/Table";
import TableCell from "components/Common/TableCell/TableCell";

// Router
import { useHistory } from "react-router-dom";
import { applyTimeZoneOffset } from "utils/common.utils";
// FontAwesome
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Material UI
import Tooltip from "@mui/material/Tooltip";

// Helper Functions
import { percentageFormatter } from "utils/bqmq.utils";

interface PrioritisedVotesProps {}

const PrioritisedVotes = ({}: PrioritisedVotesProps) => {
    const history = useHistory();

    const [reload, setReload] = useState<number>(0);
    const [filterQuery, setFilterQuery] = useState<string>("");

    const getReason = (data: any) => {
        if (data.sessions.length === 0) {
            return "No voting data";
        } else {
            return `Not voted on since ${applyTimeZoneOffset(
                data.sessions
                    .map((session: any) => session.date)
                    .sort((a: any, b: any) => a.localeCompare(b))
                    .reverse()[0]
            )}`;
        }
    };

    const columns = [
        {
            id: 2,
            title: "COMPANY",
            sortable: true,
            value: "company_name",
            cell: (data: any) => <TableCell data={data.name} />,
            show: true,
            fullDataRow: true,
        },
        {
            id: 3,
            title: "WEIGHT %",
            sortable: true,
            value: "weight",
            cell: (data: any) => (
                <Tooltip title="This is the weight as of the end of yesterday.">
                    <p>{(Number(data.portfolio_weight) * 100).toFixed(2)}%</p>
                </Tooltip>
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 6,
            title: "UPSIDE %",
            sortable: true,
            value: "upside",
            cell: (data: any) => {
                const upside: string = percentageFormatter(
                    data.fair_value,
                    data.price
                );
                return (
                    <>
                        <span>{upside}</span>
                    </>
                );
            },

            show: true,
            fullDataRow: true,
        },
        {
            id: 4,
            title: "REASON",
            sortable: true,
            value: "reason",
            cell: (data: any) => (
                <>
                    <span>{getReason(data)}</span>
                </>
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 5,
            title: "VIEW VOTES",
            sortable: false,
            value: "",
            cell: (data: any) =>
                !getReason(data).includes("No voting data") && (
                    <Tooltip title={"VIEW"}>
                        <div className="viewIcon">
                            <FontAwesomeIcon
                                icon={faArrowRight}
                                fixedWidth
                                onClick={() => {
                                    history.push(
                                        `/voting/result/company?company_id=${
                                            data.id
                                        }&company_name=${encodeURIComponent(
                                            data.name
                                        )}`
                                    );
                                }}
                            />
                        </div>
                    </Tooltip>
                ),
            show: true,
            fullDataRow: true,
        },
    ];

    return (
        <PrioritisedVotesContainer>
            <PageTitle title="PRIORITISED VOTES" />
            <Table
                columns={columns}
                reload={reload}
                filterQuery={filterQuery}
                pagination={false}
                url="api/bqmq_voting/prioritized-sessions/"
                defaultSortKey="name"
                defaultSortOrder="ASC"
            />
        </PrioritisedVotesContainer>
    );
};
export default PrioritisedVotes;
