import { Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
// Material UI
import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

// FontAwesome
import {
    faClock,
    faTimesCircle,
    IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Utils
import * as Database from "utils/database.utils";
import { getHeaderCell, setGroupCols } from "./TableHelpers";

// Context
import { useSettings } from "context/Settings.context";

// Components
import SelectedOptions from "components/Common/SelectedOptions/SelectedOptions";
import SkeletonLoader from "../SkeletonLoader/SkeletonLoader";

// Styles
import TableWrapper from "./Table.styles";

type Props = {
    url?: string;
    data?: any[];
    columns: Column[];
    total?: number;
    changePageQuery?: Function;
    filterQuery: string;
    defaultSortKey?: string;
    defaultSortOrder?: string;
    defaultLimit?: number;
    pagination?: boolean;
    large?: boolean;
    emptyText?: string;
    emptyIcon?: IconDefinition;
    showEmptyIcon?: boolean;
    selectable?: string;
    noStickyHeaders?: boolean;
    reload?: number;
    loading?: boolean;
    skeletonLength?: number;
    onRowClick?: Function;
    showHeader?: boolean;
    stickyColumns?: number[];
};

const GimTable: React.FC<Props> = ({
    url,
    data = [],
    columns,
    total,
    changePageQuery = () => {},
    filterQuery,
    defaultSortKey,
    defaultSortOrder,
    defaultLimit,
    pagination,
    large,
    emptyText = "NO RESULTS",
    emptyIcon = faTimesCircle,
    showEmptyIcon = true,
    selectable,
    noStickyHeaders = false,
    reload,
    loading = false,
    skeletonLength = 14,
    onRowClick,
    showHeader = false,
    stickyColumns = [],
}) => {
    const {
        showLoadingToast,
        setShowLoadingToast,
        stickyHeaders,
        subMenuWidth,
    } = useSettings();

    const [rows, setRows] = useState<any[]>(data);

    const [page, setPage] = useState<number>(1);
    const [count, setCount] = useState<number>(0);
    const [limit, setLimit] = useState<number>(defaultLimit || 25);
    const [sortKey, setSortKey] = useState<string | null>(
        defaultSortKey || null
    );
    const [sortOrder, setSortOrder] = useState<string>(
        defaultSortOrder || "ASC"
    ); //ASC, DESC

    const [pageQuery, setPageQuery] = useState<string>("");

    const [maxHeight, setMaxHeight] = useState<number | string>("unset");

    const [selectedCount, setSelectedCount] = useState<number>(0);
    const [allSelected, setAllSelected] = useState<boolean>(false);

    const [isScrolling, setIsScrolling] = useState<boolean>(false);

    const handleChangePage = (e: unknown, newPage: number) => {
        setPage(newPage + 1);
    };

    const changePageLimit = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLimit(Number(e.target.value));
        setPage(1);
    };

    const changeSort = (column: Column) => {
        if (column.sortable) {
            if (sortKey === column.value) {
                setSortOrder(sortOrder === "DESC" ? "ASC" : "DESC");
            }
            setSortKey(column.value);
            setPage(1);
        }
    };

    const getData = () => {
        setShowLoadingToast({ show: true, message: "LOADING" });
        sessionStorage.setItem("lastSearch", pageQuery + filterQuery);
        Database.get(url + pageQuery + filterQuery).then((res) => {
            if (res.ok) {
                res.json().then((data) => {
                    if (
                        pageQuery + filterQuery ===
                        sessionStorage.getItem("lastSearch")
                    ) {
                        scrollToTop();
                        let id = 0;
                        setRows(
                            data.data.map((row: any) => ({
                                ...row,
                                tableID: id++,
                                selected: false,
                            }))
                        );

                        setCount(data.total);
                        setShowLoadingToast({
                            show: false,
                            message: "LOADING",
                        });
                    }
                });
            }
        });
    };

    const handleSelection = (selectedIndex: number) => {
        let newList = rows.map((item, index) => {
            if (index === selectedIndex) {
                return {
                    ...item,
                    selected: !rows[selectedIndex].selected,
                };
            } else {
                return {
                    ...item,
                };
            }
        });
        setRows(newList);
    };

    const getSelectedCount = () => {
        let selectedCount = 0;

        rows.forEach((item) => {
            if (item.selected) selectedCount++;
        });

        setAllSelected(selectedCount === rows.length);
        setSelectedCount(selectedCount);
    };

    const handleSelectAll = () => {
        let newList = rows.map((item) => {
            return {
                ...item,
                selected: true,
            };
        });

        setRows(newList);
    };

    const handleSelectNone = () => {
        let newList = rows.map((item) => {
            return {
                ...item,
                selected: false,
            };
        });

        setRows(newList);
    };

    const getMaxHeight = () => {
        let windowHeight = document.body.clientHeight;

        let tableContainer = document.getElementById("tableContScroll");

        let rect = tableContainer!.getBoundingClientRect();
        let height = windowHeight - 20;

        if (rect.y < 30) {
            setMaxHeight(height);
        } else {
            setMaxHeight("unset");
        }
    };

    const buildPageQuery = () => {
        const query = `?pageSize=${limit}&page=${page}&sortKey=${sortKey}&sortOrder=${sortOrder}`;
        setPageQuery(query);
        changePageQuery(query);
    };

    const scrollToTop = () => {
        let main = document.getElementById("mainContainer");

        main!.scrollTo(0, 0);
    };

    // const startDrag = (e: React.MouseEvent) => {
    //     setIsScrolling(true);
    //     console.log(1);
    //     window.addEventListener("mouseup", stopDrag, false);
    //     window.addEventListener("mousemove", doScroll, false);
    // };

    // const stopDrag = (e: any) => {
    //     console.log(2);
    //     window.removeEventListener("mouseup", stopDrag, false);
    //     window.removeEventListener("mousemove", doScroll, false);
    //     document.querySelector(".scroll")?.classList.remove("active");
    //     setIsScrolling(false);
    // };

    // const doScroll = (e: MouseEvent) => {
    //     console.log("...scrolling", isScrolling);
    //     const table = document.querySelector(".scroll") as HTMLElement;

    //     table.classList.add("active");
    //     const x = e.pageX - table.offsetLeft - subMenuWidth - 200;
    //     table.scrollLeft = x;
    // };

    useEffect(() => {
        buildPageQuery();
    }, [limit, page, sortKey, sortOrder]);

    useEffect(() => {
        getSelectedCount();
    }, [rows]);

    useEffect(() => {
        if (!url) {
            setRows(data);
        }

        if (!url && !pagination) {
            setCount(data.length);
        }
    }, [data]);

    useEffect(() => {
        if (url && pageQuery !== "") {
            getData();
        }
    }, [reload, filterQuery, pageQuery]);

    useEffect(() => {
        setPage(1);
    }, [filterQuery]);

    useEffect(() => {
        let mainContainer = document.getElementById("mainContainer");

        if (stickyHeaders && !noStickyHeaders) {
            mainContainer!.addEventListener("scroll", getMaxHeight);
        } else {
            setMaxHeight("unset");
        }

        return () => {
            mainContainer!.removeEventListener("scroll", getMaxHeight);
        };
    }, [rows, stickyHeaders, noStickyHeaders]);

    return (
        // <ErrorBoundary key={String(window.location)}>
        <TableWrapper id="tableContScroll">
            {!loading && (rows.length > 0 || showHeader) && (
                <TableContainer
                    className="scroll"
                    style={{
                        maxHeight: maxHeight,
                        width: "100%",
                        overflowX: "auto",
                    }}
                    // onMouseDown={startDrag}
                >
                    <Table
                        className={large ? "table large" : "table"}
                        aria-labelledby="tableTitle"
                        size={"small"}
                        aria-label="enhanced table"
                        stickyHeader
                    >
                        <TableHead>
                            <TableRow key={"groups"}>
                                {setGroupCols(columns)}
                            </TableRow>
                            <TableRow key={"headers"}>
                                {selectable && (
                                    <TableCell
                                        className="tableHeadCell"
                                        padding="checkbox"
                                    >
                                        <Checkbox
                                            checked={allSelected}
                                            onClick={
                                                allSelected
                                                    ? handleSelectNone
                                                    : handleSelectAll
                                            }
                                        />
                                    </TableCell>
                                )}
                                {columns.map(
                                    (column, index) =>
                                        column.show &&
                                        (column.tooltip ? (
                                            <Tooltip
                                                title={column.tooltip}
                                                key={index}
                                            >
                                                {getHeaderCell(
                                                    column,
                                                    sortKey,
                                                    changeSort,
                                                    stickyColumns.includes(
                                                        index
                                                    )
                                                )}
                                            </Tooltip>
                                        ) : (
                                            getHeaderCell(
                                                column,
                                                sortKey,
                                                changeSort,
                                                stickyColumns.includes(index)
                                            )
                                        ))
                                )}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {rows.map((row, index) => (
                                <TableRow
                                    hover
                                    className={
                                        (row.disabled ? "disabled " : "") +
                                        (onRowClick ? "clickable" : "")
                                    }
                                    key={index}
                                    selected={row.selected}
                                    onClick={
                                        onRowClick
                                            ? () => onRowClick(row)
                                            : () => {}
                                    }
                                >
                                    {selectable && (
                                        <TableCell padding="checkbox">
                                            {!row.disabled ? (
                                                <Checkbox
                                                    checked={row.selected}
                                                    onClick={() =>
                                                        handleSelection(index)
                                                    }
                                                />
                                            ) : (
                                                <Tooltip
                                                    title="PROCESSING"
                                                    placement="left"
                                                >
                                                    <div className="disabledIcon">
                                                        <FontAwesomeIcon
                                                            icon={faClock}
                                                            fixedWidth
                                                        />
                                                    </div>
                                                </Tooltip>
                                            )}
                                        </TableCell>
                                    )}

                                    {columns.map(
                                        (column, index) =>
                                            column.show && (
                                                <TableCell
                                                    key={index}
                                                    className={
                                                        stickyColumns.includes(
                                                            index
                                                        )
                                                            ? "stickyCell"
                                                            : ""
                                                    }
                                                >
                                                    {column.fullDataRow
                                                        ? column.cell(row)
                                                        : column.cell(
                                                              row[column.value]
                                                          )}
                                                </TableCell>
                                            )
                                    )}
                                </TableRow>
                            ))}

                            {pagination && (
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25, 50]}
                                        count={total ? total : count}
                                        rowsPerPage={limit}
                                        page={page - 1}
                                        onPageChange={handleChangePage}
                                        // component="div"
                                        onRowsPerPageChange={changePageLimit}
                                    />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            {(loading || rows.length === 0) && (
                <div>
                    {loading || showLoadingToast.show ? (
                        <>
                            <SkeletonLoader
                                type="table"
                                rows={skeletonLength}
                            />
                        </>
                    ) : (
                        <div className="noData">
                            {showEmptyIcon && <FontAwesomeIcon icon={emptyIcon} fixedWidth />}
                            <p>{emptyText}</p>
                        </div>
                    )}
                </div>
            )}

            {selectedCount > 0 && (
                <SelectedOptions
                    selectedCount={selectedCount}
                    data={rows}
                    setData={setRows}
                    reload={getData}
                    type={selectable}
                />
            )}
        </TableWrapper>
        // </ErrorBoundary>
    );
};

export default GimTable;
