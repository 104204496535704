import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// Utils
import * as Database from "utils/database.utils";

// Components
import HomeList from "../../../Home/HomeList/HomeList";
import StockSplitRow from "./StockSplitRow/StockSplitRow";

// Styles
import StockSplitListContainer from "./StockSplitList.styles";

export default function StockSplitList() {
    const history = useHistory();

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);

    const getFailedExtractions = async () => {
        let res = await Database.get(
            "api/stock_splits/auto_detected?page=1&pageSize=1000&sortKey=detected_datetime&sortOrder=ASC"
        );
        let data = await res.json();

        setData(data.data);
        setTotal(data.total);
        setLoading(false);
    };

    useEffect(() => {
        setLoading(true);
        getFailedExtractions();
    }, []);

    return (
        <StockSplitListContainer>
            <HomeList
                data={data}
                row={(row: any) => <StockSplitRow data={row} />}
                title={"STOCK SPLITS"}
                count={total}
                countColor={""}
                loading={loading}
                seeAll={() => history.push("/data/stock-splits")}
                maxRows={5}
            />
        </StockSplitListContainer>
    );
}
