import styled from "styled-components";
import colors from "assets/styles/colors";

export default styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 -10px;

    .count {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 10px;

        svg {
            margin-right: 5px;
            font-size: 16px;
        }

        p {
            margin: 0;
        }

        &.blue {
            color: ${colors.textTeal};
        }
        &.orange {
            color: ${colors.accentOrange};
        }
        &.red {
            color: ${colors.accentRed};
        }
    }
`;
