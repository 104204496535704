import { useEffect, useState } from "react";

// FontAwesome
import {
    faArrowRight,
    faCheck,
    faSearch,
    faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Styles
import SelectHostContainer from "./SelectHost.styles";
import LoadingSpinner from "../../../../Common/LoadingSpinner/LoadingSpinner";

type Props = {
    chair: any;
    setChair: Function;
    users: any[];
    setUsers: Function;
};

export default function SelectHost({
    chair,
    setChair,
    users,
    setUsers,
}: Props) {
    const [searchString, setSearchString] = useState("");
    const [filteredUserList, setFilteredUserList] = useState<any[]>(users);

    const selectChair = (item: any) => {
        setChair(item);

        let newUsers = users.map((user) => {
            if (user.id === item.id) {
                return { ...user, core: true, invited: false };
            } else {
                return user;
            }
        });

        setUsers(newUsers);
    };

    useEffect(() => {
        setFilteredUserList(
            users.filter((user) =>
                user.name.toLowerCase().includes(searchString.toLowerCase())
            )
        );
    }, [users, searchString]);

    return (
        <SelectHostContainer>
            <div className={"heading"}>
                <p>SELECT CHAIR</p>
            </div>

            <div className={"searchRow"}>
                <div className={"searchIcon"}>
                    <FontAwesomeIcon icon={faSearch} fixedWidth />
                </div>

                <input
                    data-cy="user-search"
                    className={"searchBox"}
                    placeholder={"Search Attendees"}
                    value={searchString}
                    onChange={(e) => setSearchString(e.target.value)}
                />
            </div>

            {filteredUserList.map((item: any) => (
                <div
                    className={
                        "userRow " +
                        (chair && chair.id !== item.id ? "inactive " : "") +
                        (chair.id === item.id ? "active" : "")
                    }
                    key={item.id}
                    onClick={() => selectChair(item)}
                >
                    <div className={"icon"}>
                        <FontAwesomeIcon icon={faUser} fixedWidth />
                    </div>

                    <p data-cy="user-searched-name" className={"name"}>
                        {item.name}
                    </p>

                    <div className={"selectBtn"}>
                        <FontAwesomeIcon
                            icon={chair.id === item.id ? faCheck : faArrowRight}
                            fixedWidth
                        />
                    </div>
                </div>
            ))}

            {users.length === 0 && (
                <div className="loader">
                    <LoadingSpinner message={"LOADING ANALYSTS"} />
                </div>
            )}
        </SelectHostContainer>
    );
}
