import colors from "assets/styles/colors";

export default {
    tabsBottomBorder: colors.darkThemeMain,
    tabItemText: colors.darkThemeLight,

    tabItemBorderActive: colors.darkThemeLight,
    tabItemTextActive: "white",

    tabItemBorderHover: colors.darkThemeMain,
    tabItemBackgroundHover: colors.darkThemeMain,
    tabItemTextHover: colors.grey60,
};
