import React from "react";

// Styles
import SFDRWebFormViewContainer from "./SFDRWebFormView.styles";

interface SFDRWebFormViewProps {}

const SFDRWebFormView = ({}: SFDRWebFormViewProps) => {
    return (
        <SFDRWebFormViewContainer>
            <div className="iframeContainer">
                <iframe
                    title="ESG - SFDR Web Form vLive (QS)"
                    src="https://app.powerbi.com/reportEmbed?reportId=3376e27c-93f5-42c5-b877-be1e5de12970&autoAuth=true&ctid=bf4450a2-bfcb-472d-8c40-1425eafb1a3b&filterPaneEnabled=false"
                    width="100%"
                    height="100%"
                    allowFullScreen
                    frameBorder={0}
                />
            </div>
        </SFDRWebFormViewContainer>
    );
};
export default SFDRWebFormView;
