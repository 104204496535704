import colors from "assets/styles/colors";

export default {
    primaryButtonBackground: colors.accentBlue,
    primaryButtonForeground: "white",
    primaryButtonBackgroundHover: colors.darkAccentBlue,

    secondaryButtonBackground: colors.teal80,
    secondaryButtonForeground: "white",
    secondaryButtonBackgroundHover: colors.teal100,

    thirdButtonBackground: colors.grey90,
    thirdButtonForeground: colors.grey40,
    thirdButtonBackgroundHover: colors.grey80,

    accentButtonBackground: colors.accentRed,
    accentButtonForeground: "white",
    accentButtonBackgroundHover: colors.accentRedDark,

    flatButtonBackground: "unset",
    flatButtonForeground: colors.grey40,
    flatButtonBackgroundHover: colors.grey90,

    squareButtonBackground: colors.grey90,
    squareButtonText: "white",
    squareButtonIcon: colors.accentBlue,
    squareButtonBackgroundHover: colors.grey70,
    squareButtonCount: colors.accentRed,
    squareButtonBorder: "3px solid transparent",
    squareButtonBorderActive: colors.darkAccentBlue,
    squaredButtonFailed: colors.accentRed,
    squaredButtonHolding: colors.accentOrange,
    squaredButtonSuccess: colors.accentGreen,

    notificationButtonBackground: colors.fillOlive,
    notificationButtonForeground: colors.textGrey,
};
