// Components
import PageTitle from "components/Common/PageTitle/PageTitle";
import Tabs from "components/Common/Tabs/Tabs";
import NotificationsList from "components/Screens/Notifications/NotificationsList/NotificationsList";
import Settings from "components/Screens/Notifications/Settings/Settings";

// Styles
import NotificationsContainer from "./Notifications.styles";

const Notifications: React.FC = () => {
    return (
        <NotificationsContainer>
            <PageTitle title="NOTIFICATIONS" />
            <Tabs
                defaultTab="list"
                tabs={[
                    {
                        value: "list",
                        title: "VIEW",
                        content: <NotificationsList />,
                    },
                    {
                        value: "settings",
                        title: "SETTINGS",
                        content: <Settings />,
                        permissions: ["s_update_permissions"],
                    },
                ]}
            />
        </NotificationsContainer>
    );
};

export default Notifications;
