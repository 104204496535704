import { RefObject } from "react";

const calculateMaxHeight = (
    tableRef: RefObject<HTMLDivElement>,
    setMaxHeight: Function
) => {
    const content = tableRef.current;
    if (content) {
        const windowHeight = window.innerHeight;
        const contentTop = content.getBoundingClientRect().top;
        const spaceAboveContent = windowHeight - contentTop;
        if (spaceAboveContent > 0) {
            setMaxHeight(`${spaceAboveContent}px`);
        }
    }
};

export default calculateMaxHeight;
