import styled from "styled-components";

export const DateSelectContainer = styled.div`
    .dateBox {
        align-items: center;
        max-width: 250px;
        min-width: 250px;
        height: 40px;
        display: flex;

        cursor: pointer;

        background-color: ${(props) => props.theme.filterButtonBackground};
        color: ${(props) => props.theme.filterButtonForeground};

        border-radius: 5px;
        overflow: hidden;

        p {
            margin: 0 15px;
            flex-grow: 1;
            font-size: 18px;
        }

        svg {
            margin: 0 10px;
            color: ${(props) => props.theme.dropDownSearchBoxIcon};
        }

        :hover {
            background-color: ${(props) =>
                props.theme.filterButtonBackgroundHover};
            input {
                background-color: ${(props) =>
                    props.theme.filterButtonBackgroundHover};
            }
        }
        &.error {
            border: 2px solid lightcoral;
            p {
                margin: 0 15px 0 13px;
            }
        }
    }
`;

export const DateSelectPopupContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
    box-shadow: 0px 5px 15px 5px rgba(0, 0, 0, 0.28);
    margin-top: 5px;

    div {
        display: block;
    }
    .react-datepicker__day-name,
    .react-datepicker__day {
        display: inline-block;
    }
`;
