import { useEffect, useState } from "react";

// Styles
import MorningMeetingDetailContainer from "./MorningMeetingDetail.styles";

// Font Awesome
import { faArrowLeft, faDownload } from "@fortawesome/free-solid-svg-icons";

// Utils
import * as Database from "utils/database.utils";

// Components
import Button from "components/Common/Button/Button";
import LoadingSpinner from "components/Common/LoadingSpinner/LoadingSpinner";
import PageTitle from "components/Common/PageTitle/PageTitle";
import MeetingDetailBoxes from "components/Screens/Research/MeetingDetailBoxes/MeetingDetailBoxes";
import SearchOptions from "components/Common/SearchOptions/SearchOptions";
import MorningMeetingNotes from "../MorningMeetingNotes/MorningMeetingNotes";
import { useSettings } from "context/Settings.context";
import { exportFromS3 } from "utils/export.utils";
// React Router
import { useHistory } from "react-router-dom";

// Context
import { useBQMQ } from "context/BQMQ.context";

type AnalystType = {
    active: boolean;
    label: string;
    value: string;
};

type FileType = {
    id: string;
    file_name: string;
    deleted: number;
};

type NoteType = {
    date: string;
    notes: string[];
    files: FileType[];
    id: string;
    analyst_id: string;
    archived: number
};

type MorningMeetingMinutesType = {
    id: string;
    portfolio_weight: number;
    bq_score: number;
    mq_score: number;
    investment_thesis: string;
    last_vote_date: string;
    analyst: string;
    redshift_company_id: number;
    historic_notes: NoteType[];
};

type ExtractionType = {
    extraction_id: number;
    extraction_file_name: string; 
}

const MorningMeetingDetail = () => {
    const history = useHistory();

    const [notFound, setNotFound] = useState<boolean>(false);
    const [companyDetails, setCompanyDetails] =
        useState<MorningMeetingMinutesType>({
            id: "",
            portfolio_weight: 0,
            bq_score: 0,
            mq_score: 0,
            investment_thesis: "",
            last_vote_date: "",
            analyst: "",
            redshift_company_id: 0,
            historic_notes: [],
        });

    const { setShowLoadingToast } = useSettings();
    const [companyId, setCompanyId] = useState<string>("");
    const [companyName, setCompanyName] = useState<string>("");
    const [extractionDetails, setExtractionDetails] = useState<ExtractionType>();
    const [analystsList, setAnalystsList] = useState<any>();
    const [filterQuery, setFilterQuery] = useState<any>();
    const [extractionReady, setExtractionReady] = useState(false);
    const { analysts } = useBQMQ();
    const [notes, setNotes] = useState<NoteType[]>(
        companyDetails.historic_notes
    );

    useEffect(() => {
        if (filterQuery) {
            getMorningMeetingNotesFromQuery(companyId, filterQuery);
        } else {
            setNotes(companyDetails.historic_notes);
        }
    }, [filterQuery]);

    useEffect(() => {
        getAnalysts(analysts);
    }, [analysts]);

    const filterOptions = [
        {
            id: 1,
            name: "Analyst",
            value: "analyst",
            type: "DROPDOWN",
            searchable: true,
            options: analystsList,
        },
        {
            id: 2,
            name: "Date Range",
            value: "dateRange",
            type: "DATE_RANGE",
        }
    ];

    const getMorningMeetingMinutesById = async () => {
        setNotFound(false);
        const response = await Database.get(
            `api/bqmq_voting/get-morning-meeting-minutes-by-id/${String(
                companyId
            )}`
        );
        if (response.ok) {
            const data = await response.json();
            setCompanyDetails(data.data[0]);
        } else {
            setNotFound(true);
        }
    };

    const getAnalysts = (data: AnalystType[]) => {
        const uniqueAnalystsMap = new Map();

        data.filter((analyst: AnalystType) => analyst.active).forEach(
            (analyst: AnalystType) => {
                if (analyst.label) {
                    uniqueAnalystsMap.set(analyst.label, analyst.value);
                }
            }
        );

        const sortedUniqueAnalysts = Array.from(uniqueAnalystsMap.entries())
            .sort((a, b) => a[0].localeCompare(b[0]))
            .map(([label, value]) => ({
                label,
                value,
            }));

        setAnalystsList(sortedUniqueAnalysts);
    };

    const getMorningMeetingNotesFromQuery = async (companyId: string, queryString: string) => {
        try {
            const encodedQueryString = encodeURI(queryString);
            const response = await Database.get(`api/bqmq_voting/meeting-note/company/${companyId}?${encodedQueryString}`);
            const data = await response.json();
            setNotes(data.data);
        } catch (error) {
            console.error('Error fetching meeting notes:', error);
        }
    };

    const getGeneralMorningMeetingMinutes = async () => {
        setNotFound(false);
        const response = await Database.get(
            `api/bqmq_voting/get-general-morning-meeting-minutes`
        );
        if (response.ok) {
            const data = await response.json();
            setCompanyDetails({
                ...companyDetails,
                historic_notes: data,
                id: "GENERAL",
            });
        } else {
            setNotFound(true);
        }
    };

    const getMostRecentExtractionInformation = async () => {
        try {
            const response = await Database.get(`api/extractions/extraction_id-by_redshift_id/` + companyDetails.redshift_company_id);
            const data = await response.json();
            setExtractionDetails({extraction_id: data.data.extraction_id, extraction_file_name: data.data.archive_file_path});
        } catch (error) {
            console.error("Failed to fetch extraction information:", error)
        }
    };


    useEffect(() => {
        setExtractionReady(false);
        switch (companyId) {
            case "":
                break;
            case "GENERAL":
                getGeneralMorningMeetingMinutes();
                break;
            default:
                getMorningMeetingMinutesById();
        }
        setExtractionReady(true);
    }, [companyId]);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        setCompanyId(urlParams.get("company_id")!!);
        setCompanyName(urlParams.get("company_name")!!);
    }, []);


    const handleOnClick = (extractionId_val = extractionDetails?.extraction_id, archiveFilePath = extractionDetails?.extraction_file_name) => {
        setShowLoadingToast({ show: true, message: "EXPORTING" });
        exportFromS3(extractionId_val, archiveFilePath).then((res) => {
            setShowLoadingToast({
                show: false,
                message: "Exporting...",
            });

        });
    };


    useEffect(() => {
        if (extractionReady) {
            getMostRecentExtractionInformation();
        }
    }, [companyDetails.redshift_company_id]);


    return (
        <MorningMeetingDetailContainer className="pageContainer">
            <div style={{ display: "flex" }}>
                <h1 className="pageTitle">MORNING MEETING NOTES</h1>
                <Button
                    className="backButton"
                    text="BACK"
                    click={() => history.push("/research/morning-meeting")}
                    icon={faArrowLeft}
                />
            </div>
            <hr />
            {companyDetails.id !== "" && !notFound ? (
                <>
                    <PageTitle
                        title={
                            <div className="resultsTitle">
                                <h1>{companyName}</h1>
                            </div>
                        }
                    />

                    <MeetingDetailBoxes
                        mqScore={companyDetails.mq_score}
                        bqScore={companyDetails.bq_score}
                        investmentThesis={companyDetails.investment_thesis}
                        lastVoteDate={companyDetails.last_vote_date}
                        portfolioWeight={companyDetails.portfolio_weight}
                        analyst={companyDetails.analyst}
                    />

                    <div className={"item-container"}>

                        <SearchOptions
                            setFilterQuery={setFilterQuery}
                            options={filterOptions}
                            noDebounce
                        />

                        <Button className={"mm_excel_model_download"}
                            text={"Excel Model"}
                            color="primary"
                            click={handleOnClick}
                            icon={faDownload}
                        />
                    </div>

                    <div className={"morning-notes"}>
                        <MorningMeetingNotes
                            notes={notes}
                            setNotes={setNotes}
                        />
                    </div>
                </>
            ) : !notFound ? (
                <div className={"loadingScreen"}>
                    <LoadingSpinner />
                </div>
            ) : null}
            {notFound && (
                <div className="loadingScreen">
                    <h3 style={{ marginTop: "20px" }}>
                        No morning meetings found for {companyName}
                    </h3>
                </div>
            )}
        </MorningMeetingDetailContainer>
    );
};

export default MorningMeetingDetail;