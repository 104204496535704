import styled from 'styled-components';

export default styled.div`

    .textFieldDiv {

        display: flex;
        flex-wrap: wrap;
    }

    .textField {
        min-width: 250px;
        max-width: 250px;
    }

    .buttonDiv {
        display: flex;
    }

    .select {
        min-width: 262px;
        max-width: 262px;
        display: inline;
        float: left;
    }

    .config-from-daily-recs {
        padding: 15px;
        border: 1px solid #e0e0e0;
        margin-bottom: 30px;
        margin-top: 30px;
        border-radius: 5px;
        background-color: ${(props) => props.theme.subMenuBackground};

        .account {
            .account-label {
                background-color: ${(props) => props.theme.labelPrimaryBg};
                color: ${(props) => props.theme.labelPrimaryColor};
            }
        }
        .currency {
            margin-top: 10px;
            .currency-label {
                background-color: ${(props) => props.theme.labelSuccessBg};
                color: ${(props) => props.theme.labelSuccessColor};
            }
        }
    }

    .label {
        border-radius: 5px;
        padding: 5px;
    }

    .header {
        margin-bottom: 10px;
        color: ${(props) => props.theme.subMenuText};
    }

    .formatted {
        margin-left: 25px;
        color: ${(props) => props.theme.subMenuText};
        font-size: 0.95em;
    }
`;
