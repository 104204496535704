// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Styles
import SubMenuItemContainer from "./SubMenuItem.styles";
import Can from "components/Common/Can/Can";
import { useSettings } from "context/Settings.context";

type Props = {
    text: string;
    active: boolean;
    icon: any;
    click: Function;
    permissions?: string[];
    count?: number;
    secondaryCount?: number;
    development?: boolean;
};

export default function SubMenuItem({
    text,
    active,
    icon,
    click,
    permissions = [],
    count = 0,
    secondaryCount = 0,
    development,
}: Props) {
    const { windowWidth, setMenuMode } = useSettings();

    const onClick = () => {
        if (windowWidth < 600) setMenuMode("closed");
        click();
    };

    const isDevelopment = development
        ? process.env.REACT_APP_GIM_ENVIRONMENT === "development"
        : false;

    return (
        <>
            {isDevelopment || !development ? (
                <Can
                    yes={
                        <SubMenuItemContainer
                            className={
                                "subMenuItem " + (active ? "active" : "")
                            }
                            onClick={onClick}
                        >
                            <div className={"icon"}>
                                <FontAwesomeIcon icon={icon} fixedWidth />
                            </div>
                            <p data-cy="sub-menu-item" className={"text"}>
                                {text}
                            </p>
                            {count > 0 && <div className="badge">{count}</div>}
                            {secondaryCount > 0 && (
                                <div className="badge secondary">
                                    {secondaryCount}
                                </div>
                            )}
                        </SubMenuItemContainer>
                    }
                    permissions={permissions}
                />
            ) : null}
        </>
    );
}
