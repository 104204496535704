import { useEffect, useState } from "react";

// MaterialUI
import { Tooltip } from "@mui/material";

// FontAwesome
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Components
import CompanyListSelect from "./CompanyListSelect/CompanyListSelect";

// Styles
import SelectCompanyContainer from "./SelectCompany.styles";

type Props = {
    company: any;
    setCompany: Function;
};

export default function SelectCompany({ company, setCompany }: Props) {
    const [showNewCompany, setShowNewCompany] = useState(
        !company?.label ? "init" : "add"
    );

    useEffect(() => {
        if (company?.label) {
            setShowNewCompany("add");
        }
    }, [company]);

    return (
        <SelectCompanyContainer>
            {/* {showNewCompany === "init" && (
                <div className={"companyModeBtns"}>
                    <div
                        data-cy="new-company-button"
                        className={"companyModeBtn"}
                        onClick={() => setShowNewCompany("add")}
                    >
                        <FontAwesomeIcon icon={faPlus} fixedWidth />
                        <p>ADD NEW COMPANY IDEA</p>
                    </div>

                    <div
                        data-cy="existing-company-button"
                        className={"companyModeBtn"}
                        onClick={() => setShowNewCompany("select")}
                    >
                        <FontAwesomeIcon icon={faBriefcase} fixedWidth />
                        <p>SELECT EXISTING COMPANY</p>
                    </div>
                </div>
            )} */}

            {company && (
                <div className={"company"}>
                    <div className={"label"}>COMPANY</div>
                    <div className={"textBoxContainer"}>
                        <input
                            data-cy="company-name-text-box"
                            className={"textBox"}
                            value={company?.label || company?.name}
                            placeholder={"Add Company Name"}
                            onChange={(e) => setCompany(e.target.value)}
                            disabled={company?.sector?.name}
                        />

                        <Tooltip title={<h2>Clear Company</h2>}>
                            <div
                                className={"clearCompanyBtn"}
                                onClick={() => {
                                    setCompany(null);
                                    setShowNewCompany("init");
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faTimesCircle}
                                    fixedWidth
                                />
                            </div>
                        </Tooltip>
                    </div>
                </div>
            )}

            {!company && (
                <CompanyListSelect
                    setCompany={setCompany}
                    setShowNewCompany={setShowNewCompany}
                />
            )}
        </SelectCompanyContainer>
    );
}
