import { useEffect, useState } from "react";

// FontAwesome
import { faDownload } from "@fortawesome/free-solid-svg-icons";

// Components
import ButtonSmall from "components/Common/ButtonSmall/ButtonSmall";
import SearchOptions from "components/Common/SearchOptions/SearchOptions";
import CompanyResultsList from "components/Screens/BQMQ/Home/CompanyResultsList/CompanyResultsList";
import ExportModal from "components/Screens/BQMQ/Home/ExportModal/ExportModal";
import PendingMyVoteList from "components/Screens/BQMQ/Home/PendingMyVoteList/PendingMyVoteList";
import UpcomingFocusList from "components/Screens/BQMQ/Home/UpcomingFocusList/UpcomingFocusList";

// Context
import { useBQMQ } from "context/BQMQ.context";

// Utils
import queryString from "query-string";
import {
    createPartial,
    filterCompaniesWithNoVotes,
    filterResults,
    getSelectedID,
} from "utils/bqmq.utils";

// Styles
import HomeContainer from "./Home.styles";

// Logger
import Logger from "utils/logger.utils";
const Log = new Logger();
Log.init("Home");

export default function Home() {
    const { teams, companies, analysts } = useBQMQ();

    const [filterQuery, setFilterQuery] = useState<string>("");

    const [selectedCompany, setSelectedCompany] = useState<string>("");

    const [exportModalOpen, setExportModalOpen] = useState<boolean>(false);

    useEffect(() => {
        getSelectedID(filterQuery, setSelectedCompany, "company_id=");
    }, [filterQuery]);

    const filterOptions: DropDownOption[] = [
        {
            id: 1,
            name: "Search",
            value: "search",
            type: "SEARCH",
        },
        {
            id: 2,
            name: "Company",
            value: "company_id",
            type: "DROPDOWN",
            searchable: true,
            options: filterCompaniesWithNoVotes(companies),
        },
        {
            id: 3,
            name: "Team",
            value: "gim_subteam",
            type: "DROPDOWN",
            options: teams,
        },
    ];

    return (
        <HomeContainer className={"pageContainer"}>
            <SearchOptions
                setFilterQuery={setFilterQuery}
                options={filterOptions}
                noDebounce
                actions={
                    <ButtonSmall
                        text={"EXPORT DATA"}
                        icon={faDownload}
                        click={() => setExportModalOpen(true)}
                    />
                }
            />

            <PendingMyVoteList
                filterQuery={filterQuery}
                filterResults={createPartial(
                    filterResults,
                    queryString.parse(filterQuery),
                    teams
                )}
            />

            <UpcomingFocusList
                showFocusList={true}
                filterQuery={filterQuery}
                filterResults={createPartial(
                    filterResults,
                    queryString.parse(filterQuery),
                    teams
                )}
            />

            <CompanyResultsList
                filterQuery={filterQuery}
                filterResults={createPartial(
                    filterResults,
                    queryString.parse(filterQuery),
                    teams
                )}
            />

            {exportModalOpen && (
                <ExportModal
                    companyOptions={companies}
                    analystOptions={analysts}
                    selectedCompany={selectedCompany}
                    open={setExportModalOpen}
                />
            )}
        </HomeContainer>
    );
}
