import React, {useEffect, useState} from "react";

// Styles
import DelawareAsiaContainer from "./DelawareAsia.styles";

// Components
import Tabs from "components/Common/Tabs/Tabs";
import Historic from "components/Screens/Fees/Common/FundTabs/HistoricTab/Historic";
import Latest from "components/Screens/Fees/Common/FundTabs/LatestTab/Latest";
import Reports from "components/Screens/Fees/Common/FundTabs/ReportsTab/Reports";
import SubTabs from "components/Common/SubTabs/SubTabs";
import Archive from "components/Screens/Fees/Common/FundTabs/ArchiveTab/Archive";

// utils
import {feesDashboardUrl} from "utils/fees.utils";

interface DelawareAsiaProps {
    region: string;
}

const DelawareAsia = ({ region }: DelawareAsiaProps) => {
    const [feesForcastUrl, setFeesForcastUrl] = useState("");
    const [feesDiscrepancyUrl, setFeesDiscrepancyUrl] = useState("");

    useEffect(() => {
        feesDashboardUrl("DELAWARE_ASIA_FEES", setFeesForcastUrl);
        feesDashboardUrl("DELAWARE_ASIA_DISCREPANCY", setFeesDiscrepancyUrl);
    }, []);

    return (
        <DelawareAsiaContainer>
            <SubTabs
                defaultTab="latest"
                subTabs={[
                    {
                        value: "latest",
                        title: "LATEST",
                        content: <Latest region={region} fund="delaware" period="latest" />,
                        permissions: ["p_can_view_fees", "p_can_action_fees"],
                    },
                    {
                        value: "reports",
                        title: "REPORTS SIGN OFF",
                        content: <Reports region={region} fund="delaware" />,
                        permissions: ["p_can_view_fees", "p_can_action_fees"],
                    },
                    {
                        value: "fee_report",
                        title: "FEE REPORT",
                        content: (
                            <div className="iframeContainer">
                                <iframe
                                    src={feesForcastUrl}
                                    width="100%"
                                    height="100%"
                                    allowFullScreen
                                    frameBorder={0}
                                />
                            </div>
                        ),
                        permissions: ["p_can_view_fees", "p_can_action_fees"],
                    },
                    {
                        value: "discrepancy_report",
                        title: "DISCREPANCY REPORT",
                        content: (
                            <div className="iframeContainer">
                                <iframe
                                    src={feesDiscrepancyUrl}
                                    width="100%"
                                    height="100%"
                                    allowFullScreen
                                    frameBorder={0}
                                />
                            </div>
                        ),
                        permissions: ["p_can_view_fees", "p_can_action_fees"],
                    },
                    {
                        value: "archive",
                        title: "ARCHIVE",
                        content: <Archive fund="delaware" region="asia" />,
                        permissions: ["p_can_view_fees", "p_can_action_fees"],
                    },
                ]}
            />
        </DelawareAsiaContainer>
    );
};
export default DelawareAsia;
