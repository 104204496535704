import React, { useState } from "react";

// Styles
import UpdateAccrualModalContainer from "./UpdateAccrualModal.styles";

// Components
import Button from "components/Common/Button/Button";
import Modal from "components/Common/Modal/Modal";
import AccrualInputFields from "../AccrualInputFields/AccrualInputFields";

// Types
import { AccrualsValues } from "screens/DailyRecs/Inputs/Accruals/types";

// Context
import { useSettings } from "context/Settings.context";

// Utils
import * as Database from "utils/database.utils";
import { uppercaseFirstLetter } from "utils/common.utils";

interface UpdateAccrualModalProps {
    setShowModal: Function;
    runUpdate: Function;
}

const UpdateAccrualModal = ({
    setShowModal,
    runUpdate,
}: UpdateAccrualModalProps) => {
    const { setToast, setShowLoadingToast } = useSettings();

    const [loading, setLoading] = useState<boolean>(false);
    const [newAccrualsValues, setNewAccrualsValues] = useState<any>({
        fund_admin: 0,
        directors_fee_eur: 0,
        directors_meeting_expenses: 0,
        auditors_fee_eur: 0,
        other_professional_fees_pwc_uk_rfs_gbp: 0,
        legal_fees_annual: 0,
        sub_custody_fee_trustee: 0,
        custody_fee_safekeeping: 0,
        misc_vat_tt_charges: 0,
        ifrsa_levy_eur: 0,
        offshore_registration: 0,
        liability_insurance_gbp_dublin: 0,
        prior_month_nav: 0,

        // Deductions
        ta_fee_nt: 0,
        tax_reporting_services_deloitte: 0,
        incentive_fee_calculation_deloitte: 0,
        custody_fee_nt: 0,
        audit_fee_kpmg: 0,
        fund_admin_nt: 0,
        legal_fee: 0,
        liability_insurance_gbp_delaware: 0,

        // Delaware Deductions
        outstanding_accrual_carried_forward_delaware: 0,
        deductions_to_accrued_expenses_delaware: 0,
        management_fee_paid_delaware: 0,

        // Dublin Deductions
        outstanding_accrual_carried_forward_dublin: 0,
        deductions_to_accrued_expenses_dublin: 0,
        management_fees_dublin: 0,

        fund_region: "global",
    });

    const handleChange = (value: number | string, name: string) => {
        setNewAccrualsValues({
            ...newAccrualsValues,
            [name]: value,
        });
    };

    const handleUpdate = async () => {
        setShowLoadingToast({
            show: true,
            message: "UPDATING",
        });
        for (const [key, value] of Object.entries(newAccrualsValues)) {
            if (key !== "fund_region" && typeof value === "string") {
                newAccrualsValues[key as keyof AccrualsValues] =
                    parseFloat(value);
            }
        }
        let response = await Database.post(
            "api/nav/user_inputs/accruals/",
            newAccrualsValues
        );
        if (response.ok) {
            setToast({
                show: true,
                type: "success",
                message:
                    "Updated accruals data for " +
                    uppercaseFirstLetter(newAccrualsValues.fund_region),
            });
            runUpdate();
            setShowModal(false);
        }
        setShowLoadingToast({
            show: false,
            message: "",
        });
    };

    return (
        <UpdateAccrualModalContainer>
            <Modal loading={loading} width={"58vw"}>
                <div className="main">
                    <div className="text">
                        <h3>UPDATE ACCRUAL</h3>
                    </div>
                    <AccrualInputFields
                        handleChange={handleChange}
                        newAccrualsValues={newAccrualsValues}
                    />
                </div>
                <div className="actions">
                    <Button
                        text="CANCEL"
                        color="flat"
                        click={() => setShowModal(false)}
                    />
                    <Button text="SAVE" click={() => handleUpdate()} />
                </div>
            </Modal>
        </UpdateAccrualModalContainer>
    );
};
export default UpdateAccrualModal;
