export const dailyFlowInputs = [
    {
        name: "redemptions",
        label: "Redemption/Subscription",
        type: "input",
        options: [],
    },
    {
        name: "man_fees",
        label: "Management Fees",
        type: "input",
        options: [],
    },
    {
        name: "perf_fees",
        label: "Performance Fees",
        type: "input",
        options: [],
    },
    {
        name: "other",
        label: "Other Fees",
        type: "input",
        options: [],
    },
]


export type DailyFlowValues = {
    account_number: string;
    flow_date_dt: Date | undefined;
    id?: string;
    man_fees: number;
    perf_fees: number;
    redemptions: number;
    other: number;
    signed_off_by_1?: null | string
    signed_off_by_2?: null | string
    signed_off_dt_1?: null | string
    signed_off_dt_2?: null | string
    version?: number
};
