import styled from "styled-components";

export default styled.div<{ maxHeight: string }>`
    .generateBtn {
        margin-top: 15px;
        margin-bottom: 15px;
        float: right;
    }

    .tableDiv {
        margin-top: 20px;
        clear: right;
        clear: right;
        overflow-y: hidden;
    }
    #tableContScroll {
        max-height: ${(props) => props.maxHeight};
    }
`;
