// Styles
import IframeContainer from "./IframeContainer.styles";
type Props = {
    title: string;
    src: string;
};

export default function UsageStatSummary({title, src}: Props) {
    return (
        <IframeContainer>
            <div className="iframeContainer">
                <iframe
                    src={src}
                    title={title}
                    width="100%"
                    height="100%"
                    allowFullScreen
                    frameBorder={0}
                />
            </div>
        </IframeContainer>
    );
}