import styled from "styled-components";

export default styled.div`
    display: flex;
    flex-direction: row;
    min-width: 100%;

    .questionCell {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-width: 300px;
        max-width: 300px;
        min-height: 100px;
        max-height: 100px;

        color: ${(props) => props.theme.textPrimary};

        .charCount {
            font-size: 12px;

            &.limit {
                font-size: 16px;
                color: ${(props) => props.theme.subMenuBadgeBackground};
                font-weight: bold;
            }
        }
    }

    textarea {
        display: flex;
        flex-grow: 1;

        margin: 10px;
        padding: 10px;

        border-radius: 5px;

        background-color: ${(props) => props.theme.votingCellBackground};
        color: ${(props) => props.theme.votingCellCommentText};

        border: 1px solid ${(props) => props.theme.votingCellBorder};

        outline: none;

        font-size: 16px;
    }

    @media screen and (max-width: 900px) {
        flex-direction: column;

        margin-top: 20px;

        .questionCell {
            justify-content: flex-end;
            align-items: flex-start;
            margin-left: 10px;

            min-height: unset;
            max-height: unset;
        }
    }
`;
