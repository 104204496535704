import styled from "styled-components";
import colors from "assets/styles/colors";

export default styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-width: 50px;
    max-width: 50px;
    min-height: 50px;
    max-height: 50px;
    cursor: pointer;

    svg {
        font-size: 25px;
        color: ${colors.textNavy};
    }

    .inactive {
        color: ${colors.accentRed};
    }


    .loading{
        width: 25px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: ${colors.accentBlue};
        .white{
            font-size:16px;
            color:white;
        }
        animation: spin 2s linear infinite;
    }
    
`;
