import styled from "styled-components";

export default styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;

    margin: -5px 0;
    height: 30px;

    .addBtn {
        color: ${(props) => props.theme.permissionsAdd};
    }

    .editBtn {
        color: ${(props) => props.theme.primaryButtonBackground};

        margin-left: 10px;
    }

    &.center {
        justify-content: center;
        align-items: center;
    }

    &.editMode {
        cursor: pointer;
    }
`;
