import React, { useEffect, useState } from "react";

// Styles
import DealSummaryContainer from "./DealSummary.styles";

// Interface
import { DealDetails, DealTeam, DealVote } from "assets/interfaces/growth_voting";

// Utils
import { getTeamNameByType } from "utils/growth_voting.utils";

// Components
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import VoteIcon from "../VoteIcon/VoteIcon";

interface Props {
    votes: DealVote[]
    details: DealDetails
    teams: DealTeam[]
}

const DealSummary  = ({votes, details, teams}: Props) => {
    return (
        <DealSummaryContainer>
            <TableContainer>
                <Table >
                    <TableBody>
                            <TableRow key={"funding_round"}>
                                <TableCell component="th" scope="row">Milestone</TableCell>
                                <TableCell>{details.funding_round}</TableCell>
                            </TableRow>
                            <TableRow key={"description"}>
                                <TableCell component="th" scope="row">Description</TableCell>
                                <TableCell>{details.description}</TableCell>
                            </TableRow>
                             <TableRow key={"company"}>
                                <TableCell component="th" scope="row">Company</TableCell>
                                <TableCell>
                                        {details.company.name}<br />
                                        {details.company.url}
                                </TableCell>
                            </TableRow>
                            <TableRow key={"teams"}>
                                <TableCell component="th" scope="row">Teams</TableCell>
                                <TableCell>
                                    {teams.length > 0 &&
                                        teams.map((team, index) => (
                                            <div
                                                className={"team"}
                                                key={index}
                                            >
                                                {getTeamNameByType(team.type)} ({team.members.length})
                                            </div>
                                        ))}
                                </TableCell>
                            </TableRow>
                             <TableRow key={"votes"}>
                                <TableCell component="th" scope="row">Votes</TableCell>
                                 <TableCell>
                                    <div className={"vote-types"}>
                                    {votes.length > 0 &&
                                        votes.map((vote, index) => (
                                            <VoteIcon vote={vote}/>
                                        ))}
                                    </div>
                                </TableCell>
                            </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </DealSummaryContainer>
    );
};

export default DealSummary
