import styled from "styled-components";
import colors from "assets/styles/colors";

export default styled.div`
    display: flex;
    flex-direction: column;

    margin-top: 20px;
    margin-bottom: 20px;

    .heading {
        display: flex;
        justify-content: center;
        align-items: center;

        min-height: 50px;
        max-height: 50px;

        border-radius: 5px 5px 0 0;

        background-color: ${(props) => props.theme.homeListTitleRowBackground};
        color: ${(props) => props.theme.homeListTitleRowText};

        font-size: 20px;
    }

    .userRow {
        display: flex;
        flex-direction: row;

        min-height: 50px;
        max-height: 50px;

        min-width: 100%;

        border-right: 1px solid
            ${(props) => props.theme.homeListBodyBorderColor};
        border-left: 1px solid ${(props) => props.theme.homeListBodyBorderColor};
        border-bottom: 1px solid
            ${(props) => props.theme.homeListBodyBorderColor};

        .icon {
            display: flex;
            justify-content: center;
            align-items: center;

            min-height: 50px;
            max-height: 50px;
            min-width: 50px;
            max-width: 50px;

            color: ${(props) => props.theme.homeListTitleRowBackground};
        }

        .selectBtn {
            display: flex;
            justify-content: center;
            align-items: center;

            min-height: 50px;
            max-height: 50px;
            min-width: 50px;
            max-width: 50px;

            color: ${(props) => props.theme.homeListTitleRowBackground};
        }

        .name {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            flex-grow: 1;
            min-height: 50px;
            max-height: 50px;

            color: ${(props) => props.theme.hover};
        }

        :hover {
            background-color: ${(props) =>
                props.theme.homeListBodyBackgroundHover};
            cursor: pointer;
        }

        &.inactive {
            .icon {
                color: #dddddd;
            }

            .selectBtn {
                color: #dddddd;
            }

            .name {
                color: #999999;
            }
        }

        &.active {
            background-color: ${colors.teal30};
        }
    }

    .searchRow {
        display: flex;
        flex-direction: row;

        justify-content: space-between;
        align-items: center;

        min-height: 50px;
        max-height: 50px;
        min-width: 100%;

        border-right: 1px solid
            ${(props) => props.theme.homeListBodyBorderColor};
        border-left: 1px solid ${(props) => props.theme.homeListBodyBorderColor};
        border-bottom: 1px solid
            ${(props) => props.theme.homeListBodyBorderColor};

        .searchBox {
            display: flex;
            justify-content: center;
            align-items: center;

            border-radius: 0 5px 5px 0;
            border: none;
            outline: none;
            padding: 10px;

            background-color: transparent;

            color: ${(props) => props.theme.textPrimary};

            font-size: 18px;

            flex-grow: 1;
        }
        .searchIcon {
            display: flex;
            justify-content: center;
            align-items: center;

            min-width: 50px;
            max-width: 50px;

            color: ${(props) => props.theme.textPrimary};
        }
    }

    .loader {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 1;

        border-radius: 0 0 5px 5px;

        min-width: 100%;
        max-width: 100%;
        min-height: 50vh;

        border-right: 1px solid
            ${(props) => props.theme.homeListBodyBorderColor};
        border-left: 1px solid ${(props) => props.theme.homeListBodyBorderColor};
        border-bottom: 1px solid
            ${(props) => props.theme.homeListBodyBorderColor};
    }
`;
