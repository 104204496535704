// Components
import TableCell from "components/Common/TableCell/TableCell";

// Utils
import FloatToCurrencyParser from "./FloatToCurrencyParser";
import FloatToPercParser from "./FloatToPercParser";
import { SeparateAccountsRow } from "./types";

const parseForUndefined = (
    value: number | undefined,
    parser: (value: number) => string = (value: number) => value.toString()
) => {
    if (value === undefined) {
        return "N/A";
    }
    return parser(value);
};

const colorPalette = ["#6BB9CA", "#7DC5D3", "#8FD2DC", "#A0DFE5", "#B2EBEE"];

const parseEventType = (value: string) => {
    if (!value) {
        return "N/A";
    }
    const noUnderScoreValue = value.replace(/_/g, " ");
    const capitalizedValue = noUnderScoreValue
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");

    return capitalizedValue;
};

const cashflowEventColumnGenerator = (
    mgmtFeeRows: SeparateAccountsRow[],
    startCol: number
) => {
    if (!mgmtFeeRows || mgmtFeeRows?.length === 0) {
        return [];
    }
    const rowWithMaxNumberOfCashFlowEvents = mgmtFeeRows.reduce(
        (acc: SeparateAccountsRow, curr: SeparateAccountsRow) => {
            if (acc.cashflow_events.length < curr.cashflow_events.length) {
                return curr;
            }
            return acc;
        }
    );
    const maxNumberOfCashFlowEvents =
        rowWithMaxNumberOfCashFlowEvents.cashflow_events.length;
    let cashFlowEventColumns: Column[] = [];
    for (let i = 0; i < maxNumberOfCashFlowEvents; i++) {
        cashFlowEventColumns = cashFlowEventColumns.concat([
            {
                id: startCol + i,
                title: "EVENT TYPE",
                sortable: false,
                value: "event_type",
                cell: (data: SeparateAccountsRow) => (
                    <TableCell
                        data={parseEventType(
                            data.cashflow_events[i]?.event_type as string
                        )}
                    />
                ),
                show: true,
                fullDataRow: true,
                groupingKey: `Cashflow Event ${i + 1}`,
                headerColor: colorPalette[i % colorPalette.length],
                textColor: "#000000",
            },
            {
                id: startCol + i + 1,
                title: "NUMBER OF DAYS UP TO EVENT",
                sortable: false,
                value: "number_of_days_up_to_redemption",
                cell: (data: SeparateAccountsRow) => (
                    <TableCell
                        data={parseForUndefined(
                            data.cashflow_events[i]
                                ?.number_of_days_up_to_redemption
                        )}
                    />
                ),
                show: true,
                fullDataRow: true,
                groupingKey: `Cashflow Event ${i + 1}`,
                headerColor: colorPalette[i % colorPalette.length],
                textColor: "#000000",
            },
            {
                id: startCol + i + 2,
                title: "NAV DAY BEFORE EVENT",
                sortable: false,
                value: "nav_day_before_redemption",
                cell: (data: SeparateAccountsRow) => (
                    <TableCell
                        data={parseForUndefined(
                            data.cashflow_events[i]?.nav_day_before_redemption,
                            (value: number) =>
                                FloatToCurrencyParser(value, data.currency)
                        )}
                    />
                ),
                show: true,
                fullDataRow: true,
                groupingKey: `Cashflow Event ${i + 1}`,
                headerColor: colorPalette[i % colorPalette.length],
                textColor: "#000000",
            },
            {
                id: startCol + i + 3,
                title: "TRANCHE WEIGHTING BEFORE EVENT",
                sortable: false,
                value: "tranche_weighting_before_redemption",
                cell: (data: SeparateAccountsRow) => (
                    <TableCell
                        data={parseForUndefined(
                            data.cashflow_events[i]
                                ?.tranche_weighting_before_redemption,
                            FloatToPercParser
                        )}
                    />
                ),
                show: true,
                fullDataRow: true,
                groupingKey: `Cashflow Event ${i + 1}`,
                headerColor: colorPalette[i % colorPalette.length],
                textColor: "#000000",
            },
            {
                id: startCol + i + 4,
                title: "NUMBER OF DAYS AFTER TO EVENT",
                sortable: false,
                value: "number_of_days_after_redemption",
                cell: (data: SeparateAccountsRow) => (
                    <TableCell
                        data={parseForUndefined(
                            data.cashflow_events[i]
                                ?.number_of_days_after_redemption
                        )}
                    />
                ),
                show: true,
                fullDataRow: true,
                groupingKey: `Cashflow Event ${i + 1}`,
                headerColor: colorPalette[i % colorPalette.length],
                textColor: "#000000",
            },
            {
                id: startCol + i + 5,
                title: "NAV DAY AFTER EVENT",
                sortable: false,
                value: "nav_day_after_redemption",
                cell: (data: SeparateAccountsRow) => (
                    <TableCell
                        data={parseForUndefined(
                            data.cashflow_events[i]?.nav_day_after_redemption,
                            (value: number) =>
                                FloatToCurrencyParser(value, data.currency)
                        )}
                    />
                ),
                show: true,
                fullDataRow: true,
                groupingKey: `Cashflow Event ${i + 1}`,
                headerColor: colorPalette[i % colorPalette.length],
                textColor: "#000000",
            },
            {
                id: startCol + i + 6,
                title: "TRANCHE WEIGHTING AFTER EVENT",
                sortable: false,
                value: "tranche_weighting_after_redemption",
                cell: (data: SeparateAccountsRow) => (
                    <TableCell
                        data={parseForUndefined(
                            data.cashflow_events[i]
                                ?.tranche_weighting_after_redemption,
                            FloatToPercParser
                        )}
                    />
                ),
                show: true,
                fullDataRow: true,
                groupingKey: `Cashflow Event ${i + 1}`,
                headerColor: colorPalette[i % colorPalette.length],
                textColor: "#000000",
            },
        ]);
    }
    return cashFlowEventColumns;
};

export default cashflowEventColumnGenerator;
