export const clientInputs = [
    {
        name: "fund_entry_date",
        label: "Fund Entry Date",
        type: "date",
        dtype: "text",
    },
    {
        name: "benchmark_return",
        label: "Benchmark Return",
        type: "select",
        options: [
            {
                label: "MSCI ACWI ND",
                value: "MSCI ACWI ND",
            },
            {
                label: "MSCI All Country World Index ND",
                value: "MSCI All Country World Index ND",
            },
            {
                label: "MSCI All Country World Total Return Index with 80% Gross/20% Net Dividends reinvested in GBP",
                value: "MSCI All Country World Total Return Index with 80% Gross/20% Net Dividends reinvested in GBP",
            },
            {
                label: "MSCI All Country World Total Return Index with Net Dividends reinvested in GBP",
                value: "MSCI All Country World Total Return Index with Net Dividends reinvested in GBP",
            },
            {
                label: "MSCI World Total Return Index with Net Dividends reinvested in AUD",
                value: "MSCI World Total Return Index with Net Dividends reinvested in AUD",
            },
            {
                label: "MSCI World Total Return Index with Net Dividends reinvested in USD",
                value: "MSCI World Total Return Index with Net Dividends reinvested in USD",
            },
        ],
    },
    {
        name: "needs_to_take_custody_nav",
        label: "Needs to take Custody NAV?",
        type: "select",
        options: [
            {
                label: "Y (Yes)",
                value: "Y",
            },
            {
                label: "N (No)",
                value: "N",
            },
        ],
    },
    {
        name: "performance_fee_rate",
        label: "Performance Fee Rate",
        type: "input",
        dtype: "number",
        format: "percentage",
    },
    {
        name: "performance_fees_in_out_of_portfolio",
        label: "PF In/Out of Portfolio",
        type: "select",
        options: [
            {
                label: "In",
                value: "In",
            },
            {
                label: "Out",
                value: "Out",
            },
        ],
    },

    {
        name: "anniversary_period",
        label: "Anniversary Period",
        type: "select",
        options: [
            {
                label: "Q1",
                value: "Q1",
            },
            {
                label: "Q2",
                value: "Q2",
            },
            {
                label: "Q3",
                value: "Q3",
            },
            {
                label: "Q4",
                value: "Q4",
            },
        ],
    },
    {
        name: "mgt_fees_in_out_of_portfolio",
        label: "MF In/Out of Portfolio",
        type: "select",
        options: [
            {
                label: "In",
                value: "In",
            },
            {
                label: "Out",
                value: "Out",
            },
        ],
    },
    {
        name: "type_of_calculation",
        label: "MF Type of Calculation",
        type: "select",
        options: [
            {
                label: "Tranche Specific Basis Points on Average Month-End NAVs within the Quarter",
                value: "Tranche Specific Basis Points on Average Month-End NAVs within the Quarter",
            },
            {
                label: "Flat Annual Fee",
                value: "Flat Annual Fee",
            },
            {
                label: "Tranche Specific Basis Points on Aggregate Quarter-End NAV",
                value: "Tranche Specific Basis Points on Aggregate Quarter-End NAV",
            },
        ],
    },
    {
        name: "pricing",
        label: "Pricing",
        type: "input",
        dtype: "number",
        format: "percentage",
    },
    {
        name: "admin_fee",
        label: "Admin Fee",
        type: "input",
        dtype: "number",
        format: "dollar",
    },
    {
        name: "annual_fee",
        label: "Annual Fee Rate",
        type: "input",
        dtype: "number",
        format: "pound",
    },
];

export type ClientValues = {
    separate_account_name: string;
    month: string;
    tranche: number | null;
    fund_entry_date: string;
    anniversary_period: string;
    currency: string;
    mgt_fees_in_out_of_portfolio: string;
    account_id: string;
    benchmark_return: string;
    needs_to_take_custody_nav: string;
    performance_fee_rate: number;
    performance_fees_in_out_of_portfolio: string;
    type_of_calculation: string;
    pricing: number;
    admin_fee: number;
    annual_fee: number;
};

export type GeneratedColumn = {
    id: number;
    title: string;
    sortable: boolean;
    value: string;
    cell: (data: any) => JSX.Element;
    show: boolean;
    fullDataRow: boolean;
    headerColor: string;
    textColor?: string;
};

export type CellMapGetter = (qtr: number) => {
    [key: string]: (data: any) => JSX.Element;
};
export type TypeOfCalcType =
    | "FIXED FEE"
    | "1/4 Custodian at quarter end ex accrued expenses"
    | "1/4 IOO average NAV for quarter";

export type CashFlowEvent = {
    number_of_days_up_to_redemption: number;
    nav_day_before_redemption: number;
    tranche_weighting_before_redemption: number;
    number_of_days_after_redemption: number;
    nav_day_after_redemption: number;
    tranche_weighting_after_redemption: number;
    event_type?: string;
};

export type SeparateAccountPayload = {
    data: {
        redemptions: SeparateAccountsRow[];
        subscriptions: SeparateAccountsRow[];
        management_fees: SeparateAccountsRow[];
        performance_fees: SeparateAccountsRow[];
    };
};
export type SeparateAccountsRow = {
    id: string;
    account_id: string;
    custody: string;
    custody_account_number: string;
    account_name: string;
    currency: Currency;
    fx_rate: number;
    cable_rate: number;
    tranche: number;
    tranche_weighting: number;
    pricing: number;
    type_of_calculation: TypeOfCalcType;
    ioo_1: number;
    ioo_2: number;
    ioo_3: number;
    custody_nav_1: number;
    custody_nav_2: number;
    custody_nav_3: number;
    custody_accrued_expenses_1: number;
    custody_accrued_expenses_2: number;
    custody_accrued_expenses_3: number;
    variance_1: number;
    variance_2: number;
    variance_3: number;
    "variance_%_1": string;
    "variance_%_2": string;
    "variance_%_3": string;
    management_fee: number;
    management_fee_billed: number;
    variance: number;
    commentary: string;
    administration_fee: number;
    annual_fee_rate: number;
    uk_rpi: number;
    updated_annual_fee: number;
    cashflow_events: CashFlowEvent[];
    total_redemptions: number;
    market_value_of_tranche: number;
    redemption_amount: number;
    performance_fee_split: number;
    performance_fee_amount: number;
    redemption_date: string;
    user_updated_ownership?: number;
    performance_fee_date: string;
    performance_fees_sign_off: string;
    performance_fee_sign_off: string;
    performance_fee_sign_off_updated_by_name: string;
    performance_fee_sign_off_updated_by: string;
    subscription_date: string;
    subscription_amount: number;
    ownership_at_subscription_date: number;
    gross_market_value_account: number;
    new_ownship_split: number;
    client_config_setup: string;
    ownership_at_performance_fee_payment_date: number;
    performance_fee_payment_amount: number;
    status: string;
    reference_date: string;
    version: number;
    report_id: string;
    report_version: number;
    uuid: string;
    ownership_at_redemption_date: number;
    market_value_of_benchmark: number;
    performance_fee_payable_at_date: number;
    ownership_redeemed_of_tranche: number;
    performance_fee_payment_on_redemption: number;
    total_events: number;
    max_nav: number;
};
export type Currency =
    | "USD"
    | "GBP"
    | "EUR"
    | "CHF"
    | "JPY"
    | "CAD"
    | "AUD"
    | "HKD"
    | "SGD"
    | "SEK"
    | "NOK"
    | "DKK"
    | "ZAR"
    | "ILS"
    | "PLN"
    | "CZK"
    | "HUF"
    | "RON"
    | "TRY"
    | "RUB"
    | "MXN"
    | "BRL"
    | "INR"
    | "IDR"
    | "KRW"
    | "TWD"
    | "THB"
    | "MYR"
    | "NZD"
    | "PHP"
    | "CNY"
    | "AED"
    | "SAR"
    | "CLP"
    | "COP"
    | "EGP"
    | "KWD"
    | "NGN"
    | "PKR"
    | "QAR"
    | "UAH"
    | "VND"
    | "ARS"
    | "BDT"
    | "BGN"
    | "HRK"
    | "DZD"
    | "IRR"
    | "JOD"
    | "KZT"
    | "LKR"
    | "MAD"
    | "OMR"
    | "PEN"
    | "TND"
    | "VEF"
    | "BYR"
    | "IQD"
    | "LBP"
    | "MVR"
    | "AZN"
    | "BHD"
    | "BOB"
    | "BWP"
    | "CDF"
    | "GTQ"
    | "JMD"
    | "JPG"
    | "KES"
    | "LTL"
    | "MUR"
    | "NAD"
    | "NPR"
    | "PAB"
    | "PYG"
    | "RSD"
    | "SDG"
    | "TTD"
    | "TZS"
    | "UGX"
    | "UYU"
    | "UZS"
    | "XAF"
    | "XOF"
    | "ZMW"
    | "AFN"
    | "ALL"
    | "AMD"
    | "AOA"
    | "AWG"
    | "BAM"
    | "BBD"
    | "BMD"
    | "BND"
    | "BSD"
    | "BZD"
    | "CRC"
    | "CUP"
    | "CVE"
    | "DJF"
    | "DOP"
    | "ERN"
    | "ETB";
