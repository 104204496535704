import React, { useEffect, useState } from "react";

// Styles
import VoteDetailContainer from "./VoteDetail.styles";

// Interface
import {DealVote, GrowthAnswer, GrowthQuestion, DealTeam, DealTeamMember} from "assets/interfaces/growth_voting";

// Components
import OpenCloseButton from "components/Screens/GrowthVoting/OpenCloseButton/OpenCloseButton";
import EditVotingModal from "../EditVoteModal/EditVoteModal";
import Button from "components/Common/Button/Button";
import VoteDetailByVoters from "./VoteDetailByVoters/VoteDetailByVoters";
import { ApiHandler } from "services/api_handler";

// Context
import {useSettings} from "context/Settings.context";
import {useGrowthDeal} from "context/growth/GrowthDeal.context";
import VoteComments from "./VoteComments/VoteComments";


interface Props {
    vote: DealVote
    questions: GrowthQuestion[]
    answeres: GrowthAnswer[] // all answers for this vote
    team: DealTeam|undefined // vote team
    dealOpen: boolean // deal is open or not
}

const URL_DEAL_VOTES = "api/growth_voting/votes";

const VoteDetail = ({vote, questions, answeres, team, dealOpen}: Props) => {
    const [showMyVotingModal, setShowMyVotingModal] = useState<boolean>(false);
    const [userAnswers, setUserAnswers] = useState<GrowthAnswer[]>([]);
    const [member, setMember] = useState<DealTeamMember|undefined>(undefined);
    const [canUserVote, setCanUserVote] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(vote.open ? vote.open : false);

    const { setShowLoadingToast, setToast } = useSettings();
    const { setDealDirty } = useGrowthDeal();

    const userID = localStorage.getItem("gim-user-id");

    const getUserAnsweres = () => {
        if (!member) return;

        if (answeres.length === 0) return;

        const currentUserAnswers = answeres.filter((answer) => answer?.team_member_id === member?.id)
        setUserAnswers([...currentUserAnswers]);
    }

    const setMemberByUser = () => {
        const members = (team?.members) ? team.members : [];
        const m = members.find((member) => member.user_id === userID);

        if (m) {
            setMember(m);
            // user is in the vote team
            setCanUserVote(true);
        } else {
            setMember(undefined);
            setCanUserVote(false);
        }
    }

    const changeVoteState = async (state: boolean) => {
        if (!dealOpen) return;

        let url = `${URL_DEAL_VOTES}/${vote.id}`;
        let v = {...vote, open: state};
        let response = await new ApiHandler(url, setShowLoadingToast, setToast).put({vote: v});

        setDealDirty(true);

        setOpen(state);
    };

    useEffect(() => {
        setMemberByUser();
    }, []);

    useEffect(() => {
        getUserAnsweres();
        setMemberByUser();
    }, [member, answeres]);

    return (
        <VoteDetailContainer>
            {showMyVotingModal &&
                <EditVotingModal vote={vote} questions={questions} answers={userAnswers} setShowModal={setShowMyVotingModal} member={member}/>
            }

            <div className={"vote-header"}>
                {canUserVote && <Button
                    text="My Vote"
                    color="primary"
                    click={() => setShowMyVotingModal(true)}
                />}
                {dealOpen && <OpenCloseButton open={open}  changeHanlder={changeVoteState} hoverText={"Open/Close Deal"} />}
            </div>

            <div className={"vote-content"}>
                <VoteDetailByVoters vote={vote} questions={questions} answeres={answeres} team={team} />
            </div>
            <div className={"vote-comments"}>
               <VoteComments vote={vote} voteTeamMembers={team?.members ? team.members : []}/>
            </div>
        </VoteDetailContainer>
    );
};

export default VoteDetail
