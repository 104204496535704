import styled from "styled-components";

export default styled.div`
    .head {
        display: flex;
        justify-content: space-between;
        padding: 30px 0;
        .title {
            flex: 1;
            font-size: 25px;
            letter-spacing: 0.1em;
            color: ${(props) => props.theme.pageTitleText};
        }
    }
    .details {
        background-color: ${(props) => props.theme.subMenuBackground};
        padding: 10px 15px;
        border-radius: 5px;
        & > * {
            padding: 8px 0;
        }
        p > span {
            & + a {
                margin-left: 5px;
            }
        }

    }
    .teams {
        .team {
            padding: 8px 0px;
            .member {
                margin-right: 5px;
                &:before {
                   content: "•";
                }
            }
        }
    }

    .votes {
        margin-top: 20px;
    }

    .created {
        color: ${(props) => props.theme.subMenuActive};
        text-align: right;
        padding:5px 0px;
    }

    .label-base {
        padding: 3px 5px;
        margin-right: 5px;
        border-radius: 5px;
        background-color: ${(props) => props.theme.subMenuHighlight};
    }

    .label-team {
        background-color: ${(props) => props.theme.labelSecondaryBg};
        color: ${(props) => props.theme.labelWarningColor};
    }

    .label-primary {
        background-color: ${(props) => props.theme.labelPrimaryBg};
        color: ${(props) => props.theme.labelPrimaryColor};
    }

    .label-success {
        background-color: ${(props) => props.theme.labelSuccessBg};
        color: ${(props) => props.theme.labelSuccessColor};
    }
    .no-votes {
        background-color: ${(props) => props.theme.labelWarningBg};
        color: ${(props) => props.theme.labelWarningColor};
        padding: 10px;
        border-radius: 5px;
        margin-bottom: 20px;
        .title {
            font-size: 1.2rem;
        }
    }

    .link {
        text-decoration: none;
    }
`;
