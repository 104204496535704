// @ts-nocheck

import { useEffect, useState } from "react";

// FontAwesome
import {
    faCheckCircle,
    faPencil,
    faTimes,
    faUser,
    faUsers,
    faBucket
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Material Ui
import { Tooltip } from "@mui/material";

// Utils
import * as Database from "utils/database.utils";

// Components
import Button from "components/Common/Button/Button";
import ButtonSmall from "components/Common/ButtonSmall/ButtonSmall";
import Select from "components/Common/Select/Select";
import Tabs from "components/Common/Tabs/Tabs";
import BQMQList from "./BQMQList/BQMQList";
import CompanyExtractions from "./CompanyExtractions/CompanyExtractions";
import CompanyHistory from "./CompanyHistory/CompanyHistory";
import CompanyStockSplits from "./CompanyStockSplits/CompanyStockSplits";

// Context
import { useBQMQ } from "context/BQMQ.context";
import { useSettings } from "context/Settings.context";

// Data
import { teamLabelDict, teamOptions, riskBuckets } from "assets/data/lists";

// Styles
import CompanyModalContainer from "./CompanyModal.styles";

type Props = {
    company: any;
    setSelectedCompany: Function;
    reloadCompanies: Function;
};

export default function CompanyModal({
    company,
    setSelectedCompany,
    reloadCompanies,
}: Props) {
    const { setShowLoadingToast, setToast } = useSettings();
    const { analysts } = useBQMQ();

    const [editMode, setEditMode] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [team, setTeam] = useState(company.gim_subteam);
    const [analyst, setAnalyst] = useState(company.analyst_id);

    const [analystList, setAnalystList] = useState<any[]>([]);
    const [loadingAnalystList, setLoadingAnalystList] = useState(false);
    const [riskBucket, setRiskBucket] = useState(company.bespoke_sector)

    const getUserList = async () => {
        const newAnalysts = [...analysts];
        setAnalystList(
            newAnalysts.sort((a: any, b: any) => a.label.localeCompare(b.label))
        );
    };

    useEffect(() => {
        if (editMode) {
            getUserList();
        }
    }, [editMode]);

    const [bqmqLoading, setBqmqLoading] = useState(true);
    const [bqmqData, setBQMQData] = useState<any>([]);

    const getBQMQSessionsList = async () => {
        let res = await Database.get(
            "api/bqmq_voting/sessions/redshift_company/" + company.company_id
        );
        let data = await res.json();

        setBQMQData(
            data.data.map((row: any, index: number) => ({
                ...row,
                tableID: row.id + row.date + index,
            }))
        );

        setBqmqLoading(false);
    };

    useEffect(() => {
        getBQMQSessionsList();
    }, [company]);

    const updateCompany = async () => {
        setIsSaving(true); 
        setShowLoadingToast({
            show: true,
            message: "UPDATING COMPANY",
        });

        try {
            let res = await Database.put("api/companies/edit-company", {
                company_id: company.company_id,
                analyst_id: analyst,
                analyst_name: analystList.find((user) => user.value === analyst)
                    .label,
                gim_subteam: team,
                bespoke_sector: riskBucket
            });

            setToast({
                show: true,
                type: "success",
                message: "Company Updated",
            });
            setEditMode(false);
            setIsSaving(false);
            reloadCompanies();
            setSelectedCompany({
                ...company,
                analyst_id: analyst,
                analyst_name: analystList.find((user) => user.value === analyst)
                    .label,
                gim_subteam: team,
            });
        } catch (err) {
            setToast({
                show: true,
                type: "error",
                message: "Error Updating Company",
            });
            setIsSaving(false);
        }

        setShowLoadingToast({
            show: false,
            message: "UPDATING COMPANY",
        });
    };

    const cancelEdit = () => {
        setEditMode(false);
        setAnalyst(company.analyst_id);
        setTeam(company.gim_subteam);
    };

    const convertTeam = (team) => {
        if (team === "TECH & TELCO") {
            return "TECH_AND_TELCO";
        } else {
            return team;
        }
    };

    return (
        <CompanyModalContainer>
            <div className={"modalInner"}>
                <div className={"header"}>
                    <div className={"topRow"}>
                        <div className={"title"}>
                            <h1>{company.company}</h1>
                            {company.live_company_flag ? (
                                <Tooltip title={"FOCUS LIST"}>
                                    <div>
                                        <FontAwesomeIcon
                                            className={"focusList"}
                                            icon={faCheckCircle}
                                            fixedWidth
                                        />
                                    </div>
                                </Tooltip>
                            ) : null}
                        </div>

                        <div className={"headerActions"}>
                            {editMode ? (
                                <>
                                    <Button
                                        text={"SAVE"}
                                        click={updateCompany}
                                        disabled={isSaving}
                                    />
                                    <Button
                                        text={"CANCEL"}
                                        color={"third"}
                                        click={cancelEdit}
                                        disabled={isSaving}
                                    />
                                </>
                            ) : (
                                <>
                                    <ButtonSmall
                                        text={"EDIT"}
                                        color={"primary"}
                                        icon={faPencil}
                                        click={() => setEditMode(true)}
                                    />
                                    <ButtonSmall
                                        text={"CLOSE"}
                                        color={"third"}
                                        icon={faTimes}
                                        click={() => setSelectedCompany(null)}
                                    />
                                </>
                            )}
                        </div>
                    </div>

                    {editMode ? (
                        <div className={"editOptions"}>
                            <Select
                                filterName={"TEAM"}
                                options={teamOptions}
                                value={convertTeam(team)}
                                setValue={setTeam}
                            />

                            <Select
                                filterName={"ANALYST"}
                                options={analystList}
                                value={analyst}
                                setValue={setAnalyst}
                                loadingOptions={loadingAnalystList}
                                searchable
                            />

                            <Select
                                filterName={"RISK BUCKET"}
                                options={riskBuckets}
                                value={riskBucket}
                                showAll={false}
                                setValue={setRiskBucket}
                            />
                        </div>
                    ) : (
                        <div className={"details"}>
                            <Tooltip title={<h2>TEAM</h2>}>
                                <div className={"detail"}>
                                    <FontAwesomeIcon
                                        icon={faUsers}
                                        fixedWidth
                                    />
                                    <p className={"capitalize"}>
                                        {teamLabelDict[company.gim_subteam]}
                                    </p>
                                </div>
                            </Tooltip>

                            <Tooltip title={<h2>ANALYST</h2>}>
                                <div className={"detail"}>
                                    <FontAwesomeIcon icon={faUser} fixedWidth />
                                    <p>
                                        {company?.analyst_name
                                            ? company.analyst_name
                                            : "-"}
                                    </p>
                                </div>
                            </Tooltip>

                            <Tooltip title={<h2>RISK BUCKET</h2>}>
                                <div className={"detail"}>
                                    <FontAwesomeIcon icon={faBucket} fixedWidth />
                                    <p>
                                        {company?.bespoke_sector
                                            ? company.bespoke_sector
                                            : "-"}
                                    </p>
                                </div>
                            </Tooltip>
                        </div>
                    )}
                </div>

                <Tabs
                    defaultTab={"HISTORY"}
                    dontChangeUrl
                    tabs={[
                        {
                            title: "HISTORY",
                            value: "HISTORY",
                            content: (
                                <CompanyHistory
                                    companyID={company.company_id}
                                />
                            ),
                        },
                        // {
                        //     title: "INFO",
                        //     value: "INFO",
                        //     content: <p>Info</p>,
                        // },
                        // {
                        //     title: "DATA",
                        //     value: "DATA",
                        //     content: (
                        //         <CompanyData companyID={company.company_id} />
                        //     ),
                        // },
                        {
                            title: "EXTRACTIONS",
                            value: "EXTRACTIONS",
                            permissions: ["p_list_extractions"],
                            content: (
                                <CompanyExtractions
                                    companyID={company.company_id}
                                />
                            ),
                        },
                        {
                            title: "STOCK SPLITS",
                            value: "STOCK_SPLITS",
                            content: (
                                <CompanyStockSplits
                                    companyID={company.company_id}
                                />
                            ),
                        },
                        // {
                        //     title: "BUY & SELL",
                        //     value: "BUY_SELL",
                        //     content: <p>Buy & Sell Alerts</p>,
                        // },

                        {
                            title: "BQMQ",
                            value: "BQMQ",
                            permissions: ["p_bqmq_can_view_results"],
                            content: (
                                <BQMQList
                                    bqmqLoading={bqmqLoading}
                                    bqmqData={bqmqData}
                                />
                            ),
                            icon:
                                bqmqData.length > 0
                                    ? // <ModesCell
                                      //     mode={bqmqData[0].mode}
                                      //     noLabels
                                      // />
                                      null
                                    : null,
                        },
                    ]}
                />
            </div>
        </CompanyModalContainer>
    );
}
