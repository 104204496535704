import styled from 'styled-components';

export default styled.div`
    .badge {
        padding: 3px 4px;
        border-radius: 5px;
        background-color: ${(props) => props.theme.labelSecondaryBg};
        color: ${(props) => props.theme.labelSecondaryColor};
    }

    .badge-primary {
        background-color: ${(props) => props.theme.labelPrimaryBg};
        color: ${(props) => props.theme.labelPrimaryColor};
    }

    .badge-success {
        background-color: ${(props) => props.theme.labelSuccessBg};
        color: ${(props) => props.theme.labelSuccessColor};
    }

     .badge-secondary {
        background-color: ${(props) => props.theme.labelSecondaryBg};
        color: ${(props) => props.theme.labelWarningColor};
    }

    .uppercase {
        text-transform: uppercase;
    }
`;
