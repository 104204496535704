const formatDate = (date: string) => {
    let newDate = new Date(date).toLocaleDateString();
    if (newDate === "Invalid Date") {
        let parts = date.split("/");
        let formattedDateStr = `${parts[1]}/${parts[0]}/${parts[2]}`;
        newDate = new Date(formattedDateStr).toLocaleDateString();
    }
    return newDate;
};

export default formatDate;
