export default {
    textBlack: "#000000",
    textGrey: "#4B4B4A",
    textTeal: "#3B6470",
    textBlue: "#51687B",
    textNavy: "#004453",
    textNavyDark: "#002e38",

    fillOlive: "#CBCEB4",
    fillBeige: "#C2BF8F",
    fillTeal: "#C4D6D1",
    fillBlue: "#BCCFD1",
    fillGreen: "#C5D2A0",

    teal100: "#B8D0D2",
    teal80: "#C6D9DA",
    teal60: "#D5E2E3",
    teal50: "#DBE7E8",
    teal30: "#EAF1F1",

    beige: "#E5E5D9",

    // to be deleted
    lightGrey: "#eeeeee",
    lightGreyHover: "#dddddd;",
    lighterGrey: "#FAFAFA",

    grey10: "#111111",
    grey20: "#333333",
    grey30: "#555555",
    grey40: "#777777",
    grey50: "#999999",
    grey60: "#aaaaaa",
    grey70: "#cccccc",
    grey80: "#dddddd",
    grey90: "#eeeeee",
    grey100: "#efefef",

    accentBlue: "#3E99B9",
    accentOrange: "#CB7113",
    accentRed: "#AE1231",
    accentRedDark: "#7c0d22",
    accentGreen: "#A8B300",

    lightBlue: "#d1f3ff",
    lightGreen: "#ecffd8",

    darkAccentBlue: "#2980B9",

    darkThemeMain: "#004c4f",
    darkThemeDark: "#003331",
    darkThemeLight: "#008b8a",
    darkThemeLighter: "#01b2af",
};