import styled from "styled-components";
// import colors from "assets/styles/colors";

export default styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 10px;
    padding-right: 5px;

    color: ${(props) => props.theme.subMenuText};

    min-height: 40px;
    max-height: 40px;

    border-radius: 5px;

    .text {
        display: flex;
        font-size: 14px;
        line-height: 14px;
        margin: 2px 0 0 10px;
        white-space: nowrap;
    }

    :hover {
        background-color: ${(props) => props.theme.subMenuHighlight};
        cursor: pointer;
    }

    &.active {
        background-color: ${(props) => props.theme.subMenuActive};
        color: ${(props) => props.theme.mainMenuForeground};
    }
`;
