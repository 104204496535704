import styled from "styled-components";

export default styled.div`
    display: flex;
    flex-direction: column;

    .formula {
        display: flex;
        max-width: 300px;
        white-space: wrap;
        height: fit-content;
        word-break: break-all;
    }
`;
