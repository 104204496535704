// React
import { useEffect } from "react";

// Styled Components
import themes from "assets/styles/themes";
import { ThemeProvider } from "styled-components";

// Components
import GlobalSearchBox from "components/Common/GlobalSearchBox/GlobalSearchBox";
import LoadingScreen from "components/Common/LoadingScreen/LoadingScreen";
import LoadingToast from "components/Common/LoadingToast/LoadingToast";
import Toast from "components/Common/Toast/Toast";
import Menu from "components/Menu/Menu";
import SettingsMenu from "components/SettingsMenu/SettingsMenu";
import MainRoutes from "screens/MainRoutes";

// Context
import { useAuth } from "context/Auth.context";
import { useSettings } from "context/Settings.context";

// Styles
import AppContainer from "assets/styles/App.styles";

// Logger
import Logger from "utils/logger.utils";
const Log = new Logger();
Log.init("App");

export default function App() {
    const {
        setFullScreen,
        fullScreen,
        theme,
        windowWidth,
        setSubMenuWidth,
    }: {
        setFullScreen: React.Dispatch<React.SetStateAction<boolean>>;
        fullScreen: boolean;
        theme: "light" | "dark" | "highContrast";
        showBQMQBanner: any;
        windowWidth: number;
        windowHeight: number;
        setSubMenuWidth: React.Dispatch<React.SetStateAction<number>>;
    } = useSettings();
    const { user } = useAuth();

    useEffect(() => {
        setFullScreen(true);
        if (windowWidth <= 1180) setSubMenuWidth(15);
        if (windowWidth > 1180) setSubMenuWidth(250);
    }, [windowWidth]);

    return (
        <ThemeProvider theme={themes[theme]}>
            <AppContainer className={"app"}>
                {user && <Menu />}
                <div className="main" id="main">
                    <div
                        id="mainContainer"
                        className={
                            (fullScreen
                                ? "mainContainerFullScreen"
                                : "mainContainer") + " content"
                        }
                    >
                        <MainRoutes />
                    </div>
                </div>
                <Toast />
                <SettingsMenu />
                <GlobalSearchBox />
                <LoadingScreen />
                <LoadingToast />
            </AppContainer>
        </ThemeProvider>
    );
}
