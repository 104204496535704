import styled from "styled-components";

export default styled.div`
    user-select: none;
    flex-direction: column;

    .morning-notes {
        margin-top: 40px;
    }
    
    .mm_excel_model_download {
        margin-left: 430px;
        margin-top: -60px;
    }
    
    .itemContainer {
    display: flex;
    align-items:center;
    gap: 1rem;
    }

    .tabItem {
        .resultsCell {
            margin-left: 10px;
        }

        :hover {
            .resultsCell {
                margin-top: 2px;
            }
        }

        &.active {
            .resultsCell {
                margin-top: 2px;
            }
        }
    }

    .loadingScreen {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
    }

    hr {
        width: calc(100% - 10px);
        outline: none;
        border: none;

        margin: 0 5px;

        border-bottom: 1px solid #efefef;
    }

    .summaryRow {
        display: flex;

        position: relative;

        flex-direction: row;

        flex-wrap: wrap;
        margin: 0 -10px;
        padding-top: 10px;

        .sectionTitle {
            width: calc(100% - 10px);
            text-align: center;
            font-size: 25px;
            position: absolute;
            top: 0;
            margin: 0 5px;
            padding: 0 0 5px 0;

            border-bottom: 2px solid ${(props) => props.theme.tabsBottomBorder};
        }
    }

    .tableDiv {
        margin-top: 20px;
    }

    .pageTitle {
        font-size: 30px;
        color: ${(props) => props.theme.pageTitleText};
        letter-spacing: 1.7px;
        margin: 20px 0 10px 0;
        font-weight: 400;
        display: flex;
        flex-direction: row;
        width: 90%;
    }

    .backButton {
        margin-top: 15px;
    }

    .details {
        display: flex;
        flex-direction: row;
        margin: -15px -5px 0 -5px;

        .detail {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 0 5px;

            p {
                color: ${(props) => props.theme.textPrimary};
                font-size: 14px;
            }

            svg {
                color: #999999;
                font-size: 10px;
                margin-right: 4px;
                margin-bottom: 2px;
            }
        }
    }

    .loadingScreen {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
    }
`;
