export type dataTableType = {
    data_source: string
    file_received_date: string
    fund: string
    parent_table_id?: null | string
    provider: string;
    quarter?: null | string
    report: string
    signed_off: boolean;
    signed_off_dt?: null | string;
    signed_off_responsible?: null | string;
    source_extraction_id: string;
    status: string;
    table_base_id: string;
    table_id: string;
    user_edit: boolean;
    user_edit_dt?: null | string;
    user_edit_responsible?: null | string;
    user_edit_s3_archive_fp?: null | string;
    version: number;
    year: number;
}

export type fileNameMapType = {
    dbn_nt_pf_report_benchmark_monthly: string
    dbn_nt_pf_report_currency_rates_monthly: string
    dbn_nt_pf_report_series_monthly: string
}

export const fileNameMap: fileNameMapType = {
    dbn_nt_pf_report_benchmark_monthly: "Report Benchmark - Monthly",
    dbn_nt_pf_report_currency_rates_monthly: "Report Currency Rates - Monthly",
    dbn_nt_pf_report_series_monthly: "Report Series - Monthly"
}