import { useState } from "react";

// Components
import Item from "components/Docs/DocsSideBar/Item/Item";

// Styles
import DocsMenuContainer from "./DocsSideBar.styles";
import { useEffect } from "react";
import { isElementInViewport } from "utils/general.utils";

type Props = {
    text: string;
};

export default function DocsMenu({ text }: Props) {
    const [list, setList] = useState<any[]>([]);

    const getHeadings = () => {
        let newList = [];
        let body = document.getElementById("docs");
        let h2s = body!.getElementsByTagName("h2");
        let headingArr = Array.from(h2s);

        let visableSet = false;

        if (headingArr.length > 0) {
            for (let heading of headingArr) {
                let headingVisible = false;

                if (!visableSet) {
                    if (isElementInViewport(heading)) {
                        visableSet = true;
                        headingVisible = true;
                    }
                }

                let item = {
                    title: heading.innerText,
                    scroll: heading.offsetTop,
                    visible: headingVisible,
                };

                newList.push(item);
            }

            setList(newList);
        } else {
            setList([]);
        }
    };

    useEffect(() => {
        setTimeout(() => {
            getHeadings();
        }, 100);

        let body = document.getElementById("docs");
        body?.addEventListener("scroll", getHeadings);

        return () => {
            body?.removeEventListener("scroll", getHeadings);
        };
    }, [text]);

    return (
        <DocsMenuContainer>
            <div className="menu">
                {list.map((item) => (
                    <Item item={item} key={item.title} />
                ))}
            </div>
        </DocsMenuContainer>
    );
}
