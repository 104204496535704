// Material UI
import ClickAwayListener from "@mui/material/ClickAwayListener";

// Components
import Button from "components/Common/Button/Button";
import Modal from "components/Common/Modal/Modal";
import UserRow from "./UserRow/UserRow";

// Context
import { useBQMQ } from "context/BQMQ.context";

// Styles
import { useEffect, useState } from "react";
import InvitedUserModalContainer from "./InvitedUserModal.styles";

type Props = {
    companyID: string;
    session: BQMQSessionExtended;
};

export default function InvitedUserModal({ companyID, session }: Props) {
    const { setShowFullUserList } = useBQMQ();
    const [sortedUsers, setSortedUsers] = useState<Analyst[]>([]);

    useEffect(() => {
        if (session) setSortedUsers([...session?.core, ...session?.analysts]);
    }, [session]);

    return (
        <Modal>
            <ClickAwayListener onClickAway={() => setShowFullUserList(false)}>
                <InvitedUserModalContainer>
                    <div className="main">
                        <div className="header">
                            <h3>INVITED ATTENDEES</h3>
                        </div>

                        <div className="userList">
                            {sortedUsers.map((user: Analyst) => (
                                <UserRow
                                    companyID={companyID}
                                    user={user}
                                    session={session}
                                />
                            ))}
                        </div>
                    </div>

                    <div className="actions">
                        <Button
                            text="CLOSE"
                            color="flat"
                            click={() => setShowFullUserList(false)}
                        />
                    </div>
                </InvitedUserModalContainer>
            </ClickAwayListener>
        </Modal>
    );
}
