// Components
import PageTitle from "components/Common/PageTitle/PageTitle";
import Permissions from "components/Screens/Admin/Permissions/Permissions";

// Styles
import PermissionsContainer from "./Permissions.styles";

export default function PermissionsPage() {
    return (
        <PermissionsContainer className={"pageContainer"}>
            <PageTitle title="PERMISSIONS" />
            <Permissions />
        </PermissionsContainer>
    );
}
