import styled from "styled-components";
import colors from "assets/styles/colors";

export default styled.div`
    p {
        line-height: 150%;
        letter-spacing: 0.05em;

        span {
            color: ${colors.accentRed};
            font-weight: bolder;
            cursor: pointer;
            :hover {
                color: ${colors.accentRedDark};
            }
        }
    }

    .title {
        letter-spacing: 0.1em;
        color: ${(props) => props.theme.pageTitleText};
    }

    .selectIcon {
        color: ${(props) => props.theme.pageTitleText};
        font-size: 20px;
        cursor: pointer;
    }

    .searchBar {
        max-width: 100%;
        min-width: 100%;
        display: flex;
        align-items: center;
        z-index: 10;

        .actionBtns {
            display: flex;
            flex-direction: row;

            margin: 0 -10px;
        }
    }
`;
