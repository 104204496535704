import React, {useEffect, useState} from "react";

// Styles
import DublinAsiaContainer from "./DublinAsia.styles";

// Components
import Tabs from "components/Common/Tabs/Tabs";
import Historic from "components/Screens/Fees/Common/FundTabs/HistoricTab/Historic";
import Latest from "components/Screens/Fees/Common/FundTabs/LatestTab/Latest";
import Reports from "components/Screens/Fees/Common/FundTabs/ReportsTab/Reports";
import SubTabs from "components/Common/SubTabs/SubTabs";
import Archive from "components/Screens/Fees/Common/FundTabs/ArchiveTab/Archive";

//utils
import {feesDashboardUrl} from "utils/fees.utils";

interface DublinAsiaProps {
    region: string;
}

const DublinAsia = ({ region }: DublinAsiaProps) => {
    const [feesForcastUrl, setFeesForcastUrl] = useState("");
    const [feesDiscrepancyUrl, setFeesDiscrepancyUrl] = useState("");
    const [feesMonthlyUrl, setFeesMonthlyUrl] = useState("");

    useEffect(() => {
        feesDashboardUrl("DUBLIN_ASIA_FEES", setFeesForcastUrl);
        feesDashboardUrl("DUBLIN_ASIA_DISCREPANCY", setFeesDiscrepancyUrl);
        feesDashboardUrl("DUBLIN_ASIA_MONTHLY", setFeesMonthlyUrl);
    }, []);

    return (
        <DublinAsiaContainer>
            <SubTabs
                defaultTab="latest"
                subTabs={[
                    {
                        value: "latest",
                        title: "LATEST",
                        content: <Latest region={region} fund="dublin" period="latest" />,
                        permissions: ["p_can_view_fees", "p_can_action_fees"],
                    },
                    {
                        value: "reports",
                        title: "REPORTS SIGN OFF",
                        content: <Reports region={region} fund="dublin" />,
                        permissions: ["p_can_view_fees", "p_can_action_fees"],
                    },
                    {
                        value: "fee_report",
                        title: "FEE REPORT",
                        content: (
                            <div className="iframeContainer">
                                <iframe
                                    src={feesForcastUrl}
                                    width="100%"
                                    height="100%"
                                    allowFullScreen
                                    frameBorder={0}
                                />
                            </div>
                        ),
                        permissions: ["p_can_view_fees", "p_can_action_fees"],
                    },
                    {
                        value: "discrepancy_report",
                        title: "DISCREPANCY REPORT",
                        content: (
                            <div className="iframeContainer">
                                <iframe
                                    src={feesDiscrepancyUrl}
                                    width="100%"
                                    height="100%"
                                    allowFullScreen
                                    frameBorder={0}
                                />
                            </div>
                        ),
                        permissions: ["p_can_view_fees", "p_can_action_fees"],
                    },
                    {
                        value: "monthly_report",
                        title: "MONTHLY NAV REPORT",
                        content: (
                            <div className="iframeContainer">
                                <iframe
                                    src={feesMonthlyUrl}
                                    width="100%"
                                    height="100%"
                                    allowFullScreen
                                    frameBorder={0}
                                />
                            </div>
                        ),
                        permissions: ["p_can_view_fees", "p_can_action_fees"],
                    },
                    {
                        value: "archive",
                        title: "ARCHIVE",
                        content: <Archive fund="dublin" region="asia" />,
                        permissions: ["p_can_view_fees", "p_can_action_fees"],
                    },
                ]}
            />
        </DublinAsiaContainer>
    );
};
export default DublinAsia;
