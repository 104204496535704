import { useEffect, useState } from "react";

// FontAwesome
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Utils
import queryString from "query-string";

// Components
import Table from "components/Common/Table/Table";
import CompanySessionChart from "components/Screens/BQMQ/Results/CompanySessionChart/CompanySessionChart";
import ModesCell from "components/Screens/BQMQ/Results/ModesCell/ModesCell";
// Context
import { useBQMQ } from "context/BQMQ.context";
import { useSettings } from "context/Settings.context";

// Utils
import * as Database from "utils/database.utils";
import { toDateAndTime } from "utils/general.utils";

// Styles
import CompanyHeader from "../../../../components/Screens/BQMQ/Results/CompanyHeader/CompanyHeader";
import CompanyDetailContainer from "./CompanyDetail.styles";

export default function CompanyDetail() {
    const { defaultChoices } = useBQMQ();

    const { reloadHomePage, setToast } = useSettings();

    const [loading, setLoading] = useState(true);

    const [companyID, setCompanyID] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [team, setTeam] = useState("");
    const [analyst, setAnalyst] = useState("");
    const [dataWithMode, setDataWithMode] = useState<any[]>([]);
    const [data, setData] = useState<any[]>([]);

    const getCompanySessions = async (id: string) => {
        let res = await Database.get("api/bqmq_voting/sessions/company/" + id);
        let data = await res.json();
        setData(data.data);
        setTeam(data.data[0].company?.sector?.name);
        setAnalyst(data.data[0].presenter.name);
        setLoading(false);
    };

    function countVotes(votes: any[]): any {
        interface CountedVotes {
            [key: string]: {
                [key: string]: any;
                factor: any;
                mode?: string;
            };
        }

        const countedVotes: CountedVotes = {};
        interface Comments {
            [key: string]: any[];
        }
        const comments: Comments = {};
        for (const vote of votes) {
            if (vote.choice.text === "Comment") {
                continue;
            }

            countedVotes[vote.question.text] = countedVotes[
                vote.question.text
            ] || { ...defaultChoices[vote.question.text] };
            countedVotes[vote.question.text][vote.choice.text] =
                (countedVotes[vote.question.text][vote.choice.text] || 0) + 1;

            countedVotes[vote.question.text]["factor"] =
                vote.question.factor.text;
            // Find mode
            let mode = "";
            let maxCount = -1;
            for (const [choice, count] of Object.entries(
                countedVotes[vote.question.text]
            )) {
                if (
                    choice !== "mode" &&
                    choice !== "factor" &&
                    (count as number) > maxCount
                ) {
                    mode = choice;
                    maxCount = count;
                }
            }
            countedVotes[vote.question.text]["mode"] = mode;
        }

        const result: any = {
            comments: comments,
        };

        for (const [question, new_votes] of Object.entries(countedVotes)) {
            const factorText = new_votes.factor;
            result[factorText] = result[factorText] || {};
            result[factorText][question] = new_votes;

            if (
                question === "bq_overall" ||
                question === "mq_overall" ||
                question === "fade" ||
                question === "mqstar"
            ) {
                result[factorText]["mode"] = new_votes["mode"];
            }
        }

        return result;
    }

    useEffect(() => {
        const id = queryString.parse(window.location.search).id;
        const name = queryString.parse(window.location.search).name;
        setCompanyID(id);
        setCompanyName(name);

        getCompanySessions(id);
    }, []);

    useEffect(() => {
        const dataCopy = [...data];
        const newData = [];
        for (let row of dataCopy) {
            if (defaultChoices) {
                const res = countVotes(row.votes);

                row.mode = {
                    BQ: res.BQ?.mode ? res.BQ.mode : null,
                    MQ: res.MQ?.mode ? res.MQ.mode : null,
                };
            }
            newData.push(row);
        }
        setDataWithMode(newData);
    }, [data, defaultChoices]);

    const columns = [
        {
            id: 1,
            title: "COMPANY",
            sortable: false,
            value: "",
            cell: (row: any) => (
                <div data-cy="company-cell" className={"companyCell"}>
                    {row.company.name.toUpperCase()}

                    {row.stage === "LOCKED" && (
                        <FontAwesomeIcon
                            className={"lockedIcon"}
                            icon={faLock}
                            fixedWidth
                        />
                    )}
                </div>
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 3,
            title: "MEETING DATE",
            sortable: false,
            value: "date",
            cell: (date: string) => <p>{toDateAndTime(date)}</p>,
            show: true,
        },
        {
            id: 4,
            title: "CHAIR",
            sortable: false,
            value: "host",
            cell: (host: any) => (
                <p data-cy="company-host">{host.name || "-"}</p>
            ),
            show: true,
        },
        {
            id: 5,
            title: "ANALYST",
            sortable: false,
            value: "presenter",
            cell: (analyst: any) => (
                <p data-cy="company-analyst">{analyst.name || "-"}</p>
            ),
            show: true,
        },
        {
            id: 6,
            title: "MODE",
            sortable: false,
            value: "mode",
            cell: (mode: { BQ: number; MQ: number }) => (
                <ModesCell mode={mode} />
            ),
            show: true,
        },
    ];

    return (
        <CompanyDetailContainer className={"pageContainer"}>
            <CompanyHeader
                companyID={companyID}
                companyName={companyName.toUpperCase()}
                team={team}
                analyst={analyst}
            />

            {data.length > 1 && <CompanySessionChart data={dataWithMode} />}

            <Table
                data={dataWithMode}
                columns={columns}
                filterQuery={
                    "&filter_company_id=" + companyID + "&filter_recent=true"
                }
                defaultSortKey="start_date"
                defaultSortOrder={"desc"}
                emptyText="NO SESSIONS"
                large
                reload={reloadHomePage}
                loading={loading}
                // onRowClick={(row: any) =>
                //     row.summary_sheet_historic
                //         ? setToast({
                //               show: true,
                //               type: "info",
                //               message:
                //                   "Historic summary sheet sessions contain no session data",
                //           })
                //         : history.push("/voting/result?id=" + row.id)
                // }
            />
        </CompanyDetailContainer>
    );
}
