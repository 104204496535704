// Styles
import Skeleton from "@mui/material/Skeleton";
import SkeletonLoaderContainer from "./SkeletonLoader.styles";

type Props = {
    type: "table" | "stat" | "auto";
    rows?: number;
};

export default function SkeletonLoader({ type, rows }: Props) {
    const arrayLength = Array.from(Array(rows).keys());

    return (
        <SkeletonLoaderContainer>
            {type === "table" && (
                <div className="table">
                    <div className="table-heading">
                        <Skeleton
                            className="skeleton"
                            variant="rectangular"
                            animation="wave"
                            width="100%"
                            height="100%"
                        />
                    </div>
                    {arrayLength.map((key) => (
                        <div key={key} className="row">
                            <Skeleton
                                className="skeleton"
                                variant="rectangular"
                                animation="wave"
                                width="100%"
                                height="100%"
                            />
                        </div>
                    ))}
                </div>
            )}
            {type === "stat" && (
                <div className="stat">
                    <Skeleton
                        className="skeleton"
                        height="100%"
                        width="100%"
                        variant="rectangular"
                        animation="wave"
                    />
                </div>
            )}
            {type === "auto" && (
                <div className="auto">
                    <Skeleton
                        className="skeleton"
                        height="100%"
                        width="100%"
                        variant="rectangular"
                        animation="wave"
                    />
                </div>
            )}
        </SkeletonLoaderContainer>
    );
}
