import styled from 'styled-components';

export default styled.div`
    .generateBtn {
        margin-top: 15px;
        margin-bottom: 15px;
        float: right;
    }

    .tableDiv {
        margin-top: 20px;
        clear: right;
    }   
`;