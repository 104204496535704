import styled from "styled-components";

export default styled.div`
    width: 100%;

    .questionsRow {
        display: flex;
        flex-direction: row;

        min-height: 250px;
        max-height: 250px;

        border-bottom: 1px solid #aaa;

        &.short {
            min-height: 100px;
            max-height: 100px;
        }

        .name {
            display: flex;
            min-width: 200px;
            max-width: 200px;

            border-right: 1px solid #aaa;
        }

        .question {
            display: flex;
            //min-width: 70px;
            //max-width: 70px;

            flex: 1 1;

            padding-bottom: 20px;
            padding-top: 20px;

            border-left: 1px solid #efefef;

            justify-content: center;
            align-items: flex-end;

            p {
                writing-mode: vertical-rl;
                text-orientation: sideways;
                text-align: end;
                transform: rotate(180deg);
            }

            &.highlight {
                background-color: ${(props) =>
                    props.theme.homeListBodyBackgroundHover};
            }

            :nth-child(2) {
                p {
                    font-weight: bold;
                }
            }
        }
    }

    .userRow {
        display: flex;
        flex-direction: row;

        min-height: 50px;
        max-height: 50px;

        border-top: 1px solid #efefef;

        .user-details-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .name {
            display: flex;
            align-items: center;
            justify-content: space-between;

            min-width: 200px;
            max-width: 200px;

            padding-left: 10px;

            border-right: 1px solid #aaa;

            .removeVotesBtn {
                color: ${(props) => props.theme.accentButtonBackground};
                margin-right: 5px;
                cursor: pointer;

                :hover {
                    color: ${(props) =>
                        props.theme.accentButtonBackgroundHover};
                }
            }
        }

        .answerCell {
            display: flex;
            justify-content: center;
            align-items: center;

            flex: 1 1;

            min-height: 50px;
            max-height: 50px;

            border-left: 1px solid #efefef;

            .answer {
                display: flex;
                justify-content: center;
                align-items: center;

                background-color: red;

                min-width: 30px;
                max-width: 30px;
                min-height: 30px;
                max-height: 30px;

                p {
                }
            }

            &.highlight {
                background-color: ${(props) =>
                    props.theme.homeListBodyBackgroundHover};
            }
        }

        :hover {
            border-radius: 5px;
            background-color: ${(props) =>
                props.theme.homeListBodyBackgroundHover};
        }
    }
`;
