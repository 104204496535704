import { useState, useEffect } from "react";

// Material UI
import Tooltip from "@mui/material/Tooltip";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

// Components
import Status from "components/Screens/Data/CompanyModel/Status/Status";
import Actions from "components/Screens/Data/CompanyModel/Actions/Actions";
import PageTitle from "components/Common/PageTitle/PageTitle";
import SearchOptions from "components/Common/SearchOptions/SearchOptions";
import Table from "components/Common/Table/Table";
import ExtractionBatch from "components/Screens/Data/CompanyModel/ExtractionBatch/ExtractionBatch";
import TableCell from "components/Common/TableCell/TableCell";

// Utils
import { getAllCompanies } from "utils/companies.utils";

// Data
import {
    teamOptions,
    statusOptions,
    methodOptions,
    teamLabelDict,
} from "assets/data/lists";

// Styles
import CompanyModelListContainer from "./CompanyModelList.styles";

const CompanyModelList: React.FC<{
    homepageQuery?: string;
    homePage?: boolean;
}> = ({ homepageQuery, homePage }) => {
    const [filterQuery, setFilterQuery] = useState<string>("");
    const [reload, setReload] = useState(0);

    const [companyOptions, setCompanyOptions] = useState<any[]>([]);

    const getCompanies = () => {
        let queryString = filterQuery.includes("&filter_gim_subteam=")
            ? "&filter_gim_subteam=" +
              filterQuery.split("&filter_gim_subteam=")[1].split("&")[0]
            : "";

        let pageQuery = "?page=1&pageSize=1000&sortKey=company&sortOrder=ASC";

        getAllCompanies(pageQuery, queryString).then((res) => {
            if (res.ok) {
                res.json().then((data) => {
                    setCompanyOptions(
                        data.data.map((comp: any) => ({
                            label: comp.company,
                            value: comp.company_id,
                        }))
                    );
                });
            }
        });
    };

    useEffect(() => {
        getCompanies();
    }, [filterQuery]);

    const columns = [
        {
            id: 1,
            title: "COMPANY",
            sortable: true,
            value: "company",
            cell: (data: any) => (
                <div className="companyName">
                    <p className="capitalize">{data.company.toLowerCase()}</p>
                    {Boolean(data.current_model_flag) && (
                        <Tooltip title={"CURRENT MODEL"}>
                            <div className="currentModel">
                                <FontAwesomeIcon
                                    icon={faCheckCircle}
                                    fixedWidth
                                />
                            </div>
                        </Tooltip>
                    )}
                </div>
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 2,
            title: "TEAM",
            sortable: true,
            value: "sub_team",
            cell: (data: string) => (
                // @ts-ignore
                <TableCell styleTypes="capitalize" data={teamLabelDict[data]} />
            ),
            show: true,
        },
        {
            id: 3,
            title: "METHOD",
            sortable: true,
            value: "processing_method",
            cell: (data: string) => (
                <TableCell styleTypes="capitalize" data={data.toLowerCase()} />
            ),
            show: true,
        },
        {
            id: 4,
            title: "EXTRACTION BATCH",
            sortable: true,
            value: "",
            cell: (data: any) => (
                <ExtractionBatch
                    date={data.extraction_datetime}
                    companyExtractGroup={data.company_extract_group}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 5,
            title: "STATUS",
            sortable: true,
            value: "model_status",
            cell: (data: any) => (
                <Status
                    status={
                        data.num_errors_critical > 0 ? "FAI" : data.model_status
                    }
                    extractionId={data.id}
                    info={data.num_errors_info}
                    warning={data.num_errors_warning}
                    critical={data.num_errors_critical}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 6,
            title: "",
            sortable: false,
            value: "model_status",
            cell: (data: any) => (
                <Actions
                    status={
                        data.num_errors_critical > 0 ? "FAI" : data.model_status
                    }
                    id={data.id}
                    companyName={data.company}
                    companyID={data.company_id}
                    extraction_datetime={data.extraction_datetime}
                    processing_datetime={data.processing_datetime}
                    archiveFolderPath={data.archive_folder_path}
                    reload={() => setReload(reload + 1)}
                />
            ),
            show: true,
            fullDataRow: true,
        },
    ];

    const filterOptions = [
        {
            id: 1,
            name: "Company",
            value: "company",
            type: "DROPDOWN",
            searchable: true,
            options: companyOptions,
        },
        {
            id: 2,
            name: "Team",
            value: "gim_subteam",
            type: "DROPDOWN",
            options: teamOptions,
            disabled: "company",
        },
        {
            id: 3,
            name: "Status",
            value: "model_status",
            type: "DROPDOWN",
            options: statusOptions,
        },
        {
            id: 4,
            name: "Method",
            value: "method",
            type: "DROPDOWN",
            options: methodOptions,
        },
        { id: 5, name: "Extracted On", value: "date", type: "DATE" },
        {
            id: 6,
            name: "Current Models",
            value: "current_models",
            type: "SWITCH",
        },
        {
            id: 7,
            name: "Model Included in Reporting",
            value: "include_model_flag",
            type: "SWITCH",
        },
    ];

    return (
        <CompanyModelListContainer>
            {!homePage && <PageTitle title="COMPANY MODELS" />}

            <SearchOptions
                setFilterQuery={setFilterQuery}
                options={filterOptions}
            />

            <Table
                columns={columns}
                url={"api/extractions"}
                filterQuery={
                    homepageQuery ? filterQuery + homepageQuery : filterQuery
                }
                defaultSortKey="extraction_datetime"
                defaultSortOrder="DESC"
                pagination
                large
                selectable="EXTRACTION"
                emptyText="NO EXTRACTIONS"
                reload={reload}
            />
        </CompanyModelListContainer>
    );
};

export default CompanyModelList;
