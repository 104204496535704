import { useState } from "react";

// Components
import Button from "components/Common/Button/Button";
import TextArea from "components/Common/TextArea/TextArea";
import Alert from "components/Common/Alert/Alert";

// Utils
import * as Database from "utils/database.utils";

// Styles
import DeleteExtractionModalContainer from "./DeleteExtractionModal.styles";

type Props = {
    id: string;
    setShowModal: Function;
    reload: Function;
};

export default function DeleteExtractionModal({
    id,
    setShowModal,
    reload,
}: Props) {
    const [notes, setNotes] = useState<string>("");
    const [showNotes, setShowNotes] = useState<boolean>(false);

    const [mode, setMode] = useState<
        "DEFAULT" | "SUCCESS" | "ERROR" | "LOADING"
    >("DEFAULT");

    const handleDelete = () => {
        setMode("LOADING");

        let data = {
            extraction_id: id,
            user_id: localStorage.getItem("gim-user-id"),
            user_notes: notes,
        };

        Database.post("api/model_actions/delete", data)
            .then((res) => {
                if (res.ok) {
                    setMode("SUCCESS");
                } else {
                    setMode("ERROR");
                }
            })
            .catch((err) => {
                setMode("ERROR");
            });
    };

    return (
        <Alert>
            <DeleteExtractionModalContainer>
                {mode === "DEFAULT" && (
                    <>
                        <h2>DELETE</h2>

                        <p>
                            Are you sure you would like to delete this
                            extraction?
                        </p>

                        {showNotes && (
                            <TextArea
                                value={notes}
                                change={setNotes}
                                placeholder="NOTES (OPTIONAL)"
                                rows={5}
                                label="NOTES"
                            />
                        )}

                        {!showNotes && (
                            <p
                                className="addNotesBtn"
                                onClick={() => setShowNotes(true)}
                            >
                                ADD NOTES
                            </p>
                        )}

                        <Button
                            text="DELETE"
                            color="accent"
                            click={handleDelete}
                        />
                        <Button
                            text="CANCEL"
                            color="flat"
                            click={() => setShowModal(false)}
                        />
                    </>
                )}

                {mode === "SUCCESS" && (
                    <>
                        <h2>EXTRACTION DELETED</h2>
                        <p>Extraction has been deleted successfully.</p>
                        <Button
                            text="CLOSE"
                            color="primary"
                            click={() => {
                                reload();
                                setShowModal(false);
                            }}
                        />
                    </>
                )}

                {mode === "ERROR" && (
                    <>
                        <h2>ERROR</h2>
                        <p>There was a probelm deleting the extraction.</p>

                        <Button
                            text="CLOSE"
                            color="primary"
                            click={() => setShowModal(false)}
                        />
                    </>
                )}

                {mode === "LOADING" && (
                    <>
                        <h2>DELETING...</h2>
                    </>
                )}
            </DeleteExtractionModalContainer>
        </Alert>
    );
}
