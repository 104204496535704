// Styles
import IframeContainer from "../IframeContainer.styles";

type Props = {};

export default function ChartsAndAnalysis({}: Props) {
    return (
        <IframeContainer>
            <div className="iframeContainer">
                <iframe
                    src="https://app.powerbi.com/reportEmbed?reportId=ce960974-a7a7-4272-9cd1-df4e96aedd6e&autoAuth=true&ctid=bf4450a2-bfcb-472d-8c40-1425eafb1a3b&filterPaneEnabled=false"
                    title="Fund Charts and Analysis vLive (QS)"
                    width="100%"
                    height="100%"
                    allowFullScreen
                    frameBorder={0}
                />
            </div>
        </IframeContainer>
    );
}
