import { useState } from "react";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faPlus } from "@fortawesome/free-solid-svg-icons";

// Components
import EditAnalystModal from "./EditAnalystModal/EditAnalystModal";

// Styles
import AnalystCellContainer from "./AnalystCell.styles";

type Props = {
    company: any;
    editAnalystMode: boolean;
    reloadCompanies: number;
    setReloadCompanies: Function;
};

export default function AnalystCell({
    company,
    editAnalystMode,
    reloadCompanies,
    setReloadCompanies,
}: Props) {
    const [showEditAnalystModal, setShowEditAnalystModal] = useState(false);

    return (
        <>
            <AnalystCellContainer
                className={
                    (!company.analyst_name ? "center " : "") +
                    (editAnalystMode ? " editMode" : "")
                }
                onClick={
                    editAnalystMode
                        ? () => setShowEditAnalystModal(true)
                        : () => {}
                }
            >
                {editAnalystMode ? (
                    company.analyst_name ? (
                        <>
                            <p className="capitalize">
                                {company.analyst_name.toLowerCase()}
                            </p>
                            <FontAwesomeIcon
                                className={"editBtn"}
                                icon={faPencil}
                                fixedWidth
                            />
                        </>
                    ) : (
                        <FontAwesomeIcon
                            className={"addBtn"}
                            icon={faPlus}
                            fixedWidth
                        />
                    )
                ) : (
                    <p className="capitalize">
                        {company.analyst_name?.toLowerCase()}
                    </p>
                )}
            </AnalystCellContainer>

            {showEditAnalystModal && (
                <EditAnalystModal
                    company={company}
                    setShowEditAnalystModal={setShowEditAnalystModal}
                    reloadCompanies={reloadCompanies}
                    setReloadCompanies={setReloadCompanies}
                />
            )}
        </>
    );
}
