import { useState } from "react";
import { useHistory } from "react-router-dom";

// Material UI
import Tooltip from "@mui/material/Tooltip";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

// Components
import Table from "components/Common/Table/Table";
import TableCell from "components/Common/TableCell/TableCell";
import ExtractionBatch from "components/Screens/Data/CompanyModel/ExtractionBatch/ExtractionBatch";
import Status from "components/Screens/Data/CompanyModel/Status/Status";

// Styles
import CompanyExtractionsContainer from "./CompanyExtractions.styles";

type Props = { companyID: number };

export default function CompanyExtractions({ companyID }: Props) {
    const history = useHistory();

    const [reload, setReload] = useState(0);

    const columns = [
        {
            id: 1,
            title: "CURRENT",
            sortable: false,
            value: "company",
            cell: (data: any) =>
                Boolean(data.current_model_flag) && (
                    <Tooltip title={"CURRENT MODEL"}>
                        <div className="currentModel">
                            <FontAwesomeIcon icon={faCheckCircle} fixedWidth />
                        </div>
                    </Tooltip>
                ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 3,
            title: "METHOD",
            sortable: false,
            value: "processing_method",
            cell: (data: string) => (
                <TableCell styleTypes="capitalize" data={data.toLowerCase()} />
            ),
            show: true,
        },
        {
            id: 4,
            title: "EXTRACTION BATCH",
            sortable: false,
            value: "",
            cell: (data: any) => (
                <ExtractionBatch
                    date={data.extraction_datetime}
                    companyExtractGroup={data.company_extract_group}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 5,
            title: "STATUS",
            sortable: false,
            value: "model_status",
            cell: (data: any) => (
                <Status
                    status={
                        data.num_errors_critical > 0 ? "FAI" : data.model_status
                    }
                    extractionId={data.id}
                    info={data.num_errors_info}
                    warning={data.num_errors_warning}
                    critical={data.num_errors_critical}
                />
            ),
            show: true,
            fullDataRow: true,
        },
    ];

    return (
        <CompanyExtractionsContainer>
            <Table
                columns={columns}
                url={"api/extractions"}
                filterQuery={"&filter_company=" + companyID}
                defaultSortKey="extraction_datetime"
                defaultSortOrder="DESC"
                defaultLimit={10}
                pagination
                large
                emptyText="NO EXTRACTIONS"
                reload={reload}
                skeletonLength={3}
                onRowClick={() =>
                    history.push("/data/company-model?id=" + companyID)
                }
            />
        </CompanyExtractionsContainer>
    );
}
