import styled from 'styled-components';

export default styled.div`
.form {
 padding: 0 10px;

 .inputContainer {
    margin-bottom: 20px;
 }

 .company {
    margin: 20px 0px 25px 0px;
    .label {
        margin-left: 60px;
    }
 }

 .company-edit-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .field {flex:1}
 }

 label.required:after {
    content: '*';
    color: red;
    margin-left: 5px;
 }
}
`;
