import styled from "styled-components";

export default styled.div`
    display: flex;
    flex-direction: column;

    border-radius: 5px;
    overflow: hidden;

    margin-bottom: 20px;

    user-select: none;

    .titleRow {
        display: flex;
        flex-direction: row;
        align-items: center;

        background-color: ${(props) => props.theme.homeListTitleRowBackground};
        color: ${(props) => props.theme.homeListTitleRowText};

        min-height: 40px;
        max-height: 40px;

        border-radius: 5px 5px 0 0;

        .titleText {
            display: flex;
            flex-grow: 1;

            padding: 0 15px;

            font-size: 25px;
            letter-spacing: 0.1em;

            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .count {
            display: flex;
            align-items: center;
            justify-content: center;

            min-height: 40px;
            max-height: 40px;
            min-width: 40px;

            padding: 0 10px;

            font-size: 25px;
            font-weight: bolder;

            background-color: ${(props) =>
                props.theme.homeListCountBackgroundDefault};
            color: ${(props) => props.theme.homeListCountTextDefault};

            .spinner {
                margin: 0;
                svg {
                    font-size: 25px;
                    color: ${(props) => props.theme.homeListCountTextDefault};
                }
            }

            &.failed {
                background-color: ${(props) =>
                    props.theme.subMenuBadgeBackground};
                color: ${(props) => props.theme.homeListCountTextDefault};
                .spinner {
                    svg {
                        color: ${(props) =>
                            props.theme.homeListCountTextDefault};
                    }
                }
            }
            &.holding {
                background-color: ${(props) =>
                    props.theme.subMenuBadgeBackgroundSecondary};
                color: ${(props) => props.theme.homeListCountTextDefault};
                .spinner {
                    svg {
                        color: ${(props) =>
                            props.theme.homeListCountTextDefault};
                    }
                }
            }
        }
    }

    .listBody {
        display: flex;
        flex-direction: column;

        min-width: 100%;
        max-width: 100%;

        position: relative;

        transition: 1s all;

        .listRow {
            background: ${(props) => props.theme.whiteBackground};
            min-height: 40px;
            border-right: 1px solid
                ${(props) => props.theme.homeListBodyBorderColor};
            border-left: 1px solid
                ${(props) => props.theme.homeListBodyBorderColor};
            border-bottom: 1px solid
                ${(props) => props.theme.homeListBodyBorderColor};

            :last-child {
                border-radius: 0 0 5px 5px;
            }
        }
    }

    .seeAll {
        display: flex;
        justify-content: center;
        align-items: center;

        min-width: 100%;
        max-width: 100%;
        min-height: 40px;
        max-height: 20px;

        font-size: 16px;

        color: ${(props) => props.theme.homeListSeeAllText};
        background: ${(props) => props.theme.whiteBackground};

        svg {
            font-size: 16px;
            margin-left: 5px;
            margin-bottom: 1px;
        }

        cursor: pointer;

        :hover {
            background-color: ${(props) =>
                props.theme.homeListBodyBackgroundHover};
        }
    }
`;
