import * as Database from "utils/database.utils";
import jwt_decode from "jwt-decode";
// Logger
import Logger from "utils/logger.utils";
import { msalConfig, CacheLookupPolicy } from "utils/msal.config";
const Log = new Logger();
Log.init("user.utils");

export const getPermissions = async () => {
    Log.info("getPermissions()");

    let res = await Database.get("api/security/user-permissions");
    let data = await res.json();
    return data.permissions;
};

export const saveUserTokens = (res) => {
    Log.info("saveUserTokens()");
    let userToken = jwt_decode(res.accessToken);

    localStorage.setItem("gim-token", res.accessToken);
    localStorage.setItem("gim-user-id", res.account.localAccountId);
    localStorage.setItem("gim-expiry", userToken.exp);
    localStorage.setItem("gim-audience", res.idTokenClaims.aud);
};

export const isTokens = () => {
    const isTokens = [
        localStorage.getItem("gim-token"),
        localStorage.getItem("gim-user-id"),
        localStorage.getItem("gim-expiry"),
        localStorage.getItem("gim-audience"),
    ].some((item) => item !== null);

    return isTokens;
};

export const setLastRoute = (route, userID) => {
    // set the last route of the user
    // if no user (not logged in yet), we assume it was the last user
    if (!userID) {
        let lastRoute = localStorage.getItem("lastRoute")

        if (lastRoute) {
           lastRoute = JSON.parse(lastRoute);
           userID = lastRoute.userID
        }
    }

    if (!userID) {
        return
    }

    localStorage.setItem(
        "lastRoute",
        JSON.stringify({
            userID: userID,
            route: route,
        })
    );
}

export const logout = () => {
    // only clear session data in the browser
    Log.info("logout()");
    clearStoredCredentials();
    window.location.href = "/login";
};

export const hardLogout = async () => {
    // secure logout, kill the session on the Msal server and locally as well
    const logoutRequest = {
        account: getAccount(),
    };

    await msalConfig.logoutRedirect(logoutRequest);
}

export const clearStoredCredentials = () => {
    // clear local storage but keep what you have to
    let lastRoute = localStorage.getItem("lastRoute");
    localStorage.clear();
    localStorage.setItem("lastRoute", lastRoute);
}

export const setValidToken = async () => {
    if (window.Cypress) {
        return;
    }

    let res = await acquireToken();

    saveUserTokens(res);

    return res;
}

export const acquireToken = async () => {
    let res;

    const account = getAccount();

    let silentRequest = {
        scopes: getScopes(),
        account: account,
        forceRefresh: false,
        cacheLookupPolicy: CacheLookupPolicy.AccessToken
    }

    if (!is_valid_login_period()) {
        logout();
        return;
    }

    try {
        res = await msalConfig.acquireTokenSilent(silentRequest);
    } catch(err) {
        logout();
        return;
    }

    return res;
};

export const getScopes = () => {
    // provide a list of scopes for msal
    return ["offline_access", "User.Read"]
}

export const getAccount = () => {
    let account = null;

    try {
       const accounts = msalConfig.getAllAccounts();
       if (accounts.length > 0) {
           account = accounts[0]
       }
    } catch {}

   return account;
}

export const is_valid_login_period = () => {
    // gim has a request to limit the length of the login period to 24h.
    // the refresh token is valid longer, so we have to track and check it by ourself
    let lastLoginTime = localStorage.getItem("gim-last-login");

    if (!lastLoginTime) {
        return false;
    }

    // last time when the user logged in
    lastLoginTime = parseInt(lastLoginTime);

    // allowed login period in milliseconds
    let allowedLoginPeriod = 86400000; //60 * 60 * 24 * 1000

    let lastAllowedLoginTime = new Date().getTime() - allowedLoginPeriod;

    if (lastLoginTime < lastAllowedLoginTime) {
       return false
    }

    return true
}

export const getUsersDefaultHomePage = (permissions) => {
    if (
        !permissions.includes("p_list_extractions") &&
        permissions.includes("p_bqmq_can_view_results")
    )
        return "/voting";
    if (
        !permissions.includes("p_list_extractions") &&
        permissions.includes("p_can_view_fees")
    )
        return "/fees/dublin?tab=global";
    if (
        !permissions.includes("p_list_extractions") &&
        permissions.includes("p_can_submit_sfdr_esg_questionnaire")
    )
        return "/sfdr-data-capture";
    return "/ada/snapshot";
};
