import styled from "styled-components";

export default styled.div`
    .titleRow {
        .actions {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        h1 {
            margin-bottom: 0;
        }
    }

    .resultsTitle {
        display: flex;
        flex-direction: row;
        align-items: center;

        .lockedIcon {
            display: flex;

            color: ${(props) => props.theme.tableIconHighlight};

            font-size: 15px;
            margin-bottom: 5px;
            margin-left: 10px;
        }
    }

    .details {
        display: flex;
        flex-direction: row;
        margin: 0 -5px 20px -5px;

        .detail {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 0 5px;

            p {
                color: ${(props) => props.theme.textPrimary};
                font-size: 14px;
            }

            svg {
                color: #999999;
                font-size: 10px;
                margin-right: 4px;
                margin-bottom: 2px;
            }
        }
    }
`;
