import React, {useEffect, useState} from "react";
import { Pagination } from '@mui/material';

import { useLocation } from 'react-router';
import { useHistory } from "react-router-dom";


// Styles
import DealsContainer from "./GrowthDeals.styles";

// Utils
import { ApiHandler } from "services/api_handler";
import { sortDealVotes} from "utils/growth_voting.utils";

// Interfaces
import { FullDealDetails, DealFilters, GrowthPagination} from "assets/interfaces/growth_voting";

//Context
import { useSettings } from "context/Settings.context";

//Components
import CreateGrowthVotingModal from 'components/Screens/GrowthVoting/EditGrowthVotingModal/EditGrowthVotingModal';
import DealList from "components/Screens/GrowthVoting/DealList/DealList";
import DealFilter from "components/Screens/GrowthVoting/DealFilter/DealFilter";
import Button from "components/Common/Button/Button";

// Context
import {GrowthUsersProvider} from "context/growth/GrowthUser.context";
import {GrowthCompaniesProvider} from "context/growth/GrowthCompany.context";

import {faPlus} from "@fortawesome/free-solid-svg-icons";


const URL_API_DEAL_LIST = "/api/growth_voting/deals";
const URL_FE_DEAL_LIST = "/growth-voting/deals";
const PAGE_SIZE = "25";


const GrowthDeals = () => {
    const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
    const [deals, setDeals] = useState<FullDealDetails[]>([]);
    const [filters, setFilters] = useState<DealFilters>({status: "open", search: "", page: "1", page_size: PAGE_SIZE});
    const [filterParam, setFilterParams] = useState<string>(`?page=1&page_size=${PAGE_SIZE}&status=open`);
    const [page, setPage] = useState<string>("1");
    const [search, setSearch] = useState<boolean>(false);
    const [pagination, setPagination] = useState<GrowthPagination|null>(null);


    const history = useHistory();
    const location = useLocation();
    const { setShowLoadingToast, setToast } = useSettings();

    const updateUrl = async () => {
        // TODO: updateing the browser history with the params is not working currenlty, it fire up rerendering of the component
        history.push(URL_FE_DEAL_LIST + filterParam, { shallow: true });
        //window.history.replaceState(null, "Growth Deals", URL_FE_DEAL_LIST + filterParam)
        //window.history.pushState(filterParam, "")
    };

    const setUrlParams = () => {
        let filterParam = getFilterParam(filters);

        let paramUrl = "?"

        if (filterParam) {
            paramUrl += `${filterParam}`;
        }

        setFilterParams(paramUrl);
    }

    const fetchDeals = async () => {
        let url = URL_API_DEAL_LIST + filterParam;
        let response = await new  ApiHandler(url, setShowLoadingToast, setToast).list();

        sortDeals(response.data.data)

        setPagination({
            total: response.data.total,
            page: response.data.page,
            pages: response.data.pages,
            next: response.data.next,
            prev: response.data.prev,
            page_size: response.data.page_size
        });
    }

    const sortDeals = (deals: FullDealDetails[]) => {
        setDeals(deals.map((d) => { sortDealVotes(d); return d;}));
    }

    const getFilterParam = (filters: DealFilters) => {
        let filter = "";

        // page is set from useState to instantly react on it
        filter += `page=${page}`;

        filter += `&page_size=${filters.page_size}`;

        if (filters.search) {
            filter += `&search=${filters.search}`;
        }

        if (filters.status) {
            filter += `&status=${filters.status}`;
        }

        return filter;
    }

    const handlePageChange = (page: number) => {
        setPage(page.toString());
    }

    useEffect(() => {
        fetchDeals();
    }, [filterParam]);

    useEffect(() => {
        setFilters({...filters, page: page});
        setUrlParams();
    }, [page]);

    useEffect(() => {
        if (!search) return;

        if (page === "1") {
            setUrlParams();
        } else {
            setPage("1");
        }

        setSearch(false);
    }, [search]);

    return (
        <GrowthCompaniesProvider>
            <GrowthUsersProvider>
                <DealsContainer>
                    {showCreateModal && (
                        <CreateGrowthVotingModal
                            showModal={showCreateModal}
                            setShowModal={setShowCreateModal}
                        />
                    )}

                    <p data-cy="deals-main-title" className={"sectionTitle"}>
                        DEALS
                    </p>


                    <div className={"header-actions"}>
                        <div className={"filters"}>
                            <DealFilter filters={filters} setFilters={setFilters} search={search} setSearch={setSearch} />
                        </div>
                        <Button
                            text="CREATE DEAL"
                            className=""
                            icon={faPlus}
                            click={() => setShowCreateModal(!showCreateModal)}
                        />
                    </div>
                    {pagination && <div className={"total"}>Total: {pagination.total}</div>}
                    <DealList deals={deals} />
                    {deals.length > 0 && pagination && <div className={"pagination"}><Pagination
                        count={pagination.pages}
                        page={parseInt(page)}
                        onChange={(event, page) => {handlePageChange(page)}}
                        showFirstButton={true}
                        showLastButton={true}
                        /></div>}
                    {deals.length === 0 && <div className={"no-content"}>No deals found</div>}
                </DealsContainer>
            </GrowthUsersProvider>
        </GrowthCompaniesProvider>
    );
};
export default GrowthDeals;
