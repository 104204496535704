import styled from 'styled-components';
import breakpoints from "assets/styles/breakpoints";

export default styled.div`
.votes {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 8px;
    margin-bottom: 50px;
  & > .vote {
    padding: 10px;
    border: 1px solid ${(props) => props.theme.votingCellBorder};
    border-radius: 5px;

    .header {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        .date {
            width: 100px;
        }
        .type {
            font-weight: bold;
            display: flex;
            align-items: center;
            width: 220px;
            font-size: 1.2rem;
        }
        .participation {
            margin-left: 15px;
            padding: 3px 8px;
            border-radius: 5px;
            border: 1px solid ${(props) => props.theme.pageTitleText};
        }
        .missing {
            margin-left: 14px;
            margin-right: 15px;
        }
        .date {
            color: ${(props) => props.theme.votingCellBorder};
            margin-left: 15px;
        }
    }
  }
}

.sectionTitle {
    font-size: 18px;
    letter-spacing: 0.1em;

    color: ${(props) => props.theme.pageTitleText};
    margin-bottom: 10px;
    margin-top: 20px;
}

.no-vote {
     color: ${(props) => props.theme.subMenuText};
     font-size: 0.9rem;
     margin-top: -5px;
}

@media screen and (max-width: 1350px) {
    .votes {
        grid-template-columns: 100%;
    }
}

@media screen and (max-width: 2500px) and (min-width: 1351px)  {
    .votes {
        grid-template-columns: 50% 50%;
    }
}

@media screen and (min-width: 2501px) {
    .votes {
        grid-template-columns: 33.3% 33.3% 33.3%;
    }
}
`;
