import React, { useState, useEffect } from "react";

// Material UI
import { FormControlLabel, Switch } from "@mui/material";

// Components
import Button from "components/Common/Button/Button";
import Modal from "components/Common/Modal/Modal";

// Styles
import ColumnSelectModalContainer from "./ColumnSelectModal.styles";

type Props = {
    columns: Column[];
    setColumns: Function;
    setShowColumnSelectModal: Function;
};

export default function ColumnSelectModal({
    columns,
    setColumns,
    setShowColumnSelectModal,
}: Props) {
    const [modalColumns, setModalColumns] = useState<any[]>(columns);
    const [selectedCount, setSelectedCount] = useState<number>(10);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let newColumns = modalColumns.map((col) => {
            if (col.value === event.target.name) {
                return { ...col, show: event.target.checked };
            } else {
                return col;
            }
        });
        setModalColumns(newColumns);
    };

    const saveColumns = () => {
        setColumns(modalColumns);
        setShowColumnSelectModal(false);
    };

    const selectAllNone = () => {
        let selectedCount = getSelectedCount();

        let newColumns = modalColumns.map((col) => {
            return { ...col, show: selectedCount !== modalColumns.length };
        });

        setModalColumns(newColumns);
    };

    const getSelectedCount = () => {
        let count = 0;
        modalColumns.forEach((col) => {
            if (col.show === true) count++;
        });

        setSelectedCount(count);
        return count;
    };

    useEffect(() => {
        getSelectedCount();
    }, [modalColumns]);

    return (
        <ColumnSelectModalContainer>
            <Modal>
                <div className="main">
                    <div className="text">
                        <h3>SELECT FIELDS</h3>
                    </div>

                    <div className="switches">
                        {modalColumns.map((col, index) => (
                            <div className="switch" key={index}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={col.show}
                                            name={col.value}
                                            onChange={handleChange}
                                        />
                                    }
                                    label={col.title}
                                />
                            </div>
                        ))}
                        <div className="switch">
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={
                                            selectedCount ===
                                            modalColumns.length
                                        }
                                        onClick={selectAllNone}
                                    />
                                }
                                label={
                                    selectedCount === modalColumns.length
                                        ? "SELECT NONE"
                                        : "SELECT ALL"
                                }
                            />
                        </div>
                    </div>
                </div>

                <div className="actions">
                    <Button
                        text="CANCEL"
                        color="flat"
                        click={() => setShowColumnSelectModal(false)}
                    />
                    <Button text="SAVE" click={saveColumns} />
                </div>
            </Modal>
        </ColumnSelectModalContainer>
    );
}
