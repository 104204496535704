// Styles
import WarningCountsContainer from "./WarningCounts.styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faExclamationCircle,
    faInfoCircle,
    faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

type Props = {
    info: number;
    warning: number;
    critical: number;
};
export default function WarningCounts({ info, warning, critical }: Props) {
    return (
        <WarningCountsContainer className="warningCounts">
            <div className="count blue">
                <FontAwesomeIcon icon={faInfoCircle} fixedWidth />
                <p>{info}</p>
            </div>
            <div className="count orange">
                <FontAwesomeIcon icon={faExclamationCircle} fixedWidth />
                <p>{warning}</p>
            </div>
            <div className="count red">
                <FontAwesomeIcon icon={faTimesCircle} fixedWidth />
                <p>{critical}</p>
            </div>
        </WarningCountsContainer>
    );
}
