import { useState } from "react";

// Styles
import ReportsContainer from "./Reports.styles";

// Router
import { useHistory } from "react-router-dom";

// Components
import TableCell from "components/Common/TableCell/TableCell";
import SignOff from "../../SignOff/SignOff";
import Table from "components/Common/Table/Table";

// Font Awesome
import { faDownload, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Utils
import * as Database from "utils/database.utils";
import { createLocalDownload } from "utils/export.utils";
import { useSettings } from "context/Settings.context";
import { uppercaseFirstLetter } from "utils/common.utils";
import Button from "components/Common/Button/Button";
import { Tooltip } from "@mui/material";
import { fileNameMap } from "./types";

interface ReportsProps {
    fund: string;
    region: string;
    period?: string;
}

const Reports = ({ fund, region, period }: ReportsProps) => {
    const history = useHistory();
    const { setShowLoadingToast } = useSettings();
    period = period ? period : "latest";
    //example api url for reports change when api implemented
    const reportsApiUrl = `api/fees/reports/${region}/${fund}/${period}`;
    const [reload, setReload] = useState(0);
    const [filterQuery, setFilterQuery] = useState<string>("");

    const handleDownload = async (link: string, filename: string) => {
        setShowLoadingToast({
            show: true,
            message: "DOWNLOADING",
        });

        const res = await Database.get(link);
        const file = await res.blob();
        createLocalDownload(filename, file);

        setShowLoadingToast({
            show: false,
            message: "",
        });
    };

    const formatReportName = (reportName: string) => {
        let reportNameArr = reportName.replace("_monthly", "").split("report");
        let baseDetailsArr = reportNameArr[1].split("_");

        let version = baseDetailsArr[2].replace("V", "Version ");
        let year = baseDetailsArr[1].slice(0, 4);
        let quarter = reportNameArr[1].includes("M")
            ? "M" + baseDetailsArr[1].split("M")[1]
            : " Q" + baseDetailsArr[1].split("Q")[1];
        let name =
            reportNameArr[0] in fileNameMap
                ? fileNameMap[reportNameArr[0]]
                : reportNameArr[0];

        return `${name} ${year}${quarter} ${version}`;
    };

    const columns = [
        {
            id: 1,
            title: "REPORT",
            sortable: false,
            value: "report_id",
            cell: (data: any) => {
                return <TableCell data={formatReportName(data.report_id)} />;
            },
            show: true,
            fullDataRow: true,
        },
        {
            id: 2,
            title: "GENERATED DATE",
            sortable: false,
            value: "report_creation_datetime",
            cell: (data: any) => (
                <TableCell
                    data={new Date(
                        data.report_creation_datetime
                    ).toLocaleDateString()}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 3,
            title: "SIGN OFF DATE",
            sortable: false,
            value: "signed_off_dt",
            cell: (data: any) => (
                <TableCell
                    data={
                        data.signed_off
                            ? new Date(data.signed_off_dt).toLocaleDateString()
                            : "-"
                    }
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 4,
            title: "SIGNED OFF BY",
            sortable: false,
            value: "signed_off_responsible",
            cell: (data: any) => (
                <TableCell data={data.signed_off_responsible} />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 5,
            title: "SIGN OFF",
            sortable: false,
            value: "signed_off",
            cell: (data: any) => (
                <SignOff
                    from="reports"
                    signOffUrl={`api/fees/reports/${region}/${fund}/signoff`}
                    updateTable={() => setReload(reload + 1)}
                    data={data}
                    id={data.report_id}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 6,
            title: "",
            sortable: false,
            value: "",
            cell: (data: any) => (
                <Tooltip title={"DOWNLOAD"}>
                    <div className="downloadIcon">
                        <FontAwesomeIcon
                            icon={faDownload}
                            fixedWidth
                            onClick={() =>
                                handleDownload(
                                    data.download_link,
                                    `${uppercaseFirstLetter(
                                        fund
                                    )} Report Export.csv`
                                )
                            }
                        />
                    </div>
                </Tooltip>
            ),
            show: true,
            fullDataRow: true,
        },
    ];

    return (
        <ReportsContainer>
            <Button
                click={() =>
                    handleDownload(
                        `api/fees/reports/${region}/${fund}/versions/download?period=${period}`,
                        `Reports - ${uppercaseFirstLetter(
                            region
                        )} ${uppercaseFirstLetter(fund)} Versions Export.csv`
                    )
                }
                text={"DOWNLOAD VERSIONS"}
                icon={faDownload}
                className="downloadBtn"
            />
            <div className="tableDiv">
                <Table
                    showHeader
                    columns={columns}
                    url={reportsApiUrl}
                    filterQuery={filterQuery}
                    large
                    emptyText={
                        "Performance Fee and Discrepancy report for the current quarter have not been generated yet"
                    }
                    emptyIcon={faInfoCircle}
                    reload={reload}
                    defaultLimit={10}
                    defaultSortKey="report_type"
                    defaultSortOrder="DESC"
                />
            </div>
        </ReportsContainer>
    );
};
export default Reports;
