import styled from 'styled-components';

export default styled.div`
.no-answer {
    font-size: 0.9rem;
    color: ${(props) => props.theme.subMenuText};
}

.percent {
    span {
        background-color: ${(props) => props.theme.labelSecondaryBg};
        color: ${(props) => props.theme.labelWarningColor};
        padding: 4px 8px;
        border-radius: 5px;
    }
}

.bool {
    padding: 4px 8px;
    border-radius: 5px;
}
.bool-yes {

    background-color: ${(props) => props.theme.labelSuccessBg};
    color: ${(props) => props.theme.labelSuccessColor};
}
.bool-no {
    background-color: ${(props) => props.theme.labelAlertBg};
    color: ${(props) => props.theme.labelAlertColor};
}

.rating {
    span {
        padding: 4px 8px;
        border-radius: 5px;
    }
}
.rating-1 {
    background-color: ${(props) => props.theme.bqmqVoteScore1};
}

.rating-2 {
    background-color: ${(props) => props.theme.bqmqVoteScore2};
}

.rating-3 {
    background-color: ${(props) => props.theme.bqmqVoteScore3};
}
.rating-4{
    background-color: ${(props) => props.theme.bqmqVoteScore4};
}

.rating-5 {
    background-color: ${(props) => props.theme.bqmqVoteScore5};
}
`;
