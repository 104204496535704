import React from "react";

// Styles
import DetailBoxContainer from "./DetailBox.styles";

interface DetailBoxProps {
    title: string;
    data: string | number;
    fullWidth?: boolean;
}

const DetailBox = ({ title, data, fullWidth = false }: DetailBoxProps) => {
    return (
        <DetailBoxContainer>
            {!fullWidth ? (
                <div className="box">
                    <p className="title">{title}</p>
                    <div className="dataContainer">
                        <p className="data">{data}</p>
                    </div>
                </div>
            ) : (
                <div className="fullWidthBox">
                    <p className="title">
                        <div className="textContainer">
                            <p>{data}</p>
                        </div>
                    </p>
                </div>
            )}
        </DetailBoxContainer>
    );
};
export default DetailBox;
