import styled from "styled-components";

export default styled.div`
    min-width: fit-content;
    flex-direction: row;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    position: fixed;
    bottom: 0;
    right: 0;

    z-index: 300;

    background-color: ${(props) => props.theme.notificationButtonBackground};
    color: ${(props) => props.theme.notificationButtonForeground};

    margin: 30px;
    padding: 10px 20px;

    border-radius: 5px;

    p {
        font-size: 20px;
        letter-spacing: 2px;
        margin: 0;
        display: flex;
        flex-grow: 1;
    }

    .text {
        display: flex;
        flex-grow: 1;
        padding-right: 20px;
    }

    .spinner {
        display: flex;
        width: 30px;
        justify-content: center;
        align-items: center;
        svg {
            animation: spin 1s linear infinite;
            font-size: 20px;
        }
    }

    @media screen and (max-width: 900px) {
        left: 0;
        right: unset;
    }
`;
