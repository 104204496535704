// Components
import PageTitle from "components/Common/PageTitle/PageTitle";
import SummarySheet from "./SummarySheet";

// Styles
import SummarySheetListContainer from "./SummarySheetList.styles";
import Tabs from "components/Common/Tabs/Tabs";

const SummarySheetList: React.FC = () => {
    return (
        <SummarySheetListContainer>
            <PageTitle title="SUMMARY SHEETS" />
            <Tabs
                defaultTab="global"
                tabs={[
                    {
                        value: "global",
                        title: "GLOBAL",
                        content: (
                            <SummarySheet
                                region="global"
                                apiUrl="api/summary_sheet"
                            />
                        ),
                    },
                    {
                        value: "asia",
                        title: "ASIA",
                        content: (
                            <SummarySheet
                                region="asia"
                                apiUrl="api/asia_summary_sheet"
                            />
                        ),
                    },
                ]}
            />
        </SummarySheetListContainer>
    );
};

export default SummarySheetList;
