import styled from "styled-components";
import breakpoints from "assets/styles/breakpoints";

export default styled.div`
    .file {
        background-color: transparent;
        border: 1px solid ${(props) => props.theme.secondaryButtonBackground};
        border-radius: 5px;
        padding: 15px 15px 5px 15px;
        text-align: center;
        cursor: pointer;
        margin-right: 10px;
        @media only screen and (${breakpoints.device.md}) {
            margin-top: 10px;
        }
        height: 130px;
        width: 130px;
        transition: background-color 0.2s ease;
        :hover {
            background-color: #004453;
            color: white;
        }
        .filename {
            margin-bottom: 10px;
            width: 100%;
            white-space: wrap;
            overflow: hidden;
        }
    }
`;
