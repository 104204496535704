import { Divider } from "@mui/material";
import React, { useState } from "react";

// Font Awesome
import {
    faSquareCheck,
    faSquare,
    faCheck,
    faX,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Components
import DropDownMenu from "components/Common/DropDownMenu/DropDownMenu";
import DropDownMenuItem from "components/Common/DropDownMenu/DropDownMenuItem/DropDownMenuItem";

// Types
import { DailyFlowValues } from "screens/DailyRecs/Inputs/DailyFlows/types";

// Styles
import SignOffContainer from "./SignOff.styles";

// Utils
import * as Database from "utils/database.utils";
import { useSettings } from "context/Settings.context";
import Button from "components/Common/Button/Button";

interface SignOffProps {
    signOffUrl: string;
    runUpdates: Function;
    values: any;
}

const SignOff = ({ runUpdates, signOffUrl, values }: SignOffProps) => {
    const [anchorEl, setAnchorEl] = useState<any>(null);
    const { setShowLoadingToast, setToast } = useSettings();

    const handleClick = async () => {
        setShowLoadingToast({
            show: true,
            message: "SIGNING OFF",
        });
        let response = await Database.patch(signOffUrl);
        if (response.ok) {
            setShowLoadingToast({
                show: false,
                message: "",
            });
            setToast({
                type: "success",
                show: true,
                message: "Successfully signed off.",
            });
            runUpdates();
        } else {
            setToast({
                type: "error",
                show: true,
                message: "Already signed off by this user.",
            });
        }
        setShowLoadingToast({
            show: false,
            message: "",
        });
    };

    return (
        <SignOffContainer>
            <p className={"inputTitle"}>APPROVALS</p>
            <Divider style={{ marginBottom: "15px" }} />
            <div style={{ marginTop: "20px" }}>
                <div className="containerDiv">
                    <p className="labelText">Sign Off - 1</p>
                    <div className="blankDiv" style={{ marginTop: "0" }}>
                        <p style={{ marginLeft: "10px" }}>
                            {values.signed_off_by_1
                                ? values.signed_off_by_1
                                : ""}
                        </p>
                    </div>
                    <div
                        className="iconDiv"
                        onClick={(e: React.MouseEvent) => {
                            if (!values.signed_off_by_1)
                                setAnchorEl(e.currentTarget);
                        }}
                    >
                        <FontAwesomeIcon
                            className="icon"
                            icon={faSquareCheck}
                            fixedWidth
                        />
                    </div>
                </div>

                <div className="containerDiv">
                    <p className="labelText">Sign Off - 2</p>
                    <div className="blankDiv" style={{ marginTop: "0" }}>
                        <p style={{ marginLeft: "10px" }}>
                            {values.signed_off_by_2
                                ? values.signed_off_by_2
                                : ""}
                        </p>
                    </div>
                    <div
                        className="iconDiv"
                        onClick={(e: React.MouseEvent) => {
                            if (!values.signed_off_by_2)
                                setAnchorEl(e.currentTarget);
                        }}
                    >
                        <FontAwesomeIcon
                            className="icon"
                            icon={faSquareCheck}
                            fixedWidth
                        />
                    </div>
                </div>
            </div>
            <DropDownMenu
                open={Boolean(anchorEl)}
                setOpen={setAnchorEl}
                anchorEl={anchorEl}
            >
                <DropDownMenuItem
                    icon={<FontAwesomeIcon icon={faCheck} fixedWidth />}
                    text={"SIGN OFF"}
                    click={() => {
                        handleClick();
                        setAnchorEl(!anchorEl);
                    }}
                />
                <DropDownMenuItem
                    icon={<FontAwesomeIcon icon={faX} fixedWidth />}
                    text={"CANCEL"}
                    click={() => {
                        setAnchorEl(!anchorEl);
                    }}
                />
            </DropDownMenu>
        </SignOffContainer>
    );
};
export default SignOff;
