import { useState, useEffect } from "react";

// Utils
import * as Database from "utils/database.utils";
import { Chart } from "chart.js";
import { isObjectEmpty } from "utils/general.utils";

// Components
import Select from "components/Common/Select/Select";

// Styles
import FairValueGraphContainer from "./FairValueGraph.styles";
import colors from "assets/styles/colors";
import { useSettings } from "../../../../../context/Settings.context";

type Props = {
    id: number;
    currency: string;
};

const FairValueGraph: React.FC<Props> = ({ id, currency }) => {
    const { fairValueDefault, setShowLoadingToast } = useSettings();

    const [data, setData] = useState<any>();

    const [fairValueChart, setFairValueChart] = useState<any>(null);

    const [dateRange, setDateRange] = useState<
        "fv_yearly" | "fv_monthly" | "fv_weekly" | "fv_daily"
    >(fairValueDefault);

    const dateOptions = [
        {
            label: "Yearly",
            value: "fv_yearly",
        },
        {
            label: "Monthly",
            value: "fv_monthly",
        },
        {
            label: "Weekly",
            value: "fv_weekly",
        },
        {
            label: "Daily",
            value: "fv_daily",
        },
    ];

    const getFairValueData = (index: number) => {
        setShowLoadingToast({ show: true, message: "LOADING" });

        Database.get("api/extractions/fv_chart/" + index).then((res) => {
            if (res.ok) {
                res.json().then((data) => {
                    if (Object.keys(data.data.fv_yearly).length !== 0) {
                        setData(data.data);
                    }
                });
            }
        });
    };

    useEffect(() => {
        if (id) {
            getFairValueData(id);
        }
    }, [id]);

    useEffect(() => {
        if (data) createChart(data, dateRange);
    }, [data, dateRange]);

    const createChart = (
        data: any,
        dateRange: "fv_yearly" | "fv_monthly" | "fv_weekly" | "fv_daily"
    ) => {
        let dates: any[] = [];
        let fairValue: any[] = [];

        const keyDict = {
            fv_yearly: "Year",
            fv_monthly: "Month",
            fv_weekly: "Week",
            fv_daily: "Date",
        };

        Object.keys(data[dateRange][keyDict[dateRange]]).forEach((key) => {
            dates.push(data[dateRange][keyDict[dateRange]][key]);
            fairValue.push(data[dateRange].fair_value_base[key]);
        });

        if (fairValueChart === null) {
            const fvChart = document.getElementById(
                "fairValueChart"
            ) as HTMLCanvasElement;

            let ctx = fvChart.getContext("2d");

            if (ctx) {
                let fValueChart = new Chart(ctx, {
                    type: "line",
                    data: {
                        labels: dates,
                        datasets: [
                            {
                                label: "FV",
                                data: fairValue,
                                backgroundColor: colors.teal100,
                                borderWidth: 1,
                                fill: true,
                                yAxisID: "y",
                            },
                        ],
                    },
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,

                        scales: {
                            y: {
                                type: "linear",
                                ticks: {
                                    stepSize:
                                        Math.ceil(Math.max(...fairValue) / 50) *
                                        10,
                                },
                                title: {
                                    display: true,
                                    text: currency,
                                },
                            },
                            x: {
                                type: "category",
                                ticks: {
                                    autoSkip: true,
                                },
                            },
                        },
                        plugins: {
                            legend: {
                                display: false,
                            },
                        },
                    },
                });

                setFairValueChart(fValueChart);
            }
        } else {
            fairValueChart.data.labels = dates;
            fairValueChart.data.datasets[0].data = fairValue;
            fairValueChart.update();
        }
    };

    return (
        <FairValueGraphContainer>
            {data && !isObjectEmpty(data!.fv_yearly) && (
                <>
                    <label className="">FAIR VALUE</label>
                    <Select
                        filterName="RANGE"
                        options={dateOptions}
                        value={dateRange}
                        setValue={setDateRange}
                    />
                    <div className="graphContainer">
                        <canvas id="fairValueChart"></canvas>
                    </div>
                </>
            )}
        </FairValueGraphContainer>
    );
};

export default FairValueGraph;
