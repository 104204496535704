import { useState, useEffect } from "react";

// Material UI
import { Grid, Tooltip } from "@mui/material";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

// Components
import FairValueGraph from "components/Screens/Data/CompanyModel/FairValueGraph/FairValueGraph";
import PageTitle from "components/Common/PageTitle/PageTitle";
import Tabs from "components/Common/Tabs/Tabs";
import MoreInfo from "components/Screens/Data/CompanyModel/MoreInfo/MoreInfo";
import ModelData from "components/Screens/Data/CompanyModel/ModelData/ModelData";
import Validation from "components/Screens/Data/CompanyModel/Validation/Validation";
import DetailStatus from "components/Screens/Data/CompanyModel/DetailStatus/DetailStatus";
import WarningCounts from "components/Screens/Data/CompanyModel/WarningCounts/WarningCounts";
import Diff from "components/Screens/Data/CompanyModel/Diff/Diff";

// Utils
import { toDateAndTime } from "utils/general.utils";
import * as Database from "utils/database.utils";

// Context
import { useSettings } from "context/Settings.context";

// Styles
import CompanyModelDetailContainer from "./CompanyModelDetail.styles";

const CompanyModelDetail: React.FC = () => {
    const { setShowLoadingToast } = useSettings();

    const initialExtractionMeta = {
        company: "",
        company_extract_group: "",
        company_id: 0,
        company_ref_id: 0,
        team: "",
        sub_team: "",
        currency_differs_flag: 0,
        current_model_flag: 1,
        extraction_datetime: "",
        fixes_id: "",
        folder_path: "",
        id: "",
        include_model_flag: 1,
        last_modified_by: "",
        last_modified_datetime: "",
        listing_currency: "",
        model_deleted_flag: 0,
        model_errors_ignored_flag: 0,
        model_h1_end_date: "",
        model_h1_start_date: "",
        model_h1_year: "",
        model_in_holding_flag: 0,
        model_initial_success_flag: 1,
        model_replaced_flag: 0,
        model_status: "FAI",
        num_errors_critical: 0,
        num_errors_info: 0,
        num_errors_warning: 0,
        processing_datetime: "",
        processing_method: "",
        reporting_currency: "",
        user_id: "",
        user_notes: "",
        archive_folder_path: "",
    };

    const [extractionMetaData, setExtractionMetaData] = useState<any>(
        initialExtractionMeta
    );

    const [loading, setLoading] = useState<boolean>(true);

    const getExtraction = (index: string) => {
        setShowLoadingToast({ show: true, message: "LOADING" });

        Database.get("api/extractions/" + index).then((res) => {
            if (res.ok) {
                res.json().then((data) => {
                    setExtractionMetaData(data.data);

                    setLoading(false);
                });
            }
        });
    };

    const getExtractionID = (): string => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        return urlParams.get("id") ? urlParams.get("id")! : "";
    };

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        getExtraction(urlParams.get("id")!);
    }, []);

    return (
        <CompanyModelDetailContainer>
            <PageTitle
                title={
                    loading ? (
                        "LOADING"
                    ) : (
                        <>
                            {extractionMetaData.company.toUpperCase()}
                            {Boolean(extractionMetaData.current_model_flag) && (
                                <Tooltip title={"CURRENT MODEL"}>
                                    <div>
                                        <FontAwesomeIcon
                                            icon={faCheckCircle}
                                            fixedWidth
                                        />
                                    </div>
                                </Tooltip>
                            )}
                        </>
                    )
                }
                actions={
                    extractionMetaData.model_status &&
                    !loading && (
                        <DetailStatus
                            extractionMetaData={extractionMetaData}
                            reload={() => getExtraction(getExtractionID())}
                        />
                    )
                }
            ></PageTitle>

            <Grid container className="metadata">
                <Grid item xs={6} md={3} className="topGrid">
                    <div className="info">
                        <label>EXTRACTION BATCH</label>
                        <p>
                            {loading
                                ? "LOADING"
                                : toDateAndTime(
                                      extractionMetaData.extraction_datetime
                                  )}
                        </p>
                    </div>
                    <div className="info">
                        <label>TEAM</label>
                        <p className="capitalize">
                            {loading
                                ? "LOADING"
                                : extractionMetaData.sub_team.toLowerCase()}
                        </p>
                    </div>
                    <div className="info">
                        <label>LAST MODIFIED BY</label>
                        <p>
                            {loading
                                ? "LOADING"
                                : extractionMetaData.last_modified_by}
                        </p>
                    </div>
                </Grid>
                <Grid item xs={6} md={3} className="topGrid">
                    <div className="info">
                        <label>LAST MODIFIED ON</label>
                        <p>
                            {loading
                                ? "LOADING"
                                : toDateAndTime(
                                      extractionMetaData.last_modified_datetime
                                  )}
                        </p>
                    </div>
                    <div className="info">
                        <label>PROCESSED ON</label>
                        <p>
                            {loading
                                ? "LOADING"
                                : toDateAndTime(
                                      extractionMetaData.processing_datetime
                                  )}
                        </p>
                    </div>
                    <div className="info">
                        <label>PROCESSING METHOD</label>
                        <p className="capitalize">
                            {loading
                                ? "LOADING"
                                : extractionMetaData.processing_method.toLowerCase()}
                        </p>
                    </div>
                </Grid>

                <Grid item xs={12} md={6}>
                    <div className="info">
                        <FairValueGraph
                            id={extractionMetaData.company_id}
                            currency={extractionMetaData.reporting_currency}
                        />
                    </div>
                </Grid>
            </Grid>

            <Tabs
                defaultTab="data"
                dontChangeUrl
                tabs={[
                    {
                        value: "data",
                        title: "DATA",
                        content: <ModelData id={getExtractionID()} />,
                    },

                    {
                        value: "more-info",
                        title: "MORE INFO",
                        content: (
                            <MoreInfo extractionMetaData={extractionMetaData} />
                        ),
                    },
                    {
                        value: "diff",
                        title: "DIFF",
                        content: (
                            <Diff
                                id={getExtractionID()}
                                companyID={extractionMetaData.company_id}
                            />
                        ),
                    },
                    {
                        value: "validation",
                        title: "VALIDATION",
                        icon: (
                            <WarningCounts
                                info={extractionMetaData.num_errors_info}
                                warning={extractionMetaData.num_errors_warning}
                                critical={
                                    extractionMetaData.num_errors_critical
                                }
                            />
                        ),
                        content: <Validation id={getExtractionID()} />,
                    },
                ]}
            />
        </CompanyModelDetailContainer>
    );
};

export default CompanyModelDetail;
