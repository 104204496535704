import React, { useEffect, useState } from "react";

// Context
import { useSettings } from "context/Settings.context";

// Styles
import DealFilterContainer from "./DealFilter.styles";

// Components
import TextField from "../../../Common/TextField/TextField";
import Select from "../../../Common/Select/Select";
import ButtonSmall from "../../../Common/ButtonSmall/ButtonSmall";

// FontAwesome
import { faSearch } from "@fortawesome/free-solid-svg-icons";

// Interface
import {DealFilters} from "assets/interfaces/growth_voting";



interface Props {
    filters: DealFilters
    setFilters: React.Dispatch<React.SetStateAction<DealFilters>>;
    search: boolean;
    setSearch: React.Dispatch<React.SetStateAction<boolean>>;
}

const DealFilter  = ({filters, setFilters, search, setSearch}: Props) => {
   const setStatusFilter = (status: string) => {
    setFilters({...filters, status: status});
   }

   const setTextFilter = (text: string) => {
    setFilters({...filters, search : text});
   }


    return (
        <DealFilterContainer>
            <div className={"filters"}>
                 <TextField
                        placeholder="Search.."
                        change={setTextFilter}
                        value={filters.search}
                 />
                <Select
                    filterName="Status"
                    value={filters.status}
                    setValue={setStatusFilter}
                    options={["Open", "Closed"].map((status) => {
                        return {
                            value: status.toLowerCase(),
                            label: status,
                        };
                    })}
                />
                <ButtonSmall
                    text="Search"
                    click={() => {
                        setSearch(true);
                    }}
                    icon={faSearch}
                />
            </div>
        </DealFilterContainer>
    );
};

export default DealFilter
