import styled from 'styled-components';

export default styled.div`
    .inputLabel {
        margin-bottom: 10px;
    }

    .textFieldDiv {
    }

    .inputTitle {
        font-size: 18px;
        letter-spacing: 0.1em;

        color: ${(props) => props.theme.pageTitleText};
        margin-bottom: 5px;
        margin-top: 30px;
    }

    .blankDiv {
        align-items: center;
        max-width: 250px;
        min-width: 250px;
        height: 40px;
        display: flex;

        margin-top: 10px;

        cursor: pointer;

        background-color: ${(props) => props.theme.filterButtonBackground};
        color: ${(props) => props.theme.filterButtonForeground};

        border-radius: 5px;
        overflow: hidden;
    }

    .formatted-value {
        display: flex;
        justify-content: end;
        padding: 3px 0px;
        font-size: 0.88rem;
        color: ${(props) => props.theme.subMenuText};
    }
`;
