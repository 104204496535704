import colors from "assets/styles/colors";

export default {
    mainMenuBackground: "black",
    mainMenuForeground: "white",
    mainMenuActiveForeground: "red",

    subMenuBackground: "#111111",
    subMenuTitleText: "white",
    subMenuText: "white",
    subMenuHighlight: colors.grey30,
    subMenuActive: "red",
    subMenuSeparator: colors.grey80,
    subMenuHandleBorder: "orange",

    subMenuButtonBackground: "orange",
    subMenuButtonForeground: "black",
    subMenuButtonBackgroundHover: "orange",
    subMenuButtonForegroundHover: "white",

    subMenuBadgeBackground: colors.accentRed,
    subMenuBadgeText: "white",
    subMenuBadgeBackgroundSecondary: colors.accentOrange,
    subMenuBadgeTextSecondary: "white",

    mobileMenuButtonBackground: "orange",
    mobileMenuButtonForeground: "black",

    notificationsBackground: "white",
    notificationsHeaderBackground: "red",
    notificationsHeaderText: "black",
    notificationsRowText: "black",
    notificationsRowBackgroundHover: "white",
};
