// 30 Day Usage Summary
export const THIRTY_DAY_IFRAME_SRC = "https://app.powerbi.com/reportEmbed?reportId=ceddeafb-7b58-4a28-bec4-7fcacb98c27f&autoAuth=true&ctid=bf4450a2-bfcb-472d-8c40-1425eafb1a3b&filterPaneEnabled=false";
export const THIRTY_DAY_TITLE = "30 Day Usage Stats";

// 90 Day Dashboard Summary
export const NINETY_DAY_DASHBOARD_IFRAME_SRC = "https://app.powerbi.com/reportEmbed?reportId=750eda9a-3053-41ae-a21c-241908960d1c&autoAuth=true&ctid=bf4450a2-bfcb-472d-8c40-1425eafb1a3b&filterPaneEnabled=false";
export const NINETY_DAY_DASHBOARD_TITLE = "90 Day Dashboard";

// 90 Day User Summary
export const NINETY_DAY_USER_IFRAME_SRC = "https://app.powerbi.com/reportEmbed?reportId=e1daf043-b34b-4e07-b03f-53b69d383840&autoAuth=true&ctid=bf4450a2-bfcb-472d-8c40-1425eafb1a3b&filterPaneEnabled=false";
export const NINETY_DAY_USER_TITLE = "90 Day User";

// 90 Day Consumption Summary
export const NINETY_DAY_CONSUMPTION_IFRAME_SRC = "https://app.powerbi.com/reportEmbed?reportId=7c532d84-a8fe-486b-953e-973cbb22c422&autoAuth=true&ctid=bf4450a2-bfcb-472d-8c40-1425eafb1a3b&filterPaneEnabled=false";
export const NINETY_DAY_CONSUMPTION_TITLE = "90 Day Consumption";