import styled from "styled-components";
import colors from "assets/styles/colors";
import breakpoints from "assets/styles/breakpoints";

interface ContainerProps {
    fileUploadActive?: boolean;
}

export default styled.div<ContainerProps>`
    .container {
        display: flex;
        flex-direction: column;
        position: relative;
        align-items: flex-start;
        @media only screen and (max-width: ${breakpoints.size.md}) {
            align-items: stretch;
        }
        .input-wrapper {
            width: 100%;
        }
    }
    .pageTitle {
        font-size: 30px;
        color: ${(props) => props.theme.pageTitleText};
        letter-spacing: 1.7px;
        margin: 20px 0 10px 0;
        font-weight: 400;
        display: flex;
        flex-direction: row;
        width: 90%;
    }

    .selection {
        margin-top: 20px;
        display: flex;
        flex-direction: row;

        @media only screen and (max-width: ${breakpoints.size.md}) {
            flex-direction: column;
        }

        > * {
            min-width: 200px;
            margin-left: 10px;
        }

        > :last-child {
            margin-left: 20px;
            @media only screen and (max-width: ${breakpoints.size.md}) {
                margin-top: 10px;
                margin-left: 10px;
            }
        }

        .disabled {
            opacity: 0.3;
            cursor: not-allowed;
        }
    }

    .loadingScreen {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
    }

    label {
        letter-spacing: 0.1em;
        color: ${colors.textNavy};
        margin-top: 10px;
        margin-bottom: 20px;
    }

    hr {
        width: calc(100% - 10px);
        outline: none;
        border: none;

        margin: 0 5px;

        border-bottom: 1px solid #efefef;
    }

    .notes-input-wrapper {
        @media only screen and (min-width: ${breakpoints.size.lg}) {
            width: 800px;
        }
        display: flex;
        flex-direction: column;
        opacity: 1;
        transition: opacity 0.3s;
        pointer-events: Default;

        .file-upload-wrapper {
            margin-top: 20px;
            ${(props) =>
                props.fileUploadActive &&
                `
                    opacity: 0.3;
                    pointer-events: None;
                `}
        }
    }

    .file-upload-warning {
        opacity: 1;
        transition: opacity 0.3s;
        margin-left: 10px;
        margin-top: 10px;
        ${(props) =>
            !props.fileUploadActive &&
            `
            opacity: 0;
            `}
    }
`;
