import { useState, useEffect } from "react";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

// Components
import Table from "components/Common/Table/Table";
import SearchOptions from "components/Common/SearchOptions/SearchOptions";
import TableCell from "components/Common/TableCell/TableCell";

// Utils
import { to4DecimalPlaces } from "utils/general.utils";
import { getAllCompanies } from "utils/companies.utils";

// Styles
import PortfolioLiveDataContainer from "./PortfolioLiveData.styles";

type Props = {
    id: string;
    apiUrl: string;
};

export default function PortfolioLiveData({ id, apiUrl }: Props) {
    const [filterQuery, setFilterQuery] = useState("");

    const [companyOptions, setCompanyOptions] = useState([]);

    const getCompanies = () => {
        let queryString = filterQuery.includes("&filter_gim_subteam=")
            ? "&filter_gim_subteam=" +
              filterQuery.split("&filter_gim_subteam=")[1].split("&")[0]
            : "";

        let pageQuery = "?page=1&pageSize=1000&sortKey=company&sortOrder=ASC";

        getAllCompanies(pageQuery, queryString).then((res) => {
            if (res.ok) {
                res.json().then((data) => {
                    setCompanyOptions(
                        data.data.map((comp: any) => ({
                            label: comp.company,
                            value: comp.company,
                        }))
                    );
                });
            }
        });
    };

    useEffect(() => {
        getCompanies();
    }, []);

    const columns = [
        {
            id: 1,
            title: "CELL REF",
            sortable: true,
            value: "cell_ref",
            cell: (data: string) => <TableCell data={data} />,
            show: true,
        },

        {
            id: 2,
            title: "WS",
            sortable: true,
            value: "ws",
            cell: (data: string) => <TableCell data={data} />,
            show: true,
        },
        {
            id: 3,
            title: "COMPANY",
            sortable: true,
            value: "company",
            cell: (data: string) => <TableCell data={data} />,
            show: true,
        },

        {
            id: 4,
            title: "METRIC",
            sortable: true,
            value: "metric_name",
            cell: (data: string) => <TableCell data={data} />,
            show: true,
        },
        {
            id: 5,
            title: "VALUE",
            sortable: true,
            value: "cell_value",
            cell: (data: string) => <p>{to4DecimalPlaces(data)}</p>,
            show: true,
        },
        {
            id: 6,
            title: "FORMULA",
            sortable: false,
            value: "cell_formula",
            cell: (data: string) => <p className="formula">{data}</p>,
            show: true,
        },
        {
            id: 7,
            title: "HIDDEN FLAG",
            sortable: true,
            value: "hidden_flag",
            cell: (data: number) =>
                data === 1 && (
                    <FontAwesomeIcon
                        className="checkIcon"
                        icon={faCheckCircle}
                        fixedWidth
                    />
                ),
            show: true,
        },
    ];

    const filterOptions = [
        {
            id: 1,
            name: "WS",
            value: "ws",
            type: "DROPDOWN",
            options: [
                {
                    label: "P&L SHEET",
                    value: "PANDL SHEET",
                },
                {
                    label: "P&L SORT NEW",
                    value: "PANDL SORT NEW",
                },
            ],
        },
        {
            id: 2,
            name: "Company",
            value: "company",
            type: "DROPDOWN",
            searchable: true,
            options: companyOptions,
        },
        {
            id: 3,
            name: "Search CELL or METRIC",
            value: "search",
            type: "SEARCH",
        },
        {
            id: 4,
            name: "Hidden",
            value: "hidden_flag",
            type: "DROPDOWN",
            options: [
                {
                    label: "Only Hidden",
                    value: "1",
                },
                {
                    label: "Only Visable",
                    value: "0",
                },
            ],
        },
    ];

    return (
        <PortfolioLiveDataContainer>
            <SearchOptions
                setFilterQuery={setFilterQuery}
                options={filterOptions}
            />

            <Table
                url={`${apiUrl}values/${id}`}
                columns={columns}
                filterQuery={filterQuery}
                defaultSortKey="cell_ref"
                defaultSortOrder="ASC"
                pagination
            />
        </PortfolioLiveDataContainer>
    );
}
