import styled from "styled-components";

export default styled.div`
    display: flex;
    flex-direction: column;

    margin-bottom: 20px;

    .heading {
        display: flex;
        justify-content: center;
        align-items: center;

        min-height: 50px;
        max-height: 50px;

        border-radius: 5px 5px 0 0;

        background-color: ${(props) => props.theme.homeListTitleRowBackground};
        color: ${(props) => props.theme.homeListTitleRowText};

        font-size: 20px;
    }

    .userRow {
        display: flex;
        flex-direction: row;

        min-height: 50px;
        max-height: 50px;

        min-width: 100%;

        border-right: 1px solid
            ${(props) => props.theme.homeListBodyBorderColor};
        border-left: 1px solid ${(props) => props.theme.homeListBodyBorderColor};
        border-bottom: 1px solid
            ${(props) => props.theme.homeListBodyBorderColor};

        .icon {
            display: flex;
            justify-content: center;
            align-items: center;

            min-height: 50px;
            max-height: 50px;
            min-width: 50px;
            max-width: 50px;

            color: ${(props) => props.theme.homeListTitleRowBackground};

            &.core {
                color: ${(props) =>
                    props.theme.subMenuBadgeBackgroundSecondary};
            }
        }

        .name {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            flex-grow: 1;
            min-height: 50px;
            max-height: 50px;

            color: ${(props) => props.theme.subMenuForeground};

            .coreTag {
                display: flex;
                margin-left: 10px;

                padding: 3px 10px;
                border-radius: 5px;

                color: white;
                background-color: ${(props) =>
                    props.theme.subMenuBadgeBackgroundSecondary};
            }
        }

        &.empty {
            justify-content: center;
            align-items: center;
        }

        :last-child {
            border-radius: 0 0 5px 5px;
        }
    }
`;
