import colors from "assets/styles/colors";

export default {
    tableHeadCellBackground: "white",
    tableHeadCellText: "black",
    tableHeadCellSortIcon: "black",
    tableHeadCellSortIconHover: "red",

    tableBodyText: "white",

    tableHeadCellSortActiveBackground: colors.grey80,
    tableHeadCellSortActiveText: "black",
    tableHeadCellSortActiveIcon: colors.accentOrange,

    tableDisabledRowBackground: colors.grey20,
    tableDisabledRowText: colors.grey50,

    tableSkeletonBackground: colors.grey10,

    tableSelectedRowBackground: colors.grey20,
    tableSelectedRowText: colors.grey20,

    tableRowCheckbox: "white",
    tableRowCheckboxSelected: "red",

    tableIconMain: "white",
    tableIconHighlight: "red",

    selectedOptionsBackground: "white",
    selectedOptionsText: "black",
    selectedOptionsIcon: "black",
    selectedOptionsIconHover: "red",
};
