import styled from 'styled-components';

export default styled.div`
    .title {
        text-align: center;
        font-size: 20px;
        padding-bottom: 10px;
        padding-left: 20px;
        color: ${(props) => props.theme.subMenuTitleText};

    }
    .company {
        display: flex;
        background-color : ${(props) => props.theme.settingsMenuHeaderButtonBackgroundHover};
        padding: 20px;
        * > .searchBox {
            min-width: 100%;
            max-width: 100%;
        }
    }
    .form {
        padding: 20px;
    }
`
