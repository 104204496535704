import styled from "styled-components";

export default styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;

    .titleRow {
        display: flex;
        flex-direction: row;

        border-bottom: 1px solid #aaa;
    }

    .question {
        display: flex;
        justify-content: space-between;
        align-items: center;

        min-width: 220px;
        max-width: 220px;

        padding-left: 10px;
        padding-right: 5px;
        border-right: 1px solid #aaa;

        min-height: 50px;
        max-height: 50px;

        svg {
            color: #999;
        }
    }

    .answerColumn {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1 1;

        min-height: 50px;
        max-height: 50px;

        border-left: 1px solid #efefef;
    }

    .questionRow {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        min-height: 50px;
        max-height: 50px;

        border-radius: 5px;

        border-top: 1px solid #efefef;

        cursor: pointer;

        &.index-0 {
            font-weight: bolder;
            background-color: rgba(0, 68, 83, 0.05);
        }

        :hover {
            background-color: ${(props) =>
                props.theme.homeListBodyBackgroundHover};
        }
    }

    .usersVotes {
        display: flex;
        flex-direction: column;

        border-top: 1px solid #efefef;

        padding: 10px 20px;

        margin: 0 5px 20px;

        .userRow {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            width: 100%;

            .user-details-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                min-width: 350px;
                max-width: 350px;
                justify-content: space-between;
            }

            border-top: 1px solid #efefef;
            padding: 5px;

            .users-vote {
                max-width: 100px;
                min-width: 100px;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            .user-details {
                display: flex;
                flex-direction: row;
            }
        }
    }

    .date {
        color: lightgray;
    }
`;
