import { useEffect } from "react";

// Components
import MainMenu from "./MainMenu/MainMenu";
import SubMenu from "./SubMenu/SubMenu";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";

// Utils
import { useSettings } from "context/Settings.context";
import { hideBasedOnRoutes } from "utils/common.utils";

// Styles
import MenuContainer from "./Menu.styles";

export default function Menu() {
    const { windowWidth, menuMode, setMenuMode, setSubMenuMode } =
        useSettings();

    const hide = hideBasedOnRoutes(["/home", "/portfolio-live"]);

    useEffect(() => {
        if (windowWidth > 600 && menuMode === "open") setMenuMode("closed");
    }, [windowWidth, menuMode]);

    return (
        <MenuContainer
            className={
                "menu " + (windowWidth < 600 ? "mobile " : "") + menuMode
            }
        >
            <div
                className={"menuBackground"}
                onClick={() => setMenuMode("closed")}
            ></div>

            <MainMenu />
            {!hide && <SubMenu />}

            <div
                className={"mobileMenuBtn"}
                onClick={() => {
                    setMenuMode(menuMode === "open" ? "closed" : "open");
                    setSubMenuMode("open");
                }}
            >
                <FontAwesomeIcon
                    icon={menuMode === "open" ? faTimes : faBars}
                    fixedWidth
                />
            </div>
        </MenuContainer>
    );
}
