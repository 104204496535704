// Styles
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useBQMQ } from "context/BQMQ.context";
import { useEffect } from "react";
import LoadingSpinner from "../../../Common/LoadingSpinner/LoadingSpinner";
import HomeListContainer from "./HomeList.styles";
type Props = {
    data: any[];
    row: any;
    title: any;
    count?: number;
    countColor: string;
    seeAll?: Function;
    maxRows?: number | false;
    loading?: boolean;
};

export default function HomeList({
    data,
    row,
    title,
    count,
    countColor,
    seeAll,
    maxRows,
    loading,
}: Props) {
    const { bqmqLoading } = useBQMQ();
    if (!loading) {
        loading = bqmqLoading;
    }
    useEffect(() => {
        loading = bqmqLoading;
    }, [bqmqLoading]);

    return (
        <HomeListContainer>
            <div className={"titleRow"}>
                <div className={"titleText"}>{title}</div>
                {
                    <div className={"count " + countColor}>
                        {loading && !count ? <LoadingSpinner noText /> : count}
                    </div>
                }
            </div>

            <div className={"listBody"}>
                {(maxRows ? data.slice(0, maxRows) : data).map(
                    (item, index) => (
                        <div
                            className={"listRow"}
                            key={`${item.tableID || item.id}${index}`}
                        >
                            {row(item, index)}
                        </div>
                    )
                )}
            </div>

            {maxRows && seeAll && data.length > maxRows && (
                <div className={"seeAll"} onClick={() => seeAll()}>
                    SEE ALL <FontAwesomeIcon icon={faArrowRight} fixedWidth />
                </div>
            )}
        </HomeListContainer>
    );
}
