import TextField from "components/Common/TextField/TextField";
import React, { useState } from "react";

// Styles
import FuturesContainer from "./Futures.styles";
import Tabs from "components/Common/Tabs/Tabs";
import InputTab from "components/Screens/DailyRecs/Futures/InputTab/InputTab";

interface FuturesProps {}

const Futures = ({}: FuturesProps) => {
    return (
        <FuturesContainer>
            <p data-cy="navs-main-title" className={"sectionTitle"}>
                FUTURES
            </p>
            <Tabs
                defaultTab="input"
                tabs={[
                    {
                        value: "input",
                        title: "INPUT",
                        permissions: ["p_can_view_navs", "p_can_action_navs"],
                        content: <InputTab />,
                    },
                ]}
            />
        </FuturesContainer>
    );
};
export default Futures;
