import React, { useState } from "react";

// Font Awesome
import {
    faEllipsisH,
    faDownload,
    faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Components
import DropDownMenu from "components/Common/DropDownMenu/DropDownMenu";
import DropDownMenuItem from "components/Common/DropDownMenu/DropDownMenuItem/DropDownMenuItem";

// Styles
import ActionsContainer from "./Actions.styles";

interface ActionsProps {
    data: any;
    reloadData?: Function;
    actions: JSX.Element[];
}

const Actions = ({ actions, data }: ActionsProps) => {
    const [anchorEl, setAnchorEl] = useState<any>(null);

    return (
        <ActionsContainer>
            <FontAwesomeIcon
                icon={faEllipsisH}
                fixedWidth
                onClick={(e: React.MouseEvent) => setAnchorEl(e.currentTarget)}
            />

            <DropDownMenu
                open={Boolean(anchorEl)}
                setOpen={setAnchorEl}
                anchorEl={anchorEl}
            >
                {actions.map((action, index) =>
                    React.cloneElement(action, { key: index })
                )}
            </DropDownMenu>
        </ActionsContainer>
    );
};
export default Actions;
