// MaterialUI
import { FormControlLabel, Switch } from "@mui/material";

// Components
import Select from "../../Common/Select/Select";

// Context
import { useSettings } from "context/Settings.context";

// Data
import { fairValueDateOptions, themeOptions } from "assets/data/lists";

// Styles
import SettingsContainer from "./Settings.styles";
import Can from "../../Common/Can/Can";

export default function Settings() {
    const {
        theme,
        fullScreen,
        stickyHeaders,
        fairValueDefault,
        setTheme,
        setStickyHeaders,
        setFullScreen,
        setFairValueDefault,
    } = useSettings();

    const handleChangeTheme = (value: string) => {
        localStorage.setItem("settings.theme", String(value));
        setTheme(value);
    };

    const handleChangeStickyTableHeaders = (value: boolean) => {
        localStorage.setItem("settings.sticky-table-headers", String(value));
        setStickyHeaders(value);
    };

    const handleChangeFullScreen = (value: boolean) => {
        localStorage.setItem("settings.full-screen", String(value));
        setFullScreen(value);
    };

    const handleChangeFairValueDefault = (value: string) => {
        localStorage.setItem("settings.fair-value-defualt", value);
        setFairValueDefault(value);
    };

    return (
        <SettingsContainer>
            <div className="settingGroup">
                <h3>DISPLAY</h3>
                <Can
                    yes={
                        <>
                            <label>Theme</label>
                            <Select
                                filterName="THEME"
                                options={themeOptions}
                                value={theme}
                                setValue={handleChangeTheme}
                            />
                        </>
                    }
                    permissions={["p_theme_can_change_in_settings"]}
                />

                {/* Option to enable full screen switch - update useEffect in App.tsx
                <div className="switch">
                    <FormControlLabel
                        control={
                            <Switch
                                checked={Boolean(fullScreen)}
                                onChange={() =>
                                    handleChangeFullScreen(!fullScreen)
                                }
                            />
                        }
                        label="Full Screen"
                    />
                </div> */}
                <div className="switch">
                    <FormControlLabel
                        control={
                            <Switch
                                checked={Boolean(stickyHeaders)}
                                onChange={() =>
                                    handleChangeStickyTableHeaders(
                                        !stickyHeaders
                                    )
                                }
                            />
                        }
                        label="Sticky Headers"
                    />
                </div>

                <hr />

                <Can
                    yes={
                        <div className="settingGroup">
                            <h3 className="topTitle">FAIR VALUE CHART</h3>

                            <label>Default Range</label>
                            <Select
                                filterName="RANGE"
                                options={fairValueDateOptions}
                                value={fairValueDefault}
                                setValue={handleChangeFairValueDefault}
                            />
                        </div>
                    }
                    permissions={["p_list_extractions"]}
                />
            </div>
        </SettingsContainer>
    );
}
