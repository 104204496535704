import styled from "styled-components";

export default styled.div`
    .sectionTitle {
        font-size: 25px;
        letter-spacing: 0.1em;

        color: ${(props) => props.theme.pageTitleText};
        margin-bottom: 10px;
        margin-top: 20px;
    }

    .columns {
        display: flex;
        flex-direction: row;

        min-width: 100%;

        margin: 0 -10px;

        .half {
            display: flex;
            flex-direction: column;

            min-width: 50%;
            max-width: 50%;

            padding: 0 10px;
        }
    }

    .MuiTableHead-root {
        display: none;
    }

    .info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-grow: 1;

        padding: 15px 15px;

        .name {
            display: flex;
            flex-direction: row;
            align-items: center;

            font-weight: bolder;
            letter-spacing: 0.1em;
            font-size: 16px;
            color: ${(props) => props.theme.homeListRowHeader};

            .lockedIcon {
                display: flex;

                font-size: 12px;
                margin-bottom: 4px;
                margin-left: 5px;
            }

            .activeBadge {
                display: flex;
                font-size: 10px;
                background-color: ${(props) =>
                    props.theme.accentButtonBackground};
                color: white;
                padding: 3px 5px;
                margin-left: 10px;
                margin-bottom: 3px;

                border-radius: 5px;
            }

            &.active {
                color: ${(props) => props.theme.accentButtonBackground};
            }
        }

        .details {
            display: flex;
            flex-direction: row;
            margin: 0 -5px;

            .detail {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin: 0 5px;

                p {
                    color: ${(props) => props.theme.homeListRowText};
                    font-size: 14px;
                }

                svg {
                    color: ${(props) => props.theme.homeListRowText};
                    font-size: 10px;
                    margin-right: 4px;
                    margin-bottom: 2px;
                }
            }
        }
    }

    @media screen and (max-width: 1000px) {
        .columns {
            display: flex;
            flex-direction: column;
            .half {
                min-width: 100%;
                max-width: 100%;
            }
        }
    }
`;
