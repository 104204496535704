import styled from "styled-components";

export default styled.div`
.page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}
.title {
    font-size: 25px;
    letter-spacing: 0.1em;

    color: ${(props) => props.theme.pageTitleText};
    margin-bottom: 10px;
    margin-top: 20px;
}
.total {
    margin-top: 15px;
    //text-align: right;
    color: ${(props) => props.theme.pageTitleText};
}
.filters {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    & > * {
        margin: 0px;
        margin-right: 10px;
    }

    .left {
        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
}
.pagination {
    padding: 20px;
    border-top: 1px solid ${(props) => props.theme.pageTitleText};
    display: flex;
    justify-content: center;
}
.no-content {
    display: flex;
    margin-top: 20px;
    border-top: 1px solid ${(props) => props.theme.pageTitleText};
    justify-content: center;
    padding: 20px;
}
`;
