// FontAwesome
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Styles
import ReviewUserListContainer from "./ReviewUserList.styles";

type Props = {
    users: any;
};

export default function ReviewUserList({ users }: Props) {
    const coreUsers = users.filter((user: any) => user.core);
    const nonCoreUsers = users.filter((user: any) => user.invited);

    return (
        <>
            <ReviewUserListContainer>
                <div className={"heading"}>
                    <p>CORE ATTENDEES</p>
                </div>

                {coreUsers.length > 0 ? (
                    coreUsers.map((item: any, index: number) => (
                        <div className={"userRow"} key={item.id}>
                            <div className={"icon core"}>
                                <FontAwesomeIcon icon={faUser} fixedWidth />
                            </div>

                            <div className={"name"}>
                                {item.name}
                                <div className={"coreTag hideSM"}>CORE</div>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className={"userRow empty"}>
                        <p className={""}>NO CORE ATTENDEES</p>
                    </div>
                )}
            </ReviewUserListContainer>

            <ReviewUserListContainer>
                <div className={"heading"}>
                    <p>ATTENDEES</p>
                </div>

                {nonCoreUsers.length > 0 ? (
                    nonCoreUsers.map((item: any, index: number) => (
                        <div className={"userRow"} key={item.id}>
                            <div className={"icon"}>
                                <FontAwesomeIcon icon={faUser} fixedWidth />
                            </div>

                            <p className={"name"}>{item.name}</p>
                        </div>
                    ))
                ) : (
                    <div className={"userRow empty"}>
                        <p className={""}>NO ATTENDEES</p>
                    </div>
                )}
            </ReviewUserListContainer>
        </>
    );
}
