import styled from 'styled-components';

export default styled.div`
    .fundTitle {
        font-size: 25px;
        letter-spacing: 0.1em;

        color: ${(props) => props.theme.pageTitleText};
        margin-bottom: 10px;
        margin-top: 20px;
    }
    
`;