// Styles
import { memo } from "react";
import TableCellContainer from "./TableCell.styles";

type Props = {
    data: string | number | boolean;
    styleTypes?: string;
};

export default memo(function TableCell({ data, styleTypes = "" }: Props) {
    return (
        <TableCellContainer>
            <p className={styleTypes}>{data}</p>
        </TableCellContainer>
    );
});
