import styled from "styled-components";
import colors from "assets/styles/colors";

export default styled.div`
    display: flex;
    flex-direction: column;

    margin-bottom: 20px;

    .heading {
        display: flex;
        justify-content: center;
        align-items: center;

        position: relative;

        min-height: 50px;
        max-height: 50px;

        border-radius: 5px 5px 0 0;

        padding-left: 15px;

        background-color: #004453;
        color: white;

        font-size: 20px;

        .newCompanyBtn {
            display: flex;
            justify-content: center;
            align-items: center;

            position: absolute;

            left: 0;

            min-height: 50px;
            max-height: 50px;
            min-width: 50px;
            max-width: 50px;

            cursor: pointer;
        }
    }

    .sessionRow {
        display: flex;
        flex-direction: row;

        min-height: 50px;
        max-height: 50px;

        min-width: 100%;

        border: 1px solid #eeeeee;

        .icon {
            display: flex;
            justify-content: center;
            align-items: center;

            min-height: 50px;
            max-height: 50px;
            min-width: 50px;
            max-width: 50px;

            color: #004453;
        }

        .name {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            flex-grow: 1;
            min-height: 50px;
            max-height: 50px;
            margin-left: 10px;
        }

        .team {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            margin-right: 20px;

            min-height: 50px;
            max-height: 50px;

            color: #004453;
        }

        .seeAll {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            margin-right: 20px;

            min-height: 50px;
            max-height: 50px;
            min-width: 100%;

            color: #004453;
        }

        :hover {
            cursor: pointer;
            background-color: #eeeeee;
        }
    }

    .searchRow {
        display: flex;
        flex-direction: row;

        justify-content: space-between;
        align-items: center;

        min-height: 50px;
        max-height: 50px;
        min-width: 100%;

        border: 1px solid #dddddd;

        .searchBox {
            display: flex;
            justify-content: center;
            align-items: center;

            border-radius: 0 5px 5px 0;
            border: none;
            outline: none;
            padding: 10px;

            font-size: 18px;

            flex-grow: 1;
        }
        .searchIcon {
            display: flex;
            justify-content: center;
            align-items: center;

            min-width: 50px;
            max-width: 50px;

            color: #004453;
        }
    }

    .loadingRow {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        min-height: 100px;
        max-height: 100px;
        min-width: 100%;
    }

    .newCompanyText {
        display: flex;
    }

    .newCompanyContainer {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        .createBtn {
            margin-top: 10px;
        }
    }

    .newCompanySpan {
        color: ${colors.textNavy};
        font-weight: bold;
        font-size: 18px;
        margin-top: 10px;
        margin-bottom: 20px;
    }

    hr {
        width: calc(40% - 10px);
        outline: none;
        border: none;

        margin: 0 5px;

        border-bottom: 1px solid #efefef;
    }

`;
