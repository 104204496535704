import styled from "styled-components";

export default styled.div`
    display: flex;
    flex-direction: row;

    margin: 0 -10px 10px -10px;

    @media screen and (max-width: 1300px) {
        flex-wrap: wrap;
    }

    @media screen and (max-width: 900px) {
        flex-wrap: nowrap;
        overflow-x: scroll;
    }
`;
