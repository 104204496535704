import { useEffect, useState } from "react";

// Components

// Utils
import * as Database from "utils/database.utils";

// Styles
import ConfigurationsTabContainer from "./ConfigurationsTab.styles";

// Types
import { configurationsInputFields, ConfigurationsInputValues } from "./types";

// Material UI
import { Divider, Grid } from "@mui/material";

// Components
import TextField from "components/Common/TextField/TextField";
import SignOff from "../../Common/SignOff/SignOff";
import { useSettings } from "context/Settings.context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import Button from "components/Common/Button/Button";
import UpdateConfigurationsModal from "./UpdateConfigurationsModal/UpdateConfigurationsModal";
import ConfigurationsInputFields from "./ConfigurationsInputFields/ConfigurationsInputFields";

interface ConfigurationsTabProps {
    noSignOff?: boolean;
    configurationsUrl?: string;
}

const ConfigurationsTab = ({
    noSignOff = false,
    configurationsUrl,
}: ConfigurationsTabProps) => {
    const { setShowLoadingToast, setToast } = useSettings();

    const fetchUrl = !noSignOff
        ? "api/nav/user_inputs/configurations/"
        : "api/nav/user_inputs/configurations/?signed_off=true";

    const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
    const [hasEdited, setHasEdited] = useState<boolean>(false);
    const [noData, setNoData] = useState<boolean>(false);

    const [configurationsValues, setConfigurationsValues] =
        useState<ConfigurationsInputValues>({
            //configurations
            blended_management_fee_dlw_asia: 0,
            blended_management_fee_dlw_gbl_equity: 0,
            blended_management_fee_dbn_asia: 0,
            blended_management_fee_dbn_gbl_equity: 0,
            dbn_asia_admin_accrual_allocation_percent: 0,
            dbn_asia_custody_accrual_allocation_percent: 0,
            dbn_gbl_equity_admin_accrual_allocation_percent: 0,
            dbn_gbl_equity_custody_accrual_allocation_percent: 0,
            duration_forwards_broker_exposure_calculation_exclusion: 0,
            esh2_index_factor: 0,
            euro_forwards_tolerance: 0,
            grand_total: 0,
            min_annual_fee: 0,
            ndf_tolerance: 0,
            vgh2_index_factor: 0,
            //dublin fee sheet
            gav_global_dublin_equity_fund: 0,
            gav_asia_dublin_fund: 0,
            percent_band_0_200mm: 0,
            percent_band_200mm_400mm: 0,
            percent_band_400mm_1bn: 0,
            percent_band_1bn_2bn: 0,
            percent_band_2bn_7bn: 0,
            percent_band_7bn_infinity: 0,

            signed_off_by_1: null,
            signed_off_dt_1: null,
            signed_off_by_2: null,
            signed_off_dt_2: null,
            version: 0,
            id: null,
        });
    const [newConfigurationsValues, setNewConfigurationsValues] =
        useState<any>(configurationsValues);

    const getData = async () => {
        try {
            setShowLoadingToast({
                show: true,
                message: "LOADING",
            });
            setNoData(false);
            let response = await Database.get(fetchUrl);
            if (!response.ok) {
                setNoData(true);
            }
            if (response.ok) {
                let data = await response.json();
                setConfigurationsValues(data);
                setNewConfigurationsValues(data);
            }
        } catch (err) {
            setShowLoadingToast({
                show: false,
                message: "",
            });
        }
        setShowLoadingToast({
            show: false,
            message: "",
        });
    };

    const handleChange = (value: number | string, name: string) => {
        setNewConfigurationsValues({
            ...newConfigurationsValues,
            [name]: value,
        });
    };

    const handleUpdate = async () => {
        setShowLoadingToast({
            show: true,
            message: "UPDATING",
        });
        for (const [key, value] of Object.entries(newConfigurationsValues)) {
            if (typeof value === "string")
                newConfigurationsValues[
                    key as keyof ConfigurationsInputValues
                ] = parseFloat(value);
        }

        //remove keys that should not be passed to post obj
        //by use of destructuring
        const {
            id,
            signed_off_dt_1,
            signed_off_dt_2,
            signed_off_by_1,
            signed_off_by_2,
            version,
            ...filteredPostObj
        } = newConfigurationsValues;

        let response = await Database.post(
            "api/nav/user_inputs/configurations/",
            filteredPostObj
        );
        if (response.ok) {
            setToast({
                show: true,
                type: "success",
                message: "Update configurations data.",
            });
            getData();
        }
        setShowLoadingToast({
            show: false,
            message: "",
        });
    };

    //check to see wether user has edited the values
    useEffect(() => {
        if (
            JSON.stringify(newConfigurationsValues) !==
            JSON.stringify(configurationsValues)
        ) {
            setHasEdited(true);
        } else if (
            JSON.stringify(newConfigurationsValues) ===
            JSON.stringify(configurationsValues)
        ) {
            setHasEdited(false);
        }
    }, [newConfigurationsValues]);

    useEffect(() => {
        getData();
    }, []);

    return (
        <ConfigurationsTabContainer>
            {showUpdateModal && (
                <UpdateConfigurationsModal
                    setShowModal={setShowUpdateModal}
                    runUpdate={getData}
                    configurationsValues={configurationsValues}
                />
            )}
            {noData ? (
                <div className="noData">
                    <FontAwesomeIcon icon={faTimesCircle} fixedWidth />
                    <p>No configuration data found</p>
                </div>
            ) : (
                <div className="inputContainerDiv">
                    {!noSignOff && (
                        <div className="topActionDiv">
                            <Button
                                className="saveButton"
                                color={hasEdited ? "primary" : "flat"}
                                disabled={!hasEdited}
                                text="SAVE INPUTS"
                                click={() => handleUpdate()}
                            />
                        </div>
                    )}
                    <ConfigurationsInputFields
                        disabled={noSignOff}
                        handleChange={handleChange}
                        newConfigurationsValues={newConfigurationsValues}
                    />
                    {!noSignOff && (
                        <SignOff
                            runUpdates={getData}
                            signOffUrl={`api/nav/user_inputs/configurations/signoff`}
                            values={configurationsValues}
                        />
                    )}
                </div>
            )}
        </ConfigurationsTabContainer>
    );
};
export default ConfigurationsTab;
