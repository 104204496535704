import { useState } from "react";

// FontAwesome
import { faDatabase, faTable } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Components
import Table from "components/Common/Table/Table";
import TableCell from "components/Common/TableCell/TableCell";

// Data
import { databaseSchema } from "assets/data/databaseSchema/root";

// Styles
import DatabaseSchemaContainer from "./DatabaseSchema.styles";

export default function DatabaseSchema() {
    const [selectedData, setSelectedData] = useState<any>(databaseSchema);
    const [selectedDatabase, setSelectedDatabase] = useState<any>(false);
    const [selectedTable, setSelectedTable] = useState<any>(false);

    const handleClick = (option: any) => {
        if (option.type === "database") {
            setSelectedData(option.tables);
            setSelectedDatabase(option);
            setSelectedTable(false);
        } else if (option.type === "table") {
            setSelectedData(option.fields);
            setSelectedTable(option);
        } else if (option === "all") {
            setSelectedData(databaseSchema);
            setSelectedDatabase(false);
            setSelectedTable(false);
        }
    };

    const columns = [
        {
            id: 1,
            title: "FIELD",
            sortable: false,
            value: "field",
            cell: (data: string) => <TableCell data={data} />,
            show: true,
        },
        {
            id: 2,
            title: "TYPE",
            sortable: false,
            value: "type",
            cell: (data: string) => <TableCell data={data} />,
            show: true,
        },
        {
            id: 3,
            title: "PRIMARY KEY",
            sortable: false,
            value: "primaryKey",
            cell: (data: string) => <TableCell data={data} />,
            show: true,
        },
        {
            id: 4,
            title: "NULLABLE",
            sortable: false,
            value: "nullable",
            cell: (data: boolean) => <TableCell data={data} />,
            show: true,
        },
    ];

    return (
        <DatabaseSchemaContainer>
            <div className="breadcrumbsRow">
                <div
                    className={
                        "breadcrumb " +
                        (!selectedDatabase && !selectedTable ? "active" : "")
                    }
                    onClick={() => handleClick("all")}
                >
                    <p>ALL</p>
                </div>
                {selectedDatabase && (
                    <div
                        className={
                            "breadcrumb " +
                            (selectedDatabase && !selectedTable ? "active" : "")
                        }
                        onClick={() => handleClick(selectedDatabase)}
                    >
                        <p>{selectedDatabase.text}</p>
                    </div>
                )}
                {selectedTable && (
                    <div
                        className={
                            "breadcrumb " +
                            (selectedDatabase && selectedTable ? "active" : "")
                        }
                    >
                        <p>{selectedTable.text}</p>
                    </div>
                )}
            </div>

            {!selectedTable && (
                <div className={"optionWrapper " + selectedData[0].type}>
                    {selectedData.map((option: any, index: number) => (
                        <div
                            className="option"
                            onClick={() => handleClick(option)}
                            key={index}
                        >
                            {option.type === "database" ? (
                                <FontAwesomeIcon icon={faDatabase} fixedWidth />
                            ) : (
                                <FontAwesomeIcon icon={faTable} fixedWidth />
                            )}
                            <p>{option.text}</p>
                        </div>
                    ))}
                </div>
            )}

            {selectedTable && (
                <div className="optionWrapper">
                    <Table
                        columns={columns}
                        data={selectedData}
                        filterQuery={""}
                    />
                </div>
            )}
        </DatabaseSchemaContainer>
    );
}
