import styled from 'styled-components';

export default styled.div`
    .team {
        border: 1px solid ${(props) => props.theme.votingCellBorder};
        border-radius: 5px;
        padding: 10px;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
    }
    .team-name {
        padding: 0px 10px;
        font-weight: bold;
        min-width: 100px;
    }
    .team-members {
        display: flex;
        flex-wrap: wrap;
        .member {
            font-size: 0.95rem;
            padding: 2px 4px;
            border: 1px solid ${(props) => props.theme.votingCellBorder};
            border-radius: 5px;
            margin-right: 5px;
            margin-bottom: 5px;
        }
    }
`;
