import styled from "styled-components";

export default styled.div`
    padding-bottom: 80px;
    .sectionTitle {
        color: #004453;
        text-align: center;
        font-size: 25px;
        margin-top: 20px;
    }

    .sectionHint {
        text-align: center;
        margin-bottom: 20px;
    }

    hr {
        border: none;
        border-bottom: 1px solid #efefef;
        width: 100%;
        margin: 20px 0 10px 0;
    }

    .actionRow {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 10px;
        padding: 20px;
        background: white;
        position: fixed;
        bottom: 0;
        right: 0;
        width: fit-content;

        border: 1px solid ${(props) => props.theme.primaryButtonBackground};

        border-radius: 5px;

        .message {
            color: ${(props) => props.theme.pageTitleText};
            font-style: italic;
            padding: 5px;

            &.hidden {
                opacity: 0;
            }
        }

        .save-prompt {
            margin-top: 10px;
            color: ${(props) => props.theme.primaryButtonBackground};
        }
    }
`;
