import styled from "styled-components";
import colors from "assets/styles/colors";

export default styled.div`
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-top: 24px;
        margin-bottom: 16px;
        font-weight: 600;
        line-height: 1.25;
    }

    h1 {
        padding-bottom: 0.3em;
        font-size: 2em;
        border-bottom: 1px solid #ddd;
        color: ${(props) => props.theme.pageTitleText};
    }

    h2 {
        padding-bottom: 0.3em;
        font-size: 1.5em;
        border-bottom: 1px solid #ddd;
        font-weight: bolder;
        margin-top: 40px;
        color: ${(props) => props.theme.pageTitleText};
    }

    h3 {
        margin-bottom: 10px;
        font-size: 18px;
        color: black;
        letter-spacing: 1.2px;
        font-weight: bold;
    }

    p {
        margin-top: 0;
        margin-bottom: 25px;
        line-height: 30px;
        font-size: 18px;
        color: #333;
    }

    a {
        color: ${colors.accentOrange};
        cursor: pointer;
        font-weight: bolder;
    }

    hr {
        margin: 50px 0;
        height: 1px;
        background-color: ${colors.teal60};
        border: none;
    }

    blockquote {
        padding: 10px 1em;
        color: #555;
        border-left: 0.25em solid #ddd;
        margin-top: 0;
        margin-bottom: 20px;
        margin-left: 20px;
        font-style: italic;

        p {
            margin: 0;
        }
    }

    pre {
        border-radius: 10px;

        code {
            font-size: 20px !important;
        }
    }

    code {
        background-color: #efefef;
        padding: 5px 8px;
        border-radius: 5px;
    }
`;
