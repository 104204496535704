import { resultIsJson } from "utils/common.utils";
import * as Database from "utils/database.utils";

export const exportFromS3 = (id, key) => {
    return Database.post("api/model_actions/export", {
        extraction_id: id,
    })
        .then((response) => response.blob())
        .then((blob) => URL.createObjectURL(blob))
        .then((url) => {
            let downloadLink = document.createElement("a");
            downloadLink.href = url;
            downloadLink.download = key.split("/")[key.split("/").length - 1];

            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            URL.revokeObjectURL(url);
            return "success";
        });
};

export const exportCSV = (id, key) => {
    const pageQuery = `?pageSize=10000&page=1&sortKey=metric&sortOrder=ASC`;

    return Database.get("api/extractions/model-metrics/" + id + pageQuery).then(
        (res) => {
            if (res.ok) {
                res.json().then((data) => {
                    let object = data.data;
                    object.unshift(headers);
                    let csv = convertToCSV(object);

                    downloadFile(csv, key);
                });
            }
        }
    );
};

const headers = {
    id: "id",
    company: "company",
    excel_table: "excel_table",
    extraction_datetime: "extraction_datetime",
    ws: "ws",
    metric: "metric",
    cell_ref: "cell_ref",
    cell_value: "cell_value",
    cell_formula: "cell_formula",
    hidden_flag: "hidden_flag",
};

export const downloadFile = (csv, key) => {
    var exportedFilenmae = key
        .split("/")
        [key.split("/").length - 1].replace(".xlsm", ".csv");

    var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
        var link = document.createElement("a");
        if (link.download !== undefined) {
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilenmae);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
};

const convertToCSV = (objArray) => {
    var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    var str = "";

    for (var i = 0; i < array.length; i++) {
        var line = "";
        for (var index in array[i]) {
            if (line !== "") line += ",";

            let newCell = array[i][index];

            if (index === "cell_formula") {
                newCell = newCell.replaceAll('"', "'");
            }

            line += '"' + newCell + '"';
        }

        str += line + "\r\n";
    }
    return str;
};

export const createLocalDownload = (fileName, file) => {
    const a = window.document.createElement("a");
    a.href = window.URL.createObjectURL(file);
    const now = new Date().toISOString().slice(0, 10);
    a.download = `${now} - ${fileName}`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};

export const exportSessionBQMQData = async (
    fileName,
    body,
    setShowLoadingToast,
    setToast
) => {
    const url = "api/data_extraction/bq_mq_voting/session";

    const exportBody = { session_id: body.session_id };

    setShowLoadingToast({
        show: true,
        message: "EXPORTING",
    });

    const result = await Database.post(url, exportBody);
    const isJson = await resultIsJson(result);

    if (isJson) {
        const data = await result.json();
        setToast({
            show: true,
            type: "error",
            message: data.message,
        });
    } else {
        const file = await result.blob();
        createLocalDownload(fileName, file);
    }

    setShowLoadingToast({
        show: false,
        message: "",
    });
};

export const exportCompanyBQMQData = async (
    fileName,
    body,
    setShowLoadingToast,
    setToast
) => {
    const url = "api/data_extraction/bq_mq_voting/company";

    const exportBody = { company_id: body.company_id };

    setShowLoadingToast({
        show: true,
        message: "EXPORTING",
    });

    const result = await Database.post(url, exportBody);
    const isJson = await resultIsJson(result);

    if (isJson) {
        const data = await result.json();
        setToast({
            show: true,
            type: "error",
            message: data.message,
        });
    } else {
        const file = await result.blob();
        createLocalDownload(fileName, file);
    }

    setShowLoadingToast({
        show: false,
        message: "",
    });
};

export const exportAllBQMQData = async (
    fileName,
    body,
    setShowLoadingToast,
    setToast
) => {
    const url = "api/data_extraction/bq_mq_voting";

    setShowLoadingToast({
        show: true,
        message: "EXPORTING",
    });

    const result = await Database.post(url, body);
    const isJson = await resultIsJson(result);

    if (isJson) {
        const data = await result.json();
        setToast({
            show: true,
            type: "error",
            message: data.message,
        });
    } else {
        const file = await result.blob();
        createLocalDownload(fileName, file);
    }

    setShowLoadingToast({
        show: false,
        message: "",
    });
};
