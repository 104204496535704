import styled from "styled-components";

export default styled.div`
    justify-content: flex-end;
    padding-right: 10px;
    display: flex;

    svg {
        margin: 0 5px;
        font-size: 20px;
        color: ${(props) => props.theme.primaryButtonBackground};
        cursor: pointer;
    }
`;
