
import React, { useEffect, useState } from "react";

// Styles
import EditCommentModalContainer from "./EditCommentModal.styles";

// Interface
import {GrowthVoteComment} from "assets/interfaces/growth_voting";

// Components
import Button from "components/Common/Button/Button";
import ButtonSmall from "components/Common/ButtonSmall/ButtonSmall";
import Modal from "components/Common/Modal/Modal";
import TextArea from "components/Common/TextArea/TextArea";

// Service
import { ApiHandler } from "services/api_handler";

// Context
import {useSettings} from "context/Settings.context";


// Fontawsome
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";



interface Props {
    comment: GrowthVoteComment;
    setComment: React.Dispatch<React.SetStateAction<GrowthVoteComment>>;
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    setDirty: React.Dispatch<React.SetStateAction<boolean>>;
}

const URL_DEAL_VOTES = "api/growth_voting/votes";


const EditCommentModal = ({comment, setComment, showModal, setShowModal, setDirty}: Props) => {
    const { setShowLoadingToast, setToast } = useSettings();
    const { loading, setLoading } = useSettings();

    const validComment = () => {
        if (comment.comment === "") {
            setToast({show: true, message: "Please enter a comment", type: "error"});
            return false;
        }

        if (comment.comment.length > 5000) {
            setToast({show: true, message: "Comment is too long", type: "error"});
            return false;
        }

        if (!comment.vote_id) {
            setToast({show: true, message: "Vote ID is missing", type: "error"});
            return false;
        }

        if (!comment.team_member_id) {
            setToast({show: true, message: "Team member ID is missing", type: "error"});
            return false;
        }

        return true;
    }

    const submitComment = async () => {
        if (!validComment()) return;

        let URL = URL_DEAL_VOTES + "/" + comment.vote_id + "/comments";
        const reps = await new ApiHandler(URL, setShowLoadingToast, setToast).post({comment: comment});

        setDirty(true);
        setShowModal(false);
    }

    const deleteComment = async () => {
        if (!comment.id) return;

        let URL = URL_DEAL_VOTES + "/" + comment.vote_id + "/comments/" + comment.id;
        const reps = await new ApiHandler(URL, setShowLoadingToast, setToast).delete();

        setDirty(true);
        setShowModal(false);
    }

    const updateComment = (c: string) => {
        setComment({...comment, comment: c})
    }

    return (
        <EditCommentModalContainer>
             <Modal loading={loading} width={"550px"}>
             <div className={"btn-close-container"}>
                    <ButtonSmall
                        color={"flat"}
                        text={"Close"}
                        icon={faClose}
                        click={() => {setShowModal(!showModal)}}
                    />
                </div>
                <div className={"section-title"}>Comment</div>
                <div className={"form"}>
                    <TextArea
                        placeholder="Add a comment..."
                        change={updateComment}
                        value={comment.comment}
                        label=""
                        rows={6}
                    />
                </div>
                <div className={"btn-add-container"}>
                    <Button text="SAVE" color="primary"   click={submitComment} />
                    {comment?.id && <Button text="DELETE" color="flat"   click={deleteComment} />}
                </div>
          </Modal>
        </EditCommentModalContainer>
    );
};

export default EditCommentModal;
