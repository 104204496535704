import styled from "styled-components";
import colors from "assets/styles/colors";

export default styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    min-height: 40px;
    max-height: 40px;
    min-width: 40px;
    max-width: 40px;

    border-radius: 5px;

    margin: 0 10px;

    user-select: none;
    cursor: pointer;

    .icon {
        font-size: 22px;
    }

    &.primary {
        background-color: ${(props) => props.theme.primaryButtonBackground};

        .icon {
            color: ${(props) => props.theme.primaryButtonForeground};
        }
        :hover {
            background-color: ${(props) =>
                props.theme.primaryButtonBackgroundHover};
        }
    }

    &.secondary {
        background-color: ${(props) => props.theme.secondaryButtonBackground};

        .icon {
            color: ${(props) => props.theme.secondaryButtonForeground};
        }
        :hover {
            background-color: ${(props) =>
                props.theme.secondaryButtonBackgroundHover};
        }
    }

    &.third {
        background-color: ${(props) => props.theme.thirdButtonBackground};

        .icon {
            color: ${(props) => props.theme.thirdButtonForeground};
        }
        :hover {
            background-color: ${(props) =>
                props.theme.thirdButtonBackgroundHover};
        }
    }

    &.accent {
        background-color: ${(props) => props.theme.accentButtonBackground};

        .icon {
            color: ${(props) => props.theme.accentButtonForeground};
        }
        :hover {
            background-color: ${(props) =>
                props.theme.accentButtonBackgroundHover};
        }
    }

    &.flat {
        background-color: ${(props) => props.theme.flatButtonBackground};

        .icon {
            color: ${(props) => props.theme.flatButtonForeground};
        }
        :hover {
            background-color: ${(props) =>
                props.theme.flatButtonBackgroundHover};
        }
    }

    &.disabled {
        background-color: ${(props) => props.theme.filterButtonBackground};
        cursor: auto;

        .icon {
            color: ${(props) => props.theme.filterButtonForeground};
        }
        :hover {
            background-color: ${(props) => props.theme.filterButtonBackground};
        }
    }
`;
