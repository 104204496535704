import styled from 'styled-components';

export default styled.div`
    display: inline-block;
     .deal-icon {
        margin-right: 5px;
        border-radius: ;
        font-size: 0.9rem;
    }
    .open {
        color: ${(props) => props.theme.labelSuccessBg};
    }
    .closed {
        color: ${(props) => props.theme.labelAlertBg};
    }
`;
