// React
import React, { useState, useEffect } from "react";

// Components
import Table from "components/Common/Table/Table";
import SearchOptions from "components/Common/SearchOptions/SearchOptions";
import CheckCell from "../CheckCell/CheckCell";
import Button from "components/Common/Button/Button";

// Types
import { Company, UserCompany, SelectableCompany } from "./Company.types";

// Data
import { enabledOptions, teamOptions } from "assets/data/lists";

// Context
import { useSettings } from "context/Settings.context";

//Utils
import {
    getUserCompanies,
    disableCompany,
    enableCompany,
    parseBool,
    enableAllCompanies,
    resetCompaniesToDefault,
} from "utils/notifications.utlis";
import { getAllCompanies } from "utils/companies.utils";

// Styles
import CompanySettingsContainer from "./CompanySettings.styles";

const CompanySettings: React.FC = () => {
    const { setShowLoadingToast } = useSettings();

    const [loading, setLoading] = useState<boolean>(true);
    const [companyFilterQuery, setCompanyFilterQuery] = useState<any>();
    const [allCompanies, setAllCompanies] = useState<Company[]>([]);
    const [userCompanies, setUserCompanies] = useState<UserCompany[]>([]);
    const [selectableCompanies, setSelectableCompanies] = useState<
        SelectableCompany[] | null
    >();
    const [filteredCompanies, setFilteredCompanies] =
        useState<SelectableCompany[]>();

    const companiesColumns = [
        {
            id: 1,
            title: "COMPANY",
            sortable: false,
            value: "company",
            cell: (company: string) => <p>{company}</p>,
            show: true,
        },
        {
            id: 2,
            title: "Enabled",
            sortable: false,
            value: "data",
            cell: (data: {
                companyRefId: number;
                enabled: boolean;
                userCompanyMapId: string;
            }) => (
                <CheckCell
                    configId={""}
                    updateCompanySelection={updateCompanySelection}
                    companyRefId={data.companyRefId}
                    enabled={data.enabled}
                    userCompanyMapId={data.userCompanyMapId}
                />
            ),
            show: true,
        },
    ];

    const filterCompaniesOptions = [
        { id: 1, name: "Search COMPANY", value: "company", type: "SEARCH" },
        {
            id: 2,
            name: "TEAM",
            value: "gim_subteam",
            type: "DROPDOWN",
            options: teamOptions,
        },
        {
            id: 3,
            name: "STATUS",
            value: "enabled",
            type: "DROPDOWN",
            options: enabledOptions,
        },
    ];

    const getSetCompanies = async (loader: boolean) => {
        setLoading(loader);
        const result = await getAllCompanies();
        const data = await result.json();
        setAllCompanies(data.data);

        const userCompaniesData = await getUserCompanies();
        setUserCompanies(userCompaniesData);
        if (loader) setLoading(false);
    };

    const resetDefaultCompanies = async () => {
        setLoading(true);
        setShowLoadingToast({
            show: true,
            message: "UPDATING CONFIGURATION",
        });

        await resetCompaniesToDefault();
        setShowLoadingToast({
            show: false,
            message: "",
        });

        getSetCompanies(true);
    };

    const filterUserSelectedCompanies = () => {
        const companySettings = allCompanies
            .map((company: Company) => {
                return {
                    id: company.company_ref_id,
                    company: company.company,
                    gim_subteam: company.gim_subteam,
                    data: {
                        companyRefId: company.company_ref_id,
                        enabled: checkForEnabledCompany(company.company_ref_id),
                        userCompanyMapId: getUserCompanyMapId(
                            company.company_ref_id
                        ),
                    },
                };
            })
            .filter((company) => company.company !== "");
        setSelectableCompanies(companySettings);
    };

    const handleDefaultDeviation = async (companyRefId?: number) => {
        const companies = allCompanies
            .filter((company) => company.company_ref_id !== companyRefId)
            .map((company) => {
                return {
                    company_ref_id: company.company_ref_id,
                };
            });
        await enableAllCompanies(companies);
    };

    const updateCompanySelection = async (
        enabled: boolean,
        companyRefId?: number,
        userCompanyMapId?: string
    ) => {
        setShowLoadingToast({
            show: true,
            message: "UPDATING CONFIGURATION",
        });

        userCompanies.length
            ? enabled
                ? await enableCompany(companyRefId)
                : await disableCompany(userCompanyMapId)
            : await handleDefaultDeviation(companyRefId);
        await getSetCompanies(false);
        setShowLoadingToast({
            show: false,
            message: "",
        });
    };

    const checkForEnabledCompany = (companyRefId: number) => {
        if (!userCompanies.length) return true;
        if (!companyRefId) return false;
        const companyRefs = userCompanies.map(
            (company: UserCompany) => company.company_ref_id
        );
        const doesInclude = companyRefs.includes(companyRefId);
        return doesInclude;
    };

    const getUserCompanyMapId = (companyRefId: number) => {
        let id: string = "";
        userCompanies.map((company: UserCompany) => {
            if (company?.company_ref_id === companyRefId) id = company.id;
        });
        return id;
    };

    const filterByCompany = (companies: SelectableCompany[]) => {
        if (!companyFilterQuery.company) return companies;
        return companies.filter((company: SelectableCompany) =>
            company.company
                .toLowerCase()
                .includes(companyFilterQuery.company.toLowerCase())
        );
    };

    const filterByTeam = (companies: SelectableCompany[]) => {
        if (!companyFilterQuery.gim_subteam) return companies;
        return companies.filter((company: SelectableCompany) =>
            company.gim_subteam
                .toLowerCase()
                .includes(companyFilterQuery.gim_subteam.toLowerCase())
        );
    };

    const filterByEnabled = (companies: SelectableCompany[]) => {
        if (!companyFilterQuery.enabled) return companies;
        return companies.filter(
            (company: SelectableCompany) =>
                company.data.enabled === parseBool(companyFilterQuery.enabled)
        );
    };

    useEffect(() => {
        if (selectableCompanies && companyFilterQuery) {
            const filteredByCompany = filterByCompany(selectableCompanies);
            const filteredByTeam = filterByTeam(filteredByCompany);
            const filteredByEnabled = filterByEnabled(filteredByTeam);
            setFilteredCompanies(filteredByEnabled);
        }
    }, [companyFilterQuery]);

    useEffect(() => {
        selectableCompanies && setFilteredCompanies(selectableCompanies);
    }, [selectableCompanies]);

    useEffect(() => {
        filterUserSelectedCompanies();
    }, [userCompanies]);

    useEffect(() => {
        getSetCompanies(true);
    }, []);

    return (
        <CompanySettingsContainer>
            <p className="title">COMPANIES</p>
            <div className="button-container">
                <Button text="RESET TO DEFAULT" click={resetDefaultCompanies} />
            </div>
            <div className="searchBarCompanies">
                <SearchOptions
                    setFilterQuery={setCompanyFilterQuery}
                    options={filterCompaniesOptions}
                    localFilter
                />
            </div>
            <Table
                data={filteredCompanies}
                columns={companiesColumns}
                filterQuery={companyFilterQuery}
                loading={loading}
                pagination={false}
            />
        </CompanySettingsContainer>
    );
};

export default CompanySettings;
