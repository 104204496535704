import colors from "assets/styles/colors";

export default {
    tabsBottomBorder: colors.teal30,
    tabItemText: colors.grey40,

    tabItemBorderActive: colors.textNavy,
    tabItemTextActive: colors.textNavy,

    tabItemBorderHover: colors.teal30,
    tabItemBackgroundHover: colors.teal30,
    tabItemTextHover: colors.grey50,
};
