import React from "react";

// Styles
import SustainabilityContainer from "./Sustainability.styles";

// Font Awesome
import {faClipboardQuestion, faHandshake, faListOl, faTree} from "@fortawesome/free-solid-svg-icons";

// Components
import SubMenuItem from "../../SubMenuItem/SubMenuItem";
import SubMenuTitle from "../../SubMenuTitle/SubMenuTitle";

// React Router
import { useHistory } from "react-router-dom";

interface SustainabilityProps {}

const Sustainability = ({}: SustainabilityProps) => {
    const history = useHistory();
    return (
        <SustainabilityContainer className={"submenu"}>
            <div className={"top"}>
                <h1>SUSTAINABILITY</h1>
                <hr />
                <SubMenuTitle text="CLIMATE" />
                <SubMenuItem
                    text={"GHG"}
                    active={history.location.pathname === "/sustainability/ghg"}
                    icon={faTree}
                    click={() => history.push("/sustainability/ghg")}
                    permissions={["p_bqmq_can_view_results"]}
                />
                <SubMenuItem
                    text={"SBTi"}
                    active={
                        history.location.pathname === "/sustainability/sbti"
                    }
                    icon={faTree}
                    click={() => history.push("/sustainability/sbti")}
                    permissions={["p_bqmq_can_view_results"]}
                />
                <SubMenuItem
                    text={"Climate Levels"}
                    active={
                        history.location.pathname === "/sustainability/climate-levels"
                    }
                    icon={faTree}
                    click={() => history.push("/sustainability/climate-levels")}
                    permissions={["p_bqmq_can_view_results"]}
                />
                <SubMenuTitle text="DIVERSITY" />
                <SubMenuItem
                    text={"Denominator"}
                    active={
                        history.location.pathname ===
                        "/sustainability/denominator"
                    }
                    icon={faHandshake}
                    click={() => history.push("/sustainability/denominator")}
                    permissions={["p_bqmq_can_view_results"]}
                />


                {/*SFDR*/}
                <SubMenuTitle text="SFDR" />
                <SubMenuItem
                    text={"Submissions"}
                    active={
                        history.location.pathname ===
                        "/sustainability/sfdr-data-capture/submissions"
                    }
                    icon={faListOl}
                    click={() => history.push("/sustainability/sfdr-data-capture/submissions")}
                    permissions={[
                        "p_can_submit_sfdr_esg_questionnaire_on_behalf",
                    ]}
                />
                <SubMenuItem
                    text={"Data Capture Form"}
                    active={
                        history.location.pathname === "/sustainability/sfdr-data-capture/form"
                    }
                    icon={faClipboardQuestion}
                    click={() => history.push("/sustainability/sfdr-data-capture/form")}
                    permissions={[
                        "p_can_submit_sfdr_esg_questionnaire_on_behalf",
                        "p_can_submit_sfdr_esg_questionnaire",
                    ]}
                />
                <SubMenuItem
                    text={"SFDR Data"}
                    active={
                        history.location.pathname === "/sustainability/sfdr"
                    }
                    icon={faTree}
                    click={() => history.push("/sustainability/sfdr")}
                    permissions={["p_bqmq_can_view_results"]}
                />
                <SubMenuItem
                    text={"SFDR Assessments"}
                    active={
                        history.location.pathname === "/sustainability/sfdr-assessments"
                    }
                    icon={faTree}
                    click={() => history.push("/sustainability/sfdr-assessments")}
                    permissions={["p_bqmq_can_view_results"]}
                />

            </div>
        </SustainabilityContainer>
    );
};
export default Sustainability;
