import colors from "assets/styles/colors";

export default {
    homeListTitleRowBackground: "white",
    homeListTitleRowText: "black",
    homeListCountBackgroundDefault: "blue",
    homeListCountTextDefault: "white",

    homeListBodyBorderColor: "white",
    homeListBodyBackgroundHover: colors.grey30,

    homeListSeeAllText: "white",

    homeListRowHeader: "white",
    homeListRowText: "white",
};
