// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faPlus } from "@fortawesome/free-solid-svg-icons";

// Utils
import * as Database from "utils/database.utils";

// Context
import { useSettings } from "context/Settings.context";

// Styles
import CheckCellContainer from "./CheckCell.styles";

type Props = {
    selected: boolean;
    group: string;
    permission: string;
    reload: Function;
};

export default function CheckCell({
    selected,
    group,
    permission,
    reload,
}: Props) {
    const { setShowLoadingToast } = useSettings();

    const addAssignemnt = async () => {
        setShowLoadingToast({ show: true, message: "UPDATING" });

        let data = {
            group_id: group,
            permission_id: permission,
        };

        let res = await Database.post("api/permissions/group-assignment", data);

        if (res.ok) {
            reload();
        }
    };

    const deleteAssignemnt = async () => {
        setShowLoadingToast({ show: true, message: "UPDATING" });

        let data = {
            group_id: group,
            permission_id: permission,
        };

        let res = await Database.put("api/permissions/group-assignment", data);

        if (res.ok) {
            reload();
        }
    };

    return (
        <CheckCellContainer
            onClick={selected ? deleteAssignemnt : addAssignemnt}
        >
            {selected ? (
                <FontAwesomeIcon icon={faCheckCircle} fixedWidth />
            ) : (
                <FontAwesomeIcon className="addIcon" icon={faPlus} fixedWidth />
            )}
        </CheckCellContainer>
    );
}
