import styled from "styled-components";
import colors from "assets/styles/colors";

export default styled.div`
    flex-direction: column;
    display: flex;
    flex-grow: 1;

    .searchBar {
        max-width: 100%;
        min-width: 100%;
        display: flex;
        align-items: center;
        z-index: 10;

        .addNewButton {
            border-radius: 5px;
            background-color: ${colors.teal60};
            height: 40px;
            margin: 10px 0 10px 20px;
            padding: 0 15px;
            justify-content: center;
            align-items: center;
            display: flex;
            cursor: pointer;
            white-space: nowrap;

            p {
                margin: 0;
                font-size: 18px;
            }

            &.new {
                background-color: ${(props) =>
                    props.theme.accentButtonBackground};
                p {
                    color: ${(props) => props.theme.accentButtonForeground};
                }
                :hover {
                    background-color: ${(props) =>
                        props.theme.accentButtonBackgroundHover};
                }
            }

            &.custom {
                background-color: ${(props) =>
                    props.theme.primaryButtonBackground};
                p {
                    color: ${(props) => props.theme.primaryButtonForeground};
                }
                :hover {
                    background-color: ${(props) =>
                        props.theme.primaryButtonBackgroundHover};
                }
            }
        }
    }

    .deleteBtn {
        font-size: 20px;
        color: ${(props) => props.theme.permissionsDelete};
        cursor: pointer;
    }
`;
