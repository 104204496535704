import { useHistory } from "react-router-dom";

// FontAwesome
import {
    faAlignJustify,
    faBell,
    faBook,
    faBriefcase,
    faChartLine,
    faChartPie,
    faClipboardCheck,
    faClock,
    faCut,
    faDatabase,
    faHome,
    faPlus,
} from "@fortawesome/free-solid-svg-icons";

// Components
import SubMenuItem from "../../SubMenuItem/SubMenuItem";
import SubMenuTitle from "../../SubMenuTitle/SubMenuTitle";
import Can from "components/Common/Can/Can";

// Context
import { useNotifications } from "context/Notifications.context";

// Styles
import ExtractionsContainer from "./Data.styles";

export default function Data() {
    const history = useHistory();

    const {
        unknownCompanies,
        newStockSplits,
        failedCompanyExtractions,
        holdingCompanyExtractions,
    } = useNotifications();

    return (
        <ExtractionsContainer className={"submenu"}>
            <div className={"top"}>
                <h1>DATA</h1>
                <hr />
                <SubMenuItem
                    text={"Home"}
                    active={history.location.pathname === "/data"}
                    icon={faHome}
                    click={() => history.push("/data")}
                    permissions={["p_list_extractions"]}
                />

                <SubMenuTitle
                    text={"EXTRACTIONS"}
                    permissions={["p_list_extractions", "p_gbl_dashboard"]}
                />

                <SubMenuItem
                    text={"Company Models"}
                    active={
                        history.location.pathname === "/data/company-models"
                    }
                    icon={faBriefcase}
                    click={() => history.push("/data/company-models")}
                    permissions={["p_list_extractions"]}
                    count={failedCompanyExtractions}
                    secondaryCount={holdingCompanyExtractions}
                />
                <SubMenuItem
                    text={"Summary Sheets"}
                    active={
                        history.location.pathname === "/data/summary-sheets"
                    }
                    icon={faChartLine}
                    click={() => history.push("/data/summary-sheets")}
                    permissions={["p_gbl_dashboard"]}
                />
                <SubMenuItem
                    text={"Portfolio Live"}
                    active={
                        history.location.pathname ===
                        "/data/portfolio-live-list"
                    }
                    icon={faChartPie}
                    click={() => history.push("/data/portfolio-live-list")}
                    permissions={["p_gbl_dashboard"]}
                />

                <SubMenuTitle
                    text={"COMPANIES"}
                    permissions={["p_update_companies"]}
                />

                <SubMenuItem
                    text={"Company List"}
                    active={history.location.pathname === "/data/company-list"}
                    icon={faAlignJustify}
                    click={() => history.push("/data/company-list")}
                    permissions={["p_update_companies"]}
                />
                <SubMenuItem
                    text={"New Companies"}
                    active={history.location.pathname === "/data/new-companies"}
                    icon={faPlus}
                    click={() => history.push("/data/new-companies")}
                    permissions={["p_update_companies"]}
                    count={unknownCompanies}
                />
                <SubMenuItem
                    text={"Stock Splits"}
                    active={history.location.pathname === "/data/stock-splits"}
                    icon={faCut}
                    click={() => history.push("/data/stock-splits")}
                    permissions={["p_update_companies"]}
                    count={newStockSplits}
                />

                {/* UNCOMMENT WHEN BUY SELL ALERTS ARE READY FOR RELEASE */}
                {/* <SubMenuItem
                    text={"Buy & Sell Alerts"}
                    active={
                        history.location.pathname === "/data/buy-sell-alerts"
                    }
                    icon={faBell}
                    click={() => history.push("/data/buy-sell-alerts")}
                    permissions={["p_update_companies"]}
                /> */}
            </div>

            <div className={"bottom"}>
                <SubMenuTitle
                    text={"REFERENCE"}
                    permissions={["p_view_dictionary"]}
                />
                <SubMenuItem
                    text={"Data Dictionary"}
                    active={
                        history.location.pathname === "/data/data-dictionary"
                    }
                    icon={faBook}
                    click={() => history.push("/data/data-dictionary")}
                    permissions={["p_view_dictionary"]}
                />
                <SubMenuItem
                    text={"Database Schema"}
                    active={
                        history.location.pathname === "/data/database-schema"
                    }
                    icon={faDatabase}
                    click={() => history.push("/data/database-schema")}
                    permissions={["p_view_dictionary"]}
                />
                <SubMenuItem
                    text={"Validation Rules"}
                    active={
                        history.location.pathname === "/data/validation-rules"
                    }
                    icon={faClipboardCheck}
                    click={() => history.push("/data/validation-rules")}
                    permissions={["p_update_validation"]}
                />

                <Can yes={<hr />} permissions={["p_list_extractions"]} />
                <SubMenuItem
                    text={"Analytics Queue"}
                    active={
                        history.location.pathname === "/data/analytics-queue"
                    }
                    icon={faClock}
                    click={() => history.push("/data/analytics-queue")}
                    permissions={["p_list_extractions"]}
                />
            </div>
        </ExtractionsContainer>
    );
}
