import styled from "styled-components";
import colors from "assets/styles/colors";


export default styled.div`
#getFile {
        display: none;
    }



.form {
    position: relative;

    .uploadBox {
        display: flex;
        padding: 50px 0;
        user-select: none;
        margin-top: 10px;
        border-radius: 5px;
        background-color: ${colors.teal30};
        border: 2px dashed ${colors.teal100};
        cursor: pointer;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        color: #777777;
        svg {
            font-size: 50px;
        }
        p {
            margin: 15px 0 0 0;
            font-size: 20px;
            letter-spacing: 2px;
        }
        .filenameExample {
            font-size: 14px;
        }
        :hover {
            background-color: ${colors.teal80};
            border: 2px solid #777777;
            color: #444444;
        }
    
        &.true {
            border: 2px solid lightcoral;
        }
    }
    .remove-container {
        width: fit-content;
        position: absolute;
        right: 0;
        bottom: -60px;
        color: ${(props) => props.theme.accentButtonBackground};
        cursor: pointer;
        padding: 10px 20px;
        &:hover {
            padding: 10px 20px;
            border-radius: 10px;
            background-color: ${(props) => props.theme.accentButtonBackground};
            color: white;
        }
    }
}
`;
