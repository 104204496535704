export const setWithExpiry = (key: string, ttl: number, companyId: string) => {
    const now = new Date();
    const item = {
        expiry: now.getTime() + ttl,
        companyId: companyId,
    };

    localStorage.setItem(key, JSON.stringify(item));
};

export const getWithExpiry = (key: string, companyId: string) => {
    const itemString = localStorage.getItem(key);

    if (!itemString) return false;

    const item = JSON.parse(itemString);

    if (item.companyId !== companyId) return false;

    const now = new Date();
    if (now.getTime() > item.expiry) {
        localStorage.removeItem(key);
    }
    return true;
};
