import React, {useEffect, useState} from "react";
import { Pagination } from '@mui/material';

// Styles
import GrowthCompaniesContainer from "./GrowthCompanies.styles";

// Utils
import { ApiHandler } from "services/api_handler";


// Interfaces
import { FullDealDetails, GrowthCompany} from "assets/interfaces/growth_voting";

//Components
import ButtonSmall from "components/Common/ButtonSmall/ButtonSmall";
import TextField from "components/Common/TextField/TextField";
import EditCompanyModel from "components/Screens/GrowthVoting/EditCompanyModal/EditCompanyModal"
import LoadingSpinner from "components/Common/LoadingSpinner/LoadingSpinner";

// Context
import {useSettings} from "context/Settings.context";

// FontAwesome
import { faSearch, faPlus } from "@fortawesome/free-solid-svg-icons";
import CompanyWithDealList from "components/Screens/GrowthVoting/CompanyWithDealList/CompanyWithDealList";


const URL_COMPANIES_LIST = "api/growth_voting/companies-with-deals";

const DEFAULT_PAGE_SIZE = 10;

const GrowthCompanies = () => {
    const { setShowLoadingToast, setToast } = useSettings();
    const [companies, setCompanies] = useState<GrowthCompany[]>([]);
    const [total, setTotal] = useState<number>(0); // totoal number of companies
    const [pages, setPages] = useState<number>(1); // current page
    const [page, setPage] = useState<number>(1); // current page
    const [searchText, setSearchText] = useState<string>("");
    const [showNewCompany, setShowNewCompany] = useState<boolean>(false);
    const [newCompany, setNewCompany] = useState<GrowthCompany>({name: "", url: ""});
    const [saved, setSaved] = useState<boolean>(false); // listener for the save event
    const [loading, setLoading] = useState<boolean>(false);

    const fetchCompanies = async (page: number = 1) => {
        setLoading(true);
        const params = getParamas(page);

        let response = await new  ApiHandler(URL_COMPANIES_LIST + params, setShowLoadingToast, setToast).list();
        setCompanies(response.data.data);
        setTotal(response.data.total);
        setPages(response.data.pages);
        setPage(response.data.page);
        setLoading(false);
    };

    const getParamas = (page: number = 1) =>  {
        let params = "";

        params += `?page=${page}&page_size=${DEFAULT_PAGE_SIZE}`;

        if (searchText) {
            params += `&search=${searchText}`;
        }

        return params;
    }

    const handleSearch = () => {
        setPage(1);
        fetchCompanies(1);
    }

    const handlePageChange = (page: number) => {
        fetchCompanies(page);
    }

    useEffect(() => {
        fetchCompanies();
        setSaved(false);
    }, [saved]);


    return (
        <GrowthCompaniesContainer>
            {showNewCompany && (
                <EditCompanyModel company={newCompany} setCompany={setNewCompany} setShowModal={setShowNewCompany} setSaved={setSaved} />
            )}
            <div className={"page-header"}>
                 <p data-cy="deals-main-title" className={"title"}>
                    COMPANIES
                </p>
            </div>
            <div className={"filters"}>
                <div className="left">
                    <TextField
                            placeholder="Name or URL"
                            change={setSearchText}
                            value={searchText}
                    />
                    <ButtonSmall
                        text="Search"
                        click={handleSearch}
                        icon={faSearch}
                    />
                </div>
                <div className="right">
                    <ButtonSmall
                        text="Create new company"
                        click={() => setShowNewCompany(true)}
                        icon={faPlus}
                    />
                </div>
            </div>
            {loading && <div className={"loading"}><LoadingSpinner noText /></div>}
            {!loading && companies.length === 0 && <div className={"no-content"}>No companies</div>}
            {!loading && companies.length > 0 && total && <div className={"total"}>Total: {total}</div>}
            <div className={"companies"}>
                <CompanyWithDealList companies={companies} setCompanies={setCompanies}/>
            </div>
            {companies.length > 0 && <div className={"pagination"}>
                <Pagination
                        count={pages}
                        page={page}
                        onChange={(event, page) => {handlePageChange(page)}}
                        showFirstButton={true}
                        showLastButton={true}
                        />
            </div>}
        </GrowthCompaniesContainer>
    );
};
export default GrowthCompanies;
