import { useState } from "react";
import { useHistory } from "react-router-dom";

import { FormControlLabel, Switch } from "@mui/material";

// Components
import Modal from "components/Common/Modal/Modal";
import Button from "components/Common/Button/Button";

// Context
import { useAuth } from "context/Auth.context";

// Styles
import CustomPermissionModalContainer from "./CustomPermissionModal.styles";

type Props = {
    groupIDs: any;
    permissionsList: any[];
    setShowModal: Function;
};

export default function CustomPermissionModal({
    groupIDs,
    permissionsList,
    setShowModal,
}: Props) {
    const { user, setUser } = useAuth();
    const history = useHistory();

    const [groups, setGroups] = useState(
        Object.entries(groupIDs).map(([key, value]) => ({
            id: value,
            name: key,
            selected: false,
        }))
    );

    const toggleGroup = (index: number) => {
        let newGroups = [...groups];
        newGroups[index].selected = !groups[index].selected;
        setGroups(newGroups);
    };

    const setPermissions = () => {
        const newPermissionsList: any[] = ["s_view_permissions"];

        permissionsList.forEach((perm) => {
            groups.forEach((group) => {
                if (group.selected && perm[group.name]) {
                    newPermissionsList.push(perm.permission);
                }
            });
        });

        setUser({ ...user, permissions: newPermissionsList });

        history.push("/");
    };

    return (
        <CustomPermissionModalContainer>
            <Modal>
                <div className={"title"}>
                    <p>SET CUSTOM PERMISSIONS</p>
                </div>

                <div className={"switches"}>
                    {groups.map((group, index) => (
                        <div className="switch">
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={group.selected}
                                        onChange={() => {
                                            toggleGroup(index);
                                        }}
                                    />
                                }
                                label={group.name.toUpperCase()}
                            />
                        </div>
                    ))}
                </div>

                <div className={"actions"}>
                    <Button
                        text={"CANCEL"}
                        click={() => setShowModal(false)}
                        color={"flat"}
                    />
                    <Button text={"SET"} click={() => setPermissions()} />
                </div>
            </Modal>
        </CustomPermissionModalContainer>
    );
}
