import colors from "assets/styles/colors";

export default {
    tableHeadCellBackground: colors.textNavy,
    tableHeadCellText: "white",
    tableHeadCellSortIcon: "white",
    tableHeadCellSortIconHover: colors.grey70,

    tableBodyText: "black",

    tableHeadCellSortActiveBackground: colors.textNavyDark,
    tableHeadCellSortActiveText: "white",
    tableHeadCellSortActiveIcon: colors.accentOrange,

    tableDisabledRowBackground: colors.grey70,
    tableDisabledRowText: colors.grey20,

    tableSkeletonBackground: colors.grey100,

    tableSelectedRowBackground: colors.teal30,
    tableSelectedRowText: colors.grey20,

    tableRowCheckbox: colors.textNavy,
    tableRowCheckboxSelected: colors.textNavy,

    tableIconMain: colors.accentBlue,
    tableIconHighlight: colors.darkAccentBlue,

    selectedOptionsBackground: colors.textNavy,
    selectedOptionsText: "white",
    selectedOptionsIcon: colors.teal100,
    selectedOptionsIconHover: "white",
};
