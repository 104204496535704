import colors from "assets/styles/colors";

export default {
    bqmqVoteScore1: "#A4BD9B",
    bqmqVoteScore2: "#E1EEDB",
    bqmqVoteScore3: "#FFF2CC",
    bqmqVoteScore4: "#FCE4D7",
    bqmqVoteScore5: "#F9CCAD",

    chartAxesColor: "#aaaaaa",
    chartGridColor: "#dddddd",
    chartLabelColor: "black",

    dateOutOfRange: "#efefef",

    votingCellBorder: "#dddddd",
    votingCellText: "#ddd",
    votingCellCommentText: "#333333",
    votingCellBackground: "rgba(250, 250, 250, 1)",
};
