import { useEffect, useState } from "react";

// Styles
import LatestContainer from "./Latest.styles";

// Components
import TableCell from "components/Common/TableCell/TableCell";
import SignOff from "../../SignOff/SignOff";
import Table from "components/Common/Table/Table";
import Button from "components/Common/Button/Button";

// Font Awesome
import {
    faArrowRightFromFile,
    faDownload,
    faRefresh,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Material UI
import { Tooltip } from "@mui/material";

// Context
import { useSettings } from "context/Settings.context";

// Utils
import * as Database from "utils/database.utils";
import { createLocalDownload } from "utils/export.utils";
import { useHistory } from "react-router-dom";
import Can from "components/Common/Can/Can";
import { uppercaseFirstLetter } from "utils/common.utils";
import { fileNameMap, fileNameMapType } from "./types";

interface LatestProps {
    fund: string;
    region: string;
    period: string;
}

const Latest = ({ fund, region, period }: LatestProps) => {
    const { setShowLoadingToast, setToast } = useSettings();
    const history = useHistory();

    const [reload, setReload] = useState(0);
    const [filterQuery, setFilterQuery] = useState<string>("");

    const [canGenerateReports, setCanGenerateReports] =
        useState<boolean>(false);
    const [canGenerateMonthlies, setCanGenerateMonthlies] =
        useState<boolean>(false);

    const latestApiUrl = `api/fees/data_sources/${region}/${fund}/${period}`;
    const generateRedemptionsApiUrl = `api/fees/generation/sac/redemptions?period=${period}`;
    const generateReportsApiUrl = `api/fees/generation/${region}/${fund}/reports?period=${period}`;
    const generateMonthliesApiUrl = `api/fees/generation/${region}/${fund}/monthly?period=${period}`;

    //checks if its possible to generate reports/redemptions
    //checks reports if dlw/dbn and redemptions if sac
    //executed on page render
    const checkIfAllReportsSignedOff = async () => {
        let res = await Database.get(generateReportsApiUrl);
        if (res.ok) {
            let data = await res.json();
            if (data.message) setCanGenerateReports(true);
            else setCanGenerateReports(false);
        }
    };

    const checkIfAllMonthliesSignedOff = async () => {
        let res = await Database.get(generateMonthliesApiUrl);
        if (res.ok) {
            let data = await res.json();
            if (data.message) setCanGenerateMonthlies(true);
            else setCanGenerateMonthlies(false);
        }
    };


    const handleUpdates = () => {
        checkIfAllReportsSignedOff();
        if (fund === "dublin") checkIfAllMonthliesSignedOff();
    };

    const handleGenerateReport = async () => {
        setShowLoadingToast({
            message: "GENERATING REPORT",
            show: true,
        });

        let res = await Database.post(generateReportsApiUrl);

        if (res.ok) {
            let data = await res.json();

            setShowLoadingToast({ message: "", show: false });

            setToast({
                type: "success",
                message: `Report generated, redirecting to report page.`,
                show: true,
            });
            setTimeout(() => {
                history.push(`/fees/${fund}?tab=${region}&subTab=reports`);
            }, 1500);
        } else {
            setShowLoadingToast({ message: "", show: false });
            setToast({
                show: true,
                type: "error",
                message: `There was an error generating the report.`,
            });
        }
    };

    const handleGenerateMonthlies = async () => {
        setShowLoadingToast({
            message: "GENERATING MONTHLY REPORT",
            show: true,
        });

        let res = await Database.post(generateMonthliesApiUrl);

        if (res.ok) {
            let data = await res.json();

            setShowLoadingToast({ message: "", show: false });

            setToast({
                type: "success",
                message: `Report generated, redirecting to report page.`,
                show: true,
            });
            setTimeout(() => {
                history.push(`/fees/${fund}?tab=${region}&subTab=reports`);
            }, 1500);
        } else {
            setShowLoadingToast({ message: "", show: false });
            setToast({
                show: true,
                type: "error",
                message: `There was an error generating the report.`,
            });
        }
    };

    const handleDownload = async (url: string, filename: string) => {
        setShowLoadingToast({
            show: true,
            message: "DOWNLOADING",
        });

        const res = await Database.get(url);
        const file = await res.blob();
        createLocalDownload(filename, file);

        setShowLoadingToast({
            show: false,
            message: "",
        });
    };

    useEffect(() => {
        checkIfAllReportsSignedOff();
        if (fund == "dublin") checkIfAllMonthliesSignedOff();
    }, []);

    const columns = [
        {
            id: 1,
            title: "PROVIDER",
            sortable: false,
            value: "provider",
            cell: (data: any) => <TableCell data={data.provider} />,
            show: true,
            fullDataRow: true,
        },
        {
            id: 2,
            title: "FILE",
            sortable: false,
            value: "report",
            cell: (data: any) => (
                <TableCell
                    data={
                        data.report in fileNameMap
                            ? fileNameMap[data.report as keyof fileNameMapType]
                            : data.report
                    }
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 8,
            title: "PERIOD TYPE",
            sortable: false,
            value: "period_type",
            cell: (data: any) => <TableCell data={data.period_type} />,
            show: true,
            fullDataRow: true,
        },
        {
            id: 8,
            title: "STATUS",
            sortable: false,
            value: "status",
            cell: (data: any) => <TableCell data={data.status} />,
            show: true,
            fullDataRow: true,
        },
        {
            id: 3,
            title: "PROVIDED ON",
            sortable: false,
            value: "file_received_date",
            cell: (data: any) => (
                <div
                    className={
                        data.file_received_date ? "provided" : "notProvided"
                    }
                >
                    <p>
                        {!data.file_received_date
                            ? "Not yet provided"
                            : new Date(
                                  data.file_received_date
                              ).toLocaleDateString()}
                    </p>
                </div>
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 4,
            title: "SIGN OFF DATE",
            sortable: false,
            value: "signed_off_dt",
            cell: (data: any) => (
                <TableCell
                    data={
                        data.signed_off_dt
                            ? new Date(data.signed_off_dt).toLocaleDateString()
                            : "-"
                    }
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 5,
            title: "SIGN OFF BY",
            sortable: false,
            value: "signed_off_responsible",
            cell: (data: any) => (
                <TableCell
                    data={
                        data.signed_off_responsible
                            ? data.signed_off_responsible
                            : "-"
                    }
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 6,
            title: "SIGN OFF",
            sortable: false,
            value: "signed_off",
            cell: (data: any) => (
                <div>
                    {data.status === "Received" ? (
                        <Can
                            permissions={["p_can_action_fees"]}
                            yes={
                                <SignOff
                                    from="latest"
                                    signOffUrl={`api/fees/data_sources/${region}/${fund}/latest/signoff`}
                                    updateTable={() => setReload(reload + 1)}
                                    data={data}
                                    id={data.table_id}
                                    handleUpdates={() => handleUpdates()}
                                />
                            }
                        />
                    ) : (
                        <div>-</div>
                    )}
                </div>
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 7,
            title: "",
            sortable: false,
            value: "",
            cell: (data: any) => (
                <Tooltip title={"DOWNLOAD"}>
                    <div className="downloadIcon">
                        <FontAwesomeIcon
                            icon={faDownload}
                            fixedWidth
                            onClick={() =>
                                handleDownload(
                                    `api/fees/data_sources/${region}/${fund}/latest/download?table_id=${data.table_id}`,
                                    `${fund} - Data Source Export.csv`
                                )
                            }
                        />
                    </div>
                </Tooltip>
            ),
            show: true,
            fullDataRow: true,
        },
    ];

    return (
        <LatestContainer>
            {fund == "dublin" && (
                <Button
                    text={"RE-GENERATE MONTHLIES"}
                    disabled={!canGenerateMonthlies}
                    icon={faArrowRightFromFile}
                    className="generateBtn"
                    click={() => handleGenerateMonthlies()}
                />
            )}
            {fund !== "sac" && (
                <Button
                    text={"RE-GENERATE REPORTS"}
                    disabled={!canGenerateReports}
                    icon={faArrowRightFromFile}
                    className="generateBtn"
                    click={() => handleGenerateReport()}
                />
            )}
            <Button
                click={() =>
                    handleDownload(
                        `api/fees/data_sources/${region}/${fund}/versions/download`,
                        `Latest Data Sources - ${uppercaseFirstLetter(
                            region
                        )} ${uppercaseFirstLetter(fund)} Versions Export.csv`
                    )
                }
                text={"DOWNLOAD VERSIONS"}
                icon={faDownload}
                className="generateBtn"
            />
            <div className="tableDiv">
                <Table
                    emptyText={"No Data"}
                    showEmptyIcon={false}
                    showHeader
                    reload={reload}
                    columns={columns}
                    url={latestApiUrl}
                    defaultLimit={10}
                    filterQuery={filterQuery}
                    large
                    defaultSortKey="provider"
                    defaultSortOrder="DESC"
                />
            </div>
        </LatestContainer>
    );
};
export default Latest;
