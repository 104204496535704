import React, { useEffect, useState } from "react";

// Styles
import FeesValidationRulesContainer from "./FeesValidationRules.styles";

// Context
import { useSettings } from "context/Settings.context";

// Components
import EditableCell from "components/Screens/Admin/ValidationRules/EditableCell/EditableCell";
import TableCell from "components/Common/TableCell/TableCell";
import SearchOptions from "components/Common/SearchOptions/SearchOptions";
import Table from "components/Common/Table/Table";

interface FeesValidationRulesProps {}

const FeesValidationRules = ({}: FeesValidationRulesProps) => {
    const { setShowLoadingToast } = useSettings();

    const [filterQuery, setFilterQuery] = useState<string>("");
    const [reload, setReload] = useState(0);
    const [tableIsLoading, setTableIsLoading] = useState<boolean>(false);

    const selectOptions = [
        {
            label: "Info",
            value: "Info",
            color: "info",
        },
        {
            label: "Warning",
            value: "Warning",
            color: "warning",
        },
        {
            label: "Critical",
            value: "Critical",
            color: "critical",
        },
        {
            label: "NULL",
            value: "",
        },
    ];

    const columns = [
        {
            id: 1,
            title: "VALIDATION ID",
            sortable: true,
            value: "validation_id",
            cell: (data: string) => <TableCell data={data} />,
            show: true,
        },
        {
            id: 14,
            title: "CURRENT RULE",
            sortable: true,
            value: "current_rule",
            cell: (data: string) => <TableCell data={data} />,
            show: true,
        },
        {
            id: 2,
            title: "COLUMN ID",
            sortable: true,
            value: "col_id",
            cell: (data: string) => <TableCell data={data} />,
            show: true,
        },
        {
            id: 4,
            title: "SCHEMA NAME",
            sortable: true,
            value: "schema_name",
            cell: (data: any) => (
                <EditableCell
                    apiMethod="patch"
                    rowId={data.validation_id}
                    validateCellValueTypeBy="text"
                    cellValueType="number"
                    cellValue="schema_name"
                    rowIdKey={"validation_id"}
                    initialValue={data.schema_name}
                    type="textField"
                    apiUrl={`api/fees/validation/${data.validation_id}`}
                    setTableIsLoading={setTableIsLoading}
                    updateTable={() => setReload(reload + 1)}
                    tableIsLoading={tableIsLoading}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 5,
            title: "TABLE NAME",
            sortable: true,
            value: "table_name",
            cell: (data: any) => (
                <EditableCell
                    apiMethod="patch"
                    cellValueType={data.validation_id}
                    validateCellValueTypeBy="text"
                    cellValue="table_name"
                    rowId={data.validation_id}
                    rowIdKey="validation_id"
                    initialValue={data.table_name}
                    type="textField"
                    apiUrl={`api/fees/validation/${data.validation_id}`}
                    updateTable={() => setReload(reload + 1)}
                    setTableIsLoading={setTableIsLoading}
                    tableIsLoading={tableIsLoading}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 6,
            title: "COLUMN NAME",
            sortable: true,
            value: "column_name",
            cell: (data: any) => (
                <EditableCell
                    apiMethod="patch"
                    cellValueType={data.validation_id}
                    validateCellValueTypeBy="text"
                    cellValue="column_name"
                    rowId={data.validation_id}
                    rowIdKey="validation_id"
                    initialValue={data.column_name}
                    type="textField"
                    apiUrl={`api/fees/validation/${data.validation_id}`}
                    updateTable={() => setReload(reload + 1)}
                    setTableIsLoading={setTableIsLoading}
                    tableIsLoading={tableIsLoading}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 7,
            title: "DATA TYPE",
            sortable: true,
            value: "data_type",
            cell: (data: any) => <TableCell data={data.data_type} />,
            show: true,
            fullDataRow: true,
        },
        {
            id: 8,
            title: "DATA TYPE SEVERITY",
            sortable: true,
            value: "data_type_severity",
            cell: (data: any) => (
                <EditableCell
                    apiMethod="patch"
                    rowId={data.validation_id}
                    cellValueType={"text"}
                    cellValue="data_type_severity"
                    rowIdKey="validation_id"
                    initialValue={data.data_type_severity}
                    selectOptions={selectOptions}
                    type="selectOptions"
                    apiUrl={`api/fees/validation/${data.validation_id}`}
                    updateTable={() => setReload(reload + 1)}
                    setTableIsLoading={setTableIsLoading}
                    tableIsLoading={tableIsLoading}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 9,
            title: "MISSING VALUES SEVERITY",
            sortable: true,
            value: "missing_values_severity",
            cell: (data: any) => (
                <EditableCell
                    apiMethod="patch"
                    rowId={data.validation_id}
                    cellValueType={"text"}
                    cellValue="missing_values_severity"
                    rowIdKey="validation_id"
                    initialValue={data.missing_values_severity}
                    selectOptions={selectOptions}
                    type="selectOptions"
                    apiUrl={`api/fees/validation/${data.validation_id}`}
                    updateTable={() => setReload(reload + 1)}
                    setTableIsLoading={setTableIsLoading}
                    tableIsLoading={tableIsLoading}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 10,
            title: "MIN VALUE",
            sortable: true,
            value: "min_value",
            cell: (data: any) => (
                <EditableCell
                    apiMethod="patch"
                    rowId={data.validation_id}
                    cellValueType={"number"}
                    cellValue="min_value"
                    rowIdKey="validation_id"
                    initialValue={data.min_value}
                    type="numericalField"
                    apiUrl={`api/fees/validation/${data.validation_id}`}
                    updateTable={() => setReload(reload + 1)}
                    setTableIsLoading={setTableIsLoading}
                    tableIsLoading={tableIsLoading}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 11,
            title: "MIN VALUE SEVERITY",
            sortable: true,
            value: "min_value_severity",
            cell: (data: any) => (
                <EditableCell
                    apiMethod="patch"
                    rowId={data.validation_id}
                    cellValueType={"text"}
                    cellValue="min_value_severity"
                    rowIdKey="validation_id"
                    initialValue={data.min_value_severity}
                    type="selectOptions"
                    selectOptions={selectOptions}
                    apiUrl={`api/fees/validation/${data.validation_id}`}
                    updateTable={() => setReload(reload + 1)}
                    setTableIsLoading={setTableIsLoading}
                    tableIsLoading={tableIsLoading}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 12,
            title: "MAX VALUE",
            sortable: true,
            value: "max_value",
            cell: (data: any) => (
                <EditableCell
                    apiMethod="patch"
                    rowId={data.validation_id}
                    cellValueType={"number"}
                    cellValue="max_value"
                    rowIdKey="validation_id"
                    initialValue={data.max_value}
                    type="numericalField"
                    apiUrl={`api/fees/validation/${data.validation_id}`}
                    updateTable={() => setReload(reload + 1)}
                    setTableIsLoading={setTableIsLoading}
                    tableIsLoading={tableIsLoading}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 13,
            title: "MAX VALUE SEVERITY",
            sortable: true,
            value: "max_value_severity",
            cell: (data: any) => (
                <EditableCell
                    apiMethod="patch"
                    rowId={data.validation_id}
                    cellValueType={"text"}
                    cellValue="max_value_severity"
                    rowIdKey="validation_id"
                    initialValue={data.max_value_severity}
                    type="selectOptions"
                    selectOptions={selectOptions}
                    apiUrl={`api/fees/validation/${data.validation_id}`}
                    updateTable={() => setReload(reload + 1)}
                    setTableIsLoading={setTableIsLoading}
                    tableIsLoading={tableIsLoading}
                />
            ),
            fullDataRow: true,
            show: true,
        },
        {
            id: 14,
            title: "REQUIRED",
            sortable: true,
            value: "required",
            cell: (data: any) => (
                <EditableCell
                    apiMethod="patch"
                    rowId={data.validation_id}
                    validateCellValueTypeBy="text"
                    cellValueType="number"
                    cellValue="required"
                    rowIdKey={"validation_id"}
                    initialValue={data.required}
                    type="numericalField"
                    apiUrl={`api/fees/validation/${data.validation_id}`}
                    setTableIsLoading={setTableIsLoading}
                    updateTable={() => setReload(reload + 1)}
                    tableIsLoading={tableIsLoading}
                />
            ),
            fullDataRow: true,
            show: true,
        },
    ];

    useEffect(() => {
        if (tableIsLoading) {
            setShowLoadingToast({
                show: true,
                message: "UPDATING",
            });
        } else {
            setShowLoadingToast({
                show: false,
                message: "",
            });
        }
    }, [tableIsLoading]);

    const filterOption = [
        {
            id: 1,
            name: "METRIC ID",
            value: "v_metric_id",
            type: "SEARCH",
        },
    ];

    return (
        <FeesValidationRulesContainer>
            <SearchOptions
                setFilterQuery={setFilterQuery}
                options={filterOption}
                forceUpperCase
            />

            <Table
                url="api/fees/validation/"
                columns={columns}
                filterQuery={filterQuery}
                defaultSortKey="validation_id"
                defaultSortOrder="DESC"
                emptyText="NO VALIDATION RULES MATCH YOUR SEARCH"
                reload={reload}
                pagination
                defaultLimit={25}
            />
        </FeesValidationRulesContainer>
    );
};
export default FeesValidationRules;
