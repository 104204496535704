// Utils
import { toDateAndTime } from "utils/general.utils";

// MaterialUI
import { Tooltip } from "@mui/material";

// FontAwesome
import {
    faArrowRight,
    faCalendar,
    faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Components
import Actions from "../../../Companies/StockSplits/Actions/Actions";

// Styles
import StockSplitRowContainer from "./StockSplitRow.styles";

type Props = {
    data: any;
};

export default function StockSplitRow({ data }: Props) {
    return (
        <StockSplitRowContainer>
            <div className={"info"}>
                <div className={"name"}>{data.company}</div>

                <div className={"details"}>
                    <Tooltip title={"DATE DETECTED"}>
                        <div className={"date"}>
                            <FontAwesomeIcon icon={faCalendar} fixedWidth />
                            <p>{toDateAndTime(data.detected_datetime)}</p>
                        </div>
                    </Tooltip>
                </div>
            </div>

            <div className={"prices"}>
                <Tooltip title={"PREVIOUS DETECTED PRICE"}>
                    <div className={"price"}>{data.prev_detected_price}</div>
                </Tooltip>

                <FontAwesomeIcon icon={faArrowRight} fixedWidth />
                <Tooltip title={"NEW DETECTED PRICE"}>
                    <div className={"price new"}>{data.detected_price}</div>
                </Tooltip>

                <Tooltip title={"FACTOR"}>
                    <div className={"factor"}>{data.split_factor_est}</div>
                </Tooltip>
            </div>

            <div className={"actions"}>
                <Actions newSS data={data} reloadData={() => {}} />
            </div>
        </StockSplitRowContainer>
    );
}
