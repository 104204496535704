import styled from 'styled-components';

export default styled.div`
     .tableDiv {
        clear: right;
    }

    .actionsDiv {
        width: full
        display: flex
    }

    .green {
        color: ${(props) => props.theme.permissionsAdd};
    }
    .red {
        color: ${(props) => props.theme.permissionsDelete};
    }

    .badge {
        color: ${(props) => props.theme.permissionsCheck};
        border-radius: 5px;
        background-color: ${(props) => props.theme.subMenuBackground};
        padding: 2px 5px;
        margin-right: 5px;
        font-size: 0.76rem;
        font-weight: bold;

    }
`;
