import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// Utils
import { useSettings } from "context/Settings.context";
import * as Database from "utils/database.utils";

// Styles
import OverviewContainer from "./Overview.style";
import Table from "../../../../components/Common/Table/Table";
import TableCell from "../../../../components/Common/TableCell/TableCell";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowRight,
    faCheckCircle, faPrint,
    faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import Tooltip from "@mui/material/Tooltip";
import Button from "components/Common/Button/Button";
import EmailDealingDeskModal from "components/Screens/DailyRecs/Overview/EmailDealingDeskModal/EmailDealingDeskModal";
import DownloadSignedUrl from "../../../../components/Common/DownloadSignedUrl/DownloadSignedUrl";
import {formatNumberFixDecimal} from "../../../../utils/common.utils";
import ButtonSmall from "components/Common/ButtonSmall/ButtonSmall";

const URL_OVERVIEW = "api/nav/alerts/overview/";
const EMAIL_BUTTON_URL = "api/nav/alerts/overview/checks_resolved";
const RUN_CHECKS_URL = "/api/nav/actions/checks";
const PDF_PRINT_URL = "https://app.powerbi.com/links/MxQVY-UWPJ?ctid=bf4450a2-bfcb-472d-8c40-1425eafb1a3b&pbi_source=linkShare"

interface Overview {
    id?: number;
    alerts: number;
    check: string;
    name: string;
    ok: boolean;
    resolved: number;
}

const mapCheckToUrl: any = {
    daily_flows: "daily_flows",
    daily_movements: "daily_movements",
    broker_exposures: "broker_exposure",
    hedging_restrictions: "hedging",
    leverage_collateral: "leverage_collateral_future",
    cr_nt_nav: "cr_nt_nav",
    cr_nt_cash_stock: "cr_ntcash_stock",
    currency_exposure_checks: "currency_exposure_checks",
    forwards: "forwards_ndfexposure_emir",
    ndf_exposures: "forwards_ndfexposure_emir",
    emir: "forwards_ndfexposure_emir",
    form_pf_adverse_return: "form_pf",
    form_pf_adverse_collateral: "form_pf",
    form_pf_adverse_redemption: "form_pf",
};

const Overview = () => {
    const [overview, setOverview] = useState<Overview[]>();
    const [loading, setLoading] = useState<boolean>(true);
    const { setToast, setShowLoadingToast } = useSettings();
    const [showEmailNoteModal, setShowEmailNoteModal] =
        useState<boolean>(false);
    const [isChecksResolved, setIsChecksResolved] = useState<boolean>(false);
    const [isChecksRunning, setIsChecksRunning] = useState<boolean>(false);

    const getOverview = async () => {
        let response = await Database.get(URL_OVERVIEW);

        if (response.ok) {
            setLoading(false);

            let data = await response.json();

            // add id to the objects
            data = data.data.map((obj: any, index: number) => {
                return { ...obj, id: index + 1 };
            });

            setOverview(data);
        }
    };

    const runChecks = async () => {
        setIsChecksRunning(true);
        setShowLoadingToast({
            show: true,
            message: "CHECKS ARE NOW RUNNING ...",
        });

        let response = await Database.post(RUN_CHECKS_URL);

        setTimeout(() => {
            if (response.ok) {
                getOverview();
            } else {
                setToast({
                    type: "error",
                    show: true,
                    message: "There was a problem running the checks.",
                });
            }
            setShowLoadingToast({show: false, message: "",});
            setIsChecksRunning(false);
        }, 10000);
    };


    const getEmailButtonState = async () => {

        let response = await Database.get(EMAIL_BUTTON_URL);

        if (response.ok) {
            let data = await response.json();
            setIsChecksResolved(data.checks_resolved);
        }
    };

    const getUrlFromCheck = (check: string) => {
        let check_name: string = mapCheckToUrl[check];
        return `/reconciliation/checks/${check_name}`;
    };

    useEffect(() => {
        getEmailButtonState();
        getOverview();
    }, []);

    const columns = [
        {
            id: 1,
            title: "TITLE",
            sortable: false,
            value: "name",
            cell: (data: any) => <TableCell data={data.name} />,
            show: true,
            fullDataRow: true,
        },
        {
            id: 2,
            title: "# ALERTS (Checks)",
            sortable: false,
            value: "alerts",
            cell: (data: any) => <TableCell data={formatNumberFixDecimal(data.alerts)} />,
            show: true,
            fullDataRow: true,
        },
        {
            id: 3,
            title: "RESOLVED",
            sortable: false,
            value: "resolved",
            cell: (data: any) => (
                <TableCell
                    data={data.alerts - data.resolved > 0 ? "No" : " Yes"}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 4,
            title: "# TRUE (Checks)",
            sortable: false,
            value: "deviation",
            cell: (data: any) => <TableCell data={formatNumberFixDecimal(data.resolved)} />,
            show: true,
            fullDataRow: true,
        },
        {
            id: 5,
            title: "OK",
            sortable: false,
            value: "ok",
            cell: (data: any) => (
                <div className={data.ok ? "green" : "red"}>
                    <FontAwesomeIcon
                        className="checkIcon"
                        icon={data.ok ? faCheckCircle : faTimesCircle}
                        fixedWidth
                    />
                </div>
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 6,
            title: "",
            sortable: false,
            value: "check",
            cell: (data: any) => (
                <Tooltip title={"VIEW"}>
                    <div className="viewIcon">
                        <Link to={getUrlFromCheck(data.check)}>
                            <FontAwesomeIcon icon={faArrowRight} fixedWidth />
                        </Link>
                    </div>
                </Tooltip>
            ),
            show: true,
            fullDataRow: true,
        },
    ];

    return (
        <OverviewContainer>
            {showEmailNoteModal && (
                <EmailDealingDeskModal setShowModal={setShowEmailNoteModal} />
            )}
            <p className={"sectionTitle"}>Check Overview</p>
            <div className={"options"}>
                {isChecksRunning ? (
                    <Button className="emailBtn" text="RUN CHECKS" click={runChecks} disabled />
                    ) : (
                    <Button className="emailBtn" text="RUN CHECKS" click={runChecks} />
                )}
                {isChecksResolved ? (
                    <Button
                        className="emailBtn"
                        text="EMAIL DEALING DESK"
                        click={() => setShowEmailNoteModal(true)}
                    />
                    ) : (
                    <Button
                        className="emailBtn"
                        text="EMAIL DEALING DESK"
                        click={() => {}}
                        disabled
                    />
                )}
                <DownloadSignedUrl text ={'Historic AUM & Returns Download'} identifier={"nav_historic_aum_returns_download"} />
                <ButtonSmall
                className="download-signed-url"
                text={"PDF Export (PowerBi)"}
                click={()=> window.open(PDF_PRINT_URL, "_blank")}
                icon={faPrint}
            />
            </div>

            <div className="tableDiv">
                <Table
                    loading={loading}
                    large
                    filterQuery={""}
                    columns={columns}
                    defaultSortKey="name"
                    data={overview}
                />
            </div>
        </OverviewContainer>
    );
};
export default Overview;
