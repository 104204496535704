import styled from "styled-components";
import colors from "assets/styles/colors";

export default styled.div`
    .switches {
        flex-direction: column;
        display: flex;
        flex-wrap: wrap;
        height: 500px;
        width: 600px;

        .MuiFormControlLabel-label {
            color: ${(props) => props.theme.modalText};
        }

        .switch {
            padding-left: 40px;
            width: 50%;

            .MuiSwitch-thumb {
                color: ${(props) => props.theme.switchChecked};
            }
            .MuiSwitch-track {
                background-color: ${(props) => props.theme.switchTrack};
            }
        }
    }
`;
