// Styles
import CommentCellContainer from "./CommentCountCell.styles";

type Props = { value: { value: any } };

export default function CommentCountCell({ value }: Props) {
    return (
        <CommentCellContainer
            data-cy="mode-result"
            className={"resultsCell score-" + 2}
        >
            {value.value}
        </CommentCellContainer>
    );
}
