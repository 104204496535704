export class Signoff {
    public  created_by: string;
    public created_at: Date|null;
    public report_date: Date|null;

    constructor(obj: any) {
        this.created_at = obj?.created_at
        this.created_by = obj?.created_by

        this.created_at = null;
        if (obj?.created_at) {
             this.created_at = new Date(obj.created_at)
        }

        this.report_date = null;
        if (obj?.report_date) {
             this.report_date = new Date(obj.report_date)
        }
    }
}
