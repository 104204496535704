import React, { useState } from "react";

// Context
import { useSettings } from "context/Settings.context";

// Styles
import NewScenarioModalContainer from "./NewScenarioModal.styles";

// Components
import Modal from "components/Common/Modal/Modal";
import TextField from "components/Common/TextField/TextField";
import Button from "components/Common/Button/Button";

// Utils
import * as Database from "utils/database.utils";

// Router
import { useHistory } from "react-router-dom";

interface NewScenarioModalProps {
    setShowModal: (show: boolean) => void;
    reload?: () => void;
    newScenarioValues: any;
}

const NewScenarioModal = ({
    setShowModal,
    newScenarioValues,
}: NewScenarioModalProps) => {
    const { setToast, setShowLoadingToast } = useSettings();
    const history = useHistory();
    const [loading, setLoading] = useState<boolean>(false);

    const [name, setName] = useState<string>("");

    const [errors, setErrors] = useState<{ name: any; desc: any }>({
        name: false,
        desc: false,
    });

    const saveScenario = async () => {
        if (!name) {
            setToast({
                show: true,
                type: "error",
                message: "Please enter a name",
            });
        } else {
            setShowLoadingToast({
                show: true,
                message: "SAVING",
            });

            //turn all values into floats, just to be sure
            Object.keys(newScenarioValues).forEach(function (el) {
                newScenarioValues[el] = parseFloat(newScenarioValues[el]);
            });

            let newScenarioObj = {
                ...newScenarioValues,
                scenario_id: name.replace(/\\|\//g, ""),
                //remove backslashes in the scenario name
                //as it messes up the delete and update url
            };

            const res = await Database.post(
                "api/fees/scenarios/",
                newScenarioObj
            );
            const data = await res.json();

            if (res.status === 400) {
                setToast({
                    show: true,
                    type: "error",
                    message: data.scenario_id[0],
                });
            }

            if (res.status === 200 || 201) {
                setToast({
                    show: true,
                    type: "success",
                    message: `"${name}" scenario has been created`,
                });
                history.push("/fees/forecasts?tab=all_scenarios");
            }
            setShowLoadingToast({
                show: false,
                message: "",
            });
        }
    };

    return (
        <NewScenarioModalContainer>
            <Modal loading={loading}>
                <div className="main">
                    <div className="text">
                        <h3>{"NEW SCENARIO"}</h3>
                    </div>

                    <label>Scenario Name</label>
                    <TextField
                        placeholder={"Scenario Name"}
                        change={setName}
                        value={name}
                        error={errors.name}
                    />
                </div>

                <div className="actions">
                    <Button
                        text="CANCEL"
                        color="flat"
                        click={() => setShowModal(false)}
                    />
                    <Button text="SAVE" click={() => saveScenario()} />
                </div>
            </Modal>
        </NewScenarioModalContainer>
    );
};
export default NewScenarioModal;
