import { useState } from "react";

// Components
import Table from "components/Common/Table/Table";
import SearchOptions from "components/Common/SearchOptions/SearchOptions";
import ErrorLevel from "components/Screens/Data/CompanyModel/Validation/ErrorLevel/ErrorLevel";
import TableCell from "components/Common/TableCell/TableCell";

// Data
import { severityOptions, wsOptions } from "assets/data/lists";

// Styles
import ValidationContainer from "./Validation.styles";

type Props = {
    id: string;
};

export default function Validation({ id }: Props) {
    const [filterQuery, setFilterQuery] = useState<string>("");

    const columns = [
        {
            id: 1,
            title: "MODEL TAB",
            sortable: true,
            value: "v_model_tab",
            cell: (data: string) => <TableCell data={data} />,
            show: true,
        },
        {
            id: 2,
            title: "METRIC",
            sortable: true,
            value: "v_metric",
            cell: (data: string) => <TableCell data={data} />,
            show: true,
        },
        {
            id: 3,
            title: "SEVERITY",
            sortable: true,
            value: "error_severity_rank",
            cell: (data: 1 | 2 | 3) => <ErrorLevel errorLevel={data} />,
            show: true,
        },
        {
            id: 4,
            title: "DESCRIPTION",
            sortable: true,
            value: "error_desc",
            cell: (data: string) => <TableCell data={data} />,
            show: true,
        },
        {
            id: 5,
            title: "VALUE",
            sortable: false,
            value: "cell_value",
            cell: (data: string) => <TableCell data={data} />,
            show: true,
        },
        {
            id: 6,
            title: "FORMULA",
            sortable: false,
            value: "cell_formula",
            cell: (data: string) => <TableCell data={data} />,
            show: true,
        },
    ];

    const filterOptions = [
        {
            id: 1,
            name: "Model Tab",
            value: "v_model_tab",
            type: "DROPDOWN",
            options: wsOptions,
        },
        {
            id: 2,
            name: "Severity",
            value: "severity",
            type: "DROPDOWN",
            options: severityOptions,
        },
    ];

    return (
        <ValidationContainer>
            <SearchOptions
                setFilterQuery={setFilterQuery}
                options={filterOptions}
            />

            <Table
                url={"api/extractions/validation/" + id}
                columns={columns}
                filterQuery={filterQuery}
                defaultSortKey="error_severity_rank"
                defaultSortOrder="DESC"
                pagination
            />
        </ValidationContainer>
    );
}
