import { useHistory } from "react-router-dom";
// Context
import { useAuth } from "context/Auth.context";

// Utils
import { getUsersDefaultHomePage } from "utils/user.utils";

export default function HomeRedirect() {
    const history = useHistory();
    const { user } = useAuth();

    //Chnge to home to enable the honepage
    // history.push("/home");

    if (user) {
        history.push(getUsersDefaultHomePage(user.permissions));
    } else {
        history.push("/ada/snapshot");
    }

    return null;
}
