import React from "react";

// Material UI
import { Tooltip } from "@mui/material";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Styles
import ButtonContainer from "./ButtonExtraSmall.styles";

type Props = {
    text: string;
    icon: any;
    color?: "primary" | "secondary" | "third" | "accent" | "flat";
    click: Function;
    disabled?: boolean;
    className?: string;
};

export default function ButtonExtraSmall({
    text,
    icon,
    color = "primary",
    click,
    disabled = false,
    className,
}: Props) {
    const handleClick = (e: React.MouseEvent) => {
        if (!disabled) {
            click(e);
        }
    };

    return (
        <Tooltip title={<h2>{text}</h2>}>
            <ButtonContainer
                data-cy="button"
                className={
                    className +
                    " button " +
                    color +
                    (disabled ? " disabled" : "")
                }
                onClick={handleClick}
                role={"button"}
            >
                <FontAwesomeIcon className="icon" icon={icon} fixedWidth />
            </ButtonContainer>
        </Tooltip>
    );
}
