// @ts-nocheck
import { useEffect, useState } from "react";

// Material UI
import { useBQMQ } from "context/BQMQ.context";

// FontAwesome
import { faArrowRight, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Components
import Button from "components/Common/Button/Button";
import LoadingSpinner from "components/Common/LoadingSpinner/LoadingSpinner";

// Utils

// Styles
import { teamLabelDict } from "../../../../../../assets/data/lists";
import CompanyListSelectContainer from "./CompanyListSelect.styles";

type Props = { setCompany: Function; setShowNewCompany: Function };

export default function CompanyListSelect({
    setCompany,
    setShowNewCompany,
}: Props) {
    const [loading, setLoading] = useState(true);
    const { companies } = useBQMQ();
    const [companyList, setCompanyList] = useState<any[]>([]);
    const [searchString, setSearchString] = useState("");
    const [filteredCompanyList, setFilteredCompanyList] = useState<any[]>([]);

    const getCompanyList = async () => {
        setFilteredCompanyList(companies);
        setCompanyList(companies);
        setLoading(false);
    };

    const handleSearch = (value: string) => {
        setSearchString(value);
        let newArray = companyList.filter(
            (item) =>
                item.label.includes(value.toUpperCase()) ||
                item.sector?.name.includes(value.toUpperCase())
        );
        setFilteredCompanyList(newArray);
    };

    useEffect(() => {
        getCompanyList();
    }, []);

    return (
        <CompanyListSelectContainer>
            <div className={"heading"}>
                <p>SELECT COMPANY</p>
            </div>

            <div className={"searchRow"}>
                <div className={"searchIcon"}>
                    <FontAwesomeIcon icon={faSearch} fixedWidth />
                </div>

                <input
                    data-cy="companies-search-field"
                    className={"searchBox"}
                    placeholder={"Search Companies or Team"}
                    value={searchString}
                    onChange={(e) => handleSearch(e.target.value)}
                />
            </div>

            {filteredCompanyList.map((item) => (
                <div
                    data-cy="filtered-companies-list"
                    className={"sessionRow"}
                    onClick={() => {
                        setCompany(item);
                    }}
                    key={item.value}
                >
                    <p className={"name"}>{item.label}</p>
                    <p className={"team"}>{teamLabelDict[item.sector?.name]}</p>

                    <div className={"icon"}>
                        <FontAwesomeIcon icon={faArrowRight} fixedWidth />
                    </div>
                </div>
            ))}

            {!loading && filteredCompanyList.length === 0 && (
                <div className="newCompanyContainer">
                    <div className="newCompanyText">
                        <p>
                            <span className="newCompanySpan">
                                {searchString}
                            </span>{" "}
                            not found. Add as new company idea?
                        </p>
                    </div>
                    <hr />
                    <Button
                        className="createBtn"
                        click={() =>
                            setCompany({
                                name: searchString,
                            })
                        }
                        text="ADD"
                    />
                </div>
            )}

            {loading && (
                <div className={"loadingRow"}>
                    <LoadingSpinner noText />
                </div>
            )}
        </CompanyListSelectContainer>
    );
}
