import { useEffect, useRef, useState } from "react";
import cashflowEventColumnGenerator from "../../utils/CashFlowEventGenerator";

// Styles
import MgmtFeesContainer from "./Mgmt.styles";

// Components
import Table from "components/Common/Table/Table";
import TableCell from "components/Common/TableCell/TableCell";
import EditableCell from "components/Screens/Admin/ValidationRules/EditableCell/EditableCell";

// Utils
import { useFees } from "context/Fees.context";
import calculateMaxHeight from "../../utils/CalculateMaxHeight";
import cellMap from "../../utils/CellMap";
import FloatToCurrencyParser from "../../utils/FloatToCurrencyParser";
import FloatToPercParser from "../../utils/FloatToPercParser";
import GenerateQtrRepeatedCols from "../../utils/GenerateQtrRepeatedCols";
import StringToFloatParser from "../../utils/StringToFloatParser";
import { SeparateAccountsRow } from "../../utils/types";

interface MgmtProps {
    fund?: string;
    region?: string;
    period?: string;
}

const Mgmt = ({ fund, region, period }: MgmtProps) => {
    const [filterQuery, setFilterQuery] = useState<string>("");
    const [tableIsLoading, setTableIsLoading] = useState<boolean>(false);
    const [reload, setReload] = useState(0);
    const tableRef = useRef<HTMLDivElement>(null);
    const [maxHeight, setMaxHeight] = useState("100vh");
    const { managementFees, updateFeesData, loading, period: selectedPeriod } = useFees();

    useEffect(() => {
        calculateMaxHeight(tableRef, setMaxHeight);
        window.addEventListener("resize", () =>
            calculateMaxHeight(tableRef, setMaxHeight)
        );
        return () => {
            window.removeEventListener("resize", () =>
                calculateMaxHeight(tableRef, setMaxHeight)
            );
        };
    }, []);

    const columns = [
        {
            id: 1,
            title: "ACCOUNT ID",
            sortable: false,
            value: "account_id",
            cell: (data: SeparateAccountsRow) => (
                <TableCell data={data.account_id} />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 2,
            title: "CUSTODY",
            sortable: false,
            value: "custody",
            cell: (data: SeparateAccountsRow) => (
                <TableCell data={data.custody} />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 3,
            title: "CUSTODY ACCOUNT NUMBER",
            sortable: false,
            value: "custody_account_number",
            cell: (data: SeparateAccountsRow) => (
                <TableCell data={data.custody_account_number} />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 4,
            title: "ACCOUNT NAME",
            sortable: false,
            value: "account_name",
            cell: (data: SeparateAccountsRow) => (
                <TableCell data={data.account_name} />
            ),
            show: true,
            fullDataRow: true,
        },

        {
            id: 5,
            title: "CURRENCY",
            sortable: false,
            value: "currency",
            cell: (data: SeparateAccountsRow) => (
                <TableCell data={data.currency} />
            ),
            show: true,
            fullDataRow: true,
        },

        {
            id: 6,
            title: "FX RATE",
            sortable: false,
            value: "fx_rate",
           cell: (data: SeparateAccountsRow) => (
                <EditableCell
                     apiMethod="patch"
                     type="numericalField"
                    rowId={data.id}
                    validateCellValueTypeBy="number"
                    cellValueType="number"
                    cellValue="comments"
                    rowIdKey={"id"}
                    initialValue={data.fx_rate}
                    setTableIsLoading={setTableIsLoading}
                    updateTable={() => setReload(reload + 1)}
                    onUpdate={(idx: number, value: number) => {
                        return updateFeesData(
                            "fx_rate",
                            data.account_id,
                            selectedPeriod,
                            data.tranche,
                            value,
                            { version: data.version, report_id: data.report_id }
                        );
                    }}
                />
            ),
            show: true,
            fullDataRow: true,
        },

        {
            id: 7,
            title: "CABLE RATE",
            sortable: false,
            value: "cable_rate",
            cell: (data: SeparateAccountsRow) => (
                <TableCell data={data.cable_rate} />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 8,
            title: "TRANCHE",
            sortable: false,
            value: "tranche",
            cell: (data: SeparateAccountsRow) => (
                <TableCell data={data.tranche} />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 9,
            title: "TRANCHE WEIGHTING",
            sortable: false,
            value: "tranche_weighting",
            cell: (data: SeparateAccountsRow) => (
                <TableCell data={FloatToPercParser(data.tranche_weighting)} />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 10,
            title: "PRICING",
            sortable: false,
            value: "pricing",
            cell: (data: SeparateAccountsRow) => (
                <TableCell data={FloatToPercParser(data.pricing)} />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 11,
            title: "TYPE OF CALCULATION",
            sortable: false,
            value: "type_of_calculation",
            cell: (data: SeparateAccountsRow) => (
                <TableCell data={data.type_of_calculation} />
            ),
            show: true,
            fullDataRow: true,
        },
        ...GenerateQtrRepeatedCols(
            12,
            1,
            "#6BB9CA",
            "#000000",
            cellMap(setReload, reload, setTableIsLoading)
        ),
        ...GenerateQtrRepeatedCols(
            17,
            2,
            "#D5EAD8",
            "#000000",
            cellMap(setReload, reload, setTableIsLoading)
        ),
        ...GenerateQtrRepeatedCols(
            22,
            3,
            "#A7D3AD",
            "#000000",
            cellMap(setReload, reload, setTableIsLoading)
        ),
        ...cashflowEventColumnGenerator(managementFees, 35),

        {
            id: 27,
            title: "MANAGEMENT FEE",
            sortable: false,
            value: "management_fee",
            cell: (data: SeparateAccountsRow) => (
                <TableCell
                    data={FloatToCurrencyParser(
                        data.management_fee,
                        data.currency
                    )}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 28,
            title: "MANAGEMENT FEE BILLED",
            sortable: false,
            value: "management_fee_billed",
            cell: (data: SeparateAccountsRow) => (
                <TableCell data={FloatToCurrencyParser(
                    data.management_fee_billed,
                    data.currency
                )} />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 29,
            title: "VARIANCE",
            sortable: false,
            value: "variance",
            cell: (data: SeparateAccountsRow) => (
                <TableCell
                    data={FloatToCurrencyParser(data.variance, data.currency)}
                />
            ),
            show: true,
            fullDataRow: true,
            tooltip: "MANAGEMENT FEE - MANAGEMENT FEE BILLED",
        },
        {
            id: 30,
            title: "COMMENTARY",
            sortable: false,
            value: "commentary",
            cell: (data: SeparateAccountsRow) => (
                <EditableCell
                    rowId={data.id}
                    cellValueType="string"
                    cellValue="comments"
                    rowIdKey={"id"}
                    initialValue={data.commentary}
                    type="textField"
                    setTableIsLoading={setTableIsLoading}
                    updateTable={() => setReload(reload + 1)}
                    onUpdate={(idx: number, value: string) => {
                        return updateFeesData(
                            "commentary",
                            data.account_id,
                            selectedPeriod,
                            data.tranche,
                            value,
                            { version: data.version, report_id: data.report_id }
                        );
                    }}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 31,
            title: "ADMINISTRATION FEE",
            sortable: false,
            value: "administration_fee",
            cell: (data: SeparateAccountsRow) => (
                <TableCell
                    data={FloatToCurrencyParser(
                        data.administration_fee,
                        data.currency
                    )}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 32,
            title: "ANNUAL FEE RATE",
            sortable: false,
            value: "annual_fee_rate",
            cell: (data: SeparateAccountsRow) => (
                <TableCell
                    data={FloatToCurrencyParser(
                        data.annual_fee_rate,
                        data.currency
                    )}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 33,
            title: "UK RPI",
            sortable: false,
            value: "uk_rpi",
            cell: (data: SeparateAccountsRow) => (
                <TableCell data={FloatToPercParser(data.uk_rpi * 100)} />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 34,
            title: "UPDATED ANNUAL FEE",
            sortable: false,
            value: "updated_annual_fee",
            cell: (data: SeparateAccountsRow) => (
                <TableCell
                    data={FloatToCurrencyParser(
                        data.updated_annual_fee,
                        data.currency
                    )}
                />
            ),
            show: true,
            fullDataRow: true,
        },
    ];

    return (
        <MgmtFeesContainer maxHeight={maxHeight}>
            <div className="tableDiv" ref={tableRef}>
                <Table
                    emptyText={"No Data"}
                    showEmptyIcon={false}
                    data={managementFees}
                    columns={columns}
                    filterQuery={filterQuery + "&period=" + period}
                    reload={reload}
                    pagination
                    defaultSortKey="event_date"
                    defaultSortOrder="DESC"
                    stickyColumns={[3]}
                    loading={loading}
                />
            </div>
        </MgmtFeesContainer>
    );
};
export default Mgmt;
