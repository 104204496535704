
        import Button from 'components/Common/Button/Button';
        
        export default {
            
                Button: {
                    component: Button,
                }
            
        }        
        