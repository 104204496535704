import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faExclamationCircle,
    faFileExcel,
} from "@fortawesome/free-solid-svg-icons";

// Utils
import * as Database from "utils/database.utils";
import { getOneCompany } from "utils/companies.utils";
import { toDate } from "utils/general.utils";

// Components
import PageTitle from "components/Common/PageTitle/PageTitle";
import Button from "components/Common/Button/Button";
import Alert from "components/Common/Alert/Alert";

// Styles
import UploadDataContainer from "./UploadData.styles";

const UploadData: React.FC = () => {
    const history = useHistory();

    const [extractionID, setExtractionID] = useState<string>("");
    const [company, setCompany] = useState<any>(false);
    const [companyName, setCompanyName] = useState<any>("");
    const [extractionDate, setExtractionDate] = useState<string>("");

    const [showNotesBox, setShowNotesBox] = useState<boolean>(false);
    const [notes, setNotes] = useState<string>("");

    const [selectedFile, setSelectedFile] = useState<any>(null);

    const [loading, setLoading] = useState<boolean>(false);
    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
    const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
    const [errors, setErrors] = useState<any>(false);

    const uploadFile = () => {
        setLoading(true);

        const formData = new FormData();

        formData.append("raw_model", selectedFile);

        formData.append("extraction_id", extractionID);
        formData.append("company", company.company_id);
        formData.append("extraction_datetime", extractionDate);
        formData.append("user_id", localStorage.getItem("gim-user-id")!);
        formData.append("notes", notes);
        formData.append("company_name", companyName);

        Database.postFile("api/model_actions/upload", formData).then((res) => {
            setLoading(false);
            if (res.ok) {
                setShowSuccessModal(true);
                clear();
            } else {
                setShowErrorModal(true);
            }
        });
    };

    const checkFields = () => {
        let newErrors = [];
        let errorCount = 0;

        if (!selectedFile) {
            newErrors.push("Please select a file.");
            errorCount++;
        }

        if (
            company.company_id !== 0 &&
            selectedFile &&
            !(
                selectedFile.name ===
                    company.company_model_excel_name + ".xlsx" ||
                selectedFile.name === company.company_model_excel_name + ".xlsm"
            )
        ) {
            newErrors.push("Filename is incorrect.");
            errorCount++;
        }

        setErrors(newErrors);

        return errorCount === 0;
    };

    const handleClickUpload = () => {
        if (checkFields()) {
            uploadFile();
        }
    };

    const clear = () => {
        setCompany(false);
        setExtractionDate("");
        setNotes("");
        setSelectedFile(null);
        setErrors([]);
        setShowNotesBox(false);
    };

    useEffect(() => {
        if (errors.length > 0) checkFields();
    }, [company, extractionDate, selectedFile]);

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        if (urlParams.get("company")) {
            getOneCompany(urlParams.get("company")).then((res) => {
                res.json().then((data) => {
                    setCompany(data.data);
                    setCompanyName(
                        urlParams.get("company") === "0"
                            ? urlParams.get("company_name")
                            : data.data.company
                    );
                });
            });
        }

        if (urlParams.get("extractionID"))
            setExtractionID(urlParams.get("extractionID")!);
        if (urlParams.get("extractionDate"))
            setExtractionDate(urlParams.get("extractionDate")!);
    }, []);

    return (
        <UploadDataContainer>
            <PageTitle title="UPLOAD DATA" />
            {errors.length > 0 && (
                <div className="errorMessages">
                    <FontAwesomeIcon icon={faExclamationCircle} fixedWidth />
                    <ul>
                        {errors.map((error: any) => (
                            <li>{error}</li>
                        ))}
                    </ul>
                </div>
            )}
            <div className="metaOptions">
                <div className="info">
                    <label>COMPANY</label>
                    <p>{companyName ? companyName : "LOADING"}</p>
                </div>
                <div className="info">
                    <label>EXTRACTION DATE</label>
                    <p>{toDate(extractionDate)}</p>
                </div>

                <Button
                    text={showNotesBox ? "HIDE NOTES" : "ADD NOTES"}
                    color="primary"
                    click={() => setShowNotesBox(!showNotesBox)}
                />
            </div>

            {showNotesBox && (
                <>
                    <label>NOTES</label>

                    <textarea
                        className="notesBox"
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)}
                        rows={5}
                        placeholder="Notes"
                    />
                </>
            )}

            <label
                className={"uploadBox " + (errors.length > 0 && !selectedFile)}
                htmlFor="getFile"
                onDrop={(e) => {
                    e.preventDefault();
                    setSelectedFile(e.dataTransfer.items[0].getAsFile());
                }}
                onDragOver={(e) => e.preventDefault()}
            >
                <FontAwesomeIcon icon={faFileExcel} fixedWidth />
                <p>{selectedFile ? selectedFile.name : "UPLOAD MODEL"}</p>
                <p className="filenameExample">
                    FILE NAME SHOULD BE THE FORMAT:{" "}
                    {company
                        ? company.company_model_excel_name
                            ? company.company_model_excel_name
                            : "01 - Company.xlsm"
                        : "01 - Company.xlsm"}
                </p>
            </label>

            <input
                type="file"
                id="getFile"
                accept=".xlsx, .xlsm"
                className="inputHide"
                onChange={(e) => setSelectedFile(e.target.files![0])}
            />

            <div className="actions">
                <Button
                    text="UPLOAD"
                    color="primary"
                    click={handleClickUpload}
                />
            </div>

            {loading && (
                <Alert>
                    <h2>UPLOADING</h2>
                    <p>In progress, may take a few moments</p>
                </Alert>
            )}

            {!loading && showSuccessModal && (
                <Alert>
                    <h2>Processing.</h2>
                    <p>
                        Your file has been uploaded and added to the queue for
                        processing.
                    </p>
                    <Button
                        text="CLOSE"
                        color="primary"
                        click={() => {
                            setShowSuccessModal(false);
                            history.push("/");
                        }}
                    />
                </Alert>
            )}

            {!loading && showErrorModal && (
                <Alert>
                    <h2>ERROR</h2>
                    <p>There has been a problem uploading the file.</p>
                    <Button
                        text="BACK"
                        color="primary"
                        click={() => setShowErrorModal(false)}
                    />
                </Alert>
            )}
        </UploadDataContainer>
    );
};

export default UploadData;
