import { useEffect, useState } from "react";

// Context
import { useSettings } from "context/Settings.context";

// Styles
import ReportsDetailContainer from "./ReportsDetail.styles";

// Router
import { useHistory } from "react-router-dom";

// Font Awesome
import {
    faArrowAltCircleLeft,
    faDownload,
} from "@fortawesome/free-solid-svg-icons";

// Components
import PageTitle from "components/Common/PageTitle/PageTitle";
import Button from "components/Common/Button/Button";
import Tabs from "components/Common/Tabs/Tabs";
import FeeCalculations from "./FeeCalculationsTab/FeeCalculations";
import DiscrepancyReport from "./DiscrepancyReportTab/DiscrepancyReport";
import ButtonSmall from "components/Common/ButtonSmall/ButtonSmall";

// Utils
import * as Database from "utils/database.utils";
import { createLocalDownload } from "utils/export.utils";
import { uppercaseFirstLetter } from "utils/common.utils";

interface ReportsDetailProps {}

const ReportsDetail = ({}: ReportsDetailProps) => {
    const { setShowLoadingToast } = useSettings();
    const history = useHistory();
    const [fund, setFund] = useState("");
    const [region, setRegion] = useState("");
    const [reportId, setReportId] = useState("");

    useEffect(() => {
        setFund("");
        setRegion("");
        setReportId("");

        const urlParams = new URLSearchParams(window.location.search);
        setFund(urlParams.get("fund")!!);
        setRegion(urlParams.get("region")!!);
        formatReportId(urlParams.get("id")!!);
    }, []);

    const formatReportId = (id: string) => {
        let reportNameArr = id.split("_");
        let version = reportNameArr[4].replace("V", "Version ");
        let year = reportNameArr[3].slice(0, 4);
        let quarter = "Q" + reportNameArr[3].slice(5, 6);
        let name =
            uppercaseFirstLetter(reportNameArr[1]) +
            " " +
            uppercaseFirstLetter(reportNameArr[2]);
        let full = name + " " + year + " " + quarter + " " + version;
        setReportId(full);
    };

    const handleDownload = async () => {
        setShowLoadingToast({
            show: true,
            message: "DOWNLOADING",
        });

        const res = await Database.get(
            `api/fees/reports/${fund}/${reportId}/discrepancies/download`
        );
        const file = await res.blob();
        createLocalDownload(`${reportId} - Discrepancies.csv`, file);

        setShowLoadingToast({
            show: false,
            message: "",
        });
    };

    return (
        <ReportsDetailContainer>
            <PageTitle
                title={"FEES REPORT - " + reportId}
                actions={
                    <Button
                        icon={faArrowAltCircleLeft}
                        text="Back to report overview"
                        click={() =>
                            history.push(
                                `/fees/${fund.toLocaleLowerCase()}?tab=${region}&subTab=reports`
                            )
                        }
                    />
                }
            />

            <Tabs
                defaultTab="fee_calculations"
                tabs={[
                    {
                        value: "fee_calculations",
                        title: "FEE CALCULATIONS",
                        permissions: ["p_list_companies"],
                        content: (
                            <FeeCalculations
                                fund={fund}
                                url={`api/fees/reports/${region}/${fund}/${reportId}/pf_reports`}
                            />
                        ),
                    },
                    {
                        value: "discrepancy_report",
                        title: "DISCREPANCY REPORT",
                        permissions: ["p_list_companies"],
                        content: (
                            <DiscrepancyReport
                                reportId={reportId}
                                url={`api/fees/reports/${region}/${fund}/${reportId}/discrepancies`}
                            />
                        ),
                    },
                ]}
            />
        </ReportsDetailContainer>
    );
};
export default ReportsDetail;
