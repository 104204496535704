// FontAwesome
import {
    faInfoCircle,
    faExclamationCircle,
    faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Styles
import ErrorLevelContainer from "./ErrorLevel.styles";

type Props = {
    errorLevel: 1 | 2 | 3;
    noText?: boolean;
};

export default function ErrorLevel({ errorLevel, noText = false }: Props) {
    let statusDict = {
        1: { icon: faInfoCircle, text: "Info", color: "blue" },
        2: { icon: faExclamationCircle, text: "Warning", color: "orange" },
        3: { icon: faTimesCircle, text: "Critical", color: "red" },
    };

    return (
        <ErrorLevelContainer className={statusDict[errorLevel].color}>
            <div className="icon">
                <FontAwesomeIcon
                    icon={statusDict[errorLevel].icon}
                    fixedWidth
                />
            </div>
            {!noText && (
                <div className="text">
                    <p>{statusDict[errorLevel].text}</p>
                </div>
            )}
        </ErrorLevelContainer>
    );
}
