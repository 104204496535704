import styled from "styled-components";
import colors from "assets/styles/colors";

export default styled.div`
    flex-direction: column;
    display: flex;
    flex-grow: 1;

    .viewIcon {
        cursor: pointer;

        svg {
            color: ${(props) => props.theme.tableIconHighlight};
            font-size: 20px;
        }
    }
`;
