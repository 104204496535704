// Material UI
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";

// Styles
import DropDownMenuContainer from "./DropDownMenu.styles";

type Props = {
    open: boolean;
    setOpen: Function;
    anchorEl: any;
    children: React.ReactNode;
    width?: string;
};

export default function DropDownMenu({
    open,
    setOpen,
    anchorEl,
    children,
    width,
}: Props) {
    return (
        <Popper open={open} anchorEl={anchorEl} style={{ zIndex: 10 }}>
            <ClickAwayListener onClickAway={() => setOpen(false)}>
                <Paper style={{ width: width }}>
                    <DropDownMenuContainer className="dropDownMenu">
                        {children}
                    </DropDownMenuContainer>
                </Paper>
            </ClickAwayListener>
        </Popper>
    );
}
