import { createContext, useContext, useState, useEffect } from 'react';
import { ApiHandler } from "services/api_handler";
import {useSettings} from "context/Settings.context";

export const GrowthCompaniesContext = createContext();

const URL_COMPANIES_LIST = "api/growth_voting/companies";

export function GrowthCompaniesProvider({ children }) {
    const [companies, setCompanies] = useState([]);

    const fetchCompanies = async () => {
        // all growth users
        if (companies.length > 0) {
            return;
        }

        let response = await new  ApiHandler(URL_COMPANIES_LIST).list();

        // sort by abc
        let data = response.data.sort((a, b) =>a.name.localeCompare(b.name))

        setCompanies(data);
    }

    useEffect(() => {
        fetchCompanies();
    }, []);

    return (
        <GrowthCompaniesContext.Provider
            value={{
                companies,
            }}
        >
            {children}
        </GrowthCompaniesContext.Provider>
    );
}

export const useGrowthCompanies = () => {
    return useContext(GrowthCompaniesContext);
};
