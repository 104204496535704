import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// Utils
import * as Database from "utils/database.utils";

// Components
import HomeList from "../../../Home/HomeList/HomeList";
import ExtractionRow from "../ExtractionRow/ExtractionRow";

// Context
import { useSettings } from "context/Settings.context";
import { useNotifications } from "context/Notifications.context";

// Styles
import FailedExtractionsContainer from "./FailedExtractions.styles";

export default function FailedExtractions() {
    const history = useHistory();
    const { setDefaultFilters } = useSettings();
    const { setFailedCompanyExtractions } = useNotifications();

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);

    const getFailedExtractions = async () => {
        let res = await Database.get(
            "api/extractions?page=1&pageSize=1000&sortKey=extraction_datetime&sortOrder=DESC&filter_model_status=FAI"
        );
        let data = await res.json();

        setData(data.data);
        setTotal(data.total);
        setFailedCompanyExtractions(data.total);
        setLoading(false);
    };

    useEffect(() => {
        setLoading(true);
        getFailedExtractions();
    }, []);

    return (
        <FailedExtractionsContainer>
            <HomeList
                data={data}
                row={(row: any) => <ExtractionRow data={row} />}
                title={"FAILED EXTRACTIONS"}
                count={total}
                countColor={"failed"}
                loading={loading}
                seeAll={() => {
                    setDefaultFilters({ model_status: "FAI" });
                    history.push("/data/company-models");
                }}
                maxRows={5}
            />
        </FailedExtractionsContainer>
    );
}
