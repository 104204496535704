//Context
import { createContext, useContext, useEffect, useState } from "react";
// Context
import addCalculatedFeesFields from "../components/Screens/Fees/Common/utils/CalculateFields";
import { useAuth } from "./Auth.context";
import { useSettings } from "./Settings.context";

// Utils
import * as Database from "utils/database.utils";
// Logger
import Logger from "utils/logger.utils";
const Log = new Logger();
Log.init("Fees.Context");

const FeesContext = createContext();

export function FeesProvider({ children, period }) {
    const { user } = useAuth();
    const [feesData, setFeesData] = useState([]);
    const [redemptions, setRedemptions] = useState([]);
    const [subscriptions, setSubscriptions] = useState([]);
    const [performanceFees, setPerformanceFees] = useState([]);
    const [prevPerformanceFees, setPrevPerformanceFees] = useState([]);
    const [managementFees, setManagementFees] = useState([]);
    const [loading, setLoading] = useState(false);
    const { setShowLoadingToast, setToast } = useSettings();
    const [configs, setConfigs] = useState([]);

    const updateFeesData = async (
        rowKey,
        account_id,
        month,
        tranche,
        value,
        meta = null
    ) => {
        let payload = {
            account_id: account_id,
            month: month,
            tranche_number: tranche,
            key: rowKey,
            value: value,
            meta: meta,
            user: user.email,
        };
        setShowLoadingToast({ show: true, message: "LOADING" });
        setLoading(true);

        const res = await Database.patch(
            `api/fees/separate-accounts/sac/${period}`,
            payload
        );

        let rtnVal;
        if (res.status === 200) {
            let data = await res.json();
            data = data.data;
            setRedemptions(addCalculatedFeesFields(data.redemptions));
            setSubscriptions(addCalculatedFeesFields(data.subscriptions));
            setPerformanceFees(addCalculatedFeesFields(data.performance_fees));
            setManagementFees(addCalculatedFeesFields(data.management_fees));
            setPrevPerformanceFees(data.previous_pf);
        } else {
            let data = await res.json();
            rtnVal = data.error;
            setToast({
                show: true,
                type: "error",
                message: "Error. It's likely because of an incorrect client configuration for the quarter.",
            });
        }

        setLoading(false);
        setShowLoadingToast({show: false, message: "",});

        getConfig();

        return rtnVal;
    };

    const getConfig = async () => {
        setLoading(true);

        let configRequest = await Database.get(`api/fees/client_config/quarter/${period}`);
        let configData = null;

        if (configRequest.status === 200) {
            let configData = await configRequest.json();
            setConfigs(defaultSortForConfigs(configData.data));
            setShowLoadingToast({ show: false, message: "" });
        } else {
            setToast({
                show: true,
                type: "error",
                message: "Error loading the client configuration.",
            });
        }

        setLoading(false);

        return configData;
    };

    const getSeparateAccountsData = async () => {
        setLoading(true);
        const res = await Database.get(`api/fees/separate-accounts/sac/${period}`);

        let errors = null;

        if (res.status === 200) {
            let response_data = await res.json();
            let data = response_data.data;
            setRedemptions(addCalculatedFeesFields(data.redemptions));
            setSubscriptions(addCalculatedFeesFields(data.subscriptions));
            setPerformanceFees(addCalculatedFeesFields(data.performance_fees));
            setManagementFees(addCalculatedFeesFields(data.management_fees));
            setPrevPerformanceFees(data.previous_pf);

            errors = response_data.errors;
        } else {
            setToast({
                show: true,
                type: "error",
                message: "Error loading the separate accounts data.",
            });

            // Clear the error, don't show lower priority error
            errors = null;
        }

        if (errors) {
            setToast({
                show: true,
                type: "error",
                message: `Accounts with issues: ${errors}`,
            });
        }

        setLoading(false);
    };

    const getData = async () => {
        await getConfig();
        await getSeparateAccountsData();
    };

    const defaultSortForConfigs = (configs) => {
        return configs.sort((a, b) => a.account_id.localeCompare(b.account_id));
    }

    useEffect(() => {
        getData();
    }, []);

    return (
        <FeesContext.Provider
            value={{
                feesData,
                setFeesData,
                redemptions,
                subscriptions,
                performanceFees,
                managementFees,
                setRedemptions,
                setSubscriptions,
                setPerformanceFees,
                setManagementFees,
                updateFeesData,
                loading,
                prevPerformanceFees,
                configs,
                period,
                getData,
            }}
        >
            {children}
        </FeesContext.Provider>
    );
}

export const useFees = () => {
    return useContext(FeesContext);
};
