import React from "react";

// Styles
import QualityMatrixViewContainer from "./QualityMatrixView.styles";

interface QualityMatrixViewProps {}

const QualityMatrixView = ({}: QualityMatrixViewProps) => {
    return (
        <QualityMatrixViewContainer>
            <div className="iframeContainer">
                <iframe
                    title="Portfolio Quality Matrix (QS) vLive"
                    src="https://app.powerbi.com/reportEmbed?reportId=e5fc799a-c36f-49bb-aad6-4fa690fcfcbd&autoAuth=true&ctid=bf4450a2-bfcb-472d-8c40-1425eafb1a3b&filterPaneEnabled=false"
                    width="100%"
                    height="100%"
                    allowFullScreen
                    frameBorder={0}
                />
            </div>
        </QualityMatrixViewContainer>
    );
};
export default QualityMatrixView;
