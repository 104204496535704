import styled from "styled-components";

export const SearchableDropDownContainer = styled.div`
    .searchBox {
        /* border-bottom: 1px solid #777777; */
        align-items: center;
        max-width: 250px;
        min-width: 250px;
        cursor: pointer;

        background-color: #eeeeee;
        border-radius: 5px;
        overflow: hidden;
        display: flex;

        input {
            border: none;
            height: 40px;
            font-size: 18px;
            padding-left: 15px;
            outline: none;
            display: flex;
            flex-grow: 1;
            background-color: #eeeeee;
        }

        svg {
            margin: 0 10px;
            color: #777777;
        }

        :hover {
            background-color: #dddddd;
            input {
                background-color: #dddddd;
            }
        }

        &.error {
            border: 2px solid lightcoral;
            input {
                height: 36px;
                padding-left: 13px;
            }
        }
    }

    .dropDownMenu {
        z-index: 5;
    }
`;

export const SearchableOptionsContainer = styled.div`
    flex-direction: column;
    display: flex;
    max-height: 400px;
    max-width: 250px;
    min-width: 250px;
    overflow-y: scroll;
    background-color: white;
    box-shadow: 0px 5px 15px 5px rgba(0, 0, 0, 0.28);

    .option {
        min-width: 150px;
        display: flex;
        cursor: pointer;
        :hover {
            background-color: #dddddd;
        }
        p {
            margin: 10px 15px;
        }
    }

    .noResults {
        min-width: 150px;
        display: flex;
        width: 100%;
        p {
            margin: 10px 15px;
            width: 100%;
            text-align: center;
            color: #777777;
        }
    }
`;
