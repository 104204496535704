import { useEffect, useState } from "react";

// Material UI
import ClickAwayListener from "@mui/material/ClickAwayListener";

// FontAwesome
import { faEye, faSearch, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Utils
import * as Database from "utils/database.utils";

// Components
import Button from "components/Common/Button/Button";
import ButtonSmall from "components/Common/ButtonSmall/ButtonSmall";
import LoadingSpinner from "components/Common/LoadingSpinner/LoadingSpinner";
import Modal from "components/Common/Modal/Modal";

//Context
import { useSettings } from "context/Settings.context";

// Styles
import { useBQMQ } from "context/BQMQ.context";
import InviteAnalystListContainer from "./InviteAnalystList.styles";

type Props = {
    setShowInviteAnalystScreen: Function;
    setShowFullUserList: Function;
    currentUsers: any[];
    session: any;

    reload: () => void;
};

export default function InviteAnalystList({
    setShowInviteAnalystScreen,
    setShowFullUserList,
    currentUsers,
    session,
    reload,
}: Props) {
    const { setToast, setShowLoadingToast } = useSettings();
    const { analysts } = useBQMQ();

    const [showOnlyInvited, setShowOnlyInvited] = useState(false);

    const [searchString, setSearchString] = useState("");
    const [users, setUsers] = useState<any[]>([]);
    const [filteredUserList, setFilteredUserList] = useState<any[]>([]);

    const changeValue = (type: string, userId: string) => {
        const updatedUser = users.find((user: any) => user.id === userId);

        if (type === "core") {
            updatedUser.core = !updatedUser.core;
            updatedUser.invited = false;
        } else {
            updatedUser.invited = !updatedUser.invited;
            updatedUser.core = false;
        }

        let newUsers = users.filter((user: any) => user.id !== userId);

        setUsers(
            [updatedUser, ...newUsers].sort((a, b) =>
                a.name.localeCompare(b.name)
            )
        );
    };

    const getUserList = async () => {
        const newAnalysts = analysts.map((user: any) => ({
            name: user.label,
            id: user.value,
            active: user.active,
            core: session.core
                .map((coreUser: any) => coreUser.id)
                .includes(user.value),
            invited: session.analysts
                .map((invitedUser: any) => invitedUser.id)
                .includes(user.value),
            current:
                session.analysts
                    .map((invitedUser: any) => invitedUser.id)
                    .includes(user.value) ||
                session.core
                    .map((coreUser: any) => coreUser.id)
                    .includes(user.value),
        }));
        setUsers(newAnalysts);
    };

    const reloadAndClose = () => {
        reload();
        setShowInviteAnalystScreen(false);
    };

    const saveNewUsers = async () => {
        setShowLoadingToast({
            show: true,
            message: "SAVING ADDITIONAL USERS",
        });
        setShowFullUserList(false);

        let newUsers = users?.filter(
            (user: any) => !user.current && (user.invited || user.core)
        );
        let newSession = { ...session.session };
        const newCore = session.core;
        const newAnalysts = session.analysts;
        newUsers.forEach((user: any) => {
            if (user.core) {
                newCore.push({ id: user.id, name: user.name });
            } else {
                newAnalysts.push({ id: user.id, name: user.name });
            }
        });
        newSession.core = newCore;
        newSession.analysts = newAnalysts;
        let res = await Database.put(
            "api/bqmq_voting/users/invite-additional",
            {
                session: newSession,
            }
        );
        const data = await res.json();

        if (data.data.message === "SESSION_CREATED") {
            setToast({
                show: true,
                type: "success",
                message: "Additional Users Invited",
            });
            reloadAndClose();
        } else {
            setToast({
                show: true,
                type: "error",
                message: "Error Inviting Additional Users",
            });
        }

        setShowLoadingToast({
            show: false,
            message: "SAVING ADDITIONAL USERS",
        });
    };

    useEffect(() => {
        getUserList();
    }, []);

    useEffect(() => {
        setFilteredUserList(
            users?.filter(
                (user: any) =>
                    user.name
                        .toLowerCase()
                        .includes(searchString.toLowerCase()) &&
                    (!showOnlyInvited ||
                        (!user.current && (user.invited || user.core)))
            )
        );
    }, [users, searchString, showOnlyInvited]);

    return (
        <Modal>
            <ClickAwayListener
                onClickAway={() => setShowInviteAnalystScreen(false)}
            >
                <InviteAnalystListContainer>
                    <div className="main">
                        <div className="header">
                            <h2 className="title">INVITE ANALYST</h2>

                            <ButtonSmall
                                text={
                                    showOnlyInvited
                                        ? "SHOW ALL"
                                        : "SHOW ONLY NEW INVITES"
                                }
                                icon={faEye}
                                click={() =>
                                    setShowOnlyInvited(!showOnlyInvited)
                                }
                            />
                        </div>

                        <div className="userList">
                            <div className={"searchRow"}>
                                <div className={"searchIcon"}>
                                    <FontAwesomeIcon
                                        icon={faSearch}
                                        fixedWidth
                                    />
                                </div>

                                <input
                                    data-cy="user-search"
                                    className={"searchBox"}
                                    placeholder={"Search Attendees"}
                                    value={searchString}
                                    onChange={(e) =>
                                        setSearchString(e.target.value)
                                    }
                                />
                            </div>

                            {filteredUserList.map((user: any) => (
                                <div
                                    className={"userRow"}
                                    key={user.id}
                                    data-cy="user-row"
                                >
                                    <div
                                        className={
                                            "icon " +
                                            (user.invited ? "invited " : "") +
                                            (user.core ? "core " : "")
                                        }
                                    >
                                        <FontAwesomeIcon
                                            icon={faUser}
                                            fixedWidth
                                        />
                                    </div>

                                    <div
                                        data-cy="invited-user-name"
                                        className={
                                            "name " +
                                            user.invited +
                                            " " +
                                            user.core
                                        }
                                    >
                                        {user.name}

                                        {user.core && (
                                            <div className={"coreTag hideSM"}>
                                                CORE
                                            </div>
                                        )}
                                    </div>

                                    {!user.current && (
                                        <div className={"rowActions"}>
                                            <div
                                                className={
                                                    "actionBtn core " +
                                                    (user.core ? "active" : "")
                                                }
                                                onClick={() =>
                                                    changeValue("core", user.id)
                                                }
                                                data-cy="action-core"
                                            >
                                                CORE
                                            </div>
                                            <div
                                                className={
                                                    "actionBtn all " +
                                                    (user.invited
                                                        ? "active"
                                                        : "")
                                                }
                                                onClick={() =>
                                                    changeValue(
                                                        "invited",
                                                        user.id
                                                    )
                                                }
                                            >
                                                NON-CORE
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))}

                            {users.length === 0 && (
                                <div className="loader">
                                    <LoadingSpinner
                                        message={"LOADING ANALYSTS"}
                                    />
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="actions">
                        <Button
                            text="CANCEL"
                            color="flat"
                            click={() => setShowInviteAnalystScreen(false)}
                        />
                        <Button
                            text="INVITE"
                            color="primary"
                            click={saveNewUsers}
                        />
                    </div>
                </InviteAnalystListContainer>
            </ClickAwayListener>
        </Modal>
    );
}
