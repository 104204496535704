import styled from "styled-components";

export default styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;

    p {
        font-size: 30px;
        color: ${(props) => props.theme.pageSubTitleText};
        letter-spacing: 0.1em;
    }

    svg {
        font-size: 100px;
        margin-bottom: 30px;
        color: ${(props) => props.theme.mainMenuBackground};
    }
`;
