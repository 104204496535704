import { useHistory } from "react-router-dom";

// Utils

// MaterialUI
import { Tooltip } from "@mui/material";

// FontAwesome
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Components
import ModesCell from "../../../Results/ModesCell/ModesCell";

// Data

// Styles
import CompanyResultsRowContainer from "./CompanyResultsRow.styles";

type Props = {
    data: any;
    pendingMyVote?: boolean;
};

export default function CompanyResultsRow({
    data,
    pendingMyVote = false,
}: Props) {
    const history = useHistory();

    type CategoryMap = {
        CONSUMER: string;
        FINANCIALS: string;
        HEALTHCARE: string;
        INDUSTRIALS: string;
        TECH_AND_TELCO: string;
        "TECH_&_TELCO": string;
        "TECH & TELCO": string;
        "HEALTH & LUX": string;
        UNKNOWN: string;
    };

    const teamLabelDict: CategoryMap = {
        CONSUMER: "Consumer",
        FINANCIALS: "Financials",
        HEALTHCARE: "Healthcare",
        INDUSTRIALS: "Industrials",
        TECH_AND_TELCO: "Technology",
        "TECH_&_TELCO": "Technology",
        "TECH & TELCO": "Technology",
        "HEALTH & LUX": "Health & Luxury",
        UNKNOWN: "-",
    };

    const handleActiveCompany = () => {
        history.push(
            `/voting/result/company?company_id=${data.value}&company_name=${data.label}`
        );
    };

    const mode = { BQ: data.bq, MQ: data.mq };
    return (
        <CompanyResultsRowContainer onClick={handleActiveCompany}>
            <div className={"info"}>
                <div data-cy="recent-company" className={"name"}>
                    {data.label.toUpperCase()}
                </div>
                <div className={"details"}>
                    {
                        <Tooltip title={<h2>TEAM</h2>}>
                            <div className={"detail"}>
                                <FontAwesomeIcon icon={faUsers} fixedWidth />

                                <p className={"capitalize"}>
                                    {
                                        teamLabelDict[
                                            data?.sector?.name
                                                ? (data.sector.name.toUpperCase() as keyof CategoryMap)
                                                : "UNKNOWN"
                                        ]
                                    }
                                </p>
                            </div>
                        </Tooltip>
                    }
                </div>
            </div>
            <ModesCell mode={mode} />
        </CompanyResultsRowContainer>
    );
}
