import styled from "styled-components";

export default styled.div`
    display: flex;
    flex-direction: column;

    min-width: 50%;
    max-width: 50%;

    margin-bottom: 50px;

    padding: 10px;

    .cardInner {
        display: flex;
        flex-direction: column;
        align-items: center;

        min-width: 100%;
        max-width: 100%;
        //min-height: 100%;
        //max-height: 100%;

        border-radius: 5px;

        overflow: hidden;

        border: 1px solid #efefef;

        margin-bottom: 20px;

        .title {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            flex-grow: 1;

            text-align: center;
            font-size: 25px;
            padding-right: 10px;

            width: 100%;

            background-color: ${(props) =>
                props.theme.homeListTitleRowBackground};
            color: ${(props) => props.theme.homeListTitleRowText};

            min-height: 60px;
            max-height: 60px;

            border-radius: 5px 5px 0 0;

            .left {
                display: flex;
                flex-direction: row;
                align-items: center;
                font-size: 1.3rem;

                color: ${(props) => props.theme.homeListTitleRowText};

                .mode {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    min-height: 60px;
                    max-height: 60px;

                    min-width: 50px;

                    margin-right: 10px;
                    padding: 0 15px;

                    color: black;

                    &.score-1 {
                        background-color: ${(props) =>
                            props.theme.bqmqVoteScore1};
                    }
                    &.score-2 {
                        background-color: ${(props) =>
                            props.theme.bqmqVoteScore2};
                    }
                    &.score-3 {
                        background-color: ${(props) =>
                            props.theme.bqmqVoteScore3};
                    }
                    &.score-4 {
                        background-color: ${(props) =>
                            props.theme.bqmqVoteScore4};
                    }
                    &.score-5 {
                        background-color: ${(props) =>
                            props.theme.bqmqVoteScore5};
                    }

                    &.score-AGREE {
                        background-color: ${(props) =>
                            props.theme.bqmqVoteScore1};
                    }
                    &.score-NEUTRAL {
                        background-color: ${(props) =>
                            props.theme.bqmqVoteScore3};
                    }
                    &.score-DISAGREE {
                        background-color: ${(props) =>
                            props.theme.bqmqVoteScore5};
                    }
                }
            }

            svg {
                cursor: pointer;
                font-size: 20px;
                margin-left: 10px;
            }

            &.dropdown {
                cursor: pointer;

                min-height: 30px;
                max-height: 30px;

                border-radius: 0 0 5px 5px;
                font-size: 20px;

                padding-left: 10px;

                &.true {
                    border-radius: 0;
                }

                :hover {
                    background-color: ${(props) =>
                        props.theme.primaryButtonBackgroundHover};
                }
            }

            .tableModeBtn {
                display: flex;
                font-size: 16px;
                cursor: pointer;

                padding: 5px 10px;
                margin: 5px 10px;

                border-radius: 5px;

                background-color: #dddddd;
                color: #004453;

                :hover {
                    background-color: #bbbbbb;
                }
            }
        }

        .comments {
            width: 100%;
            padding: 20px 20px 0 20px;
        }
    }

    @media screen and (max-width: 1382px) {
        min-width: 100%;
        max-width: 100%;
    }
`;
