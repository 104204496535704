// Styles
import { useEffect } from "react";
import ItemContainer from "./Item.styles";

type Props = {
    item: any;
};

export default function Item({ item }: Props) {
    const goToHeading = () => {
        let body = document.getElementById("docs");
        if (body) {
            body.scrollTo({
                top: item.scroll - 80,
                behavior: "smooth",
            });
        }
    };

    return (
        <ItemContainer onClick={goToHeading}>
            <p className={item.visible ? "item active" : "item"}>
                {item.title}
            </p>
        </ItemContainer>
    );
}
