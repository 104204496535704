import SearchOptions from "components/Common/SearchOptions/SearchOptions";
import Table from "components/Common/Table/Table";
import TableCell from "components/Common/TableCell/TableCell";
import EditableCell from "components/Screens/Admin/ValidationRules/EditableCell/EditableCell";
import React, { useEffect, useState } from "react";
import * as models from "components/Screens/DailyRecs/DailyFlowsCheck/AlertsTab/models";

import BaseTabContainer from "./_BaseTab.styles";

// Utils
import * as Database from "../../../../../utils/database.utils";

// Styles
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowRight,
    faCheckCircle,
    faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import {formatNumberFixDecimal} from "../../../../../utils/common.utils";
import {AlertFormPf} from "components/Screens/DailyRecs/DailyFlowsCheck/AlertsTab/models";

interface BaseTabProps {
    checkName: string;
    baseUrl: string;
    tableType: "alert" | "log";
    filterQuery?: string;
}

const PAGE_LIMIT = 25;

const BaseTab = ({
    tableType,
    checkName,
    baseUrl,
    filterQuery = "",
}: BaseTabProps) => {
    const selectAlertModel = () => {
        let alertModel: any = null;

        if (checkName == "daily_flows") {
            alertModel = models.AlertDailyFlow;
        } else if (checkName == "daily_movements") {
            alertModel = models.AlertDailyMovements;
        } else if (checkName == "broker_exposures") {
            alertModel = models.AlertBrokerExposures;
        } else if (checkName == "hedging_restrictions") {
            alertModel = models.AlertHedgingRestrictions;
        } else if (checkName == "leverage_collateral") {
            alertModel = models.AlertLeverageCollateral;
        } else if (checkName == "cr_nt_nav") {
            alertModel = models.AlertCRNTNav;
        } else if (checkName == "cr_nt_cash_stock") {
            alertModel = models.AlertCRNTCashStock;
        } else if (checkName == "currency_exposure_checks") {
            alertModel = models.AlertCurrencyExposure;
        } else if (checkName == "forwards") {
            alertModel = models.AlertForwards;
        } else if (checkName == "ndf_exposures") {
            alertModel = models.AlertNDFExposure;
        } else if (checkName == "emir") {
            alertModel = models.AlertEmir;
        } else if (checkName == "form_pf_adverse_return") {
            alertModel = models.AlertFormPf;
        } else if (checkName == "form_pf_adverse_collateral") {
            alertModel = models.AlertAdverseCollateral;
        } else if (checkName == "form_pf_adverse_redemption") {
            alertModel = models.AlertAdverseRedemption;
        }

        return alertModel;
    };

    let alertType: any = selectAlertModel();

    const [alerts, setAlerts] = useState<typeof alertType[]>([]);
    const [total, setTotal] = useState<number | undefined>(undefined);
    const [loading, setLoading] = useState(true);
    const [reload, setReload] = useState(0);
    const [tableIsLoading, setTableIsLoading] = useState<boolean>(false);

    const fetchPage = async (query: string | null = null) => {
        setLoading(true);

        if (!baseUrl) {
            // parent doesn't set it yet
            return;
        }

        let url = baseUrl;

        if (filterQuery) {
            let date_string = filterQuery.substring(
                filterQuery.indexOf("=") + 1,
                filterQuery.lastIndexOf("00:") - 4
            );
            let date_formatted = new Date(date_string)
                .toISOString()
                .slice(0, 10);

            url += `${date_formatted}/`;
        }

        if (!query) {
            // default params when the page load
            query = `?pageSize=${PAGE_LIMIT}&page=1`;
        }

        url += query;

        await getAlerts(url);
    };

    const getAlerts = async (url: string) => {
        // get the reasons
        let response = await Database.get(url);

        if (response.ok) {
            let resp = await response.json();

            setLoading(false);

            setTotal(resp.total);

            let alerts = [];

            for (const d of resp.data) {
                alerts.push(new alertType(d));
            }

            setAlerts(alerts);
        }
    };

    const isShowColumn = (prop: string) => {
        // show the column if the property exist on the alert type

        if (prop === "report_date" && tableType === "log") {
            // for logs we show the report date column
            return true;
        }

        return Object.keys(new alertType()).indexOf(prop) > -1;
    };

    useEffect(() => {
        fetchPage();
    }, [baseUrl, reload, filterQuery]);

    const columns = [
        {
            id: 6493549733,
            title: "DATE",
            sortable: false,
            value: "report_date",
            cell: (data: any) =>
                new Date(data.report_date).toLocaleDateString(),
            show: isShowColumn("report_date"),
            fullDataRow: true,
        },
        {
            id: 3657887587,
            title: "ANALYST NAME",
            sortable: false,
            value: "ioo_acct",
            cell: (data: any) => <TableCell data={data.timestamp} />,
            show: isShowColumn("timestamp"),
            fullDataRow: true,
        },
        {
            id: 3657887587,
            title: "ANALYST NAME",
            sortable: false,
            value: "ioo_acct",
            cell: (data: any) => <TableCell data={data.analyst_name} />,
            show: isShowColumn("analyst_name"),
            fullDataRow: true,
        },
        {
            id: 5464593885,
            title: "ACCOUNT",
            sortable: false,
            value: "account_id",
            cell: (data: any) => (
                <div>
                    {data.account_number && (
                        <span className={"badge"}>{data.account_number}</span>
                    )}
                    {data.acc_name}
                </div>
            ),
            show: isShowColumn("account_number") || isShowColumn("acc_name"),
            fullDataRow: true,
        },
        {
            id: 3657887587,
            title: "CHECK NAME",
            sortable: false,
            value: "check_name",
            cell: (data: any) => <TableCell data={
                data.check_name == "bm_exposure_check" ? "BM Exposure" : "Fund Exposure"
            } />,
            show: isShowColumn("check_name"),
            fullDataRow: true,
        },
        {
            id: 3657887586,
            title: "TOTAL PERCENTAGE",
            sortable: false,
            value: "total_percentage",
            cell: (data: any) => <TableCell data={formatNumberFixDecimal(data.total_percentage)} />,
            show: isShowColumn("total_percentage"),
            fullDataRow: true,
        },
        {
            id: 3657887589,
            title: "PRICE",
            sortable: false,
            value: "price",
            cell: (data: any) => <TableCell data={formatNumberFixDecimal(data.price)} />,
            show: isShowColumn("price"),
            fullDataRow: true,
        },
        {
            id: 3657887590,
            title: "A AST MV BSE",
            sortable: false,
            value: "a_ast_mv_bse",
            cell: (data: any) => <TableCell data={formatNumberFixDecimal(data.a_ast_mv_bse)} />,
            show: isShowColumn("a_ast_mv_bse"),
            fullDataRow: true,
        },
        {
            id: 3657887591,
            title: "MONETARY DIFF",
            sortable: false,
            value: "monetary_dff",
            cell: (data: any) => <TableCell data={formatNumberFixDecimal(data.monetary_dff)} />,
            show: isShowColumn("monetary_dff"),
            fullDataRow: true,
        },
        {
            id: 3657887592,
            title: "BPS DIFF",
            sortable: false,
            value: "bps_dff",
            cell: (data: any) => <TableCell data={formatNumberFixDecimal(data.bps_dff, 0)} />,
            show: isShowColumn("bps_dff"),
            fullDataRow: true,
        },
        {
            id: 3657887594,
            title: "STOCK BREAK",
            sortable: false,
            value: "stock_break",
            cell: (data: any) => <TableCell data={data.stock_break} />,
            show: isShowColumn("stock_break"),
            fullDataRow: true,
        },
        {
            id: 3657887596,
            title: "BPS DISPERSION",
            sortable: false,
            value: "bps_dispersion",
            cell: (data: any) => <TableCell data={formatNumberFixDecimal(data.bps_dispersion, 0)} />,
            show: isShowColumn("bps_dispersion"),
            fullDataRow: true,
        },
        {
            id: 3657887597,
            title: "CASHFLOW FORECAST",
            sortable: false,
            value: "cashflow_forecast",
            cell: (data: any) => <TableCell data={formatNumberFixDecimal(data.cashflow_forecast)} />,
            show: isShowColumn("cashflow_forecast"),
            fullDataRow: true,
        },
        {
            id: 3657887587,
            title: "IOO ACCOUNT",
            sortable: false,
            value: "ioo_acct",
            cell: (data: any) => <TableCell data={data.ioo_acct} />,
            show: isShowColumn("ioo_acct"),
            fullDataRow: true,
        },
        {
            id: 6688954439,
            title: "DEVIATION",
            sortable: false,
            value: "deviation",
            cell: (data: any) => <TableCell data={formatNumberFixDecimal(data.deviation)} />,
            show: isShowColumn("deviation"),
            fullDataRow: true,
        },
        {
            id: 5368593655,
            title: "DEVIATION FROM MEDIAN",
            sortable: false,
            value: "deviation_from_median",
            cell: (data: any) => (
                <TableCell data={formatNumberFixDecimal(data.deviation_from_median)} />
            ),
            show: isShowColumn("deviation_from_median"),
            fullDataRow: true,
        },
        {
            id: 7438739973,
            title: "ABS FWD NOTIONAL EXPOSURE",
            sortable: false,
            value: "abs_fwd_notional_exposure",
            cell: (data: any) => (
                <TableCell data={formatNumberFixDecimal(data.abs_fwd_notional_exposure)} />
            ),
            show: isShowColumn("abs_fwd_notional_exposure"),
            fullDataRow: true,
        },
        {
            id: 55546749544,
            title: "FWD GAIN LOSS EXPOSURE",
            sortable: false,
            value: "fwd_gain_loss_exposure",
            cell: (data: any) => (
                <TableCell data={formatNumberFixDecimal(data.fwd_gain_loss_exposure)} />
            ),
            show: isShowColumn("fwd_gain_loss_exposure"),
            fullDataRow: true,
        },
        {
            id: 55546749545,
            title: "ABS NOTIONAL EXP",
            sortable: false,
            value: "abs_notional_exp",
            cell: (data: any) => (
                <TableCell data={formatNumberFixDecimal(data.abs_notional_exp)} />
            ),
            show: isShowColumn("abs_notional_exp"),
            fullDataRow: true,
        },
        {
            id: 55546749546,
            title: "EUR FORWARD EXPOSURE",
            sortable: false,
            value: "eur_forward_exposure",
            cell: (data: any) => (
                <TableCell data={formatNumberFixDecimal(data.eur_forward_exposure)} />
            ),
            show: isShowColumn("eur_forward_exposure"),
            fullDataRow: true,
        },
        {
            id: 55546749547,
            title: "NDF EXPOSURE",
            sortable: false,
            value: "ndf_exposure",
            cell: (data: any) => (
                <TableCell data={formatNumberFixDecimal(data.ndf_exposure)} />
            ),
            show: isShowColumn("ndf_exposure"),
            fullDataRow: true,
        },
        {
            id: 55546749548,
            title: "AGG MARGIN",
            sortable: false,
            value: "agg_margin",
            cell: (data: any) => (
                <TableCell data={formatNumberFixDecimal(data.agg_margin, 4)} />
            ),
            show: isShowColumn("agg_margin"),
            fullDataRow: true,
        },
        {
            id: 55546749549,
            title: "NDF EXPOSURE 2",
            sortable: false,
            value: "ndf_exposure_2",
            cell: (data: any) => (
                <TableCell data={formatNumberFixDecimal(data.ndf_exposure_2)} />
            ),
            show: isShowColumn("ndf_exposure_2"),
            fullDataRow: true,
        },
        {
            id: 8433739973,
            title: "BROKER",
            sortable: false,
            value: "broker",
            cell: (data: any) => <TableCell data={data.broker} />,
            show: isShowColumn("broker"),
            fullDataRow: true,
        },
        {
            id: 23567453979,
            title: "10 DAYS NAV",
            sortable: false,
            value: "ten_day_nav",
            cell: (data: any) => <TableCell data={formatNumberFixDecimal(data.ten_day_nav)} />,
            show: isShowColumn("ten_day_nav"),
            fullDataRow: true,
        },
        {
            id: 3719733979,
            title: "CURRENT NAV",
            sortable: false,
            value: "current_nav",
            cell: (data: any) => <TableCell data={formatNumberFixDecimal(data.current_nav)} />,
            show: isShowColumn("current_nav"),
            fullDataRow: true,
        },
        {
            id: 5536733979,
            title: "ROLLING CALCULATION",
            sortable: false,
            value: "rolling_calc",
            cell: (data: any) => <TableCell data={formatNumberFixDecimal(data.rolling_calc)} />,
            show: isShowColumn("rolling_calc"),
            fullDataRow: true,
        },
        {
            id: 34349773435,
            title: "FX GL EXP",
            sortable: false,
            value: "chk2_fx_gl_exp",
            cell: (data: any) => (
                <div className={data.ok ? "green" : "red"}>
                    <FontAwesomeIcon
                        className="checkIcon"
                        icon={
                            data.chk2_fx_gl_exp ? faCheckCircle : faTimesCircle
                        }
                        fixedWidth
                    />
                </div>
            ),
            show: isShowColumn("chk2_fx_gl_exp"),
            fullDataRow: true,
        },
        {
            id: 64544596674,
            title: "NOTIONAL EXP",
            sortable: false,
            value: "chk1_notional_exp",
            cell: (data: any) => (
                <div className={data.ok ? "green" : "red"}>
                    <FontAwesomeIcon
                        className="checkIcon"
                        icon={
                            data.chk1_notional_exp
                                ? faCheckCircle
                                : faTimesCircle
                        }
                        fixedWidth
                    />
                </div>
            ),
            show: isShowColumn("chk1_notional_exp"),
            fullDataRow: true,
        },
        {
            id: 46353799369,
            title: "CURRENCY CODE",
            sortable: false,
            value: "currencyCode",
            cell: (data: any) => <TableCell data={data.currencyCode} />,
            show: isShowColumn("currencyCode"),
            fullDataRow: true,
        },
        {
            id: 75346877583,
            title: "NET REL EXP",
            sortable: false,
            value: "net_rel_exp",
            cell: (data: any) => (
                <TableCell data={formatNumberFixDecimal(data.net_rel_exp)} />
            ),
            show: isShowColumn("net_rel_exp"),
            fullDataRow: true,
        },
        {
            id: 15846567587,
            title: "REDEMPTION CHECK",
            sortable: false,
            value: "redemption_check",
            cell: (data: any) => (
                <TableCell data={formatNumberFixDecimal(data.redemption_check)} />
            ),
            show: isShowColumn("redemption_check"),
            fullDataRow: true,
        },
        {
            id: 56788663948,
            title: "",
            sortable: false,
            value: "check1",
            cell: (data: any) => (
                <div className={data.check1 ? "red" : "green"}>
                    <FontAwesomeIcon
                        className="checkIcon"
                        icon={data.check1 ? faCheckCircle : faTimesCircle}
                        fixedWidth
                    />
                </div>
            ),
            show: isShowColumn("check1"),
            fullDataRow: true,
        },
        {
            id: 567885863948,
            title: "",
            sortable: false,
            value: "check2",
            cell: (data: any) => (
                <div className={data.check2 ? "red" : "green"}>
                    <FontAwesomeIcon
                        className="checkIcon"
                        icon={data.check2 ? faCheckCircle : faTimesCircle}
                        fixedWidth
                    />
                </div>
            ),
            show: isShowColumn("check2"),
            fullDataRow: true,
        },
        {
            id: 47456893993,
            title: "REASON FOR DISCREPANCY",
            sortable: false,
            value: "changelog.reason",
            cell: (data: any) => (
                <>
                    {tableType === "alert" && (
                        <EditableCell
                            apiMethod="post"
                            rowId={data.id}
                            cellValue="reason"
                            cellValueType="text"
                            rowIdKey={"data_id"}
                            initialValue={data.changelog?.reason}
                            type="textField"
                            apiUrl={`api/nav/alerts/${checkName}/${data.report_date
                                .toISOString()
                                .slice(0, 10)}/notes/`}
                            setTableIsLoading={setTableIsLoading}
                            updateTable={() => setReload(reload + 1)}
                            tableIsLoading={tableIsLoading}
                        />
                    )}

                    {tableType === "log" && data.changelog?.reason}
                </>
            ),
            show: isShowColumn("changelog"),
            fullDataRow: true,
        },
    ];

    const filterOptions = [
        {
            id: 1,
            name: "Select Date",
            value: "date",
            date: "date",
            type: "SINGLEDATE",
        },
    ];

    return (
        <BaseTabContainer>
            <div className="tableDiv">
                <Table
                    total={total}
                    filterQuery={filterQuery}
                    columns={columns}
                    defaultSortKey="acc_number"
                    defaultLimit={PAGE_LIMIT}
                    data={alerts}
                    pagination
                    loading={loading}
                    changePageQuery={(query: string) => fetchPage(query)}
                />
            </div>
        </BaseTabContainer>
    );
};

export default BaseTab;
