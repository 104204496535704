import { useState } from "react";

// Components
import ColumnSelectModal from "./ColumnSelectModal/ColumnSelectModal";
import Table from "components/Common/Table/Table";
import SearchOptions from "components/Common/SearchOptions/SearchOptions";

// Data
import { dataDictionaryColumns } from "assets/data/dataDictionary";
import { wsOptions, excelTableOptions } from "assets/data/lists";

// Styles
import DataDictionaryContainer from "./DataDictionary.styles";
import Button from "../../../../Common/Button/Button";

export default function DataDictionary() {
    const [columns, setColumns] = useState<Column[]>(dataDictionaryColumns);

    const [showColumnSelectModal, setShowColumnSelectModal] = useState<boolean>(
        false
    );

    const [filterQuery, setFilterQuery] = useState<string>("");

    const filterOptions = [
        {
            id: 1,
            name: "Search NAME DB or NAME RAW",
            value: "search",
            type: "SEARCH",
        },
        {
            id: 2,
            name: "WS",
            value: "ws",
            type: "DROPDOWN",
            options: wsOptions,
        },
        {
            id: 3,
            name: "Table",
            value: "excel_table",
            type: "DROPDOWN",
            options: excelTableOptions,
        },
    ];

    return (
        <DataDictionaryContainer>
            <div className="searchBar">
                <SearchOptions
                    setFilterQuery={setFilterQuery}
                    options={filterOptions}
                />

                <div className={"actionBtns"}>
                    <Button
                        text={"FIELDS"}
                        click={() => setShowColumnSelectModal(true)}
                        color={"accent"}
                    />
                </div>
            </div>

            <Table
                url={"api/data_dictionary"}
                columns={columns}
                filterQuery={filterQuery}
                defaultSortKey="metric_name_db"
                pagination
            />

            {showColumnSelectModal && (
                <ColumnSelectModal
                    columns={columns}
                    setColumns={setColumns}
                    setShowColumnSelectModal={setShowColumnSelectModal}
                />
            )}
        </DataDictionaryContainer>
    );
}
