import styled from "styled-components";
import colors from "assets/styles/colors";

export default styled.div`
    width: 100%;
    height: 100%;

    .notApplicableCell {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        border: 1px solid #dddddd;
        border-radius: 5px;
        color: ${colors.teal100};
        padding: 10px;
        width: 100%;
        height: 100%;
    }
    .cellWrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border: 1px solid #dddddd;
        border-radius: 5px;
        padding: 10px;
        width: 100%;
        height: 100%;

        &.disabled {
            background: white;
            opacity: 0.5;
            pointer-events: none;
        }
    }

    .inputField {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        div {
            margin-bottom: 0px;

            .textField {
                margin-right: 10px;
            }
        }

        .actions {
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        .icon-button {
            cursor: pointer;
            margin-left: 10px;
            &.close {
                transform: rotate(45deg);
            }
        }

        .Info {
            color: ${colors.accentBlue};
        }

        .Warning {
            color: ${colors.accentOrange};
        }

        .Critical {
            color: ${colors.accentRed};
        }
    }
`;
