import React, { useEffect, useState } from "react";

// Styles
import EditDealTeamsContainer from "./EditDealTeams.styles";

// Types
import {DealTeam} from "assets/interfaces/growth_voting";

//Enums
import { EnumGrowthVotingTeams } from "assets/enums/growth_voting";

// Services
import {ApiHandler} from "services/api_handler";

//Utils
import { getTeamNameByType } from "utils/growth_voting.utils";

// Components
import EditDealTeam from "./EditDealTeam/EditDealTeam";
import LoadingSpinner from "components/Common/LoadingSpinner/LoadingSpinner";


const URL_USERS_LIST = "api/growth_voting/users";

interface Props {
    teams: DealTeam[]
    setTeams: React.Dispatch<React.SetStateAction<DealTeam[]>>
}

const EditDealTeams  = ({teams, setTeams}: Props) => {
    const [dealTeam, setDealTeam] = useState<DealTeam>({type: EnumGrowthVotingTeams.Deal, members: []});
    const [voteTeam, setVoteTeam] = useState<DealTeam>({type: EnumGrowthVotingTeams.Vote, members: []});
    const [quicTeam, setQuicTeam] = useState<DealTeam>({type: EnumGrowthVotingTeams.Quic, members: []});
    const [leadTeam, setLeadTeam] = useState<DealTeam>({type: EnumGrowthVotingTeams.Lead, members: []});
    const [loading, setLoading] = useState<boolean>(true);

    const createVotingTeam = async ()  => {
        // set all Growth users as a voters as default
        let response = await new  ApiHandler(URL_USERS_LIST).list();

        let adUsers = response.data;

         for (let i = 0; i < adUsers.length; i++) {
            adUsers[i]["user_id"] = adUsers[i].id;
            delete adUsers[i]["id"]
        }

        let votingTeam = {
            name: "Vote Team",
            type: EnumGrowthVotingTeams.Vote,
            members: response.data,
        };

        return votingTeam
    }

    const getTeam = async (type: string) => {
        let team = teams.filter((team: DealTeam) => team.type === type);

        if (team.length == 1) {
            return team[0];
        }

        if (type == EnumGrowthVotingTeams.Vote) {
            return await createVotingTeam();
        } else {
            let name = getTeamNameByType(type);

            let newTeam: DealTeam = {
                type: type,
                members: [],
            };

            return newTeam;
        }

    }

    const setupTeams = async () => {
        setDealTeam(await getTeam(EnumGrowthVotingTeams.Deal));
        setVoteTeam(await getTeam(EnumGrowthVotingTeams.Vote));
        setQuicTeam(await getTeam(EnumGrowthVotingTeams.Quic));
        setLeadTeam(await getTeam(EnumGrowthVotingTeams.Lead));

        setLoading(false);
    }

    useEffect(() => {
        setTeams([voteTeam, dealTeam, quicTeam, leadTeam]);
    }, [voteTeam, dealTeam, quicTeam, leadTeam])

    useEffect(() => {
        setupTeams();
    }, [])

    return (
        <EditDealTeamsContainer>
            {loading && <div className={"loading"}><LoadingSpinner /></div>}
            {!loading && <>
                <div className={"team"}>
                    <EditDealTeam key={"deal-team"} team={dealTeam} setTeam={setDealTeam}/>
                </div>
                <div className={"team"}>
                    <EditDealTeam key={"quic-team"} team={quicTeam} setTeam={setQuicTeam}/>
                </div>
                <div className={"team"}>
                    <EditDealTeam key={"lead-team"} team={leadTeam} setTeam={setLeadTeam}/>
                </div>
                <div className={"team"}>
                    <EditDealTeam key={"vote-team"} team={voteTeam} setTeam={setVoteTeam}/>
                </div>
            </>}
        </EditDealTeamsContainer>
    );
};

export default EditDealTeams
