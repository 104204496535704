import { useEffect, useState } from "react";

// Components
import ResultCell from "../ResultCell/ResultCell";

// Context
import { useBQMQ } from "context/BQMQ.context";

// Styles
import ModesCellContainer from "./ModesCell.styles";

type Props = {
    mode: Mode;
    noLabels?: boolean;
};

export default function ModesCell({ mode, noLabels = false }: Props) {
    const { allFactorsList } = useBQMQ();

    const [results, setResults] = useState<ModeDetail[]>([]);

    useEffect(() => {
        if (mode && allFactorsList) {
            let newResults: ModeDetail[] = [];
            Object.entries(mode).forEach(
                ([factor, mode]: [
                    factor: string,
                    mode: number | string | null
                ]) => {
                    newResults.push({
                        mode: mode,
                        factor_label: allFactorsList[factor]?.name,
                        factorID: factor,
                    });
                }
            );
            setResults(newResults);
        }
    }, [mode, allFactorsList]);

    return (
        <ModesCellContainer className={"modesCell"}>
            {results.length
                ? results.map((mode: ModeDetail) => (
                      <div className={"modeCell"} key={mode.factorID}>
                          {!noLabels && (
                              <p className={"label"}>{mode.factorID}</p>
                          )}
                          <ResultCell value={mode.mode} />
                      </div>
                  ))
                : [
                      { factorID: "BQ", mode: "-" },
                      { factorID: "MQ", mode: "-" },
                  ].map((mode: ModeDetail) => (
                      <div className={"modeCell"} key={mode.factorID}>
                          {!noLabels && (
                              <p className={"label"}>{mode.factorID}</p>
                          )}
                          <ResultCell value={mode.mode} />
                      </div>
                  ))}
        </ModesCellContainer>
    );
}
