import { useEffect, useState } from "react";

// Styles
import AddDailyFlowModalContainer from "./AddDailyFlowModal.styles";

// Utils
import { useSettings } from "context/Settings.context";
import * as Database from "utils/database.utils";

// Components
import Button from "components/Common/Button/Button";
import Modal from "components/Common/Modal/Modal";
import { DailyFlowValues } from "screens/DailyRecs/Inputs/DailyFlows/types";
import DailyFlowInputFields from "../DailyFlowInputFields/DailyFlowInputFields";

interface AddDailyFlowModalProps {
    setShowModal: Function;
    runUpdate: Function;
}

function getYesterdayDate() {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 1); // Subtract one day
    return currentDate.toISOString().split("T")[0];
}

const yesterday = getYesterdayDate()

const AddDailyFlowModal = ({
    setShowModal,
    runUpdate,
}: AddDailyFlowModalProps) => {
    const [newDailyFlowValues, setNewDailyFlowValues] =
        useState<DailyFlowValues>({
            account_number: "",
            flow_date_dt: undefined,
            redemptions: 0,
            man_fees: 0,
            perf_fees: 0,
            other: 0,
        });

    const [loading, setLoading] = useState<boolean>(false);
    const [selectedAccount, setSelectedAccount] = useState<string|undefined>(undefined);
    const [selectedFlowDate, setSelectedFlowDate] = useState<Date|undefined>(undefined);
    const { setToast, setShowLoadingToast } = useSettings();
    const [accountNames, setAccountNames] = useState<
        {
            gen_acct: string;
            account_name: string;
        }[]
    >([]);

    const handleChange = (value: string | number, name: string) => {
        // save dates from stringify
        let tempFlowDt = newDailyFlowValues["flow_date_dt"];

        let temp = JSON.parse(JSON.stringify(newDailyFlowValues));

        temp["flow_date_dt"] = tempFlowDt;
        temp[name] = value;
        setNewDailyFlowValues(temp);
    };

    const handleDateChange = (name: string, date: Date) => {
        setNewDailyFlowValues({
            ...newDailyFlowValues,
            [name]: date,
        });
    };

    const handleAccountChange = (account_number: string) => {
        setSelectedAccount(account_number);

        setNewDailyFlowValues({
            ...newDailyFlowValues,
            account_number: account_number
        });
    };

    const handleFlowDateChange = (flow_date_dt: Date) => {
        setSelectedFlowDate(flow_date_dt);

        setNewDailyFlowValues({
            ...newDailyFlowValues,
            flow_date_dt: flow_date_dt,
        });
    };

    const validAddDailyFlow = async (values: DailyFlowValues) => {
        if (!values.account_number) {
            setToast({
                show: true,
                type: "error",
                message: "Please select an Account",
            });

            return false;
        }

        if (!values.flow_date_dt) {
            setToast({
                show: true,
                type: "error",
                message: "Please select a Flow Date.",
            });

            return false;
        }

        return true;
    }

    const mimicISOString = (date: Date) => {
        // we have to make sure that the selected day won't change because any timezone conversion!
        // to avoid they day change because of timezones we create the iso string manually
        // Note: the react date selector return a date where the hours are zero this means when use .toISOString we lose a day
        let month = '' + (date.getMonth() + 1);
        let day = '' + date.getDate();
        let year = date.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    const handleAddDailyFlow = async (values: DailyFlowValues) => {
        const isValid = await validAddDailyFlow(values);

        if (!isValid) {
            return;
        }

        setShowLoadingToast({
            show: true,
            message: "UPDATING",
        });

        const parsedValues: any = { ...values };

        // transform dates
        parsedValues.flow_date_dt =  mimicISOString(parsedValues.flow_date_dt);

        let response = await Database.post(
            "api/nav/user_inputs/daily_flows/",
            parsedValues
        );
        if (response.ok) {
            setShowLoadingToast({
                show: false,
                message: "",
            });
            setToast({
                type: "success",
                show: true,
                message: "Updating daily flow for " + values.account_number,
            });
            runUpdate();
            setShowModal(false);
        }
        setShowLoadingToast({
            show: false,
            message: "",
        });
    };

    const getAccountNames = async () => {
        let response = await Database.get("api/nav/lookups/accounts");
        if (response.ok) {
            let data = await response.json();
            setAccountNames(data.data);
        }
    };

    const fetchPreviousFlowData = async () => {
        if (!selectedAccount || !selectedFlowDate) {
            // both have to be selected to look for the data
            return
        }

         setShowLoadingToast({
            show: true,
            message: "Loading",
        });

        let response = await Database.get(`api/nav/user_inputs/daily_flows/${selectedAccount}/${mimicISOString(selectedFlowDate)}/`);

        let data = null;

        if (response.ok) {
            data = await response.json();

             setToast({
                show: true,
                message: "Input fields are populated based on the latest Daily Flow.",
                type: "success",
            });
        } else {
            if (response.status === 404) {
                // Daily Flow not found, unset values
                data = {
                    man_fees: 0,
                    other: 0,
                    perf_fees: 0,
                    redemptions: 0,
                    account_number: selectedAccount,
                    flow_date_dt: selectedFlowDate,
                }
            }
        }

        setShowLoadingToast({
            show: false,
        });

        prePopulateDailyFlowValues(data);
    };

    const prePopulateDailyFlowValues = (data: DailyFlowValues) => {
        setNewDailyFlowValues({
            ...newDailyFlowValues,
            man_fees: data.man_fees,
            other: data.other,
            perf_fees: data.perf_fees,
            redemptions: data.redemptions,
        });
    };

    useEffect(() => {
        getAccountNames();
    }, []);

    useEffect(() => {
        fetchPreviousFlowData();
    }, [selectedAccount, selectedFlowDate]);


    return (
        <AddDailyFlowModalContainer>
            <Modal loading={loading} width={"58vw"}>
                <div className="main">
                    <div className="text">
                        <h3>NEW DAILY FLOW</h3>
                    </div>
                    <DailyFlowInputFields
                        accountNames={accountNames}
                        handleDateChange={handleDateChange}
                        handleChange={handleChange}
                        handleFlowDateChange={handleFlowDateChange}
                        handleAccountChange={handleAccountChange}
                        dailyFlowsValues={newDailyFlowValues}
                    />
                </div>

                <div className="actions">
                    <Button
                        text="CANCEL"
                        color="flat"
                        click={() => setShowModal(false)}
                    />
                    <Button
                        text="SAVE"
                        click={() => handleAddDailyFlow(newDailyFlowValues)}
                    />
                </div>
            </Modal>
        </AddDailyFlowModalContainer>
    );
};
export default AddDailyFlowModal;
