import styled from "styled-components";
import colors from "assets/styles/colors";

export default styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    p {
        color: ${colors.textTeal};
        letter-spacing: 0.1em;
    }

    .spinner {
        display: flex;
        width: 30px;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;

        svg {
            animation: spin 1s linear infinite;
            font-size: 20px;
            color: ${colors.teal100};
        }
    }
`;
