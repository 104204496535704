import styled from "styled-components";
import colors from "assets/styles/colors";

export default styled.div`
    .menu {
        position: sticky;
        top: 0;
        display: flex;
        flex-direction: column;

        height: fit-content;
    }
`;
