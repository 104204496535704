import styled from "styled-components";

export default styled.div`
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 150px);

    h4 {
        font-size: 20px;
        margin-bottom: 20px;
    }

    .loadingScreen {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 1;

        min-height: 200px;
    }

    .userList {
        display: flex;
        flex-direction: column;

        overflow-y: scroll;

        .userRow {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            min-height: 40px;
            max-height: 40px;

            padding: 0 15px;

            border-bottom: 1px solid #efefef;

            cursor: pointer;

            svg {
                color: #004453;
            }

            :hover {
                background-color: #efefef;
            }

            :last-child {
                border-bottom: none;
            }
        }
    }
`;
