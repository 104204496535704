import styled from 'styled-components';

export default styled.div`
    .title {
        font-size: 1.2rem;
        text-align: center;
        letter-spacing: 0.1em;
        padding: 20px 0px;
        color: ${(props) => props.theme.pageTitleText};
    }

    .flow-title {
       padding: 10px 0px;
    }

    .label {
        padding: 4px 6px;
        border-radius: 5px;
    }

    .current {
        background-color: ${(props) => props.theme.labelSuccessBg};
        color: ${(props) => props.theme.labelSuccessColor};
    }

    .future {
        border: 1px solid  ${(props) => props.theme.labelSecondaryBg};
        color: ${(props) => props.theme.labelSecondaryBg};
    }
`;
