import styled from "styled-components";
import colors from "assets/styles/colors";

export default styled.div`
    flex-direction: column;
    display: flex;
    flex-grow: 1;

    .newCompanyCount {
        border-radius: 5px;
        background-color: ${colors.accentRed};
        padding: 3px 7px;
        margin-left: 10px;
        color: white;
        font-weight: bolder;
    }
`;
