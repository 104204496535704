export const isBusinessDay = (dt: Date): boolean => {
    // check if the day is a business day
    let day = dt.getDay();

    if(day == 0 || day == 6  ) {
        return false;
    }

    return true;
}

export const getLastBusinessDay = (dt?: Date): Date => {
    // return the last business day if you pass a weekend otherwise return the day
    if (!dt) {
        dt = new Date();
    }

    while (!isBusinessDay(dt)) {
        dt.setDate(dt.getDate() - 1)
    }

    return dt;
}

export const isSameDay = (date1: Date, date2: Date): boolean => {
    if (date1.toDateString() === date2.toDateString()) {
       return true;
    }

    return false;
}

export const isPastDate = (baseDate: Date, date: Date): boolean => {
    // baseDate is the date which to compare to
    if (baseDate.getTime() > date.getTime()) {
        return true;
    }

    return false;
}

export const isFutureDate = (baseDate: Date, date: Date): boolean => {
    // baseDate is the date which to compare to
    if (baseDate.getTime() < date.getTime()) {
        return true;
    }

    return false;
}


export const formatDate = (dt: Date): string => {
    // return a UK date string from a date object
    return dt.toLocaleDateString('en-GB');
}

export const formatDateTime = (dt: Date): string => {
    // return a UK date string from a date object
    return dt.toLocaleString('en-GB');
}

export function formatUserFriendlyDate(inputDate: string): string {
    const date = new Date(inputDate);
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    };
    return new Intl.DateTimeFormat('en-US', options).format(date);
}

export function swapDayAndMonth(dateStr: string): Date {
    let dateParts = dateStr.split("-");
  
    let day = parseInt(dateParts[0], 10);
    let month = parseInt(dateParts[1], 10) - 1;
    let year = parseInt(dateParts[2], 10);
    
    return new Date(year, month, day);
}
