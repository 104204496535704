import colors from "assets/styles/colors";

export default {
    primaryButtonBackground: "white",
    primaryButtonForeground: "black",
    primaryButtonBackgroundHover: colors.grey70,

    secondaryButtonBackground: "blue",
    secondaryButtonForeground: "white",
    secondaryButtonBackgroundHover: "darkBlue",

    thirdButtonBackground: colors.grey50,
    thirdButtonForeground: "white",
    thirdButtonBackgroundHover: colors.grey30,

    accentButtonBackground: "red",
    accentButtonForeground: "white",
    accentButtonBackgroundHover: "darkRed",

    flatButtonBackground: "unset",
    flatButtonForeground: "white",
    flatButtonBackgroundHover: colors.grey20,

    squareButtonBackground: "black",
    squareButtonText: "white",
    squareButtonIcon: "white",
    squareButtonBackgroundHover: colors.grey30,
    squareButtonCount: "red",
    squareButtonBorder: "1px solid white",
    squareButtonBorderActive: "red",
    squaredButtonFailed: "red",
    squaredButtonHolding: "orange",
    squaredButtonSuccess: "blue",
};
