import GroupTab from "./GroupTabs.styles";

type StringInstance = {
    value: string;
    instances: number;
    indexes: number[];
};

export const getGroupTabs = (
    tab: StringInstance,
    widths: (number | undefined)[]
) => {
    const width = tab.indexes.reduce(
        (sum: number, index: number) => sum + (widths[index] ?? 0),
        0
    );

    return (
        <GroupTab
            width={width}
            value={tab.value ? "4px solid #0F4453" : "none"}
        >
            <div className="tabItem tabGroup">
                <p>
                    <b>{tab.value}</b>
                </p>
            </div>
        </GroupTab>
    );
};

export const reduceConsecutiveStrings = (arr: string[]): StringInstance[] => {
    return arr.reduce(
        (
            acc: StringInstance[],
            curr: string,
            i: number,
            array: string[]
        ): StringInstance[] => {
            if (i === 0 || curr !== array[i - 1]) {
                acc.push({ value: curr, instances: 1, indexes: [i] });
            } else {
                acc[acc.length - 1].instances++;
                acc[acc.length - 1].indexes.push(i);
            }
            return acc;
        },
        []
    );
};
