import { useEffect, useState } from "react";

// Chart.js
import Chart from "chart.js/auto";

// Utils
import { isObjectEmpty, toDate } from "utils/general.utils";

// Context
import { useBQMQ } from "context/BQMQ.context";
import { useSettings } from "context/Settings.context";

// Styles
import themes from "assets/styles/themes";
import CompanySessionChartContainer from "./CompanySessionChart.styles";

type Props = {
    data: BQMQSession[];
};

export default function CompanySessionChart({ data }: Props) {
    const { allFactorsList } = useBQMQ();
    const { theme }: { theme: "dark" | "light" | "highContrast" } =
        useSettings();

    const colors = ["#3E99B9", "#AE1231", "#A8B300", "#CB7113"];

    const [factorsChart, setFactorsChart] = useState<any>(null);

    useEffect(() => {
        if (data.length > 0 && !isObjectEmpty(allFactorsList)) {
            createChart(data);
        }
    }, [data, allFactorsList, theme]);

    const createChart = (data: any) => {
        let dates: any[] = [];

        let factors: any = {};

        Object.entries(allFactorsList).forEach(
            ([key, value]: [key: string, value: any]) => {
                factors[key] = [];
            }
        );

        [...data].reverse().forEach((session: any) => {
            dates.push(toDate(session.date));
            let modes = session.mode || [];

            Object.entries(modes).forEach(
                ([key, value]: [key: string, value: any]) => {
                    factors[key] = [...factors[key], value ? value : null];
                }
            );
        });

        if (factorsChart === null) {
            const fvChart = document.getElementById(
                "companyChart" + data
            ) as HTMLCanvasElement;

            let ctx = fvChart.getContext("2d");
            if (ctx) {
                let fValueChart: any = new Chart(ctx, {
                    type: "line",
                    data: {
                        labels: dates,
                        datasets: Object.entries(factors)
                            .filter(
                                ([factorID, value]: [
                                    factorID: string,
                                    value: any
                                ]) => ["BQ", "MQ"].includes(factorID)
                            )
                            .map(
                                (
                                    [factorID, value]: [
                                        factorID: string,
                                        value: any
                                    ],
                                    index: number
                                ) => ({
                                    label: factorID,
                                    data: value,
                                    backgroundColor: colors[index],
                                    borderColor: colors[index],
                                    steppedLine: false,
                                    yAxisID: "y",
                                })
                            ),
                    },
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        interaction: {
                            mode: "index",
                            intersect: false,
                        },
                        spanGaps: false,
                        plugins: {
                            legend: {
                                labels: {
                                    color: themes[theme].chartLabelColor,
                                },
                            },
                        },
                        scales: {
                            y: {
                                type: "linear",
                                display: true,
                                position: "left",
                                reverse: true,
                                min: 1,
                                max: 5,
                                ticks: {
                                    stepSize: 1,
                                    color: themes[theme].chartLabelColor,
                                },
                                grid: {
                                    borderColor: themes[theme].chartAxesColor,
                                    color: themes[theme].chartGridColor,
                                },
                            },
                            x: {
                                title: {
                                    display: true,
                                    text: "SESSIONS",
                                    color: themes[theme].chartLabelColor,
                                },
                                grid: {
                                    borderColor: themes[theme].chartAxesColor,
                                    color: themes[theme].chartGridColor,
                                },
                                ticks: {
                                    color: themes[theme].chartLabelColor,
                                },
                            },
                        },
                    },
                });

                setFactorsChart(fValueChart);
            }
        } else {
            factorsChart.options.scales.x.ticks.color =
                themes[theme].chartLabelColor;
            factorsChart.options.scales.y.ticks.color =
                themes[theme].chartLabelColor;

            factorsChart.options.scales.x.title.color =
                themes[theme].chartLabelColor;

            factorsChart.options.plugins.legend.labels.color =
                themes[theme].chartLabelColor;

            factorsChart.update();
        }
    };

    return (
        <CompanySessionChartContainer>
            <canvas className={"chart"} id={"companyChart" + data}></canvas>
        </CompanySessionChartContainer>
    );
}
