import { createContext, useContext, useEffect, useState } from "react";
// Context
import { useAuth } from "./Auth.context";

// Utils

import {
    commentSorter,
    getAllFactors,
    getAllQuestions,
    getAnalysts,
    getCompanies,
    getSessions,
    getTeams,
} from "utils/bqmq.utils";
import { hasPermissions } from "utils/permissions.utils";

// Logger
import Logger from "utils/logger.utils";
const Log = new Logger();
Log.init("BQMQ.Context");

const BQMQContext = createContext();

export function BQMQProvider({ children }) {
    const { user } = useAuth();
    const [allQuestionList, setAllQuestionList] = useState([]);
    const [companyId, setCompanyId] = useState("");
    const [allFactorsList, setAllFactorsList] = useState({});
    const [sessionID, setSessionID] = useState("");
    const [company, setCompany] = useState(null);
    const [team, setTeam] = useState(null);
    const [host, setHost] = useState(null);
    const [analyst, setAnalyst] = useState(null);
    const [userList, setUserList] = useState([]);
    const [factors, setFactors] = useState(null);
    const [myVotes, setMyVotes] = useState({});

    const [onlyShowCurrentEmployees, setOnlyShowCurrentEmployees] =
        useState(false);
    const [highlightedFactor, setHighlightedFactor] = useState("");
    const [showDetailPane, setShowDetailPane] = useState(false);
    const [showFullUserList, setShowFullUserList] = useState(false);
    const [teams, setTeams] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [analysts, setAnalysts] = useState([]);
    const [sessions, setSessions] = useState([]);
    const [upcomingSessions, setUpcomingSessions] = useState([]);
    const [pendingSessions, setPendingSessions] = useState([]);
    const [defaultChoices, setDefaultChoices] = useState(null);
    const [bqmqLoading, setBqmqLoading] = useState(true);

    const getSetupData = async () => {
        setAllFactorsList(await getAllFactors());
        const questions = await getAllQuestions();
        questions.sort(commentSorter);
        setAllQuestionList(questions);
    };

    const getDropDowns = async () => {
        getSessions(
            setSessions,
            setPendingSessions,
            setUpcomingSessions,
            user.id
        );
        getAnalysts(setAnalysts);
        getTeams(setTeams);
        getCompanies(setCompanies);

        setBqmqLoading(
            !analysts |
                !companies |
                !teams |
                !sessions |
                !upcomingSessions |
                !pendingSessions
        );
    };

    useEffect(() => {
        if (
            user &&
            hasPermissions(
                ["p_bqmq_can_vote", "p_bqmq_can_view_vote"],
                user.permissions
            )
        ) {
            getSetupData();
            getDropDowns();
        }
    }, [user]);

    return (
        <BQMQContext.Provider
            value={{
                getDropDowns,
                bqmqLoading,
                defaultChoices,
                setDefaultChoices,
                pendingSessions,
                sessions,
                upcomingSessions,
                companies,
                teams,
                analysts,
                myVotes,
                setMyVotes,
                company,
                setCompany,
                team,
                setTeam,
                analyst,
                setAnalyst,
                userList,
                setUserList,
                allQuestionList,
                setAllQuestionList,
                allFactorsList,
                setAllFactorsList,
                factors,
                setFactors,
                sessionID,
                setSessionID,
                host,
                setHost,

                showFullUserList,
                setShowFullUserList,
                highlightedFactor,
                setHighlightedFactor,
                showDetailPane,
                setShowDetailPane,
                onlyShowCurrentEmployees,
                setOnlyShowCurrentEmployees,

                setCompanyId,
                companyId,
                getSetupData,
            }}
        >
            {children}
        </BQMQContext.Provider>
    );
}

export const useBQMQ = () => {
    return useContext(BQMQContext);
};
