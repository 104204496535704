import styled from "styled-components";

export default styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    .info {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-grow: 1;

        padding: 15px 15px;

        .name {
            display: flex;
            flex-direction: row;
            align-items: center;

            font-weight: bolder;
            letter-spacing: 0.1em;
            font-size: 16px;
            color: ${(props) => props.theme.homeListRowHeader};
        }

        .email {
            position: relative;
            z-index: 9;
        }

        .details {
            display: flex;
            flex-direction: row;
            margin: 0 -5px;

            .detail {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin: 0 5px;

                p {
                    color: ${(props) => props.theme.homeListRowText};
                    font-size: 14px;
                }

                svg {
                    color: ${(props) => props.theme.homeListRowText};
                    font-size: 10px;
                    margin-right: 4px;
                    margin-bottom: 2px;
                }
            }
        }
    }

    .date {
        display: flex;
        flex-direction: column;
        justify-content: center;

        color: ${(props) => props.theme.homeListRowText};
        font-size: 14px;
        margin: 0 20px;
        white-space: nowrap;
    }

    :hover {
        background-color: ${(props) => props.theme.homeListBodyBackgroundHover};
    }
`;
