import colors from "assets/styles/colors";

export default {
    avatarBackground: colors.darkThemeLight,
    avatarText: "white",
    nameText: "white",

    logoutButtonBackground: colors.darkThemeLight,
    logoutButtonText: "white",
    logoutButtonBackgroundHover: colors.darkThemeLighter,
};
