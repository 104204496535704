import { FeesProvider } from "context/Fees.context";
import { useEffect, useState } from "react";
// Styles
import SeparateAccountsGlobalContainer from "./SeparateAccountsGlobal.styles";

// Components
import SubTabs from "components/Common/SubTabs/SubTabs";
import Latest from "components/Screens/Fees/Common/FundTabs/LatestTab/Latest";
import Redemptions from "components/Screens/Fees/Common/FundTabs/RedemptionsTab/Redemptions";
import Reports from "components/Screens/Fees/Common/FundTabs/ReportsTab/Reports";
import Subscriptions from "components/Screens/Fees/Common/FundTabs/SubscriptionsTab/Subscriptions";
import ClientConfig from "components/Screens/Fees/Common/FundTabs/ClientConfigTab/ClientConfig";

//utils
import Mgmt from "components/Screens/Fees/Common/FundTabs/MgmtTab/Mgmt";
import PerformanceFees from "components/Screens/Fees/Common/FundTabs/PerformanceFeeTab/PerformanceFee";
import { feesDashboardUrl } from "utils/fees.utils";

interface SeparateAccountsGlobalProps {
    region: string;
    period: string;
}

const SeparateAccountsGlobal = ({
    region,
    period,
}: SeparateAccountsGlobalProps) => {
    const [feesSepAccountUrl, setFeesSepAccountUrl] = useState("");

    useEffect(() => {
        feesDashboardUrl("SEP_ACCOUNTS_FEES", setFeesSepAccountUrl);
    }, []);

    return (
        <SeparateAccountsGlobalContainer>
            <FeesProvider period={period}>
                <SubTabs
                    defaultTab="latest"
                    subTabs={[
                        {
                            value: "latest",
                            title: "LATEST FILES",
                            content: (
                                <Latest
                                    fund="sac"
                                    region={region}
                                    period={period}
                                />
                            ),
                            permissions: [
                                "p_can_view_fees",
                                "p_can_action_fees",
                            ],
                        },
                        {
                            value: "management_fees",
                            title: "MANAGEMENT FEES",
                            content: (
                                <Mgmt
                                    fund="sac"
                                    region={region}
                                    period={period}
                                />
                            ),
                            permissions: [
                                "p_can_view_fees",
                                "p_can_action_fees",
                            ],
                        },
                        {
                            value: "redemptions",
                            title: "REDEMPTIONS",
                            content: (
                                <Redemptions
                                    fund="sac"
                                    region={region}
                                    period={period}
                                />
                            ),
                            permissions: [
                                "p_can_view_fees",
                                "p_can_action_fees",
                            ],
                            groupKey: "Monthly Cashflow Events",
                        },
                        {
                            value: "subscriptions",
                            title: "SUBSCRIPTIONS",
                            content: (
                                <Subscriptions
                                    fund="sac"
                                    region={region}
                                    period={period}
                                />
                            ),
                            permissions: [
                                "p_can_view_fees",
                                "p_can_action_fees",
                            ],
                            groupKey: "Monthly Cashflow Events",
                        },
                        {
                            value: "performance_fees",
                            title: "PERFORMANCE FEES",
                            content: (
                                <PerformanceFees
                                    fund="sac"
                                    region={region}
                                    period={period}
                                />
                            ),
                            permissions: [
                                "p_can_view_fees",
                                "p_can_action_fees",
                            ],
                            groupKey: "Monthly Cashflow Events",
                        },
                        {
                            value: "reports",
                            title: "REPORTS SIGN OFF",
                            content: (
                                <Reports
                                    fund="sac"
                                    region={region}
                                    period={period}
                                />
                            ),
                            permissions: [
                                "p_can_view_fees",
                                "p_can_action_fees",
                            ],
                            groupKey: "Monthly Sign Off",
                        },
                        {
                            value: "historic",
                            title: "FEE REPORT",
                            content: (
                                <div className="iframeContainer">
                                    <iframe
                                        src={feesSepAccountUrl}
                                        width="100%"
                                        height="100%"
                                        allowFullScreen
                                        frameBorder={0}
                                    />
                                </div>
                            ),
                            permissions: [
                                "p_can_view_fees",
                                "p_can_action_fees",
                            ],
                            groupKey: "Monthly Sign Off",
                        },
                        {
                            value: "client_config",
                            title: "CLIENT CONFIG",
                            content: (
                                <ClientConfig fund="sac" region={region} />
                            ),
                            permissions: [
                                "p_can_view_fees",
                                "p_can_action_fees",
                            ],
                        },
                    ]}
                />
            </FeesProvider>
        </SeparateAccountsGlobalContainer>
    );
};
export default SeparateAccountsGlobal;
