import styled from "styled-components";

export default styled.div`
    justify-content: flex-end;
    display: flex;
    margin-left: 10px;

    .action {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        svg {
            margin: 0 5px;
            font-size: 25px;
            color: white;
            cursor: pointer;
        }

        :hover {
            svg {
                color: #dddddd;
            }
        }
    }
`;
