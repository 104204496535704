import { useState, useEffect } from "react";

// FontAwesome
import { faTrash, faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Material UI
import { Tooltip } from "@mui/material";

// Components
import Can from "components/Common/Can/Can";
import Button from "components/Common/Button/Button";
import Alert from "components/Common/Alert/Alert";

// Utils
import * as Database from "utils/database.utils";
import { exportFromS3 } from "utils/export.utils";

// Context
import { useSettings } from "context/Settings.context";

// Styles
import ExtractionOptionsContainer from "./ExtractionOptions.styles";

type Props = {
    data: any[];
    setData: Function;
    reload: Function;
};

const ExtractionOptions: React.FC<Props> = ({ data, setData, reload }) => {
    const { setShowLoadingToast } = useSettings();

    const [canDelete, setCanDelete] = useState<boolean>(false);
    const [selectedExtractions, setSelectedExtractions] = useState<any[]>([]);

    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

    const checkSelectedStatus = () => {
        let canDelete = true;
        let selected: any[] = [];

        data.forEach((row) => {
            if (
                row.selected &&
                row.model_status !== "HLD" &&
                row.model_status !== "FAI"
            ) {
                canDelete = false;
            }

            if (row.selected) {
                selected.push(row);
            }
        });

        setSelectedExtractions(selected);
        setCanDelete(canDelete);
    };

    useEffect(() => {
        checkSelectedStatus();
    }, [data]);

    const exportSelected = () => {
        setShowLoadingToast({
            show: true,
            message: "EXPORTING",
        });

        let count = 0;

        for (const ext of selectedExtractions) {
            exportFromS3(ext.id, ext.archive_folder_path).then((res) => {
                count++;
                if (count === selectedExtractions.length) {
                    setShowLoadingToast({
                        show: false,
                        message: "",
                    });
                }
            });
        }
    };

    const deleteSelected = async () => {
        setShowLoadingToast({
            show: true,
            message: "DELETING",
        });

        disabledRows();

        let count = 0;

        for (const ext of selectedExtractions) {
            let data = {
                extraction_id: ext.id,
                user_id: localStorage.getItem("gim-user-id"),
                user_notes: "",
            };

            await Database.post("api/model_actions/delete", data);
            count++;
            if (count === selectedExtractions.length) {
                setShowLoadingToast({
                    show: false,
                    message: "",
                });
            }
        }

        reload();
    };

    const disabledRows = () => {
        let selectedIDs = selectedExtractions.map((ext) => ext.id);

        let newData: any[] = [];

        data.forEach((ext) => {
            if (selectedIDs.includes(ext.id)) {
                newData.push({ ...ext, disabled: true, selected: false });
            } else {
                newData.push(ext);
            }
        });

        setData(newData);
    };

    return (
        <ExtractionOptionsContainer>
            {canDelete && (
                <Can
                    yes={
                        <Tooltip title="DELETE SELECTED" placement="top">
                            <div onClick={() => setShowConfirmModal(true)}>
                                <FontAwesomeIcon icon={faTrash} fixedWidth />
                            </div>
                        </Tooltip>
                    }
                    permissions={["p_remove_extraction"]}
                />
            )}

            <Can
                yes={
                    <Tooltip title="EXPORT RAW SELECTED" placement="top">
                        <div onClick={exportSelected}>
                            <FontAwesomeIcon icon={faFileExcel} fixedWidth />
                        </div>
                    </Tooltip>
                }
                permissions={["p_upload_model"]}
            />

            {showConfirmModal && (
                <Alert>
                    <h2>DELETE EXTRACTIONS</h2>
                    <p>
                        Are you sure you want to delete these{" "}
                        {selectedExtractions.length} extractions? It may take a
                        couple of minutes.
                    </p>
                    <Button
                        text="DELETE"
                        color="accent"
                        click={() => {
                            deleteSelected();
                            setShowConfirmModal(false);
                        }}
                    />
                    <Button
                        text="CLOSE"
                        color="flat"
                        click={() => {
                            setShowConfirmModal(false);
                        }}
                    />
                </Alert>
            )}
        </ExtractionOptionsContainer>
    );
};

export default ExtractionOptions;
