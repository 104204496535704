// Styles
import FormPFContainer from "./FormPF.styles";
import Tabs from "components/Common/Tabs/Tabs";
import ReportsTab from "components/Screens/DailyRecs/DailyFlowsCheck/ReportsTab/ReportsTab";
import AlertsTab from "components/Screens/DailyRecs/DailyFlowsCheck/AlertsTab/AlertsTab";
import ChangeLogTab from "components/Screens/DailyRecs/DailyFlowsCheck/ChangeLogTab/ChangeLogTab";

interface FormPF {}

const powerBI = "https://app.powerbi.com/reportEmbed?reportId=b5ab97cc-7213-4284-93c4-db323c620ac8&autoAuth=true&ctid=bf4450a2-bfcb-472d-8c40-1425eafb1a3b&filterPaneEnabled=false";
const adverseInvestmentCheckName = "form_pf_adverse_return";
const adverseCollateralCheckName = "form_pf_adverse_collateral";
const adverseRedemptionCheckName = "form_pf_adverse_redemption";

const FormPF = ({}: FormPF) => {
    return (
        <FormPFContainer>
            <p className={"sectionTitle"}>Form PF</p>
            <Tabs
                defaultTab="reports"
                tabs={[
                    {
                        value: "reports",
                        title: "REPORTS",
                        permissions: ["p_can_view_navs", "p_can_action_navs"],
                        content: <ReportsTab powerBI={powerBI}/>,
                    },
                    {
                        value: "investment_alerts",
                        title: "INVESTMENT ALERTS",
                        permissions: ["p_can_view_navs", "p_can_action_navs"],
                        content: <AlertsTab  checkName={adverseInvestmentCheckName}/>,
                    },
                    {
                        value: "investment_change_log",
                        title: "INVESTMENT CHANGE LOG",
                        permissions: ["p_can_view_navs", "p_can_action_navs"],
                        content: <ChangeLogTab checkName={adverseInvestmentCheckName} />,
                    },
                    {
                        value: "collateral_alerts",
                        title: "COLLATERAL ALERTS",
                        permissions: ["p_can_view_navs", "p_can_action_navs"],
                        content: <AlertsTab  checkName={adverseCollateralCheckName}/>,
                    },
                    {
                        value: "collateral_change_log",
                        title: "COLLATERAL CHANGE LOG",
                        permissions: ["p_can_view_navs", "p_can_action_navs"],
                        content: <ChangeLogTab checkName={adverseCollateralCheckName} />,
                    },
                    {
                        value: "redemption_alerts",
                        title: "REDEMPTION ALERTS",
                        permissions: ["p_can_view_navs", "p_can_action_navs"],
                        content: <AlertsTab  checkName={adverseRedemptionCheckName}/>,
                    },
                    {
                        value: "redemption_change_log",
                        title: "REDEMPTION CHANGE LOG",
                        permissions: ["p_can_view_navs", "p_can_action_navs"],
                        content: <ChangeLogTab checkName={adverseRedemptionCheckName} />,
                    },
                ]}
            />
        </FormPFContainer>
    );
};

export default FormPF
