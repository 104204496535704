import { useState } from "react";

// Components
import Table from "components/Common/Table/Table";
import SearchOptions from "components/Common/SearchOptions/SearchOptions";
import TableCell from "components/Common/TableCell/TableCell";

// Utils
import { to4DecimalPlaces } from "utils/general.utils";

// Data
import { wsOptions, excelTableOptions } from "assets/data/lists";

// Styles
import ModelDataContainer from "./ModelData.styles";

type Props = {
    id: string;
};

export default function ModelData({ id }: Props) {
    const [filterQuery, setFilterQuery] = useState("");

    const columns = [
        {
            id: 1,
            title: "METRIC",
            sortable: true,
            value: "metric",
            cell: (data: string) => <TableCell data={data} />,
            show: true,
        },
        {
            id: 2,
            title: "WS",
            sortable: true,
            value: "ws",
            cell: (data: string) => <TableCell data={data} />,
            show: true,
        },
        {
            id: 3,
            title: "TABLE",
            sortable: true,
            value: "excel_table",
            cell: (data: string) => <TableCell data={data} />,
            show: true,
        },
        {
            id: 4,
            title: "CELL REF",
            sortable: true,
            value: "cell_ref",
            cell: (data: string) => <TableCell data={data} />,
            show: true,
        },
        {
            id: 5,
            title: "VALUE",
            sortable: true,
            value: "cell_value",
            cell: (data: string) => <p>{to4DecimalPlaces(data)}</p>,
            show: true,
        },
        {
            id: 6,
            title: "FORMULA",
            sortable: false,
            value: "cell_formula",
            cell: (data: string) => <TableCell data={data} />,
            show: true,
        },
    ];

    const filterOptions = [
        {
            id: 1,
            name: "WS",
            value: "ws",
            type: "DROPDOWN",
            options: wsOptions,
        },
        {
            id: 2,
            name: "Table",
            value: "excel_table",
            type: "DROPDOWN",
            options: excelTableOptions,
        },
    ];

    return (
        <ModelDataContainer>
            <SearchOptions
                setFilterQuery={setFilterQuery}
                options={filterOptions}
            />

            <Table
                columns={columns}
                url={"api/extractions/model-metrics/" + id}
                filterQuery={filterQuery}
                defaultSortKey="metric"
                defaultSortOrder="ASC"
                pagination
            />
        </ModelDataContainer>
    );
}
