// Core
import { useEffect, useState } from "react";

// Icons
import { faCheck, faPen, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Components
import { Tooltip } from "@mui/material";
import DateSelect from "components/Common/DateSelect/DateSelect";
import Select from "components/Common/Select/Select";
import SkeletonLoader from "components/Common/SkeletonLoader/SkeletonLoader";
import TextField from "components/Common/TextField/TextField";
// styles
import colors from "assets/styles/colors";
import EditableCellContainer from "./EditableCell.styles";

// Utils
import * as Database from "utils/database.utils";

// Context
import { useSettings } from "context/Settings.context";

type Props = {
    rowId: string | number;
    rowIdKey: string | number;
    startDateKey?: string;
    initialValue: string | number | null;
    selectOptions?: Option[];
    type: "selectOptions" | "textField" | "numericalField" | "dateField";
    cellValue?: string;
    updateTable: () => void;
    cellValueType?: string;
    validateCellValueTypeBy?: string;
    setTableIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    tableIsLoading?: boolean;
    // function is called when you make the update (pass the change up)
    // if it is set it's ignore the API related config setup
    onUpdate?: Function;
    // set if you want the component to handle the update
    apiMethod?: string;
    apiUrl?: string;
    inputParser?: Function;
};

type createdObject = {
    value: string | number | null;
    attribute?: string;
};

export default function EditableCell({
    rowId,
    rowIdKey,
    initialValue,
    selectOptions,
    type,
    apiUrl,
    cellValue,
    updateTable,
    cellValueType,
    validateCellValueTypeBy,
    setTableIsLoading,
    tableIsLoading,
    startDateKey,
    apiMethod,
    onUpdate,
    inputParser = (value: any) => value,
}: Props) {
    const [editing, setEditing] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const { toast, setToast } = useSettings();
    const [value, setValue] = useState<string | number | null>(
        inputParser(initialValue)
    );
    const [error, setError] = useState<string | undefined>(undefined);

    const createUpdateObject = (
        value: string | number | null,
        attribute?: string
    ) => {
        if (attribute) {
            const object: any = {};
            object[rowIdKey] = rowId;
            object[attribute] = value === "" || null ? null : value;
            if (startDateKey) {
                object[startDateKey] = new Date().toUTCString();
            }
            return object;
        }
    };

    const updateCellValue = async () => {
        // call the onUpdate function or do the API call
        setTableIsLoading(true);

        const updateObject = createUpdateObject(value, cellValue);
        if (!onUpdate) {
            await apiUpdate(updateObject);
        } else {
            const errors = await onUpdate(
                updateObject.id,
                updateObject.comments
            );
            if (errors) {
                setError(errors);
                setLoading(false);
                setValue(initialValue);
                setToast({
                    show: true,
                    type: "error",
                    message: "Error Updating Value!",
                });
            }

            setTableIsLoading(false);
            setEditing(false);
        }
    };

    const apiUpdate = async (updateObject: createdObject) => {
        setLoading(true);

        setTableIsLoading(true);

        if (apiMethod === "patch") {
            await Database.patch(apiUrl, updateObject);
        } else if (apiMethod === "post") {
            await Database.post(apiUrl, updateObject);
        } else {
            await Database.put(apiUrl, updateObject);
        }

        setEditing(false);

        await updateTable();
    };

    const formatDate = (date: string) => {
        let parts = date.split("/");
        let formattedDateStr = `${parts[1]}/${parts[0]}/${parts[2]}`;
        let newDate = new Date(formattedDateStr);
        return newDate.toISOString();
    };

    useEffect(() => {
        setLoading(false);
        setTableIsLoading(false);
    }, [initialValue]);

    return (
        <EditableCellContainer>
            {!loading ? (
                cellValueType === validateCellValueTypeBy &&
                type === "textField" ? (
                    <div className="notApplicableCell">N/A</div>
                ) : (
                    <div
                        className={`cellWrapper ${
                            tableIsLoading && "disabled"
                        }`}
                    >
                        {editing ? (
                            <div className="inputField">
                                {type === "selectOptions" && (
                                    <Select
                                        filterName={"NULL"}
                                        options={
                                            selectOptions ? selectOptions : []
                                        }
                                        value={value}
                                        setValue={setValue}
                                    />
                                )}
                                {type === "textField" && (
                                    <TextField
                                        type="text"
                                        placeholder={value}
                                        value={value}
                                        change={setValue}
                                        error={error !== undefined}
                                        errorText={error}
                                    />
                                )}
                                {type === "numericalField" && (
                                    <TextField
                                        step={value
                                            ?.toString()
                                            .replace(/[^.]/g, "0")
                                            .replace(/\d$/, "1")}
                                        type="number"
                                        placeholder={value}
                                        value={value}
                                        change={setValue}
                                        error={error !== undefined}
                                        errorText={error}
                                    />
                                )}
                                {type === "dateField" && (
                                    <DateSelect
                                        placeholder={formatDate(
                                            value as string
                                        )}
                                        setValue={(value: Date) => {
                                            setValue(
                                                value.toLocaleDateString()
                                            );
                                        }}
                                        value={
                                            new Date(
                                                formatDate(value as string)
                                            )
                                        }
                                        error={error !== undefined}
                                        errorText={error}
                                    />
                                )}
                                <div className="actions">
                                    <Tooltip
                                        title="SAVE"
                                        placement="left-start"
                                    >
                                        <div className="icon-button save">
                                            <FontAwesomeIcon
                                                color={colors.accentGreen}
                                                icon={faCheck}
                                                fixedWidth
                                                onClick={updateCellValue}
                                            />
                                        </div>
                                    </Tooltip>
                                    <Tooltip
                                        title="CANCEL"
                                        placement="right-start"
                                    >
                                        <div className="icon-button close">
                                            <FontAwesomeIcon
                                                color={colors.accentRed}
                                                icon={faPlus}
                                                fixedWidth
                                                onClick={() => {
                                                    setEditing(false);
                                                    setValue(
                                                        inputParser(
                                                            initialValue
                                                        )
                                                    );
                                                    setError(undefined);
                                                }}
                                            />
                                        </div>
                                    </Tooltip>
                                </div>
                            </div>
                        ) : (
                            <div className="inputField">
                                <p className={`${initialValue}`}>
                                    {initialValue}
                                </p>
                                <Tooltip title="EDIT" placement="left-start">
                                    <div id="edit" className="icon-button edit">
                                        <FontAwesomeIcon
                                            color={colors.textTeal}
                                            icon={faPen}
                                            fixedWidth
                                            onClick={() => setEditing(true)}
                                        />
                                    </div>
                                </Tooltip>
                            </div>
                        )}
                    </div>
                )
            ) : (
                <SkeletonLoader type="auto" />
            )}
        </EditableCellContainer>
    );
}
