import styled from "styled-components";

export default styled.div`
    .sectionTitle {
        font-size: 25px;
        letter-spacing: 0.1em;

        color: ${(props) => props.theme.pageTitleText};
        margin-bottom: 10px;
        margin-top: 20px;
    }

    .columns {
        display: flex;
        flex-direction: row;

        min-width: 100%;

        margin: 0 -10px;

        .half {
            display: flex;
            flex-direction: column;
            min-width: 50%;
            max-width: 50%;

            padding: 0 10px;
        }
    }

    @media screen and (max-width: 1000px) {
        .columns {
            display: flex;
            flex-direction: column;
            .half {
                min-width: 100%;
                max-width: 100%;
            }
        }
    }
`;
