// Core
import { useEffect, useState } from "react";

// Packages
import queryString from "query-string";

// Fontawesome
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

// Components
import ButtonExtraSmall from "components/Common/ButtonExtraSmall/ButtonExtraSmall";

// Utils
import * as Database from "utils/database.utils";
import { getWithExpiry, setWithExpiry } from "utils/storage.utils";

// Context
import { useBQMQ } from "context/BQMQ.context";
import { useSettings } from "context/Settings.context";

// Styles
import EmailReminderContainer from "./EmailReminder.styles";

type Props = {
    userID: string;
    userName: string;
    companyID: string;
    companyName?: string;
};

export default function EmailReminder({
    userID,
    userName,
    companyID,
    companyName,
}: Props) {
    const { setShowLoadingToast, setToast } = useSettings();
    const { company } = useBQMQ();

    const [isSent, setIsSent] = useState<boolean>(false);

    const sessionID = queryString.parse(window.location.search).id;

    useEffect(() => {
        const hasBeenSent = getWithExpiry(
            `bqmq.email-reminder-${userName}-${companyID}`,
            companyID
        );
        setIsSent(hasBeenSent);
    }, []);

    const sendEmail = async () => {
        setShowLoadingToast({
            show: true,
            message: "Sending email",
        });
        const result = await Database.post("api/bqmq_voting/send-reminder", {
            user_id: userID,
            session_id: companyID ? companyID : "",
            company: companyName ? companyName : company,
            company_id: companyID ? companyID : "",
        });
        const message = await result.json();
        if (result.ok) {
            setToast({
                show: true,
                type: "info",
                message: `${message} to ${userName}!`,
            });
            setIsSent(true);

            //Set the email button to become disbaled for 24 hours after an email has been sent per user.
            setWithExpiry(
                `bqmq.email-reminder-${userName}-${companyID}`,
                86400000,
                companyID
            );
        } else {
            setToast({
                show: true,
                type: "error",
                message: `${message} to ${userName}!`,
            });
        }
        setShowLoadingToast({
            show: false,
            message: "",
        });
    };

    return (
        <EmailReminderContainer>
            <ButtonExtraSmall
                click={sendEmail}
                text={isSent ? "EMAIL SENT" : "SEND EMAIL REMINDER"}
                icon={faEnvelope}
                disabled={isSent}
            />
        </EmailReminderContainer>
    );
}
