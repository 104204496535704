export const gbl_stage_table = [
    {
        text: "model_extraction_meta_stg",
        type: "table",
        value: "model_extraction_meta_stg",
        fields: [
            {
                field: "extraction_id",
                type: "String(36)",
                primaryKey: true,
                nullable: true,
            },
            {
                field: "company",
                type: "String(50)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "company_extract_group",
                type: "String(60)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "extraction_datetime",
                type: "DateTime",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "processing_datetime",
                type: "DateTime",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "processing_method",
                type: "String(10)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "last_modified_datetime",
                type: "DateTime)",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "last_modified_by",
                type: "String(30))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "created_datetime",
                type: "DateTime)",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "created_by",
                type: "String(30))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "folder_path",
                type: "String(100))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "fixes_id",
                type: "String(36))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "company_id",
                type: "Integer",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "company_ref_id",
                type: "Integer",
                primaryKey: false,
                nullable: false,
            },
        ],
    },
    {
        text: "model_named_cells_stg",
        type: "table",
        value: "model_named_cells_stg",
        fields: [
            {
                field: "extraction_id",
                type: "String(255)",
                primaryKey: true,
                nullable: false,
            },
            {
                field: "ws",
                type: "String(255)",
                primaryKey: true,
                nullable: false,
            },
            {
                field: "metric",
                type: "String(255)",
                primaryKey: true,
                nullable: false,
            },
            {
                field: "metric_id",
                type: "String(255)",
                primaryKey: false,
                nullable: false,
            },
            {
                field: "cell_ref",
                type: "String(255))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "ws_metric_only",
                type: "SmallInteger)",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "hidden_flag",
                type: "SmallInteger)",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "wb_ref_type",
                type: "String(255))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "cell_type",
                type: "String(255))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "cell_type_complete",
                type: "String(255))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "cell_formula",
                type: "String(2000))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "cell_data_exists",
                type: "Integer)",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "cell_value",
                type: "String(2000))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "cell_value_numeric",
                type: "Float(53))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "cell_value_str",
                type: "String(2000))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "id",
                type: "String(255))",
                primaryKey: false,
                nullable: true,
            },
            {
                field: "target",
                type: "String(255))",
                primaryKey: false,
                nullable: true,
            },
        ],
    },
];
