import styled from "styled-components";

export default styled.div`
    display: flex;
    flex-direction: column;

    margin-bottom: 20px;

    .chartTitle {
        margin: 20px 0;

        font-size: 18px;
        letter-spacing: 0.1em;

        color: ${(props) => props.theme.pageTitleText};
    }

    .chart {
        display: flex;
        max-height: 350px;
        min-height: 350px;
        min-width: 100%;
        max-width: 100%;
    }
`;
