import { useEffect, useState } from "react";

// Styles
import AccrualsContainer from "./Accruals.styles";
import { accrualsFields, AccrualsValues } from "./types";

// Components
import TextField from "components/Common/TextField/TextField";
import SignOff from "components/Screens/DailyRecs/Common/SignOff/SignOff";
import Button from "components/Common/Button/Button";

// Material UI
import { Divider, Grid } from "@mui/material";
import Select from "components/Common/Select/Select";
import UpdateAccrualModal from "components/Screens/DailyRecs/Accruals/UpdateAccrualModal/UpdateAccrualModal";

// Utils
import * as Database from "utils/database.utils";
import { useSettings } from "context/Settings.context";
import { uppercaseFirstLetter } from "utils/common.utils";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import Tabs from "components/Common/Tabs/Tabs";
import InputTab from "components/Screens/DailyRecs/Accruals/InputTab/InputTab";

interface AccrualsProps {}

const Accruals = ({}: AccrualsProps) => {
    return (
        <AccrualsContainer>
            <p data-cy="navs-main-title" className={"sectionTitle"}>
                ACCRUALS
            </p>
            <Tabs
                defaultTab="current_inputs"
                tabs={[
                    {
                        value: "current_inputs",
                        title: "CURRENT INPUTS",
                        permissions: ["p_can_view_navs", "p_can_action_navs"],
                        content: <InputTab noSignOff />,
                    },
                    {
                        value: "proposed_inputs",
                        title: "PROPOSED INPUTS",
                        permissions: ["p_can_view_navs", "p_can_action_navs"],
                        content: <InputTab />,
                    },
                    {
                        value: "report",
                        title: "REPORT",
                        permissions: ["p_can_view_navs", "p_can_action_navs"],
                        content: (
                            <div className="iframeContainer">
                                <iframe
                                    src={
                                        "https://app.powerbi.com/reportEmbed?reportId=645a1dce-453e-49ab-a0b0-c4cc4a829a87&autoAuth=true&ctid=bf4450a2-bfcb-472d-8c40-1425eafb1a3b&filterPaneEnabled=false"
                                    }
                                    width="100%"
                                    height="100%"
                                    allowFullScreen
                                    frameBorder={0}
                                />
                            </div>
                        ),
                    },
                ]}
            />
        </AccrualsContainer>
    );
};
export default Accruals;
