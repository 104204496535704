import styled from "styled-components";
import colors from "assets/styles/colors";

export default styled.div`
    display: flex;
    flex-direction: column;

    .noErrors {
        width: 100%;
        height: 200px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        svg {
            font-size: 80px;
            color: ${colors.teal50};
        }
        p {
            font-size: 25px;
            color: ${colors.textGrey};
        }
    }
`;
