import { useState, useEffect } from "react";
import queryString from "query-string";

// Material UI
import { Grid } from "@mui/material";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookOpen, faSearch } from "@fortawesome/free-solid-svg-icons";

// Components
import PageTitle from "components/Common/PageTitle/PageTitle";
import Tabs from "components/Common/Tabs/Tabs";
import SummarySheetData from "components/Screens/Data/SummarySheets/SummarySheetData/SummarySheetData";
import SummarySheetErrors from "components/Screens/Data/SummarySheets/SummarySheetErrors/SummarySheetErrors";
import SummarySheetCounts from "components/Screens/Data/SummarySheets/SummarySheetCounts/SummarySheetCounts";
import SummarySheetMoreInfo from "components/Screens/Data/SummarySheets/SummarySheetMoreInfo/SummarySheetMoreInfo";

// Utils
import {getSessionCachedData, sessionCacheData, toDateAndTime} from "utils/general.utils";
import * as Database from "utils/database.utils";

// Context
import { useSettings } from "context/Settings.context";

// Styles
import SummarySheetDetailContainer from "./SummarySheetDetail.styles";

const SummarySheetDetail: React.FC = () => {
    const { setShowLoadingToast } = useSettings();

    const parsedUrl = queryString.parse(window.location.search);

    const apiUrl =
        parsedUrl.region === "asia"
            ? "api/asia_summary_sheet/"
            : "api/summary_sheet/";

    const cacheKey =
        parsedUrl.region === "asia"
            ? "asiaSummarySheetDetail"
            : "globalSummarySheetDetail";

    const initialSummarySheetData = {
        id: "",
        extraction_datetime: "",
        processing_datetime: "",
        processing_method: "AUTO",
        last_modified_datetime: "",
        last_modified_by: "",
        folder_path: "",
        fixes_id: "",
        num_focus_list_companies: 0,
        num_portfolio_companies: 0,
        num_errors: 0,
        num_vals_extracted: 0,
    };

    const [summarySheetData, setSummarySheetData] = useState<any>(
        initialSummarySheetData
    );

    const getSummarySheet = (index: string) => {
        setShowLoadingToast({ show: true, message: "LOADING" });

        Database.get(apiUrl + index).then((res) => {
            if (res.ok) {
                res.json().then((data) => {
                    setSummarySheetData(data.data[0]);
                    sessionCacheData(cacheKey, index, data.data[0]);
                });
            }
        });
    };

    const getID = (): string => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        return urlParams.get("id") || "";
    };

    useEffect(() => {
        let oldData = getSessionCachedData(cacheKey, getID());

        oldData ? setSummarySheetData(oldData) : getSummarySheet(getID());
    }, []);

    return (
        <SummarySheetDetailContainer>
            <PageTitle
                title={
                    summarySheetData.extraction_datetime
                        ? "SUMMARY SHEET: " +
                          toDateAndTime(summarySheetData.extraction_datetime)
                        : "LOADING"
                }
            ></PageTitle>

            <Grid container className="metadata">
                <Grid item xs={6} md={3} className="topGrid">
                    <div className="info">
                        <label>EXTRACTION DATE</label>
                        <p>
                            {summarySheetData.extraction_datetime
                                ? toDateAndTime(
                                      summarySheetData.extraction_datetime
                                  )
                                : "LOADING"}
                        </p>
                    </div>

                    <div className="info">
                        <label>PROCESSED ON</label>
                        <p>
                            {toDateAndTime(
                                summarySheetData.processing_datetime
                            )}
                        </p>
                    </div>
                </Grid>
                <Grid item xs={6} md={3} className="topGrid">
                    <div className="info">
                        <label>LAST MODIFIED ON</label>
                        <p>
                            {summarySheetData.last_modified_datetime
                                ? toDateAndTime(
                                      summarySheetData.last_modified_datetime
                                  )
                                : "LOADING"}
                        </p>
                    </div>

                    <div className="info">
                        <label>LAST MODIFIED BY</label>
                        <p>{summarySheetData.last_modified_by}</p>
                    </div>
                </Grid>

                <Grid item xs={6} md={3} className="topGrid">
                    <div className="info">
                        <label>NUMBER OF VALUES EXTRACTED</label>
                        <p>{summarySheetData.num_vals_extracted}</p>
                    </div>
                </Grid>

                <Grid item xs={6} md={3} className="topGrid">
                    <div className="info">
                        <label>FOCUS LIST COMPANIES</label>
                        <p>
                            <FontAwesomeIcon
                                icon={faSearch}
                                fixedWidth
                                className="blue"
                            />
                            {summarySheetData.num_focus_list_companies}
                        </p>
                    </div>

                    <div className="info">
                        <label>PORTFOLIO LIVE COMPANIES</label>
                        <p>
                            <FontAwesomeIcon
                                icon={faBookOpen}
                                fixedWidth
                                className="orange"
                            />
                            {summarySheetData.num_portfolio_companies}
                        </p>
                    </div>
                </Grid>
            </Grid>

            <Tabs
                defaultTab="data"
                tabs={[
                    {
                        value: "data",
                        title: "DATA",
                        content: (
                            <SummarySheetData apiUrl={apiUrl} id={getID()} />
                        ),
                    },
                    {
                        value: "more-info",
                        title: "MORE INFO",
                        content: (
                            <SummarySheetMoreInfo
                                summarySheetData={summarySheetData}
                            />
                        ),
                    },
                    {
                        value: "errors",
                        title: "ERRORS",
                        icon: (
                            <SummarySheetCounts
                                error={summarySheetData.num_errors}
                            />
                        ),
                        content: (
                            <SummarySheetErrors apiUrl={apiUrl} id={getID()} />
                        ),
                    },
                ]}
            />
        </SummarySheetDetailContainer>
    );
};

export default SummarySheetDetail;
