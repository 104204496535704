import styled from "styled-components";

export default styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 1;
    min-height: 40px;
    max-height: 40px;

    border-radius: 5px;

    color: ${(props) => props.theme.subMenuText};

    .icon {
        display: flex;
        justify-content: center;
        align-items: center;

        margin: 0 10px;

        svg {
            font-size: 16px;
        }
    }

    .text {
        display: flex;
        font-size: 14px;
        line-height: 14px;
        margin: 2px 0 0 0;
        white-space: nowrap;
    }

    .badge {
        display: flex;
        justify-content: center;
        align-items: center;

        border-radius: 5px;

        margin-left: 6px;

        padding: 1px 5px 0 5px;

        min-height: 20px;
        max-height: 20px;

        font-size: 14px;
        line-height: 14px;
        font-weight: bolder;

        background-color: ${(props) => props.theme.subMenuBadgeBackground};
        color: ${(props) => props.theme.subMenuBadgeText};

        &.secondary {
            background-color: ${(props) =>
                props.theme.subMenuBadgeBackgroundSecondary};
            color: ${(props) => props.theme.subMenuBadgeTextSecondary};
        }
    }

    :hover {
        background-color: ${(props) => props.theme.subMenuHighlight};
        cursor: pointer;
    }

    &.active {
        background-color: ${(props) => props.theme.subMenuActive};
        color: ${(props) => props.theme.mainMenuForeground};
    }
`;
