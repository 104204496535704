import colors from "assets/styles/colors";

export default {
    labelSuccessBg: colors.darkThemeLight,
    labelSuccessColor: "white",

    labelAlertBg: "white",
    labelAlertColor: colors.darkThemeLight,

    labelWarningBg: colors.accentOrange,
    labelWarningColor: "white",

    labelPrimaryBg: colors.darkAccentBlue,
    labelPrimaryColor: "white",

    labelSecondaryBg: colors.darkThemeLight,
    labelSecondaryColor: "white",
};
