import styled from "styled-components";
import colors from "assets/styles/colors";

export default styled.div`
    flex-direction: column;
    display: flex;
    flex-grow: 1;

    label {
        color: #777777;
        letter-spacing: 1px;
        margin-top: 10px;
        margin-bottom: 5px;
    }

    .metaOptions {
        margin: 0 -10px;
        display: flex;
        align-items: center;

        .option {
            flex-direction: column;
            display: flex;
            margin: 10px;
        }

        .button {
            margin-top: 12px;
        }
    }

    .info {
        flex-direction: column;
        margin: 10px 20px 10px 10px;
        display: flex;

        label {
            font-size: 14px;
            color: #777777;
        }

        p {
            margin: 0;
            font-size: 18px;
            letter-spacing: 1px;
        }
    }

    .notesBox {
        border-radius: 5px;
        padding: 10px;
        font-size: 18px;
        border: 2px solid ${colors.teal100};
        color: ${colors.textGrey};
        outline: none;
        margin-bottom: 10px;

        :focus {
            border: 2px solid ${colors.textBlue};
        }
    }

    #getFile {
        display: none;
    }

    .uploadBox {
        width: 100%;
        height: 200px;
        display: flex;

        user-select: none;

        margin-top: 10px;

        border-radius: 5px;

        background-color: ${colors.teal30};
        border: 2px dashed ${colors.teal100};

        cursor: pointer;

        justify-content: center;
        align-items: center;
        flex-direction: column;

        color: #777777;

        svg {
            font-size: 50px;
        }

        p {
            margin: 15px 0 0 0;
            font-size: 20px;
            letter-spacing: 2px;
        }

        .filenameExample {
            font-size: 14px;
        }

        :hover {
            background-color: ${colors.teal80};
            border: 2px solid #777777;
            color: #444444;
        }

        &.true {
            border: 2px solid lightcoral;
        }
    }

    .actions {
        margin: 15px -10px;
        display: flex;
    }

    .errorMessages {
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: lightcoral;
        width: fit-content;
        padding: 10px 20px 10px 5px;
        color: white;
        border-radius: 50px 5px 5px 50px;
        svg {
            color: white;
            font-size: 40px;
        }

        ul {
            margin: 0;
        }
    }
`;
