import React, {useEffect, useState} from "react";
import Button from "components/Common/Button/Button";

// Styles
import ChronDataRefreshContainer from "./ChronDataRefresh.styles";

//Utils
import { queryDatabase, submitToDatabase } from "services/api";
import LoadingSpinner from "../../../components/Common/LoadingSpinner/LoadingSpinner";

const API_FETCH_STATUS = "api/chronograph/refresh-request/status"
const API_START_REFRESH_REQUEST = "api/chronograph/refresh-request/start"

interface refreshRequest {
    status: ""|"started"|"failed"|"succeeded",
    last_updated: Date|null,
    created_on: Date|null,
    check_started_at: Date|null,
    check_finished_at: Date|null,
    retry_count: number
};


const ChronDataRefresh = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [lastSuccessfulRefresh, setLastSuccessfulRefresh] = useState<Date|null>(null);
    const [triggerAllowedAt, setTriggerdAllowedAt] = useState<Date|null>(null);
    const [canRefresh, setCanRefresh] = useState<boolean>(false);
    const [refreshRequest, setRefreshRequest] = useState<refreshRequest>({
        status: "",
        last_updated: null,
        created_on: null,
        check_started_at: null,
        check_finished_at: null,
        retry_count: 0
    });


    const getState = async () => {
        setLoading(true);

        const data = await queryDatabase(API_FETCH_STATUS);

        if (!data.refresh_request) {
            return
        }

        let request = {
            status: data.refresh_request.status,
            last_updated: new Date(data.refresh_request.last_updated),
            created_on: new Date(data.refresh_request.created_on),
            check_started_at: new Date(data.refresh_request.check_started_at),
            check_finished_at: new Date(data.refresh_request.check_finished_at),
            retry_count: data.refresh_request.retry_count
        }

        setRefreshRequest(request);

        checkCanRefresh(request);

        if (data.last_successful_refresh) {
            setLastSuccessfulRefresh(new Date(data.last_successful_refresh))
        }

        setLoading(false);
    };

    const startRefresh = async() => {
        setLoading(true);

        await submitToDatabase(API_START_REFRESH_REQUEST, null);

        getState();
    }

    const checkCanRefresh = (refreshRequest: refreshRequest) => {
        // to trigger another refresh request is only allowed in every 2 hours for the frontend
        let canRefresh = false;

        if (refreshRequest.status == "succeeded") {
            if (refreshRequest.check_started_at) {
                // 2 hours after the latest refresh attempt
                let nextTriggerAllowedAt = new Date(new Date(refreshRequest.check_started_at).setHours(refreshRequest.check_started_at.getHours() + 2));

                if (nextTriggerAllowedAt.getTime() < new Date().getTime()) {
                    // can trigger a refresh after 2 hours of the latest refresh
                    canRefresh = true;
                }

                setTriggerdAllowedAt(nextTriggerAllowedAt);
            } else {
                // first refresh attempt
                canRefresh = true;
            }
        }

        setCanRefresh(canRefresh)
    }

    useEffect(() => {
        getState();
    }, []);


    return (
        <ChronDataRefreshContainer>
            <p className={"sectionTitle"}>Data Refresh </p>
            <p className="title mb20">Manual trigger to obtain the latest data from Chronograph</p>

            {loading && (
                <div className="loader">
                    <LoadingSpinner message={"Checking Refresh Status"} />
                </div>
            )}

             {!loading && (
                <p className={refreshRequest.status}>
                    <p className={"header"}>

                        { refreshRequest.status == 'succeeded' &&
                            <>
                                {canRefresh && <p className={"status"}>Please click the button below to start a new refresh process. </p>}

                                {!canRefresh && <p>The refresh process only can be initiated every 2 hours. The refresh process had been triggered recently.</p>}

                                {!canRefresh && triggerAllowedAt &&
                                    <p className={"trigger-at"}>The next option to start a refresh process is at {triggerAllowedAt.toLocaleString()} </p>

                                }

                            </>
                        }

                        { refreshRequest.status == 'failed' &&
                            <>
                                <p className={"status"}>The previous refresh has failed.</p>
                                <p>The issue had been reported and will be fixed soon.</p>
                                <p>This issue has to be resolved before another refresh process can start. </p>
                            </>

                        }

                        { refreshRequest.status == 'started' &&
                            <>
                                <p className={"status"}>There is an ongoing refresh process</p>
                                <p>This process has to finish first before you can start a new one. Please come back later.</p>
                            </>
                        }

                    </p>
                    <p className={"body"}>

                        {refreshRequest.status == 'succeeded' &&
                            <div className={"important-note"}>New data from Chronograph only will be visible in PowerBI after 2 hours.</div>
                        }

                        <Button
                            text={"START REFRESH PROCESS"}
                            click={() => startRefresh()}
                            color={"primary"}
                            disabled={!canRefresh}
                        />

                         <p className={"last-successful-refresh"}>
                            { lastSuccessfulRefresh &&
                                "Last successful refresh: " + lastSuccessfulRefresh.toLocaleString()
                            }
                            { !lastSuccessfulRefresh &&
                                "There was no successful refresh process yet"
                            }
                        </p>
                    </p>
                </p>
             )}



        </ChronDataRefreshContainer>
    );
};
export default ChronDataRefresh;
