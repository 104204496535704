import React, { useEffect, useState } from "react";
import {Link} from "react-router-dom";

// Styles
import CompanyWithDealListContainer from "./CompanyWithDealList.styles";


// Interface
import {FullDealDetails, GrowthCompany} from "assets/interfaces/growth_voting";

// Utils
import { formatDate } from "utils/date.utils";

// Components
import ButtonSmall from "components/Common/ButtonSmall/ButtonSmall";
import VoteIcon from "../VoteIcon/VoteIcon";
import DealIcon from "../DealIcon/DealIcon";
import EditCompanyModel from "components/Screens/GrowthVoting/EditCompanyModal/EditCompanyModal"

// Icons
import {faArrowRight, faPencil} from "@fortawesome/free-solid-svg-icons";

interface Props {
    companies: GrowthCompany[];
    setCompanies: React.Dispatch<React.SetStateAction<GrowthCompany[]>>

}

const CompanyWithDealList  = ({companies, setCompanies}: Props) => {
    const [selectedCompany, setSelectedCompany] = useState<GrowthCompany>({name: "", url: ""});
    const [showEditCompany, setShowEditCompany] = useState<boolean>(false); // listener for the save event
    const [saved, setSaved] = useState<boolean>(false); // listener for the save event

    const editCompany = (company: GrowthCompany) => {
        setSelectedCompany(company);
        setShowEditCompany(true);
    }

    useEffect(() => {
        if (!saved) return;

        companies.forEach((company) => {
            if (company.id && company.id === selectedCompany.id) {
                company.name = selectedCompany.name;
                company.url = selectedCompany.url;
            }
        });

        setCompanies(companies);
        setSaved(false);
    }, [saved]);

    return (
        <CompanyWithDealListContainer>
                {showEditCompany && (
                <EditCompanyModel company={selectedCompany} setCompany={setSelectedCompany} setShowModal={setShowEditCompany} setSaved={setSaved} />
            )}

             {companies && companies.length > 0 &&
                       companies.map((company, companyIndex) => (
                            <div className={"company"} key={companyIndex} >
                                <div className={"company-details"}>
                                    <span className={"name"}>
                                        {company.name}
                                    </span>
                                    <span className={"url"}>
                                        <a className={"link"} target='_blank' href={`https://${company.url}`}>{company.url}</a>
                                    </span>
                                    {company?.created && <span className={"created"}>
                                        {formatDate(new Date(company?.created))}
                                    </span>}
                                    <span className={"edit"}>
                                       <ButtonSmall
                                            color={"third"}
                                            text={"Edit Company"}
                                            icon={faPencil}
                                            click={() => {editCompany(company)}}
                                       />
                                   </span>
                                </div>
                                {company?.deals && company?.deals.length > 0 && company.deals.map((deal: FullDealDetails, dealIndex) => (
                                    <div key={dealIndex} className={"deal"}>

                                        <div>
                                            <DealIcon deal={deal} />
                                        </div>

                                        <div className={"funding"}>
                                            <div>{deal.funding_round}</div>
                                            <div className={"created"}>{deal?.created && formatDate(new Date(deal?.created))}</div>
                                        </div>

                                        <div className={"vote-types"}>
                                            {deal.votes.length > 0 &&
                                                deal.votes.map((vote, index) => (
                                                    <div
                                                        className={"vote-type"}
                                                        key={index}
                                                    >
                                                        <VoteIcon vote={vote} />
                                                    </div>
                                                ))}
                                        </div>

                                        <div className={"link"}>
                                            <Link to={{
                                                pathname: `/growth-voting/deals/${deal.id}`,
                                                state: { deal: deal }
                                            }}>
                                                <ButtonSmall
                                                    text={"Open Deal"}
                                                    icon={faArrowRight}
                                                    click={() => { }}
                                                />
                                            </Link>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ))
                    }

        </CompanyWithDealListContainer>
    );
};

export default CompanyWithDealList
