import styled from 'styled-components';

export default styled.div`
    .choices {
        display: flex;
        align-items: flex-start;
        .choice {
            padding: 6px 12px;
            font-weight: normal;
            margin-right: 10px;
            border-radius: 5px;
            border: 1px solid  ${(props) => props.theme.labelSecondaryBg};
            color: ${(props) => props.theme.labelSecondaryBg};
            transition: background-color 150ms linear;
            cursor: pointer;
            &:hover {
                box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
            }
        }
        .selected {
            background-color: ${(props) => props.theme.labelSuccessBg};
            border: 1px solid ${(props) => props.theme.labelSuccessBg};
            color: ${(props) => props.theme.labelSuccessColor};
            transition: background-color 150ms linear;
            font-weight: bold;
            &:hover {
                box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
            }
        }
    }

    .closed {
        pointer-events:none;
        cursor: not-allowed;
        pointer: not-allowed;
    }
`;
