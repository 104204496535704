import colors from "assets/styles/colors";

// Component Themes
import menuTheme from "./menu.theme";
import buttonTheme from "./button.theme";
import tableTheme from "./table.theme";
import tabsTheme from "./tabs.theme";
import filterButtonTheme from "./filterButton.theme";
import profileTheme from "./profile.theme";
import modalTheme from "./modal.theme";
import homeListTheme from "./homeList.theme";
import bqmqTheme from "./bqmq.theme";
import adaHomeTheme from "./adaHome.theme";
import askAdaTheme from "./askAda.theme";
import labelTheme from "./label.theme";

export default {
    mainBackground: "#003331",

    textPrimary: "#FFFFFF",

    pageTitleText: "white",
    pageSubTitleText: colors.darkThemeLighter,

    settingsMenuMainBackground: colors.darkThemeDark,
    settingsMenuHeaderBackground: colors.darkThemeMain,
    settingsMenuHeaderButtonBackground: colors.darkThemeLight,
    settingsMenuHeaderButtonBackgroundHover: colors.darkThemeLighter,
    settingsMenuHeaderText: "white",

    switchTrack: colors.darkThemeLight,
    switchChecked: colors.darkThemeLight,

    permissionsCheck: colors.darkThemeLight,
    permissionsAdd: colors.darkThemeLighter,
    permissionsDelete: colors.accentOrange,

    ...menuTheme,
    ...buttonTheme,
    ...tableTheme,
    ...tabsTheme,
    ...filterButtonTheme,
    ...profileTheme,
    ...modalTheme,
    ...homeListTheme,
    ...bqmqTheme,
    ...adaHomeTheme,
    ...askAdaTheme,
    ...labelTheme,
};
