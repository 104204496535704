import styled from "styled-components";

export default styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    padding: 10px 20px;

    width: 100%;

    color: ${(props) => props.theme.dropDownMenuText};
    background-color: ${(props) => props.theme.dropDownMenuBackground};

    cursor: pointer;

    &:hover {
        background-color: ${(props) => props.theme.dropDownBackgroundHover};
    }

    .icon {
        width: 40px;
        color: ${(props) => props.theme.dropDownSearchBoxIcon};

        svg {
            font-size: 16px;
        }
    }

    .label {
        font-size: 16px;
        margin: 0;
        line-height: 1;
        flex-grow: 1;
        letter-spacing: 2px;
    }
`;
