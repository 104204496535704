import styled from "styled-components";

export default styled.div`
    display: flex;
    flex-direction: row;

    min-height: 50px;

    min-width: 100%;

    margin-bottom: 20px;

    font-size: 18px;

    .label {
        display: flex;
        justify-content: center;
        align-items: center;

        border-radius: 5px 0 0 5px;

        background-color: ${(props) => props.theme.homeListTitleRowBackground};
        color: ${(props) => props.theme.homeListTitleRowText};

        padding: 0 15px;

        min-width: 170px;
        max-width: 170px;
    }

    .value {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        border-radius: 0 5px 5px 0;

        border-right: 1px solid
            ${(props) => props.theme.homeListBodyBorderColor};
        border-top: 1px solid ${(props) => props.theme.homeListBodyBorderColor};
        border-bottom: 1px solid
            ${(props) => props.theme.homeListBodyBorderColor};

        padding: 10px 20px;

        font-size: 18px;

        flex-grow: 1;
    }

    &.error {
        .label {
            background-color: ${(props) => props.theme.subMenuBadgeBackground};
            color: ${(props) => props.theme.subMenuBadgeText};
        }

        .value {
            border: 1px solid ${(props) => props.theme.subMenuBadgeBackground};
        }
    }
`;
