import { useState, useEffect } from "react";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowRight,
    faChevronDown,
    faChevronUp,
    faEquals,
} from "@fortawesome/free-solid-svg-icons";

// Components
import Table from "components/Common/Table/Table";
import WarningCounts from "components/Screens/Data/CompanyModel/WarningCounts/WarningCounts";
import Status from "components/Screens/Data/CompanyModel/Status/Status";
import Button from "components/Common/Button/Button";
import TableCell from "components/Common/TableCell/TableCell";

// Utils
import * as Database from "utils/database.utils";
import {
    diffColor,
    to4DecimalPlaces,
    toDateAndTime,
} from "utils/general.utils";

// Context
import { useSettings } from "context/Settings.context";

// Styles
import DiffContainer from "./Diff.styles";

type Props = {
    id: string;
    companyID: number;
};

const Diff: React.FC<Props> = ({ id, companyID }: Props) => {
    const { setShowLoadingToast } = useSettings();

    const [mode, setMode] = useState<"SELECT" | "DIFF">("SELECT");

    const [selectedID, setSelectedID] = useState<string>("");

    const [diffData, setDiffData] = useState<any[]>([]);
    const [extractionList, setExtractionList] = useState<any[]>([]);

    const [date1, setDate1] = useState<Date | string>("");
    const [date2, setDate2] = useState<Date | string>("");

    const [count, setCount] = useState<number>(0);
    const [pageQuery, setPageQuery] = useState<string>("");
    const [filterQuery, setFilterQuery] = useState<string>("");

    const getDiffData = () => {
        setShowLoadingToast({ show: true, message: "LOADING" });

        Database.get(
            "api/extractions/difference/" +
                id +
                "/" +
                selectedID +
                pageQuery +
                filterQuery
        ).then((res) => {
            if (res.ok) {
                res.json().then((data) => {
                    setDiffData(data.data);
                    setCount(data.total);
                    if (data.data.length > 0) {
                        setDate1(data.data[0].date1);
                        setDate2(data.data[0].date2);
                    }

                    setShowLoadingToast({
                        show: false,
                        message: "LOADING",
                    });
                });
            }
        });
    };

    const getExtractionList = () => {
        setShowLoadingToast({ show: true, message: "LOADING" });

        Database.get(
            "api/extractions?filter_company=" +
                companyID +
                "&page=1&pageSize=1000&sortKey=company&sortOrder=ASC"
        ).then((res) => {
            if (res.ok) {
                res.json().then((data) => {
                    removeCurrentExtraction(data.data);

                    setShowLoadingToast({
                        show: false,
                        message: "LOADING",
                    });
                });
            }
        });
    };

    const removeCurrentExtraction = (list: any[]) => {
        let newList: any[] = [];

        list.forEach((extraction: any) => {
            if (extraction.id !== id) newList.push(extraction);
        });

        setExtractionList(newList);
    };

    useEffect(() => {
        if (selectedID) {
            getDiffData();
        } else {
            getExtractionList();
        }
    }, [pageQuery, filterQuery, selectedID]);

    const handleGoBack = () => {
        setSelectedID("");
        setMode("SELECT");
        setDiffData([]);
    };

    const columnsSelect = [
        {
            id: 1,
            title: "DATE",
            sortable: true,
            value: "extraction_datetime",
            cell: (data: Date) => <p>{toDateAndTime(data)}</p>,
            show: true,
        },
        {
            id: 2,
            title: "ERRORS",
            sortable: false,
            value: "model_status",
            cell: (data: any) => (
                <WarningCounts
                    info={data.num_errors_info}
                    warning={data.num_errors_warning}
                    critical={data.num_errors_critical}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 3,
            title: "STATUS",
            sortable: false,
            value: "model_status",
            cell: (data: any) => (
                <Status
                    status={data.model_status}
                    extractionId={data.id}
                    info={data.num_errors_info}
                    warning={data.num_errors_warning}
                    critical={data.num_errors_critical}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 4,
            title: "",
            sortable: false,
            value: "id",
            cell: (data: string) => (
                <FontAwesomeIcon
                    icon={faArrowRight}
                    fixedWidth
                    className="selectIcon"
                    onClick={() => {
                        setSelectedID(data);
                        setMode("DIFF");
                    }}
                />
            ),
            show: true,
        },
    ];

    const columnsDiff = [
        {
            id: 1,
            title: "METRIC",
            sortable: true,
            value: "metric",
            cell: (data: string) => <TableCell data={data} />,
            show: true,
        },
        {
            id: 2,
            title: "THIS EXTRACTION",
            sortable: false,
            value: "value1",
            cell: (data: string) => <p>{to4DecimalPlaces(data)}</p>,
            show: true,
        },
        {
            id: 3,
            title: "SELECTED EXTRACTION",
            sortable: false,
            value: "value2",
            cell: (data: string) => <p>{to4DecimalPlaces(data)}</p>,
            show: true,
        },
        {
            id: 4,
            title: "DIFF",
            sortable: false,
            value: "diff",
            cell: (data: any) => (
                <p className={diffColor(data)}>
                    {to4DecimalPlaces(data)}{" "}
                    <FontAwesomeIcon
                        icon={
                            diffColor(data) === "green"
                                ? faChevronUp
                                : diffColor(data) === "red"
                                ? faChevronDown
                                : faEquals
                        }
                        fixedWidth
                    />
                </p>
            ),
            show: true,
        },
    ];

    return (
        <DiffContainer>
            {mode === "SELECT" && (
                <>
                    <p className="title">SELECT EXTRACTION TO COMPARE</p>

                    <Table
                        columns={columnsSelect}
                        data={extractionList}
                        filterQuery={filterQuery}
                        changePageQuery={setPageQuery}
                        defaultSortKey="extraction_datetime"
                        defaultSortOrder="DESC"
                        large
                    />
                </>
            )}

            {mode === "DIFF" && (
                <>
                    <div className="searchBar">
                        <Button text="BACK" click={handleGoBack} />
                        {date2 && (
                            <p className="selectedDate">
                                <span>SELECTED EXTRACTION: </span>
                                {toDateAndTime(date2)}
                            </p>
                        )}
                    </div>

                    <Table
                        columns={columnsDiff}
                        data={diffData}
                        total={count}
                        filterQuery={filterQuery}
                        changePageQuery={setPageQuery}
                        defaultSortKey="metric"
                        defaultSortOrder="ASC"
                        pagination
                        emptyText="NO DIFFERENCE"
                    />
                </>
            )}
        </DiffContainer>
    );
};

export default Diff;
