import styled from 'styled-components';

export default styled.div`
    .downloadIcon {
        cursor: pointer;
        z-index: 10;
        svg {
            color: ${(props) => props.theme.tableIconHighlight};
            font-size: 20px;
        }
    }

    .downloadBtn {
        margin-top: 15px;
        margin-bottom: 15px;
        float: right;
    }

    .tableDiv {
        clear: right;
    }

    .downloadIcon {
        cursor: pointer;

        svg {
            color: ${(props) => props.theme.tableIconHighlight};
            font-size: 20px;
        }
    }
`;