import React, { useEffect, useState } from "react";

// Components
import Table from "components/Common/Table/Table";
import SearchOptions from "components/Common/SearchOptions/SearchOptions";
import Actions from "components/Screens/Data/Companies/StockSplits/Actions/Actions";
import StockSplitModal from "components/Screens/Data/Companies/StockSplits/StockSplitModal/StockSplitModal";
import Button from "components/Common/Button/Button";
import TableCell from "components/Common/TableCell/TableCell";

// Utils
import * as Database from "utils/database.utils";
import { getAllCompanies } from "utils/companies.utils";

// Context
import { useNotifications } from "context/Notifications.context";

// Styles
import StockSplitsContainer from "./StockSplits.styles";

type Props = {
    homePage?: boolean;
};

const StockSplits: React.FC<Props> = ({ homePage }) => {
    const { setNewStockSplits } = useNotifications();
    const [filterQuery, setFilterQuery] = useState<string>("");
    const [reload, setReload] = useState(0);
    const [loading, setLoading] = useState<boolean>(true);

    const [stockSplitList, setStockSplitList] = useState<any[]>([]);

    const [companiesList, setCompaniesList] = useState<any[]>([]);

    const [showConfirmed, setShowConfirmed] = useState<boolean>(false);
    const [showNewStockSplitModal, setShowNewStockSplitModal] =
        useState<boolean>(false);

    const getCompanies = async () => {
        let res = await getAllCompanies();
        let companies = await res.json();

        setCompaniesList(companies.data);
    };

    const getStockSplits = () => {
        setLoading(true);
        Database.get(
            "api/stock_splits/auto_detected?page=1&pageSize=1000&sortKey=detected_datetime&sortOrder=ASC"
        ).then((res) => {
            if (res.ok) {
                res.json().then((data) => {
                    setStockSplitList(data.data);
                    setNewStockSplits(data.total);
                    setLoading(false);
                });
            }
        });
    };

    useEffect(() => {
        getCompanies();
        getStockSplits();
    }, []);

    const columnsNew = [
        {
            id: 1,
            title: "COMPANY",
            sortable: true,
            value: "company",
            cell: (data: string) => (
                <TableCell styleTypes="capitalize" data={data.toLowerCase()} />
            ),
            show: true,
        },
        {
            id: 2,
            title: "DETECTED DATE",
            sortable: true,
            value: "detected_datetime",
            cell: (data: string) => <TableCell data={data} />,
            show: true,
        },
        {
            id: 3,
            title: "DETECTED PRICE",
            sortable: false,
            value: "detected_price",
            cell: (data: number) => <TableCell data={data} />,
            show: true,
        },
        {
            id: 4,
            title: "PREV PRICE",
            sortable: false,
            value: "prev_detected_price",
            cell: (data: number) => <TableCell data={data} />,
            show: true,
        },
        {
            id: 5,
            title: "SPLIT FACTOR",
            sortable: false,
            value: "split_factor_est",
            cell: (data: number) => <TableCell data={Math.round(data)} />,
            show: true,
        },
        {
            id: 6,
            title: "",
            sortable: false,
            value: "",
            cell: (data: any) => (
                <Actions newSS data={data} reloadData={reloadData} />
            ),
            show: true,
            fullDataRow: true,
        },
    ];

    const columnsConfirmed = [
        {
            id: 1,
            title: "COMPANY",
            sortable: true,
            value: "company",
            cell: (data: string) => (
                <TableCell styleTypes="capitalize" data={data.toLowerCase()} />
            ),
            show: true,
        },
        {
            id: 2,
            title: "SPLIT DATE",
            sortable: true,
            value: "split_date",
            cell: (data: string) => <TableCell data={data} />,
            show: true,
        },
        {
            id: 3,
            title: "SPLIT FACTOR",
            sortable: false,
            value: "split_factor",
            cell: (data: number) => <TableCell data={data} />,
            show: true,
        },
        {
            id: 4,
            title: "",
            sortable: false,
            value: "",
            cell: (data: any) => (
                <Actions data={data} reloadData={reloadData} />
            ),
            show: true,
            fullDataRow: true,
        },
    ];

    const filterOptions = [
        {
            id: 1,
            name: "Company",
            value: "company",
            searchable: true,
            type: "DROPDOWN",
            options: companiesList.map((comp: any) => ({
                label: comp.company,
                value: comp.company_id,
            })),
        },
        {
            id: 2,
            name: "Date",
            value: "date",
            type: "DATE",
        },
    ];

    const reloadData = () => {
        setReload(reload + 1);
        getStockSplits();
    };

    return (
        <StockSplitsContainer>
            {!showConfirmed && (
                <>
                    {!homePage && (
                        <>
                            <p className="title mt20">
                                NEWLY DETECTED STOCK SPLITS REQUIRING ATTENTION
                            </p>

                            <div className="searchBar">
                                <SearchOptions
                                    setFilterQuery={setFilterQuery}
                                    options={filterOptions}
                                />

                                <div className={"actionBtns"}>
                                    <Button
                                        text={"SEE CONFIRMED"}
                                        click={() => setShowConfirmed(true)}
                                        color={"third"}
                                    />

                                    <Button
                                        text={"CREATE NEW"}
                                        click={() => {
                                            setShowNewStockSplitModal(true);
                                        }}
                                        color={"accent"}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                    <Table
                        loading={loading}
                        data={stockSplitList}
                        columns={columnsNew}
                        filterQuery={filterQuery}
                        defaultSortKey="detected_datetime"
                        emptyText="NO NEW DETECTED STOCK SPLITS"
                    />
                </>
            )}

            {showConfirmed && (
                <>
                    <p className="title mt20 ">CONFIRMED STOCK SPLITS</p>

                    <div className="searchBar">
                        <SearchOptions
                            setFilterQuery={setFilterQuery}
                            options={filterOptions}
                        />

                        <Button
                            text={"SEE NEW DETECTED"}
                            click={() => setShowConfirmed(false)}
                            color={"third"}
                        />

                        <Button
                            text={"CREATE NEW"}
                            click={() => {
                                setShowNewStockSplitModal(true);
                            }}
                            color={"accent"}
                        />
                    </div>

                    <Table
                        loading={loading}
                        url={"/api/stock_splits/confirmed"}
                        columns={columnsConfirmed}
                        filterQuery={filterQuery}
                        pagination
                        defaultSortKey="split_date"
                        emptyText="NO CONFIRMED STOCK SPLITS"
                        reload={reload}
                    />
                </>
            )}

            {showNewStockSplitModal && (
                <StockSplitModal
                    mode={"NEW"}
                    setShowModal={setShowNewStockSplitModal}
                    selectedStockSplit={false}
                    reload={reloadData}
                />
            )}
        </StockSplitsContainer>
    );
};

export default StockSplits;
