// MaterialUI
import { Tooltip } from "@mui/material";

// FontAwesome
import {
    faChair,
    faEnvelope,
    faUser,
    faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Data
import { teamLabelDict } from "assets/data/lists";

// Utils
import { applyTimeZoneOffset } from "utils/common.utils";
import * as Database from "utils/database.utils";

// Components
import Can from "components/Common/Can/Can";
import Actions from "components/Screens/BQMQ/Common/Actions/Actions";

// Context
import { useAuth } from "context/Auth.context";
import { useBQMQ } from "context/BQMQ.context";

// Styles
import ButtonSmall from "components/Common/ButtonSmall/ButtonSmall";
import { useSettings } from "context/Settings.context";
import UpcomingFocusListRowContainer from "./UpcomingFocusListRow.styles";

type Props = {
    session: any;
};

export default function UpcomingFocusListRow({ session }: Props) {
    const { setShowLoadingToast, setToast } = useSettings();

    const { user } = useAuth();

    const { analysts } = useBQMQ();

    const isHost = () => {
        return user.id === session.host_id;
    };

    const host = analysts.find(
        (analyst: any) => analyst.value === session.host_id
    );
    const presenter = analysts.find(
        (analyst: any) => analyst.value === session.presenter_id
    );

    const getTeam = () => {
        // @ts-ignore
        return teamLabelDict[session.company?.sector?.name.toUpperCase()];
    };

    const emailSessionInvitation = async () => {
        setShowLoadingToast({
            show: true,
            message: "SENDING INVITATION",
        });
        const result = await Database.post("api/bqmq_voting/session/invite", {
            session_id: session.id,
        });
        if (result.ok) {
            setToast({
                show: true,
                type: "success",
                message: "Invitation Sent",
            });
        } else {
            setToast({
                show: true,
                type: "error",
                message: "Error Sending Invitation",
            });
        }
        setShowLoadingToast({
            show: false,
            message: "",
        });
    };

    return (
        <UpcomingFocusListRowContainer>
            <div className={"info"}>
                <div
                    data-cy="upcoming-company"
                    className={"name " + session.company.name}
                >
                    {session.company.name.toUpperCase()}
                </div>

                <div className={"details"}>
                    {presenter && (
                        <Tooltip title={<h2>ANALYST</h2>}>
                            <div
                                className={"detail"}
                                data-cy={"focus-list-analyst"}
                            >
                                <FontAwesomeIcon icon={faUser} fixedWidth />
                                <p>{presenter.label}</p>
                            </div>
                        </Tooltip>
                    )}

                    {host && (
                        <Tooltip title={<h2>CHAIR</h2>}>
                            <div
                                className={"detail"}
                                data-cy={"focus-list-host"}
                            >
                                <FontAwesomeIcon icon={faChair} fixedWidth />
                                <p>{host.label}</p>
                            </div>
                        </Tooltip>
                    )}

                    {session?.company?.sector?.name && (
                        <Tooltip title={<h2>TEAM</h2>}>
                            <div className={"detail"}>
                                <FontAwesomeIcon icon={faUsers} fixedWidth />
                                <p className={"capitalize"}>{getTeam()}</p>
                            </div>
                        </Tooltip>
                    )}
                </div>
            </div>

            <Tooltip title={<h2>MEETING DATE</h2>}>
                <div className={"date"}>
                    {applyTimeZoneOffset(session.date)}
                </div>
            </Tooltip>

            <div className={"actions"}>
                <Can
                    yes={<Actions session={session} />}
                    permissions={["p_bqmq_can_create_session"]}
                />
                <div className="email">
                    <Can
                        yes={
                            <ButtonSmall
                                className={"hideSM"}
                                icon={faEnvelope}
                                text={"SEND EMAIL INVITATION"}
                                click={() => emailSessionInvitation()}
                            />
                        }
                        permissions={["p_bqmq_can_create_session"]}
                    />
                </div>
            </div>
        </UpcomingFocusListRowContainer>
    );
}
